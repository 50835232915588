import { useRef, useState } from 'react';
import cs from 'classnames';
import { faGlobe } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';

import { Locale, t, TranslationKey, useI18nContext } from '../../lib/i18n';
import { useOutsideClickDetection } from '../../lib/useOutsideClickDetection';
import checkedImg from '../../images/tick.png';
import {
  locales,
  localeDefinitions,
  getTranslatedLanguageName,
} from '../../lib/i18n/i18n';
import { useAppContext } from '../../lib/global';

import './PublicLanguageSelector.scss';

export const PublicLanguageSelector = () => {
  const app = useAppContext();
  const { locale, setLocale } = useI18nContext();
  const [menuOpen, setMenuOpen] = useState(false);

  const wrapperRef = useRef(null);
  useOutsideClickDetection(wrapperRef, () => setMenuOpen(false));

  const toggleMenu = () => {
    setMenuOpen((prev) => !prev);
  };

  const handleLocaleChange = (locale: Locale) => () => {
    setLocale(locale);
    setMenuOpen(false);
  };

  return (
    <div className="PublicLanguageSelector" ref={wrapperRef}>
      <button className="PublicLanguageSelector__toggler" onClick={toggleMenu}>
        <FontAwesomeIcon
          className="PublicLanguageSelector__globe-icon"
          icon={faGlobe}
        />
        <span>
          {t(`common.commonLanguages.languages.${locale}` as TranslationKey)}
        </span>
        <KeyboardArrowDownIcon
          className={cs(
            'PublicLanguageSelector__chevron',
            menuOpen && 'PublicLanguageSelector__chevron--up'
          )}
        />
      </button>

      {menuOpen && (
        <ul className="PublicLanguageSelector__menu">
          {locales.map((lang) => {
            const definition = localeDefinitions[lang];

            /**
             * If the language has a feature requirement, and the user
             * does not have that feature, do not show the language.
             */
            if (definition.feature && !app.hasFeature(definition.feature)) {
              return null;
            }

            return (
              <li key={lang} onClick={handleLocaleChange(lang)}>
                <FontAwesomeIcon
                  className="PublicLanguageSelector__globe-icon"
                  icon={faGlobe}
                />
                {getTranslatedLanguageName(lang)}
                {locale === lang && (
                  <img
                    className="PublicLanguageSelector__checkmark-icon"
                    src={checkedImg}
                    alt="checked"
                  />
                )}
              </li>
            );
          })}
        </ul>
      )}
    </div>
  );
};
