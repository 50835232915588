import { CSSProperties } from 'react';
import cn from 'classnames';

import './Badge.scss';

export type BadgeProps = {
  className?: string;
  style?: CSSProperties;
  children?: React.ReactNode;
  type?: 'error' | 'success' | 'info';
};

export function Badge(props: BadgeProps) {
  const cssType = props.type ?? 'info';
  return (
    <div
      className={cn(`Badge Badge--${cssType}`, props.className)}
      style={props.style}
    >
      {props.children}
    </div>
  );
}
