import React from 'react';
import { union } from 'lodash';
import * as api from '../../../../../serverApi';
import { t, TranslationKey } from '../../../../../lib/i18n';

import './ChangedFieldsSummary.scss';
import { getLabelForEventType } from '../../../../../lib/getLabelForEventType';

function sprintf(val: any) {
  if (typeof val === 'string') {
    return val;
  }
  if (typeof val === 'number') {
    return val.toString();
  }
  if (typeof val === 'boolean') {
    return val ? 'true' : 'false';
  }
  return JSON.stringify(val, null, 2);
}

// prettier-ignore
const fieldTranslations: {
  [auditableType: string]: { [s: string]: TranslationKey | false };
} = {
  'App\\Models\\Zone': {
    zone_id: 'manage.logs.fields.Zone.zone_id',
    comment: 'manage.logs.fields.Zone.comment',
    is_housing_unit: 'manage.logs.fields.Zone.is_housing_unit',
    care_observation_image_detailed: 'manage.logs.fields.Zone.care_observation_image_detailed',
    care_observation_image_anonymised: 'manage.logs.fields.Zone.care_observation_image_anonymised',
    care_event_image_detailed: 'manage.logs.fields.Zone.care_event_image_detailed',
    care_event_image_anonymised: 'manage.logs.fields.Zone.care_event_image_anonymised',
    stream_indicator_led: 'manage.logs.fields.Zone.stream_indicator_led',
    stream_indicator_audio: 'manage.logs.fields.Zone.stream_indicator_audio',
    enable_twoway_audio: 'manage.logs.fields.Zone.enable_twoway_audio',
    enable_oneway_audio: 'manage.logs.fields.Zone.enable_oneway_audio',
    enable_oneway_audio_anonymised: 'manage.logs.fields.Zone.enable_oneway_audio_anonymised',
    max_call_duration_twoway_audio: 'manage.logs.fields.Zone.max_call_duration_twoway_audio',
    max_call_duration_oneway_audio: 'manage.logs.fields.Zone.max_call_duration_oneway_audio',
    max_call_duration_oneway_audio_anonymised: 'manage.logs.fields.Zone.max_call_duration_oneway_audio_anonymised',
    alert_reminder_count: 'manage.logs.fields.Zone.alert_reminder_count',
    alert_reminder_interval: 'manage.logs.fields.Zone.alert_reminder_interval',
    reflex_presence_timeout: 'manage.logs.fields.Zone.reflex_presence_timeout',
    observation_stream_timeout: 'manage.logs.fields.Zone.observation_stream_timeout',
    vkp_enabled: 'manage.logs.fields.Zone.vkp_enabled',
    sensor_display_area_format: 'manage.logs.fields.Zone.sensor_display_area_format',
    sensor_display_name_format: 'manage.logs.fields.Zone.sensor_display_name_format',
    ip_range_from: 'manage.logs.fields.Zone.ip_range_from',
    ip_range_to: 'manage.logs.fields.Zone.ip_range_to',
    zone_ip_whitelist_id: false,
    parent_zone_id: false,
    legacy_zone_id: false,
    legacy_site_id: false,
  },

  'App\\Models\\AlertSetting': {
    include_regular_event_types: 'manage.logs.fields.AlertSetting.include_regular_event_types',
    include_technical_event_types: 'manage.logs.fields.AlertSetting.include_technical_event_types',
    include_custom_event_types: 'manage.logs.fields.AlertSetting.include_custom_event_types',
  },

  'App\\Models\\Sensor': {
    enabled_from: 'manage.logs.fields.Sensor.enabled_from',
    enabled_to: 'manage.logs.fields.Sensor.enabled_to',
    threshold1: 'manage.logs.fields.Sensor.threshold1',
    threshold2: 'manage.logs.fields.Sensor.threshold2',
    quarantine1: 'manage.logs.fields.Sensor.quarantine1',
    quarantine2: 'manage.logs.fields.Sensor.quarantine2',
    audio_enabled: 'manage.logs.fields.Sensor.audio_enabled',
    audio_file: 'manage.logs.fields.Sensor.audio_file',
    marking_index: 'manage.logs.fields.Sensor.marking_index',
    marking_type: 'manage.logs.fields.Sensor.marking_type',
    marking_event: 'manage.logs.fields.Sensor.marking_event',
  },

  /**
   * This section contains fallback labels when
   * a more specific label is not provided.
   */
  common: {
    alert_setting_id: 'manage.logs.fields.common.alert_setting_id',
    alert_setting_schedule_id: 'manage.logs.fields.common.alert_setting_schedule_id',
    enabled: 'manage.logs.fields.common.enabled',
    event_type: 'manage.logs.fields.common.event_type',
    first_name: 'manage.logs.fields.common.first_name',
    from_time: 'manage.logs.fields.common.from_time',
    from_weekday: 'manage.logs.fields.common.from_weekday',
    is_enabled: 'manage.logs.fields.common.is_enabled',
    last_name: 'manage.logs.fields.common.last_name',
    level: 'manage.logs.fields.common.level',
    name: 'manage.logs.fields.common.name',
    parent_zone_id: 'manage.logs.fields.common.parent_zone_id',
    role: 'manage.logs.fields.common.role',
    sensor_type: 'manage.logs.fields.common.sensor_type',
    to_time: 'manage.logs.fields.common.to_time',
    to_weekday: 'manage.logs.fields.common.to_weekday',
    user_id: 'manage.logs.fields.common.user_id',
    user_role_id: 'manage.logs.fields.common.user_role_id',
    valid_from: 'manage.logs.fields.common.valid_from',
    valid_to: 'manage.logs.fields.common.valid_to',
    zone_id: 'manage.logs.fields.common.zone_id',
  },
};

const formatFieldName = (auditableType: string, fieldName: string) => {
  const translationKey =
    fieldTranslations[auditableType]?.[fieldName] ??
    fieldTranslations.common[fieldName];

  // We have explicitly set this field to be ignored in the field translations above
  if (translationKey === false) {
    return false;
  }

  return translationKey ? t(translationKey) : fieldName;
};

function formatValue(
  fieldName: string,
  otherValues: { [key: string]: any },
  value: any
) {
  if (fieldName === 'event_type') {
    if (otherValues['sensor_type'] === 'roommate') {
      return getLabelForEventType(value, 'roommate');
    }
  }

  if (fieldName === 'level') {
    if (value === 'alarm') {
      return t('care.components.InfoPage.levels.alarm');
    } else if (value === 'alert') {
      return t('care.components.InfoPage.levels.alert');
    }
  }

  if (['is_housing_unit'].includes(fieldName)) {
    return value ? t('manage.logs.common.yes') : t('manage.logs.common.no');
  }

  if (value === null) {
    return '';
  }

  if (typeof value === 'boolean') {
    return value ? t('manage.logs.common.on') : t('manage.logs.common.off');
  }

  if (['is_enabled', 'enabled'].includes(fieldName)) {
    return value ? t('manage.logs.common.on') : t('manage.logs.common.off');
  }

  return sprintf(value);
}

interface Props {
  auditableType: string;
  values: {
    old_values?: [] | api.AuditChangedFields;
    new_values?: [] | api.AuditChangedFields;
  };
  evaluatedSensorType?: string | null;
  evaluatedEventType?: string | null;
}

export function ChangedFieldsSummary(props: Props) {
  const newValues =
    props.values?.new_values && !Array.isArray(props.values.new_values)
      ? props.values.new_values
      : {};

  const oldValues =
    props.values?.old_values && !Array.isArray(props.values.old_values)
      ? props.values.old_values
      : {};

  const hasNew = !Array.isArray(newValues) && Object.keys(newValues).length > 0;
  const hasOld = !Array.isArray(oldValues) && Object.keys(oldValues).length > 0;

  const fields = union(Object.keys(newValues), Object.keys(oldValues));

  return (
    <table className="ChangedFieldsSummary">
      <thead>
        <tr>
          <th>{t('manage.logs.change.ChangedFieldsSummary.fieldName')}</th>
          {hasOld && (
            <th>
              {t('manage.logs.change.ChangedFieldsSummary.originalValue')}
            </th>
          )}
          {hasNew && (
            <th>{t('manage.logs.change.ChangedFieldsSummary.newValue')}</th>
          )}
        </tr>
      </thead>
      <tbody>
        {props.evaluatedSensorType && props.evaluatedSensorType && (
          <tr>
            <td>{t('manage.logs.fields.common.event_type')}</td>
            <td colSpan={2} style={{ textAlign: 'center' }}>
              {getLabelForEventType(
                props.evaluatedEventType as api.EventType,
                props.evaluatedSensorType
              )}
            </td>
          </tr>
        )}

        {fields.map((f) => {
          const label = formatFieldName(props.auditableType, f);
          if (label === false) {
            return null;
          }
          return (
            <tr key={f}>
              <td>{formatFieldName(props.auditableType, f)}</td>
              {hasOld && <td>{formatValue(f, oldValues, oldValues[f])}</td>}
              {hasNew && <td>{formatValue(f, newValues, newValues[f])}</td>}
            </tr>
          );
        })}
      </tbody>
    </table>
  );
}

export default ChangedFieldsSummary;
