import React from 'react';

import useEventSettingsForm, { EventFormProps } from '../useEventSettingsForm';

import EventActiveControl from '../controls/EventActiveControl';
import EventLevelControl from '../controls/EventLevelControl';
import EventTimeIntervalControl from '../controls/EventTimeIntervalControl';
import EventFormControls from '../controls/EventFormControls';
import { RouteLeavingGuard } from '../../../../../components/RouteLeavingGuard';
import { Panel } from '../../../../../components/Panel';
import { t } from '../../../../../lib/i18n';
import { getLabelForEventType } from '../../../../../lib/getLabelForEventType';
import EventNumberControl from '../controls/EventNumberControl';

export default function WristButtonClickEventForm(props: EventFormProps) {
  const form = useEventSettingsForm({
    sensorType: 'climax_wrist_button',
    eventType: 'click',

    initialValues: props.initialValues,
    scales: {
      quarantine1: 60,
    },
  });

  return (
    <form onSubmit={form.handleSubmit}>
      <RouteLeavingGuard when={form.dirty} />
      <h2>{getLabelForEventType('click', 'climax_wrist_button')}</h2>

      <Panel>
        <span
          dangerouslySetInnerHTML={{
            __html: t(
              'manage.sensors.events.WristButtonClickEventForm.firstInfo'
            ),
          }}
        />
      </Panel>

      <EventActiveControl form={form} />

      <EventTimeIntervalControl form={form} />

      <EventNumberControl
        form={form}
        label={t('manage.sensors.common.quarantineTimeInMinutes')}
        field="quarantine1"
      />

      <EventLevelControl form={form} />

      <EventFormControls form={form} />
    </form>
  );
}
