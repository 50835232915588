import { useEffect, useState } from 'react';
import { RouteComponentProps } from 'react-router';
import { FormGroup, TextField } from '@material-ui/core';
import { Col, Row } from 'reactstrap';
import qs from 'query-string';
import { Redirect } from 'react-router-dom';
import { useRouter } from '../../lib/useRouter';

import { t } from '../../lib/i18n';
import * as api from '../../serverApi';

import { PublicHeader } from './PublicHeader';
import { CenteredCard } from './CenteredCard';
import { CenterPanel } from '../manage/common/CenterPanel';
import { LayoutWithBackground } from './LayoutWithBackground';
import { ErrorMessages } from '../../components/ErrorMessages';
import { InlineSpinner } from '../../components/InlineSpinner';
import { Button } from '../../components/Button';

import { useErrorHandler } from '../../lib/useErrorHandler';
import { useAppContext } from '../../lib/global';

export const LoginSSO = (props: RouteComponentProps) => {
  const [accountCode, setAccountCode] = useState('');
  const [loading, setLoading] = useState(false);
  const [requestingLogin, setRequestingLogin] = useState(false);
  const errorHandler = useErrorHandler();
  const app = useAppContext();

  const router = useRouter();
  const values = qs.parse(router.location.search);

  useEffect(() => {
    const queryParams = qs.parse(props.location.search);
    const code = queryParams.code as string;
    if (!code) {
      return;
    }

    handleSSOLogin(code);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.location.search]);

  if (app.isAuthenticated) {
    const redirect = values.redirect ? values.redirect.toString() : '/';
    if (values.skipRoleSelect === '1' || app.user?.active_role) {
      return <Redirect to={redirect} />;
    } else {
      const url =
        redirect === '/' || redirect === '/roles'
          ? '/roles'
          : `/roles?redirect=${encodeURIComponent(redirect)}`;
      return <Redirect to={url} />;
    }
  }

  const handleSSOLogin = async (code: string) => {
    try {
      setRequestingLogin(true);

      const accountCodeFromStorage = localStorage.getItem('accountCode');

      if (!accountCodeFromStorage) {
        return;
      }

      const { user } = await api.attemptLogin(accountCodeFromStorage, { code });

      app.login(user);
      localStorage.removeItem('accountCode');
    } catch (error) {
      errorHandler.handleError(error);
      setRequestingLogin(false);
    }
  };

  const handleAccountCodeChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setAccountCode(e.target.value);
  };

  const redirectToAzureLogin = async () => {
    try {
      setLoading(true);
      const res = await api.getAuthUrl(accountCode);
      localStorage.setItem('accountCode', accountCode);
      window.location.href = res.auth_url;
    } catch (error) {
      errorHandler.handleError(error);
      setLoading(false);
    }
  };

  if (requestingLogin) {
    return (
      <CenterPanel>
        <InlineSpinner
          size="md"
          text={t('common.commonTexts.loading')}
          textStyle={{ fontWeight: 500, fontSize: 22 }}
        />
      </CenterPanel>
    );
  }

  return (
    <div className="LoginSSO App App-public">
      <LayoutWithBackground>
        <PublicHeader />
        <CenteredCard>
          <h1>SSO {t('common.commonTexts.logIn')}</h1>
          <br />
          <p>{t('publicComponents.LoginSSO.pleaseEnterAccountCode')}</p>
          <ErrorMessages className="mb-2" errorData={errorHandler} />
          <Row>
            <Col lg="10">
              <FormGroup>
                <TextField
                  placeholder={t('supervendor.AccountForm.accountCode')}
                  value={accountCode}
                  onChange={handleAccountCodeChange}
                  InputProps={{
                    className: 'text-field-input',
                  }}
                />
              </FormGroup>
              <Button
                className="w-100 mt-3"
                color="primary"
                onClick={redirectToAzureLogin}
                disabled={loading || accountCode === ''}
              >
                {loading
                  ? t('common.commonTexts.pleaseWait')
                  : t('common.commonTexts.logIn')}
              </Button>
            </Col>
          </Row>
        </CenteredCard>
      </LayoutWithBackground>
    </div>
  );
};
