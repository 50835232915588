import React from 'react';
import { RouteComponentProps } from 'react-router';
import { useErrorHandler } from '../../../lib/useErrorHandler';
import { ErrorMessages } from '../../../components/ErrorMessages';
import { useRouter } from '../../../lib/useRouter';
import { RouteLeavingGuard } from '../../../components/RouteLeavingGuard';

import { HeadingBar } from '../shared/HeadingBar';
import { Card } from '../shared/Card';

import { useUsersFormik } from './lib/useUsersFormik';
import { UserForm } from './ui/UserForm';

import * as api from '../../../serverApi';

interface Props extends RouteComponentProps {}

export const CreateUser: React.FC<Props> = () => {
  const errorHandler = useErrorHandler();
  const router = useRouter();

  const formik = useUsersFormik({
    onSubmit: async (values, helpers) => {
      try {
        errorHandler.reset();

        await api.superCreateUser({
          ...values,
          username: values.username || null,
        } as api.SuperCreateUserData);

        helpers.resetForm();
        router.history.push('/super/users');
      } catch (e) {
        errorHandler.handleError(e);
      }
    },
  });

  return (
    <div>
      <HeadingBar>
        <h1>Create super user</h1>
      </HeadingBar>
      <Card>
        <RouteLeavingGuard when={formik.dirty} />
        <div className="mt-2">
          <UserForm formik={formik} mode="create" />
          <ErrorMessages
            className="my-2"
            errorData={errorHandler}
            renderFieldErrors
          />
        </div>
      </Card>
    </div>
  );
};
