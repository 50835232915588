import React, { CSSProperties } from 'react';
import cs from 'classnames';

import { Icon } from '../../components/Icon';
import { SensorType } from '../../serverApi';
import { sensorTypes } from '../../sensorTypes';

interface SensorTypeIconProps {
  sensorType: SensorType;
  className?: string;
  style?: CSSProperties;
}

export function SensorTypeIcon(props: SensorTypeIconProps) {
  const icon = sensorTypes[props.sensorType]?.icon;

  if (!icon) {
    return null;
  }

  return (
    <Icon
      icon={icon}
      className={cs('SensorTypeIcon', props.className)}
      style={props.style}
    />
  );
}
