import { useState, useCallback, useEffect } from 'react';
import * as api from '../../../serverApi';

// TODO: Adjust refreshInterval based on lifetime of token, as returned by the server.
const refreshInterval = 90 * 1000; // 90 seconds

/**
 * This hook will retrieve and refresh a status token. The status token is used
 * by `/api/status` to fetch sensor and event statuses, and might be used in the
 * future for status updates through other channels (i.e. web sockets).
 */
export function useStatusToken(): string | undefined {
  const [token, setToken] = useState<undefined | string>(undefined);

  /**
   * The function to fetch a token and set the token state
   */
  const fetchToken = useCallback(() => {
    api
      .getStatusToken()
      .then(res => {
        setToken(res.token);
        // res.lifetime
      })
      .catch(error => {
        console.log('Error while getting status token:', error);
        if (error.status) {
          console.error(error);
        }
      });
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  /**
   * Refresh the token regularly
   */
  useEffect(() => {
    fetchToken();
    const interval = setInterval(() => fetchToken(), refreshInterval);
    return () => {
      clearInterval(interval);
    };
  }, [fetchToken]);

  return token;
}
