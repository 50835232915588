import React, { useState } from 'react';
import cs from 'classnames';

import { StreamType, useCareContext } from '../lib/CareContext';
import { AudioProperty, useWebRtcContext } from '../lib/WebRtcContext';

import { ToolbarButton } from './ToolbarButton';
import { ObservationEpjModal } from '../../../components/modals/care/ObservationEpjModal';
import { VkpReceiptModal } from '../../../components/modals/care/VkpReceiptModal';

import { useAppContext } from '../../../lib/global';

import { ToolbarMobileHeader } from './ToolbarMobileHeader';
import { t } from '../../../lib/i18n';

import './Toolbar.scss';
import { isEventTypeExcludedFromVkp } from '../../../lib/isEventTypeExcludedFromVkp';
import { eventTypesBySensorType } from '../../../eventTypes';

interface Props {
  className?: string;
  toolbarRef?: React.RefObject<HTMLDivElement>;
  hideActions?: boolean;
}

export function Toolbar(props: Props) {
  const app = useAppContext();
  const care = useCareContext();
  const [observationModal, setObservationModal] = useState(false);
  const [vkpReceiptModal, setVkpReceiptModal] = useState(false);

  const rtc = useWebRtcContext();

  const handleImageStreamingClick = (type: StreamType) => {
    if (care.sidebarMode !== 'sensors') {
      care.setSidebarMode('sensors');
    }
    care.setStreamType(type);
  };

  const handleAudioStreamingClick = (type: AudioProperty) => {
    if (care.sidebarMode !== 'sensors') {
      care.setSidebarMode('sensors');
    }

    care.continueAudioStreaming && care.setContinueAudioStreaming(false);
    rtc?.requestCall(type);
  };

  const sensor = care.selectedSensor ?? care.selectedEvent?.sensor;

  const disableAnonymous =
    care.selectedEvent !== undefined ||
    !sensor ||
    !sensor.media.observation_image_anonymised ||
    !app.hasCapability('observationAnonymised');

  const disableDetailed =
    care.selectedEvent !== undefined ||
    !sensor ||
    !sensor.media.observation_image_detailed ||
    app.user?.account?.disable_all_detailed_images ||
    !app.hasCapability('observationDetailed');

  const disableHandlingFromFrontend = care.selectedEvent?.event_type
    ? eventTypesBySensorType[care.selectedEvent.sensor.sensor_type][
        care.selectedEvent.event_type
      ]?.disableHandlingFromFrontend
    : undefined;

  // const showRawInsteadOfDetailed = !app.hasCapability('observationDetailed');
  const showRawInsteadOfDetailed = disableDetailed && !disableAnonymous;

  return (
    <div className={cs('Toolbar', props.className)} ref={props.toolbarRef}>
      <ToolbarMobileHeader className="Toolbar-mobile-header" />

      {!props.hideActions && (
        <div className="Toolbar-actions">
          <ToolbarButton
            disabled={disableAnonymous}
            label={t('care.components.Toolbar.anonymousImage')}
            icon="roommate-sensor"
            accentColor={true}
            active={
              !disableAnonymous &&
              care.streamType === 'depth' &&
              (care.selectedSensor !== undefined ||
                care.selectedEvent !== undefined)
            }
            onClick={() => handleImageStreamingClick('depth')}
          />

          {!app.user?.account?.disable_all_detailed_images && (
            <>
              {showRawInsteadOfDetailed ? (
                <ToolbarButton
                  disabled={disableAnonymous}
                  label={t(
                    'care.components.Toolbar.anonymizedImageWithoutColors'
                  )}
                  icon="roommate-sensor"
                  active={
                    !disableAnonymous &&
                    care.streamType === 'raw' &&
                    (care.selectedSensor !== undefined ||
                      care.selectedEvent !== undefined)
                  }
                  onClick={() => handleImageStreamingClick('raw')}
                />
              ) : (
                <ToolbarButton
                  disabled={disableDetailed}
                  label={t('care.components.common.detailedImage')}
                  icon="roommate-sensor-outline"
                  active={
                    !disableDetailed &&
                    care.streamType === 'amp' &&
                    (care.selectedSensor !== undefined ||
                      care.selectedEvent !== undefined)
                  }
                  onClick={() => handleImageStreamingClick('amp')}
                />
              )}
            </>
          )}

          <ToolbarButton
            disabled={
              !care.selectedSensor ||
              care.selectedSensor.status !== 'online' ||
              rtc?.status !== 'enabled' ||
              !care.selectedSensor.media.oneway_audio_anonymised ||
              !app.hasCapability('oneWayAudioAnonymised') ||
              //blf is detected as busy, but no active call, dial icons are disabled.
              //blf is detected as busy, and with active call, dial icons are still enabled to allow dynamic swithing of 3 call-types
              (rtc?.busy && rtc?.call?.audioProperty === undefined)
            }
            active={rtc?.call?.audioProperty === 'oneWayAnon'}
            label={t('care.components.common.anonymousListened')}
            icon="ear"
            accentColor={true}
            onClick={() => handleAudioStreamingClick('oneWayAnon')}
          />

          <ToolbarButton
            disabled={
              !care.selectedSensor ||
              care.selectedSensor.status !== 'online' ||
              rtc?.status !== 'enabled' ||
              !care.selectedSensor.media.oneway_audio ||
              !app.hasCapability('oneWayAudio') ||
              (rtc?.busy && rtc?.call?.audioProperty === undefined)
            }
            active={rtc?.call?.audioProperty === 'oneWay'}
            label={t('care.components.Toolbar.oneWayListened')}
            icon="ear"
            onClick={() => handleAudioStreamingClick('oneWay')}
          />

          <ToolbarButton
            disabled={
              !care.selectedSensor ||
              care.selectedSensor.status !== 'online' ||
              rtc?.status !== 'enabled' ||
              !care.selectedSensor.media.twoway_audio ||
              !app.hasCapability('twoWayAudio') ||
              (rtc?.busy && rtc?.call?.audioProperty === undefined)
            }
            active={rtc?.call?.audioProperty === 'twoWay'}
            label={t('care.components.Toolbar.twoWayTalk')}
            icon="telephone"
            onClick={() => handleAudioStreamingClick('twoWay')}
          />

          <ToolbarButton
            label={t('care.components.Toolbar.epj')}
            icon="epj"
            disabled={
              disableHandlingFromFrontend ||
              app.user?.vkp_enabled !== true ||
              care.selectedSensor?.vkp_enabled !== true ||
              app.hasCapability('sendActivityToVkp') !== true ||
              care.sidebarMode === 'info'
            }
            onClick={() => setObservationModal(true)}
          />

          <ObservationEpjModal
            isOpen={observationModal}
            onClose={() => setObservationModal(false)}
          />

          <ToolbarButton
            label={t('care.components.Toolbar.check')}
            icon="check"
            disabled={disableHandlingFromFrontend || !care.selectedEvent}
            onClick={() => {
              if (care.selectedEvent) {
                if (
                  care.selectedEvent.sensor &&
                  !isEventTypeExcludedFromVkp(
                    care.selectedEvent.sensor.sensor_type,
                    care.selectedEvent.event_type
                  ) &&
                  care.selectedEvent.sensor.vkp_enabled &&
                  app.hasCapability('sendActivityToVkp')
                ) {
                  setVkpReceiptModal(true);
                } else {
                  care.markEventAsHandled(care.selectedEvent);
                  care.selectEvent(undefined);
                }
              }
            }}
          />

          <VkpReceiptModal
            isOpen={vkpReceiptModal}
            onClose={() => setVkpReceiptModal(false)}
            onSubmitRequest={(note, vkp) => {
              if (care.selectedEvent) {
                care.markEventAsHandled(care.selectedEvent, note, vkp);
                care.selectEvent(undefined);
                setVkpReceiptModal(false);
              }
            }}
          />
        </div>
      )}
    </div>
  );
}
