import React from 'react';
import { FormGroup, TextField } from '@material-ui/core';
import { Button } from '../../../../../components/Button';
import {
  ErrorHandlerType,
  errorIsObjectError,
} from '../../../../../lib/useErrorHandler';

import * as api from '../../../../../serverApi';
import { t } from '../../../../../lib/i18n';

import './IpWhiteListForm.scss';
import { generateAutoCompleteValue } from '../../../../../lib/utils/generateAutoCompleteValue';

interface Props {
  loading?: boolean;
  error?: ErrorHandlerType;
  values: api.IpWhitelistInput;
  setValues: (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => void;
  onSubmit: () => void;
}

export const IpWhiteListForm = (props: Props) => {
  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    props.onSubmit();
  };

  const values = props.values;

  return (
    <div className="IpWhiteListForm">
      <p>
        <strong>
          {t('manage.zone.settings.advanced.IpWhiteListForm.addNewIpRange')}
        </strong>
      </p>
      <form
        noValidate
        autoComplete={generateAutoCompleteValue()}
        onSubmit={handleSubmit}
      >
        <div className="IpWhiteListForm-wrapper">
          <FormGroup row={true} className="IpWhiteListForm-wrapper-row">
            <TextField
              label={t('common.commonInputs.from')}
              name="ip_range_from"
              value={values.ip_range_from}
              onChange={(e) => props.setValues(e)}
              error={
                errorIsObjectError(props.error?.error) &&
                !!props.error?.error?.['ip_range_from']
              }
              helperText={
                errorIsObjectError(props.error?.error) &&
                props.error?.error?.['ip_range_from']
              }
            />

            <TextField
              label={t('common.commonInputs.to')}
              name="ip_range_to"
              value={values.ip_range_to}
              onChange={(e) => props.setValues(e)}
              error={
                errorIsObjectError(props.error?.error) &&
                !!props?.error?.error?.['ip_range_to']
              }
              helperText={
                errorIsObjectError(props.error?.error) &&
                props.error?.error?.['ip_range_to']
              }
            />
            <TextField
              label={t('common.commonInputs.comment')}
              name="comment"
              value={values.comment}
              onChange={(e) => props.setValues(e)}
              error={
                errorIsObjectError(props.error?.error) &&
                !!props?.error?.error?.['comment']
              }
            />
          </FormGroup>

          <Button
            type="submit"
            color="primary"
            icon={props.loading ? 'gear' : undefined}
            spinIcon={props.loading}
            disabled={props.loading}
            style={{ marginTop: 27 }}
          >
            {t('common.commonButtons.add')}
          </Button>
        </div>
      </form>
    </div>
  );
};
