import React from 'react';
import { useParams } from 'react-router';
import { useApiCall } from '../../../lib/useApiCall';
import LoadingPlaceholder from '../../../lib/ui/LoadingPlaceholder';
import { EditUserForm } from './forms/EditUserForm';
import { EditImpersonalUserForm } from './forms/EditImpersonalUserForm';

import * as serverApi from '../../../serverApi';
import { t } from '../../../lib/i18n';

export const EditUser = () => {
  const { userId } = useParams<{ userId: string }>();

  const userData = useApiCall(serverApi.getUser, [parseInt(userId)]);

  if (userData.loading) {
    return <LoadingPlaceholder heading={t('common.commonTexts.loading')} />;
  }

  if (!userData.data) {
    return null;
  }

  const userDetails = userData.data;

  const isImpersonal = !!userDetails.impersonal_user;

  return (
    <div style={{ maxWidth: 500 }}>
      {isImpersonal ? (
        <EditImpersonalUserForm
          data={userDetails}
          onAfterChange={userData.reload}
        />
      ) : (
        <EditUserForm data={userDetails} onAfterChange={userData.reload} />
      )}
    </div>
  );
};
