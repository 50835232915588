import { FormikHelpers, useFormik } from 'formik';
import * as yup from 'yup';
import { t } from '../../../../lib/i18n';

export interface CreateUserFields {
  account_id: null | number;
  username?: null | string;
  first_name?: null | string;
  last_name?: null | string;
  display_name?: null | string;
  email?: null | string;
  phone?: null | string;
  impersonal_user: boolean;
  send_generated_password_by_email?: boolean;
  roles: undefined | string[];
}

export type UserFormik = ReturnType<typeof useUsersFormik>;

interface UseSuperCreateUserFormOptions {
  initialValues?: CreateUserFields;
  enableReinitialize?: boolean;
  validationSchema?: any | (() => any);
  onSubmit: (
    values: CreateUserFields,
    formikHelpers: FormikHelpers<CreateUserFields>
  ) => Promise<any>;
}

const UserValidationSchema = yup.object().shape(
  {
    account_id: yup.number().required().typeError('Account is required'),
    impersonal_user: yup.boolean().required(),
    username: yup.string().when('impersonal_user', {
      is: false,
      then: yup.string().required(),
      otherwise: yup.string().nullable(),
    }),
    first_name: yup.string().nullable(),
    last_name: yup.string().nullable(),
    display_name: yup.string().nullable(),
    email: yup.string().when('send_generated_password_by_email', {
      is: true,
      then: yup.string().email().required(t('yup.string.email')),
      otherwise: yup.string().nullable(),
    }),

    phone: yup
      .string()
      .matches(/^\+?[0-9 ]+$/)
      .nullable(),
  },
  [['impersonal_user', 'username']]
);

export function useUsersFormik(opts: UseSuperCreateUserFormOptions) {
  return useFormik<CreateUserFields>({
    initialValues: opts.initialValues
      ? opts.initialValues
      : {
          account_id: null,
          username: '',
          first_name: '',
          last_name: '',
          display_name: '',
          email: '',
          phone: '',
          impersonal_user: false,
          send_generated_password_by_email: false,
          roles: undefined,
        },
    validateOnChange: true,
    validateOnBlur: true,
    validationSchema: opts.validationSchema
      ? opts.validationSchema
      : UserValidationSchema,
    enableReinitialize: true,
    onSubmit: async (values, helpers) => {
      await opts.onSubmit(values, helpers);
    },
  });
}
