import React from 'react';
import cs from 'classnames';
import { ZoneSettingsForm } from './useZoneSettingsForm';
import { ButtonGroup } from '../../../../../../components/ButtonGroup';
import { Button } from '../../../../../../components/Button';
import { t } from '../../../../../../lib/i18n';
import { ErrorMessages } from '../../../../../../components/ErrorMessages';

interface Props {
  className?: string;
  zoneSettingsForm: ZoneSettingsForm;
}

export function FormButtons(props: Props) {
  function submit() {
    props.zoneSettingsForm.formik.handleSubmit();
  }

  return (
    <div className={cs('FormButtons', props.className)}>
      <ErrorMessages errorApiCall={props.zoneSettingsForm.error} />
      <ButtonGroup>
        <Button
          type="submit"
          icon={props.zoneSettingsForm.loading ? 'gear' : undefined}
          spinIcon={props.zoneSettingsForm.loading}
          color="primary"
          onClick={submit}
          disabled={
            !props.zoneSettingsForm.hasChanged || props.zoneSettingsForm.loading
          }
        >
          {t('common.commonButtons.save')}
        </Button>

        <Button
          onClick={props.zoneSettingsForm.cancel}
          disabled={
            !props.zoneSettingsForm.hasChanged || props.zoneSettingsForm.loading
          }
        >
          {t('common.commonButtons.cancel')}
        </Button>
      </ButtonGroup>
    </div>
  );
}
