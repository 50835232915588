import axios from 'axios';
import { useEffect, useRef, useState } from 'react';
import { toast } from 'react-toastify';
import { me } from '../serverApi';

import { t } from './i18n';

// /**
//  * Convert seconds to minutes and seconds, with translated string included.
//  *
//  * @param seconds
//  * @returns minutes, seconds or both.
//  */
// function convertSecondsToMinutesString(seconds: number) {
//   if (seconds < 60) {
//     return `${seconds} ${t('common.commonTexts.seconds')}`;
//   }

//   const minutes = Math.floor(seconds / 60);
//   const secondsLeft = seconds % 60;

//   if (secondsLeft === 0) {
//     return `${minutes} ${t('common.minutes', 'minutes')}`;
//   }

//   return `${minutes} ${t('common.minutes', 'minutes')} ${secondsLeft} ${t(
//     'common.commonTexts.seconds'
//   )}`;
// }

/**
 *
 *  Inform user that it's about to be logged out after staying idle for too long.
 *
 *  @param notifyTime when should the notification appear (based on user's 'idle-timeout-notify-time' feature flag).
 */

export const useAlertUserForIdleTimeout = (notifyTime: number) => {
  const [idleTimeleft, setIdleTimeleft] = useState<{ value: number }>();
  const [lastNotification, setLastNotification] = useState<number>();
  const timeout = useRef<NodeJS.Timeout | undefined>();
  const redirectTimeout = useRef<NodeJS.Timeout | undefined>();

  /**
   * Set up axios interceptor to watch for 'x-roommate-idle-time-left' headers in responses
   * and update local state according to header value.
   */

  useEffect(() => {
    const interceptor = axios.interceptors.response.use((response) => {
      if (!response) {
        return;
      }

      const idleTimeleftHeader = response.headers['x-roommate-idle-time-left'];

      /**
       * Check if its value is a positive integer before proceeding.
       */
      if (
        typeof idleTimeleftHeader === 'string' &&
        /^[1-9]\d*$/.test(idleTimeleftHeader)
      ) {
        /**
         * After ensuring that idle timeleft is a valid positive integer we set it to state.
         */
        setIdleTimeleft({ value: parseInt(idleTimeleftHeader) });
      }
      return response;
    });

    /**
     * Dispose created interceptor when hook un-mounts.
     */
    return () => axios.interceptors.response.eject(interceptor);
  }, []);

  /**
   * Watch for idleTimeleft state change and display notification .
   */
  useEffect(() => {
    /**
     * Do nothing if there's no idle timeleft provided.
     */
    if (idleTimeleft === undefined) {
      return;
    }

    /**
     * Prevent user from getting spammed with alerts by setting a threshold when 'notify-time'
     * is greater or equal to 'idle-time-left' header and user has been notified recently.
     */
    if (lastNotification !== undefined && idleTimeleft.value <= notifyTime) {
      /**
       * We use notifyTime as a threshold for notification, so we don't show another notification
       * if there's an active one already.
       */

      if (Date.now() - lastNotification < notifyTime * 1000) {
        return;
      }
    }

    /**
     * Calculate timeout delay time depending in idle-time-left and notify-time values.
     * e.g if notify time is 60sec, we know that the user should be alerted 60sec before its idle time is finished,
     * so by subtracting idle-time-left with notify-time, we get the exact delay time before the user will be notified.
     */

    const delay = idleTimeleft.value - notifyTime;
    // const timeLeft = convertSecondsToMinutesString(
    //   delay < 0 ? idleTimeleft.value : notifyTime
    // );
    timeout.current && clearTimeout(timeout.current);
    timeout.current = setTimeout(() => {
      toast.info(t('user.IdleTimeout.youWillBeLoggedOut'), {
        autoClose: notifyTime * 1000,
        pauseOnHover: false,
        pauseOnFocusLoss: false,
        onClick: async () => await me(), //On toast click, we make a request to have idle timeleft resetted.
      });
      setLastNotification(Date.now());
    }, delay * 1000);

    redirectTimeout.current && clearTimeout(redirectTimeout.current);
    redirectTimeout.current = setTimeout(() => {
      window.location.replace(
        `/login?timeout=1&redirect=${encodeURIComponent(
          window.location.pathname
        )}`
      );
    }, (idleTimeleft.value + 2) * 1000);

    return () => {
      timeout.current && clearTimeout(timeout.current);
    };

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [idleTimeleft]);
};
