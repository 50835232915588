import cs from 'classnames';
import { useSSE, SSEProvider } from 'react-hooks-sse';

import * as api from '../../../../../../serverApi';
import { WifiStrength } from '../../../../../manage/sensors/ui/WifiStrength';

interface Props {
  sensor: { sensor_id: number | string };
  markingObjects?: api.MarkingObject[];
  className?: string;
}

interface ConsumerProps {
  rssi: number;
  ssid: string;
}
function Consumer() {
  const sse = useSSE<ConsumerProps[]>('rssi', []);

  const wifiStrength = sse?.[0]?.rssi;
  const wifiSSID = sse?.[0]?.ssid;
  return (
    <div>
      <WifiStrength wifiStrength={wifiStrength} />
      {wifiSSID && (
        <span className="h6 mt-1 mb-0" style={{ fontSize: '14px' }}>
          {wifiSSID}
        </span>
      )}
    </div>
  );
}

function createEventSource(sensorId: number | string): EventSource {
  const rootURL = api.getApiRoot();
  const endpoint = `${rootURL}/api/stream/wireless/rssi?sensor_ids=${sensorId}`;
  const source = new EventSource(endpoint, { withCredentials: true });
  return source;
}

export function SensorWirelessStatus(props: Props) {
  const sensor = props.sensor;
  return (
    <div className={cs('SensorWirelessStatus', props.className)}>
      <SSEProvider source={() => createEventSource(sensor.sensor_id)}>
        <Consumer />
      </SSEProvider>
    </div>
  );
}
