import React, { useRef } from 'react';
import { TextField, FormGroup } from '@material-ui/core';
import ModalWrapper from '../ModalWrapper';
import { StructureZone, createZone } from '../../../serverApi';
import { RouteLeavingGuard } from '../../RouteLeavingGuard';
import { useHandleCancel } from '../../../lib/useHandleCancel';
import {
  errorIsObjectError,
  useErrorHandler,
} from '../../../lib/useErrorHandler';
import { ErrorMessages } from '../../ErrorMessages';
import { useZoneFormik } from './useZoneFormik';
import { t } from '../../../lib/i18n';
import { generateAutoCompleteValue } from '../../../lib/utils/generateAutoCompleteValue';

export interface CreateZoneProps {
  isOpen: boolean;
  housingUnit?: boolean;
  activeZone: StructureZone;
  handleModal: (property: string) => void;
  updateTree: () => void;
}

export default function CreateZoneModal(props: CreateZoneProps) {
  const inputRef = useRef<HTMLDivElement | null>(null);

  const hc = useHandleCancel();
  const errorHandler = useErrorHandler();

  const formik = useZoneFormik({
    initialValues: {
      name: '',
    },
    onSubmit: async (values, helpers) => {
      const parentId = props.activeZone.id;

      if (!parentId || props.activeZone.api_lock) {
        return;
      }

      try {
        await createZone({
          name: values.name,
          parent_zone_id: parentId,
          is_housing_unit: props.housingUnit,
        });
        helpers.resetForm();
        props.updateTree();
        props.handleModal('createModal');
      } catch (error) {
        errorHandler.handleError(error);
      }
    },
  });

  const handleOnOpened = () => {
    if (inputRef !== null && inputRef.current) {
      inputRef.current.focus();
    }
  };

  const handleOnHide = (name: string) => {
    if (name === '') {
      props.handleModal('createModal');
    } else {
      hc.handleCancel(true, null, () => {
        props.handleModal('createModal');
      });
    }
  };

  const handleOnReset = () => {
    errorHandler.reset();
    formik.resetForm();
  };

  const getNameError = () => {
    const errors = formik.errors;
    const touched = formik.touched;

    if (!errorHandler.error && !errors) {
      return undefined;
    }

    if (touched['name'] && errors['name']) {
      return errors['name'];
    }

    return (
      (errorIsObjectError(errorHandler.error) && errorHandler.error.name) ||
      undefined
    );
  };

  const error = getNameError();

  return (
    <ModalWrapper
      className="CreateZoneModal"
      title={
        props.housingUnit
          ? t('components.CreateZoneModal.createNewHousingUnit')
          : t('components.CreateZoneModal.createNewZone')
      }
      okButtonText={t('common.commonButtons.create')}
      cancelButtonText={t('common.commonButtons.cancel')}
      isOpen={props.isOpen}
      loading={formik.isSubmitting}
      onHide={() => handleOnHide(formik.values.name)}
      onResetModal={handleOnReset}
      onOpened={handleOnOpened}
      onSubmit={formik.submitForm}
    >
      <RouteLeavingGuard when={formik.dirty} />
      <div className="name-wrapper">
        <form onSubmit={formik.submitForm}>
          <FormGroup>
            <TextField
              autoComplete={generateAutoCompleteValue()}
              type="text"
              name="name"
              label={t('common.commonInputs.name')}
              inputRef={inputRef}
              value={formik.values.name}
              error={!!error}
              helperText={error}
              onKeyPress={(e) => {
                if (e.key === 'Enter') {
                  e.preventDefault();
                  e.stopPropagation();
                  formik.submitForm();
                }
              }}
              onBlur={formik.handleBlur}
              onChange={formik.handleChange}
            />
          </FormGroup>
          <ErrorMessages className="my-2" errorData={errorHandler} />
        </form>
      </div>
    </ModalWrapper>
  );
}
