import React from 'react';
import './BorderWrapper.scss';

interface Props {
  children: React.ReactNode;
}

export const BorderWrapper = (props: Props) => {
  return <div className="BorderWrapper">{props.children}</div>;
};
