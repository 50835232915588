import React from 'react';
import { FormControlLabel, RadioGroup, Radio } from '@material-ui/core';
import useZoneSettingsForm from './lib/useZoneSettingsForm';
import { Panel } from '../../../../../components/Panel';
import { useZoneContext } from '../../../../../lib/ZoneContext';
import { FormBody } from './lib/FormBody';
import * as api from '../../../../../serverApi';
import { t } from '../../../../../lib/i18n';

const fields: Array<keyof api.ZoneSettings> = ['observation_stream_timeout'];

export function ObservationTimeout() {
  const ctx = useZoneContext();
  const form = useZoneSettingsForm({ fields });

  function onChange(event: React.ChangeEvent<HTMLInputElement>, value: string) {
    const n = parseInt(value, 10);
    form.formik.setFieldValue('observation_stream_timeout', n);
    form.formik.setFieldTouched('observation_stream_timeout');
  }

  return (
    <div>
      <h2>
        {t('manage.zone.ObservationTimeout.headingFor')} {ctx.activeZone.name}
      </h2>

      <Panel>
        <strong>{t('common.commonTexts.tips')}:</strong>{' '}
        {t(
          'manage.zone.settings.common.theSettingsFromTheParentZoneAreShownInGrayWhenThisIsSelected'
        )}
      </Panel>

      <FormBody zoneSettingsForm={form}>
        <RadioGroup
          name="observation_stream_timeout"
          value={`${form.displayValues?.observation_stream_timeout ?? ''}`}
          onChange={onChange}
        >
          <FormControlLabel
            disabled={form.disabled}
            value="0"
            control={<Radio color="primary" />}
            label={t('manage.zone.settings.common.noTimeLimit')}
          />

          <FormControlLabel
            disabled={form.disabled}
            value="60"
            control={<Radio color="primary" />}
            label={`1 ${t('common.minutes', 1)}`}
          />

          <FormControlLabel
            disabled={form.disabled}
            value="300"
            control={<Radio color="primary" />}
            label={`5 ${t('common.minutes', 5)}`}
          />
        </RadioGroup>
      </FormBody>
    </div>
  );
}
