import * as api from '../serverApi';

/**
 * Return api locked zone ID-s.
 * @param zone - root zone structure.
 */
const getApiLockedZoneIds = (zone?: api.StructureZone): number[] => {
  let response = [] as number[];

  if (!zone) {
    return [] as number[];
  }

  if (zone.hasOwnProperty('api_lock') && zone.api_lock) {
    response = response.concat(zone.id);
  }

  if (zone.zones && zone.zones.length > 0) {
    zone.zones &&
      zone.zones.forEach((zone: api.StructureZone) => {
        response = response.concat(getApiLockedZoneIds(zone));
      });
  }

  return response;
};

export default getApiLockedZoneIds;
