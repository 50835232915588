import React from 'react';
import ModalWrapper from '../ModalWrapper';
import { IconLegendHelp } from '../../../areas/care/components/IconLegendHelp';
import { t } from '../../../lib/i18n';
import './HelpModal.scss';

export interface Props {
  isOpen: boolean;
  onClose: () => void;
}

export default function HelpModal(props: Props) {
  function handleToggle() {
    props.onClose();
  }
  return (
    <ModalWrapper
      isOpen={props.isOpen}
      onHide={handleToggle}
      className="HelpModal"
      showCancelButton={false}
      showOkButton={false}
      title={t('components.HelpModal.symbolExplanations')}
    >
      <div className="HelpModal-body">
        <IconLegendHelp hideTitle />
      </div>
    </ModalWrapper>
  );
}
