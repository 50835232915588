import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import * as Sentry from '@sentry/browser';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faEye,
  faTrash,
  faPlus,
  faSyncAlt,
  faSpinnerThird,
} from '@fortawesome/pro-solid-svg-icons';
import { useConfirmation } from '../../../lib/confirmation/ConfirmationContext';
import * as serverApi from '../../../serverApi';
import TableCell from '@material-ui/core/TableCell';
import { useSuperStyles } from '../useSuperStyles';
import { Button } from '@material-ui/core';
import { HeadingBar } from '../shared/HeadingBar';
import { Card } from '../shared/Card';
import { faSpinner } from '@fortawesome/pro-light-svg-icons';
import { t } from '../../../lib/i18n';
import useFilters from '../shared/filters/useFilters';
import { FiltersWrapper } from '../shared/filters/FiltersWrapper';
import { AccountFilter } from '../shared/filters/AccountFilter';
import { GlobalRoleFilter } from '../shared/filters/GlobalRoleFilter';
import { VendorFilter } from '../shared/filters/VendorFilter';
import { ImpersonateButton } from './ui/ImpersonateButton';
import { Table } from '../shared/Table';
import { UsePagination } from '../../../hooks/usePagination';
import { Pagination } from '../../../components/Pagination/Pagination';
import { transformSortStringToObject } from '../../../lib/transformSortStringToObject';

interface Props {}

const columns = [
  { key: 'user_id', header: 'ID', sortable: true },
  { key: 'account', header: 'Account' },
  { key: 'username', header: 'Username', sortable: true },
  { key: 'display_name', header: 'Name', sortable: true },
  { key: 'email', header: 'Email' },
  { key: 'global_roles', header: 'Global roles' },
  { key: 'actions', header: 'Actions' },
];

export const ListUsers: React.FC<Props> = () => {
  const confirmation = useConfirmation();
  const c = useSuperStyles();

  const [error, setError] = useState('');
  const [deleting, setDeleting] = useState<string | number | null>(null);

  const filters = useFilters<serverApi.SuperGetUsersInput>({
    initialValues: { page: 1, page_size: 100, sort: 'user_id asc' },
    key: 'super-users',
  });

  const res = serverApi.useSuperGetUsersQuery(filters.values, {
    keepPreviousData: true,
  });
  const users = (res.data && res.data.data) || [];

  const handleDeleteUser = async (userId: number) => {
    confirmation
      .confirm(t('common.commonTexts.toConfirmDeletionPressOk'))
      .then(async () => {
        try {
          error && setError('');
          setDeleting(userId);
          await serverApi.superDeleteUser(userId);
          setDeleting(null);
          res.refetch();
        } catch (error) {
          Sentry.captureException(error);
          setError(String(error));
          setDeleting(null);
        }
      })
      .catch(() => {});
  };

  const rows = users.map((user) => {
    const globalRoles = user.user_roles?.filter((x) => x.is_global);
    const isDeleting = deleting === user.user_id;

    return {
      id: user.user_id,
      user_id: (
        <TableCell component="th" scope="row">
          <Link to={`/super/users/${user.user_id}`}>{user.user_id}</Link>
        </TableCell>
      ),
      account: (
        <TableCell>
          <code>{user.account.account_code}</code>
        </TableCell>
      ),
      username: <TableCell>{user.username}</TableCell>,
      display_name: (
        <TableCell>
          {user.impersonal_user ? (
            user.display_name
          ) : (
            <>
              {user.first_name} {user.last_name}
            </>
          )}
        </TableCell>
      ),
      email: <TableCell>{user.email}</TableCell>,
      global_roles: (
        <TableCell>{globalRoles?.map((x) => x.role)?.join(',')}</TableCell>
      ),
      actions: (
        <TableCell>
          <Button component={Link} to={`/super/users/${user.user_id}`}>
            <FontAwesomeIcon icon={faEye} className="mr-2" /> View
          </Button>

          <Button
            disabled={isDeleting}
            onClick={() => handleDeleteUser(user.user_id)}
            className="ml-2"
            color="secondary"
          >
            <FontAwesomeIcon
              spin={isDeleting}
              icon={isDeleting ? faSpinner : faTrash}
              className="mr-2"
            />{' '}
            Remove
          </Button>

          <ImpersonateButton userId={user.user_id} className="ml-2" />
        </TableCell>
      ),
    };
  });

  const handleSortClick = (columnKey: string) => {
    const currentSort = transformSortStringToObject(filters.values.sort);

    /**
     * We sort by ascending order if there's no previous sorting column or we're switching the
     * column that's being sorted
     */
    if (!currentSort || currentSort.key !== columnKey) {
      filters.setFilter('sort', `${columnKey} asc`);
      return;
    }

    /**
     * Otherwise, just switch the sorting order
     */
    if (currentSort?.key === columnKey) {
      const newSortOrder = currentSort.type === 'asc' ? 'desc' : 'asc';

      filters.setFilter('sort', `${columnKey} ${newSortOrder}`);
    }
  };

  const handleUserFiltersChange = (data: serverApi.SuperGetUsersInput) => {
    filters.setFilters({ ...filters.values, ...data });
    paginationData.reset();
  };

  const paginationData: UsePagination = {
    data: users,
    currentPage: filters.values.page || 1,
    itemsPerPage: filters.values.page_size || 100,
    totalPages: Array.from({ length: res.data?.last_page || 1 }),
    setCurrentPage(page: number | ((page: number) => number)) {
      filters.setFilter('page', page);
    },
    setItemsPerPage(itemsPerPage: number | ((itemsPerPage: number) => number)) {
      filters.setFilter('page_size', itemsPerPage);
    },
    reset() {
      filters.setFilters({
        page: 1,
        page_size: filters.values.page_size || 100,
      });
    },
  };

  return (
    <>
      <HeadingBar>
        <h2>Users</h2>

        <div
          style={{
            height: '25px',
            width: '25px',
            fontSize: '25px',
            lineHeight: '25px',
          }}
        >
          {res.isLoading && (
            <FontAwesomeIcon icon={faSpinnerThird} spin={true} />
          )}
        </div>

        <Button onClick={() => res.refetch()} disabled={res.isLoading}>
          <FontAwesomeIcon
            icon={faSyncAlt}
            spin={res.isLoading}
            className="mr-2"
          />
          Refresh
        </Button>

        <Button component={Link} to="/super/users/create">
          <FontAwesomeIcon icon={faPlus} className="mr-2" />
          Create user
        </Button>
      </HeadingBar>

      <Card>
        {res.error && <div className="text-danger my-4 p-5">{error}</div>}

        <FiltersWrapper onReset={filters.reset}>
          <AccountFilter
            className="FiltersWrapper-item"
            disabled={res.isLoading}
            value={filters.values.account_id}
            onChange={(account) =>
              handleUserFiltersChange({ account_id: account?.account_id })
            }
          />

          <VendorFilter
            className="FiltersWrapper-item"
            disabled={res.isLoading}
            value={filters.values.vendor_account_id}
            onChange={(vendor) =>
              handleUserFiltersChange({ vendor_account_id: vendor?.account_id })
            }
          />

          <GlobalRoleFilter
            className="FiltersWrapper-item"
            disabled={res.isLoading}
            value={filters.values.roles}
            onChange={(selectedRoles) => {
              handleUserFiltersChange({
                roles: selectedRoles
                  ? selectedRoles?.map((x) => x.value)
                  : undefined,
              });
            }}
          />
        </FiltersWrapper>
        <Pagination loading={res.isLoading} pagination={paginationData} />

        <Table
          className={c.table}
          columns={columns}
          rows={rows}
          loading={res.isLoading}
          sortColumn={transformSortStringToObject(filters.values.sort)}
          onSortClick={handleSortClick}
        />
      </Card>
    </>
  );
};
