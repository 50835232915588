/**
 *
 *
 *
 * This file should be the primary definition of how sensor types are defined in the frontend.
 *
 * All sensor types and event types need to be present here, otherwise TypeScript will
 * give a compile-time error. It must match the sensor type structure generated in serverApi.ts.
 *
 *
 *
 */

import { IconDefinition } from './components/Icon';

import { SensorType } from './serverApi';

export type SensorTypeDefinition<S extends string> = {
  sensorType: S;
  icon: IconDefinition;
};

export const sensorTypes: {
  [key in SensorType]: SensorTypeDefinition<key>;
} = {
  roommate: {
    sensorType: 'roommate',
    icon: 'roommate-sensor',
  },
  climax_door_contact: { sensorType: 'climax_door_contact', icon: 'door-open' },
  climax_geo_pendant: {
    sensorType: 'climax_geo_pendant',
    icon: 'wrist-button',
  },
  climax_heat_detector: { sensorType: 'climax_heat_detector', icon: 'fire' },
  climax_motion_detector: {
    sensorType: 'climax_motion_detector',
    icon: 'activity',
  },
  climax_pull_cord: { sensorType: 'climax_pull_cord', icon: 'pull-cord' },
  climax_push_button: {
    sensorType: 'climax_push_button',
    icon: 'panic-button',
  },
  climax_smoke_detector: { sensorType: 'climax_smoke_detector', icon: 'fire' },
  climax_water_detector: { sensorType: 'climax_water_detector', icon: 'water' },
  climax_wrist_button: {
    sensorType: 'climax_wrist_button',
    icon: 'wrist-button',
  },
};
