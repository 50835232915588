import * as api from '../../../../serverApi';

import { SearchResultUser } from './SearchResultUser';
import { SearchResultSensor } from './SearchResultSensor';
import { SearchResultAccount } from './SearchResultAccount';

import './SearchResult.scss';

export type SearchResultSingleType<T = {}> = {
  entry: T;
};

interface SearchResultProps {
  item: api.SuperSearchResults['results'][0];
}

export function SearchResult(props: SearchResultProps) {
  switch (props.item.type) {
    case 'user':
      return <SearchResultUser entry={props.item.entry} />;

    case 'account':
      return <SearchResultAccount entry={props.item.entry} />;

    case 'sensor':
      return <SearchResultSensor entry={props.item.entry} />;

    default:
      return (
        <div className="SearchResult" style={{ padding: '16px' }}>
          Unknown result type
        </div>
      );
  }
}
