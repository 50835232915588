import { getPausedSensorRanges } from './helpers/getPausedSensorRanges';
import PauseIcon from '../../../images/icons/roommate-off.svg';

interface Props {
  values: { startDate: Date; streak: number }[];
  margin: { top: number; right: number; bottom: number; left: number };
  xScale: d3.ScaleTime<number, number, never>;
  width: number;
  height: number;
  samplePeriodS: number;
}

const PAUSE_ICON_SIZE = 62;

export const PausedSensorOverlay = ({
  values = [],
  width,
  height,
  margin,
  xScale,
  samplePeriodS,
}: Props) => {
  const ranges = getPausedSensorRanges({
    streaks: values,
    scaleFunction: xScale,
    samplePeriodS,
  });

  /**
   * Avoid rendering paused sensor overlay if width is not calculated properly yet
   */
  if (width <= 1) {
    return null;
  }

  return (
    <svg className="BehavioralDataChartOverlay">
      <clipPath id="overlay-clip-path">
        <rect
          x={margin.left}
          y={margin.top}
          width={width - margin.left}
          height={height - margin.top - 25} // Height calculated by chart wrapping div height and removing top margin and x-axis height
        />
      </clipPath>
      {ranges.map((range, idx) => {
        const rangeWidth = range.endX - range.startX;
        const imageX = range.startX + (rangeWidth - PAUSE_ICON_SIZE) / 2;
        return (
          <g key={idx} clipPath="url(#overlay-clip-path)">
            <rect
              width={rangeWidth}
              height={height}
              x={range.startX}
              y={margin.top}
              fill="rgba(132, 185, 60, 0.6)"
            />
            <image
              href={PauseIcon}
              x={imageX}
              y={margin.top}
              width={PAUSE_ICON_SIZE}
              height={PAUSE_ICON_SIZE}
            />
          </g>
        );
      })}
    </svg>
  );
};
