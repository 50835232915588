import React, { useState } from 'react';
import { deleteZone } from '../../../serverApi';
import { useConfirmation } from '../../../lib/confirmation/ConfirmationContext';
import { useZoneContext } from '../../../lib/ZoneContext';
import { useErrorHandler } from '../../../lib/useErrorHandler';
import { useRouter } from '../../../lib/useRouter';
import { IconButton } from '../../../components/IconButton';
import { ErrorMessages } from '../../../components/ErrorMessages';
import { useZoneTreeContext } from '../../care/lib/ZoneTreeContext';
import { Panel } from '../../../components/Panel';
import { t } from '../../../lib/i18n';

export const DeleteZoneButton = () => {
  const [deleting, setDeleting] = useState(false);

  const router = useRouter();
  const ctx = useZoneContext();
  const treeCtx = useZoneTreeContext();
  const confirmation = useConfirmation();
  const errorHandler = useErrorHandler();

  const parentZone = treeCtx.getZoneById(ctx.activeZone.parent?.id);

  const disabled =
    deleting ||
    ctx.activeZone.has_user_roles ||
    (!!ctx.activeZone.zones && ctx.activeZone.zones.length > 0) ||
    !!ctx.activeZone.care_receiver ||
    !!ctx.activeZone.sensors ||
    ctx.activeZone.api_lock;

  const handleDeleteZone = async () => {
    confirmation
      .confirm(t('common.commonTexts.toConfirmDeletionPressOk'))
      .then(async () => {
        try {
          setDeleting(true);
          await deleteZone(ctx.activeZone.id);
          setDeleting(false);
          ctx.reload();
          if (parentZone?.id) {
            router.history.push(`/manage/${parentZone.id}`);
          }
        } catch (error) {
          setDeleting(false);
          errorHandler.handleError(error);
        }
      })
      .catch(() => {});
  };

  return (
    <>
      <IconButton
        disabled={disabled}
        onClick={handleDeleteZone}
        color="secondary"
        icon={deleting ? 'gear' : 'trash'}
        spinIcon={deleting}
      >
        {ctx.activeZone.is_housing_unit
          ? t('manage.zone.DeleteZoneButton.deleteThisHousingUnit')
          : t('manage.zone.DeleteZoneButton.deleteThisZone')}
      </IconButton>

      <ErrorMessages tag="span" className="m-2" errorData={errorHandler} />

      {disabled && (
        <Panel className="ml-5">
          <strong>{t('common.commonTexts.tips')}:</strong>{' '}
          {t('manage.zone.DeleteZoneButton._deleteZoneTips')}.
        </Panel>
      )}
    </>
  );
};
