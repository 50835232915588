import React, { useContext } from 'react';

import * as api from '../serverApi';

export interface OptionalZoneContext {
  rootZone: undefined | api.StructureZone;
  activeZone: undefined | api.StructureZone;
  reload: () => void;
}

export interface ZoneContext {
  rootZone: api.StructureZone;
  activeZone: api.StructureZone;
  activeZoneSettings: api.GetSensorsResult | undefined;
  setActiveZoneSettings: React.Dispatch<
    React.SetStateAction<api.GetSensorsResult | undefined>
  >;
  reload: () => void;
}

const zoneContext = React.createContext<OptionalZoneContext>({
  rootZone: undefined,
  activeZone: undefined,
  reload: () => {},
});

const { Consumer, Provider } = zoneContext;

export const useOptionalZoneContext = () => useContext(zoneContext);

export function useZoneContext(): ZoneContext {
  const context = useContext(zoneContext);
  if (!context.activeZone || !context.rootZone) {
    throw new Error(
      'Invalid zone context -- required root zone and active zone'
    );
  }
  return context as ZoneContext;
}

export { Consumer, Provider as ZoneContextProvider };
