import React from 'react';
import { useCareContext } from '../areas/care/lib/CareContext';
import { Table, TableBody, TableCell, TableRow } from '@material-ui/core';
import cs from 'classnames';

import './SensorSelector.scss';
import groupSensorsByZone, { ReducedZone } from '../lib/groupSensorsByZone';

interface Props {
  selected?: ReducedZone;
  onSelected(id: ReducedZone): void;
}

export function SensorSelector(props: Props) {
  const care = useCareContext();

  return (
    <div className="SensorSelector">
      <Table className={cs('SensorSelector-list')}>
        <TableBody>
          {care.sensors &&
            groupSensorsByZone(care.sensors).map((sensor) => {
              return (
                <TableRow
                  key={sensor.zone_id}
                  className={
                    props.selected &&
                    (sensor.zone_id === props.selected.zone_id
                      ? 'SensorSelector-selected'
                      : undefined)
                  }
                >
                  <TableCell component="th" scope="row">
                    <div
                      role="button"
                      className="SensorSelector-list-item"
                      onClick={() => props.onSelected(sensor)}
                    >
                      {sensor.parent_zone?.name}
                      <br />
                      {sensor.zone_name}
                    </div>
                  </TableCell>
                </TableRow>
              );
            })}
        </TableBody>
      </Table>
    </div>
  );
}
