import React from 'react';
import { t } from '../../lib/i18n';
import { InlineSpinner } from '../InlineSpinner';

import './FullScreenLoader.scss';

interface Props {
  text?: string;
  children?: string;
}

export const FullScreenLoader = (props: Props) => {
  return (
    <div className="FullScreenLoader">
      {props.children || (
        <InlineSpinner
          size="md"
          text={props.text || t('common.commonTexts.loading')}
          textStyle={{ fontWeight: 500, fontSize: 22 }}
        />
      )}
    </div>
  );
};
