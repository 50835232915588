import React, { useState } from 'react';
import { useZoneContext } from '../../../lib/ZoneContext';
import MoveZoneModal from '../../../components/modals/zones/MoveZoneModal';
import { IconButton } from '../../../components/IconButton';
import { t } from '../../../lib/i18n';

export const MoveZoneButton = () => {
  const [isOpen, setIsOpen] = useState(false);

  const ctx = useZoneContext();

  const update = () => ctx.reload();

  const handleModal = () => setIsOpen(!isOpen);

  return (
    <>
      <IconButton
        disabled={ctx.activeZone.api_lock}
        icon="map-marker"
        color="primary"
        onClick={handleModal}
      >
        {ctx.activeZone.is_housing_unit
          ? t('manage.zone.MoveZoneButton.moveHousingUnitToAnotherLocation')
          : t('manage.zone.MoveZoneButton.moveZoneToAnotherLocation')}
      </IconButton>

      <MoveZoneModal
        isOpen={isOpen}
        activeZone={ctx.activeZone}
        handleModal={handleModal}
        updateTree={update}
      />
    </>
  );
};
