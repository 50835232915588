import React from 'react';

import useEventSettingsForm, { EventFormProps } from '../useEventSettingsForm';

import EventActiveControl from '../controls/EventActiveControl';
import EventLevelControl from '../controls/EventLevelControl';
import EventTimeIntervalControl from '../controls/EventTimeIntervalControl';
import EventFormControls from '../controls/EventFormControls';
import EventSelectControl from '../controls/EventSelectControl';
import PlayEventSoundControl from '../controls/PlayEventSoundControl';
import { RouteLeavingGuard } from '../../../../../components/RouteLeavingGuard';
import EventNumberControl from '../controls/EventNumberControl';
import { Panel } from '../../../../../components/Panel';
import { getLabelForEventType } from '../../../../../lib/getLabelForEventType';
import { t } from '../../../../../lib/i18n';
import { MissingMarkingWarningPanel } from '../controls/MissingMarkingWarningPanel';

export default function AroseInBedEventForm(props: EventFormProps) {
  const form = useEventSettingsForm({
    sensorType: 'roommate',
    eventType: 'hib',

    initialValues: props.initialValues,
    scales: {
      threshold1: 10,
    },
  });

  return (
    <form onSubmit={form.handleSubmit}>
      <RouteLeavingGuard when={form.dirty} />

      <h2>{getLabelForEventType('hib', 'roommate')}</h2>

      <MissingMarkingWarningPanel
        onlyWarnWhenMissing={true}
        markingType="bed"
      />

      <Panel>
        <span
          dangerouslySetInnerHTML={{
            __html: t('manage.sensors.events.AroseInBedEventForm.firstInfo'),
          }}
        />
      </Panel>

      <EventActiveControl form={form} />

      <EventTimeIntervalControl form={form} />

      <EventNumberControl
        form={form}
        label={t(
          'manage.sensors.events.AroseInBedEventForm.heightThresholdInCm'
        )}
        field="threshold1"
      />

      <EventSelectControl
        form={form}
        label={t('manage.sensors.common.quarantineTimeInMinutes')}
        field="quarantine1"
        options={[
          { label: '1 min', value: 1 * 60 },
          { label: '2 min', value: 2 * 60 },
          { label: '5 min', value: 5 * 60 },
          { label: '10 min', value: 10 * 60 },
          { label: '15 min', value: 15 * 60 },
          { label: '30 min', value: 30 * 60 },
        ]}
      />

      <EventLevelControl form={form} />

      <PlayEventSoundControl form={form} />

      <EventFormControls form={form} />
    </form>
  );
}
