import { createTheme } from '@material-ui/core';

export const SuperAreaTheme = createTheme({
  palette: {
    primary: {
      main: '#84b93c',
      light: '#d1eda9',
    },
    secondary: {
      main: 'rgb(253,164,59)',
      light: 'rgb(239,186,105)',
    },
  },
  props: {
    MuiButton: {
      variant: 'contained',
    },
  },
  overrides: {
    MuiTab: {
      root: {
        textTransform: 'none',
      },
    },
    MuiButton: {
      root: {
        textTransform: 'none',
      },
    },
  },
});
