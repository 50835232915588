import { FormikHelpers, useFormik } from 'formik';
import * as yup from 'yup';

export interface AccountFields {
  name: string;
  account_code: string;
  default_user_locale: string;
  disable_all_detailed_images: boolean;
  require_national_id_for_care_receivers: boolean;
  disable_national_id_numbers: boolean;
  is_vendor: boolean;
  enable_sso: boolean;
}

export type AccountFormik = ReturnType<typeof useAccountFormik>;

interface UseAccountFormOptions {
  initialValues?: Partial<AccountFields>;
  validationSchema?: any | (() => any);
  onSubmit: (
    values: AccountFields,
    formikHelpers: FormikHelpers<AccountFields>
  ) => Promise<any>;
}

/**
 * Formik wrapper for super account form.
 */
export function useAccountFormik(opts: UseAccountFormOptions) {
  return useFormik<AccountFields>({
    initialValues: {
      name: '',
      account_code: '',
      default_user_locale: 'nb',
      disable_all_detailed_images: false,
      disable_national_id_numbers: false,
      require_national_id_for_care_receivers: false,
      is_vendor: false,
      enable_sso: false,
      ...opts.initialValues,
    },
    validateOnChange: true,
    validateOnBlur: true,
    validationSchema:
      opts.validationSchema ??
      (() =>
        yup.object().shape({
          name: yup.string().required(),
          account_code: yup.string().required(),
        })),
    onSubmit: async (values, formikHelpers) => {
      await opts.onSubmit(values, formikHelpers);
    },
  });
}
