import React from 'react';
import cs from 'classnames';

import { Tab as MuiTab, Tabs as MuiTabs /*Tooltip*/ } from '@material-ui/core';

import './Tabs.scss';
import { IconDefinition, Icon } from '../../components/Icon';

interface Tab {
  key: string;
  label: string;
  hideLabel?: boolean;
  icon?: IconDefinition;
  position?: string;
}

interface Props {
  tabs: Tab[];
  activeTab?: string;
  onSetActiveTab: (activeTab?: string) => void;
  className?: string;
}

export const Tabs = (props: Props) => (
  <MuiTabs
    aria-label="Tabs"
    className={cs('Tabs', props.className)}
    variant="scrollable"
    scrollButtons="auto"
    TabIndicatorProps={{ style: { backgroundColor: 'transparent' } }}
    value={props.activeTab}
    onChange={(e, value) => props.onSetActiveTab(value)}
  >
    {props.tabs.map((tab: Tab) => (
      <MuiTab
        id={`tab-${tab.key}`}
        key={tab.key}
        value={tab.key}
        data-cy={tab.key}
        label={
          <span>
            {/*<Tooltip title={tab.label} aria-label={tab.label} key={tab.key}>*/}
            <span>
              {tab.icon && <Icon className="Tab-icon" icon={tab.icon} />}

              {!tab.hideLabel && <span className="mr-2" />}

              <span className={`Tab--label ${tab.hideLabel ? 'sr-only' : ''}`}>
                {tab.label}
              </span>
            </span>
            {/*</Tooltip>*/}
          </span>
        }
        className={cs('Tab', tab.position === 'right' ? 'Tab--right' : '')}
      />
    ))}
  </MuiTabs>
);
