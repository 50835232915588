import React from 'react';
import * as api from '../../../../serverApi';
import Select from 'react-select';
import { useApiCall } from '../../../../lib/useApiCall';
import cn from 'classnames';

const reactSelectStyles = {
  menu: (provided: any) => ({
    ...provided,
    zIndex: 100,
  }),

  container: (provided: any) => ({
    ...provided,
    width: '100%',
    maxWidth: 300,
    margin: '10px 20px 10px 0',
  }),
};

interface Props {
  disabled?: boolean;
  value?: string[];
  className?: string;
  onChange: (
    selectedRoles: Array<{
      label: string;
      value: string;
    }> | null
  ) => void;
}

export const GlobalRoleFilter = (props: Props) => {
  const roles = useApiCall(api.superGetAllRoles);

  const globalRoleOptions =
    roles?.data
      ?.filter((x) => x.is_global)
      ?.map((x) => ({ value: x.role, label: x.name })) ?? [];

  const selectedItems = globalRoleOptions.filter((x) =>
    props.value?.includes(x.value)
  );

  return (
    <Select
      isSearchable
      isClearable
      isMulti
      styles={reactSelectStyles}
      className={cn('GlobalRoleFilter', props.className)}
      isDisabled={roles.loading || props.disabled}
      isLoading={roles.loading}
      placeholder="Select global role"
      options={globalRoleOptions}
      value={selectedItems as any}
      onChange={(s: any) => {
        props.onChange(s);
      }}
    />
  );
};
