import React, { useState } from 'react';
import {
  CareReceiverBlockedUser,
  useCareReceiverFormik,
} from '../lib/useCareReceiverFormik';
import { CareReceiverForm } from '../CareReceiverForm';
import isValidNorwegianId from '../../../../lib/isValidNorwegianId';
import { useRouter } from '../../../../lib/useRouter';
import { useZoneContext } from '../../../../lib/ZoneContext';
import {
  errorIsString,
  useErrorHandler,
} from '../../../../lib/useErrorHandler';
import { useZoneTreeContext } from '../../../care/lib/ZoneTreeContext';
import { SearchCareReceiverType } from '../search/SearchCareReceiver';
import { toast } from 'react-toastify';
import { t } from '../../../../lib/i18n';

import * as yup from 'yup';
import * as api from '../../../../serverApi';
import { useAccount } from '../../../../lib/useAccount';
import { isValidationError } from '../../../../lib/utils/errorUtils';

interface Props {
  initialZoneId?: number;
  onCreate?: () => void;
}

export function CreateCareReceiver(props: Props) {
  const router = useRouter();
  const ctx = useZoneContext();
  const zoneTreeCtx = useZoneTreeContext();
  const errorHandler = useErrorHandler();
  const account = useAccount();

  const [careReceiver, setCareReceiver] =
    useState<SearchCareReceiverType>(null);

  const initialZone = props.initialZoneId
    ? zoneTreeCtx.getZoneById(props.initialZoneId)
    : undefined;

  const [selectedZone, setSelectedZone] = useState<
    api.StructureZone | undefined
  >(initialZone);

  const onCareReceiverAutofill = (careReceiver: SearchCareReceiverType) => {
    setCareReceiver(careReceiver);
  };

  const handleSetSelectedZone = (zoneId?: number) => {
    const zone = zoneTreeCtx.getZoneById(zoneId);
    setSelectedZone(zone);
  };

  const formik = useCareReceiverFormik({
    initialValues: {
      national_id_number: '',
      first_name: '',
      last_name: '',
      zone_id: selectedZone?.id ?? null,
      blocked_users: [],
      nationalIdRequired:
        !account.disable_national_id_numbers &&
        account.require_national_id_for_care_receivers,
    },
    validationSchema: () =>
      yup.object().shape({
        national_id_number: yup
          .string()
          .when('nationalIdRequired', {
            is: true,
            then: yup
              .string()
              .required(t('common.commonTexts.birthNumberorDnumberIsRequired')),
            otherwise: yup.string(),
          })
          .test(
            'national id number',
            t('common.commonTexts.invalidBirthNumber'),
            (x) =>
              account.disable_national_id_numbers || isValidNorwegianId(x) || !x
          ),
        first_name: yup.string().nullable(),
        last_name: yup.string().nullable(),
        zone_id: yup.number().integer().nullable(),
      }),
    onSubmit: async (values, formikHelpers) => {
      try {
        if (careReceiver) {
          if (!careReceiver.zone && !values.zone_id) {
            formikHelpers.setFieldError(
              'zone_id',
              t('manage.careReceivers.add.CreateCareReceiver.zoneIsRequired')
            );
            return;
          }

          await api.updateCareReceiver(careReceiver.care_receiver_id, {
            national_id_number: values.national_id_number,
            first_name: values.first_name,
            last_name: values.last_name,
            zone_id: values.zone_id,
            blocked_user_ids: values.blocked_users.map(
              (bu: CareReceiverBlockedUser) => bu.blocked_user_id
            ),
          });
          setCareReceiver(null);
        } else {
          //If national id is provided append it to the payload, otherwise don't!
          await api.addCareReceiver({
            first_name: values.first_name,
            last_name: values.last_name,
            zone_id: values.zone_id,
            blocked_user_ids: values.blocked_users.map(
              (bu: CareReceiverBlockedUser) => bu.blocked_user_id
            ),
            ...(values.national_id_number
              ? { national_id_number: values.national_id_number }
              : {}),
          });
        }

        toast.success(
          t(
            'manage.careReceivers.add.CreateCareReceiver.serviceRecipientWasSaved'
          )
        );
        formikHelpers.resetForm(values);
        if (props.onCreate) {
          props.onCreate();
        }
        router.history.push(
          `/manage/${values.zone_id ?? ctx.activeZone.id}/care-receivers`
        );
      } catch (e) {
        if (isValidationError(e)) {
          /**
           * We check if `national_id_number` field exists in the errors object
           */
          let hasNINError = false;
          Object.entries(e.response.data.errors).forEach(([key, value]) => {
            value.forEach((v) => {
              if (key === 'national_id_number' && v === 'validation.required') {
                hasNINError = true;
              }
            });
          });

          /**
           * If yes, we replace the string with a more descriptive one
           * and update the formik errors state with the server ones.
           */
          const errors = hasNINError
            ? {
                ...e.response.data.errors,
                national_id_number: [
                  t('common.commonTexts.birthNumberorDnumberIsRequired'),
                ],
              }
            : e.response.data.errors;

          formikHelpers.setErrors(errors);
        }

        toast.error(t('common.error.anErrorOccurred'));
      }
    },
  });

  return (
    <CareReceiverForm
      mode="create"
      error={errorIsString(errorHandler.error) ? errorHandler.error : undefined}
      form={formik}
      selectedZone={selectedZone}
      onSelectZone={(zoneId) => handleSetSelectedZone(zoneId)}
      onCareReceiverAutofill={(careReceiver) =>
        onCareReceiverAutofill(careReceiver)
      }
    />
  );
}
