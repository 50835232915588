import React, { useState } from 'react';
import { Link, RouteComponentProps } from 'react-router-dom';
import * as Sentry from '@sentry/browser';

import { useApiCall } from '../../../lib/useApiCall';

import { faTrash } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { InlineSpinner } from '../../../components/InlineSpinner';
import { useConfirmation } from '../../../lib/confirmation/ConfirmationContext';
import { Button } from '@material-ui/core';

import * as api from '../../../serverApi';
import { useRouter } from '../../../lib/useRouter';
import { HeadingBar } from '../shared/HeadingBar';
import { Card } from '../shared/Card';

export function CareReceiverDetails(
  props: RouteComponentProps<{ careReceiverId: string }>
) {
  const [deleting, setDeleting] = useState<number | undefined>(undefined);
  const confirmation = useConfirmation();
  const router = useRouter();

  const careReceiverId = props.match.params.careReceiverId;
  const res = useApiCall(api.superGetCareReceiverDetails, [
    parseInt(careReceiverId),
  ]);

  const careReceiver: api.SuperCareReceiver | undefined =
    res.data && res.data.care_receiver;

  const handleDelete = async (careReceiverId: number) => {
    confirmation
      .confirm('For å bekrefte inaktivering, trykk OK')
      .then(async () => {
        try {
          setDeleting(careReceiverId);
          await api.superDeleteCareReceiver(careReceiverId);
          setDeleting(undefined);
          router.history.push('/super/care-receivers');
        } catch (e) {
          Sentry.captureException(e);
          setDeleting(undefined);
        }
      })
      .catch(() => {});
  };

  return (
    <>
      <HeadingBar>
        <h2>
          {res.loading ? (
            <span>Loading ...</span>
          ) : (
            <>
              {careReceiver ? (
                <>
                  {careReceiver.first_name} {careReceiver.last_name}
                </>
              ) : (
                'Not found'
              )}
            </>
          )}
        </h2>

        <Button component={Link} to="/super/care-receivers">
          Back to list
        </Button>
      </HeadingBar>

      <Card>
        <div className="p-5">
          {res.loading ? (
            <div>
              <span className="mr-2">Loading ...</span>
              <InlineSpinner size="sm" />
            </div>
          ) : !careReceiver ? (
            <div>
              <span className="alert alert-danger mt-2">
                Care receiver not found
              </span>
            </div>
          ) : (
            <div>
              <Button
                color="secondary"
                variant="contained"
                disabled={deleting === careReceiver.care_receiver_id}
                onClick={() => handleDelete(careReceiver.care_receiver_id)}
              >
                {' '}
                {deleting ? (
                  <InlineSpinner size="sm" />
                ) : (
                  <>
                    <FontAwesomeIcon icon={faTrash} className="mr-2" />
                    Delete care receiver
                  </>
                )}
              </Button>
            </div>
          )}
        </div>
      </Card>
    </>
  );
}
