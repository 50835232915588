import { useCallback, useEffect, useRef } from 'react';
import * as d3 from 'd3';

interface Props {
  xScale: any;
  width: number;
  zoomScale: number;
}
export const BehaviorChartsXAxis = (props: Props) => {
  const { width, xScale, zoomScale } = props;
  const ref = useRef(null);

  const formatTickLabels = useCallback(
    (date: Date | d3.NumberValue) => {
      if (!(date instanceof Date)) {
        return '';
      }

      const hours = date.getHours();
      const minutes = date.getMinutes();

      if (zoomScale < 2.2) {
        return hours < 10 ? `0${hours}` : `${hours}`;
      }

      return `${hours === 0 ? '00' : hours}:${
        minutes > 9 ? minutes : `0${minutes}`
      }`;
    },
    [zoomScale]
  );

  useEffect(() => {
    const svg = d3
      .select(ref.current)
      .attr('width', width)
      .attr('height', '26.5');

    svg.selectAll('*').remove();
    const gx = svg.append('g');
    const xAxis = (
      g: d3.Selection<SVGGElement, any, any, any>,
      x: d3.ScaleTime<number, number, never>,
      height: number
    ) =>
      g
        .attr('class', 'BehavioralDataChart__axis BehavioralDataChart__axis--x')
        .attr('transform', `translate(0,3)`)
        .attr('width', width)
        .attr('height', height)
        .style('stroke-width', 3)
        .call(
          d3
            .axisBottom(x)
            .ticks(width > 650 && zoomScale === 1 ? 24 : width / 95)
            .tickSizeOuter(0)
            .tickFormat(formatTickLabels)
        );

    // Added TS typecast to work around TS error introduced in a Oct 2023 update.
    (gx as any).call(xAxis, xScale);
  }, [xScale, width, formatTickLabels, zoomScale]);

  return <svg ref={ref} />;
};
