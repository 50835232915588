/**
 * Simulate anchor link click useful in places where we can't add a propper anchor tag link
 */
export const openLinkInNewTab = (url: string) => {
  const link = document.createElement('a');

  link.href = url;
  link.target = '_blank';

  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
};
