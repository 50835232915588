import React from 'react';
import cs from 'classnames';
import * as api from '../../../../../serverApi';

import './SensorHistoryTable.scss';

interface Props {
  className?: string;
  data?: api.GetSensorStatusLogResult;
}

export function SensorHistoryTable(props: Props) {
  const log = props.data?.data ?? [];
  return (
    <div className={cs('SensorHistoryTable', props.className)}>
      <div
        style={{
          minHeight: '250px',
          maxWidth: 'calc(100vw - 350px)',
          overflowX: 'scroll',
        }}
      >
        <table className="SensorHistoryTable-table">
          <thead>
            <tr>
              <th>Time</th>
              <th>
                <code>cpu</code>
                <br />
                <small>&deg;C</small>
              </th>
              <th>
                <code>lim</code>
                <br />
                <small>&deg;C</small>
              </th>
              <th>
                <code>tim</code>
                <br />
                <small>&deg;C</small>
              </th>
              <th>
                <code>5V</code>
                <br />
                <small>mV</small>
              </th>
              <th>
                <code>5V</code>
                <br />
                <small>mA</small>
              </th>
              <th>
                <code>3.9V</code>
                <br />
                <small>mV</small>
              </th>
              <th>
                <code>3.9V</code>
                <br />
                <small>mA</small>
              </th>
              <th>
                <code>Vin</code>
                <br />
                <small>mV</small>
              </th>
              <th>
                <code>Vin</code>
                <br />
                <small>mA</small>
              </th>
              <th>
                <code>Vlim</code>
                <br />
                <small>mV</small>
              </th>
              <th>
                <code>Vlim</code>
                <br />
                <small>mA</small>
              </th>

              <th>revHw</th>
              <th>revsensor</th>
              <th>revtonet</th>
              <th>revalarm</th>
              <th>revsnm</th>
              <th>revss</th>
              <th>revsc</th>
              <th>revgm</th>
              <th>revalgo</th>
              <th>revrctc</th>
              <th>revrm</th>
              <th>revaudio</th>
              <th>ssid</th>
              <th>wifi strength</th>
              <th>cronRunning</th>
              <th>distroVersionCurrent</th>
              <th>distroVersionAvailable</th>
              <th>upgradeAdvice</th>
            </tr>
          </thead>
          <tbody>
            {log.map((l) => (
              <tr key={l.id}>
                <td>{l.reg}</td>
                <td>{l.cpuTemp}</td>
                <td>{l.limTemp}</td>
                <td>{l.timTemp}</td>
                <td>{l.volt5v}</td>
                <td>{l.curr5v}</td>
                <td>{l.volt3v9}</td>
                <td>{l.curr3v9}</td>
                <td>{l.voltVin}</td>
                <td>{l.currVin}</td>
                <td>{l.voltLim}</td>
                <td>{l.currLim}</td>
                <td>{l.revHw}</td>
                <td>{l.revsensor}</td>
                <td>{l.revtonet}</td>
                <td>{l.revalarm}</td>
                <td>{l.revsnm}</td>
                <td>{l.revss}</td>
                <td>{l.revsc}</td>
                <td>{l.revgm}</td>
                <td>{l.revalgo}</td>
                <td>{l.revrctc}</td>
                <td>{l.revrm}</td>
                <td>{l.revaudio}</td>
                <td>{l.SSID}</td>
                <td>{l.wifiStrength}</td>
                <td>{l.cronRunning}</td>
                <td>{l.distroVersionCurrent}</td>
                <td>{l.distroVersionAvailable}</td>
                <td>{l.upgradeAdvice}</td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
}
