import React, { useState, useEffect } from 'react';
import { useFormik } from 'formik';
import { TableWrapper } from '../../../../../components/TableWrapper';
import Table from '@material-ui/core/Table';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import TableBody from '@material-ui/core/TableBody';
import {
  Button,
  Checkbox,
  FormControlLabel,
  FormGroup,
  MenuItem,
  TextField,
} from '@material-ui/core';
import * as api from '../../../../../serverApi';
import { useErrorHandler } from '../../../../../lib/useErrorHandler';
import { ErrorMessages } from '../../../../../components/ErrorMessages';

import './SensorSettings.scss';

interface SensorSettingsProps {
  sensor: {
    sensor_id: number;
    sensor_type: api.SensorType;
  };
}

interface ButtonEditMode {
  fieldName: string;
  loading?: boolean;
  resetForm: () => void;
}

export function SensorSettings(props: SensorSettingsProps) {
  const errorHandler = useErrorHandler();

  const [loading, setLoading] = useState(false);
  const [activeField, setActiveField] = useState<string | undefined>(undefined);
  const [sensorSetting, setSensorSetting] = useState<
    api.UpdateSensorSettingData | undefined
  >(undefined);

  const sensorCompositeId = `${props.sensor.sensor_type}${props.sensor.sensor_id}`;

  useEffect(() => {
    async function getSensorSetting(sensorId: string) {
      try {
        setLoading(true);
        const setting = await api.getSensorSettings(sensorId);
        setSensorSetting(setting);
        setLoading(false);
      } catch (error) {
        setLoading(false);
      }
    }

    getSensorSetting(sensorCompositeId);
  }, [sensorCompositeId]);

  const DEFAULT_SETTING = {
    zone_id: undefined,
    modulation_frequency: 100,
    led_blink: false,
    wifi_channels: '',
    sensor_algorithm_config_bits: 100,
    audio_twoway_file: '',
    audio_twoway_volume: undefined,
    audio_oneway_file: '',
    audio_oneway_volume: undefined,
    audio_hangup_file: '',
    audio_hangup_volume: undefined,
    audio_stream_start_file: '',
    audio_stream_start_volume: undefined,
    audio_stream_stop_file: '',
    audio_stream_stop_volume: undefined,
  };

  const formik = useFormik<api.UpdateSensorSettingData>({
    enableReinitialize: true,
    initialValues: sensorSetting || DEFAULT_SETTING,
    onSubmit: async (values, helpers) => {
      try {
        errorHandler.reset();
        const { sensor_setting } = await api.updateSensorSetting(
          sensorCompositeId,
          values
        );
        setSensorSetting(sensor_setting);
        setActiveField(undefined);
      } catch (error) {
        errorHandler.handleError(error);
      }
    },
  });

  const ButtonEditMode = (props: ButtonEditMode) => {
    return (
      <>
        {activeField === props.fieldName ? (
          <>
            <Button
              type="submit"
              variant="contained"
              color="default"
              size="small"
              className="btn-action mr-1"
              disabled={props.loading}
            >
              {props.loading ? 'Saving' : 'Save'}
            </Button>
            <Button
              variant="contained"
              color="default"
              size="small"
              className="btn-action"
              disabled={props.loading}
              onClick={() => {
                props.resetForm();
                setActiveField(undefined);
              }}
            >
              Cancel
            </Button>
          </>
        ) : (
          <Button
            disabled={props.loading}
            variant="contained"
            color="primary"
            size="small"
            className="btn-action btn-action-edit"
            onClick={() => {
              props.resetForm();
              setActiveField(props.fieldName);
            }}
          >
            Edit
          </Button>
        )}
      </>
    );
  };

  return (
    <div className="SensorSettings">
      {loading ? (
        <span>Loading sensor setting ...</span>
      ) : (
        <form onSubmit={formik.handleSubmit}>
          <TableWrapper>
            <Table size="small" aria-label="sensor settings table">
              <TableBody>
                <TableRow>
                  <TableCell component="th" scope="row" size="medium">
                    Led blink
                  </TableCell>
                  <TableCell>
                    <FormGroup>
                      <FormControlLabel
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        disabled={activeField !== 'led_blink'}
                        control={
                          <Checkbox
                            name="led_blink"
                            checked={Boolean(formik.values.led_blink)}
                          />
                        }
                        label=""
                      />
                    </FormGroup>
                  </TableCell>
                  <TableCell>
                    <ButtonEditMode
                      fieldName="led_blink"
                      loading={formik.isSubmitting}
                      resetForm={formik.resetForm}
                    />
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell component="th" scope="row" size="medium">
                    Modulation frequency
                  </TableCell>
                  <TableCell>
                    <TextFieldWithEditMode
                      editing={activeField === 'modulation_frequency'}
                      type="number"
                      name="modulation_frequency"
                      value={formik.values.modulation_frequency}
                      onChange={formik.handleChange}
                    />
                  </TableCell>
                  <TableCell>
                    <ButtonEditMode
                      fieldName="modulation_frequency"
                      loading={formik.isSubmitting}
                      resetForm={formik.resetForm}
                    />
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell component="th" scope="row" size="medium">
                    Wifi channels
                  </TableCell>
                  <TableCell>
                    <SelectWithEditMode
                      editing={activeField === 'wifi_channels'}
                      name="wifi_channels"
                      value={formik.values.wifi_channels}
                      options={[
                        { label: 'All', value: 'all' },
                        { label: '2G4only', value: '2G4only' },
                        { label: '5Gonly', value: '5Gonly' },
                      ]}
                      onChange={formik.handleChange}
                    />
                  </TableCell>
                  <TableCell>
                    <ButtonEditMode
                      fieldName="wifi_channels"
                      loading={formik.isSubmitting}
                      resetForm={formik.resetForm}
                    />
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell component="th" scope="row" size="medium">
                    Sensor algorithm config bits
                  </TableCell>
                  <TableCell>
                    <TextFieldWithEditMode
                      editing={activeField === 'sensor_algorithm_config_bits'}
                      type="number"
                      name="sensor_algorithm_config_bits"
                      value={formik.values.sensor_algorithm_config_bits}
                      onChange={formik.handleChange}
                    />
                  </TableCell>
                  <TableCell>
                    <ButtonEditMode
                      fieldName="sensor_algorithm_config_bits"
                      loading={formik.isSubmitting}
                      resetForm={formik.resetForm}
                    />
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell component="th" scope="row" size="medium">
                    Audio twoway file
                  </TableCell>
                  <TableCell>
                    <TextFieldWithEditMode
                      editing={activeField === 'audio_twoway_file'}
                      name="audio_twoway_file"
                      value={formik.values.audio_twoway_file}
                      onChange={formik.handleChange}
                    />
                  </TableCell>
                  <TableCell>
                    <ButtonEditMode
                      fieldName="audio_twoway_file"
                      loading={formik.isSubmitting}
                      resetForm={formik.resetForm}
                    />
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell component="th" scope="row" size="medium">
                    Audio twoway volume
                  </TableCell>
                  <TableCell>
                    <TextFieldWithEditMode
                      type="number"
                      editing={activeField === 'audio_twoway_volume'}
                      name="audio_twoway_volume"
                      value={formik.values.audio_twoway_volume}
                      onChange={formik.handleChange}
                    />
                  </TableCell>
                  <TableCell>
                    <ButtonEditMode
                      fieldName="audio_twoway_volume"
                      loading={formik.isSubmitting}
                      resetForm={formik.resetForm}
                    />
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell component="th" scope="row" size="medium">
                    Audio oneway file
                  </TableCell>
                  <TableCell>
                    <TextFieldWithEditMode
                      editing={activeField === 'audio_oneway_file'}
                      name="audio_oneway_file"
                      value={formik.values.audio_oneway_file}
                      onChange={formik.handleChange}
                    />
                  </TableCell>
                  <TableCell>
                    <ButtonEditMode
                      fieldName="audio_oneway_file"
                      loading={formik.isSubmitting}
                      resetForm={formik.resetForm}
                    />
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell component="th" scope="row" size="medium">
                    Audio oneway volume
                  </TableCell>
                  <TableCell>
                    <TextFieldWithEditMode
                      type="number"
                      editing={activeField === 'audio_oneway_volume'}
                      name="audio_oneway_volume"
                      value={formik.values.audio_oneway_volume}
                      onChange={formik.handleChange}
                    />
                  </TableCell>
                  <TableCell>
                    <ButtonEditMode
                      fieldName="audio_oneway_volume"
                      loading={formik.isSubmitting}
                      resetForm={formik.resetForm}
                    />
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell component="th" scope="row" size="medium">
                    Audio hangup file
                  </TableCell>
                  <TableCell>
                    <TextFieldWithEditMode
                      editing={activeField === 'audio_hangup_file'}
                      name="audio_hangup_file"
                      value={formik.values.audio_hangup_file}
                      onChange={formik.handleChange}
                    />
                  </TableCell>
                  <TableCell>
                    <ButtonEditMode
                      fieldName="audio_hangup_file"
                      loading={formik.isSubmitting}
                      resetForm={formik.resetForm}
                    />
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell component="th" scope="row" size="medium">
                    Audio hangup volume
                  </TableCell>
                  <TableCell>
                    <TextFieldWithEditMode
                      type="number"
                      editing={activeField === 'audio_hangup_volume'}
                      name="audio_hangup_volume"
                      value={formik.values.audio_hangup_volume}
                      onChange={formik.handleChange}
                    />
                  </TableCell>
                  <TableCell>
                    <ButtonEditMode
                      fieldName="audio_hangup_volume"
                      loading={formik.isSubmitting}
                      resetForm={formik.resetForm}
                    />
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell component="th" scope="row" size="medium">
                    Audio stream start file
                  </TableCell>
                  <TableCell>
                    <TextFieldWithEditMode
                      editing={activeField === 'audio_stream_start_file'}
                      name="audio_stream_start_file"
                      value={formik.values.audio_stream_start_file}
                      onChange={formik.handleChange}
                    />
                  </TableCell>
                  <TableCell>
                    <ButtonEditMode
                      fieldName="audio_stream_start_file"
                      loading={formik.isSubmitting}
                      resetForm={formik.resetForm}
                    />
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell component="th" scope="row" size="medium">
                    Audio stream start volume
                  </TableCell>
                  <TableCell>
                    <TextFieldWithEditMode
                      type="number"
                      editing={activeField === 'audio_stream_start_volume'}
                      name="audio_stream_start_volume"
                      value={formik.values.audio_stream_start_volume}
                      onChange={formik.handleChange}
                    />
                  </TableCell>
                  <TableCell>
                    <ButtonEditMode
                      fieldName="audio_stream_start_volume"
                      loading={formik.isSubmitting}
                      resetForm={formik.resetForm}
                    />
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell component="th" scope="row" size="medium">
                    Audio stream stop file
                  </TableCell>
                  <TableCell>
                    <TextFieldWithEditMode
                      editing={activeField === 'audio_stream_stop_file'}
                      name="audio_stream_stop_file"
                      value={formik.values.audio_stream_stop_file}
                      onChange={formik.handleChange}
                    />
                  </TableCell>
                  <TableCell>
                    <ButtonEditMode
                      fieldName="audio_stream_stop_file"
                      loading={formik.isSubmitting}
                      resetForm={formik.resetForm}
                    />
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell component="th" scope="row" size="medium">
                    Audio stream stop volume
                  </TableCell>
                  <TableCell>
                    <TextFieldWithEditMode
                      type="number"
                      editing={activeField === 'audio_stream_stop_volume'}
                      name="audio_stream_stop_volume"
                      value={formik.values.audio_stream_stop_volume}
                      onChange={formik.handleChange}
                    />
                  </TableCell>
                  <TableCell>
                    <ButtonEditMode
                      fieldName="audio_stream_stop_volume"
                      loading={formik.isSubmitting}
                      resetForm={formik.resetForm}
                    />
                  </TableCell>
                </TableRow>
                {errorHandler.error ? (
                  <TableRow>
                    <TableCell colSpan={3}>
                      <ErrorMessages errorData={errorHandler} />
                    </TableCell>
                  </TableRow>
                ) : null}
              </TableBody>
            </Table>
          </TableWrapper>
        </form>
      )}
    </div>
  );
}

function TextFieldWithEditMode(props: {
  type?: 'text' | 'number';
  editing: boolean;
  name: string;
  value?: string | number | null;
  onChange: (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => void;
}) {
  const { value, editing } = props;

  return !editing ? (
    <span>{value}</span>
  ) : (
    <TextField
      autoFocus
      type={props.type || 'text'}
      name={props.name}
      value={value || ''}
      onChange={props.onChange}
    />
  );
}

function SelectWithEditMode(props: {
  editing: boolean;
  name: string;
  value?: string | number | null;
  options: Array<{ label: string; value: string }>;
  onChange: (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => void;
}) {
  const { value, editing } = props;

  return !editing ? (
    <span>{value}</span>
  ) : (
    <TextField
      select
      name={props.name}
      value={props.value}
      onChange={props.onChange}
    >
      {props.options.map((option: { value: string; label: string }) => (
        <MenuItem key={option.value} value={option.value}>
          {option.label}
        </MenuItem>
      ))}
    </TextField>
  );
}
