/**
 * Generate a random password for standard users.
 * @returns {string}
 */
function generateStandardPassword() {
  const chars = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz';

  const nr = Math.floor(9000 + Math.random() * 2000);

  let text = '';

  for (var i = 0; i < 5; i++) {
    text += chars.charAt(Math.floor(Math.random() * chars.length));
  }

  return `${text[0].toUpperCase()}${text.substr(1).toLocaleLowerCase()}${nr}!`;
}

/**
 * Generate a random password for integration users, with stricter requirements.
 * @returns {string}
 */
function generateIntegrationPassword() {
  const chars = 'ABCDEFGHJKLMNPQRSTUVWXYZabcdefghjkmnpqrstuvwxyz';
  const specialChars = '-_?!@,.:';
  let text = '';

  for (let i = 0; i < 15; i++) {
    text += chars.charAt(Math.floor(Math.random() * chars.length));
  }

  text += specialChars.charAt(Math.floor(Math.random() * specialChars.length));

  let nr = '';
  for (let i = 0; i < 5; i++) {
    nr += Math.floor(Math.random() * 10);
  }

  return `${text[0].toUpperCase()}${text.substr(1).toLocaleLowerCase()}${nr}`;
}

/**
 * Generate a new random password.
 * @param passwordType The type of password to generate.
 * @returns
 */
export function generatePassword(
  passwordType?: 'integration' | 'standard'
): string {
  return passwordType === 'integration'
    ? generateIntegrationPassword()
    : generateStandardPassword();
}
