import React from 'react';
import { Redirect } from 'react-router-dom';
import { useAppContext } from '../lib/global';

export default function RedirectBasedOnActiveRole() {
  const app = useAppContext();

  /**
   * Redirect rules:
   */

  /**
   * If the global super capability is present, redirect to the superuser interface.
   */
  if (app.hasCapability('globalSuper')) {
    return <Redirect to="/super" />;
  }

  /**
   * If the user has dashboard access, redirect to /dashboard
   */
  if (app.hasCapability('dashboardAccess')) {
    return <Redirect to="/dashboard" />;
  }

  /**
   * If the user has vendor access, redirect to /vendor
   */
  if (app.hasCapability('vendor')) {
    return <Redirect to="/vendor" />;
  }

  /**
   * If the user has manage access, redirect to /manage
   */
  if (app.hasCapability('manageZones')) {
    return <Redirect to="/manage" />;
  }

  /**
   * If the user has supervision access, redirect to /care
   */
  if (app.hasCapability('careAndObservation')) {
    return <Redirect to="/care" />;
  }

  // /**
  //  * Otherwise, if user's active role has no capabilities, redirect to access denied page and show a message.
  //  */
  return <Redirect to="/access-denied" />;
}
