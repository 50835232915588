import cs from 'classnames';
import { AudioPlayerProvider, useAudioPlayer } from 'react-use-audio-player';

import './AudioPlayer.scss';
import { useEffect } from 'react';
import { PlaySoundButton } from './PlaySoundButton';

interface Props {
  className?: string;
  file?: string | string[];
  stopAfterLoadingNext?: boolean;
  disabled?: boolean;
  tooltipLabel?: string;
}
export const AudioPlayer = (props: Props) => {
  return (
    <AudioPlayerProvider>
      <Player {...props} />
    </AudioPlayerProvider>
  );
};

const Player = (props: Props) => {
  const { stopAfterLoadingNext = true } = props;
  const { play, stop, playing, load, player } = useAudioPlayer();

  /**
   * Lazy load the audio file that comes from 'file' prop
   */
  useEffect(() => {
    //Before loading the new audio we check wether user has 'stopAfterLoadingNext' prop enabled and stop the previous one if it's playing.
    if (stopAfterLoadingNext && playing) {
      player.stop();
    }

    //Load file if it exists
    if (props.file) {
      load({
        src: props.file,
        format: 'mp3',
        autoplay: false,
      });
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.file]);

  const togglePlayStop = () => {
    if (playing) {
      return stop();
    }

    play();
  };

  const playingDisabled = props.disabled || !props.file;

  return (
    <PlaySoundButton
      className={cs(
        'AudioPlayer',
        props.className,
        playingDisabled && 'AudioPlayer--disabled'
      )}
      disabled={playingDisabled}
      onClick={togglePlayStop}
      playing={playing}
    />
  );
};
