import React, { useState } from 'react';
import { Link, RouteComponentProps } from 'react-router-dom';
import { Button } from '@material-ui/core';
import * as Sentry from '@sentry/browser';

import { useApiCall } from '../../../lib/useApiCall';
import { faEdit, faTrash } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { InlineSpinner } from '../../../components/InlineSpinner';
import { useConfirmation } from '../../../lib/confirmation/ConfirmationContext';
import './AccountDetails.scss';

import * as api from '../../../serverApi';
import { useRouter } from '../../../lib/useRouter';
import HeadingBar from '../../super/shared/HeadingBar';
import { ButtonGroup } from '../../../components/ButtonGroup';
import { t, TranslationKey } from '../../../lib/i18n';
import { Card } from '../../super/shared/Card';
import { FullScreenLoader } from '../../../components/FullScreenLoader/FullScreenLoader';
import { toast } from 'react-toastify';
import { isAxiosError } from '../../../lib/utils/errorUtils';

export function AccountDetails(
  props: RouteComponentProps<{ accountId: string }>
) {
  const [deleting, setDeleting] = useState<number | undefined>(undefined);
  const [switchingToManage, setSwitchingToManage] = useState(false);

  const confirmation = useConfirmation();
  const router = useRouter();

  const accountId = props.match.params.accountId;
  const res = useApiCall(api.superVendorGetAccountDetails, [
    parseInt(accountId),
  ]);

  const account: api.Account | undefined = res.data && res.data.account;

  const handleDelete = async (accountId: number) => {
    confirmation
      .confirm('For å bekrefte sletting, trykk OK')
      .then(async () => {
        try {
          setDeleting(accountId);
          await api.superVendorDeleteAccount(accountId);
          setDeleting(undefined);
          router.history.push('/super/accounts');
        } catch (e) {
          Sentry.captureException(e);
          setDeleting(undefined);
        }
      })
      .catch(() => {});
  };

  const impersonateClick = async (account_id: number) => {
    try {
      setSwitchingToManage(true);
      await api.impersonateAccount({ account_id, redirect_url: '/super' });
      window.location.reload();
    } catch (e) {
      setSwitchingToManage(false);
      if (isAxiosError(e) && e.response?.data?.code) {
        const errorCode = e.response.data.code;
        toast.error(t(`common.serverError.${errorCode}` as TranslationKey));
      } else {
        console.error(e);
      }
    }
  };

  if (res.loading) {
    return (
      <InlineSpinner
        size="md"
        text="Loading..."
        textStyle={{ fontWeight: 500, fontSize: 22 }}
      />
    );
  }

  if (!account) {
    return <p> Account not found. </p>;
  }

  if (switchingToManage) {
    return <FullScreenLoader />;
  }

  return (
    <div className="Account-Details">
      <HeadingBar>
        <h1>{account.name}</h1>
        <Button component={Link} to="/super/accounts">
          Back to accounts
        </Button>
      </HeadingBar>

      <Card>
        <div className="AccountDetails-content mt-4">
          <h3>Code:</h3>
          <div className="AccountDetails-content-value">
            {account.account_code}
          </div>
        </div>
        <div className="AccountDetails-content">
          <h3>Language</h3>
          <div className="AccountDetails-content-value">
            {t(
              `common.commonLanguages.languages.${account.default_user_locale}` as TranslationKey
            )}
          </div>
        </div>
        <div className="AccountDetails-content">
          <h3>Disable all detailed images:</h3>
          <div className="AccountDetails-content-value">
            {account.disable_all_detailed_images ? 'Yes' : 'No'}
          </div>
        </div>
        <div className="AccountDetails-content">
          <h3>Require national id for care receivers:</h3>
          <div className="AccountDetails-content-value">
            {account.require_national_id_for_care_receivers ? 'Yes' : 'No'}
          </div>
        </div>
        <div className="AccountDetails-content">
          <h3>Disable national id numbers:</h3>
          <div className="AccountDetails-content-value">
            {account.disable_national_id_numbers ? 'Yes' : 'No'}
          </div>
        </div>
        <div className="AccountDetails-content">
          <h3>This account is a vendor:</h3>
          <div className="AccountDetails-content-value">
            {account.is_vendor ? 'Yes' : 'No'}
          </div>
        </div>

        <ButtonGroup>
          <Button
            color="primary"
            variant="contained"
            component={Link}
            to={`/super/accounts/${account.account_id}/edit`}
          >
            <FontAwesomeIcon icon={faEdit} className="mr-2" />
            Update account
          </Button>

          <Button
            color="secondary"
            variant="contained"
            disabled={deleting === account.account_id}
            onClick={() => handleDelete(account.account_id)}
            className="ml-2"
          >
            {deleting ? (
              <InlineSpinner size="sm" />
            ) : (
              <>
                <FontAwesomeIcon icon={faTrash} className="mr-2" />
                Delete account
              </>
            )}
          </Button>
          <Button
            className="ml-2"
            onClick={() => impersonateClick(account.account_id)}
          >
            {t('supervendor.ListAccounts.goToManageArea')}
          </Button>
        </ButtonGroup>
      </Card>
    </div>
  );
}
