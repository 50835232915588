import React, { useEffect } from 'react';

import { Switch, Route } from 'react-router';
import { Redirect } from 'react-router-dom';

import { Login } from './public/Login';
import { LoginSSO } from './public/LoginSSO';
import { SelectRole } from './public/SelectRole';
import { ForgotPassword } from './public/ForgotPassword';
import { ResetPassword } from './public/ResetPassword';

import { ChangePassword } from './user/ChangePassword';

import { useAppContext } from '../lib/global';

import { Care } from './care/Care';
import { Super } from './super/Super';
import { Vendor } from './vendor/Vendor';
import { Manage } from './manage/Manage';
import { DashboardContainer } from './dashboard/DashboardContainer';

import { useRouter } from '../lib/useRouter';
import RedirectBasedOnActiveRole from './RedirectBasedOnActiveRole';
import { useI18nContext } from '../lib/i18n';
import { AccessDenied } from './AccessDenied';
import { getBrowserPreferredLanguage } from '../lib/getBrowserPreferredLanguage';

import { Event } from './event/Event';

import './App.scss';

export function App() {
  const { location } = useRouter();
  const app = useAppContext();
  const i18n = useI18nContext();

  /**
   * Set the language to the users preferred language or the browser language
   **/
  const preferredLocale =
    app.user?.preferences.locale ??
    app.user?.account?.default_user_locale ??
    getBrowserPreferredLanguage();

  useEffect(() => {
    i18n.setLocale(preferredLocale);
  }, [preferredLocale]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <Switch>
      <Route exact={true} path="/login" component={Login} />
      <Route exact path="/sso" component={LoginSSO} />
      {app.isAuthenticated && (
        <Route exact={true} path="/roles" component={SelectRole} />
      )}
      {app.isAuthenticated && (
        <Route
          exact={true}
          path="/change-password"
          component={ChangePassword}
        />
      )}

      <Route exact={true} path="/forgot-password" component={ForgotPassword} />
      <Route exact={true} path="/reset-password" component={ResetPassword} />

      <Route path="/e/:hash" component={Event} />

      {/**
       * If authenticated but password has expire, always
       * redirect the update password page
       */}
      {app.user?.password_expiration_days === 0 && (
        <Redirect
          to={
            location.pathname === '/'
              ? '/change-password'
              : `/change-password?redirect=${encodeURIComponent(
                  location.pathname
                )}`
          }
        />
      )}

      {/**
       * If authenticated but no role is selected, always
       * redirect the user to the Select role screen
       */}
      {app.user && !app.user.active_role && (
        <Redirect
          to={
            location.pathname === '/'
              ? '/roles'
              : `/roles?redirect=${encodeURIComponent(location.pathname)}`
          }
        />
      )}

      {app.hasCapability('careAndObservation') && (
        <Route path="/care" component={Care} />
      )}

      {app.hasCapability('manageZones') && (
        <Route path="/manage/:zoneId([0-9]+)?" component={Manage} />
      )}

      {app.hasCapability('vendor') && (
        <Route path="/vendor" component={Vendor} />
      )}

      {app.hasCapability('globalSuper') && (
        <Route path="/super" component={Super} />
      )}

      {app.hasCapability('dashboardAccess') && (
        <Route path="/dashboard" component={DashboardContainer} />
      )}

      <Route path="/access-denied" component={AccessDenied} />

      {app.isAuthenticated && <Route component={RedirectBasedOnActiveRole} />}

      <Redirect
        to={
          location.pathname === '/'
            ? '/login'
            : `/login?redirect=${encodeURIComponent(
                location.pathname + location.search
              )}`
        }
      />
    </Switch>
  );
}

export default App;
