import React from 'react';
import { RouteComponentProps } from 'react-router';
import { useRouter } from '../../../../lib/useRouter';
import { SensorContextProvider } from '../../../../lib/SensorContext';
import LoadingPlaceholder from '../../../../lib/ui/LoadingPlaceholder';
import MoveSensorButton from './MoveSensorButton';
import UnassignSensorButton from './UnassignSensorButton';
import ChangeSensorNameButton from './ChangeSensorNameButton';
import getParentPath from '../../../../lib/getParentPath';
import { IconButton } from '../../../../components/IconButton';
import { LabeledValue } from '../../../../components/LabeledValue';
import getCareReceiverDisplayName from '../getCareReceiverDisplayName';
import { SensorAdvancedFormButton } from './SensorAdvancedFormButton';
import { useZoneContext } from '../../../../lib/ZoneContext';
import { SensorStatusCheckbox } from './SensorStatusCheckbox';
import { ShowReceivingSensorCheckbox } from './ShowReceivingSensorCheckbox';
import { t, TranslationKey } from '../../../../lib/i18n';
import isSensorLocked from '../lib/isSensorLocked';
import { SensorWirelessStatus } from '../../../supervendor/sensors/details/roommate/wireless/SensorWirelessStatus';
import { Feature } from '../../../../components/Feature';
import { useGetSensorDetailsQuery } from '../../../../serverApi';
import { ArgusBasicManagementButton } from './argus/ArgusBasicManagementButton';

interface Props extends RouteComponentProps<{ sensorId: string }> {}

export default function SensorDetails(props: Props) {
  const sensorId = props.match.params.sensorId;

  // const query = useQuery(QueryKeys.manageSensorDetails(sensorId), () =>
  //   api.getSensorDetails(sensorId)
  // );

  const query = useGetSensorDetailsQuery(sensorId);

  const router = useRouter();
  const zoneCtx = useZoneContext();

  const handleMoveSensorOnChange = () => {
    query.refetch();
    zoneCtx.reload();
  };

  const handleUnassignSensorOnChange = () => {
    zoneCtx.reload();
    router.history.push(getParentPath(props.match.path));
  };

  if (query.isLoading) {
    return <LoadingPlaceholder heading={t('common.commonTexts.loading')} />;
  }

  if (!query.data) {
    return null;
  }

  const sensor = query.data.sensor;

  const isLocked = isSensorLocked(sensor);

  return (
    <SensorContextProvider
      value={{
        id: query.data.sensor.id,
        name: query.data.sensor.name,
        sensor_type: query.data.sensor.sensor_type,
        is_enabled: !!query.data.sensor.is_enabled,
        show_receiving_sensor: !!query.data.sensor.show_receiving_sensor,
        zone: query.data.sensor.zone,
        careReceiver: query.data.sensor.care_receiver,
        eventTypes: query.data.sensor.event_types,
        reload: query.refetch,
      }}
    >
      <div className="SensorDetails">
        <h1>
          {t(`common.commonSensors.${sensor.sensor_type}` as TranslationKey)}, #
          {sensor.sensor_id}
        </h1>

        <div className="my-4">
          <IconButton
            icon="arrow-left"
            to={getParentPath(router.location.pathname)}
          >
            {t('common.commonButtons.back')}
          </IconButton>
        </div>

        <LabeledValue
          label={t('manage.sensors.details.SensorDetails.sensorNumber')}
        >
          {t(`common.commonSensors.${sensor.sensor_type}` as TranslationKey)}, #
          {sensor.sensor_id}
        </LabeledValue>

        <LabeledValue>
          <SensorStatusCheckbox
            onChanged={() => {
              query.refetch();
            }}
          />
        </LabeledValue>

        <LabeledValue>
          <ShowReceivingSensorCheckbox
            onChanged={() => {
              query.refetch();
            }}
          />
        </LabeledValue>

        <LabeledValue label={t('manage.sensors.common.sensorName')}>
          {sensor.name || (
            <em>{t('manage.sensors.details.SensorDetails.notSet')}</em>
          )}
        </LabeledValue>

        <LabeledValue label={t('common.commonTexts.theRecipient')}>
          {getCareReceiverDisplayName(sensor.care_receiver) ?? (
            <em>
              {t(
                'manage.sensors.details.SensorDetails.noAffiliateServiceRecipient'
              )}
            </em>
          )}
        </LabeledValue>

        <Feature feature="enable-sensor-details-wifi-streaming">
          <LabeledValue label="Sensor wireless status">
            <SensorWirelessStatus sensor={sensor} />
          </LabeledValue>
        </Feature>

        <div>
          <ChangeSensorNameButton
            disabled={isLocked}
            onChanged={query.refetch}
          />
        </div>

        <div>
          <MoveSensorButton
            disabled={isLocked}
            onChanged={handleMoveSensorOnChange}
          />
        </div>

        <div>
          <UnassignSensorButton
            disabled={isLocked}
            onChanged={handleUnassignSensorOnChange}
          />
        </div>

        <hr />

        <div>
          <SensorAdvancedFormButton />
        </div>

        {sensor.sensor_type === 'roommate' ? (
          <Feature feature="show-argus-management">
            <ArgusBasicManagementButton />
          </Feature>
        ) : null}
      </div>
    </SensorContextProvider>
  );
}
