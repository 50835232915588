import React, { useState } from 'react';

import { IconDefinition } from './Icon';

import './HelpBlock.scss';
import { HelpBlock } from './HelpBlock';
import { VideoModal } from './modals/VideoModal';
import { useWindowSize } from '../lib/useWindowSize';

interface Props {
  vimeoId: string;
  title: string;
  icon: IconDefinition;
}

export function VideoHelpBlock(props: Props) {
  const windowSize = useWindowSize();
  const [isOpen, setIsOpen] = useState(false);
  const cleanTitle = props.title.replace('&shy;', '');

  const minutesOnSeparateLine = props.title.replace(
    /( \([0-9]+(.[0-9]+)? min)/,
    '<br>$1'
  );

  const toggleVideoModal = () => setIsOpen(!isOpen);

  function click() {
    if (windowSize.isMobile) {
      // If mobile send directly to vimeo
      window.location.href = `https://player.vimeo.com/video/${props.vimeoId}`;
    } else {
      toggleVideoModal();
    }
  }

  if (!props.vimeoId || !props.title) {
    return null;
  }

  return (
    <>
      <HelpBlock onClick={click} icon={props.icon}>
        <span
          dangerouslySetInnerHTML={{
            __html: minutesOnSeparateLine,
          }}
        />
      </HelpBlock>

      <VideoModal
        isOpen={isOpen}
        title={cleanTitle}
        src={`https://player.vimeo.com/video/${props.vimeoId}`}
        onToggle={toggleVideoModal}
      />
    </>
  );
}
