import React from 'react';
import {
  TextField,
  Checkbox,
  FormGroup,
  FormControlLabel,
  FormControl,
  MenuItem,
} from '@material-ui/core';
import { ScheduleSelector } from './ui/ScheduleSelector';
import { EventTypeSelector } from './EventTypeSelector';
import { UserSelector } from '../../../components/UserSelector/UserSelector';
import { Button } from '../../../components/Button';
import { BorderWrapper } from '../../../components/BorderWrapper';
import { ButtonGroup } from '../../../components/ButtonGroup';
import { useRouter } from '../../../lib/useRouter';
import {
  ErrorHandlerType,
  errorIsObjectError,
} from '../../../lib/useErrorHandler';
import { ErrorMessages } from '../../../components/ErrorMessages';
import { ZonePickerField } from '../../../components/ZonePickerField/ZonePickerField';
import { useZoneTreeContext } from '../../care/lib/ZoneTreeContext';

import * as api from '../../../serverApi';
import { InlineError } from '../../../components/InlineError';
import getParentPath from '../../../lib/getParentPath';
import { AlertSettingsFormik } from './lib/useAlertSettingsFormik';
import { t } from '../../../lib/i18n';
import { AlertSettingForSingleSensorType } from './lib/types';
import { generateAutoCompleteValue } from '../../../lib/utils/generateAutoCompleteValue';

export type AlertSettingState = Omit<
  AlertSettingForSingleSensorType & { zone_id?: number; api_lock?: boolean },
  'alert_setting_id' | 'zone'
>;

interface Props {
  loading?: boolean;
  mode?: 'create' | 'edit';
  error?: ErrorHandlerType;
  disabled?: boolean;
  formik: AlertSettingsFormik;
}

function FieldError(props: { error?: string | null }) {
  if (!props.error) {
    return null;
  }
  return <InlineError>{props.error}</InlineError>;
}

export function AlertSettingsForm(props: Props) {
  const router = useRouter();
  const treeCtx = useZoneTreeContext();

  const values = props.formik.values;
  const selectedZone = treeCtx.getZoneById(values.zone_id);

  const handleRegularAlarmEventsChange = (value: boolean) => {
    props.formik.setFieldTouched('include_regular_alarm_events');
    props.formik.setFieldValue('include_regular_alarm_events', value);
  };

  const handleRegularAlertEventsChange = (value: boolean) => {
    props.formik.setFieldTouched('include_regular_alert_events');
    props.formik.setFieldValue('include_regular_alert_events', value);
  };

  const handleTechnicalEventChange = (value: boolean) => {
    props.formik.setFieldTouched('include_technical_event_types');
    props.formik.setFieldValue('include_technical_event_types', value);
  };

  const handleCustomEventChange = (value: boolean) => {
    props.formik.setFieldTouched('include_custom_event_types');
    props.formik.setFieldValue('include_custom_event_types', value);
  };

  const onChangeCustomEventTypes = (events: string[]) => {
    props.formik.setFieldTouched('event_types');
    props.formik.setFieldValue('event_types', events);
  };

  function onRecipientsChange(recipients: api.AlertSettingRecipient[]) {
    props.formik.setFieldTouched('recipients');
    props.formik?.setFieldValue(
      'recipients',
      recipients.map((u) => ({
        id: u.id,
        username: u.username,
        impersonal_user: u.impersonal_user,
        phone: u.phone,
        first_name: u.first_name,
        last_name: u.last_name,
        display_name: u.display_name,
      }))
    );
  }

  function onEscalationRecipientsChange(
    recipients: api.AlertSettingRecipient[]
  ) {
    props.formik.setFieldTouched('escalation_recipients');
    props.formik.setFieldValue('escalation_recipients', recipients);
  }

  function onScheduleChange(schedule: api.AlertSettingScheduleRule[]) {
    props.formik.setFieldTouched('schedule');
    props.formik.setFieldValue('schedule', schedule);
  }

  function onEscalationDelayChange(
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) {
    props.formik.setFieldTouched('escalation_delay');
    props.formik.setFieldValue(
      'escalation_delay',
      parseInt(e.target.value, 10)
    );
  }

  function getErrorByField(key: string) {
    const errors = props.formik.errors;
    const touched = props.formik.touched;

    if (!props.error && !errors) {
      return null;
    }

    if (
      touched[key as keyof AlertSettingState] &&
      errors[key as keyof AlertSettingState]
    ) {
      return errors[key as keyof AlertSettingState] as string;
    }

    return props?.error?.error && errorIsObjectError(props.error.error)
      ? props.error.error[key]
      : undefined;
  }

  return (
    <div className="AlertSettingsForm">
      <BorderWrapper>
        <form>
          <div className="row">
            <div className="col-lg-6">
              <div style={{ maxWidth: 400 }}>
                <FormGroup className="mb-3">
                  <TextField
                    autoFocus={props?.mode === 'create'}
                    style={{ marginTop: 5, fontSize: 20 }}
                    name="name"
                    disabled={props.disabled}
                    label={t('common.commonInputs.name')}
                    value={values.name}
                    onBlur={props.formik.handleBlur}
                    onChange={props.formik.handleChange}
                    autoComplete={generateAutoCompleteValue()}
                  />
                </FormGroup>

                <FormGroup className="mb-3">
                  <ZonePickerField
                    disabled={props.disabled}
                    error={getErrorByField('zone_id')}
                    label={t('common.commonTexts.zoneUnit')}
                    selectedZone={
                      selectedZone
                        ? {
                            id: selectedZone?.id,
                            name: selectedZone.name,
                          }
                        : undefined
                    }
                    onSelectZone={(zone) => {
                      props.formik.setFieldTouched('zone_id');
                      props.formik.setFieldValue('zone_id', zone?.id);
                    }}
                  />
                </FormGroup>

                <FormControlLabel
                  control={
                    <Checkbox
                      disabled={props.disabled}
                      color="primary"
                      checked={values.enabled}
                      onChange={(e) => {
                        props.formik.setFieldTouched('enabled');
                        props.formik.setFieldValue('enabled', e.target.checked);
                      }}
                    />
                  }
                  label={t('common.commonTexts.enabled')}
                  className="mb-3"
                />

                <div className="mb-3">
                  <h5>
                    <strong>
                      {t('manage.alerts.AlertSettingsForm.types')}:
                    </strong>
                  </h5>
                  <EventTypeSelector
                    disabled={props.disabled}
                    includeRegularAlarmTypes={
                      props.formik.values.include_regular_alarm_events
                    }
                    includeRegularAlertTypes={
                      props.formik.values.include_regular_alert_events
                    }
                    setIncludeRegularAlarmTypes={handleRegularAlarmEventsChange}
                    setIncludeRegularAlertTypes={handleRegularAlertEventsChange}
                    includeCustomEventTypes={
                      props.formik.values.include_custom_event_types
                    }
                    setIncludeCustomEventTypes={handleCustomEventChange}
                    includeTechnicalEventTypes={
                      props.formik.values.include_technical_event_types
                    }
                    setIncludeTechnicalEventTypes={handleTechnicalEventChange}
                    customEventTypes={values.event_types}
                    onChangeCustomEventTypes={onChangeCustomEventTypes}
                    alwaysShowCustomOption={false}
                  />
                  <FieldError error={getErrorByField('event_types')} />
                </div>

                <div className="AlertSettingsForm-recipients mb-5">
                  <h5>
                    <strong>
                      {t('manage.alerts.common.notificationRecipients')}
                    </strong>
                  </h5>
                  <UserSelector
                    error={getErrorByField('recipients')}
                    recipients={values.recipients}
                    onChange={onRecipientsChange}
                    searchZoneId={selectedZone?.id}
                    disabled={props.disabled}
                  />
                </div>

                {/*Alert settings escalation*/}
                <div className="AlertSettingsForm-escalation mb-5">
                  <FormGroup>
                    <FormControlLabel
                      disabled={props.disabled}
                      control={
                        <Checkbox
                          color="primary"
                          checked={values.escalation_enabled}
                          onChange={(e) => {
                            props.formik.setFieldTouched('escalation_enabled');
                            props.formik.setFieldValue(
                              'escalation_enabled',
                              e.target.checked
                            );

                            if (e.target.checked && !values.escalation_delay) {
                              props.formik.setFieldTouched('escalation_delay');
                              props.formik.setFieldValue(
                                'escalation_delay',
                                180
                              );
                            }
                          }}
                        />
                      }
                      label={t(
                        'manage.alerts.AlertSettingsForm.alertEscalation'
                      )}
                      className="mb-3"
                    />
                  </FormGroup>

                  {values.escalation_enabled ? (
                    <>
                      <h5>
                        <strong>
                          {t(
                            'manage.alerts.AlertSettingsForm.alertScalingRecipients'
                          )}
                        </strong>
                      </h5>

                      <FormGroup className="LogStorage-input-wrapper">
                        <FormControl>
                          <TextField
                            disabled={
                              !values.escalation_delay || props.disabled
                            }
                            select={true}
                            name="escalation_delay"
                            value={values.escalation_delay}
                            onChange={onEscalationDelayChange}
                            label={t('manage.alerts.AlertSettingsForm.delay')}
                          >
                            <MenuItem value="60">
                              1 {t('common.minutes', 1)}
                            </MenuItem>
                            <MenuItem value="120">
                              2 {t('common.minutes', 2)}
                            </MenuItem>
                            <MenuItem value="180">
                              3 {t('common.minutes', 3)}
                            </MenuItem>
                            <MenuItem value="300">
                              5 {t('common.minutes', 5)}
                            </MenuItem>
                            <MenuItem value="600">
                              10 {t('common.minutes', 10)}
                            </MenuItem>
                            <MenuItem value="900">
                              15 {t('common.minutes', 15)}
                            </MenuItem>
                            <MenuItem value="1200">
                              20 {t('common.minutes', 20)}
                            </MenuItem>
                            <MenuItem value="1800">
                              30 {t('common.minutes', 30)}
                            </MenuItem>
                          </TextField>
                        </FormControl>
                      </FormGroup>

                      <div className="AlertSettingsForm-escalation-recipients">
                        <UserSelector
                          disabled={
                            !values.escalation_recipients || props.disabled
                          }
                          searchZoneId={selectedZone?.id}
                          error={getErrorByField('escalation_recipients')}
                          recipients={values.escalation_recipients || []}
                          onChange={onEscalationRecipientsChange}
                        />
                      </div>
                    </>
                  ) : null}
                </div>
              </div>
            </div>

            {/*Schedule selector*/}
            <div className="col-lg-6 col-sm-12">
              <ScheduleSelector
                disabled={props.disabled}
                scheduleRules={values.schedule}
                onChange={onScheduleChange}
                error={props.error}
              />
              <FieldError error={getErrorByField('schedule')} />
            </div>
          </div>

          {/*Action buttons*/}
          <div className="row">
            <div className="col-lg-12">
              <ButtonGroup direction="horizontal" className="mt-3">
                <Button
                  color="primary"
                  icon={props.formik.isSubmitting ? 'gear' : undefined}
                  spinIcon={props.formik.isSubmitting}
                  disabled={
                    !props.formik.isValid ||
                    props.formik.isSubmitting ||
                    props.disabled
                  }
                  onClick={props.formik.submitForm}
                >
                  {t('common.commonButtons.save')}
                </Button>
                <Button
                  color="default"
                  to={getParentPath(router.location.pathname)}
                >
                  {t('common.commonButtons.cancel')}
                </Button>
              </ButtonGroup>
            </div>
          </div>
          <ErrorMessages className="my-3" errorData={props.error} />
        </form>
      </BorderWrapper>
    </div>
  );
}
