import React, { useEffect, useState } from 'react';
import { Provider, ManageContext } from './ManageContext';
// import { useWindowWidth } from '../../lib/useWindowWidth';
// import { useOptionalZoneContext } from '../../lib/ZoneContext';
import { useRouter } from '../../lib/useRouter';

interface Props {
  children: React.ReactNode;
}

export const ManageContextProvider = (props: Props) => {
  const router = useRouter();
  // const windowWidth = useWindowWidth();
  // const zoneCtx = useOptionalZoneContext();
  const [isSidebarOpen, setIsSidebarOpen] = useState(
    router.location.pathname === '/manage'
  );

  useEffect(() => {
    if (isSidebarOpen && router.location.pathname !== '/manage') {
      setIsSidebarOpen(false);
    }
  }, [router.location]); //eslint-disable-line react-hooks/exhaustive-deps

  // useEffect(() => {
  //   if (windowWidth < 992 && zoneCtx.activeZone) {
  //     setIsSidebarOpen(false);
  //   }
  // }, [windowWidth, zoneCtx.activeZone]);

  const context: ManageContext = {
    isSidebarOpen,
    toggleSidebar: () => setIsSidebarOpen(!isSidebarOpen),
  };

  return <Provider value={context}>{props.children}</Provider>;
};
