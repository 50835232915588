import { groupBy } from 'lodash';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faUser,
  faBuilding,
  faUserTag,
  faAddressBook,
  faPhone,
} from '@fortawesome/pro-solid-svg-icons';

import { SuperSearchUser } from '../../../../serverApi';
import { t, TranslationKey } from '../../../../lib/i18n';
import { SearchResultSingleType } from './SearchResult';

export function SearchResultUser(
  props: SearchResultSingleType<SuperSearchUser>
) {
  const user = props.entry;
  const zones = groupBy(user.user_roles, 'zone.zone_id') ?? [];
  return (
    <div className="SearchResult SearchResult--user">
      <div className="SearchResult-heading">
        <FontAwesomeIcon className="mr-2" icon={faUser} />
        <span>
          {user.first_name} {user.last_name} {user.display_name}{' '}
          <code>{user.username}</code>
        </span>
      </div>

      <div>
        <FontAwesomeIcon className="mr-2" icon={faBuilding} />
        <span>{user.account.name}</span>
      </div>

      <div>
        {user.email && (
          <span className="mr-4">
            <FontAwesomeIcon className="mr-2" icon={faAddressBook} />
            {user.email}
          </span>
        )}

        {user.phone && (
          <span className="mr-4">
            <FontAwesomeIcon className="mr-2" icon={faPhone} />
            {user.phone}
          </span>
        )}
      </div>

      <div>
        {Object.keys(zones).map((key) => {
          const data = zones[key];
          if (!data) {
            return null;
          }
          const zoneName = data[0].zone?.name;
          return (
            <div
              key={`${data[0].zone?.zone_id}-${data[0].zone?.name}-${data[0].role}`}
            >
              <div>
                <FontAwesomeIcon className="mr-2" icon={faUserTag} />
                {zoneName && (
                  <>
                    <span>{zoneName}</span> &mdash;{' '}
                  </>
                )}
                <span>
                  {data.map((d) => (
                    <span key={d.role}>
                      {t(`common.roles.${d.role}` as TranslationKey)}{' '}
                    </span>
                  ))}
                </span>
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
}
