import React from 'react';
import { FormGroup, TextField } from '@material-ui/core';
import { BorderWrapper } from '../../../../components/BorderWrapper';
import { ImpersonalUsersFormik } from '../lib/useImpersonalUsersFormik';
import { RouteLeavingGuard } from '../../../../components/RouteLeavingGuard';
import { t } from '../../../../lib/i18n';
import { LanguageSelector } from '../../../../components/LanguageSelector';
import { generateAutoCompleteValue } from '../../../../lib/utils/generateAutoCompleteValue';

interface Props {
  title?: string;
  mode?: 'create' | 'edit';
  form: ImpersonalUsersFormik;
}

export const ImpersonalUsersForm = (props: Props) => (
  <div className="ImpersonalUsersForm">
    <RouteLeavingGuard when={props.form.dirty} />
    <BorderWrapper>
      <h3>
        {props.title ||
          t('manage.permissions.forms.ImpersonalUsersForm.emergencyPhone')}
      </h3>

      <FormGroup className="mt-3">
        <TextField
          name="display_name"
          className="w-100"
          label={t('common.commonTexts.name')}
          value={props.form.values.display_name}
          onChange={props.form.handleChange}
          onBlur={props.form.handleBlur}
          error={
            props.form.touched.display_name &&
            props.form.errors.display_name !== undefined
          }
          helperText={
            props.form.touched.display_name && props.form.errors.display_name
          }
          autoComplete={generateAutoCompleteValue()}
        />
      </FormGroup>

      <FormGroup className="mt-3">
        <TextField
          name="phone"
          className="w-100"
          label={t('manage.permissions.forms.ImpersonalUsersForm.phone')}
          value={props.form.values.phone}
          onChange={props.form.handleChange}
          onBlur={props.form.handleBlur}
          error={
            props.form.touched.phone && props.form.errors.phone !== undefined
          }
          helperText={props.form.touched.phone && props.form.errors.phone}
          autoComplete={generateAutoCompleteValue()}
        />
      </FormGroup>

      <LanguageSelector
        name="preferred_locale"
        value={props.form.values.preferred_locale}
        onChange={props.form.handleChange}
      />
    </BorderWrapper>
  </div>
);
