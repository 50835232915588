import { SortColumnI } from '../areas/super/shared/Table';
/**
 *
 * Transform the sort string into an object used for the Table component
 *
 * @param string
 * @returns - Sort object -> SortColumnI
 */
export const transformSortStringToObject = (
  string?: string
): SortColumnI | undefined => {
  if (!string) {
    return;
  }
  /**
   * Sorting interface comes in 'sort_key sort_type' format (e.g. 'name desc'),
   * so when we split the string by whitespace, we always know that the first element is
   * the column that's being sorted and the second one will be the sorting order
   */
  const [column, sortOrder] = string.split(' ');

  /**
   * We only support ascending and descending orders
   */
  if (sortOrder !== 'asc' && sortOrder !== 'desc') {
    return;
  }

  return {
    key: column,
    type: sortOrder,
  };
};
