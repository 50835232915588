import cs from 'classnames';
import { Icon } from '../../../../components/Icon';
import { t } from '../../../../lib/i18n';
import { SensorTypeIcon } from '../../../../lib/utils/SensorTypeIcon';
import { SuperSearchSensor } from '../../../../serverApi';
import { SearchResultSingleType } from './SearchResult';

export function SearchResultSensor(
  props: SearchResultSingleType<SuperSearchSensor>
) {
  const sensor = props.entry;

  return (
    <div className="SearchResult SearchResult--sensor">
      <div className="SearchResult-heading">
        <SensorTypeIcon sensorType={sensor.sensor_type} />
        {sensor.sensor_type + sensor.sensor_id}
        {sensor.name && <code className="ml-1">{sensor.name}</code>}
      </div>

      <div>
        <Icon className="mr-2" icon="zone-tree" />
        {sensor.zone_id ? (
          <span>
            {sensor.display_area ?? ''}
            {sensor.display_area && sensor.display_name ? ' - ' : ''}
            {sensor.display_name ?? ''}
          </span>
        ) : (
          <span>
            <em>{t('manage.sensors.ListSensors.noZoneAssignment')}</em>
          </span>
        )}
      </div>

      <div>
        <span
          className={cs(
            'SearchResult-status',
            sensor.is_online
              ? 'SearchResult-status--online'
              : 'SearchResult-status--offline'
          )}
        />
        {sensor.is_online
          ? t('common.commonEvents.roommate.online')
          : t('common.commonEvents.roommate.offline')}
      </div>
    </div>
  );
}
