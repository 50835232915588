import React, { useState, useEffect } from 'react';
import { FormGroup, MenuItem, TextField } from '@material-ui/core';
import ModalWrapper from '../../../../components/modals/ModalWrapper';
import { SensorsField } from './SensorsFields';
import {
  errorIsObjectError,
  useErrorHandler,
} from '../../../../lib/useErrorHandler';
import { isAxiosError } from '../../../../lib/utils/errorUtils';
import { ErrorMessages } from '../../../../components/ErrorMessages';
import { useFormik } from 'formik';

import * as Yup from 'yup';
import * as api from '../../../../serverApi';
import { t, TranslationKey } from '../../../../lib/i18n';

import './BulkAssignAccountModal.scss';
import { generateAutoCompleteValue } from '../../../../lib/utils/generateAutoCompleteValue';

export interface BulkAssignAccountModalProps {
  isOpen: boolean;
  toggle: () => void;
  mode: 'vendor_account' | 'account';
  loadAvailableAccounts: () => Promise<Array<{ id: number; label: string }>>;
  onChanged?: () => void;
  onSubmit: (values: {
    accountId: number | null;
    sensors: string;
    sensorType: api.SensorType;
  }) => Promise<api.SuperVendorBulkAssignSensorAccountResult>;
}

interface BulkAssignAccountFields {
  accountId: number | undefined | null;
  sensorIds: string;
  sensor_type: string;
}

export default function BulkAssignAccountModal(
  props: BulkAssignAccountModalProps
) {
  const errorHandler = useErrorHandler();

  const [responseData, setResponseData] = useState<
    { accountName: string; sensors: number[] } | undefined
  >();

  const [accounts, setAccounts] = useState<
    Array<{ id: number; label: string }>
  >([]);

  //TODO: check this, maybe we should change this and accept _accounts_ as prop
  const { loadAvailableAccounts } = props;

  useEffect(() => {
    let mounted = true;
    loadAvailableAccounts().then((res) => {
      if (mounted) {
        setAccounts(res);
      }
    });
    return () => {
      mounted = false;
    };
  }, [loadAvailableAccounts]);

  const formik = useFormik<BulkAssignAccountFields>({
    initialValues: {
      accountId: undefined,
      sensorIds: '',
      sensor_type: 'roommate',
    },
    validationSchema: () =>
      Yup.object().shape({
        accountId: Yup.number().nullable(),
        sensorIds: Yup.string().required(),
        sensor_type: Yup.mixed().oneOf(['roommate']).required(),
      }),
    onSubmit: async (values, helpers) => {
      if (values.accountId === undefined) {
        return;
      }

      try {
        const res = await props.onSubmit({
          accountId: values.accountId,
          sensors: values.sensorIds,
          sensorType: values.sensor_type as api.SensorType,
        });

        errorHandler.reset();
        helpers.resetForm();

        setResponseData({
          accountName: res.account?.name || '',
          sensors: res.sensors,
        });

        if (props.onChanged) {
          props.onChanged();
        }
      } catch (e) {
        /**
         * Handle custom validation error when assigning multiple sensors fails.
         * This validation error response format is different than the normal validation error response
         * because we also need the sensor_id and the code from server in order to display the errors.
         */
        if (isAxiosError(e) && e.response?.status === 422) {
          if (
            e.response?.data?.errors &&
            Array.isArray(e.response?.data?.errors) &&
            e.response.data.errors.length > 0
          ) {
            const errors = e.response.data.errors.map(
              (error: { code: string; sensor_id: string }) => {
                const errorCode = error.code;
                const errorMessage = t(
                  `common.serverError.${errorCode}` as TranslationKey
                );

                return `${error.sensor_id} - ${errorMessage}`;
              }
            );
            errorHandler.setArrayError(errors);
            return;
          }
        }
        errorHandler.handleError(e);
      }
    },
  });

  const resetModal = () => {
    errorHandler.reset();
    setResponseData(undefined);
    formik.resetForm();
  };

  const getSensorsError = () => {
    if (formik.touched.sensorIds && formik.errors.sensorIds) {
      return formik.errors.sensorIds;
    }
    if (
      errorIsObjectError(errorHandler.error) &&
      errorHandler.error['sensor_type']
    ) {
      return errorHandler.error['sensor_type'];
    }

    return undefined;
  };

  const handleOnHide = () => {
    responseData ? clearResponse() : props.toggle();
  };

  const handleOnSubmit = () => {
    responseData ? props.toggle() : formik.submitForm();
  };

  const clearResponse = () => setResponseData(undefined);

  const accountValue =
    formik.values.accountId === null ? 'null' : formik.values.accountId;

  return (
    <ModalWrapper
      title={t(
        `components.BulkAssignAccountModal.${props.mode}.title` as TranslationKey
      )}
      className="BulkAssignAccountModal"
      okButtonText={responseData ? 'Close' : 'OK'}
      cancelButtonText={responseData ? 'Assign more' : 'Cancel'}
      isOpen={props.isOpen}
      loading={formik.isSubmitting}
      onHide={handleOnHide}
      onSubmit={handleOnSubmit}
      onResetModal={resetModal}
      onToggle={props.toggle}
    >
      {responseData ? (
        <div className="BulkAssignAccountModal-response-summary">
          <span>
            Account <strong>{responseData?.accountName} </strong> was assigned
            to {responseData?.sensors?.length > 1 ? 'sensors' : 'sensor'}{' '}
            <strong>{responseData?.sensors.join(', ')}</strong>
          </span>
        </div>
      ) : (
        <>
          <FormGroup>
            <TextField
              select={true}
              name="account"
              label={t(
                `components.BulkAssignAccountModal.${props.mode}.selectAccountLabel` as TranslationKey
              )}
              value={accountValue || ''}
              onChange={(e) => {
                const { value } = e.target;
                formik.setFieldTouched('accountId');
                formik.setFieldValue(
                  'accountId',
                  value === 'null' ? null : value
                );
              }}
              error={!!formik.errors.accountId}
              helperText={formik.errors.accountId}
            >
              <MenuItem value="null">Unassign</MenuItem>
              {accounts.length > 0 ? (
                accounts.map((acc) => (
                  <MenuItem key={acc.id} value={acc.id}>
                    {acc.label}
                  </MenuItem>
                ))
              ) : (
                <MenuItem value="" disabled>
                  No available accounts
                </MenuItem>
              )}
            </TextField>
          </FormGroup>

          <SensorsField
            className="mb-3"
            disabled={formik.isSubmitting}
            value={formik.values.sensorIds}
            error={getSensorsError()}
            onChange={(sensorIds) => {
              formik.setFieldTouched('sensorIds');
              formik.setFieldValue('sensorIds', sensorIds);
            }}
          />

          <FormGroup>
            <TextField
              type="text"
              name="sensor_type"
              label="Sensor type"
              disabled={formik.isSubmitting}
              value={formik.values.sensor_type}
              InputProps={{
                readOnly: true,
              }}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              error={formik.touched.sensor_type && !!formik.errors.sensor_type}
              helperText={
                formik.touched.sensor_type && formik.errors.sensor_type
                  ? formik.errors.sensor_type
                  : undefined
              }
              autoComplete={generateAutoCompleteValue()}
            />
          </FormGroup>
        </>
      )}

      <ErrorMessages
        renderFieldErrors
        className="mt-4"
        errorData={errorHandler}
      />
    </ModalWrapper>
  );
}
