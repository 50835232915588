import React from 'react';
import { FormattedAlertSettingValues } from '../groups/formats/formatAlertSetting';
import { t } from '../../../../../lib/i18n';
import { getLabelForEventType } from '../../../../../lib/getLabelForEventType';
import {
  useZoneTreeContext,
  ZoneTreeContext,
} from '../../../../care/lib/ZoneTreeContext';
import { SensorType } from '../../../../../serverApi';

interface Props {
  values: {
    old_values?: FormattedAlertSettingValues;
    new_values?: FormattedAlertSettingValues;
  };
}

const labels: { [key: string]: string } = {
  enabled: 'Aktivert',
  event_types: 'Varseltyper',
  recipients: 'Varselmottakere',
  schedule_rules: 'Tidsplan',
  name: 'Navn',
  zone_id: 'Sone',
  escalation_enabled: 'Eskalering',
  escalation_delay: 'Eskalering: forsinkelse',
  escalation_recipients: 'Eskalering: varselmottakere',
};

const days: { [key: string]: string } = {
  mon: 'Man',
  tue: 'Tir',
  wed: 'Ons',
  thu: 'Tor',
  fri: 'Fre',
  sat: 'Lør',
  sun: 'Søn',
};

function formatScheduleRule(sr: {
  to_weekday: string;
  to_time: string;
  from_weekday: string;
  from_time: string;
}) {
  const dayPart =
    sr.to_weekday === sr.from_weekday
      ? sr.to_weekday === 'mon,tue,wed,thu,fri,sat,sun'
        ? 'Alle dager'
        : sr.to_weekday
            .split(',')
            .map((x) => days[x] || x)
            .join(', ')
      : sr.to_weekday
          .split(',')
          .map((x) => days[x] || x)
          .join(', ') +
        ' til ' +
        sr.from_weekday
          .split(',')
          .map((x) => days[x] || x)
          .join(', ');

  const hourPart =
    sr.to_time === '00:00:00' && sr.from_time === '00:00:00'
      ? 'hele døgnet'
      : `${sr.from_time}-${sr.to_time}`;

  return `${dayPart}, ${hourPart}`;
}

function formatValueByField(
  k: keyof FormattedAlertSettingValues,
  vals: undefined | FormattedAlertSettingValues,
  zt: ZoneTreeContext
) {
  if (!vals) {
    return null;
  }

  switch (k) {
    case 'enabled':
      return vals[k] ? 'På' : 'Av';

    case 'event_types':
      return vals[k]?.map((et) => (
        <div key={et.event_type}>
          {getLabelForEventType(et.event_type, et.sensor_type as SensorType)}
        </div>
      ));

    case 'recipients':
      return vals[k]?.map((r) => r.user_id.toString()).join(', ');

    case 'schedule_rules':
      return vals[k]?.map((sr, ix) => (
        <div key={ix}>{formatScheduleRule(sr)}</div>
      ));

    case 'name':
      return vals[k];

    case 'escalation_enabled':
      return vals[k] ? 'På' : 'Av';

    case 'escalation_delay':
      return vals[k];

    case 'escalation_recipients':
      return vals[k]?.map((r) => r.user_id.toString()).join(', ');

    case 'zone_id':
      if (!vals[k]) {
        return null;
      }
      const z = zt.getZoneById(vals[k]);
      return z ? z.name : `ID ${vals[k]}`;
  }
  return <code>{JSON.stringify(vals[k])}</code>;
}

function AlertSettingChanges(props: Props) {
  const of = props.values.old_values;
  const nf = props.values.new_values;

  const zt = useZoneTreeContext(); // Zone tree context used to get zone names

  function fieldRow(field: keyof FormattedAlertSettingValues) {
    const oldValue = of && field in of ? of[field] : undefined;
    const newValue = nf && field in nf ? nf[field] : undefined;

    if (oldValue === undefined && newValue === undefined) {
      return null;
    }

    return (
      <tr>
        <td>{labels[field] || field}</td>
        <td>{formatValueByField(field, of, zt)}</td>
        <td>{formatValueByField(field, nf, zt)}</td>
      </tr>
    );
  }

  return (
    <table className="ChangedFieldsSummary">
      <thead>
        <tr>
          <th>{t('manage.logs.change.ChangedFieldsSummary.fieldName')}</th>
          {of && (
            <th>
              {t('manage.logs.change.ChangedFieldsSummary.originalValue')}
            </th>
          )}
          {nf && (
            <th>{t('manage.logs.change.ChangedFieldsSummary.newValue')}</th>
          )}
        </tr>
      </thead>
      <tbody>
        {fieldRow('enabled')}
        {fieldRow('zone_id')}
        {fieldRow('name')}
        {fieldRow('event_types')}
        {fieldRow('recipients')}
        {fieldRow('schedule_rules')}
        {fieldRow('escalation_enabled')}
        {fieldRow('escalation_delay')}
        {fieldRow('escalation_recipients')}
      </tbody>
    </table>
  );
}

// return <div>{JSON.stringify(props.values, null, 2)}</div>;

export default AlertSettingChanges;
