import React, { useState } from 'react';
import cs from 'classnames';
import * as api from '../../../../../serverApi';
import { toast } from 'react-toastify';

import { List, ListItem, ListItemText } from '@material-ui/core';

import './SensorStreaming.scss';
import { ImageStreamer } from '../../../../care/ImageStreamer';
import { useAppContext } from '../../../../../lib/global';
import { t } from '../../../../../lib/i18n';

interface Props {
  sensor: api.SuperVendorSensor;
  markingObjects?: api.MarkingObject[];
  className?: string;
}

export function SensorStreaming(props: Props) {
  const sensor = props.sensor;
  const sensorCompositeId = `${sensor.sensor_type}${sensor.sensor_id}`;
  const [streamType, setStreamType] = useState<api.StreamType | null>(null);

  const app = useAppContext();

  const streamThrottle =
    app.getFeatureValueNumber('image-streaming-interval') ?? 125;

  return (
    <div className={cs('SensorStreaming', props.className)}>
      <div className="SensorStreaming-nav">
        <List>
          <ListItem
            button
            onClick={() => setStreamType(null)}
            selected={streamType === null}
          >
            <ListItemText primary="Off" />
          </ListItem>
          <ListItem
            button
            onClick={() => setStreamType('depth')}
            selected={streamType === 'depth'}
          >
            <ListItemText primary="Depth" />
          </ListItem>
          <ListItem
            button
            onClick={() => setStreamType('amp')}
            selected={streamType === 'amp'}
          >
            <ListItemText primary="Amp" />
          </ListItem>
          <ListItem
            button
            onClick={() => setStreamType('raw')}
            selected={streamType === 'raw'}
          >
            <ListItemText primary="Raw" />
          </ListItem>
        </List>
      </div>

      <div className="SensorStreaming-body">
        <div className="SensorStreaming-image">
          {streamType ? (
            <ImageStreamer
              // For the time being, we only want superusers testing this
              useSse={app.hasFeature('enable-sse')}
              markingObjects={props.markingObjects}
              className=""
              sensorId={sensorCompositeId}
              streamType={streamType}
              throttleInterval={streamThrottle}
              timeout={60}
              onTimeout={() => {
                toast.success(
                  t('care.ImageStreamer.theInspectionPeriodHasExpired')
                );
                setStreamType(null);
              }}
              demoOverlay={false}
              disableExplicitSizing={true}
            />
          ) : (
            <div className="SensorStreaming-nostream">
              Select a stream type to proceed.
            </div>
          )}
        </div>
      </div>
    </div>
  );
}
