import React, { useState } from 'react';
import { useRouter } from '../../../../lib/useRouter';
import { useAssignSensorFormik } from '../lib/useAssignSensorFormik';
import { useZoneTreeContext } from '../../../care/lib/ZoneTreeContext';
import {
  errorIsString,
  useErrorHandler,
} from '../../../../lib/useErrorHandler';
import { useConfirmation } from '../../../../lib/confirmation/ConfirmationContext';

import * as yup from 'yup';
import * as api from '../../../../serverApi';
import { t } from '../../../../lib/i18n';
import { ClimaxSensorForm } from '../ClimaxSensorForm';
import { useZoneContext } from '../../../../lib/ZoneContext';
import { IconButton } from '../../../../components/IconButton';
import { isAxiosError } from '../../../../lib/utils/errorUtils';

interface Props {
  onCreate: () => void;
}
export const AssignOtherSensorPage = (props: Props) => {
  const router = useRouter();
  const zoneTreeCtx = useZoneTreeContext();
  const zoneCtx = useZoneContext();
  const errorHandler = useErrorHandler();
  const confirmation = useConfirmation();

  const initialZone = zoneTreeCtx.getZoneById(zoneCtx.activeZone.id);

  const [selectedZone, setSelectedZone] = useState<
    api.StructureZone | undefined
  >(initialZone?.is_housing_unit ? initialZone : undefined);

  const handleSetSelectedZone = (zoneId?: number) => {
    const zone = zoneTreeCtx.getZoneById(zoneId);
    setSelectedZone(zone);
  };

  const formik = useAssignSensorFormik({
    initialValues: {
      name: '',
      is_enabled: true,
      serial: '',
      zone_id: selectedZone?.id ?? null,
      move: false,
    },
    validationSchema: () =>
      yup.object().shape({
        name: yup.string(),
        is_enabled: yup.boolean().required(),
        zone_id: yup
          .number()
          .transform((value) => (isNaN(value) ? undefined : value))
          .required(t('manage.sensors.common.zoneHousingUnitIsRequired')),
      }),
    onSubmit: async (values, formikHelpers) => {
      const sensorId = `${values.serial}`;
      if (!values.zone_id || !sensorId) {
        return;
      }

      try {
        errorHandler.error && errorHandler.reset();

        await api.updateSensors(sensorId, {
          name: values.name,
          zone_id: values.zone_id,
          is_enabled: values.is_enabled,
          move: values.move,
        });

        formikHelpers.resetForm({ values });

        if (props.onCreate) {
          props.onCreate();
        }

        router.history.push(`/manage/${values.zone_id}/sensors`);
      } catch (error) {
        if (
          isAxiosError(error) &&
          error?.response?.data?.code === 'sensor-move-to-zone-confirmation'
        ) {
          const old_zone_name = error?.response?.data?.data?.old_zone_name;
          confirmation
            .confirm(
              t(
                'manage.sensors.assign.AssignNewSensor.sensorAlreadyAssociated',
                old_zone_name
              )
            )
            .then(() => {
              formik.setFieldValue('move', true);
              formik.submitForm();
            })
            .catch(() => {});
        } else {
          errorHandler.handleError(error);
        }
      }
    },
  });

  const error = errorIsString(errorHandler.error)
    ? errorHandler.error
    : undefined;

  return (
    <div className="AssignNewSensor">
      <h1>{t('manage.sensors.common.addOtherSensor')} </h1>

      <IconButton
        icon="arrow-left"
        className="my-4"
        to={`/manage/${zoneCtx.activeZone.id}/sensors`}
      >
        {t('common.commonButtons.back')}
      </IconButton>

      <ClimaxSensorForm
        form={formik}
        error={error}
        selectedZone={selectedZone}
        onSelectZone={handleSetSelectedZone}
        hasSerial={false}
      />
    </div>
  );
};
