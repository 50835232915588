import { useMemo } from 'react';
import { useApiCall } from '../../../lib/useApiCall';
import * as api from '../../../serverApi';
import useInterval from '../../care/lib/useInterval';
import { evaluateDashboardOverviewZone } from './evaluateDashboardOverviewZone';

const refreshInterval = 3 * 1000;

export function useDashboardOverviewData(
  zone_id: string | number | undefined | null,
  skipQuery?: boolean
) {
  const params = useMemo(
    () => ({
      zone_id:
        (typeof zone_id === 'string' ? parseInt(zone_id, 10) : zone_id) ?? 0,
    }),
    [zone_id]
  );

  const res = useApiCall(api.getDashboardOverview, [params], {
    skip: !zone_id || skipQuery,
  });

  useInterval(res.reload, refreshInterval);

  const data = useMemo(() => {
    if (!res.data?.data) {
      return undefined;
    }
    return res.data.data.map(evaluateDashboardOverviewZone);
  }, [res]);

  return {
    data,
    loading: res.loading,
    error: res.error,
  };
}
