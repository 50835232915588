import { useMemo } from 'react';
import { useApiCall } from '../../../lib/useApiCall';
import * as api from '../../../serverApi';
import { Sensor, Event } from './CareContext';
import { useStatusToken } from './useStatusToken';
import { useAppContext } from '../../../lib/global';
import { SensorStatusResult } from './useSensorStatus';

interface SensorStatusLegacyOptions {
  skip?: boolean;
}

function careGetStatusByToken(token: string) {
  return api.apiRequest<string, api.CareStatus>(
    'post',
    '/api/status',
    `token=${encodeURIComponent(token)}&ts=${Date.now()}`
  );
}

/**
 * Fetch and parse sensor and event status with regular intervals.
 */
export function useSensorStatusLegacy(
  options?: SensorStatusLegacyOptions
): SensorStatusResult {
  const app = useAppContext();
  const statusToken = useStatusToken();

  // Increased from 1000ms to 1200ms to slightly improve latency/load issues
  const repeatInterval =
    app.getFeatureValueNumber('sensor-status-query-interval') ?? 1200;

  const status = useApiCall(careGetStatusByToken, [statusToken || ''], {
    skip: !statusToken || options?.skip,
    repeatInterval,
  });

  return useMemo(() => {
    // If we don't have any status data, just return empty objects
    if (!status.data) {
      return {
        alarms: undefined,
        alerts: undefined,
        sensors: undefined,
        event: {},
        sensor: {},
        dataTimestamp: undefined,
      };
    }

    const sensors = status.data.sensors;
    const events = status.data.events;

    /**
     * Loop through the sensors and get sensor by index
     */
    const sensor = sensors.reduce((coll, item) => {
      coll[item.sensor_composite_id] = item;
      return coll;
    }, {} as { [sensor_id: string]: Sensor });

    /**
     * Loop through the events and get event by index
     */
    const event = events.reduce((coll, item) => {
      coll[item.event_id] = item;
      return coll;
    }, {} as { [event_id: number]: Event });

    return {
      sensor,
      event,
      sensors,
      alarms: events.filter((x) => x.level === 'alarm'),
      alerts: events.filter((x) => x.level === 'alert'),
      dataTimestamp: Date.now(),
    };
  }, [status.data]);
}
