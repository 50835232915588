import React from 'react';
import { CreateZoneButton } from './CreateZoneButton';
import { UpdateZoneButton } from './UpdateZoneButton';
import { MoveZoneButton } from './MoveZoneButton';
import { DeleteZoneButton } from './DeleteZoneButton';
import { useZoneContext } from '../../../lib/ZoneContext';
import { ButtonGroup } from '../../../components/ButtonGroup';
import { IconButton } from '../../../components/IconButton';
import { useRouter } from '../../../lib/useRouter';
import { t } from '../../../lib/i18n';
import {
  ManageHelpDropdown,
  ManageHelpOption,
} from '../common/ManageHelpDropdown';

export default function ZoneHome() {
  const router = useRouter();
  const zoneContext = useZoneContext();
  const isHousingUnit = zoneContext.activeZone?.is_housing_unit;

  const helpDropdownOptions: ManageHelpOption[] = [
    {
      title: t('common.video.manage.createZones.title'),
      vimeoId: t('common.video.manage.createZones.vimeo'),
      type: 'video',
    },
    {
      title: t('common.video.manage.systemSettings.title'),
      vimeoId: t('common.video.manage.systemSettings.vimeo'),
      type: 'video',
    },
    {
      title: t('common.video.manage.displayName.title'),
      vimeoId: t('common.video.manage.displayName.vimeo'),
      type: 'video',
    },
    {
      title: t('common.video.manage.globalSettings.title'),
      vimeoId: t('common.video.manage.globalSettings.vimeo'),
      type: 'video',
    },
    {
      title: t('common.pdf.manage.systemAdministrator.title'),
      link: t('common.url.adminUserGuidePdf'),
      type: 'pdf',
    },
  ];

  return (
    <>
      <div className="Manage__heading-wrapper">
        <h1 className="m-0">
          {isHousingUnit
            ? `${t('manage.zone.ZoneHome.selectedHousingUnit')}: ${
                zoneContext.activeZone.name
              }`
            : `${t('manage.zone.ZoneHome.selectedZone')}: ${
                zoneContext.activeZone.name
              }`}
        </h1>
        <ManageHelpDropdown options={helpDropdownOptions} />
      </div>

      <div className="my-4">
        <ButtonGroup direction="vertical" className="align-items-start">
          {isHousingUnit ? (
            <>
              <UpdateZoneButton />

              <MoveZoneButton />

              <DeleteZoneButton />
            </>
          ) : (
            <>
              <CreateZoneButton />

              <UpdateZoneButton />

              <MoveZoneButton />

              <DeleteZoneButton />
            </>
          )}
        </ButtonGroup>

        <hr />

        <IconButton
          icon="gear"
          color="primary"
          onClick={() =>
            router.history.push(
              `/manage/${zoneContext.activeZone.id}/zone/settings`
            )
          }
        >
          {t('manage.zone.ZoneHome.showZoneSettings')}
        </IconButton>
        <br />
      </div>
    </>
  );
}
