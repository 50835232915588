import * as api from '../../../serverApi';
import { name, version, UserAgent, SIPExtension } from 'sip.js';
//import { UserAgent } from '@sentry/browser/dist/integrations';

/**
 * Takes the configuration received from the server as input and
 * creates a new client configuration object for sip.js.
 */
export function getSipUserAgentOptions(s: api.CareWebRtcConfig) {
  return {
    sessionDescriptionHandlerFactoryOptions: {
      constraints: {
        audio: true,
        video: false,
      },
      peerConnectionOptions: {
        iceCheckingTimeout: 400,
        rtcConfiguration: {
          iceServers: s.iceServers,
        },
      },
    },
    transportOptions: {
      wsServers: [`wss://${s.voipServerHost}/ws`],
      traceSip: s.voipDebug,
      keepAliveInterval: 15,
      maxReconnectionAttempts: 60,
      reconnectionTimeout: 10
    },
    delegate: {},
    uri: UserAgent.makeURI(`sip:${s.sipUsername}@${s.voipServerHost}`),
    authorizationHa1: s.sipHa1,
    userAgentString: `${name} ${version} on ${s.sipUserAgent}`,
    sipExtension100rel: SIPExtension.Required,
    register: false,
    autoStart: false,

    log: {
      builtinEnabled: s.voipDebug,
      logLevel: "log",
    },
  };
}
