import React from 'react';

import { AuditLog } from './audit/AuditLog';
import { EventLog } from './event/EventLog';
import { VkpLog } from './vkp/VkpLog';
import { ObservationLog } from './observation/ObservationLog';
import { LogTypeSelector } from './ui/LogTypeSelector';

import { RouteComponentProps, Redirect } from 'react-router';
import { useZoneContext } from '../../../lib/ZoneContext';
import { t } from '../../../lib/i18n';

import './Logs.scss';
import { AuditChangeLog } from './audit-change/AuditChangeLog';
import {
  ManageHelpDropdown,
  ManageHelpOption,
} from '../common/ManageHelpDropdown';

function getValidLogType(area: string | undefined) {
  if (
    area === 'audit' ||
    area === 'audit-change' ||
    area === 'observation' ||
    area === 'vkp' ||
    area === 'event'
  ) {
    return area;
  }
  return undefined;
}

export default function Logs(
  props: RouteComponentProps<{ log: string | undefined }>
) {
  const ctx = useZoneContext();
  const logType = getValidLogType(props.match.params.log);

  if (!logType) {
    return <Redirect to={`/manage/${ctx.activeZone.id}/logs/audit`} />;
  }

  const helpDropdownOptions: ManageHelpOption[] = [
    {
      title: t('common.video.manage.logging.title'),
      vimeoId: t('common.video.manage.logging.vimeo'),
      type: 'video',
    },
    {
      title: t('common.pdf.manage.systemAdministrator.title'),
      link: t('common.url.adminUserGuidePdf'),
      type: 'pdf',
    },
  ];

  return (
    <>
      <div className="Manage__heading-wrapper">
        <h1 className="m-0">{t('manage.logs.Logs.logs')}</h1>
        <ManageHelpDropdown options={helpDropdownOptions} />
      </div>

      <LogTypeSelector
        className="my-4"
        logType={logType}
        onLogTypeChange={(lt) =>
          props.history.push(`/manage/${ctx.activeZone.id}/logs/${lt}`)
        }
      />

      {logType === 'audit' && <AuditLog className="mt-5" />}

      {logType === 'audit-change' && <AuditChangeLog className="mt-5" />}

      {logType === 'event' && <EventLog className="mt-5" />}

      {logType === 'vkp' && <VkpLog className="mt-5" />}

      {logType === 'observation' && <ObservationLog className="mt-5" />}
    </>
  );
}
