import { useEffect, useState, useCallback } from 'react';
import { Event } from './CareContext';

/**
 * Manage state for whether the alarm/alert sections are blinking/attention-seeking
 */
export function useSensorEventAttention(events: Event[] | undefined) {
  // When seenEvents is undefined, it means we have not received the initial events yet.
  const [seenEvents, setSeenEvents] = useState<undefined | number[]>(undefined);

  // The number of unseen events
  const [numberOfUnseen, setNumberOfUnseen] = useState(0);

  /**
   * This runs every time the event array changes.
   */
  useEffect(() => {
    // As long as the events are undefined, we clear the state and do nothing.
    if (events === undefined) {
      setNumberOfUnseen(0);
      setSeenEvents(undefined);
      return;
    }

    // If seenEvents is undefined, it means this is the first iteration, and we simply
    // store the currently seen events without enabling attention. We set the seen
    // events to the initial events to avoid starting with any attention grabbers.
    if (!seenEvents) {
      setSeenEvents(events.map(e => e.event_id));
      return;
    }

    // This far is the regular operation. We check to see if any of the events
    // in the current events array are _not_ in our own seenEvents state array.
    let unseenCount = 0;
    if (events?.length) {
      for (let i = 0, j = events.length; i < j; i++) {
        if (!seenEvents.includes(events[i].event_id)) {
          unseenCount += 1;
        }
      }
    }
    setNumberOfUnseen(unseenCount);

    // To avoid building up a huge array of seen events, we remove
    // any seen events that are no longer in the events array.
    const newSeenEvents = events?.length
      ? seenEvents.filter(eid => events.find(evt => evt.event_id === eid))
      : [];

    if (newSeenEvents.length !== seenEvents.length) {
      setSeenEvents(newSeenEvents);
    }
  }, [events, seenEvents]);

  const clear = useCallback(() => {
    setSeenEvents(events?.map(e => e.event_id) ?? []);
  }, [events]);

  return {
    attention: numberOfUnseen > 0,
    clear,
    numberOfUnseen,
  };
}
