import React from 'react';

import MUITable from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';

import { TableWrapper } from '../../../components/TableWrapper';
import { TableLoadingPlaceholder } from './TableLoadingPlaceholder';

import { t } from '../../../lib/i18n';
import { SortIcon } from '../../../components/SortIcon';

export interface SortColumnI {
  key: string; //Column key
  type: 'asc' | 'desc'; //Sorting type
}
interface TableColumnI {
  key: string;
  header: string;
  style?: React.CSSProperties;
  sortable?: boolean;
}

interface TableRowI {
  [key: string]: React.ReactNode;
  className?: string;
  style?: React.CSSProperties;
  id: number | string;
}

interface Props {
  className?: string;
  columns: TableColumnI[];
  rows: TableRowI[];
  loading?: boolean;
  loadingPlaceholder?: JSX.Element;
  customPlaceholder?: { when: boolean; content: JSX.Element };
  sortColumn?: SortColumnI;
  onSortClick?: (columnKey: string) => void;
}

export const Table = (props: Props) => {
  const {
    className,
    rows,
    columns,
    loading,
    loadingPlaceholder,
    customPlaceholder,
    sortColumn,
    onSortClick,
  } = props;

  let tableBody: React.ReactNode = null;

  if (customPlaceholder && customPlaceholder.when) {
    tableBody = customPlaceholder.content;
  } else if (loading && rows.length === 0) {
    tableBody = loadingPlaceholder || (
      <TableLoadingPlaceholder colSpan={columns.length} />
    );
  } else if (rows.length === 0) {
    tableBody = (
      <TableRow>
        <TableCell
          colSpan={columns.length}
          style={{ textAlign: 'center', height: '70px' }}
        >
          {t('care.common.noDataAvailable')}
        </TableCell>
      </TableRow>
    );
  } else {
    tableBody = rows.map((row) => (
      <TableRow key={row.id} className={row.className} style={row.style}>
        {columns.map((column) => (
          <React.Fragment key={`${column.key}--${row.id}`}>
            {row[column['key']]}
          </React.Fragment>
        ))}
      </TableRow>
    ));
  }

  return (
    <TableWrapper>
      <MUITable className={className}>
        <TableHead>
          <TableRow>
            {columns.map((col) => (
              <TableCell key={col.key} style={col.style}>
                <div style={{ display: 'flex', alignItems: 'center' }}>
                  {col.header}
                  {col.sortable && (
                    <SortIcon
                      type={
                        sortColumn?.key === col.key
                          ? sortColumn.type
                          : undefined
                      }
                      onClick={() => onSortClick?.(col.key)}
                    />
                  )}
                </div>
              </TableCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody>{tableBody}</TableBody>
      </MUITable>
    </TableWrapper>
  );
};
