import React from 'react';
import { RouteComponentProps, Switch, Route } from 'react-router';
import { ListVendors } from './ListVendors';

export function Vendors(props: RouteComponentProps<{}>) {
  return (
    <Switch>
      <Route exact={true} path={props.match.path} component={ListVendors} />
    </Switch>
  );
}
