import React, { useState, FormEvent, CSSProperties } from 'react';
// import moment from 'moment';
import cs from 'classnames';
import { Form } from 'reactstrap';
import { Button } from '../../components/Button';
import { Panel } from '../../components/Panel';
import { TextField, FormGroup } from '@material-ui/core';

import { RouteLeavingGuard } from '../../components/RouteLeavingGuard';
import { useAppContext } from '../../lib/global';
import { errorIsObjectError, useErrorHandler } from '../../lib/useErrorHandler';
import { ErrorMessages } from '../../components/ErrorMessages';
import { ButtonGroup } from '../../components/ButtonGroup';

import * as api from '../../serverApi';

import { t } from '../../lib/i18n';

import './ChangePasswordForm.scss';
import { generateAutoCompleteValue } from '../../lib/utils/generateAutoCompleteValue';

interface Props {
  style?: CSSProperties;
  className?: string;
  onChanged?: () => void;
}

export function ChangePasswordForm(props: Props) {
  const app = useAppContext();
  const errorHandler = useErrorHandler();

  const [loading, setLoading] = useState(false);
  const [success, setSuccess] = useState('');
  const [currentPassword, setCurrentPassword] = useState('');
  const [newPassword, setNewPassword] = useState('');
  const [verifiedPassword, setVerifiedPassword] = useState('');

  const passwordIsExpiring =
    app.user &&
    app.user?.password_expiration_days &&
    app.user.password_expiration_days < 7
      ? app.user.password_expiration_days
      : null;

  const passwordExpired = app.user?.password_expiration_days === 0;

  // const passwordExpiringDate = !!passwordIsExpiring
  //   ? moment()
  //       .add(passwordIsExpiring, 'd')
  //       .format('LL')
  //   : null;

  const passwordExpiringDays = app.user?.password_expiration_days ?? null;

  const verifyPassword = () => {
    if (newPassword !== verifiedPassword) {
      errorHandler.setObjectError({
        verified_password: t('user.ChangePasswordForm.verifyYourPassword'),
      });
      return false;
    }
    return true;
  };

  async function submit(e: FormEvent<EventTarget>) {
    e.preventDefault();

    const userId = app.user && app.user.id;

    if (!userId) {
      return;
    }

    if (!verifyPassword()) {
      return;
    }

    try {
      setLoading(true);
      setSuccess('');
      await api.changePassword({
        current_password: currentPassword,
        new_password: newPassword,
      });
      setLoading(false);
      setCurrentPassword('');
      setNewPassword('');
      setVerifiedPassword('');
      setSuccess(t('user.ChangePasswordForm.passwordChangedSuccessfully'));
      errorHandler.error && errorHandler.reset();
      if (props.onChanged) {
        props.onChanged();
      }
    } catch (error) {
      errorHandler.handleError(error);
      setLoading(false);
    }
  }

  const dirty =
    newPassword !== '' || currentPassword !== '' || verifiedPassword !== '';

  return (
    <div
      className={cs('ChangePasswordForm', props.className)}
      style={{ ...props.style }}
    >
      <RouteLeavingGuard when={dirty} />
      <Form onSubmit={submit}>
        <FormGroup>
          <TextField
            type="password"
            name="current_pass"
            placeholder={t('user.ChangePasswordForm.currentPassword')}
            className="w-100"
            disabled={loading}
            value={currentPassword}
            autoComplete={generateAutoCompleteValue()}
            InputProps={{
              className: 'text-field-input',
            }}
            error={
              errorIsObjectError(errorHandler.error) &&
              !!errorHandler.error['current_password']
            }
            helperText={
              errorIsObjectError(errorHandler.error) &&
              errorHandler.error['current_password']
            }
            onChange={(e) => setCurrentPassword(e.currentTarget.value)}
          />
        </FormGroup>

        <FormGroup>
          <TextField
            type="password"
            name="new_pass"
            autoComplete={generateAutoCompleteValue()}
            placeholder={t('user.ChangePasswordForm.newPassword')}
            disabled={loading}
            value={newPassword}
            InputProps={{
              className: 'text-field-input',
            }}
            error={
              errorIsObjectError(errorHandler.error) &&
              !!errorHandler.error['new_password']
            }
            helperText={
              errorIsObjectError(errorHandler.error) &&
              errorHandler.error['new_password']
            }
            onChange={(e) => setNewPassword(e.currentTarget.value)}
          />
        </FormGroup>

        <FormGroup>
          <TextField
            type="password"
            name="verified_password"
            autoComplete={generateAutoCompleteValue()}
            placeholder={t('user.ChangePasswordForm.confirmNewPassword')}
            disabled={loading}
            value={verifiedPassword}
            InputProps={{
              className: 'text-field-input',
            }}
            error={
              errorIsObjectError(errorHandler.error) &&
              !!errorHandler.error['verified_password']
            }
            helperText={
              errorIsObjectError(errorHandler.error) &&
              errorHandler.error['verified_password']
            }
            onChange={(e) => setVerifiedPassword(e.currentTarget.value)}
          />
        </FormGroup>

        <div className="mt-3">
          <ButtonGroup direction="horizontal">
            <Button
              type="submit"
              color="primary"
              disabled={loading}
              icon={loading ? 'gear' : undefined}
              spinIcon={loading}
            >
              {t('common.commonButtons.save')}
            </Button>

            {!passwordExpired && (
              <Button to="/">{t('common.commonButtons.cancel')}</Button>
            )}
          </ButtonGroup>

          <ErrorMessages errorData={errorHandler} className="mt-2" />

          {success && (
            <div className="mt-2 text-success">
              {t('user.ChangePasswordForm.thePasswordWasChanged')}.
            </div>
          )}

          {passwordIsExpiring && (
            <Panel className="mt-4" color="danger">
              <h3>{t('user.ChangePassword.timeToChangePassword')}</h3>
              {t('user.ChangePasswordForm.yourPasswordWillExpireIn')}{' '}
              {passwordExpiringDays}{' '}
              {passwordExpiringDays === 1
                ? t('common.commonTexts.day')
                : t('common.commonTexts.days')}
              .
            </Panel>
          )}

          <Panel className="mt-4">
            <h3>{t('common.commonTexts.tips')}</h3>
            {t('common.commonTexts._passwordTips')}
          </Panel>
        </div>
      </Form>
    </div>
  );
}
