import React from 'react';

import { HelpBlocks } from '../../components/HelpBlocks';
import { HelpBlock } from '../../components/HelpBlock';
import { useCareContext } from './lib/CareContext';
import { Icon } from '../../components/Icon';
import { t } from '../../lib/i18n';

import { VideoHelpBlock } from '../../components/VideoHelpBlock';
import { openLinkInNewTab } from '../../lib/openLinkInNewTab';

import './CareHelpPage.scss';

export function CareHelpPage() {
  const care = useCareContext();

  return (
    <div className="CareHelpPage">
      <h1 className="Care-splash-title mb-4 d-none d-md-block">
        <Icon icon="arrow-left" />{' '}
        {t('care.common.selectHousingUnitSensorInTheLeftFieldForSupervision')}
      </h1>

      <h1 className="Care-splash-title mb-3 ml-2">
        {t('care.CareHelpPage.helpVideos')}
      </h1>

      <HelpBlocks>
        <HelpBlock icon="help" onClick={() => care.setShowHelpModal(true)}>
          {t('care.CareHelpPage.symbolListWithExplanations')}
        </HelpBlock>

        <VideoHelpBlock
          icon="video-screens-combined"
          title={t('common.video.care.guideToCareMobile.title')}
          vimeoId={t('common.video.care.guideToCareMobile.vimeo')}
        />

        <VideoHelpBlock
          icon="video-mobile"
          title={t('common.video.care.guideToUseTheApp.title')}
          vimeoId={t('common.video.care.guideToUseTheApp.vimeo')}
        />

        <VideoHelpBlock
          icon="roommate"
          title={t('common.video.care.guideToCare.title')}
          vimeoId={t('common.video.care.guideToCare.vimeo')}
        />

        <VideoHelpBlock
          icon="check"
          title={t('common.video.care.guideToReceipt.title')}
          vimeoId={t('common.video.care.guideToReceipt.vimeo')}
        />

        <VideoHelpBlock
          icon="epj"
          title={t('common.video.care.guideToEpj.title')}
          vimeoId={t('common.video.care.guideToEpj.vimeo')}
        />

        <VideoHelpBlock
          icon="pause-card"
          title={t('common.video.care.guideToSettingPresence.title')}
          vimeoId={t('common.video.care.guideToSettingPresence.vimeo')}
        />

        <VideoHelpBlock
          icon="info"
          title={t('common.video.care.guideToInfoAndActivityOverview.title')}
          vimeoId={t('common.video.care.guideToInfoAndActivityOverview.vimeo')}
        />

        <HelpBlock
          icon="book"
          onClick={() => openLinkInNewTab(t('common.url.careUserGuidePdf'))}
          isPdf
        >
          {t(
            'common.commonTexts.downloadUserGuideIncludingGlossaryAndExplanationOfBasicConcepts'
          )}
        </HelpBlock>
      </HelpBlocks>
    </div>
  );
}
