import React from 'react';
import cs from 'classnames';
import { Link } from 'react-router-dom';

import { getLabelForEventType } from '../../../lib/getLabelForEventType';
import { useSensorContext } from '../../../lib/SensorContext';
import { useRouter } from '../../../lib/useRouter';
import { t } from '../../../lib/i18n';

import './AlertIconsForSensor.scss';
import {
  eventTypesBySensorType,
  eventTypesInOrderBySensorType,
} from '../../../eventTypes';
import { Icon } from '../../../components/Icon';
import { Tooltip } from '../../../components/Tooltip/Tooltip';

interface Props {
  className?: string;
  large?: boolean;
  hideDisabledEventTypes?: boolean;
  linkToEventSettings?: boolean;

  /**
   * Whether to display the level/audio set indicators in the corner of each icon.
   */
  displayLevelIndicators?: boolean;
}

export default function AlertIconsForSensor(props: Props) {
  const router = useRouter();
  const sensor = useSensorContext();

  if (!sensor.eventTypes) {
    return null;
  }

  const et = sensor.eventTypes;

  const eventTypesInOrder: string[] =
    eventTypesInOrderBySensorType[sensor.sensor_type] ?? [];

  return (
    <div
      className={cs(
        'AlertIconsForSensor',
        { 'AlertIconsForSensor--large': props.large },
        props.className
      )}
    >
      {eventTypesInOrder.map((eventType) => {
        const e = eventTypesBySensorType[sensor.sensor_type][eventType];

        if (!e) {
          return null;
        }

        // Event not defined; this means that the sensor does not have that event
        // enabled at all -- i.e. no row in the event_settings table
        if (!et[eventType]) {
          return null;
        }

        const { enabled, level, audio_set } = et[eventType];

        if (props.hideDisabledEventTypes && !enabled) {
          return null;
        }

        const label = `${getLabelForEventType(
          eventType,
          sensor.sensor_type
        )} (${
          enabled
            ? t('common.commonTexts.enabled')
            : t('common.commonTexts.disabled')
        })`;

        return (
          <Tooltip title={label} aria-label={label} key={eventType}>
            <div
              className={cs(
                'AlertIconsForSensor-event',
                `AlertIconsForSensor-event--${level}`,
                props.linkToEventSettings && 'AlertIconsForSensor-event-link'
              )}
            >
              {props.linkToEventSettings ? (
                <Link
                  to={`${router.location.pathname}/${sensor.id}/alerts/${eventType}`}
                >
                  <Icon
                    icon={e.icon}
                    className={cs(
                      'AlertIconsForSensor-icon',
                      enabled ? 'enabled' : undefined
                    )}
                  />
                </Link>
              ) : (
                <Icon
                  icon={e.icon}
                  className={cs(
                    'AlertIconsForSensor-icon',
                    enabled ? 'enabled' : undefined
                  )}
                />
              )}
              {props.displayLevelIndicators &&
                enabled &&
                level &&
                level !== 'info' && (
                  <div
                    className={`AlertIconsForSensor-level AlertIconsForSensor-level--${level}`}
                  >
                    {audio_set}
                  </div>
                )}
            </div>
          </Tooltip>
        );
      })}
    </div>
  );
}
