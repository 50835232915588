import React, { CSSProperties } from 'react';
import cs from 'classnames';

import { Icon } from '../../components/Icon';
import { UserStatusType, userStatusTypes } from '../../userStatusTypes';

interface UserStatusIconProps {
  userStatus: string;
  className?: string;
  style?: CSSProperties;
}

export function UserStatusIcon(props: UserStatusIconProps) {
  const icon = userStatusTypes[props.userStatus as UserStatusType]?.icon;

  if (!icon) {
    return null;
  }

  return (
    <Icon
      icon={icon}
      className={cs('UserStatusIcon', props.className)}
      style={props.style}
    />
  );
}
