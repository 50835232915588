import { FormikHelpers, FormikValues, useFormik } from 'formik';
import * as yup from 'yup';
import { t } from '../../../../lib/i18n';
import { SensorType } from '../../../../serverApi';

export interface SensorAdvancedFormFields {
  demo_mode: boolean;
  sensor_algorithm_config_bits: number;
  modulation_frequency?: number;
}

export type SensorAdvancedFormik = ReturnType<typeof useSensorAdvancedForm>;

interface UseSensorAdvancedFormikOptions {
  initialValues?: SensorAdvancedFormFields;
  sensorType: SensorType;
  onSubmit: (
    values: FormikValues,
    formikHelpers: FormikHelpers<SensorAdvancedFormFields>
  ) => Promise<any>;
}

export function useSensorAdvancedForm(opts?: UseSensorAdvancedFormikOptions) {
  return useFormik<SensorAdvancedFormFields>({
    initialValues: {
      demo_mode: false,
      modulation_frequency: 15000000,
      sensor_algorithm_config_bits: 0,
      ...opts?.initialValues,
    },
    enableReinitialize: true,
    validateOnChange: true,
    validateOnBlur: true,
    validationSchema: () =>
      yup.object().shape({
        demo_mode: yup.boolean(),
        sensor_algorithm_config_bits:
          opts?.sensorType === 'roommate' //This field should be required only if sensor type is roommate
            ? yup
                .number()
                .required()
                .integer()
                .typeError(
                  t(
                    'manage.sensors.details.useSensorAdvancedForm.thisFieldShouldContainOnlyIntegerNumbers'
                  )
                )
            : yup
                .number()
                .integer()
                .nullable()
                .typeError(
                  t(
                    'manage.sensors.details.useSensorAdvancedForm.thisFieldShouldContainOnlyIntegerNumbers'
                  )
                ),
        modulation_frequency: yup.number().integer().nullable(),
      }),
    onSubmit: async (values: FormikValues, formikHelpers) => {
      if (opts?.onSubmit) {
        /**
         * The advanced sensor settings section fields are mostly for roommate type sensors (except the demo_mode field)
         * so, if it's roommmate sensor, we send all the fields as request payload
         */
        if (opts.sensorType === 'roommate') {
          await opts.onSubmit(values, formikHelpers);
          return;
        }
        /***
         * Otherwise, we just provide the demo_mode field for non-roommate sensors
         */
        await opts.onSubmit({ demo_mode: values.demo_mode }, formikHelpers);
      }
    },
  });
}
