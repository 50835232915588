import React from 'react';
import * as api from '../../../../../serverApi';
import { AssignmentDetailsCard } from '../shared/AssignmentDetailsCard';
import { SimulateEventPanel } from '../../../../../components/SimulateEventPanel';

import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import Typography from '@material-ui/core/Typography';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { Card } from '../../../../super/shared/Card';

interface Props {
  sensor: api.SuperVendorSensor;
  onRequestReload?: () => void;
}

export function SensorClimaxDetails(props: Props) {
  return (
    <>
      <AssignmentDetailsCard
        sensor={props.sensor}
        onRequestReload={props.onRequestReload}
      />

      <Card className="Card--accordion my-4">
        <Accordion TransitionProps={{ unmountOnExit: true }}>
          <AccordionSummary expandIcon={<ExpandMoreIcon />}>
            <Typography>Simulate sensor events</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <SimulateEventPanel
              sensor_type={props.sensor.sensor_type}
              sensor_id={props.sensor.sensor_id}
              showEventSettings={true}
            />
          </AccordionDetails>
        </Accordion>
      </Card>
    </>
  );
}
