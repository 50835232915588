import React from 'react';
import * as api from '../../../../serverApi';
import { ScheduleSelectorRule } from './ScheduleSelectorRule';
import { IconButton } from '../../../../components/IconButton';
import { Checkbox, FormControlLabel } from '@material-ui/core';
import FormGroup from '@material-ui/core/FormGroup';
import {
  ErrorHandlerType,
  errorIsObjectError,
  errorIsString,
} from '../../../../lib/useErrorHandler';
import { t } from '../../../../lib/i18n';

import './ScheduleSelector.scss';

interface Props {
  scheduleRules?: api.AlertSettingScheduleRule[];
  onChange?: (scheduleRules: api.AlertSettingScheduleRule[]) => void;
  disabled?: boolean;
  error?: ErrorHandlerType;
}

// prettier-ignore
export const allDays: api.Weekday[] = ['mon', 'tue', 'wed', 'thu', 'fri', 'sat', 'sun'];

const defaultScheduleRule: api.AlertSettingScheduleRule = {
  from_time: '08:00:00',
  from_weekday: ['mon'],
  to_time: '22:00:00',
  to_weekday: ['mon'],
};

export function ScheduleSelector(props: Props) {
  function onChange(rules: api.AlertSettingScheduleRule[]) {
    if (props.onChange) {
      props.onChange(rules);
    }
  }

  function onCheckboxChange(e: React.ChangeEvent<HTMLInputElement>) {
    if (props.onChange && props.scheduleRules) {
      props.onChange([
        {
          from_time: '08:00:00',
          from_weekday: e.target.checked ? allDays : ['mon'],
          to_time: '22:00:00',
          to_weekday: e.target.checked ? allDays : ['mon'],
        },
      ]);
    }
  }

  function addNewSchedule() {
    if (!props.scheduleRules || props.scheduleRules.length === 0) {
      onChange([...srs, defaultScheduleRule]);
      return;
    }

    const lastFromWeekDay =
      props.scheduleRules[props.scheduleRules.length - 1]['from_weekday'];

    const lastFromDay =
      lastFromWeekDay.length === 1 ? lastFromWeekDay[0] : 'mon';

    const lastFromDayIndex = allDays.indexOf(lastFromDay);

    const nextDay =
      lastFromDayIndex === allDays.length - 1
        ? 'mon'
        : allDays[lastFromDayIndex + 1];

    onChange([
      ...srs,
      {
        from_time: '08:00:00',
        from_weekday: [nextDay] as api.Weekday[],
        to_time: '22:00:00',
        to_weekday: [nextDay] as api.Weekday[],
      },
    ]);
  }

  const srs = props.scheduleRules || [];

  const firstRule = srs.length === 1 ? srs[0] : null;

  const isAllDays =
    !!firstRule &&
    firstRule.from_weekday.length === 7 &&
    firstRule.to_weekday.length === 7;

  function hasScheduleError(scheduleIndex: number, name: string) {
    if (
      !props.error ||
      !props.error.error ||
      errorIsString(props.error.error)
    ) {
      return '';
    }

    const key = `schedule.${scheduleIndex}.${name}`;

    if (Object.keys(props.error.error).includes(key)) {
      return errorIsObjectError(props.error.error)
        ? props.error.error[key]
        : undefined;
    }
  }

  return (
    <div className="ScheduleSelector">
      <div>
        <h5>{t('manage.alerts.common.schedule')}</h5>
      </div>

      {srs.length ? (
        <>
          <FormGroup className="mb-2">
            <FormControlLabel
              control={
                <Checkbox
                  checked={isAllDays}
                  color="primary"
                  onChange={onCheckboxChange}
                  value="allDays"
                  disabled={props.disabled}
                />
              }
              label={t('manage.alerts.ui.ScheduleSelector.allDays')}
            />
          </FormGroup>

          {srs.map((sr, idx) => {
            const fromTimeError = hasScheduleError(idx, 'from_time');
            const toTimeError = hasScheduleError(idx, 'to_time');
            return (
              <ScheduleSelectorRule
                key={idx}
                disabled={props.disabled}
                scheduleRule={sr}
                allDays={isAllDays}
                onChange={(sr) =>
                  onChange([...srs.slice(0, idx), sr, ...srs?.slice(idx + 1)])
                }
                onDelete={() =>
                  onChange([...srs.slice(0, idx), ...srs?.slice(idx + 1)])
                }
                fromTimeError={fromTimeError}
                toTimeError={toTimeError}
              />
            );
          })}
        </>
      ) : (
        <div>{t('manage.alerts.ui.ScheduleSelector.noScheduleHasBeenSet')}</div>
      )}

      <IconButton
        color="primary"
        icon="plus"
        className="mt-2"
        disabled={props.disabled || isAllDays}
        onClick={addNewSchedule}
      >
        {t('manage.alerts.ui.ScheduleSelector.addTimeInterval')}
      </IconButton>
    </div>
  );
}
