import React from 'react';
import { AssignSensorFormik } from './lib/useAssignSensorFormik';
import {
  Checkbox,
  FormControlLabel,
  FormGroup,
  TextField,
} from '@material-ui/core';
import { ZonePickerField } from '../../../components/ZonePickerField/ZonePickerField';
import { Button } from '../../../components/Button';
import { InlineError } from '../../../components/InlineError';
import { ButtonGroup } from '../../../components/ButtonGroup';
import { RouteLeavingGuard } from '../../../components/RouteLeavingGuard';
import { useHandleCancel } from '../../../lib/useHandleCancel';

import * as api from '../../../serverApi';
import { Panel } from '../../../components/Panel';
import { useZoneContext } from '../../../lib/ZoneContext';
import { t } from '../../../lib/i18n';
import { generateAutoCompleteValue } from '../../../lib/utils/generateAutoCompleteValue';

interface Props {
  error?: string;
  form?: AssignSensorFormik;
  selectedZone?: api.StructureZone;
  onSelectZone?: (zoneId?: number) => void;
}

export const SensorForm = (props: Props) => {
  const hc = useHandleCancel();
  const zoneCtx = useZoneContext();

  const setSelectedZone = (zoneId?: number) => {
    props.form?.setFieldValue('zone_id', zoneId || null);
    props.form?.setFieldTouched('zone_id');
    if (props.onSelectZone) {
      props.onSelectZone(zoneId);
    }
  };

  const getDisabledZoneIds = (z?: api.StructureZone): number[] => {
    let response = [] as number[];

    if (!z) {
      return [] as number[];
    }

    if (!z.is_housing_unit || (z.zones && z.zones.length > 0)) {
      response = response.concat(z.id);
    }

    z.zones?.forEach((zone: api.StructureZone) => {
      response = response.concat(getDisabledZoneIds(zone));
    });

    return response;
  };

  if (!props.form) {
    return null;
  }

  const disabledZoneIds = getDisabledZoneIds(zoneCtx.rootZone);

  // const notValidZone = Boolean(
  //   !props.selectedZone?.is_housing_unit ||
  //     (props.selectedZone.zones && props.selectedZone.zones.length > 0)
  // );

  const disabled = props.form.isSubmitting;

  const selectedZone = props.selectedZone
    ? {
        id: props.selectedZone?.id,
        name: props.selectedZone.name,
      }
    : undefined;

  return (
    <div className="AssignSensorForm">
      <RouteLeavingGuard when={props.form?.dirty} />

      <Panel className="my-3">
        <strong>{t('common.commonTexts.tips')}:</strong>{' '}
        {t(
          'manage.sensors.SensorForm.createHousingUnitBeforeAddingSensorSensorCanOnlyBeAddedToHousingUnit'
        )}
        .
      </Panel>

      <form onSubmit={props.form?.handleSubmit}>
        <FormGroup className="mb-3">
          <TextField
            type="text"
            name="serial"
            id="serial"
            label={t('manage.sensors.common.serialNumber')}
            disabled={disabled}
            value={props?.form.values.serial}
            style={{ maxWidth: '400px' }}
            onChange={props.form.handleChange}
            onBlur={props.form.handleBlur}
            error={
              props.form.touched.serial &&
              props.form.errors.serial !== undefined
            }
            helperText={props.form?.touched.serial && props.form.errors.serial}
            autoComplete={generateAutoCompleteValue()}
          />
        </FormGroup>

        <FormControlLabel
          control={
            <Checkbox
              color="primary"
              disabled={disabled}
              checked={props.form?.values.is_enabled}
              onChange={(e) => {
                props.form?.setFieldValue('is_enabled', e.target.checked);
                props.form?.setFieldTouched('is_enabled');
              }}
            />
          }
          label={t('manage.sensors.SensorForm.activateSensor')}
          className="mb-3"
        />

        <FormGroup className="mb-3">
          <TextField
            type="text"
            name="name"
            label={t('manage.sensors.common.sensorName')}
            disabled={disabled}
            value={props?.form.values.name}
            style={{ maxWidth: '400px' }}
            onChange={props.form.handleChange}
            onBlur={props.form.handleBlur}
            error={props.form.errors.name !== undefined}
            helperText={props.form.errors.name}
            autoComplete={generateAutoCompleteValue()}
          />
        </FormGroup>

        <ZonePickerField
          disabled={disabled}
          label={t('common.commonTexts.housingUnit')}
          className="mb-3"
          textFieldStyle={{
            maxWidth: '400px',
            flex: '1 1 auto',
          }}
          error={props.form?.touched.zone_id ? props.form.errors.zone_id : ''}
          disabledZoneIds={disabledZoneIds}
          initialZoneId={props.selectedZone?.id}
          selectedZone={selectedZone}
          onSelectZone={(zone) => setSelectedZone(zone?.id)}
          onClear={() => setSelectedZone(undefined)}
        />

        <ButtonGroup direction="horizontal" className="mt-5">
          <Button
            color="primary"
            type="submit"
            disabled={disabled}
            icon={props.form?.isSubmitting ? 'gear' : undefined}
            spinIcon={props.form?.isSubmitting}
            small={true}
          >
            {t('common.commonButtons.ok')}
          </Button>
          <Button
            onClick={() => {
              hc.handleCancel(props.form?.dirty, null, () =>
                props.form?.resetForm()
              );
            }}
          >
            {t('common.commonButtons.cancel')}
          </Button>
        </ButtonGroup>

        {props.error && <InlineError>{props.error}</InlineError>}
      </form>
    </div>
  );
};
