import React, { useEffect, useState } from 'react';
import cs from 'classnames';
import {
  Collapse,
  Navbar,
  NavbarToggler,
  NavbarBrand,
  Nav,
  NavItem,
  NavLink,
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from 'reactstrap';
import * as Sentry from '@sentry/browser';

import { Link } from 'react-router-dom';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faUserCircle,
  faSignOut,
  faUser,
  faGlobe,
  // faGlobe,
  // faSitemap,
} from '@fortawesome/pro-solid-svg-icons';

import { useAppContext } from '../../lib/global';
import { useRouter } from '../../lib/useRouter';

import Logo from '../Logo';
import { useWindowSize } from '../../lib/useWindowSize';
import { useCareContext } from '../../areas/care/lib/CareContext';
import { useManageContext } from '../../areas/manage/ManageContext';
import { ManageMobileHeader } from './ManageMobileHeader';
import * as serverApi from '../../serverApi';
import { toast } from 'react-toastify';

import { MobileHeader } from './MobileHeader';

import { isLocale, t, TranslationKey, useI18nContext } from '../../lib/i18n';
import { SimulateModal } from '../modals/SimulateModal';
import { FullScreenLoader } from '../FullScreenLoader/FullScreenLoader';
import { Icon } from '../Icon';
import { HeaderSearch } from '../../areas/supervendor/components/HeaderSearch/HeaderSearch';
import { Locale, localeDefinitions, locales } from '../../lib/i18n/i18n';

import './Header.scss';
import { isAxiosError } from '../../lib/utils/errorUtils';

export type HeaderMode = 'default' | 'super' | 'manage' | 'care' | 'vendor';

interface Props {
  mode?: HeaderMode;
  className?: string;
}

const ShowOnMobileOnly = (props: {
  children: React.ReactNode;
  when: boolean;
}) => {
  const windowSize = useWindowSize();

  if (!windowSize.isMobile || !props.children || !props.when) {
    return <></>;
  }

  return <>{props.children}</>;
};

const LanguageDropdownItem = (props: { code: Locale; onClick: () => void }) => {
  const i18n = useI18nContext();
  return (
    <DropdownItem onClick={props.onClick}>
      <FontAwesomeIcon icon={faGlobe} className="mr-2" />
      {t(`common.commonLanguages.languages.${props.code}`)}
      {i18n.locale === props.code && (
        <Icon icon="check" style={{ color: '#84b93c', marginBottom: 3 }} />
      )}
    </DropdownItem>
  );
};

export function Header(props: Props) {
  const [isZoneOpen, setIsZoneOpen] = useState(false);
  const [switchingToSuper, setSwitchingToSuper] = useState(false);
  const [isSimulateOpen, setIsSimulateOpen] = useState(false);

  const i18n = useI18nContext();

  const app = useAppContext();
  const router = useRouter();
  const careCtx = useCareContext();
  const windowSize = useWindowSize();
  const manageContext = useManageContext();

  useEffect(() => {
    if (isZoneOpen) {
      setIsZoneOpen(false);
    }
  }, [router.location, careCtx.showMainContentMobile]); // eslint-disable-line react-hooks/exhaustive-deps

  const handleSelectZone = () => {
    setIsZoneOpen(false);
    manageContext.toggleSidebar();
  };

  const toggle = () => setIsZoneOpen(!isZoneOpen);

  const handleSwitchBack = async () => {
    try {
      setSwitchingToSuper(true);
      const data = await serverApi.stopImpersonation();
      if (data.redirect_url) {
        window.location.replace(data.redirect_url);
      } else {
        window.location.reload();
      }
    } catch (e) {
      Sentry.captureException(e);
      setSwitchingToSuper(false);

      if (isAxiosError(e)) {
        if (e.response?.data?.code) {
          const errorCode = e.response.data.code;
          toast.error(t(`common.serverError.${errorCode}` as TranslationKey));
        } else {
          console.error(e.response);
        }
      }
    }
  };

  const mode = props.mode || 'default';

  const passwordIsExpiring =
    app.user &&
    app.user.password_expiration_days &&
    app.user.password_expiration_days < 7;

  // const hasManageAccess =
  //   app.user &&
  //   app.user.active_role &&
  //   app.user.active_role.capabilities.includes('manageZones');
  //
  // const hasSuperAccess =
  //   app.user &&
  //   app.user.active_role &&
  //   app.user.active_role.capabilities.includes('globalSuper');
  //
  // const hasCareAccess =
  //   app.user &&
  //   app.user.active_role &&
  //   app.user.active_role.capabilities.includes('careAndObservation');

  const setLocale = (locale: string) => () => {
    // Set the local locale
    i18n.setLocale(locale);

    // Try to update the server locale, but ignore any errors.
    serverApi
      .updateProfile({
        preferences: {
          locale: isLocale(locale) ? locale : undefined,
        },
      })
      .then(() => {})
      .catch(() => {});

    toast.success(t('common.commonLanguages.switchMessage'));
  };

  //If the user is from an external provider, we stop it from redirecting to /change-password
  const handleChangePasswordRedirectForSSOUser = (
    e: React.MouseEvent<HTMLElement, MouseEvent>
  ) => {
    if (app.user?.external_idp) {
      e.preventDefault();
      toast.info(t('user.ChangePassword.cannotChangeSSOUserPassword'));
    }
  };

  if (switchingToSuper) {
    return <FullScreenLoader />;
  }

  return (
    <header className={cs('Header', `Header--${mode}`, props.className)}>
      <Navbar dark={true} expand="md" fixed="top">
        <NavbarToggler onClick={toggle} />

        {mode === 'manage' && app.user && windowSize.isTabletOrMobile && (
          <ManageMobileHeader
            username={app.user?.username}
            onTreeButtonClick={handleSelectZone}
          />
        )}

        {mode === 'care' && app.user && windowSize.isMobile && (
          <MobileHeader username={app.user.username} />
        )}

        <Collapse isOpen={isZoneOpen} navbar>
          <Nav navbar>
            {app.user ? (
              <>
                {!windowSize.isMobile && (
                  <UncontrolledDropdown nav inNavbar>
                    <DropdownToggle nav caret className="mr-3">
                      <FontAwesomeIcon icon={faUserCircle} className="mr-2" />
                      <strong
                        className={cs(
                          'mr-2',
                          passwordIsExpiring && 'alert-dot'
                        )}
                      >
                        {app.user.username}
                      </strong>
                    </DropdownToggle>

                    <DropdownMenu>
                      <DropdownItem
                        tag={Link}
                        to="/change-password"
                        onClick={handleChangePasswordRedirectForSSOUser}
                      >
                        <FontAwesomeIcon icon={faUser} className="mr-2" />
                        <span className={cs(passwordIsExpiring && 'alert-dot')}>
                          {t('components.Header.changePassword')}
                        </span>
                      </DropdownItem>

                      <DropdownItem divider={true} />

                      {locales.map((lang) => {
                        const definition = localeDefinitions[lang];

                        /**
                         * If the language has a feature requirement, and the user
                         * does not have that feature, do not show the language.
                         */
                        if (
                          definition.feature &&
                          !app.hasFeature(definition.feature)
                        ) {
                          return null;
                        }

                        return (
                          <LanguageDropdownItem
                            key={lang}
                            code={lang}
                            onClick={setLocale(lang)}
                          />
                        );
                      })}

                      <DropdownItem divider={true} />

                      <DropdownItem
                        tag={Link}
                        to={{
                          pathname: '/',
                          state: { isLoggingOut: true },
                        }}
                        onClick={(e) => {
                          e.preventDefault();
                          app.requestLogout();
                        }}
                      >
                        <FontAwesomeIcon icon={faSignOut} className="mr-2" />
                        {t('common.commonTexts.logOut')}
                      </DropdownItem>
                    </DropdownMenu>
                  </UncontrolledDropdown>
                )}

                <ShowOnMobileOnly when>
                  <NavItem className="mr-3">
                    <NavLink
                      tag={Link}
                      to="/change-password"
                      onClick={handleChangePasswordRedirectForSSOUser}
                    >
                      <span className={cs(passwordIsExpiring && 'alert-dot')}>
                        {t('components.Header.changePassword')}
                      </span>
                    </NavLink>
                  </NavItem>
                </ShowOnMobileOnly>

                {app.user && app.user.roles && app.user.roles.length > 1 ? (
                  <NavItem className="mr-3">
                    <NavLink tag={Link} to="/roles">
                      {t('components.Header.switchRole')}
                    </NavLink>
                  </NavItem>
                ) : null}

                {mode === 'care' && (
                  <>
                    <NavItem className="mr-3">
                      <NavLink
                        style={{ cursor: 'pointer' }}
                        onClick={() => {
                          setIsZoneOpen(false);
                          careCtx.setShowDisableModal(true);
                        }}
                      >
                        {t('components.Header.presence')}
                      </NavLink>
                    </NavItem>
                    <NavItem className="mr-3">
                      <NavLink
                        style={{ cursor: 'pointer' }}
                        onClick={() => {
                          if (!careCtx.showMainContentMobile) {
                            careCtx.toggleMainContent();
                          }
                          careCtx.selectSensor(undefined);
                          router.history.push('/care/help');
                        }}
                      >
                        {t('components.Header.help')}
                      </NavLink>
                    </NavItem>

                    {app.hasCapability('simulateSensorEvents') && (
                      <NavItem className="mr-3">
                        <NavLink
                          disabled={!careCtx.selectedSensor}
                          style={{ cursor: 'pointer' }}
                          onClick={() => {
                            setIsSimulateOpen(true);
                          }}
                        >
                          {t('components.Header.simulate')}
                        </NavLink>
                      </NavItem>
                    )}
                  </>
                )}

                {mode === 'manage' && (
                  <NavItem className="mr-3">
                    <NavLink tag={Link} to="/manage/help">
                      {t('components.Header.help')}
                    </NavLink>
                  </NavItem>
                )}

                <NavItem className="mr-3">
                  <NavLink
                    tag={Link}
                    to={{
                      pathname: '/',
                      state: { isLoggingOut: true },
                    }}
                    onClick={() => app.requestLogout()}
                  >
                    {t('common.commonTexts.logOut')}
                  </NavLink>
                </NavItem>

                {app?.user?.impersonating && (
                  <NavItem className="mr-3">
                    <NavLink
                      style={{ cursor: 'pointer' }}
                      onClick={handleSwitchBack}
                    >
                      {t('components.Header.backToSuper')}
                    </NavLink>
                  </NavItem>
                )}
              </>
            ) : null}
          </Nav>

          {(mode === 'super' || mode === 'vendor') && (
            <HeaderSearch area={mode} />
          )}

          {!windowSize.isMobile && (
            <Nav navbar className="ml-auto">
              <NavbarBrand tag={Link} to="/">
                <Logo className="Header-logo" style={{ fill: '' }} />
              </NavbarBrand>
            </Nav>
          )}
        </Collapse>
      </Navbar>

      {careCtx.selectedSensor && (
        <SimulateModal
          sensorId={careCtx.selectedSensor.sensor_id}
          sensorType={careCtx.selectedSensor.sensor_type}
          isOpen={isSimulateOpen}
          onHide={() => setIsSimulateOpen(false)}
        />
      )}
    </header>
  );
}
