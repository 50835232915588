import { useEffect } from 'react';
interface Options {
  enabled?: boolean;
}
/**
 * Modifies the care area main div wrapper overflow style attribute
 * by setting it to hidden on component mount (attribute is reseted to auto when component un-mounts).
 *
 * Can also be controlled through 'enabled' option, whether to modify the overflow property or not.
 *
 * @param opts -> Options interface declared above
 */
export function useCareMainWrapperOverflow(opts?: Options) {
  useEffect(() => {
    if (!opts?.enabled) {
      return;
    }
    const container = document.getElementById('careMainWrapper');
    if (!container) {
      return;
    }
    container.style.overflow = 'hidden';
    return () => {
      container.style.overflow = 'auto';
    };
  }, [opts?.enabled]);
}
