import { SVGAttributes } from 'react';
import { ArgusDetection } from './argus';
import { getPositions } from './getPositions';
import { isSupportedMarkingType } from './isSupportedMarkingType';
import { t } from '../../../../../lib/i18n';

interface Props {
  detection: ArgusDetection;
  detectionIndex: number;
  acceptSuggestion: (detection: ArgusDetection, detectionIndex: number) => void;
  rejectSuggestion: (detection: ArgusDetection, detectionIndex: number) => void;
}

function getLabelForShape(detection: ArgusDetection) {
  if (detection.type === 'bed') {
    return t('manage.sensors.marking.markingObjects.bed');
  } else if (detection.type === 'chair') {
    return t('manage.sensors.marking.markingObjects.chair_circle');
  } else if (detection.type === 'seating_group') {
    return t('manage.sensors.marking.markingObjects.chair_area');
  } else {
    return detection.type;
  }
}

/**
 * Represent a single detection as a SVG shape with labels and controls.
 */
export function ArgusDetectionShape(props: Props) {
  const detection = props.detection;

  const positions = getPositions(detection);

  const shapeAttrs: SVGAttributes<SVGElement> = {
    fill: 'pink',
    fillOpacity: 0.1,
    stroke: 'pink',
    strokeOpacity: 0.5,
    strokeWidth: 0.5,
    strokeDasharray: '1,1',
    // style: { pointerEvents: 'all' }
  };

  return (
    <>
      {detection.points.length === 1 ? (
        <circle
          cx={detection.points[0].h}
          cy={detection.points[0].v}
          r={2}
          {...shapeAttrs}
        />
      ) : (
        <polygon
          points={detection.points.map((p) => `${p.h},${p.v}`).join(' ')}
          {...shapeAttrs}
        />
      )}

      <rect
        x={positions.label.h - 12}
        y={positions.label.v - 2.5}
        width={24}
        height={7.5}
        fill="#000000"
        opacity={0.4}
      />
      <text
        x={positions.label.h}
        y={positions.label.v}
        textAnchor="middle"
        dominantBaseline="middle"
        fontSize={3}
        fill="white"
      >
        {getLabelForShape(detection)}
      </text>
      {/* Shoing confidence level */}
      <text
        x={positions.label.h}
        y={positions.label.v + 3}
        textAnchor="middle"
        dominantBaseline="middle"
        fontSize={2}
        fill="white"
      >
        {t(
          'components.ArgusDetectionShape.certain',
          Math.round(detection.conf * 100)
        )}
      </text>

      {isSupportedMarkingType(detection.type) && (
        <>
          <rect
            onClick={() =>
              props.acceptSuggestion(detection, props.detectionIndex)
            }
            x={positions.controls.h - 12}
            y={positions.controls.v - 2.5}
            width="12"
            height="5"
            fill="#38761d"
            style={{
              pointerEvents: 'all',
              cursor: 'pointer',
              opacity: 0.5,
            }}
          />
          <text
            x={positions.controls.h - 6}
            y={positions.controls.v + 0.1}
            textAnchor="middle"
            dominantBaseline="middle"
            fontSize={2.5}
            fill="white"
          >
            {t('components.ArgusDetectionShape.accept')}
          </text>
          <rect
            onClick={() =>
              props.rejectSuggestion(detection, props.detectionIndex)
            }
            x={positions.controls.h}
            y={positions.controls.v - 2.5}
            width="12"
            height="5"
            fill="#990000"
            style={{
              pointerEvents: 'all',
              cursor: 'pointer',
              opacity: 0.5,
            }}
          />
          <text
            x={positions.controls.h + 6}
            y={positions.controls.v + 0.1}
            textAnchor="middle"
            dominantBaseline="middle"
            fontSize={2.5}
            fill="white"
          >
            {t('components.ArgusDetectionShape.reject')}
          </text>
        </>
      )}
    </>
  );
}
