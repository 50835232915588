import React, { useEffect, useState } from 'react';
import * as Sentry from '@sentry/browser';

import { AppContext, appContext } from './appContext';
import * as api from '../../serverApi';

import history from '../history';

import { CenterPanel } from '../../areas/manage/common/CenterPanel';
import { InlineSpinner } from '../../components/InlineSpinner';

// import { toast } from 'react-toastify';

import { t } from '../i18n';
import { setDefaultAccountCode } from '../persistDefaultAccountCode';
import { isAxiosError, isUnauthorizedError } from '../utils/errorUtils';

interface Props {
  children?: React.ReactNode;
}

// enum ApplicationState {
//     Initializing, // Fetching current authentication state
//     LoggingIn, // Trying to log in, waiting for response
//     LoggingOut, // Trying to log out, waiting for response
//     SelectingRole, // Trying to select role, waiting for response
//     Ready, // Ready! (whether or not we're authenticated)
// }

export function AppContextProvider(props: Props) {
  const [hasAppOtpCode, setHasAppOtpCode] = useState(false);
  const [user, setUser] = useState<api.User | undefined>();
  const [error, setError] = useState<
    undefined | string | { [key: string]: string }
  >();
  const [isLoggingIn, setIsLoggingIn] = useState(false);
  const [isLoggingOut, setIsLoggingOut] = useState(false);
  const [loading, setLoading] = useState(true);

  /**
   * When we first mount the AppContextProvider, we send an initial "who am I"
   * request to the backend to figure out our initial authentication context.
   */
  useEffect(() => {
    api
      .me()
      .then((res) => {
        setLoading(false);
        setUser(res.user ?? undefined);
        setHasAppOtpCode(Boolean(res.has_app_otp));
      })
      .catch((err) => {
        /**
         * We ignore reporting 401 Unauthorized error to sentry,
         * reason is that this happens when the idle timeout expires
         * and the first request that's made when the page is refreshed fails.
         * This is an expected behavior for normal timeouts, so we're not logging this to sentry.
         */
        if (!isUnauthorizedError(err)) {
          Sentry.captureException(err);
        }

        setLoading(false);
        console.error('Error', err);
        // toast.error('Authentication error');
      });
  }, []);

  /**
   *
   * Update Sentry scope if the account or role changes
   *
   */
  const account = user?.account;
  const role = user?.active_role?.role;

  useEffect(() => {
    Sentry.configureScope((scope) => {
      // Sentry can accept undefined as parameters to unset tags,
      // even though the TS type is erroneous.
      // See: https://github.com/getsentry/sentry-javascript/issues/2218
      scope.setTag(
        'account_id',
        account?.account_id ? `${account?.account_id}` : undefined
      );
      scope.setExtra('account_name', account?.name || undefined);
    });
  }, [account]);

  useEffect(() => {
    Sentry.configureScope((scope) => {
      scope.setTag('role', role ?? undefined);
    });
  }, [role]);

  /**
   * Selecting a new active role.
   */
  async function setActiveRole(roleId: number) {
    try {
      setIsLoggingIn(true);
      const res = await api.selectRole({ roleId });
      setUser(res.user);
      setError(undefined);
      return true;
    } catch (err) {
      /**
       * If it's not considered an axios error, we simply display a generic error message
       */
      if (!isAxiosError(err)) {
        setError(t('common.error.anErrorOccurred'));
        return false;
      }

      /**
       * If it's due to disabled IPs for that role, we show a custom message
       */
      if (err.response?.data.code === 'user-role-disabled-invalid-ip') {
        setError(t('publicComponents.SelectRole.ipNotOnWhitelist'));
        return false;
      }

      /**
       * For other unknown errors, we stringify the error and display it
       */
      setError(err.toString());
      return false;
    } finally {
      setIsLoggingIn(false);
    }
  }

  function setOneTimeCodeProvided() {
    if (user) {
      setUser({
        ...user,
        one_time_code_provided: true,
      });
    }
  }

  function updateUser(user?: api.User) {
    if (user) {
      setUser(user);
    }
  }

  function hasFeature(feature: string) {
    return (user?.features && feature in user.features) ?? false;
  }

  function getFeatureValue(feature: api.FeatureFlagKey) {
    if (user?.features && feature in user.features) {
      return user.features[feature];
    }
    return undefined;
  }

  function getFeatureValueNumber(
    feature: api.FeatureFlagKey
  ): number | undefined {
    if (user?.features && feature in user.features) {
      const x = user.features[feature];

      if (typeof x === 'number') {
        return x;
      }

      if (typeof x === 'string') {
        const i = parseInt(x, 10);
        if (!isNaN(i)) {
          return i;
        }
      }
    }
    return undefined;
  }

  function hasCapability(capability: api.CapabilityType) {
    if (Array.isArray(user?.active_role?.capabilities)) {
      return user?.active_role?.capabilities?.includes(capability) ?? false;
    }
    return false;
  }

  function hasCapabilityInAnyRole(capability: api.CapabilityType) {
    const roles = user?.roles;
    if (Array.isArray(roles)) {
      for (let i = 0; i < roles.length; i++) {
        if (roles[i].capabilities.includes(capability)) {
          return true;
        }
      }
    }
    return false;
  }

  function login(user: api.User) {
    setUser(user);
    setHasAppOtpCode(false);
    setDefaultAccountCode(user.account.account_code);
  }

  async function requestLogout() {
    try {
      setIsLoggingOut(true);
      await api.logout();
      setUser(undefined);
      setHasAppOtpCode(false);
      setIsLoggingOut(false);
      history.push({
        pathname: '/',
        state: { isLoggingOut: true },
      });
    } catch (err) {
      setError(String(err));
    }
  }

  if (loading || isLoggingOut || isLoggingIn) {
    return (
      <CenterPanel>
        <InlineSpinner
          size="md"
          text={t('common.commonTexts.loading')}
          textStyle={{ fontWeight: 500, fontSize: 22 }}
        />
      </CenterPanel>
    );
  }

  const context: AppContext = {
    isAuthenticated: user !== undefined,
    isLoading: loading,
    isLoggingIn,
    error,
    user,
    setActiveRole,
    setOneTimeCodeProvided,
    login,
    requestLogout,
    hasCapability,
    hasCapabilityInAnyRole,
    hasFeature,
    getFeatureValue,
    getFeatureValueNumber,
    updateUser,
    hasAppOtpCode,
  };

  return (
    <appContext.Provider value={context}>{props.children}</appContext.Provider>
  );
}
