import React from 'react';
import cs from 'classnames';
import { HelpBlocks } from '../../../components/HelpBlocks';
import { Icon } from '../../../components/Icon';
import { t } from '../../manage/../../lib/i18n';

import { VideoHelpBlock } from '../../../components/VideoHelpBlock';
import { HelpBlock } from '../../../components/HelpBlock';
import { openLinkInNewTab } from '../../../lib/openLinkInNewTab';

import './ManageHelpPage.scss';

interface Props {
  className?: string;
}

export function ManageHelpPage(props: Props) {
  return (
    <div
      className={cs(
        'ManageHelpPage',
        'Manage-round-container',
        'Manage-main',
        props.className
      )}
    >
      <h1 className="pl-2 SplashScreen-title d-none d-lg-block">
        <Icon icon="arrow-left" />
        {t(
          'manage.common.ManageHelpPage.selectZoneOrHousingUnitInTheLeftField'
        )}
      </h1>

      <h1 className="ManageHelpPage__sub-heading">
        {t('manage.common.ManageHelpPage.videosAndPdfGuide')}:
      </h1>

      <HelpBlocks>
        <VideoHelpBlock
          vimeoId={t('common.video.manage.basicFunction.vimeo')}
          title={t('common.video.manage.basicFunction.title')}
          icon="gear"
        />

        <VideoHelpBlock
          vimeoId={t('common.video.manage.createZones.vimeo')}
          title={t('common.video.manage.createZones.title')}
          icon="home"
        />

        <VideoHelpBlock
          vimeoId={t('common.video.manage.addCareReceiver.vimeo')}
          title={t('common.video.manage.addCareReceiver.title')}
          icon="video-plus-person-solid"
        />

        <VideoHelpBlock
          vimeoId={t('common.video.manage.moveCareReceiver.vimeo')}
          title={t('common.video.manage.moveCareReceiver.title')}
          icon="map-marker"
        />

        <VideoHelpBlock
          vimeoId={t('common.video.manage.addUser.vimeo')}
          title={t('common.video.manage.addUser.title')}
          icon="video-plus-person-outline"
        />

        <VideoHelpBlock
          vimeoId={t('common.video.manage.addNewRole.vimeo')}
          title={t('common.video.manage.addNewRole.title')}
          icon="video-plus-person-outline"
        />

        <VideoHelpBlock
          vimeoId={t('common.video.manage.addSharedPhone.vimeo')}
          title={t('common.video.manage.addSharedPhone.title')}
          icon="video-plus-mobile"
        />

        <VideoHelpBlock
          vimeoId={t('common.video.manage.addRoomMateSensor.vimeo')}
          title={t('common.video.manage.addRoomMateSensor.title')}
          icon="roommate"
        />

        <VideoHelpBlock
          vimeoId={t('common.video.manage.addOtherSensor.vimeo')}
          title={t('common.video.manage.addOtherSensor.title')}
          icon="roommate"
        />

        <VideoHelpBlock
          vimeoId={t('common.video.manage.marking.vimeo')}
          title={t('common.video.manage.marking.title')}
          icon="roommate"
        />

        <VideoHelpBlock
          vimeoId={t('common.video.manage.enableAlerts.vimeo')}
          title={t('common.video.manage.enableAlerts.title')}
          icon="fall"
        />

        <VideoHelpBlock
          vimeoId={t('common.video.manage.addAlertSettings.vimeo')}
          title={t('common.video.manage.addAlertSettings.title')}
          icon="video-time"
        />

        <VideoHelpBlock
          vimeoId={t('common.video.manage.logging.vimeo')}
          title={t('common.video.manage.logging.title')}
          icon="video-log"
        />

        <VideoHelpBlock
          vimeoId={t('common.video.manage.systemSettings.vimeo')}
          title={t('common.video.manage.systemSettings.title')}
          icon="gear"
        />

        <VideoHelpBlock
          vimeoId={t('common.video.manage.displayName.vimeo')}
          title={t('common.video.manage.displayName.title')}
          icon="home"
        />
        <VideoHelpBlock
          vimeoId={t('common.video.manage.globalSettings.vimeo')}
          title={t('common.video.manage.globalSettings.title')}
          icon="gear"
        />
        <VideoHelpBlock
          vimeoId={t('common.video.manage.activationCodes.vimeo')}
          title={t('common.video.manage.activationCodes.title')}
          icon="video-mobile"
        />
        <HelpBlock
          icon="book"
          onClick={() => openLinkInNewTab(t('common.url.adminUserGuidePdf'))}
          isPdf
        >
          {t(
            'common.commonTexts.downloadUserGuideIncludingGlossaryAndExplanationOfBasicConcepts'
          )}
        </HelpBlock>
      </HelpBlocks>
    </div>
  );
}
