import { GroupedChangesFormatter } from '../formatChanges';

type FormattedAlertSettingRecipients = Array<{
  user_id: number;
}>;

export type FormattedAlertSettingValues = {
  enabled?: boolean;
  name?: string;
  zone_id?: number;
  escalation_enabled?: boolean;
  escalation_delay?: number;
  escalation_recipients?: FormattedAlertSettingRecipients;
  event_types?: Array<{
    event_type: string;
    sensor_type: string;
  }>;
  recipients?: FormattedAlertSettingRecipients;
  schedule_rules?: Array<{
    to_weekday: string;
    to_time: string;
    from_weekday: string;
    from_time: string;
  }>;
};

const formatAlertSetting: GroupedChangesFormatter<
  'alert-setting',
  FormattedAlertSettingValues
> = (acl, cg) => {
  // If we don't have any group, we consider this an "update" of the
  // parent group, even if we have only added/removed child models.
  // const event = cg.group?.event ?? 'updated';

  /**
   * The new/old value container we are going to organise the entries into.
   */
  const res = {
    new_values: {} as FormattedAlertSettingValues,
    old_values: {} as FormattedAlertSettingValues,
  };

  /**
   * Loop through all the grouped entries:
   */
  for (let i = 0, j = cg.changes.length; i < j; i++) {
    const c = cg.changes[i];

    /**
     * We can safely skip event types and schedule rules for escalation rules,
     * since these are identical to the main/parent alert setting.
     */
    if (
      c.auditable_group_subid &&
      (c.auditable_type === 'App\\Models\\AlertSettingEventType' ||
        c.auditable_type === 'App\\Models\\AlertSettingScheduleRule')
    ) {
      continue;
    }

    /**
     *
     *
     *
     *
     *
     *
     * First, check event types:
     */
    if (c.auditable_type === 'App\\Models\\AlertSettingEventType') {
      // Created
      if (c.event === 'created') {
        if (!res.new_values.event_types) {
          res.new_values.event_types = [];
        }
        if (Array.isArray(c.new_values)) {
          continue;
        }
        res.new_values.event_types.push({
          event_type: c.new_values.event_type as string,
          sensor_type: c.new_values.sensor_type as string,
        });
      }
      // Deleted
      if (c.event === 'deleted') {
        if (!res.old_values.event_types) {
          res.old_values.event_types = [];
        }
        if (Array.isArray(c.old_values)) {
          continue;
        }
        res.old_values.event_types.push({
          event_type: c.old_values.event_type as string,
          sensor_type: c.old_values.sensor_type as string,
        });
      }
    }

    /**
     *
     *
     *
     *
     *
     *
     * Second, check schedule rules:
     */
    if (c.auditable_type === 'App\\Models\\AlertSettingScheduleRule') {
      // Created
      if (c.event === 'created') {
        if (!res.new_values.schedule_rules) {
          res.new_values.schedule_rules = [];
        }
        if (Array.isArray(c.new_values)) {
          continue;
        }
        res.new_values.schedule_rules.push({
          from_time: c.new_values.from_time as string,
          from_weekday: c.new_values.from_weekday as string,
          to_time: c.new_values.to_time as string,
          to_weekday: c.new_values.to_weekday as string,
        });
      }
      // Deleted
      if (c.event === 'deleted') {
        if (!res.old_values.schedule_rules) {
          res.old_values.schedule_rules = [];
        }
        if (Array.isArray(c.old_values)) {
          continue;
        }
        res.old_values.schedule_rules.push({
          from_time: c.old_values.from_time as string,
          from_weekday: c.old_values.from_weekday as string,
          to_time: c.old_values.to_time as string,
          to_weekday: c.old_values.to_weekday as string,
        });
      }
    }

    /**
     *
     *
     *
     *
     *
     *
     * Third, check recipients:
     */
    if (c.auditable_type === 'App\\Models\\AlertSettingRecipient') {
      // Created
      if (c.event === 'created') {
        let target: FormattedAlertSettingRecipients;

        // Recipients can be set on the escalation too -- use `target` to refer to the right container
        if (c.auditable_group_subid) {
          if (!res.new_values.escalation_recipients) {
            res.new_values.escalation_recipients = [];
          }
          target = res.new_values.escalation_recipients;
        } else {
          if (!res.new_values.recipients) {
            res.new_values.recipients = [];
          }
          target = res.new_values.recipients;
        }
        // It's only an array when it's empty... (Laravel/Auditing/JSON peculiarity)
        if (Array.isArray(c.new_values)) {
          continue;
        }
        target.push({
          user_id: c.new_values.user_id as number,
        });
      }

      // Created
      if (c.event === 'deleted') {
        let target: FormattedAlertSettingRecipients;

        // Recipients can be set on the escalation too -- use `target` to refer to the right container
        if (c.auditable_group_subid) {
          if (!res.old_values.escalation_recipients) {
            res.old_values.escalation_recipients = [];
          }
          target = res.old_values.escalation_recipients;
        } else {
          if (!res.old_values.recipients) {
            res.old_values.recipients = [];
          }
          target = res.old_values.recipients;
        }
        // It's only an array when it's empty... (Laravel/Auditing/JSON peculiarity)
        if (Array.isArray(c.old_values)) {
          continue;
        }
        target.push({
          user_id: c.old_values.user_id as number,
        });
      }
    }

    /**
     *
     *
     *
     *
     *
     *
     * Fourth, check the alert settings themselves
     */
    if (c.auditable_type === 'App\\Models\\AlertSetting') {
      if (c.auditable_group_id) {
        /**
         *
         *
         * Fourth, A) The escalation setting
         *
         *
         * No matter what event type this is, we can consider it an update on the parent.
         * We can therefore ignore c.event and just look at new/old values.
         */
        for (let k of ['new_values', 'old_values'] as [
          'new_values',
          'old_values'
        ]) {
          const src = c[k];
          const target = res[k];
          if (Array.isArray(src)) {
            continue;
          }
          if ('enabled' in src) {
            target.escalation_enabled = Boolean(src.enabled);
          }
          if ('delay' in src) {
            target.escalation_delay = src.delay as number;
          }
        }
      } else {
        /**
         *
         *
         *
         * Fourth, B) The main setting
         */
        for (let k of ['new_values', 'old_values'] as [
          'new_values',
          'old_values'
        ]) {
          const src = c[k];
          const target = res[k];
          if (Array.isArray(src)) {
            continue;
          }
          if ('enabled' in src) {
            target.enabled = Boolean(src.enabled);
          }
          if ('name' in src) {
            target.name = src.name as string;
          }
          if ('zone_id' in src) {
            target.zone_id = src.zone_id as number;
          }
        }
      }
    }
  }

  return {
    auditChangeLogEntry: acl,
    groupedChanges: cg,
    type: 'alert-setting',
    formattedChanges: res,
  };
};

export default formatAlertSetting;
