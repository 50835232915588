import { useCallback, useRef, useState } from 'react';
import cs from 'classnames';
import {
  Collapse,
  Nav,
  NavbarBrand,
  NavItem,
  NavLink,
  UncontrolledCollapse,
} from 'reactstrap';
import { Link } from 'react-router-dom';
import { toast } from 'react-toastify';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faGlobe, faUserCircle } from '@fortawesome/pro-solid-svg-icons';

import * as api from '../../serverApi';
import { useAppContext } from '../../lib/global';
import { useCareContext } from '../../areas/care/lib/CareContext';
import { useRouter } from '../../lib/useRouter';
import { isLocale, t, TranslationKey, useI18nContext } from '../../lib/i18n';

import { HamburgerButton } from '../HamburgerButton';
import CareSelectZoneModal from '../modals/care/CareSelectZoneModal';
import { SimulateModal } from '../modals/SimulateModal';
import { Icon } from '../Icon';
import Logo from '../Logo';

import { FullScreenLoader } from '../FullScreenLoader/FullScreenLoader';
import { useOutsideClickDetection } from '../../lib/useOutsideClickDetection';
import { locales } from '../../lib/i18n/i18n';
import { isAxiosError } from '../../lib/utils/errorUtils';

import './CareHeader.scss';

export const CareHeader = () => {
  const app = useAppContext();
  const careCtx = useCareContext();
  const router = useRouter();
  const i18n = useI18nContext();

  const [menuOpen, setMenuOpen] = useState(false);
  const menuRef = useRef<HTMLElement>(null);

  const [simulateModalOpen, setSimulateModalOpen] = useState(false);
  const [switchingToSuper, setSwitchingToSuper] = useState(false);

  const toggleMenu = () => setMenuOpen((prev) => !prev);

  const closeMenu = useCallback(() => {
    setMenuOpen(false);
  }, []);

  useOutsideClickDetection(menuRef, closeMenu);

  const setLocale = (locale: string) => () => {
    // Set the local locale
    i18n.setLocale(locale);

    // Try to update the server locale, but ignore any errors.
    api
      .updateProfile({
        preferences: {
          locale: isLocale(locale) ? locale : undefined,
        },
      })
      .then(() => {})
      .catch(() => {});

    toast.success(t('common.commonLanguages.switchMessage'));
  };

  const handleSwitchBack = async () => {
    try {
      setSwitchingToSuper(true);
      const data = await api.stopImpersonation();
      if (data.redirect_url) {
        window.location.replace(data.redirect_url);
      } else {
        window.location.reload();
      }
    } catch (e) {
      setSwitchingToSuper(false);
      if (!isAxiosError(e)) {
        return;
      }
      if (e.response?.data?.code) {
        const errorCode = e.response.data.code;
        toast.error(t(`common.serverError.${errorCode}` as TranslationKey));
      } else {
        console.error(e.response);
      }
    }
  };

  const passwordIsExpiring =
    app.user &&
    app.user.password_expiration_days &&
    app.user.password_expiration_days < 7;

  if (switchingToSuper) {
    return <FullScreenLoader />;
  }

  return (
    <header className="CareHeader" ref={menuRef}>
      <HamburgerButton open={menuOpen} onClick={toggleMenu} />
      <UserInfo user={app.user} />
      <ZoneModal
        activeZone={careCtx.activeZone}
        onZoneSelect={(zone) => careCtx.handleActiveZoneSelect(zone)}
      />
      <Nav
        className="CareHeader--nav"
        vertical
        onClick={(e: any) => {
          if (e.target.id === 'languages') {
            return;
          }

          if (e.target.className ?? ''.includes('nav-link')) {
            toggleMenu();
          }
        }}
      >
        <Collapse isOpen={menuOpen}>
          <NavItem onClick={() => careCtx.setShowDisableModal(true)}>
            <NavLink>{t('components.Header.presence')}</NavLink>
          </NavItem>

          {app.user && app.user.roles && app.user.roles.length > 1 && (
            <NavItem>
              <NavLink tag={Link} to="/roles">
                {t('components.Header.switchRole')}
              </NavLink>
            </NavItem>
          )}

          <NavItem>
            <NavLink
              tag={Link}
              to="/change-password"
              onClick={(e) => {
                if (app.user?.external_idp) {
                  e.preventDefault();
                  toast.info(
                    t('user.ChangePassword.cannotChangeSSOUserPassword')
                  );
                }
              }}
            >
              <span className={cs(passwordIsExpiring && 'alert-dot')}>
                {t('components.Header.changePassword')}
              </span>
            </NavLink>
          </NavItem>

          <NavItem tag="div">
            <NavLink id="languages">
              {t('common.commonLanguages.language')}
            </NavLink>

            <UncontrolledCollapse toggler="#languages">
              {locales.map((lang) => (
                <LanguageNavItem
                  key={lang}
                  code={lang}
                  onClick={setLocale(lang)}
                />
              ))}
            </UncontrolledCollapse>
          </NavItem>

          <NavItem>
            <NavLink
              onClick={() => {
                if (!careCtx.showMainContentMobile) {
                  careCtx.toggleMainContent();
                }
                careCtx.selectSensor(undefined);
                router.history.push('/care/help');
              }}
            >
              {t('components.CareHeader.help')}
            </NavLink>
          </NavItem>

          {app?.user?.impersonating && (
            <NavItem className="mr-3">
              <NavLink style={{ cursor: 'pointer' }} onClick={handleSwitchBack}>
                {t('components.Header.backToSuper')}
              </NavLink>
            </NavItem>
          )}

          {app.hasCapability('simulateSensorEvents') && (
            <NavItem>
              <NavLink
                disabled={!careCtx.selectedSensor}
                onClick={() => {
                  setSimulateModalOpen(true);
                }}
              >
                {t('components.Header.simulate')}
              </NavLink>
            </NavItem>
          )}

          <NavItem>
            <NavLink
              onClick={() => {
                careCtx.setSearchEnabled((prev) => !prev);
              }}
            >
              {careCtx.searchEnabled
                ? t('care.CareHeader.disableSearch')
                : t('care.CareHeader.enableSearch')}
            </NavLink>
          </NavItem>

          <NavItem>
            <NavLink
              tag={Link}
              to={{
                pathname: '/',
                state: { isLoggingOut: true },
              }}
              onClick={() => app.requestLogout()}
            >
              {t('common.commonTexts.logOut')}
            </NavLink>
          </NavItem>

          <NavItem className="CareHeader--logo">
            <NavbarBrand tag={Link} to="/">
              <Logo className="CareHeader--logo-icon" style={{ fill: '' }} />
            </NavbarBrand>
          </NavItem>
        </Collapse>
      </Nav>

      {careCtx.selectedSensor && (
        <SimulateModal
          sensorId={careCtx.selectedSensor.sensor_id}
          sensorType={careCtx.selectedSensor.sensor_type}
          isOpen={simulateModalOpen}
          onHide={() => setSimulateModalOpen(false)}
        />
      )}
    </header>
  );
};

/**
 * User avatar and username
 */
interface UserInfoProps {
  user?: api.User;
}
const UserInfo = (props: UserInfoProps) => {
  return (
    <span className="CareHeader--user-info">
      <FontAwesomeIcon icon={faUserCircle} className="mr-2" />
      <strong className="mr-1">{props.user?.username}</strong>
    </span>
  );
};

/**
 * Modal for selecting active zone that's used to filter sensors
 */
interface ZoneModalProps {
  activeZone?: api.StructureZone;
  onZoneSelect: (zone?: api.StructureZone) => void;
}
const ZoneModal = (props: ZoneModalProps) => {
  const [zoneModalOpen, setZoneModalOpen] = useState(false);
  return (
    <>
      <span
        role="button"
        className="CareHeader--select-zone-btn"
        onClick={() => setZoneModalOpen((prev) => !prev)}
      >
        <Icon icon="map-marker" />
      </span>
      <CareSelectZoneModal
        isOpen={zoneModalOpen}
        activeZone={props.activeZone}
        handleModal={() => setZoneModalOpen((prev) => !prev)}
        onSelect={(x) => props.onZoneSelect(x)}
      />
    </>
  );
};

const LanguageNavItem = (props: { code: string; onClick: () => void }) => {
  const i18n = useI18nContext();

  return (
    <NavItem
      className={cs(
        'CareHeader--lang-item',
        i18n.locale === props.code && 'CareHeader--lang-item--active'
      )}
      onClick={props.onClick}
    >
      <FontAwesomeIcon icon={faGlobe} />
      {t(`common.commonLanguages.languages.${props.code}` as TranslationKey)}
    </NavItem>
  );
};
