import { debounce } from 'lodash';
import { useCallback, useEffect, useRef, useState } from 'react';
import * as draw from '../areas/manage/sensors/marking/lib/drawingUtils';
import {
  convertFromServerMarkingObjectToLocalType,
  sensorImageResolution,
} from '../areas/manage/sensors/marking/types';
import { MarkingObject } from '../serverApi';

import './ReadOnlyMarkingCanvasOverlay.scss';

interface Props {
  /**
   * An array of the marking objects to be drawn on the canvas.
   */
  markingObjects?: MarkingObject[];
  className?: string;
}

export function ReadOnlyMarkingCanvasOverlay(props: Props) {
  const markingRef = useRef<HTMLCanvasElement>(null);
  const objects =
    props.markingObjects?.map(convertFromServerMarkingObjectToLocalType) ?? [];

  const [dim, setDim] = useState({
    width: 160,
    height: 120,
    scale: 1,
  });

  const handleResize = debounce(() => {
    // In order to resize, we need to access the parents parent to get its size; this
    // will be our reference point, and the canvas must fit within those constraints.
    if (!markingRef.current?.parentElement) {
      return;
    }
    const constraints = {
      width: markingRef.current.parentElement.clientWidth,
      height: markingRef.current.parentElement.clientHeight,
    };

    if (constraints.width * sensorImageResolution.ratio > constraints.height) {
      // Constrained by height
      setDim({
        width: constraints.height / sensorImageResolution.ratio,
        height: constraints.height,
        scale: constraints.height / sensorImageResolution.h,
      });
    } else {
      // Constrained by width
      setDim({
        width: constraints.width,
        height: constraints.width * sensorImageResolution.ratio,
        scale: constraints.width / sensorImageResolution.w,
      });
    }
  }, 100);

  const redraw = useCallback(() => {
    if (markingRef.current && props.markingObjects) {
      const ctx = markingRef.current.getContext('2d');
      if (ctx) {
        markingRef.current.width = dim.width;
        markingRef.current.height = dim.height;
        ctx.clearRect(
          0,
          0,
          markingRef.current.width,
          markingRef.current.height
        );
        draw.drawObjs(ctx, objects, dim.scale);
      }
    }
  }, [dim, markingRef, props.markingObjects]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (!markingRef) {
      handleResize();
      redraw();
    }
  }, [markingRef, handleResize, redraw]);

  /**
   * Attach the resize event handler
   */
  useEffect(() => {
    handleResize();
    redraw();
    window.addEventListener('resize', redraw);
    return () => {
      window.removeEventListener('resize', redraw);
    };
  }, [redraw, handleResize]);

  return <canvas ref={markingRef} className="ReadOnlyMarkingCanvasOverlay" />;
}
