import React from 'react';
import { Switch, RouteComponentProps, Route } from 'react-router';
import ZoneHome from './ZoneHome';
import Settings from './settings/Settings';

export default function Zone(props: RouteComponentProps<{}>) {
  return (
    <Switch>
      <Route exact={true} path={props.match.path} component={ZoneHome} />
      <Route path={`${props.match.path}/settings`} component={Settings} />
    </Switch>
  );
}
