import { faBuilding, faGlobe } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { toast } from 'react-toastify';

import { t, TranslationKey } from '../../../../lib/i18n';
import { Account } from '../../../../serverApi';
import { SearchResultSingleType } from './SearchResult';
import * as api from '../../../../serverApi';
import { useState } from 'react';
import { FullScreenLoader } from '../../../../components/FullScreenLoader/FullScreenLoader';
import { Button } from '@material-ui/core';
import { isAxiosError } from '../../../../lib/utils/errorUtils';

export function SearchResultAccount(props: SearchResultSingleType<Account>) {
  const [switchingToManage, setSwitchingToManage] = useState(false);

  const impersonateClick = async (account_id: number) => {
    try {
      setSwitchingToManage(true);
      await api.impersonateAccount({ account_id, redirect_url: '/super' });
      window.location.reload();
    } catch (e) {
      setSwitchingToManage(false);
      if (isAxiosError(e) && e.response?.data?.code) {
        const errorCode = e.response.data.code;
        toast.error(t(`common.serverError.${errorCode}` as TranslationKey));
      } else {
        console.error(e);
      }
    }
  };

  if (switchingToManage) {
    return <FullScreenLoader />;
  }

  return (
    <div className="SearchResult SearchResult--account">
      <div>
        <div className="SearchResult-heading">
          <FontAwesomeIcon className="mr-2" icon={faBuilding} />
          {props.entry.name}
        </div>

        <div>
          <span className="mr-2">
            Code: <code>{props.entry.account_code}</code>
          </span>
          <span className="mr-2">
            ID: <code>{props.entry.account_id}</code>
          </span>
        </div>

        <div>
          <FontAwesomeIcon className="mr-2" icon={faGlobe} />
          {t(
            `common.commonLanguages.languages.${props.entry.default_user_locale}` as TranslationKey
          )}
        </div>
      </div>
      <div>
        <Button
          variant="outlined"
          onClick={(e) => {
            e.stopPropagation();
            impersonateClick(props.entry.account_id);
          }}
        >
          {t('supervendor.SearchResults.manage')}
        </Button>
      </div>
    </div>
  );
}
