import React from 'react';
import cs from 'classnames';
import {
  FrontendMarkingObjectType,
  markingObjectDefinitions,
  MarkingObject,
} from '../types';

import Select, { components } from 'react-select';
import { Icon } from '../../../../../components/Icon';
import { t, TranslationKey } from '../../../../../lib/i18n';

/**
 * Initialize the types that should be available to select from in the sidebar.
 */
const addMarkingTypes: FrontendMarkingObjectType[] = [
  'bed',
  'door_exit',
  'bathroom',
  'chair_circle',
  'chair_area',
  'reflex',
  'discard',
];

/**
 * The Option component represents the marking object type element in the dropdown list
 * for selecting a new marking object type to create.
 */
function Option(props: any) {
  return (
    <components.Option {...props}>
      <div
        style={{
          backgroundColor: props.data.iconColor,
          fill: 'white',
          color: 'white',
          display: 'inline-block',
          borderRadius: '6px',
          marginRight: '8px',
          padding: '3px',
        }}
      >
        <Icon
          icon={props.data.icon}
          style={{
            width: '24px',
            height: '24px',
          }}
        />
      </div>
      <div
        style={{
          display: 'inline-block',
        }}
      >
        {props.data.label}
      </div>
    </components.Option>
  );
}

const customSelectStyles = {
  control: (provided: any, s: any) => ({
    ...provided,
    border: '1px solid #707070',
    backgroundColor: 'transparent',
  }),
  placeholder: (p: any) => ({
    ...p,
    color: '#444',
  }),
  dropdownIndicator: (p: any) => ({
    ...p,
    color: '#707070',
    ':hover': {
      color: '#555',
    },
  }),
};

interface Props {
  disabled?: boolean;
  className?: string;
  onAdd?: (objectType: FrontendMarkingObjectType) => void;

  /**
   * An array of the currently stored marking objects. Used for
   * determining whether certain marking object types can be added.
   */
  markingObjects?: MarkingObject[];
}

const chairTypes = ['chair_area', 'chair_circle'];

export function AddNewMarkingObject(props: Props) {
  let disableChair = false;
  let disableBed = false;
  let disableReflex = false;

  if (props.markingObjects) {
    for (let i = 0, len = props.markingObjects.length; i < len; i++) {
      if (chairTypes.includes(props.markingObjects[i].type)) {
        disableChair = true;
      }
      if (props.markingObjects[i].type === 'bed') {
        disableBed = true;
      }
      if (props.markingObjects[i].type === 'reflex') {
        disableReflex = true;
      }
    }
  }

  /**
   * For each of the available types, load its metadata into a new arry.
   */
  const addMarkingOptions = addMarkingTypes.map((mt) => ({
    type: mt,
    icon: markingObjectDefinitions[mt].icon,
    iconColor: markingObjectDefinitions[mt].iconColor,
    label: t(`manage.sensors.marking.markingObjects.${mt}` as TranslationKey),
    markingLineColor: markingObjectDefinitions[mt].markingLineColor,
    isDisabled:
      (disableChair && chairTypes.includes(mt)) ||
      (disableBed && mt === 'bed') ||
      (disableReflex && mt === 'reflex'),
  }));

  return (
    <Select
      className={cs('AddNewMarkingObject', props.className)}
      styles={customSelectStyles}
      placeholder={t(
        'manage.sensors.marking.components.AddNewMarkingObject.makeANewSelection'
      )}
      options={addMarkingOptions}
      maxMenuHeight={400}
      components={{ Option }}
      isDisabled={props.disabled}
      value={false as any}
      inputValue={undefined}
      isSearchable={false}
      onChange={(objectType) => {
        if (objectType) {
          props.onAdd?.(objectType.type);
        }
      }}
    />
  );
}
