import React from 'react';
import * as api from '../../../../serverApi';
import { TableWrapper } from '../../../../components/TableWrapper';
import {
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
} from '@material-ui/core';
import { TableRowPlaceholder } from '../../common/TableRowPlaceholder';
import { InlineSpinner } from '../../../../components/InlineSpinner';
import { t } from '../../../../lib/i18n';
import moment from 'moment';
import {
  formatChanges,
  RequestWithFormattedChanges,
} from './groups/formatChanges';
import formatAuditableType from './groups/utils/formatAuditableType';
import formatEvent from './groups/utils/formatEvent';
import AlertSettingChanges from './components/AlertSettingChanges';
import { ChangedFieldsSummary } from './components/ChangedFieldsSummary';

interface Props {
  entries?: api.AuditChangeLogEntry[];
  loading?: boolean;
}

interface User {
  id: number;
  impersonal_user: boolean | null;
  username: string | null;
  display_name: string | null;
  first_name: string | null;
  last_name: string | null;
}

function renderUser(u: User | null) {
  if (!u) {
    return (
      <em>
        {t('manage.logs.change.AuditChangeLogEntriesTable.notRegistered')}
      </em>
    );
  }
  if (u.username) {
    return (
      <>
        {u.username} <small>({u.id})</small>
      </>
    );
  }
  return (
    <>
      <em>{t('manage.logs.change.AuditChangeLogEntriesTable.deletedUser')}</em>{' '}
      <small>({u.id})</small>
    </>
  );
}

function RequestRows(props: { entry: RequestWithFormattedChanges }) {
  const e = props.entry;
  const createdAt = moment(e.created_at_user_local);

  return (
    <>
      {e.formattedAuditableGroups.map((fmtAg, ix) => {
        const ag = fmtAg.groupedChanges;

        const groupEvent = ag.group?.event ?? ag.changes[0].event;

        /**
         * For EventSettings rows, we need to evaluate the URL to determine the event type.
         */
        let evaluatedSensorType: undefined | string = undefined;
        let evaluatedEventType: undefined | string = undefined;
        const eetMatch = e?.url?.match(
          /\/api\/sensors\/([a-z_]+)[0-9]+\/event-settings\/([a-z]+)$/
        );
        if (eetMatch) {
          evaluatedSensorType = eetMatch[1];
          evaluatedEventType = eetMatch[2];
        }

        return (
          <TableRow key={ix}>
            <TableCell>
              <span style={{ whiteSpace: 'nowrap' }}>
                {createdAt.format('YYYY-MM-DD')}
              </span>{' '}
              <span style={{ whiteSpace: 'nowrap' }}>
                {createdAt.format('HH:mm:ss')}
              </span>
            </TableCell>
            <TableCell>{renderUser(e.change_by)}</TableCell>
            <TableCell>
              <small>
                {groupEvent !== 'updated' ? `${formatEvent(groupEvent)}: ` : ''}
                {formatAuditableType(ag.auditable_type)} ({ag.auditable_id})
              </small>
              <br />
              {ag.auditable_name}
            </TableCell>
            <TableCell>
              {fmtAg.type === 'alert-setting' ? (
                <AlertSettingChanges values={fmtAg.formattedChanges} />
              ) : (
                <>
                  {fmtAg.groupedChanges.changes.map((c, ix) => (
                    <ChangedFieldsSummary
                      key={ix}
                      auditableType={ag.auditable_type}
                      evaluatedSensorType={evaluatedSensorType}
                      evaluatedEventType={evaluatedEventType}
                      values={c}
                    />
                  ))}
                </>
              )}
            </TableCell>
          </TableRow>
        );
      })}
    </>
  );
}

export function AuditChangeLogEntriesTable(props: Props) {
  const auditables = formatChanges(props.entries ?? []);
  // console.log('ENTRIES', props.entries);
  // console.log('AUDITABLES', auditables);
  // return null;
  return (
    <TableWrapper>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell>{t('manage.logs.common.pointInTime')}</TableCell>
            <TableCell style={{ whiteSpace: 'normal' }}>
              {t('manage.logs.change.ChangeLogEntriesTable.changedBy')}
            </TableCell>
            <TableCell>{t('manage.logs.common.changed')}</TableCell>
            <TableCell>{t('manage.logs.common.details')}</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {auditables.length > 0 ? (
            auditables.map((a, ix) => (
              <RequestRows entry={a} key={a.request_id} />
            ))
          ) : (
            <TableRowPlaceholder colSpan={4}>
              {props.loading ? (
                <InlineSpinner text={t('common.commonTexts.loading')} />
              ) : (
                <>
                  {t(
                    'manage.logs.common.thereAreNoEntriesInTheLogThatMatchTheFilters'
                  )}
                  .
                </>
              )}
            </TableRowPlaceholder>
          )}
        </TableBody>
      </Table>
    </TableWrapper>
  );
}
