import { CareGetBehaviorDataResult } from '../../../../serverApi';
import { addSecondsToDate } from './addSecondsToDate';

export const processBehaviorAreaChartData = (
  data: CareGetBehaviorDataResult,
  withAudioLevel?: boolean
) => {
  const startDate = new Date(data.time.startTime);
  const intervalInSeconds = data.time.samplePeriodS;
  let results = Array(data.samples.length);

  const bedMotionIndex = data.labels.findIndex(
    (label) => label === 'bedMotion'
  );
  const audioLevelIndex = data.labels.findIndex(
    (label) => label === 'audioLevel'
  );

  for (var i = 0; i < data.samples.length; i++) {
    const bedActivity =
      bedMotionIndex === -1 ? 0 : data.samples[i][bedMotionIndex];
    const audioLevel =
      audioLevelIndex === -1 ? 0 : data.samples[i][audioLevelIndex];

    results[i] = {
      date:
        i === 0
          ? startDate
          : addSecondsToDate(startDate, i * intervalInSeconds),
      bedActivity,
      ...(withAudioLevel && { audioLevel }),
    };
  }

  return results;
};
