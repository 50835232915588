import React, { PropsWithChildren } from 'react';
import { useZoneTreeQuery, ZoneTreeContextProvider } from './ZoneTreeContext';
import { CenterPanel } from '../../manage/common/CenterPanel';
import { InlineSpinner } from '../../../components/InlineSpinner';
import { t } from '../../../lib/i18n';

export default function DefaultZoneTreeContextProvider(
  props: PropsWithChildren<{}>
) {
  const tree = useZoneTreeQuery();

  if (tree.loading) {
    return (
      <CenterPanel>
        <InlineSpinner
          size="md"
          text={t('common.commonTexts.loading')}
          textStyle={{ fontWeight: 500, fontSize: 22 }}
        />
      </CenterPanel>
    );
  }

  if (!tree.root) {
    return null;
  }

  return (
    <ZoneTreeContextProvider value={tree}>
      {props.children}
    </ZoneTreeContextProvider>
  );
}
