import { Row, Col } from 'reactstrap';

import * as api from '../../serverApi';
import { useApiCall } from '../../lib/useApiCall';

import './SystemMessages.scss';

export function SystemMessages() {
  const res = useApiCall(api.getSystemMessages);
  const messages = res?.data?.messages ?? [];

  if (!messages?.length) {
    return null;
  }

  return (
    <Row>
      <Col lg="10">
        <ul className="SystemMessages">
          {messages.map((msg, i) => (
            <li
              key={`${msg.heading}-${i}`}
              className="SystemMessages-list-item"
            >
              <div className="SystemMessages-head">
                <span className="SystemMessages-heading">{msg.heading}</span>
              </div>
              <div className="SystemMessages-body">{msg.body}</div>
            </li>
          ))}
        </ul>
      </Col>
    </Row>
  );
}
