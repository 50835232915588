import { ChangeEvent, useEffect, useState } from 'react';
import { FormGroup } from '@material-ui/core';
import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';

import * as api from '../../../../../serverApi';
import useZoneSettingsForm from './lib/useZoneSettingsForm';
import { t } from '../../../../../lib/i18n';
import { useZoneContext } from '../../../../../lib/ZoneContext';
import { FormBody } from './lib/FormBody';
import { ErrorComponent } from '../../../../../components/forms/shared/ErrorComponent';
import { Panel } from '../../../../../components/Panel';

import { timeZoneOptions, TimeZoneOption } from './TimeZone.options';

import './TimeZone.scss';

const fields: Array<keyof api.ZoneSettings> = ['timezone'];

export const TimeZone = () => {
  const ctx = useZoneContext();
  const form = useZoneSettingsForm({ fields });

  const selectedOption = timeZoneOptions.find(
    (opt) => opt.value === form.displayValues?.timezone
  );

  const [searchValue, setSearchValue] = useState(selectedOption?.value);

  /**
   * Update the initial value of search value after loading the inital zone settings form values
   */
  useEffect(() => {
    /**
     * Update the search value only if the value is undefined;
     * this means we haven't selected any option and the user didn't start typing yet.
     *
     * It prevents triggering state update if user selects a new option
     * (i.e. we want this state update to happen only the first time we load the initial form values)
     */
    if (selectedOption && searchValue === undefined) {
      setSearchValue(selectedOption?.value);
    }
  }, [selectedOption, searchValue]);

  /**
   * Update time zone form field when a new option is selected.
   * @param newOption
   */
  const handleOptionChange = (
    e: ChangeEvent<{}>,
    newOption: TimeZoneOption | null
  ) => {
    form.formik.setFieldValue('timezone', newOption?.value ?? null);
    form.formik.setFieldTouched('timezone');
  };

  return (
    <div className="TimeZone">
      <h2>
        {t('manage.zone.TimeZone.headingFor')} {ctx.activeZone.name}
      </h2>

      <Panel>
        <p>
          <strong>{t('common.commonTexts.tips')}:</strong>{' '}
          {t(
            'manage.zone.settings.common.theSettingsFromTheParentZoneAreDisplayedInGrayWhenThisIsSelected'
          )}
        </p>
      </Panel>

      <FormBody zoneSettingsForm={form}>
        <FormGroup>
          <Autocomplete
            className="TimeZone__combo-box"
            value={selectedOption ?? { value: '' }}
            onChange={handleOptionChange}
            inputValue={searchValue ?? ''}
            onInputChange={(e, newValue) => {
              setSearchValue(newValue);
            }}
            getOptionLabel={(option) => option.value}
            getOptionSelected={(option, selectedOption) => {
              /**
               * This check is performed to not throw a warning when the initial empty value of the
               * combo box is not found on the available options
               */
              if (selectedOption.value === '') {
                return true;
              }
              return option.value === selectedOption.value;
            }}
            options={timeZoneOptions}
            disabled={form.disabled}
            disableClearable
            renderInput={(params) => (
              <TextField
                {...params}
                label={t(`manage.zone.settings.Settings.areas.time-zone`)}
                placeholder={t('manage.zone.TimeZone.search')}
              />
            )}
          />
          {form.formik.errors.timezone && (
            <ErrorComponent msg={form.formik.errors.timezone} />
          )}
        </FormGroup>
      </FormBody>
    </div>
  );
};
