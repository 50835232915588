import { useEffect } from 'react';
import { t } from './i18n';
export const useBeforeUnloadConfirmation = (dirty?: boolean) => {
  useEffect(() => {
    const listener = (e: BeforeUnloadEvent) => {
      if (dirty) {
        e.returnValue = t(
          'common.commonTexts.youAreAboutToLeaveThePageWithoutSavingTheChangesYouHaveMade'
        );
      }
    };
    window.addEventListener('beforeunload', listener);

    return () => window.removeEventListener('beforeunload', listener);
  }, [dirty]);
};
