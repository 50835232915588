import React from 'react';
import moment from 'moment';
import { RouteComponentProps } from 'react-router';
import getParentPath from '../../../lib/getParentPath';
import { InlineError } from '../../../components/InlineError';
import { IconButton } from '../../../components/IconButton';
import { Button } from '../../../components/Button';
import { useZoneContext } from '../../../lib/ZoneContext';
import { useRouter } from '../../../lib/useRouter';
import { RoleForm } from './forms/RoleForm';
import { useImpersonalUsersFormik } from './lib/useImpersonalUsersFormik';
import { ImpersonalUsersForm } from './forms/ImpersonalUsersForm';

import * as yup from 'yup';
import * as serverApi from '../../../serverApi';
import { useHandleCancel } from '../../../lib/useHandleCancel';
import { ButtonGroup } from '../../../components/ButtonGroup';
import { useErrorHandler } from '../../../lib/useErrorHandler';
import { ErrorMessages } from '../../../components/ErrorMessages';
import { useApiCall } from '../../../lib/useApiCall';
import { t } from '../../../lib/i18n';

import './NewImpersonal.scss';
import { isValidationError } from '../../../lib/utils/errorUtils';

interface Props extends RouteComponentProps<{}> {}

export const hardcodedImpersonalUserRoleName = 'impersonal';

export const NewImpersonal = (props: Props) => {
  const router = useRouter();
  const context = useZoneContext();
  const hc = useHandleCancel();
  const errorHandler = useErrorHandler();

  const res = useApiCall(serverApi.getAvailableRoles);

  const formik = useImpersonalUsersFormik({
    initialValues: {
      display_name: '',
      phone: '',
      preferred_locale: 'null',
      roles: [
        {
          role: hardcodedImpersonalUserRoleName,
          zone_id: context.activeZone.id,
          valid_from: null,
          valid_to: null,
        },
      ] as serverApi.CreateUserRoleInput[],
    },
    validationSchema: () =>
      yup.object().shape(
        {
          display_name: yup.string().when('phone', {
            is: (s: string) => s && s.length > 0,
            then: yup.string(),
            otherwise: yup
              .string()
              .required(t('manage.permissions.NewImpersonal.mustFillBoth')),
          }),
          phone: yup.string().when('display_name', {
            is: (s: string) => s && s.length > 0,
            then: yup.string().when('phone', {
              is: (s: string) => s && s.length > 0,
              then: yup
                .string()
                .test(
                  'phone',
                  t(
                    'manage.permissions.common.thePhoneNumberMustContainOnlyDigits'
                  ),
                  (v) => {
                    return typeof v === 'string'
                      ? /^\+?[0-9 ]+$/.test(v)
                      : true;
                  }
                ),
            }),
            otherwise: yup.string().when('phone', {
              is: (s: string) => s && s.length > 0,
              then: yup
                .string()
                .test(
                  'phone',
                  t(
                    'manage.permissions.common.thePhoneNumberMustContainOnlyDigits'
                  ),
                  (v) => {
                    return typeof v === 'string'
                      ? /^\+?[0-9 ]+$/.test(v)
                      : true;
                  }
                ),
              otherwise: yup
                .string()
                .required(t('manage.permissions.NewImpersonal.mustFillBoth')),
            }),
          }),
          phone2: yup.string().nullable(),
        },
        [['display_name', 'phone']]
      ),
    onSubmit: async (values, formikHelpers) => {
      try {
        formikHelpers.setSubmitting(true);
        await serverApi.createImpersonalUser({
          ...values,
          preferred_locale:
            values.preferred_locale === 'null' ? null : values.preferred_locale,
          roles: values.roles.map((r: serverApi.CreateUserRoleInput) => ({
            zone_id: r.zone_id,
            valid_from: r.valid_from,
            valid_to: r.valid_to,
          })),
        } as serverApi.CreateImpersonalUserData);
        formikHelpers.resetForm(values);
        router.history.push(
          '/manage/' + context.activeZone.id + '/permissions'
        );
      } catch (err) {
        formikHelpers.setSubmitting(false);

        if (isValidationError(err)) {
          formikHelpers.setErrors(err.response.data.errors);
        }
        errorHandler.handleError(err);
      }
    },
  });

  const setZone = (roleIndex: number, zoneId: number) => {
    formik.setFieldValue(
      'roles',
      formik.values.roles.map((r, i) =>
        i === roleIndex ? { ...r, zone_id: zoneId } : r
      )
    );
    formik.setFieldTouched('roles');
  };

  const handleDateChange = (
    roleIndex: number,
    name: string,
    time: moment.Moment | null
  ) => {
    formik.setFieldValue(
      'roles',
      formik.values.roles.map((role, i) =>
        i === roleIndex
          ? {
              ...role,
              [name]: time ? time.format('YYYY-MM-DD') : null,
            }
          : role
      )
    );
    formik.setFieldTouched('roles');
  };

  return (
    <div className="NewImpersonal mt-5">
      <h1>{t('manage.permissions.common.registerEmergencyTelephone')}</h1>

      <div className="my-4">
        <IconButton
          icon="arrow-left"
          to={getParentPath(props.location.pathname)}
        >
          {t('common.commonButtons.back')}
        </IconButton>
      </div>

      <div className="row">
        <div className="col-md-6 mt-3">
          <ImpersonalUsersForm mode="create" form={formik} />

          <div className="mt-4">
            <ButtonGroup direction="horizontal">
              <Button
                color="primary"
                disabled={formik.isSubmitting || !formik.isValid}
                spinIcon={formik.isSubmitting}
                icon={formik.isSubmitting ? 'gear' : undefined}
                onClick={formik.submitForm}
              >
                {t('common.commonButtons.save')}
              </Button>

              <Button
                onClick={() =>
                  hc.handleCancel(formik.dirty, null, () => formik.resetForm())
                }
              >
                {t('common.commonButtons.cancel')}
              </Button>
            </ButtonGroup>
            <ErrorMessages className="my-2" errorData={errorHandler} />
          </div>
        </div>

        <div className="col-md-6 NewImpersonal-roles mt-3">
          {formik.errors.roles && (
            <InlineError>{formik.errors.roles}</InlineError>
          )}

          {formik.values.roles.map((r, index) => (
            <RoleForm
              key={`${r.role}-${index}`}
              mode="create"
              roleLocked={true}
              role={r}
              availableRoles={res?.data?.roles}
              setZone={(zoneId) => setZone(index, zoneId)}
              setValidFrom={(validFrom) =>
                handleDateChange(index, 'valid_from', validFrom)
              }
              setValidTo={(validTo) =>
                handleDateChange(index, 'valid_to', validTo)
              }
              deleteDisabled={true}
            />
          ))}
        </div>
      </div>
    </div>
  );
};
