import React from 'react';
import cs from 'classnames';

import { t } from '../../../lib/i18n';

interface Props {
  className?: string;
  receivedBySensor?: null | {
    sensor_id: number;
    name: null | string;
    zone: null | {
      zone_id: number;
      name: null | string;
    };
  };
}

export function EventReceivedBySummary(props: Props) {
  if (!props.receivedBySensor) {
    return null;
  }

  const parts = [];

  if (props.receivedBySensor?.zone?.name) {
    parts.push(props.receivedBySensor.zone.name);
  }

  if (props.receivedBySensor?.name) {
    parts.push(props.receivedBySensor.name);
  }

  // Only show details if available
  if (!parts.length) {
    return null;
  }

  return (
    <div className={cs('EventReceivedBySummary', props.className)}>
      {t('care.components.EventReceivedBySummary.receivedBy')}
      {': '}
      {parts.join(' / ')}
    </div>
  );
}
