import React from 'react';

export const EditableTextCell = (props: any) => {
  const {
    value: initialValue,
    row: { index },
    column: { id },
    onUpdate: updateData,
  } = props;

  const [editing, setEditing] = React.useState(false);
  const [value, setValue] = React.useState(initialValue);

  const onChange = (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    setValue(e.target.value);
  };

  const onFocus = () => {
    setEditing(true);
  };

  const onBlur = () => {
    setEditing(false);
    if (value === initialValue) {
      return;
    }
    updateData(index, id, value);
  };

  // If the initialValue is changed external, sync it up with our state
  React.useEffect(() => {
    setValue(initialValue);
  }, [initialValue]);

  return (
    <span className="EditableTextCell">
      {!editing ? (
        <span onClick={onFocus} className="EditableTextCell-value">
          {value}
        </span>
      ) : (
        <input
          autoFocus
          value={value || ''}
          onChange={onChange}
          onBlur={onBlur}
          className="EditableTextCell-input"
        />
      )}
    </span>
  );
};
