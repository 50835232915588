/**
 * Generates dates for the highlight zone and midnight ruler
 * Highlight range being the hours between 22:00 - 08:00
 */
export const getHighlightRangeAndMidnightDate = (date: string) => {
  const startHour = new Date(date).getHours();
  const midnight = new Date(date);

  midnight.setDate(midnight.getDate() + 1);
  midnight.setHours(0, 0, 0, 0);

  /**
   * If period start hous is between 8 and 22, we have to render a single rectangle as highlight zone
   */
  if (startHour >= 8 && startHour < 22) {
    const start = new Date(date);
    const end = new Date(date);

    start.setHours(22, 0, 0, 0);

    end.setDate(end.getDate() + 1);
    end.setHours(8, 0, 0, 0);

    return { range: [{ start, end, id: 1 }], midnight };
  }

  /**
   * Otherwise, we have to split the highlight zone/area into two separate rectangles
   */
  let rect1 = { start: new Date(date), end: new Date(date), id: 1 };
  let rect2 = { start: new Date(date), end: new Date(date), id: 2 };

  /**
   * Case when starting hour is earlier than 8 AM
   */
  if (startHour < 8) {
    rect1.end.setHours(8, 0, 0, 0);

    rect2.start.setHours(22, 0, 0, 0);
    rect2.end.setDate(rect2.end.getDate() + 1);
    rect2.end.setHours(startHour + 1, 0, 0, 0); //We add an extra hour at the end to compensate the minutes that
  }
  /**
   * Case when starting hour is after or equal to 10PM
   */
  if (startHour >= 22) {
    rect1.end.setDate(rect1.end.getDate() + 1);
    rect1.end.setHours(8, 0, 0, 0);

    rect2.start.setDate(rect2.start.getDate() + 1);
    rect2.start.setHours(22, 0, 0, 0);
    rect2.end.setDate(rect2.end.getDate() + 1);
    rect2.end.setHours(startHour + 1, 0, 0, 0);
  }

  return { range: [rect1, rect2], midnight };
};
