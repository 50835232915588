import React from 'react';
import { RouteComponentProps } from 'react-router-dom';
import { Switch, Route } from 'react-router';
import { ListIntegrations } from './ListIntegrations';
import { SetupIntegration } from './SetupIntegration';
import { ResetIntegrationUserPassword } from './ResetIntegrationUserPassword';

interface Props {}

export const Integrations: React.FC<RouteComponentProps<Props>> = (props) => {
  return (
    <Switch>
      <Route exact path={props.match.path} component={ListIntegrations} />
      <Route
        exact
        path={`${props.match.path}/new`}
        component={SetupIntegration}
      />
      <Route
        path={`${props.match.path}/reset-password/:id`}
        component={ResetIntegrationUserPassword}
      />
    </Switch>
  );
};
