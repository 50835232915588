import React, { useState } from 'react';
import matchSorter from 'match-sorter';

import { AlertSettingsTable } from './ui/AlertSettingsTable';
import { IconButton } from '../../../components/IconButton';
import FilterInput from '../../../components/FilterInput/FilterInput';

import { useApiCall } from '../../../lib/useApiCall';
import { AlertSetting, getAlertSettings } from '../../../serverApi';
import { useZoneContext } from '../../../lib/ZoneContext';
import { useRouter } from '../../../lib/useRouter';
import { t } from '../../../lib/i18n';
import { AlertSettingForSingleSensorType } from './lib/types';
import {
  ManageHelpDropdown,
  ManageHelpOption,
} from '../common/ManageHelpDropdown';

export function ListAlertSettings() {
  const router = useRouter();
  const context = useZoneContext();

  const [search, setSearch] = useState('');

  const res = useApiCall(getAlertSettings, [context.activeZone.id]);

  const filterAlertSettings = (
    alertSettings: AlertSetting[],
    keyword?: string
  ): AlertSetting[] => {
    if (!keyword) {
      return alertSettings;
    }

    return matchSorter(alertSettings, keyword, {
      keys: ['name', 'zone.name'],
    });
  };

  const alertSettings = res?.data?.alert_settings
    ? filterAlertSettings(res.data.alert_settings, search)
    : [];

  const roommateAlertSettings: AlertSettingForSingleSensorType[] = alertSettings.map(
    (als) => ({
      ...als,
      event_types: als.event_types
        .filter((a) => a.sensor_type === 'roommate')
        .map((b) => b.event_type),
    })
  );

  const helpDropdownOptions: ManageHelpOption[] = [
    {
      title: t('common.video.manage.addAlertSettings.title'),
      vimeoId: t('common.video.manage.addAlertSettings.vimeo'),
      type: 'video',
    },
    {
      title: t('common.pdf.manage.systemAdministrator.title'),
      link: t('common.url.adminUserGuidePdf'),
      type: 'pdf',
    },
  ];

  return (
    <>
      <div className="Manage__heading-wrapper">
        <h1 className="m-0">
          {t('manage.alerts.common.notificationRulesAndRecipients')}:{' '}
          {context.activeZone.name}
        </h1>
        <ManageHelpDropdown options={helpDropdownOptions} />
      </div>

      <div className="d-flex align-items-start justify-content-between flex-column flex-sm-row  my-4">
        <IconButton icon="plus" color="primary" to={router.match.path + '/new'}>
          {t('manage.alerts.ListAlertSettings.addNewAlertRule')}
        </IconButton>

        <FilterInput
          onChange={(name, value) => setSearch(value)}
          className="mb-2"
        />
      </div>

      <AlertSettingsTable
        loading={res.loading}
        settings={roommateAlertSettings}
      />
    </>
  );
}
