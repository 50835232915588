import React from 'react';
import cs from 'classnames';

import './CenteredCard.scss';

interface Props {
  className?: string;
  children?: React.ReactNode;
  showIcon?: boolean;
}

export const CenteredCard = (props: Props) => (
  <div className={cs('CenteredCard', props.className)}>
    <div className="container">
      <div className="row">
        <div className="col-lg-8 offset-lg-2 col-md-10 offset-md-1">
          <div className="CenteredCard-wrapper mb-3">
            {props.children}
            {props.showIcon === false ? null : (
              <span className="CenteredCard-wrapper-icon" />
            )}
          </div>
        </div>
      </div>
    </div>
  </div>
);
