import React from 'react';
import { List, ListItem } from '@material-ui/core';
import moment from 'moment';

import { t } from '../../../lib/i18n';
import { useFetchUnassignedClimaxSensors } from '../../../lib/useFetchUnassignedClimaxSensors';
import { UnassignedClimaxSensorDetails } from '../../../serverApi';
import { getLabelForSensorType } from '../../../lib/getLabelForSensorType';

import './AvailableOtherSensorsListSelect.scss';

interface Props {
  selectedSensor: string | undefined;
  onSelectedSensor: (sensor: UnassignedClimaxSensorDetails) => void;
}

export function AvailableOtherSensorsListSelect(props: Props) {
  const res = useFetchUnassignedClimaxSensors();

  if (res.loading && res.data === undefined) {
    return <p>Laster...</p>;
  }

  if (res.data === undefined) {
    return <p>{t('common.commonTexts.error')}</p>;
  }

  const data = res.data;
  return (
    <div className="AvailableOtherSensorsListSelect">
      <List>
        {data.length === 0 ? (
          <ListItem style={{ cursor: 'inherit' }}>
            {t('manage.sensors.assign.AssignNewSensor.noSensorsFound')}
          </ListItem>
        ) : (
          data.map((s) => (
            <ListItem
              key={s.sensor_id}
              className={
                `${s.sensor_type}${s.sensor_id}` === props.selectedSensor
                  ? 'selected'
                  : ''
              }
              onClick={() => props.onSelectedSensor(s)}
            >
              {`${getLabelForSensorType(s.sensor_type)} - ${moment(
                s.last_seen_at
              ).format('HH:mm')}`}
            </ListItem>
          ))
        )}
      </List>
    </div>
  );
}
