import React from 'react';
import { FormControlLabel, RadioGroup, Radio } from '@material-ui/core';

import useZoneSettingsForm from './lib/useZoneSettingsForm';
import { Panel } from '../../../../../components/Panel';
import { useZoneContext } from '../../../../../lib/ZoneContext';
import { FormBody } from './lib/FormBody';

import * as api from '../../../../../serverApi';
import { t } from '../../../../../lib/i18n';

const fields: Array<keyof api.ZoneSettings> = ['sensor_resolution'];

export function SensorResolution() {
  const ctx = useZoneContext();
  const form = useZoneSettingsForm({ fields });

  const options = [
    {
      value: '160',
      label: t('manage.zone.SensorResolution.160'),
    },
    {
      value: '320',
      label: t('manage.zone.SensorResolution.320'),
    },
    {
      value: '640',
      label: t('manage.zone.SensorResolution.640'),
    },
  ];

  return (
    <div>
      <h2>
        {t('manage.zone.SensorResolution.headingFor')} {ctx.activeZone.name}
      </h2>

      <Panel>
        <strong>{t('common.commonTexts.tips')}:</strong>{' '}
        {t('manage.zone.SensorResolution.tips')}
      </Panel>

      <FormBody zoneSettingsForm={form}>
        <RadioGroup
          aria-label={t(
            'manage.zone.settings.Settings.areas.sensor-resolution'
          )}
          name="sensor_resolution"
          value={form.displayValues?.sensor_resolution?.toString() || ''}
          onChange={(e) => {
            form.formik.setFieldValue(
              'sensor_resolution',
              e.currentTarget.value
            );
            form.formik.setFieldTouched('sensor_resolution');
          }}
        >
          {options.map((option) => (
            <FormControlLabel
              key={option.value}
              disabled={form.disabled}
              value={option.value}
              control={<Radio color="primary" />}
              label={option.label}
            />
          ))}
        </RadioGroup>
      </FormBody>
    </div>
  );
}
