import { FormikHelpers, FormikValues, useFormik } from 'formik';
import * as yup from 'yup';

export interface ArgusBasicManagementFormFields {
  'argus-enable'?: boolean | null;
  'alert-activation-delay-secs'?: number | null;
}

export type ArgusBasicManagementFormik = ReturnType<
  typeof useArgusBasicManagementForm
>;

interface UseArgusBasicManagementFormikOptions {
  initialValues?: ArgusBasicManagementFormFields;
  onSubmit: (
    values: FormikValues,
    formikHelpers: FormikHelpers<ArgusBasicManagementFormFields>
  ) => Promise<void>;
}

export function useArgusBasicManagementForm(
  opts?: UseArgusBasicManagementFormikOptions
) {
  return useFormik<ArgusBasicManagementFormFields>({
    initialValues: {
      'argus-enable': false,
      'alert-activation-delay-secs': null,
      ...opts?.initialValues,
    },
    enableReinitialize: true,
    validateOnChange: true,
    validateOnBlur: true,
    validationSchema: () =>
      yup.object().shape({
        'argus-enable': yup.boolean(),
        'alert-activation-delay-secs': yup.number().integer().nullable(),
      }),

    onSubmit: async (values: FormikValues, formikHelpers) => {
      if (opts?.onSubmit) {
        await opts.onSubmit(values, formikHelpers);
      }
    },
  });
}
