import React from 'react';
import { FormGroup, TextField, MenuItem } from '@material-ui/core';

import { EventSettingsForm } from '../useEventSettingsForm';
import { ErrorComponent } from '../../../../../components/forms/shared/ErrorComponent';
import { t } from '../../../../../lib/i18n';
import { GreenCircleIndicator } from '../../../common/ManageSidebar';

const none = '__none__';

export default function PlayEventSoundControl(props: {
  form: EventSettingsForm;
  enableFallOption?: boolean;
}) {
  const { form } = props;

  const options = props.enableFallOption
    ? [
        {
          value: none,
          label: t(
            'manage.sensors.events.controls.PlayEventSoundControl.noSound'
          ),
        },
        { value: 'ding.wav', label: 'Ding' },
        { value: 'Mari90.wav', label: 'Fall' },
      ]
    : [
        {
          value: none,
          label: t(
            'manage.sensors.events.controls.PlayEventSoundControl.noSound'
          ),
        },
        { value: 'ding.wav', label: 'Ding' },
      ];

  const fieldHasChanged =
    (form.values.audio_file || '') !== (form.initialValues.audio_file || '');

  return (
    <FormGroup className="mt-4 position-relative" style={{ maxWidth: '200px' }}>
      <TextField
        select={true}
        value={form.values.audio_file || none}
        label={t(
          'manage.sensors.events.controls.PlayEventSoundControl.soundWarningFromTheSensor'
        )}
        onChange={(e) => {
          if (e.target.value === none) {
            form.setFieldValue('audio_file', '');
            form.setFieldTouched('audio_file');
          } else {
            form.handleChange(e);
          }
          form.setFieldValue('audio_enabled', e.target.value !== none);
          form.setFieldTouched('audio_enabled');
        }}
        name="audio_file"
      >
        {options.map((opt) => (
          <MenuItem key={opt.value} value={opt.value}>
            {opt.label}
          </MenuItem>
        ))}
      </TextField>
      {form.errors.audio_file && (
        <ErrorComponent msg={form.errors.audio_file} />
      )}
      {fieldHasChanged && (
        <GreenCircleIndicator style={{ right: '-15px', top: '70%' }} />
      )}
    </FormGroup>
  );
}
