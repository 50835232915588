import React from 'react';
import cs from 'classnames';

import { InlineSpinner } from '../../components/InlineSpinner';
import * as serverApi from '../../serverApi';

import { t } from '../../lib/i18n';
import { renderDeviceTitle } from './OneTimeCode';

import './OneTimeCodeSelectDevice.scss';

interface Props {
  devices?: Array<serverApi.OtpDevice>;
  onSelectDevice?: (device: serverApi.OtpDevice, deviceIndex?: string) => void;
  loading?: string;
  className?: string;
}

export function OneTimeCodeSelectDevice(props: Props) {
  return (
    <div className={cs('OneTimeCodeSelectDevice', props.className)}>
      {props.devices?.length ? (
        <>
          <div className="mb-2">
            <strong>
              {t(
                'publicComponents.OneTimeCode.youHaveToProvideOneTimeCodeAuthentication'
              )}
            </strong>
          </div>

          {props.devices.map((device, ix) => {
            const deviceIndex = `${device.channel}-${ix}`;
            const loading = props.loading === deviceIndex;
            return (
              <button
                key={ix}
                className="btn btn-secondary mb-2"
                data-cy={`otp-device-${ix}`}
                style={{ display: 'block', width: '100%' }}
                disabled={!!props.loading}
                onClick={() => props.onSelectDevice?.(device, deviceIndex)}
              >
                {loading ? (
                  <InlineSpinner
                    text={`${t('common.commonTexts.pleaseWait')}...`}
                  />
                ) : (
                  <>{renderDeviceTitle(device)}</>
                )}
              </button>
            );
          })}
        </>
      ) : (
        <>
          <div className="mb-2">
            <strong>
              {t('publicComponents.OneTimeCode.noAvailableDevicesForOtp')}
            </strong>
          </div>
        </>
      )}
    </div>
  );
}
