import React from 'react';
import * as api from '../../../../serverApi';
import { TableWrapper } from '../../../../components/TableWrapper';

import {
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
} from '@material-ui/core';
import { TableRowPlaceholder } from '../../common/TableRowPlaceholder';
import { InlineSpinner } from '../../../../components/InlineSpinner';
import { t, TranslationKey } from '../../../../lib/i18n';

interface Props {
  entries?: api.AuditLogEntry[];
  loading?: boolean;
}

function Row(props: { entry: api.AuditLogEntry }) {
  const e = props.entry;
  return (
    <TableRow key={e.id}>
      <TableCell>{e.at_user_local}</TableCell>
      <TableCell>
        {t(
          `manage.logs.audit.AuditLogEntriesTable.events.${e.event}` as TranslationKey
        )}
      </TableCell>
      <TableCell>
        {e.user
          ? `${e.user.display_name || ''} ${e.user.first_name || ''} ${
              e.user.last_name || ''
            }${e.user.username ? ` (${e.user.username})` : ''}`
          : ''}
      </TableCell>
      <TableCell>
        {e.role ? (
          <>
            {t(`common.roles.${e.role.role}` as TranslationKey)}
            {e.role.zone ? `, ${e.role.zone.name}` : ''}
          </>
        ) : null}
      </TableCell>
    </TableRow>
  );
}

export function AuditLogEntriesTable(props: Props) {
  return (
    <TableWrapper>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell>{t('manage.logs.common.pointInTime')}</TableCell>
            <TableCell>{t('manage.logs.common.action')}</TableCell>
            <TableCell>
              {t('manage.logs.audit.AuditLogEntriesTable.user')}
            </TableCell>
            <TableCell>
              {t('manage.logs.audit.AuditLogEntriesTable.roleAndZone')}
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {props.entries && props.entries.length > 0 ? (
            props.entries.map((e) => <Row entry={e} key={e.id} />)
          ) : (
            <TableRowPlaceholder colSpan={7}>
              {props.loading ? (
                <InlineSpinner text={t('common.commonTexts.loading')} />
              ) : (
                <>
                  {t(
                    'manage.logs.common.thereAreNoEntriesInTheLogThatMatchTheFilters'
                  )}
                  .
                </>
              )}
            </TableRowPlaceholder>
          )}
        </TableBody>
      </Table>
    </TableWrapper>
  );
}
