import { FormikHelpers, useFormik } from 'formik';
import { AlertSettingState } from '../AlertSettingsForm';
import { defaultTime } from '../ui/ScheduleSelectorRule';
import { allDays } from '../ui/ScheduleSelector';
import { useZoneContext } from '../../../../lib/ZoneContext';
import * as yup from 'yup';
import { t } from '../../../../lib/i18n';

export interface AlertSettingsFields extends AlertSettingState {}

export type AlertSettingsFormik = ReturnType<typeof useAlertSettingsFormik>;

interface UseAlertSettingsFormOptions {
  initialValues?: AlertSettingsFields;
  validationSchema?: any | (() => any);
  onSubmit: (
    values: AlertSettingsFields,
    formikHelpers: FormikHelpers<AlertSettingsFields>
  ) => Promise<any>;
}

/**
 * Formik wrapper for alert settings forms.
 */
export function useAlertSettingsFormik(opts: UseAlertSettingsFormOptions) {
  const context = useZoneContext();
  return useFormik<AlertSettingsFields>({
    initialValues: {
      name: '',
      zone_id: context.activeZone.id,
      enabled: true,
      event_types: [],
      schedule: [
        {
          from_time: defaultTime,
          from_weekday: allDays,
          to_time: defaultTime,
          to_weekday: allDays,
        },
      ],
      recipients: [],
      escalation_delay: 180,
      escalation_enabled: false,
      escalation_recipients: [],
      include_regular_alarm_events:
        opts.initialValues?.include_regular_alarm_events ?? true,
      include_regular_alert_events:
        opts.initialValues?.include_regular_alert_events ?? true,
      include_technical_event_types:
        opts.initialValues?.include_technical_event_types ?? true,
      include_custom_event_types:
        opts.initialValues?.include_custom_event_types ?? false,
      ...opts.initialValues,
      api_lock: false,
    },
    validateOnBlur: true,
    validateOnChange: true,
    validationSchema: () =>
      yup.object().shape({
        name: yup.string().nullable(),
        zone_id: yup
          .number()
          .required(t('manage.alerts.common.aZoneOrHousingUnitIsRequired')),
        event_types: yup.array(),
        schedule: yup
          .array()
          .required(
            t('manage.alerts.lib.useAlertSettingsFormik.mustSpecifySchedule')
          ),
        ...opts.validationSchema,
      }),
    onSubmit: async (values, formikHelpers) => {
      await opts.onSubmit(values, formikHelpers);
    },
  });
}
