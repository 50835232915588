import React, { useEffect, useState } from 'react';
import cs from 'classnames';
import { Switch, Redirect, Route } from 'react-router';

import { Tabs } from './Tabs';

import { useRouter } from '../../lib/useRouter';

import Zone from './zone/Zone';
import { CareReceiver } from './carereceivers/CareReceiver';

import { Alerts } from './alerts/Alerts';
import { Sensors } from './sensors/Sensors';

import Permissions from './permissions/Permissions';
import Logs from './logs/Logs';
import getFirstSegmentRelativeToRoot from '../../lib/getFirstSegmentRelativeToRoot';
import { t, TranslationKey } from '../../lib/i18n';

const tabs = [
  {
    key: 'zone',
    component: Zone,
  },
  {
    key: 'care-receivers',
    component: CareReceiver,
  },
  {
    key: 'permissions',
    component: Permissions,
  },
  {
    key: 'sensors',
    component: Sensors,
  },
  {
    key: 'alerts',
    component: Alerts,
  },
  {
    key: 'logs',
    route: 'logs/:log([A-Za-z0-9-]+)?',
    component: Logs,
  },
];

interface Props {
  className?: string;
}

export function ZoneArea(props: Props) {
  const router = useRouter();

  const currentSegment = getFirstSegmentRelativeToRoot(
    router.match.url,
    router.location.pathname
  );

  const [activeTab, setActiveTab] = useState<string | undefined>(
    currentSegment || 'zone'
  );

  useEffect(() => {
    if (currentSegment) {
      setActiveTab(currentSegment);
    }
  }, [currentSegment]);

  return (
    <div className={cs('ZoneArea Manage-round-container', props.className)}>
      <Tabs
        className="mt-3 mb-5"
        tabs={tabs.map((tab) => ({
          ...tab,
          label: t(`manage.ZoneArea.${tab.key}` as TranslationKey),
        }))}
        activeTab={activeTab}
        onSetActiveTab={(tab) => {
          // setActiveTab(tab);
          router.history.push(`${router.match.url}/${tab}`);
        }}
      />

      <Switch>
        <Route
          exact={true}
          path={router.match.url}
          render={() => <Redirect to={`${router.match.url}/${activeTab}`} />}
        />
        {tabs.map((tab) => (
          <Route
            key={tab.key}
            path={`${router.match.url}/${tab.route ?? tab.key}`}
            component={tab.component}
          />
        ))}
      </Switch>
    </div>
  );
}
