import React, { useState } from 'react';
// import { useZoneContext } from '../../../lib/ZoneContext';
import { RouteComponentProps } from 'react-router';
import { toast } from 'react-toastify';
import { Button } from '../../../components/Button';
import { useZoneContext } from '../../../lib/ZoneContext';
import { useApiCall } from '../../../lib/useApiCall';
import * as serverApi from '../../../serverApi';
import { isLocale, t, TranslationKey } from '../../../lib/i18n';

import getParentPath from '../../../lib/getParentPath';
import LoadingPlaceholder from '../../../lib/ui/LoadingPlaceholder';
import { IconButton } from '../../../components/IconButton';
import { useConfirmation } from '../../../lib/confirmation/ConfirmationContext';
import { useRouter } from '../../../lib/useRouter';

import { CreateUserRolesForExistingUser } from './CreateUserRolesForExistingUser';
import { UserRoleCard } from './UserRoleCard';

import './UserDetails.scss';
import { ButtonGroup } from '../../../components/ButtonGroup';
import { useErrorHandler } from '../../../lib/useErrorHandler';
import { ErrorMessages } from '../../../components/ErrorMessages';
import { StatusMarker } from '../../../lib/ui/StatusMarker';
import { useAccount } from '../../../lib/useAccount';
import { Redirect } from 'react-router-dom';

type Props = RouteComponentProps<{ userId: string }>;

export function UserDetails(props: Props) {
  const [isDeleting, setIsDeleting] = useState(false);
  const [isInactivating, setIsInactivating] = useState(false);

  const confirmation = useConfirmation();
  const ctx = useZoneContext();
  const router = useRouter();
  const errorHandler = useErrorHandler();

  const userId = parseInt(props.match.params.userId);
  const userData = useApiCall(serverApi.getUser, [userId]);
  const account = useAccount();
  const user = userData?.data;

  const isImpersonalUser = user && !!user.impersonal_user;

  const deactivateUser = () => {
    if (user) {
      confirmation.confirm().then(async () => {
        setIsInactivating(true);
        serverApi
          .updateUser(userId, {
            enabled: !user.enabled,
          })
          .then((data) => {
            setIsInactivating(false);
            toast.success(
              data.user.enabled
                ? t('manage.permissions.UserDetails.userIsActivated')
                : t('manage.permissions.UserDetails.userIsDeactivated')
            );
            userData.reload();
          })
          .catch((e) => {
            setIsInactivating(false);
            errorHandler.handleError(e);
            toast.error(t('common.commonTexts.somethingWentWrong'));
          });
      });
    }
  };

  const unassignUserFromMobileDevice = async () => {
    if (!user) {
      return; // Typscript check
    }

    try {
      await confirmation.confirm(
        t('manage.permissions.UserDetails.confirmDelete')
      );
      try {
        await serverApi.unassignUserFromMobileDevice({
          user_id: user.user_id,
          mobile_device_id: user.mobile_device_id,
        });
        userData.reload();
      } catch (e) {
        errorHandler.handleError(e);
      }
    } catch (e) {}
  };

  const handleDelete = async () => {
    confirmation
      .confirm(t('common.commonTexts.toConfirmDeletionPressOk'))
      .then(async () => {
        try {
          setIsDeleting(true);
          await serverApi.deleteUser(userId);
          setIsDeleting(false);
          router.history.push(`/manage/${ctx.activeZone.id}/permissions/`);
        } catch (e) {
          setIsDeleting(false);
          errorHandler.handleError(e);
        }
      })
      .catch(() => {});
  };

  const handleAddDevice = async () => {
    if (!user) {
      return;
    }

    try {
      await serverApi.addUserToMobileDevice({
        user_id: user.user_id,
        override: true,
      });
    } catch (e) {
      errorHandler.handleError(e);
    }

    userData.reload();
  };

  const handleRoleChange = () => {
    /**
     * Need reload zoneContext since its uses role
     * information to determine if a zone can be deleted
     * or not.
     */
    userData.reload();
    ctx.reload();
  };

  if (user && user.api_lock) {
    return <Redirect to={`/manage/${ctx.activeZone.id}/permissions`} />;
  }

  return (
    <div className="UserDetails">
      <h1>
        {t('manage.permissions.common.serviceProvidersAndAccesses')}:{' '}
        {ctx.activeZone.name}
      </h1>

      <IconButton
        icon="arrow-left"
        to={getParentPath(props.location.pathname)}
        className="my-4"
      >
        {t('common.commonButtons.back')}
      </IconButton>

      {userData.loading ? (
        <LoadingPlaceholder />
      ) : user ? (
        <div className="my-5">
          <div className="row">
            <div className="UserDetails-info col-sm-6">
              <div className="UserDetails-content">
                <h3>{t('common.commonTexts.enabled')}:</h3>
                <div className="UserDetails-content-value">
                  <StatusMarker
                    status={user.enabled ? 'success' : 'danger'}
                    label={
                      user.enabled
                        ? t('common.commonTexts.yes')
                        : t('common.commonTexts.no')
                    }
                  />
                </div>
              </div>
              {user.app_reg && (
                <div className="UserDetails-content">
                  <h3>{t('manage.permissions.UserDetails.appCode')}</h3>
                  <div className="UserDetails-content-value">
                    {user.app_reg}
                  </div>
                </div>
              )}
              <div className="UserDetails-content">
                <h3>{t('manage.permissions.UserDetails.hasAppTitle')}</h3>
                <div className="UserDetails-content-value">
                  <StatusMarker
                    status={user.has_registered_app ? 'success' : 'danger'}
                    label={
                      user.has_registered_app
                        ? t('common.commonTexts.yes')
                        : t('common.commonTexts.no')
                    }
                  />
                  <br />
                  {user.has_registered_app && (
                    <Button
                      className="mt-2"
                      icon={isDeleting ? 'gear' : undefined}
                      spinIcon={isDeleting}
                      color="secondary"
                      onClick={unassignUserFromMobileDevice}
                    >
                      {t('manage.permissions.UserDetails.removeUnit')}
                    </Button>
                  )}

                  {!user.has_registered_app && !user.app_reg && (
                    <Button
                      className="mt-2"
                      icon={isDeleting ? 'gear' : undefined}
                      spinIcon={isDeleting}
                      color="primary"
                      onClick={handleAddDevice}
                    >
                      {t('manage.permissions.UserDetails.addUnit')}
                    </Button>
                  )}
                </div>
              </div>

              {user.username && (
                <div className="UserDetails-content">
                  <h3>
                    {t('manage.permissions.UserDetails.usernameEmployeeNumber')}
                    :
                  </h3>
                  <div className="UserDetails-content-value">
                    {user.username}
                  </div>
                </div>
              )}

              {!user.impersonal_user && !account.disable_national_id_numbers && (
                <div className="UserDetails-content">
                  <h3>{t('common.commonTexts.dateOfBirthday')}:</h3>
                  <div className="UserDetails-content-value">
                    {user.national_id_number ||
                      t('common.commonTexts.notRegistered')}
                  </div>
                </div>
              )}

              {user.display_name && (
                <div className="UserDetails-content">
                  <h3>{t('common.commonTexts.name')}:</h3>
                  <div className="UserDetails-content-value">
                    {user.display_name}
                  </div>
                </div>
              )}

              {user.first_name && (
                <div className="UserDetails-content">
                  <h3>{t('common.commonTexts.firstNameAndMiddleName')}</h3>
                  <div className="UserDetails-content-value">
                    {user.first_name}
                  </div>
                </div>
              )}

              {user.last_name && (
                <div className="UserDetails-content">
                  <h3>{t('common.commonTexts.surname')}:</h3>
                  <div className="UserDetails-content-value">
                    {user.last_name}
                  </div>
                </div>
              )}

              <div className="UserDetails-content">
                <h3>{t('common.commonTexts.telephone')}:</h3>
                <div className="UserDetails-content-value">
                  {user.phone}
                  {user.phone2 && <br />}
                  {user.phone2}
                </div>
              </div>
              {user.email && (
                <div className="UserDetails-content">
                  <h3>{t('common.commonTexts.email')}:</h3>
                  <div className="UserDetails-content-value">{user.email}</div>
                </div>
              )}

              <div className="UserDetails-content">
                <h3>{t('common.commonLanguages.language')}:</h3>
                <div className="UserDetails-content-value">
                  {isLocale(user.preferred_locale)
                    ? t(
                        `common.commonLanguages.languages.${user.preferred_locale}` as TranslationKey
                      )
                    : t('common.commonLanguages.default')}
                </div>
              </div>

              <ButtonGroup>
                <Button
                  color="primary"
                  to={`/manage/${ctx.activeZone.id}/permissions/user/${userId}/edit`}
                  data-cy="manage-edit-user"
                >
                  {t('common.commonButtons.edit')}
                </Button>
                <Button
                  icon={isInactivating ? 'gear' : undefined}
                  spinIcon={isInactivating}
                  onClick={deactivateUser}
                  color={user.enabled ? undefined : 'primary'}
                >
                  {user.enabled
                    ? t('common.commonTexts.disable')
                    : t('common.commonTexts.enabled')}
                </Button>
                <Button
                  icon={isDeleting ? 'gear' : undefined}
                  spinIcon={isDeleting}
                  color="secondary"
                  onClick={handleDelete}
                  data-cy="manage-delete-user"
                >
                  {t('common.commonButtons.delete')}
                </Button>
              </ButtonGroup>

              <ErrorMessages className="my-2" errorData={errorHandler} />
            </div>
            <div className="UserDetails-roles col-sm-6">
              <h3 className="pb-3 mb-3">
                {t('manage.permissions.UserDetails.rolesPermissions')}
              </h3>

              <CreateUserRolesForExistingUser
                userId={user.user_id}
                userNationalIdNumber={user.national_id_number}
                isImpersonalUser={isImpersonalUser}
                onAfterChange={userData.reload}
              />

              {user.user_roles.map((ur) => (
                <UserRoleCard
                  key={ur.user_role_id}
                  userId={user.user_id}
                  userNationalIdNumber={user.national_id_number}
                  userRole={ur}
                  onAfterChange={handleRoleChange}
                />
              ))}
            </div>
          </div>
        </div>
      ) : null}
    </div>
  );
}
