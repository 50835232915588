import React, { useState, CSSProperties } from 'react';
import cs from 'classnames';
import { useZoneContext } from '../../../../lib/ZoneContext';
import { ObservationLogEntriesTable } from './ObservationLogEntriesTable';

import { useApiCall } from '../../../../lib/useApiCall';
import { Filters } from '../ui/Filters';
import { Pager } from '../ui/Pager';

import * as api from '../../../../serverApi';
import { t } from '../../../../lib/i18n';

import { format, sub } from 'date-fns';
import { IconButton } from '../../../../components/IconButton';
import { LogsFilterWrapper } from '../ui/LogsFilterWrapper';
import { openFileDownloadLink } from '../../../../lib/openFileDownloadLink';
import { useOnZoneChange } from '../lib/useOnZoneChange';

interface Props {
  className?: string;
  style?: CSSProperties;
}

export function ObservationLog(props: Props) {
  const ctx = useZoneContext();
  const [params, setParams] = useState<api.GetStreamAccessLogInput>({
    zone_id: ctx.activeZone.id,
    from: format(sub(Date.now(), { days: 7 }), 'yyyy-MM-dd'),
    to: format(Date.now(), 'yyyy-MM-dd'),
    page: 0,
    page_size: 20,
  });

  useOnZoneChange((zone_id) => setParams({ ...params, zone_id }));
  const res = useApiCall(api.getStreamAccessLog, [params], {
    skip:
      !(params.from && params.from !== 'Invalid date') ||
      !(params.to && params.to !== 'Invalid date'),
  });

  const handleExport = () => {
    openFileDownloadLink(
      `/api/export/stream-logs/${params.zone_id}/${params.from}/${params.to}`,
      `observasjonslogg-${params.zone_id}.xlsx`
    );
  };

  return (
    <div className={cs('ObservationLog', props.className)} style={props.style}>
      <LogsFilterWrapper>
        <Filters
          periodFrom={params.from}
          periodTo={params.to}
          zoneId={params.zone_id}
          onZoneChange={(zone_id) => setParams({ ...params, zone_id })}
          onPeriodChange={(from, to) => setParams({ ...params, from, to })}
        />

        <IconButton
          color="primary"
          icon="export"
          onClick={handleExport}
          disabled={!res.data || res.data.data.length === 0}
        >
          {t('common.commonTexts.exportToExcel')}
        </IconButton>
      </LogsFilterWrapper>

      <Pager
        loading={res.loading}
        className="my-3"
        pager={res?.data}
        onPageChange={(page) => setParams({ ...params, page })}
      />

      <ObservationLogEntriesTable
        loading={res.loading}
        entries={res.data?.data}
      />
    </div>
  );
}
