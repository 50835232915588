import React, { ChangeEvent, useEffect, useState } from 'react';
import { Checkbox, FormControlLabel, FormGroup } from '@material-ui/core';
import { useSensorContext } from '../../../../lib/SensorContext';
import * as api from '../../../../serverApi';
import { useErrorHandler } from '../../../../lib/useErrorHandler';
import { ErrorMessages } from '../../../../components/ErrorMessages';
import { InlineSpinner } from '../../../../components/InlineSpinner';
import { t } from '../../../../lib/i18n';

interface Props {
  onChanged?: () => void;
}

export const ShowReceivingSensorCheckbox = (props: Props) => {
  const sensorCtx = useSensorContext();
  const errorHandler = useErrorHandler();

  const [loading, setLoading] = useState(false);
  const [checked, setChecked] = useState<boolean>(
    sensorCtx.show_receiving_sensor
  );

  useEffect(() => {
    setChecked(sensorCtx.show_receiving_sensor);
  }, [sensorCtx]);

  const onCheckboxChange = async (e: ChangeEvent<HTMLInputElement>) => {
    const checkedValue = e.target.checked;

    e.persist();

    if (!sensorCtx || !sensorCtx.id) {
      return;
    }
    try {
      setLoading(true);
      await api.updateSensorSetting(sensorCtx.id, {
        show_receiving_sensor: checkedValue,
      });

      setLoading(false);

      setChecked(checkedValue);

      if (props.onChanged) {
        props.onChanged();
      }
    } catch (e) {
      setLoading(false);

      errorHandler.handleError(e);
    }
  };

  /**
   * Only show this component for certain Climax sensors
   */
  if (
    sensorCtx.sensor_type !== 'climax_geo_pendant' &&
    sensorCtx.sensor_type !== 'climax_wrist_button'
  ) {
    return null;
  }

  return (
    <FormGroup>
      <FormControlLabel
        disabled={loading}
        control={
          <Checkbox
            color="primary"
            value="show_receiving_sensor"
            checked={checked}
            onChange={(e) => onCheckboxChange(e)}
          />
        }
        label={
          <span>
            {t(
              'manage.sensors.details.ShowReceivingSensorCheckbox.showReceivingSensor'
            )}{' '}
            {loading && <InlineSpinner className="ml-1" />}
          </span>
        }
      />

      <ErrorMessages errorData={errorHandler} />
    </FormGroup>
  );
};
