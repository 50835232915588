import Select, { ValueType } from 'react-select';

interface Option {
  value: string;
  label: string;
}

interface Props {
  className?: string;
  value?: string;
  onChange?: (value: ValueType<Option, false>) => void;
  disabled?: boolean;
}
const options: Option[] = [
  { value: 'has_vendors', label: 'Accounts with vendors' },
  { value: 'no_vendors', label: 'Accounts without vendors' },
];
const reactSelectStyles = {
  menu: (provided: any) => ({
    ...provided,
    zIndex: 100,
  }),
};

export const HasVendorsFilter = (props: Props) => {
  return (
    <Select
      className={props.className}
      isClearable
      styles={reactSelectStyles}
      options={options}
      placeholder="Filter by"
      value={options.find((option) => option.value === props.value)}
      onChange={(e) => {
        props.onChange?.(e);
      }}
      getOptionLabel={(option: Option) => option.label}
      getOptionValue={(option: Option) => option.value}
    />
  );
};
