import React, { useState } from 'react';
import { useZoneContext } from '../../../lib/ZoneContext';

import UpdateZoneModal from '../../../components/modals/zones/UpdateZoneModal';
import { IconButton } from '../../../components/IconButton';
import { t } from '../../../lib/i18n';
import { getZoneAncestors } from './settings/tabs/lib/getZoneAncestors';

export const UpdateZoneButton = () => {
  const [isOpen, setIsOpen] = useState(false);

  const ctx = useZoneContext();

  const update = () => ctx.reload();

  const handleModal = () => setIsOpen(!isOpen);

  const ancestors = getZoneAncestors(ctx.rootZone, ctx.activeZone);
  const atleastOneAncestorIsApiLocked = ancestors.some((zone) => zone.api_lock);

  return (
    <>
      <IconButton
        disabled={atleastOneAncestorIsApiLocked}
        icon="edit"
        color="primary"
        onClick={handleModal}
      >
        {ctx.activeZone.is_housing_unit
          ? t('manage.zone.UpdateZoneButton.renameHousingUnit')
          : t('manage.zone.UpdateZoneButton.renameZone')}
      </IconButton>

      <UpdateZoneModal
        isOpen={isOpen}
        activeZone={ctx.activeZone}
        handleModal={handleModal}
        updateTree={update}
      />
    </>
  );
};
