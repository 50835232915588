import React from 'react';
import cs from 'classnames';
import { DashboardHousingUnit } from './DashboardHousingUnit';

import { DashboardZoneSummary } from './lib/evaluateDashboardOverviewZone';

import './DashboardHousingUnitOverview.scss';

interface Props {
  className?: string;
  units: Array<DashboardZoneSummary>;
}

export function DashboardHousingUnitOverview(props: Props) {
  return (
    <div className={cs('DashboardHousingUnitOverview', props.className)}>
      {props.units.map((u) => (
        <DashboardHousingUnit key={u.key} zone={u} />
      ))}
    </div>
  );
}
