import React from 'react';
import cs from 'classnames';

import { CareCard } from './CareCard';

import { ClickEvent } from '../../../components/LongPressButton';
import { Icon } from '../../../components/Icon';
import { SensorWithActiveEvent } from '../lib/CareContext';
import { EventTypeIcon } from '../../../lib/utils/EventTypeIcon';

import './SensorCard.scss';

interface Props {
  selected?: boolean;
  sensor: SensorWithActiveEvent;
  onClick?: (e: ClickEvent) => void;
}

const conditions: Array<{
  condition: (s: SensorWithActiveEvent) => boolean;
  icon?: React.ReactNode;
  key: string;
  label: string;
}> = [
  {
    key: 'offline',
    label: 'Offline',
    condition: (s) => s.status === 'offline',
    icon: <Icon icon="disconnected" alt="Avstilt" />,
  },
  {
    key: 'blocked',
    label: 'Blokkert',
    condition: (s) => s.status === 'blocked',
    icon: <Icon icon="eye-off" alt="Blokkert" />,
  },
  {
    key: 'deactivated',
    label: 'Deaktivert',
    condition: (s) => s.status === 'deactivated',
    icon: <Icon icon="roommate-off" alt="Deaktivert" />,
  },
  {
    key: 'silenced',
    label: 'Avstilt',
    condition: (s) => s.status === 'silenced',
    icon: <Icon icon="roommate-off" alt="Avstilt" />,
  },

  // User statuses
  {
    key: 'uk',
    label: 'Unknown',
    condition: (s) => s.user_status === 'uk',
    icon: <Icon icon="person" alt="" />,
  },
  {
    key: 'uu',
    label: 'User upright',
    condition: (s) => s.user_status === 'uu',
    icon: <Icon icon="person" alt="" />,
  },
  {
    key: 'us',
    label: 'User sitting',
    condition: (s) => s.user_status === 'us',
    icon: <Icon icon="person" alt="" />,
  },
  {
    key: 'ul',
    label: 'User laying',
    condition: (s) => s.user_status === 'ul',
    icon: <Icon icon="person" alt="" />,
  },
  {
    key: 'of',
    label: 'On floor',
    condition: (s) => s.user_status === 'of',
    icon: <Icon icon="person" alt="" />,
  },
  {
    key: 'lo',
    label: 'Ukjent',
    condition: (s) => s.user_status === 'lo',
  },
  {
    key: 'ib',
    label: 'In bed',
    condition: (s) => s.user_status === 'ib',
    icon: <Icon icon="bed-sleeping" />,
  },
  {
    key: 'nu',
    label: 'No users',
    condition: (s) => s.user_status === 'nu',
  },
  {
    key: 'mu',
    label: 'Multiple users',
    condition: (s) => s.user_status === 'mu',
    icon: <Icon icon="persons" alt="" />,
  },
  {
    key: 'ub',
    label: 'Up in bed',
    condition: (s) => s.user_status === 'ub',
    icon: <Icon icon="bed-sleeping" alt="" />,
  },
  {
    key: 'ic',
    label: 'In chair',
    condition: (s) => s.user_status === 'ic',
    icon: <Icon icon="chair-sitting" alt="" />,
  },
  {
    key: 'ps',
    label: 'Presence',
    condition: (s) => s.user_status === 'ps',
    icon: <Icon icon="person" alt="" />,
  },
];

// status
// offline: disconnected.svg
// presence: presence.svg
// blokkert: Fontawesome ban
// deactivated:

export function SensorCard(props: Props) {
  // Find the first matching condition
  const status = conditions.find((con) => con.condition(props.sensor));

  const sensorIcon = props.sensor.activeEvent ? (
    <EventTypeIcon
      className={cs(
        `SensorCard-event`,
        `SensorCard-event--${props.sensor.activeEvent.level}`
      )}
      eventType={props.sensor.activeEvent.event_type}
      sensorType={props.sensor.sensor_type}
    />
  ) : status ? (
    status.icon
  ) : undefined;

  return (
    <CareCard
      sensor={props.sensor}
      selected={props.selected}
      className={cs(
        'SensorCard',
        status ? `SensorCard--${status.key}` : undefined,
        props.sensor.is_demo ? 'SensorCard--demo' : undefined,
        props.sensor.activeEvent &&
          `SensorCard-event-icon SensorCard-event-icon--${props.sensor.activeEvent.level}`
      )}
      onClick={props.onClick}
      // onLongPress={props.onLongPress}
      icon={sensorIcon}
    />
  );
}
