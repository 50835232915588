import React from 'react';
import { Icon } from '../Icon';
import { useWindowSize } from '../../lib/useWindowSize';
import { MobileHeader } from './MobileHeader';

interface Props {
  username: string;
  onTreeButtonClick: () => void;
}

export const ManageMobileHeader = (props: Props) => {
  const windowSize = useWindowSize();

  return (
    <>
      <button
        className="manage-treeview-toggler"
        onClick={props.onTreeButtonClick}
      >
        <Icon icon="zone-tree" />
      </button>

      {windowSize.isMobile && <MobileHeader username={props.username} />}
    </>
  );
};
