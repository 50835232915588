import React from 'react';
import { Switch, Route, Redirect } from 'react-router-dom';
import cs from 'classnames';
import { useCareContext } from './lib/CareContext';
import { ImageStreamer } from './ImageStreamer';
import { toast } from 'react-toastify';

import * as api from '../../serverApi';

import { EventSummary } from './EventSummary';
import { Button } from '../../components/Button';

import groupSensorsByZone from '../../lib/groupSensorsByZone';
import { InfoPage } from './components/InfoPage';

import { useWebRtcContext } from './lib/WebRtcContext';

import { Toolbar } from './components/Toolbar';
import { CareHelpPage } from './CareHelpPage';
import { CareMainPageMessage } from './components/CareMainPageMessage';
import { t } from '../../lib/i18n';
import { useFirstTimeOnly } from '../../lib/useFirstTimeOnly';
import { CenteredLogo } from './CenteredLogo';
import { useAppContext } from '../../lib/global';
import { useRouter } from '../../lib/useRouter';
import { useWindowSize } from '../../lib/useWindowSize';
import { UserStatusIcon } from '../../lib/utils/UserStatusIcon';
import { sensorSerialIsForHardwareVersion1 } from '../../lib/utils/sensorSerialIsForHardwareVersion1';

interface Props {
  className?: string;
}

interface SensorInfoBarProps {
  sensor?: {
    user_status: string | number | null;
    display_area: string | null;
    display_name: string | null;
  };
}

function SensorInfoBar(props: SensorInfoBarProps) {
  if (!props.sensor) {
    return null;
  }
  return (
    <div className="Care-main-sensor-info">
      <UserStatusIcon
        userStatus={props.sensor.user_status as string}
        className="mr-2"
      />
      <h1 className="Care-main-sensor-info-name">
        {`${props.sensor.display_area} - ${props.sensor.display_name}`}
      </h1>
    </div>
  );
}

function CareMainPage() {
  const app = useAppContext();
  const care = useCareContext();
  const rtc = useWebRtcContext();
  const windowSize = useWindowSize();
  const router = useRouter();
  const firstTime = useFirstTimeOnly(
    'care-redirect-to-help',
    true,
    app.user?.id
  );

  const handleImageStreamingTimeout = () => {
    /**
     * If audio streaming is active when image streaming has timed out,
     * we reset the stream type, but prevent the sensor from being deselected
     * so we can keep streaming audio
     */
    if (rtc?.call?.status === 'Established') {
      care.setStreamType(undefined);
      care.setContinueAudioStreaming(true);
      return;
    }

    toast.success(t('care.ImageStreamer.theInspectionPeriodHasExpired'));
    care.selectSensor(undefined);
  };

  // If first time and there's no 'start_streaming' parameter in url, we send user to help page
  if (
    firstTime.isFirstTime &&
    router.location.pathname + router.location.search === '/care'
  ) {
    return <Redirect to="/care/help" />;
  }

  async function clearSensorPresence() {
    if (care.selectedSensor && care.sensors) {
      // Find all sensors connected to the same zone as the selected sensor
      const grouped = groupSensorsByZone(care.sensors);
      const sensorGroup = grouped.find(
        (x) => x.zone_id === care.selectedSensor?.zone_id
      );
      if (sensorGroup) {
        sensorGroup.sensors.forEach(
          async (z) => await api.clearSensorPresence(z.sensor_id)
        );
      } else {
        await api.clearSensorPresence(care.selectedSensor.sensor_composite_id);
      }
      care.selectSensor(undefined);
    }
  }

  let body: React.ReactNode = null;

  if (care.sidebarMode === 'info') {
    body = (
      <>
        {windowSize.isMobile && care.selectedSensor && (
          <SensorInfoBar sensor={care.selectedSensor} />
        )}

        <InfoPage />
      </>
    );
  } else if (care.selectedSensor) {
    if (care.selectedSensor.user_status === 'ps') {
      body = (
        <CareMainPageMessage>
          <p className="mb-4">{t('care.Main.theSensorIsMuted')}</p>
          <Button onClick={clearSensorPresence}>
            {t('care.Main.cancelShutdown')}
          </Button>
        </CareMainPageMessage>
      );
    } else if (care.selectedSensor.status === 'blocked') {
      body = (
        <CareMainPageMessage>
          <p className="mb-4">
            {t(
              'care.Main.theServiceRecipientHasMadeAReservationAgainstSupervision'
            )}
            .
          </p>
        </CareMainPageMessage>
      );
    } else if (care.selectedSensor.status === 'offline') {
      body = (
        <CareMainPageMessage>
          <p className="mb-4">{t('care.Main.sensorIsOfflineTop')}</p>
          <p className="mb-4">
            {sensorSerialIsForHardwareVersion1(care.selectedSensor.sensor_id)
              ? t('care.Main.sensorIsOfflineBottomOldHw')
              : t('care.Main.sensorIsOfflineBottom')}
          </p>
        </CareMainPageMessage>
      );
    } else if (care.streamType) {
      const streamThrottle =
        app.getFeatureValueNumber('image-streaming-interval') ?? 125;
      body = (
        <>
          {windowSize.isMobile && (
            <SensorInfoBar sensor={care.selectedSensor} />
          )}

          <ImageStreamer
            className={cs(
              'Care-main-image-streamer',
              windowSize.isMobile && 'Care-main-image-streamer--with-heading'
            )}
            useMjpeg={app.hasFeature('enable-mjpeg-streams')}
            useSse={app.hasFeature('enable-sse')}
            sensorId={care.selectedSensor.sensor_composite_id}
            streamType={care.streamType}
            throttleInterval={streamThrottle}
            timeout={care.selectedSensor.observation_stream_timeout}
            onTimeout={handleImageStreamingTimeout}
            demoOverlay={care.selectedSensor.is_demo}
            audioProperty={rtc?.call?.audioProperty}
            audioCallStatus={rtc?.call?.status}
          />
        </>
      );
    } else if (
      care.continueAudioStreaming &&
      rtc?.call?.status === 'Established'
    ) {
      body = (
        <CareMainPageMessage>
          <p>{t('care.ImageStreamer.imageStoppedAudioContinued')}.</p>
        </CareMainPageMessage>
      );
    } else if (
      !care.selectedSensor.media.observation_image_anonymised &&
      !care.selectedSensor.media.observation_image_detailed
    ) {
      body = (
        <CareMainPageMessage>
          <p>{t('care.Main.noImagesAllowedOnZone')}</p>
        </CareMainPageMessage>
      );
    } else if (
      !app.hasCapability('observationDetailed') &&
      !app.hasCapability('observationAnonymised')
    ) {
      body = (
        <CareMainPageMessage>
          <p>{t('care.Main.noImagesAllowedOnUser')}</p>
        </CareMainPageMessage>
      );
    } else {
      body = (
        <CareMainPageMessage>
          <p>{t('care.Main.selectImageType')}</p>
        </CareMainPageMessage>
      );
    }
  } else if (care.selectedEvent) {
    body = <EventSummary event={care.selectedEvent} />;
  } else {
    body = (
      <CenteredLogo
        title={t(
          'care.common.selectHousingUnitSensorInTheLeftFieldForSupervision'
        )}
      />
    );
  }
  return <>{body}</>;
}

export default function Main(props: Props) {
  const care = useCareContext();
  const router = useRouter();
  const windowSize = useWindowSize();

  return (
    <main
      className={cs(
        props.className,
        care.showMainContentMobile && 'Care-main--show'
      )}
    >
      <Toolbar
        hideActions={
          router.location.pathname === '/care/help' && windowSize.isMobile
        }
      />
      <div id="careMainWrapper" className={cs('Care-main-wrapper')}>
        <Switch>
          <Route exact path="/care" component={CareMainPage} />
          <Route exact path="/care/help" component={CareHelpPage} />
        </Switch>
      </div>
    </main>
  );
}
