import { useState } from 'react';
import { Button } from '@material-ui/core';
import cs from 'classnames';
import { InlineSpinner } from './InlineSpinner';
import { toast } from 'react-toastify';
import { t } from '../lib/i18n';
import { useConfirmation } from '../lib/confirmation/ConfirmationContext';

interface Props<R, A extends any[]> {
  className?: string;
  label?: string;
  successMessage?: string;
  errorMessage?: string;
  action?: (...args: A) => Promise<R>;
  parameters?: A;
  onSuccess?: (result: R) => void;
  onError?: (error: any) => void;

  /**
   * Whether to request confirmation before proceeding with action.
   */
  requestConfirmation?: boolean;
}

export function ActionButton<R, A extends any[]>(props: Props<R, A>) {
  const confirmation = useConfirmation();
  const [loading, setLoading] = useState(false);

  const onClick = async () => {
    if (!props.action) {
      return;
    }

    if (props.requestConfirmation) {
      try {
        await confirmation.confirm();
      } catch {
        return;
      }
    }

    setLoading(true);
    try {
      const res = await props.action.apply(
        {},
        props.parameters === undefined
          ? (([] as unknown) as any)
          : props.parameters
      );
      toast.success(
        props.successMessage ?? t('components.ActionButton.successMessage')
      );
      props.onSuccess?.(res);
    } catch (err) {
      toast.error(
        props.errorMessage ?? t('components.ActionButton.errorMessage')
      );
      props.onError?.(err);
    }
    setLoading(false);
  };

  return (
    <Button
      disabled={loading}
      variant="contained"
      color="primary"
      className={cs('ActionButton', props.className)}
      onClick={onClick}
    >
      {props.label ?? t('components.ActionButton.defaultLabel')}
      {loading && <InlineSpinner />}
    </Button>
  );
}
