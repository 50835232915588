import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircle } from '@fortawesome/pro-solid-svg-icons';
import { ReactComponent as HomeHeartIcon } from '../images/home.svg';
import LockIcon from '@material-ui/icons/Lock';
import { Icon } from './Icon';
import cs from 'classnames';
import { StructureZone } from '../serverApi';
import {
  getLocalStorageExpandedNodes,
  setLocalStorageExpandedNodes,
} from '../lib/expandedTreeNodes';
import { GreenCircleIndicator } from '../areas/manage/common/ManageSidebar';
import { useRouter } from '../lib/useRouter';
import { useAccount } from '../lib/useAccount';
import { useAppContext } from '../lib/global';
import { hasZoneSettingsChanged } from '../lib/utils/hasZoneSettingsChanged';

import './TreeView.scss';

type NodeMap = {
  [key: string]: boolean;
};

interface BranchProps {
  zone: StructureZone;
  selectZone: (zone: StructureZone) => void;
  activeZone?: StructureZone;
  expandedNodes: NodeMap;
  toggleNode: (node: StructureZone) => void;
  showLinkToAccountSettings?: boolean;
  showSettingsChangedIndicator?: boolean;
  /**
   * When provided, the branch with the specified ID will be disabled for selection. This includes all subbranches.
   */
  disableBranchId?: number;

  disabled?: boolean;

  disabledZoneIds?: number[];
  /**
   * Used for referencing the root zone branch in e2e testing
   */
  rootZoneTestId?: string;
}

function Branch(props: BranchProps) {
  const app = useAppContext();
  const router = useRouter();
  function onClick() {
    props.selectZone(props.zone);
  }

  let icon: 'plus' | 'minus' | 'none' = 'none';

  const isApiLocked = props.zone.api_lock;

  const expanded = props.expandedNodes[props.zone.id];

  if (props.zone.zones && props.zone.zones.length > 0) {
    icon = expanded ? 'minus' : 'plus';
  }
  const disableEntireNode =
    props.disabled || props.disableBranchId === props.zone.id;

  const disableSingleNode =
    props.disabledZoneIds && props.disabledZoneIds.includes(props.zone.id);

  const isDisabled = disableEntireNode || disableSingleNode;

  const zoneIcon = props.zone.is_housing_unit ? (
    <HomeHeartIcon className="TreeView-branch-icon TreeView-branch-icon--housing-unit" />
  ) : (
    <FontAwesomeIcon
      className="TreeView-branch-icon TreeView-branch-icon--zone"
      icon={faCircle}
    />
  );

  const isTopZone =
    props.zone &&
    (props.zone.parent === null ||
      app.user?.active_role?.zone?.zone_id === props.zone.id);

  const hasDifferingZoneSettings =
    (props.showSettingsChangedIndicator &&
      hasZoneSettingsChanged(props.zone.zone_settings)) ||
    (props.showSettingsChangedIndicator &&
      props.zone.has_defined_ip_whitelists);

  const isOnGlobalSettings = router.location.pathname.includes(
    'manage/account'
  );

  return (
    <li
      data-zone-id={props.zone.id}
      className={cs(
        'TreeView-branch',
        (props.activeZone && props.activeZone.id === props.zone.id) ||
          (isTopZone && isOnGlobalSettings)
          ? 'TreeView-branch--active'
          : undefined,
        isDisabled ? 'TreeView-branch--disabled' : undefined
      )}
    >
      <div
        className={cs(
          'TreeView-branch-wrapper',
          isTopZone && 'TreeView-branch-top-zone'
        )}
        data-cy={props.rootZoneTestId}
      >
        <ins
          className={`TreeView-icon TreeView-icon--${icon}`}
          onClick={() => props.toggleNode(props.zone)}
        >
          &nbsp;
        </ins>
        <span
          role="button"
          onClick={isDisabled ? undefined : onClick}
          style={{
            cursor: isDisabled ? 'not-allowed' : 'pointer',
            fontWeight:
              props.zone.id === (props.activeZone && props.activeZone.id)
                ? 'bold'
                : 'normal',
          }}
          className="TreeView-zone-name"
        >
          {zoneIcon}
          {props.zone.name}

          {isApiLocked && <LockIcon className="TreeView-branch-lock-icon" />}

          {/*

          // Bug in careReceiver; hiding this until correct data is shown

          {props.zone.careReceiver ? (
            <div style={{ paddingLeft: '1em' }}>
              {props.zone.careReceiver.name}
            </div>
          ) : null}

          {props.zone.sensors && props.zone.sensors.length > 0 ? (
            <div style={{ paddingLeft: '1em' }}>
              {props.zone.sensors.length} sensor
              {props.zone.sensors.length === 1 ? '' : 's'}
            </div>
          ) : null}
          */}
          {hasDifferingZoneSettings && (
            <GreenCircleIndicator className="TreeView-settings-indicator" />
          )}
        </span>
        {props.showLinkToAccountSettings && (
          <Link to="/manage/account">
            <Icon
              icon="gear"
              className={cs(
                'TreeView-branch-account-link',
                isOnGlobalSettings && 'd-block '
              )}
            />
          </Link>
        )}
      </div>
      {(isTopZone || expanded) &&
      props.zone.zones &&
      props.zone.zones.length > 0 ? (
        <ul>
          {props.zone.zones.map((ch, index) => (
            <Branch
              showSettingsChangedIndicator={props.showSettingsChangedIndicator}
              key={ch.id}
              zone={ch}
              activeZone={props.activeZone}
              selectZone={props.selectZone}
              expandedNodes={props.expandedNodes}
              toggleNode={props.toggleNode}
              disabled={disableEntireNode}
              disableBranchId={props.disableBranchId}
              disabledZoneIds={props.disabledZoneIds}
            />
          ))}
        </ul>
      ) : null}
    </li>
  );
}

interface Props {
  root: StructureZone;
  selectZone: (zone: StructureZone) => void;
  activeZone?: StructureZone;
  className?: string;
  showLinkToAccountSettings?: boolean;
  showSettingsChangedIndicator?: boolean;

  /**
   * When provided, the branch with the specified ID will be disabled for selection. This includes all subbranches.
   */
  disableBranchId?: number;

  disabledZoneIds?: number[];
}

export function TreeView(props: Props) {
  const account = useAccount();
  const [expandedNodes, setExpandedNodes] = useState<NodeMap>(
    getLocalStorageExpandedNodes(account.account_code) ?? {}
  );

  useEffect(() => {
    if (!props.root || expandedNodes[props.root.id]) {
      return;
    }

    const nodes: NodeMap = {
      ...expandedNodes,
      [props.root.id]: true,
    };
    setExpandedNodes(nodes);
    setLocalStorageExpandedNodes(account.account_code, nodes);
  }, [props.root]); // eslint-disable-line react-hooks/exhaustive-deps

  function toggleNode(zone: StructureZone) {
    const nodes = {
      ...expandedNodes,
      [zone.id]: !expandedNodes[zone.id],
    };
    setExpandedNodes(nodes);
    setLocalStorageExpandedNodes(account.account_code, nodes);
  }
  return (
    <ul className={cs('TreeView', props.className)}>
      <Branch
        showLinkToAccountSettings={props.showLinkToAccountSettings}
        showSettingsChangedIndicator={props.showSettingsChangedIndicator}
        zone={props.root}
        selectZone={props.selectZone}
        activeZone={props.activeZone}
        expandedNodes={expandedNodes}
        toggleNode={toggleNode}
        disableBranchId={props.disableBranchId}
        disabledZoneIds={props.disabledZoneIds}
        rootZoneTestId="root-zone"
      />
    </ul>
  );
}
