import { fallbackLocale, isLocale } from './i18n/i18n';
import { LANG_LOCAL_STORAGE_KEY } from './i18n/I18nProvider';

/**
 * Retreive the prefered locale from the system browser language.
 */
export const getBrowserPreferredLanguage = () => {
  /**
   * When we change the language, we save the locale as favorite
   * on localStorage with key being LANG_LOCAL_STORAGE_KEY
   */
  const lngFromLocalStorage = localStorage.getItem(LANG_LOCAL_STORAGE_KEY);
  if (lngFromLocalStorage) {
    /**
     * If a favorite language if found, we prioritize that.
     */
    return lngFromLocalStorage;
  }

  /**
   * If no favorite language is set on localStorage, check for the browser language.
   */
  if (navigator.language) {
    let browserLang = navigator.language;
    /**
     * Browser language can also include regions (e.g en-GB).
     * If that's the case, we only take the language code part
     * (See https://developer.mozilla.org/en-US/docs/Web/API/Navigator/language)
     */
    if (browserLang.includes('-')) {
      browserLang = browserLang.split('-')[0];
    }

    /**
     * Treat Norwegian language groups as valid Norwegian locale
     * ('no' and 'nn' locales are part of Norwegian language)
     */
    if (browserLang === 'nn' || browserLang === 'no') {
      return 'nb';
    }

    /**
     * We return the browser preferred locale only if it exists in our app.
     */
    if (isLocale(browserLang)) {
      return browserLang;
    }
  }
  /**
   * In case it fails to find the browser language (or it's not valid for our app),
   * we return the fallback language (which is Norwegian)
   */
  return fallbackLocale;
};
