import React from 'react';
import cs from 'classnames';

interface Props {
  children: React.ReactNode;
  className?: string;
}

export const CenterPanel = (props: Props) => (
  <div
    style={{
      maxWidth: '700px',
      padding: '40px',
      position: 'absolute',
      top: '50%',
      left: '50%',
      transform: 'translate(-50%, -50%)',
    }}
    className={cs(props.className)}
  >
    {props.children}
  </div>
);
