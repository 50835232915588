import React from 'react';
import { RouteComponentProps, Switch, Route } from 'react-router';
import { ListUsers } from './ListUsers';
import { EditUser } from './EditUser';
import { CreateUser } from './CreateUser';
import { UserDetails } from './UserDetails';

export function Users(props: RouteComponentProps<{}>) {
  return (
    <Switch>
      <Route exact={true} path={props.match.path} component={ListUsers} />
      <Route
        exact={true}
        path={`${props.match.path}/create`}
        component={CreateUser}
      />
      <Route
        exact={true}
        path={`${props.match.path}/:userId/edit`}
        component={EditUser}
      />
      <Route
        exact={true}
        path={`${props.match.path}/:userId`}
        component={UserDetails}
      />
    </Switch>
  );
}
