import React from 'react';

import './CareMainPageMessage.scss';

interface Props {
  children: React.ReactNode;
}
export const CareMainPageMessage = (props: Props) => {
  return (
    <div className="CareMainPageMessage">
      <div className="CareMainPageMessage-content">{props.children}</div>
    </div>
  );
};
