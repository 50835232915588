import React from 'react';
import { LabeledValue } from '../../../../components/LabeledValue';
import { t } from '../../../../lib/i18n';

// import { formatInterval } from '../../../../lib/utils/formatInterval';

import { ScheduleSummaryCard } from './ScheduleSummaryCard';

import './AlertSettingDetailsCard.scss';
import formatUserLabel from '../../permissions/lib/formatUserLabel';
import formatMinutePeriod from '../../../../lib/utils/formatMinutePeriod';
import { StatusMarker } from '../../../../lib/ui/StatusMarker';
import { AlertSettingForSingleSensorType } from '../lib/types';
import { formatEventTypesLabel } from '../lib/formatEventTypesLabel';

interface Props {
  alertSetting?: AlertSettingForSingleSensorType;
}

export function AlertSettingDetailsCard(props: Props) {
  const als = props.alertSetting;

  if (!als) {
    return null;
  }

  return (
    <div className="AlertSettingDetailsCard">
      <div className="AlertSettingDetailsCard-left">
        <LabeledValue
          label={t('manage.alerts.ui.AlertSettingDetailsCard.alertGroupName')}
        >
          {als.name}
        </LabeledValue>

        <LabeledValue label="Status:">
          <StatusMarker
            status={als.enabled ? 'success' : 'danger'}
            label={
              als.enabled
                ? t('common.commonTexts.enabled')
                : t('common.commonTexts.notActivated')
            }
          />
        </LabeledValue>

        <LabeledValue
          label={t('manage.alerts.ui.AlertSettingDetailsCard.notificationType')}
        >
          {formatEventTypesLabel(als)}
        </LabeledValue>

        <LabeledValue label={t('manage.alerts.common.notificationRecipients')}>
          {als.recipients.map((rec) => (
            <div key={rec.id}>{formatUserLabel(rec)}</div>
          ))}
        </LabeledValue>

        {als?.escalation_enabled ? (
          <>
            <LabeledValue
              label={t(
                'manage.alerts.ui.AlertSettingDetailsCard.escalationReceivers'
              )}
            >
              {als?.escalation_recipients &&
              als.escalation_recipients.length > 0
                ? als.escalation_recipients.map((rec) => (
                    <div key={rec.id}>{formatUserLabel(rec)}</div>
                  ))
                : t(
                    'manage.alerts.ui.AlertSettingDetailsCard.noEscalationReceivers'
                  )}
            </LabeledValue>

            {als?.escalation_delay !== null && (
              <>
                <LabeledValue
                  label={t(
                    'manage.alerts.ui.AlertSettingDetailsCard.escalationDelay'
                  )}
                >
                  {formatMinutePeriod(als?.escalation_delay || 0)}
                </LabeledValue>
              </>
            )}
          </>
        ) : (
          <>
            <LabeledValue
              label={t('manage.alerts.ui.AlertSettingDetailsCard.escalation')}
            >
              {t('manage.alerts.ui.AlertSettingDetailsCard.noEscalation')}
            </LabeledValue>
          </>
        )}
      </div>

      <div className="AlertSettingDetailsCard-right">
        <ScheduleSummaryCard scheduleRules={als.schedule} />
      </div>
    </div>
  );
}
