import React, { useState } from 'react';
import { RouteComponentProps } from 'react-router-dom';

import matchSorter from 'match-sorter';

import * as api from '../../../serverApi';
import { useZoneContext } from '../../../lib/ZoneContext';
import { IconButton } from '../../../components/IconButton';
import FilterInput from '../../../components/FilterInput/FilterInput';
import { useRouter } from '../../../lib/useRouter';
import { SensorsTable } from './ui/SensorsTable';
import { t } from '../../../lib/i18n';
import { useGetSensorsForZoneQuery } from '../../../serverApi';
import {
  ManageHelpDropdown,
  ManageHelpOption,
} from '../common/ManageHelpDropdown';

export function ListSensors(props: RouteComponentProps<{}>) {
  const router = useRouter();
  const ctx = useZoneContext();

  const [search, setSearch] = useState('');

  const query = useGetSensorsForZoneQuery(ctx.activeZone.id);

  const fetchedSensors = query?.data?.sensors ?? [];

  const onExport = () => {
    const a = document.createElement('a');
    a.href = `/api/sensors/${ctx.activeZone.id}/sensors.xlsx`;
    a.download = `sensors-${ctx.activeZone.id}.xlsx`;
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
  };

  const filterSensors = (
    sensors: api.SensorDetails[],
    keyword?: string
  ): api.SensorDetails[] => {
    if (!keyword) {
      return sensors;
    }

    return matchSorter(sensors, keyword, {
      keys: [
        'id',
        'name',
        'zone.name',
        'zone.parent.name',
        'care_receiver.first_name',
        'care_receiver.last_name',
      ],
    });
  };

  const sensors = search
    ? filterSensors(fetchedSensors, search)
    : fetchedSensors;

  const helpDropdownOptions: ManageHelpOption[] = [
    {
      title: t('common.video.manage.addRoomMateSensor.title'),
      vimeoId: t('common.video.manage.addRoomMateSensor.vimeo'),
      type: 'video',
    },
    {
      title: t('common.video.manage.addOtherSensor.title'),
      vimeoId: t('common.video.manage.addOtherSensor.vimeo'),
      type: 'video',
    },
    {
      title: t('common.video.manage.enableAlerts.title'),
      vimeoId: t('common.video.manage.enableAlerts.vimeo'),
      type: 'video',
    },
    {
      title: t('common.video.manage.marking.title'),
      vimeoId: t('common.video.manage.marking.vimeo'),
      type: 'video',
    },
    {
      title: t('common.pdf.manage.roomLayoutGuide.title'),
      link: t('common.url.markingUserGuidePdf'),
      type: 'pdf',
    },
    {
      title: t('common.pdf.manage.systemAdministrator.title'),
      link: t('common.url.adminUserGuidePdf'),
      type: 'pdf',
    },
  ];

  return (
    <>
      <div className="Manage__heading-wrapper">
        <h1 className="m-0">
          {t('manage.sensors.ListSensors.sensorsNotificationOverview')}:{' '}
          {ctx.activeZone.name}
        </h1>
        <ManageHelpDropdown options={helpDropdownOptions} />
      </div>

      <div className="d-flex align-items-start justify-content-between flex-column flex-sm-row my-4">
        <div className="d-flex flex-column align-items-start">
          <IconButton
            icon="plus"
            color="primary"
            to={`${router.match.url}/new`}
          >
            {t('manage.sensors.common.addRoommateSensor')}
          </IconButton>
          <IconButton
            icon="plus"
            color="primary"
            to={`${router.match.url}/other/new`}
          >
            {t('manage.sensors.common.addOtherSensor')}
          </IconButton>
        </div>

        <div className="d-flex flex-column-reverse flex-sm-column">
          <FilterInput
            onChange={(name, value) => setSearch(value)}
            className="mb-2"
          />
          <div>
            <IconButton
              color="primary"
              icon="export"
              onClick={onExport}
              disabled={fetchedSensors.length === 0}
            >
              {t('common.commonTexts.exportToExcel')}
            </IconButton>
          </div>
        </div>
      </div>

      <SensorsTable
        className="mt-4"
        loading={query.isLoading}
        sensors={sensors}
      />
    </>
  );
}
