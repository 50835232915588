import React from 'react';
import { IpWhitelistEditor } from './IpWhitelistEditor';
import { t } from '../../../../../lib/i18n';

export function IpWhitelist() {
  return (
    <div style={{ overflowX: 'auto' }}>
      <h2>{t('manage.zone.settings.advanced.IpWhitelist.approvedIpRanges')}</h2>

      <IpWhitelistEditor />
    </div>
  );
}
