import React, { useState } from 'react';
import cs from 'classnames';
import matchSorter from 'match-sorter';

import { TreeView } from '../../../components/TreeView';

import { useOptionalZoneContext } from '../../../lib/ZoneContext';
import { useRouter } from '../../../lib/useRouter';
import * as api from '../../../serverApi';
import FilterInput from '../../../components/FilterInput/FilterInput';
import { useManageContext } from '../ManageContext';
import { t } from '../../../lib/i18n';
import { sortZones } from '../../../lib/sortZones';
import { getAllZoneKeys } from './getAllZoneKeys';

import './ManageSidebar.scss';

interface Props {
  className?: string;
}

export function ManageSidebar(props: Props) {
  const manageContext = useManageContext();
  const zone = useOptionalZoneContext();
  const router = useRouter();
  const [keyword, setKeyword] = useState('');

  const isInZoneSettingsArea = router.location.pathname.includes(
    '/zone/settings'
  );

  function filterZones(zone: api.StructureZone): api.StructureZone[] {
    if (!zone.zones || zone.zones.length === 0) {
      return [] as api.StructureZone[];
    }
    return matchSorter<api.StructureZone>(zone.zones, keyword.trim(), {
      keys: getAllZoneKeys(zone.zones),
    });
  }

  const sortedZones = (
    originalZones: api.StructureZone[] | null
  ): api.StructureZone[] => {
    if (!originalZones) {
      return [] as api.StructureZone[];
    }

    const zones = [...originalZones];

    sortZones(zones);

    return zones.map((zone: api.StructureZone) => {
      if (!zone.zones || zone.zones.length === 0) {
        return zone;
      }

      return {
        ...zone,
        zones: sortedZones(zone.zones),
      };
    });
  };

  const handleSelectZone = (zone: api.StructureZone) => {
    manageContext.toggleSidebar();
    const path = router.location.pathname;

    // Check if user has previously selected a zone
    const hasSelectedZone = /[/]manage[/][0-9]+[/]/.test(path);

    // If user has previously selected a zone;
    // keep the url path after zone selection
    // only change to the url will be the zone id
    const redirect = hasSelectedZone
      ? `/${router.location.pathname.replace(/^.+?[/].+?[/]/, '')}`
      : '';
    router.history.push(`/manage/${zone.id}${redirect}`);
  };

  return (
    <div
      className={cs(
        'ManageSidebar',
        manageContext.isSidebarOpen && 'ManageSidebar--opened',
        props.className
      )}
    >
      <div className="ManageSidebar-head">
        <div
          role="button"
          className="ManageSidebar-mobile-close"
          onClick={() => manageContext.toggleSidebar()}
        >
          {t('common.commonButtons.cancel')}
        </div>

        <h2 style={{ fontSize: '24px', color: '#666' }}>
          {t('manage.common.ManageSidebar.zones')}
        </h2>

        <FilterInput onChange={(name, value) => setKeyword(value)} />
      </div>

      <div className="ManageSidebar-body">
        {zone.rootZone ? (
          <TreeView
            showLinkToAccountSettings={true}
            showSettingsChangedIndicator={isInZoneSettingsArea}
            className="ManageSidebar-tree-view"
            root={{
              ...zone.rootZone,
              zones: sortedZones(filterZones(zone.rootZone)),
            }}
            selectZone={(zone) => handleSelectZone(zone)}
            activeZone={zone.activeZone}
          />
        ) : null}
      </div>
    </div>
  );
}
interface GreenCircleIndicatorProps {
  className?: string;
  style?: React.CSSProperties;
}
export const GreenCircleIndicator = (props: GreenCircleIndicatorProps) => {
  return (
    <div
      className={cs('ManageSidebar-green-indicator', props.className)}
      style={props.style}
    />
  );
};
