import * as api from '../../../../../serverApi';

export type GroupedChanges = {
  /**
   * A composite key set from auditable_id and auditable_type. Used for grouping and `key` props.
   */
  key: string;

  /**
   * The ID of the subject of the group, i.e. the ID of the topmost "parent" model.
   */
  auditable_id: string;

  /**
   * The type of group.
   */
  auditable_type: string;

  /**
   * The presentation name for the group, if provided.
   */
  auditable_name: null | string;

  /**
   * The "main" change, the overall object that was changed. This can be null,
   * when we have grouped individual entries but there is no entry for the group
   * itself (for example when we have added an AlertSettingEventType, but the
   * AlertSetting itself was not changed and no audit record was added for it).
   */
  group: null | api.AuditChangeLogEntryChange;

  /**
   * The grouped changes, _except_ the change in `group` (if any).
   */
  changes: api.AuditChangeLogEntryChange[];
};

export default function groupAuditables(
  entries?: api.AuditChangeLogEntryChange[]
): GroupedChanges[] {
  // Fallback, return empty array if no entries were provided.
  if (!entries?.length) {
    return [];
  }

  // The initial object used by the reducer below
  const initialGroups: { [groupKey: string]: GroupedChanges } = {};

  // Create groups by their group key
  const groupsByKey = entries.reduce((carry, item) => {
    // For each row, get a group key
    const [groupId, groupType] =
      item.auditable_group && item.auditable_group_id
        ? [item.auditable_group_id, item.auditable_group]
        : [item.auditable_id, item.auditable_type];

    const groupKey = `${groupType}:${groupId}`;

    /**
     * This is the first entry from this group. We set some overall group
     * values that are common to all entries in this group.
     */
    if (!carry[groupKey]) {
      carry[groupKey] = {
        key: groupKey,
        auditable_id: groupId,
        auditable_type: groupType,
        auditable_name: null,
        group: null,
        changes: [],
      };
    }

    /**
     * Check whether the current entry is the "parent model", i.e. on a change collection
     * regarding an AlertSetting, whether _this_ entry is the AlertSetting.
     */
    if (
      `${item.auditable_type}` === `${groupType}` &&
      `${item.auditable_id}` === `${groupId}` &&
      !carry[groupKey].group
    ) {
      carry[groupKey].auditable_name = item.auditable_name ?? null;
      carry[groupKey].group = item;
    } else {
      // We expect all the auditable_group_names to be identical, so we pick the first truthy one:
      if (!carry[groupKey].auditable_name) {
        carry[groupKey].auditable_name = item.auditable_group_name ?? null;
      }
    }
    carry[groupKey].changes.push(item);

    return carry;
  }, initialGroups);

  return Object.values(groupsByKey);
}
