import React from 'react';

import { ButtonGroup } from '../../../components/ButtonGroup';
import { Button } from '../../../components/Button';

import { formatRoleValidityDates } from './formatRoleValidityDates';

import { UserRole } from './UserRoleCard';

import { t, TranslationKey } from '../../../lib/i18n';

import './UserRoleDetailCard.scss';

interface Props {
  userRole: UserRole;
  error?: string;
  deleting?: boolean;
  onEdit: () => void;
  onDeactivate?: () => void;
  onDelete: () => void;
}

export function UserRoleDetailCard(props: Props) {
  const ur = props.userRole;
  return (
    <div
      className="UserRoleDetailCard mt-4 pt-4"
      style={{ borderTop: '1px solid #999' }}
      key={ur.user_role_id}
    >
      <div className="UserRoleDetailCard-left">
        <div className="UserDetails-content">
          <h3>{t('common.commonTexts.role')}:</h3>
          <div className="UserDetails-content-value">
            {t(`common.roles.${ur.role}` as TranslationKey)}
          </div>
        </div>

        <div className="UserDetails-content">
          <h3>{t('common.commonTexts.zoneUnit')}:</h3>
          <div className="UserDetails-content-value">{ur.zone_name}</div>
        </div>

        <div className="UserDetails-content">
          <h3>{t('common.commonTexts.duration')}:</h3>
          <div className="UserDetails-content-value">
            {formatRoleValidityDates(ur) ||
              t('manage.permissions.UserRoleDetailCard.noLimit')}
          </div>
        </div>
      </div>
      <div className="UserRoleDetailCard-right">
        <ButtonGroup direction="vertical">
          {props.onDeactivate && (
            <Button onClick={props.onDeactivate}>
              {t('common.commonTexts.disable')}
            </Button>
          )}
          <Button
            icon={props.deleting ? 'gear' : undefined}
            spinIcon={props.deleting}
            disabled={props.deleting}
            color="secondary"
            onClick={props.onDelete}
          >
            {t('common.commonButtons.delete')}
          </Button>

          <Button color="primary" onClick={props.onEdit}>
            {t('common.commonButtons.edit')}
          </Button>
        </ButtonGroup>
        {props.error && <div className=" mt-3 text-danger">{props.error}</div>}
      </div>
    </div>
  );
}
