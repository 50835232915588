import React, { ErrorInfo, ReactNode } from 'react';
import { ButtonGroup } from 'reactstrap';
import * as Sentry from '@sentry/browser';
import { t } from '../lib/i18n';

import './ErrorBoundary.scss';

interface Props {
  children?: ReactNode;
}

interface State {
  hasError: boolean;
  errorId: null | string;
}
export class ErrorBoundary extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      hasError: false,
      errorId: null,
    };
  }

  componentDidCatch(error: Error, errorInfo: ErrorInfo) {
    const errorId = Math.random().toString(36).substr(2, 9);
    console.error(error);
    Sentry.withScope((scope) => {
      scope.setTag('error_id', errorId);
      Sentry.captureException(error);
    });

    this.setState({
      hasError: true,
      errorId,
    });
  }

  render() {
    if (this.state.hasError) {
      return (
        <div className="ErrorBoundary" style={{ textAlign: 'center' }}>
          <div className="title">
            {t('common.commonTexts.somethingWentWrong')}
            <p>
              ID <code>{this.state.errorId}</code>
            </p>
          </div>

          <ButtonGroup>
            <button
              className="btn btn-outline-success btn-action"
              onClick={() => window.location.reload()}
            >
              {t('common.commonButtons.reload')}
            </button>
            <button
              className="btn btn-outline-danger btn-action "
              onClick={() => this.setState({ hasError: false, errorId: null })}
            >
              {t('common.commonButtons.cancel')}
            </button>
          </ButtonGroup>
        </div>
      );
    }

    return this.props.children;
  }
}

export default ErrorBoundary;
