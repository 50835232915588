import { useRef, useState } from 'react';
import cs from 'classnames';

import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import { VideoModal } from '../../../components/modals/VideoModal';
import { IconButton } from '../../../components/IconButton';

import { useOutsideClickDetection } from '../../../lib/useOutsideClickDetection';
import { t } from '../../../lib/i18n';

import './ManageHelpDropdown.scss';

export interface ManageHelpOption {
  title: string;
  vimeoId?: string;
  type: 'video' | 'pdf';
  link?: string;
}

interface Props {
  options?: ManageHelpOption[];
}

export const ManageHelpDropdown = (props: Props) => {
  const wrapperRef = useRef<HTMLDivElement>(null);
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const [selectedOption, setSelectedOption] = useState<ManageHelpOption>();

  /**
   * Close dropdown on outside click
   */
  useOutsideClickDetection(wrapperRef, () => {
    /*
     * Ignore outside click if there's a modal open, so the user
     * can continue selecting another helper video from the dropdown
     */
    if (!!selectedOption) {
      return;
    }
    dropdownOpen && setDropdownOpen(false);
  });

  const toggleDropdown = () => {
    setDropdownOpen((prev) => !prev);
  };

  const handleOptionClick = (option: ManageHelpOption) => () => {
    /**
     * We don't save the selected option into state if its pdf type,
     * because we open it in a new tab
     */
    if (option.type === 'pdf') {
      return;
    }

    setSelectedOption(option);
  };

  const closeVideoModal = () => {
    setSelectedOption(undefined);
  };

  return (
    <div className="ManageHelpDropdown" ref={wrapperRef}>
      <button className="ManageHelpDropdown__button" onClick={toggleDropdown}>
        <span>{t('manage.common.ManageHelpPage.doYouNeedHelp')}</span>
        <KeyboardArrowDownIcon
          className={cs(
            'ManageHelpDropdown__chevron',
            dropdownOpen && 'ManageHelpDropdown__chevron--up'
          )}
        />
      </button>

      {dropdownOpen && (
        <ul className="ManageHelpDropdown__menu">
          {props.options?.map((option) => {
            /**
             * Temporary fix so that we don't display an item for some videos that don't exist on all languages
             */
            if (!option.title) {
              return null;
            }

            const cleanTitle = option.title.replace('&shy;', '');
            const isVideo = option.type === 'video';

            return (
              <li
                onClick={handleOptionClick(option)}
                key={option.vimeoId || option.link}
              >
                <IconButton
                  className="ManageHelpDropdown__icon"
                  icon={isVideo ? 'play' : 'book'}
                  href={option.link}
                  target={!isVideo ? '_blank' : undefined}
                >
                  {cleanTitle}
                </IconButton>
              </li>
            );
          })}
        </ul>
      )}

      {selectedOption && (
        <VideoModal
          isOpen={selectedOption.type === 'video'}
          title={selectedOption.title.replace('&shy;', '')}
          src={`https://player.vimeo.com/video/${selectedOption.vimeoId}`}
          onToggle={closeVideoModal}
        />
      )}
    </div>
  );
};
