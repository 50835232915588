import { AlertSettingScheduleRule } from '../../serverApi';
import { t, TranslationKey } from '../i18n';

const skipMinutesWhen00 = (s: string) =>
  s.length === 8 && s.substr(5, 3) === ':00' ? s.substr(0, 5) : s;

export function formatAlertSettingTime(from: string, to: string) {
  if (from === '00:00' && to === '00:00') {
    return t('common.commonTexts.allDay');
  }

  return `${from}–${to}`;
}

export function formatAlertSettingSchedule(
  schedule: AlertSettingScheduleRule[]
) {
  if (!schedule?.length) {
    return t('lib.utils.formatAlertSettingSchedule.noPeriodDefined');
  }

  if (schedule.length > 1) {
    return t('lib.utils.formatAlertSettingSchedule.variablePeriod');
  }

  const sr = schedule[0];

  const fr = sr.from_time ? skipMinutesWhen00(sr.from_time) : '';
  const to = sr.to_time ? skipMinutesWhen00(sr.to_time) : '';

  if (sr.to_weekday.length === 7 && sr.from_weekday.length === 7) {
    return `${t('common.commonTexts.allDays')}, ${formatAlertSettingTime(
      fr,
      to
    )}`;
  }

  if (sr.to_weekday.length !== 1 || sr.to_weekday.length !== 1) {
    return t('lib.utils.formatAlertSettingSchedule.defaultSetup');
  }

  if (sr.from_weekday[0] === sr.to_weekday[0]) {
    return `${t(
      `lib.longWeekDay.${sr.from_weekday[0]}` as TranslationKey
    )}, ${formatAlertSettingTime(fr, to)}`;
  }

  return `${t(
    `lib.shortWeekDay.${sr.from_weekday[0]}` as TranslationKey
  )} ${fr}—${t(
    `lib.shortWeekDay.${sr.to_weekday[0]}` as TranslationKey
  )} ${to}`;

  // const values: DaySchedule[] = Object.values(schedule);

  // // If any of the days differ
  // for (let i = 1; i < 7; i++) {
  //   if (
  //     values[0]?.time_from !== values[i]?.time_from ||
  //     values[0]?.time_to !== values[i]?.time_to
  //   ) {
  //     return 'Variabel periode';
  //   }
  // }

  // const from = values[0]?.time_from;
  // const to = values[0]?.time_to;

  // if ((!from && to) || (from && !to)) {
  //   throw new Error(
  //     'Invalid period -- time_from,time_to must be defined together'
  //   );
  // }

  // if (!from && !to) {
  //   return 'Ingen dager';
  // }

  // if (
  //   (from === '00:00' || from === '00:00:00') &&
  //   (to === '00:00' || to === '24:00' || to === '00:00:00' || to === '24:00:00')
  // ) {
  //   return 'Alle dager, hele døgnet';
  // }

  // const f =
  //   from?.length === 8 && from?.substr(6, 2) === '00'
  //     ? from.substr(0, 5)
  //     : from;

  // const t =
  //   to?.length === 8 && to?.substr(6, 2) === '00' ? to.substr(0, 5) : to;

  // return `Alle dager, ${f}-${t}`;
}
