import React from 'react';
import qs from 'query-string';
import { Col, Row } from 'reactstrap';

import { LayoutWithBackground } from '../public/LayoutWithBackground';
import { PublicHeader } from '../public/PublicHeader';
import { PublicFooter } from '../public/PublicFooter';
import { ChangePasswordForm } from './ChangePasswordForm';
import { CenteredCard } from '../public/CenteredCard';
import { useAppContext } from '../../lib/global';
import { Button } from '../../components/Button';
import { useRouter } from '../../lib/useRouter';
import { useErrorHandler } from '../../lib/useErrorHandler';
import { useAppContainerOverflow } from '../../lib/useAppContainerOverflow';
import { ErrorMessages } from '../../components/ErrorMessages';

import * as api from '../../serverApi';
import { t } from '../../lib/i18n';

export function ChangePassword() {
  useAppContainerOverflow();

  const app = useAppContext();
  const router = useRouter();
  const errorHandler = useErrorHandler();

  async function handleOnChange() {
    try {
      const res = await api.me();
      if (app.updateUser) {
        app.updateUser(res.user || undefined);
      }
      redirectAfterUpdate();
    } catch (err) {
      errorHandler.handleError(err);
    }
  }

  function redirectAfterUpdate() {
    const values = qs.parse(router.location.search);
    const redirect = values.redirect ? values.redirect.toString() : '/';
    router.history.push(redirect);
  }

  const passwordExpired = app.user && app.user.password_expiration_days === 0;

  return (
    <div className="App" id="app">
      <div className="App-body" id="appBody">
        <LayoutWithBackground>
          <div className="ChangePassword">
            <PublicHeader />
            <CenteredCard>
              <h1>{t('user.ChangePassword.changePassword')}</h1>
              <div className="mt-4">
                <Row>
                  <Col lg={10}>
                    {passwordExpired && (
                      <>
                        <p className="mb-1">
                          {t('user.ChangePassword.yourPasswordHasExpired')}.
                        </p>
                        <p className="mb-1">
                          {t(
                            'user.ChangePassword.toContinueYouMustChangeYourPassword'
                          )}
                          .
                        </p>
                      </>
                    )}
                    <ErrorMessages errorData={errorHandler} className="my-2" />

                    <ChangePasswordForm onChanged={handleOnChange} />

                    <div className="d-flex justify-content-end mt-5">
                      <Button
                        to="/"
                        onClick={(e) => {
                          e.preventDefault();
                          app.requestLogout();
                        }}
                      >
                        {t('common.commonTexts.logOut')}
                      </Button>
                    </div>
                  </Col>
                </Row>
              </div>
            </CenteredCard>
            <PublicFooter />
          </div>
        </LayoutWithBackground>
      </div>
    </div>
  );
}
