import { t } from '../../../../lib/i18n';

interface User {
  username: string;
  impersonal_user: boolean;
  first_name?: string | null;
  last_name?: string | null;
  display_name?: string | null;
  phone?: string | null;
}

export default function formatUserLabel(user: User) {
  if (user.impersonal_user) {
    if (user.display_name) {
      return `${user.display_name} ${user.phone ? `(${user.phone})` : ''}`;
    } else if (user.phone) {
      return user.phone;
    } else {
      return `${t('manage.permissions.lib.formatUserLabel.unknownUser')} (${user.username})`;
    }
  } else {
    if (user.first_name || user.last_name) {
      return `${user.first_name ?? ''} ${user.last_name ?? ''} ${
        user.username ? `(${user.username})` : ''
      }`;
    } else {
      return user.username;
    }
  }
}
