import { atom, useAtom } from 'jotai';
import { useEffect } from 'react';
import * as api from '../../../../../serverApi';

type Hv = { h: number; v: number };
type Xyza = { x: number; y: number; z: number; a: number };

const hvAtom = atom<Hv | undefined>(undefined);
const xyzaAtom = atom<Xyza | undefined>(undefined);

/**
 * Hook to get details about the current hover point, without
 */
export const useHoverPointCoordinates = () => {
  const [hv] = useAtom(hvAtom);
  const [xyza] = useAtom(xyzaAtom);
  return {
    ...hv,
    ...xyza,
  };
};

interface Opts {
  /**
   * When sensorCompositeId is null/undefined, the `skip` option
   * will be implicitly true, and no requests will be sent.
   */
  sensorCompositeId?: string;

  /**
   * When skip is true, the point will be set to null and
   * no API requests will be sent.
   */
  skip?: boolean;
}

/**
 * Maintain the current hover point and query the API for the XYZA values
 * for this point.
 */
export function useHoverPoint(opts: Opts): {
  /**
   * The currently hovered point, or null if no point is hovered.
   * Additionally the latest xyza values from the server, that might not
   * directly match the hv values. They're just the latest values
   * fetched from the buffer in rcts.
   */
  point: null | {
    h: number;
    v: number;
    x?: number;
    y?: number;
    z?: number;
    a?: number;
  };

  /**
   * Clear the current point and xyza values (when the pointer leaves the canvas)
   */
  clear: () => void;

  /**
   * Set the new point position (continually as the pointer moved over the canvas)
   */
  set: (point: Hv) => void;
} {
  const [hv, setHv] = useAtom(hvAtom);
  const [xyza, setXyza] = useAtom(xyzaAtom);

  const skip = Boolean(opts.skip || !opts.sensorCompositeId);

  /**
   * Whenever `skip` is set to true or the `sensorCompositeId` is
   * empty, clear the point data.
   */
  useEffect(() => {
    if (skip === true && hv) {
      setHv(undefined);
      setXyza(undefined);
    }
  }, [skip, hv, setHv, setXyza]);

  /**
   * Whenever the point changes, we run the request to get updated x,y,z,a values
   */
  useEffect(() => {
    (async () => {
      if (
        skip === true || // If skip is true, we don't need to send a request
        !hv || // If a point is not set, we don't need to send a request
        !opts.sensorCompositeId // If the sensorCompositeId is not set, we can't send a request
      ) {
        return;
      }

      try {
        const pointDetails = await api.getMarkingPixelDetails(
          opts.sensorCompositeId,
          hv
        );
        setXyza(pointDetails);
      } catch (err) {
        console.error(err);
      }
    })();
  }, [hv, skip, setXyza, opts.sensorCompositeId]);

  return {
    point: hv
      ? {
          ...hv,
          ...xyza,
        }
      : null,

    clear: () => {
      setHv(undefined);
      setXyza(undefined);
    },

    set: setHv,
  };
}
