//
//
// TypeScript generated by `php artisan ts:generate` from RoomMate server.
//
//

import Axios, { AxiosError } from 'axios';
import { useQuery, useMutation, UseQueryOptions, UseMutationOptions } from 'react-query';
import { buildUrl } from './lib/buildUrl';

/**
 * The API root URL used by the server API wrapper functions.
 * Default is empty, to use the same hostname as the frontend.
 *
 */
let apiRootUrl = '';

export function setApiRoot(apiRoot: string) {
  apiRootUrl = apiRoot;
}

export function getApiRoot() {
  return apiRootUrl;
}

export async function apiRequest<D = {}, R = unknown>(
    method: 'get' | 'delete' | 'head' | 'options' | 'post' | 'put' | 'patch',
    path: string,
    input: D
) {
    const targetUrl = (method === 'get' && typeof input === 'object')
        ? buildUrl(apiRootUrl + path, input)
        : apiRootUrl + path;

    const res = await Axios.request<R>({
      url: targetUrl,
      method,
      data: input,
    });

    return res?.data;
}

export interface Paged<T> {
  from: number;
  to: number;
  current_page: number;
  per_page: number;
  total: number;
  last_page: number;
  path: string;
  first_page_url: string;
  last_page_url: string;
  next_page_url: string;
  prev_page_url: string;
  data: Array<T>;
}

export type WithPagination<T> = T & { page?: number; page_size?: number; };
export type WithSortable<T, K extends string> = T & { sort?: `${K}` | `${K} asc` | `${K} desc`; };

export type EventType = string;
export type CapabilityType =
  | 'accessSensorsWithoutZoneAssignment'
  | 'careAndObservation'
  | 'dashboardAccess'
  | 'eventImageAnonymised'
  | 'eventImageDetailed'
  | 'globalSuper'
  | 'manageZones'
  | 'observationAnonymised'
  | 'observationDetailed'
  | 'oneWayAudio'
  | 'oneWayAudioAnonymised'
  | 'requireOneTimeCode'
  | 'sendActivityToVkp'
  | 'simulateSensorEvents'
  | 'skipIpWhitelistRequirement'
  | 'superImpersonateAccounts'
  | 'superImpersonateUserRoles'
  | 'twoWayAudio'
  | 'vendor'
  | 'vendorAccessSensorAudio'
  | 'vendorAccessSensorSettings'
  | 'vendorAccessUnassignedSensorSettings'
  | 'vendorBulkAssignSensorsToAccount'
  | 'vendorCreateAccounts'
  | 'vendorImpersonateAccounts'
  | 'vendorImpersonateUserRoles'
  | 'vendorListIntegrationUsers'
  | 'vendorReadAccounts'
  | 'vendorRemoveIntegration'
  | 'vendorSetupIntegration'
  | 'vendorUpdateAccounts';

export type FeatureFlagKey =
  | 'argus-ignored-detections-timeout'
  | 'enable-dashboard'
  | 'enable-mjpeg-streams'
  | 'enable-sensor-details-wifi-streaming'
  | 'enable-sse'
  | 'enable-status-streaming'
  | 'enable-time-zones'
  | 'enable-wifi-column'
  | 'idle-timeout-notify-time'
  | 'image-streaming-interval'
  | 'interval-dashboard-events'
  | 'interval-dashboard-structure'
  | 'interval-status-events'
  | 'interval-status-sensors'
  | 'sensor-status-query-interval'
  | 'show-activity-overview'
  | 'show-argus-management'
  | 'show-argus-markings'
  | 'show-argus-markings-for-unsupported-types'
  | 'show-sensor-resolution-option'
  | 'show-sound-dimension';

export type SensorType =
  | 'climax_door_contact'
  | 'climax_geo_pendant'
  | 'climax_heat_detector'
  | 'climax_motion_detector'
  | 'climax_pull_cord'
  | 'climax_push_button'
  | 'climax_smoke_detector'
  | 'climax_water_detector'
  | 'climax_wrist_button'
  | 'roommate';

export type BySensorTypeAndEventType<T> = {
  climax_door_contact: {
    battery: T;
    close: T;
    open: T;
    tamper: T;
  };
  climax_geo_pendant: {
    battery: T;
    click: T;
    doubleclick: T;
  };
  climax_heat_detector: {
    battery: T;
    fire: T;
  };
  climax_motion_detector: {
    battery: T;
    motion: T;
  };
  climax_pull_cord: {
    battery: T;
    doublepull: T;
    pull: T;
  };
  climax_push_button: {
    battery: T;
    click: T;
    doubleclick: T;
  };
  climax_smoke_detector: {
    battery: T;
    fire: T;
  };
  climax_water_detector: {
    battery: T;
    water: T;
  };
  climax_wrist_button: {
    battery: T;
    click: T;
    doubleclick: T;
  };
  roommate: {
    abs: T;
    act: T;
    bedmark: T;
    ent: T;
    exi: T;
    fall: T;
    fire: T;
    floor: T;
    hib: T;
    hsnd: T;
    inact: T;
    lmib: T;
    markings: T;
    offline: T;
    online: T;
    oob: T;
    ooc: T;
    speech: T;
    startup: T;
    tlib: T;
  };
};

export type ServerExceptionCodes =
  | 'account-not-found'
  | 'cant-change-account-to-vendor'
  | 'cant-change-vendor-to-account'
  | 'cant-set-vendor-field'
  | 'identity-provider-is-disabled'
  | 'identity-provider-not-found'
  | 'vendor-already-assigned'
  | 'vendor-not-assigned-on-account'
  | 'alert-setting-api-locked'
  | 'auth-login-failed-invalid-credentials'
  | 'auth-login-failed-no-passing-ip-whitelists'
  | 'auth-login-failed-papi-user'
  | 'auth-login-failed-user-disabled'
  | 'auth-not-impersonating'
  | 'care-receiver-cant-be-deleted'
  | 'care-receiver-couldnt-be-deleted'
  | 'care-receiver-does-not-have-vkp-enabled'
  | 'care-receiver-empty-input'
  | 'care-receiver-not-exists'
  | 'care-receiver-zone-already-has-a-care-receiver'
  | 'care-receiver-zone-has-to-be-housing-unit'
  | 'event-log-not-found'
  | 'cant-delete-feature-flag-definition'
  | 'feature-flag-already-exists'
  | 'feature-flag-cant-delete'
  | 'feature-flag-definition-not-exists'
  | 'feature-flag-not-exists'
  | 'feature-flags-user-and-account-dont-associate'
  | 'cant-remove-integration-user-has-multiple-roles'
  | 'cant-remove-integration-user-isnt-a-papi-user'
  | 'cant-remove-integration-zone-has-multiple-alert-settings'
  | 'cant-remove-integration-zone-has-non-locked-alert-settings'
  | 'cant-remove-integration-zone-has-sensors'
  | 'cant-remove-integration-zone-has-subzones'
  | 'integration-setup-parent-zone-cant-be-housing-unit'
  | 'integration-setup-parent-zone-id-required'
  | 'integration-setup-parent-zone-not-found'
  | 'integration-setup-user-display-name-required'
  | 'integration-setup-user-password-required'
  | 'integration-setup-user-username-required'
  | 'integration-setup-username-must-not-contain-special-chars'
  | 'integration-setup-zone-name-required'
  | 'user-ip-address-not-whitelisted'
  | 'date-period-more-than-a-year'
  | 'to-date-before-from-date'
  | 'mobile-device-already-exists-for-user'
  | 'mobile-device-user-not-found'
  | 'national-id-number-already-exists'
  | 'user-password-expired'
  | 'user-required-one-time-code'
  | 'resource-not-found'
  | 'sensor-zone-api-locked'
  | 'sensor-already-assigned-to-account'
  | 'sensor-already-assigned-to-vendor-account'
  | 'sensor-already-assigned-to-zone'
  | 'sensor-already-exists'
  | 'sensor-assigned-to-other-account'
  | 'sensor-cant-bulk-update-account'
  | 'sensor-couldnt-be-deleted'
  | 'sensor-move-to-zone-confirmation'
  | 'sensor-not-assigned-to-account'
  | 'sensor-not-assigned-to-vendor-account'
  | 'sensor-not-found'
  | 'sensor-roommate-id-exceeds-max-id'
  | 'sensor-setting-not-found'
  | 'sensor-zone-has-to-be-housing-unit'
  | 'invalid-sensor-ids-input'
  | 'invalid-sensor-ids-input-max-range'
  | 'sso-user-cannot-reset-password'
  | 'user-already-connected-to-azure'
  | 'user-cannot-move-user-account-with-active-roles'
  | 'user-cant-change-impersonal-role'
  | 'user-cant-change-role-to-impersonal'
  | 'user-cant-delete-users-only-role'
  | 'user-cant-remove-national-id-on-enabled-vkp'
  | 'user-couldnt-be-deleted'
  | 'user-does-not-have-vkp-capability'
  | 'user-does-not-have-vkp-enabled'
  | 'user-duplicate-role-on-same-zone-not-allowed'
  | 'user-email-not-allowed-on-impersonal-users'
  | 'user-impersonal-role-not-allowed'
  | 'user-incorrect-current-password'
  | 'user-invalid-one-time-code'
  | 'user-invalid-password-parameters'
  | 'user-is-api-locked'
  | 'user-is-not-integration-user'
  | 'user-national-id-not-allowed-on-impersonal-users'
  | 'user-national-id-required-on-selected-roles'
  | 'user-no-access-on-mobile-device'
  | 'user-no-access-on-user'
  | 'user-not-found'
  | 'user-only-impersonal-role-allowed-in-user'
  | 'user-reset-token-not-found'
  | 'user-role-already-assigned'
  | 'user-role-not-exists'
  | 'user-role-not-found'
  | 'user-username-exists-on-account'
  | 'user-username-not-allowed-on-impersonal-users'
  | 'user-validation-date-expired'
  | 'user-not-logged-in'
  | 'user-role-disabled-inactive'
  | 'user-role-disabled-invalid-ip'
  | 'user-role-disabled-other'
  | 'user-role-disabled-outside-valid-time-period'
  | 'user-role-cant-add-global-role'
  | 'user-role-cant-delete-global-role'
  | 'user-role-cant-update-global-role'
  | 'user-role-not-found'
  | 'zone-is-api-locked'
  | 'zone-does-not-have-vkp-enabled'
  | 'zones-alert-setting-already-has-that-zone-id'
  | 'zones-cant-be-deleted'
  | 'zones-cant-be-deleted-has-care-receiver'
  | 'zones-cant-be-deleted-has-sensors'
  | 'zones-cant-be-deleted-has-sub-zones'
  | 'zones-cant-be-deleted-has-user-roles'
  | 'zones-cant-delete-active-zone'
  | 'zones-ip-whitelist-couldnt-be-deleted'
  | 'zones-ip-whitelist-not-found'
  | 'zones-not-exists'
  | 'zones-parent-zone-already-has-care-receiver'
  | 'zones-parent-zone-already-has-sensor'
  | 'zones-parent-zone-cant-be-housing-unit';

export type MarkingObjectType = 'bed' | 'door_exit' | 'chair' | 'bathroom' | 'reflex' | 'discard';

export type OtpDevice = { display_name?: null | string; phone?: null | string; personal: boolean; } & ({ channel: 'app'; mobile_device_id: number; } | { channel: 'sms'; user_id: number; } | { channel: 'email'; });

export interface Role {
  id: number;
  role: string;
  role_is_global?: boolean;
  zone: null | {
    zone_id: number;
    name: string;
  };
  account: null | {
    account_id: number;
    name: string;
  };
  capabilities: CapabilityType[];
  disabled?: boolean;
  disabledReason?: 'outside-valid-time-period' | 'invalid-ip' | 'inactive' | 'other';
  otp_devices?: OtpDevice[];
}

export interface Account {
  account_code: string;
  account_id: number;
  enable_sso: boolean;
  default_user_locale: string;
  disable_all_detailed_images: boolean;
  disable_national_id_numbers: boolean;
  is_vendor: boolean;
  name: string;
  require_national_id_for_care_receivers: boolean;
  password_expiration_days: null | number;
  log_lifetime_event_images_minutes: number;
  log_lifetime_change_logs_days: number;
  log_lifetime_access_logs_days: number;
  log_lifetime_care_logs_days: number;
  vkp_available: boolean;
  root_zone: {
    zone_id: number;
    name: string;
  };
  vendors?: Array<{
    id: number;
    name: string;
  }>;
  accounts?: Array<{
    id: number;
    name: string;
  }>;
}

export interface User {
  account: Account;
  id: number;
  username: string;
  email: string | null;
  phone: string | null;
  phone2: string | null;
  first_name: string | null;
  last_name: string | null;
  one_time_code_provided: boolean;
  roles: Role[];
  active_role: Role | null;
  password_expiration_days: number | null;
  vkp_enabled: boolean | null;
  external_idp: boolean;
  features: {
     [key: string]: boolean | string;
  };
  preferences: ProfilePreferences;
  impersonating?: boolean;
  authenticated_with_sso: boolean;
}

export interface LoginResult {
  user: User;
}

export interface LoginData {
  account: string;
  username: string;
  password: string;
  redirect?: string;
}

export interface MeResult {
  user: User | null;
  has_app_otp?: boolean;
}

export interface SystemMessages {
  messages: Array<{
    heading: string;
    body: string;
    date: string;
  }>;
}

export type OtpCodeInput = ({ channel: 'app'; mobile_device_id: number; } | { channel: 'sms'; user_id: number; } | { channel: 'email'; });

export type requestOtpCodeInput = OtpCodeInput;

export interface OtpSubmitData {
  code: string;
}

export interface SelectRoleInput {
  roleId: number;
}

export interface SelectRoleResult {
  user: User;
}

export interface ForgotPasswordData {
  username: string;
  account: string;
}

export interface ResetPasswordData {
  account: string;
  username: string;
  password: string;
  passwordConfirmation: string;
}

export interface ChangePasswordData {
  current_password: string;
  new_password: string;
}

export interface ChangePasswordResult {
  user: User;
}

export interface ProfilePreferences {
  locale: null | string;
}

export interface UpdateProfileInput {
  preferences?: Partial<ProfilePreferences>;
}

export interface UpdateProfileOutput {
  preferences: ProfilePreferences;
}

export interface GetAuthUrl {
  auth_url: string;
}

export interface AzureData {
  code: string;
}

export interface AzureUser {
  account: Account;
  id: number;
  username: string;
  email: string | null;
  phone: string | null;
  phone2: string | null;
  first_name: string | null;
  last_name: string | null;
  one_time_code_provided: boolean;
  roles: Role[];
  active_role: Role | null;
  password_expiration_days: number | null;
  vkp_enabled: boolean | null;
  external_idp:boolean;
  features: {
     [key: string]: boolean | string;
  };
  preferences: ProfilePreferences;
  impersonating?: boolean;
  authenticated_with_sso: boolean;
}

export interface AzureResult {
  user: AzureUser;
}

export interface StopImpersonationResult {
  user: User;
  redirect_url?: string | null; // The URL to redirect to after impersonation is stopped
}

export type SensorStatus =  'offline' | 'blocked' | 'deactivated' | 'silenced' | 'online';

export interface CareSensor {
  sensor_composite_id: string;
  sensor_id: number;
  sensor_type: SensorType;
  sensor_name: string | null;
  zone_id: number;
  zone_name: string | null;
  parent_zone_id: number | null;
  parent_zone_name: string | null;
  on_time: string;
  off_time: string;
  vkp_enabled: boolean | null;
  care_receiver: null | {
    id: number;
    first_name: string | null;
    last_name: string | null;
    vkp_enabled: boolean;
  };
  media: {
    observation_image_detailed: boolean;
    observation_image_anonymised: boolean;
    event_image_detailed: boolean;
    event_image_anonymised: boolean;
    twoway_audio: boolean;
    oneway_audio: boolean;
    oneway_audio_anonymised: boolean;
    max_call_duration_twoway_audio: number;
    max_call_duration_oneway_audio: number;
    max_call_duration_oneway_audio_anonymised: number;
    enable_behavioral_data: boolean;
  };
  status: SensorStatus;
  is_emulated?: boolean;
  is_audio_only?: boolean;
  is_updating?: boolean;
  is_demo?: boolean;
  is_online?: boolean;
  is_enabled?: boolean;
  reflex_presence_timeout: number | null;
  observation_stream_timeout: number | null;
  display_area_format: string | null;
  display_name_format: string | null;
  user_status: string | number | null;
  display_area: string | null;
  display_name: string | null;
  is_blocked?: boolean;
}

export interface CareEvent {
  event_type: EventType;
  event_id: number;
  event_at: string; // YYYY-MM-DD HH:MM:SS
  level: 'alarm' | 'alert';
  is_technical: boolean;
  sensor: CareSensor;
}

export interface CareStatus {
  events: CareEvent[];
  sensors: CareSensor[];
}

export interface GetStatusTokenResult {
  token: string; // The token itself.
  lifetime: number; // The lifetime of the token, in seconds.
}

export interface CareWebRtcConfig {
  iceServers: Array<{
    urls: string[];
  }>;
  voipServerHost: string;
  sipUserAgent: string;
  sipUsername: string;
  sipHa1: string;
  voipDebug: boolean;
  voipSecurityIdHash: string;
  viewTimeout: number;
  sipClientIp: string;
  disabled?: boolean;
}

export type StreamType = 'depth' | 'amp' | 'raw';

export type AudioProperty = 'oneWay' | 'oneWayAnon' | 'twoWay';

export interface CareKeepStreamingInput {
  sensorId: string;
  type?: null | StreamType;
  audioProperty?: null | AudioProperty;
}

export interface GetStreamTokenInput {
  sensorId: string;
  streamId: number; // Client-generated ID to identify this particular stream in the session
  type: StreamType;
  audioProperty?: null | AudioProperty;
}

export interface GetStreamTokenResult {
  token: string;
}

export interface AddObservationNoteInput {
  sensorId: string;
  type: StreamType | null;
  audioProperty: AudioProperty | null;
  note: string;
}

export interface MarkEventAsHandledInput {
  sensor_id: string;
  event_id: number;
  vkp?: boolean;
  note?: string;
  event_type: EventType;
  event_hash?: string | null;
}

export interface CareGetEventSummaryResult {
  event_type: EventType;
  event_id: number;
  event_at: string;
  event_at_utc: string;
  event_at_sensor_local: string;
  level: 'alarm' | 'alert';
  vkp_enabled: null | boolean;
  timezone?: string;
  sensor: {
    id: string;
    name: null | string;
    sensor_type: SensorType;
    display_area: string | null;
    display_name: string | null;
  };
  zone: {
    id: number;
    name: null | string;
  };
  handled: null | {
    by: null | {
      id: number;
      username: string;
      impersonal_user: boolean;
      first_name: null | string;
      last_name: null | string;
      display_name: null | string;
    };
    at: string; // yyyy-MM-dd HH:mm:ss
    at_utc: string; // yyyy-MM-dd HH:mm:ss
    at_sensor_local: string; // yyyy-MM-dd HH:mm:ss
  };
  care_receiver: null | {
    id: number;
    first_name: null | string;
    last_name: null | string;
  };
  images: Array<{
    type: 'depth' | 'raw' | 'amp';
    data: string;
  }>;
  received_by_sensor: null | {
    name: null | string;
    sensor_id: number;
    sensor_type: SensorType;
    zone: null | {
      zone_id: number,
      name: string;
    };
  };
}

export interface CareGetInfoSummaryForSensorResult {
  last_movement_in_bed_utc?: null | string;
  last_movement_in_bed_sensor_local?: null | string;
  timezone?: null | string;
  events: Array<{
     id: number;
     sensor_type: SensorType;
     event_type: string;
     received_at: string;
     received_at_utc: string;
     received_at_sensor_local: string;
     timezone: string;
  }>
  event_settings: Array<{
     sensor_type: SensorType;
     event_type: EventType;
     enabled: boolean;
     level: 'alert' | 'alarm';
     enabled_from: string | null; // 'HH:mm'
     enabled_to: string | null; // 'HH:mm'
  }>
}

export interface CareGetBehaviorDataResult {
  settings: {
    sensitivity: number;
    bedPixels: number;
    bedDistance: number;
  };
  time: {
    startTime: string;
    samplePeriodS: number;
  };
  labels: string[];
  samples: Array<number[]>;
}

export interface DashboardOverviewInput {
  zone_id: number;
}

export interface DashboardOverviewZoneData {
  zone: {
    id: number;
    name: string | null;
    parent: null | {
      id: number;
      name: string | null;
    };
  };
  care_receiver: null | {
    id: number;
    first_name: string | null;
    last_name: string | null;
  };
  events: Array<{
    sensor_id: number;
    sensor_type: SensorType;
    sensor_composite_id: string;
    event_type: string;
    event_id: number;
    event_at: string;
    level: 'alarm' | 'alert' | 'info' | 'hidden';
  }>;
  sensors: Array<{
    sensor_id: number;
    sensor_type: SensorType;
    sensor_composite_id: string;
    name: null | string;
    demo_mode?: boolean;
    emulated?: boolean;
    updating?: boolean;
    user_status?: string;
    status: 'offline' | 'silenced' | 'updating' | 'online';
  }>;
}

export interface DashboardOverviewResult {
  data: Array<DashboardOverviewZoneData>;
}

export interface DashboardEventsLastPeriodInput {
  zone_id: number;
}

export interface DashboardEventsLastPeriodResult {
  periodInHours: number;
  data: Array<{
    sensor_type: SensorType;
    event_type: string;
    event_type_count: number;
  }>;
}

export interface GetEventByHashResult {
  account_code: string;
  event_id: number;
  event_type: string;
  is_technical: boolean;
  channel: string;
  at_utc: string;
  at_sensor_local: string;
  vkp_enabled: null | boolean;
  recipient: {
    id: number;
    username: string;
    impersonal_user: boolean;
    first_name: null | string;
    last_name: null | string;
    display_name: null | string;
    locale: null | string;
  };
  me: null | User;
  access_to_supervision: boolean;
  role_with_supervision_access: Role | null;
  sensor: {
    id: string;
    name: null | string;
    sensor_type: SensorType;
    display_area: null | string;
    display_name: null | string;
  };
  zone: {
    id: number;
    name: null | string;
    parent: null | {
      id: number;
      name: null | string;
    };
  };
  handled: null | {
    by: null | {
      id: number;
      username: string;
      impersonal_user: boolean;
      first_name: null | string;
      last_name: null | string;
      display_name: null | string;
    };
    at_utc: string; // yyyy-MM-dd HH:mm:ss
    at_sensor_local: string; // yyyy-MM-dd HH:mm:ss
  };
  care_receiver: null | {
    id: number;
    first_name: null | string;
    last_name: null | string;
    vkp_enabled: null | boolean;
  };
  images: Array<{
    type: 'depth' | 'raw' | 'amp';
    data: string;
  }>;
  received_by_sensor: null | {
    name: null | string;
    sensor_id: number;
    sensor_type: SensorType;
    zone: null | {
      zone_id: number,
      name: string;
    };
  };
}

export interface StructureSensor {
  id: string;
  name: null | string;
  display_name: null | string;
  display_area: null | string;
  on_time: string;
  off_time: string;
  comment: string;
}

export interface StructureZone {
  id: number;
  name: string;
  api_lock?: boolean; // Whether this zone is locked down by an integration
  parent: null | {
    id: number;
    name: string;
  };
  zones: null | Array<StructureZone>;
  is_housing_unit: boolean;
  care_receiver: null | {
    id: string;
    first_name: string | null;
    last_name: string | null;
  };
  sensors: null | Array<StructureSensor>;
  has_user_roles: boolean;
  zone_settings: null | ZoneSettings;
  has_defined_ip_whitelists: boolean;
}

export interface GetStructureResult {
  zone: StructureZone;
}

export interface EventTypesForSensorResult {
  data: Array<{
    sensor_type: SensorType;
    event_type: string;
    enabled: boolean;
    enabled_from: string | null;
    enabled_to: string | null;
    level: 'alarm' | 'alert';
  }>;
}

export interface SimulateEventInput {
  eventType: string;
}

export interface PresenceInput {
  seconds: number;
}

export interface MoveSensorToServerInput {
  server: string;
}

export interface SearchUsersInput {
  query: string;
  zone_id?: number;
}

export interface SearchUsersResult {
  results: Array<{
    id: number;
    username: string;
    phone: string;
    impersonal_user: boolean;
    first_name: string | null;
    last_name: string | null;
    display_name: string | null;
    readonly: boolean;
    user_roles: Array<{
      id: number;
      role: string;
      zone: null | {
        id: number;
        name: string | null;
      };
    }>;
  }>;
}

export interface UnassignedClimaxSensorDetails {
  sensor_id: string;
  sensor_type: string;
  last_seen_at: string;
}

export type GetUnassignedClimaxResult = UnassignedClimaxSensorDetails[];

export type GetAccountSettingsResult = AccountSettingsResult;

export interface AccountSettingsResult {
  password_expiration_days: null | number;
  log_lifetime_event_images_minutes: number;
  log_lifetime_change_logs_days: number;
  log_lifetime_access_logs_days: number;
  log_lifetime_care_logs_days: number;
  idle_timeout: null | number;
}

export type UpdateAccountSettingsResult = AccountSettingsResult;

export interface UpdateAccountSettingsData {
  password_expiration_days?: null | number;
  log_lifetime_event_images_minutes?: number;
  log_lifetime_change_logs_days?: number;
  log_lifetime_access_logs_days?: number;
  log_lifetime_care_logs_days?: number;
  idle_timeout?: null | number;
}

export interface GetCareReceiversByZoneResult {
  care_receivers: Array<{
    care_receiver_id: number;
    first_name: string;
    last_name: string;
    birth_date: string | null;
    blocked_users_count: number;
    user: null | {
      id: number;
    };
    zone: null | {
      id: number;
      name: string;
    };
    parent_zone: null | {
      id: number;
      name: string;
    };
  }>;
}

export interface CareReceiverBlockedUser {
  blocked_user_id: number;
  username: string;
  first_name: string | null;
  last_name: string | null;
  impersonal_user: boolean;
}

export interface CareReceiverDetails {
  care_receiver_id: number;
  first_name: string | null;
  last_name: string | null;
  birth_date: string | null;
  national_id_number: string | null;
  user: null | {
    id: number;
  };
  zone: null | {
    id: number;
    name: string;
    parent: null | {
      id: number;
      name: string;
    };
  };
  blocked_users: Array<CareReceiverBlockedUser>;
}

export type GetCareReceiverDetailsResult = CareReceiverDetails;

export interface SearchCareReceiversInput {
  query: string;
}

export interface SearchCareReceiversResult {
  results: Array<{
    care_receiver_id: number;
    first_name: string;
    last_name: string;
  }>;
}

export interface FindCareReceiverByNationalIdInput {
  national_id_number: string;
}

export interface FindCareReceiverByNationalIdResult {
  care_receiver: CareReceiverDetails | null
}

export interface AddCareReceiverInput {
  national_id_number?: string;
  first_name: string | null;
  last_name: string | null;
  zone_id?: null | number;
  blocked_user_ids?: number[];
}

export type AddCareReceiverResult = CareReceiverDetails;

export interface UpdateCareReceiverData {
  national_id_number?: string;
  first_name?: null | string;
  last_name?: null | string;
  zone_id?: null | number;
  blocked_user_ids?: number[];
}

export type UpdateCareReceiverResult = CareReceiverDetails;

export interface GetAvailableRolesResult {
  roles: Array<{
    role: string;
    is_global: boolean;
    capabilities: CapabilityType[];
  }>;
}

export interface CreateUserRolesInput {
  user_id: number;
    zone_id: number;
    role: string;
    enabled?: boolean;
    valid_from: string | null;
    valid_to: string | null;
}

export interface CreateUserRolesResult {
  user_role: UserRolesData;
}

export interface UpdateUserRolesInput {
  zone_id: number;
    role: string;
    enabled?: boolean;
    valid_from: string | null;
    valid_to: string | null;
}

export interface UpdateUserRolesResult {
  user_role: UserRolesData;
}

export interface UserRolesData {
  user_role_id: number;
   username: string;
   zone_name: string;
   role: string;
   enabled: boolean;
   valid_from: string | null;
   valid_to: string | null;
}

export interface UserRolesOutputData {
  user_role_id: number;
  enabled: boolean;
  valid_from: string | null;
  valid_to: string | null;
  readonly: boolean;
  user:{
    user_id: number;
    username: string;
    enabled: boolean;
    impersonal_user: boolean;
    display_name: string | null;
    email: string | null;
    first_name: string | null;
    last_name: string | null;
    phone: string | null;
    phone2: string | null;
    api_lock: boolean;
  };
  zone:{
    zone_id: number;
    name: string;
  };
  role: {
    role: string;
  };
}

export interface GetUserRolesForZoneResult {
  data: Array<UserRolesOutputData>;
}

export interface SortedUserRolesOutputData {
  user_id: number;
  username: string;
  phone: string;
  enabled: boolean;
  impersonal_user: boolean;
  display_name: string | null;
  first_name: string | null;
  last_name: string | null;
  has_national_id_number: boolean;
  has_registered_app: boolean;
  app_reg: null | string;
  readonly: boolean;
  api_lock: boolean;
  user_roles: Array<{
     user_role_id: number;
     role: string;
     enabled: boolean;
     valid_from: string | null;
     valid_to: string | null;
     lineage_zone_id: number;
     zone_id: number;
     zone_name: string;
  }>
}

export interface GetSortedUserRolesForZoneResult {
  data: Array<SortedUserRolesOutputData>;
}

export interface AddUserToMobileDeviceInput {
  user_id: number;
  override?: boolean;
}

export interface UnassignUserFromMobileDeviceInput {
  user_id: number;
  mobile_device_id: number;
}

export interface SensorDetails {
  id: string;
  sensor_id: string;
  sensor_type: SensorType;
  name: string | null;
  zone: {
    id: number;
    name: string | null;
    api_lock: boolean;
    parent: null | {
      id: number;
      name: string | null;
    };
  };
  care_receiver: null | {
    id: number;
    first_name: string | null;
    last_name: string | null;
  };
  on_time: string | null;
  off_time: string | null;
  is_online: boolean;
  is_updating: boolean;
  markings_defined?: MarkingObjectType[];
  event_types: {
    [key: string]: {
      enabled: boolean;
      level: string;
      audio_set: string;
    };
  };
  is_enabled: boolean;
  show_receiving_sensor: boolean;
  is_audio_only: boolean;
  demo_mode: boolean;
  argus_enabled: boolean;
}

export interface GetSensorDetailsResult {
  sensor: SensorDetails;
}

export interface GetSensorsForZoneResult {
  sensors: SensorDetails[];
}

export interface EventSettings {
  enabled: boolean;
  level: 'hidden' | 'info' | 'alert' | 'alarm';
  enabled_from: string | null; // 'HH:mm:ss'
  enabled_to: string | null; // 'HH:mm:ss'
  quarantine1: number | null;
  quarantine2: number | null;
  threshold1: number | null;
  threshold2: number | null;
  audio_enabled: boolean | null;
  audio_file: string | null;
  audio_volume: number | null; // 0-100
  audio_set: string;
}

export interface GetEventSettingsBySensorIdResult {
  [eventType: string]: EventSettings;
}

export interface UpdateEventSettingsBySensorIdResult {
  [eventType: string]: EventSettings;
}

export type UpdateEventSettingsBySensorIdInput = Partial<EventSettings>;

export interface GetEventSettingsByZoneIdResult {
  [eventType: string]: EventSettings;
}

export interface UpdateEventSettingsByZoneIdResult {
  [eventType: string]: EventSettings;
}

export type UpdateEventSettingsByZoneIdInput = Partial<EventSettings>;

export interface SensorData {
  sensor_id: string;
 sensor_type: SensorType;
 zone_id?: number;
 zone_name?: string;
 account_id?: number;
 on_time?: string;
 off_time: string;
 off_warning_sent?: boolean;
 name?: string;
 user_status?: string;
 last_view_depth_uid?: number;
 last_view_depth_time?: string;
 last_view_amp_uid?: number;
 last_view_amp_time?: string;
 last_view_raw_uid?: number;
 last_view_raw_time?: string;
 is_updating?: boolean;
 tunnel_ip?: number;
 wan_ip?: number;
 comment?: string;
 created_at?: string;
 updated_at?: string;
}

export interface SensorEventSettingRecord {
  sensor_event_setting_id : number;
  event_type : string;
  level : string;
  enabled : boolean;
  enabled_from : string | null;
  enabled_to : string | null;
  quarantine1?: number;
  quarantine2?: number;
  threshold1?: number;
  audio_volume?: boolean;
}

export interface UpdateSensorsInput {
  name?: string;
 zone_id?: null | number;
 is_enabled?: boolean;
 move?: boolean;
 factoryResetSettings?: boolean;
}

export interface UpdateSensorsResult {
  sensor: SensorData
}

export interface GetAlertsForSensorResult {
  alert_settings: AlertSetting[];
}

export type Weekday = 'mon' | 'tue' | 'wed' | 'thu' | 'fri' | 'sat' | 'sun';

export interface AlertSettingEventType {
  event_type: string;
  sensor_type: SensorType;
}

export interface AlertSettingRecipient {
  id: number;
  username: string;
  impersonal_user: boolean;
  first_name: string | null;
  last_name: string | null;
  display_name: string | null;
  phone: null | string;
}

export interface AlertSettingScheduleRule {
  alert_setting_schedule_id?: number;
  to_time: string;
  to_weekday: Weekday[];
  from_time: string;
  from_weekday: Weekday[];
}

export interface AlertSetting {
  alert_setting_id: number;
  zone: {
    id: number;
    name: string | null;
  };
  name: string | null;
  enabled: boolean;
  schedule: Array<AlertSettingScheduleRule>;
  event_types: AlertSettingEventType[];
  recipients: AlertSettingRecipient[];
  escalation_enabled: boolean;
  escalation_delay: null | number;
  escalation_recipients: null | AlertSettingRecipient[];
  include_regular_alarm_events: boolean;
  include_regular_alert_events: boolean;
  include_technical_event_types: boolean;
  include_custom_event_types: boolean;
  api_lock: boolean;
}

export interface GetAlertSettingsResult {
  alert_settings: AlertSetting[];
}

export interface CreateAlertSettingInput {
  name: string | null;
  enabled: boolean;
  schedule: Array<AlertSettingScheduleRule>;
  include_regular_alarm_events: boolean;
  include_regular_alert_events: boolean;
  include_technical_event_types: boolean;
  include_custom_event_types: boolean;
  event_types: AlertSettingEventType[];
  recipients: number[]; // The IDs of the users
  escalation_enabled: boolean;
  escalation_delay?: null | number;
  escalation_recipients?: null | number[]; // The IDs of the users
}

export type CreateAlertSettingResult = AlertSetting;

export type GetAlertSettingResult = AlertSetting;

export interface UpdateAlertSettingInput {
  name?: string | null;
  zone_id?: number;
  enabled?: boolean;
  schedule?: Array<AlertSettingScheduleRule>;
  include_regular_alarm_events?: boolean;
  include_regular_alert_events?: boolean;
  include_technical_event_types?: boolean;
  include_custom_event_types?: boolean;
  event_types?: AlertSettingEventType[];
  recipients?: number[]; // The IDs of the users
  escalation_enabled: boolean;
  escalation_delay?: null | number;
  escalation_recipients: null | number[]; // The IDs of the users
}

export type UpdateAlertSettingResult = AlertSetting;

export interface CreateZoneData {
  account_id ?: number;
  name : string;
  parent_zone_id ?: number;
  is_housing_unit?: boolean;
}

export interface CreateZoneResult {
  zone: Zone;
  parentZone?: Zone;
}

export interface UpdateZoneData {
  account_id ?: number;
  name : string;
}

export interface UpdateZoneResult {
  zone: Zone;
}

export interface Zone {
  zone_id: number;
  account_id: number;
  name: string;
  parent_zone_id ?: number;
}

export interface MoveZoneData {
  parent_zone_id : number;
}

export interface MoveZoneResult {
  zone: Zone;
}

export interface IpWhitelistRecord {
  zone_ip_whitelist_id: number;
  ip_range_from: string;
  ip_range_to: string;
  comment: string;
  created_at: number;
  updated_at: number;
}

export interface GetIpWhitelistForZoneResult {
  data: Array<IpWhitelistRecord>;
}

export interface IpWhitelistInput {
  ip_range_from: string;
  ip_range_to: string;
  comment?: string;
}

export type AddIpWhitelistData = IpWhitelistInput;

export type AddIpWhitelistResult = GetIpWhitelistForZoneResult;

export type UpdateIpWhitelistData = IpWhitelistInput;

export type UpdateIpWhitelistResult = GetIpWhitelistForZoneResult;

export type GetSensorsResult = ZoneSettingsResult;

export type ZoneSettingsResult = { [P in keyof ZoneSettingsDerived]: { inherited_from: null | { id: number; name: string | null }; inherited_value: null | ZoneSettingsDerived[P]; value: null | ZoneSettingsDerived[P]; }; };;

export type UpdateZoneSettingsResult = ZoneSettingsResult;

export interface ZoneSettingsDerived {
  care_observation_image_detailed: boolean;
  care_observation_image_anonymised: boolean;
  care_event_image_detailed: boolean;
  care_event_image_anonymised: boolean;
  stream_indicator_led: boolean;
  stream_indicator_audio: boolean;
  enable_twoway_audio: boolean;
  enable_oneway_audio: boolean;
  enable_oneway_audio_anonymised: boolean;
  max_call_duration_twoway_audio: number;
  max_call_duration_oneway_audio: number;
  max_call_duration_oneway_audio_anonymised: number;
  alert_reminder_count: number;
  alert_reminder_interval: number;
  reflex_presence_timeout: number;
  observation_stream_timeout: number;
  vkp_enabled: boolean;
  sensor_display_area_format: string;
  sensor_display_name_format: string;
  alarm_audio_key_b: string;
  alarm_audio_key_a: string;
  alert_audio_key_a: string;
  alert_audio_key_b: string;
  enable_behavioral_data: boolean;
  timezone: string;
  enable_behavioral_data_audio: boolean;
  sensor_resolution: '160' | '320' | '640';
}

export type ZoneSettings = { [P in keyof ZoneSettingsDerived]: null | ZoneSettingsDerived[P]; };

export type UpdateZoneSettingsData = Partial<ZoneSettings>;

export interface GetMarkingPixelDetailsInput {
  h: number;
  v: number;
  t?: string; // The _frontend_ type of marking object (used to validate x,y,z results). Need to use the frontend type to differ between chair areas and chairs.
  e?: boolean; // Foor door/bathroom markings, whether the point is to be extended. Used to validate x,y,z results when toggling `e` in the frontend.
}

export interface GetMarkingPixelDetailsResult {
  h: number;
  v: number;
  x: number;
  y: number;
  z: number;
  a: number;
  status?: 'ok' | 'warning' | 'reject'; // 'ok' and undefined are the same.
}

export interface RevalidateMarkingPixelValuesInput {
  x: number;
  y: number;
  z: number;
  a: number;
  t: string;  // The _frontend_ type of marking object (used to validate x,y,z results). Need to use the frontend type to differ between chair areas and chairs.
  e?: boolean; // Foor door/bathroom markings, whether the point is to be extended. Used to validate x,y,z results when toggling `e` in the frontend.
}

export interface RevalidateMarkingPixelValuesResult {
  status: 'ok' | 'warning' | 'reject';
}

export interface MarkingObject {
  item: number;
  modified: number;
  type: MarkingObjectType;
  points: Array<{
    h: number;
    v: number;
    x: number;
    y: number;
    z: number;
    a: number;
    d?: number;
    e?: boolean;
  }>;
  has_warning?: boolean;
}

export interface MarkingObjectsResult {
  simulated?: boolean;
  items: MarkingObject[];
}

export type GetMarkingObjectsResult = MarkingObjectsResult;

export interface MarkingObjectInput {
  type: MarkingObjectType;
  points: Array<{
    h: number;
    v: number;
    d?: number;
    e?: boolean;
  }>;
  has_warning?: boolean; // Whether we should attach a warning label to this marking
}

export type SetMarkingObjectInput = MarkingObjectInput;

export type SetMarkingResult = MarkingObjectsResult;

export type DeleteAllMarkingObjectsResult = MarkingObjectsResult;

export type DeleteMarkingObjectResult = MarkingObjectsResult;

export interface ArgusDetections {
  detections: Array<{
    uid?: string;
    modified?: number;
    item?: number;
    points: Array<{
      h: number;
      v: number;
      d?: number;
    }>;
    type: string | MarkingObjectType; // Can be other types than MarkingObjectType
    conf: number; // Confidence
  }>;
}

export type GetArgusConfigurationResult = ArgusDetections;

export interface GetArgusDisabledCategoriesResult {
  categories: Array<string>;
}

export interface SetArgusDisabledCategoriesInput {
  bed?: boolean;
  chair?: boolean;
  table?: boolean;
  seating_group?: boolean;
  rolator?: boolean;
  wheelchair?: boolean;
  night_stand?: boolean;
}

export type SetArgusDisabledCategoriesResult = GetArgusDisabledCategoriesResult;

export interface SensorArgusConfiguration {
  'argus-enable'?: boolean; // Whether Argus is enabled for this sensor.
  'alert-activation-delay-secs'?: number; // Wait x seconds until furniture moved alert is deactivated (default 120)
}

export type GetSensorArgusConfigurationResult = SensorArgusConfiguration;

export type SetSensorArgusConfigurationInput = Partial<SensorArgusConfiguration>;

export type SetSensorArgusConfigurationResult = SensorArgusConfiguration;

export interface LookupObjectsInput {
  zone_ids?: number[];
  user_ids?: number[];
  sensors: Array<{
    sensor_type: SensorType;
    sensor_id: string;
  }>;
  care_receiver_ids?: number[];
}

export interface LookupObjectsResult {
  zones: Array<{
    id: number;
    name: string | null;
    parent: null | {
      id: number;
      name: string | null;
    };
  }>;
  sensors: Array<{
    id: string;
    name: string;
    zone: null | {
      id: number;
      name: string | null;
      parent: null | {
        id: number;
        name: string | null;
      };
    };
  }>;
  users: Array<{
    id: number;
    username: string;
    first_name: string | null;
    last_name: string | null;
  }>;
  care_receivers: Array<{
    id: number;
    first_name: string | null;
    last_name: string | null;
  }>;
}

export interface GetAuditLogInput {
  sensor_id?: string | null;
  care_receiver_id?: number | null;
  zone_id?: number | null;
  user_id?: number | null;
  from?: string; // YYYY-MM-DD HH:mm:ss
  to?: string; // YYYY-MM-DD HH:mm:ss
  sort_order?: 'asc' | 'desc';
  sort_by?: 'at' | 'sensor_id' | 'user_id' | 'zone_id' | 'care_receiver_id';
  page_size?: number;
  page?: number;
}

export interface AuditLogEntry {
  id: number;
  event: string;
  at: string; // DateTime
  at_user_local: string; // DateTime
  user: null | {
    id: number;
    impersonal_user: boolean;
    username: string;
    display_name: string | null;
    first_name: string | null;
    last_name: string | null;
  };
  role: null | {
    role: string;
    name: string;
    zone: null | {
      id: number;
      name: string;
      parent_zone: null | {
        id: number;
        name: string;
      };
    };
  };
}

export type GetAuditLogResult = Paged<AuditLogEntry>;

export interface GetEventLogInput {
  sensor_id?: string | null;
  care_receiver_id?: number | null;
  zone_id?: number | null;
  user_id?: number | null;
  from?: string; // YYYY-MM-DD HH:mm:ss
  to?: string; // YYYY-MM-DD HH:mm:ss
  sort_order?: 'asc' | 'desc';
  sort_by?: 'time_start';
  page_size?: number;
  page?: number;
}

export interface EventLogEntry {
  event_log_id: number;
  event_id: number;
  received_at: string; // Y-m-d H:i:s
  received_at_utc: string; // Y-m-d H:i:s
  received_at_sensor_local: string; // Y-m-d H:i:s
  received_at_user_local: string; // Y-m-d H:i:s
  sensor_local_timezone?: string | null;
  event_status: string;
  event_type: EventType;
  user: null | {
    id: number;
    impersonal_user: boolean;
    username: string;
    display_name: string | null;
    first_name: string | null;
    last_name: string | null;
  };
  sensor: {
    id: string;
    name: null | string;
    sensor_id: number;
    sensor_type: SensorType;
  };
  zone: null | {
    id: number;
    name: string | null;
    parent: null | {
      id: number;
      name: string | null;
    };
  };
  care_receiver: null | {
    id: number;
    first_name: string | null;
    last_name: string | null;
  };
}

export type GetEventLogResult = Paged<EventLogEntry>;

export interface GetStreamAccessLogInput {
  sensor_id?: string | null;
  care_receiver_id?: number | null;
  zone_id?: number | null;
  user_id?: number | null;
  from?: string; // YYYY-MM-DD HH:mm:ss
  to?: string; // YYYY-MM-DD HH:mm:ss
  sort_order?: 'asc' | 'desc';
  sort_by?: 'time_start';
  page_size?: number;
  page?: number;
}

export interface StreamAccessLogEntry {
  access_log_id: number;
  time_start_utc: string; // DateTime
  time_start_sensor_local: string; // DateTime
  time_start_user_local: string; // DateTime
  time_stop_utc: string; // DateTime
  time_stop_sensor_local: string; // DateTime
  time_stop_user_local: string; // DateTime
  ip_address: string;
  stream_type: null | string;
  audio_property: null | 'oneWay' | 'oneWayAnon' | 'twoWay';
  context: null | { [key: string]: any; };
  user: {
          impersonal_user: true;
          id: number;
          username: string;
          display_name: string | null;
        } | {
          impersonal_user: false;
          id: number;
          username: string;
          first_name: string | null;
          last_name: string | null;
        };
  sensor: {
    id: string;
    name: null | string;
  };
  zone: {
    id: number;
    name: string | null;
    parent: null | {
      id: number;
      name: string | null;
    };
  };
  care_receiver: null | {
    id: number;
    first_name: string | null;
    last_name: string | null;
  };
}

export type GetStreamAccessLogResult = Paged<StreamAccessLogEntry>;

export interface GetAuditChangeLogInput {
  sensor_id?: string | null;
  care_receiver_id?: number | null;
  zone_id?: number | null;
  user_id?: number | null;
  from?: string; // YYYY-MM-DD HH:mm:ss
  to?: string; // YYYY-MM-DD HH:mm:ss
  page_size?: number;
  page?: number;
}

export interface AuditChangedFields {
  [key: string]: string | number | boolean | null | AuditChangedFields;
}

export interface AuditChangeLogEntryChange {
  id: number;
  event: string;
  auditable_id: string;
  auditable_type: string;
  auditable_name?: string | null;
  auditable_group?: string | null;
  auditable_group_id?: string | null;
  auditable_group_subid?: string | null;
  auditable_group_name?: string | null;
  old_values: [] | AuditChangedFields; // Empty array when there are no values
  new_values: [] | AuditChangedFields; // Empty array when there are no values
  tags: unknown;
  auditable_zone: null | {
    id: number;
    name: string | null;
    parent: null | {
      id: number;
      name: string | null;
    };
  };
  zone: null | {
    id: number;
    name: string | null;
    parent: null | {
      id: number;
      name: string | null;
    };
  };
  sensor: null | {
    id: number;
    type: string;
    name: null | string;
  };
  care_receiver: null | {
    id: number;
    first_name: string | null;
    last_name: string | null;
  };
}

export interface AuditChangeLogEntry {
  request_id: string;
  created_at: string;
  updated_at: string;
  created_at_user_local: string;
  updated_at_user_local: string;
  url: null | string;
  ip_address: null | string;
  user_agent: null | string;
  change_by: null | {
    id: number;
    impersonal_user: boolean | null;
    username: string | null;
    display_name: string | null;
    first_name: string | null;
    last_name: string | null;
  };
  changes: AuditChangeLogEntryChange[];
}

export type GetAuditChangeLogResult = Paged<AuditChangeLogEntry>;

export interface GetVkpLogInput {
  sensor_id?: string | null;
  care_receiver_id?: number | null;
  zone_id?: number | null;
  user_id?: number | null;
  from?: string; // YYYY-MM-DD
  to?: string; // YYYY-MM-DD
  sort_order?: 'asc' | 'desc';
  sort_by?: 'at' | 'sensor_id' | 'user_id' | 'zone_id' | 'care_receiver_id';
  page_size?: number;
  page?: number;
}

export interface VkpLogEntry {
  id: number;
  status_code: number;
  error: string | null;
  time: string;
  event_id: number | null;
  added_at_utc: string | null;
  added_at_user_local: string | null;
  note: string | null;
  user: null | {
    id: number;
    impersonal_user: boolean;
    username: string;
    display_name: string | null;
    first_name: string | null;
    last_name: string | null;
  };
  sensor: null | {
    sensor_composite_id: string;
    sensor_id: number;
    sensor_type: SensorType;
    name: null | string;
  };
  zone: null | {
    id: number;
    name: string | null;
    parent: null | {
      id: number;
      name: string | null;
    };
  };
  care_receiver: null | {
    id: number;
    first_name: string | null;
    last_name: string | null;
  };
}

export type GetVkpLogResult = Paged<VkpLogEntry>;

export interface RetryVkpLogInput {
  vkp_log_id: number;
}

export interface GetUserResult {
  user_id: number;
  username: string;
  enabled: boolean;
  first_name: string | null;
  last_name: string | null;
  display_name: string | null;
  email: null | string;
  phone: null | string;
  phone2: null | string;
  national_id_number: null | string;
  has_registered_app: boolean;
  app_reg: string | null;
  mobile_device_id: number;
  impersonal_user: boolean;
  preferred_locale: string;
  only_personal_devices_for_otp: boolean;
  external_idp: boolean;
  api_lock: boolean;
  account: {
     account_id: number;
     account_name: string;
     vkp_available: boolean;
  };
  user_roles: Array<{
    user_role_id: number;
    role: string;
    valid_from: string | null;
    valid_to: string | null;
    lineage_zone_id: number;
    zone_id: number;
    zone_name: string;
    disabled?: boolean;
    disabledReason?: 'outside-valid-time-period' | 'invalid-ip' | 'inactive' | 'other';
  }>;
}

export interface CreateUserRoleInput {
  role: string;
  zone_id: number;
  valid_from: string | null; // yyyy-mm-dd
  valid_to: string | null; // yyyy-mm-dd
}

export type CreateUserNoPasswordOption = undefined;

export type CreateUserPasswordNotifyOption = 'email' | 'sms' | 'none';

export interface CreateUserPassword {
  notify: CreateUserPasswordNotifyOption;
  password: string;
}

export interface CreateUserData {
  username: string;
  password: CreateUserPassword;
  first_name?: string;
  last_name?: string;
  display_name?: string;
  email?: string;
  phone?: string;
  phone2?: string;
  national_id_number?: string;
  preferred_locale?: string;
  only_personal_devices_for_otp?: boolean;
  roles: CreateUserRoleInput[];
}

export interface CreateUserResult {
  user: any;
}

export interface CreateImpersonalUserRoleInput {
  zone_id: number;
  valid_from: string | null; // yyyy-mm-dd
  valid_to: string | null; // yyyy-mm-dd
}

export interface CreateImpersonalUserData {
  display_name?: string;
  phone?: string;
  phone2?: string;
  preferred_locale?: string;
  roles: CreateImpersonalUserRoleInput[];
}

export interface CreateImpersonalUserResult {
  user: any;
}

export interface UpdateUserData {
  username?: string;
  password?: CreateUserPassword;
  first_name?: string;
  last_name?: string;
  display_name?: string;
  email?: string;
  phone?: string;
  phone2?: string;
  national_id_number?: string;
  preferred_locale?: string;
  only_personal_devices_for_otp?: boolean;
  enabled?: boolean;
}

export interface UpdateUserResult {
  user: any;
}

export interface SetUserEnabledInput {
  enabled: boolean;
}

export interface SetUserEnabledResult {
  enabled: boolean;
}

export interface SensorSetting {
  sensor_setting_id: number;
  sensor_composite_id: string;
  zone_id?: number;
  modulation_frequency?: number;
  led_blink?: boolean;
  upgrade_time: string;
  upgrade_channel: string;
  wifi_channels?: string;
  is_enabled?: boolean;
  sensor_algorithm_config_bits: number;
  show_receiving_sensor: boolean;
  audio_twoway_file?: string;
  audio_twoway_volume?: number;
  audio_oneway_file?: string;
  audio_oneway_volume?: number;
  audio_hangup_file?: string;
  audio_hangup_volume?: number;
  audio_stream_start_file?: string;
  audio_stream_start_volume?: number;
  audio_stream_stop_file?: string;
  audio_stream_stop_volume?: number;
  demo_mode: boolean;
}

export type GetSensorSettingsResult = SensorSetting;

export interface UpdateSensorSettingData {
  zone_id?: number;
  modulation_frequency?: number;
  led_blink?: boolean;
  upgrade_time?: string;
  upgrade_channel?: string;
  wifi_channels?: string;
  is_enabled?: boolean;
  sensor_algorithm_config_bits?: number;
  show_receiving_sensor?: boolean;
  audio_twoway_file?: string;
  audio_twoway_volume?: number;
  audio_oneway_file?: string;
  audio_oneway_volume?: number;
  audio_hangup_file?: string;
  audio_hangup_volume?: number;
  audio_stream_start_file?: string;
  audio_stream_start_volume?: number;
  audio_stream_stop_file?: string;
  audio_stream_stop_volume?: number;
  demo_mode?: boolean;
}

export interface UpdateSensorSettingResult {
  sensor_setting: SensorSetting;
}

export interface SendStoppedStreamSignalInput {
  streamId: string | number; // The ID of the stream to stop
}

export interface SuperOverviewData {
  count_sensors: number;
  count_sensors_real: number;
  count_sensors_emulated: number;
  count_sensors_online: number;
  count_sensors_real_online: number;
  count_sensors_emulated_online: number;
  max_roommate_sensor_id: number;
  count_zones: number;
  count_users: number;
  max_user_id: number;
  nhn_certificates: Array<{
     account_code: string;
     account_id: number;
     name: string;
     created_at: string;
     days_left: number;
     lifetime: number;
  }>;
}

export interface SuperGetOfflineSensorsResult {
  data: OfflineSensor[];
}

export interface OfflineSensor {
  sensor_type: SensorType;
  sensor_id: number;
  sensor_name: null | string;
  off_time: string;
  account: null | { account_id: number; account_code: string; name: string; };
  zone: null | { zone_id: number; name: string; };
  is_updating: boolean;
  is_enabled: boolean;
}

export interface SuperGetOfflineSensorsInput {
  last_seen_min?: number;
  last_seen_max?: number;
}

export interface ServerDetails {
  [section: string]: {
    [key: string]: string | number;
  };
}

export interface SuperEndpoints {
  endpoints: Array<{
    httpMethod: string;
    path: string;
    class: string;
    className: string;
    method: string;
    filename: string;
    line: string;
    apiName: string;
    auth: Array<{
      capability: string;
      authorizable: string | null;
    }>;
    code: string;
    docBlock: string;
    tests: Array<string>;
  }>;
  tests: Array<{
    name: string;
    file_name: string;
    file_path: string;
    methods: Array<{
      method: string;
      line_number: number;
      line_number_end: number;
      description: string | null;
      endpoints: string[];
    }>;
  }>;
}

export interface SuperDefaults {
  sensor_settings: {
    [sensorType: string]: {
      [key: string]: number | boolean | string | null;
    };
  };
  sensor_event_settings: {
    [sensorType: string]: {
      [eventType: string]: {
        [key: string]: number | boolean | string | null;
      };
    };
  };
  sensor_event_settings_demo_overrides: {
    [sensorType: string]: {
      [eventType: string]: {
        [key: string]: number | boolean | string | null;
      };
    };
  };
  root_zone_settings: {
    [key: string]: number | boolean | string | null;
  };
}

export interface SuperGetAllViewsResult {
  locales: string[];
  templates: {
    [template: string]: {
      className: string;
      label: string;
      description: string;
      email?: {
        body: {
          [locale: string]: string;
        };
        subject: {
          [locale: string]: string;
        };
      };
      sms?: {
        body: {
          [locale: string]: string;
        };
      };
    };
  };
}

export interface RoleDefinition {
  role: string;
  name: string;
  is_global: boolean;
  capabilities: string[];
}

export interface Capability {
  capability: CapabilityType;
  is_global: boolean;
  name: string;
}

export interface RolesAndCapabilities {
  capabilities: Capability[];
  roles: RoleDefinition[];
}

export type GetPermissionsResult = RolesAndCapabilities;

export type PutPermissionsData = RolesAndCapabilities;

export type PutPermissionsResult = void;

export type SuperVendorGlobalUserRolesResult = Array<{role: string; name: string; is_global: boolean; }>;

export interface SuperRole {
  role: string;
  is_global: boolean;
}

export type SuperCreateRoleData = SuperRole;

export type SuperCreateRoleResult = SuperRole;

export type SuperUpdateRoleData = Partial<SuperRole>;

export type SuperUpdateRoleResult = SuperRole;

export interface SuperZone {
  zone_id: number;
  name: string;
  parent_zone_id: null | number;
  parent_zone_name: null | string;
  account_id: number;
  account_name: string;
}

export type SuperGetZonesResult = Paged<SuperZone>;

export interface SuperUser {
  account: Account;
  user_id: number;
  username: null | string;
  email: null | string;
  phone: null | string;
  display_name: null | string;
  first_name: null | string;
  last_name: null | string;
  national_id_number: null | string;
  preferred_locale: null | string;
  impersonal_user: boolean;
  enable_webrtc: boolean;
  enabled: boolean;
  last_login_at: string;
  view_timeout: number;
  vkp_enabled: boolean;
  voip_debug: boolean;
  api_lock: boolean;
  user_roles?: null | Array<{
    user_role_id: number;
    enabled: boolean;
    role: string;
    is_global: boolean;
    valid_from: null | string;
    valid_to: null | string;
    zone_id: null | number;
    zone?: null | {
      is_housing_unit: boolean;
      name: null | string;
      parent_zone_id: null |  number;
      zone_id: number;
    };
  }>;
}

export type SuperGetUsersInput = WithSortable<WithPagination<{
  account_id?: number;
  vendor_account_id?: number;
  roles?: Array<string>;
}>, 'user_id' | 'username' | 'display_name' | 'first_name' | 'last_name' | 'email'>;

export type SuperGetUsersResult = Paged<SuperUser>;

export type SuperGetUserDetailsResult = SuperUser;

export interface SuperCreateUserData {
  account_id: number;
  username?: null | string;
  first_name?: null | string;
  last_name?: null | string;
  display_name?: null | string;
  email?: null | string;
  phone?: null | string;
  impersonal_user: boolean;
  send_generated_password_by_email?: boolean;
  roles: string[];
}

export interface SuperCreateUserResult {
  user: SuperUser;
}

export interface SuperUpdateUserData {
  account_id: number;
  username?: null | string;
  first_name?: null | string;
  last_name?: null | string;
  display_name?: null | string;
  email?: null | string;
  phone?: null | string;
  impersonal_user: boolean;
  generate_new_password_and_send_by_email?: boolean;
}

export interface SuperUpdateUserResult {
  user: SuperUser;
}

export interface SuperCreateUserRoleData {
  user_id: number;
  role: string;
  zone_id?: null | number;
  enabled?: boolean; // whether the user role is initially enabled
  valid_from?: null | string; // yyyy-mm-dd
  valid_to?: null | string; // yyyy-mm-dd
}

export type SuperCreateUserRoleResult = SuperUser;

export interface SuperUpdateUserRoleData {
  user_id: number;
  role: string;
  zone_id?: null | number;
  enabled?: boolean; // whether the user role is initially enabled
  valid_from?: null | string; // yyyy-mm-dd
  valid_to?: null | string; // yyyy-mm-dd
}

export type SuperUpdateUserRoleResult = SuperUser;

export type SuperGetAccountVendorsResult = Paged<Account>;

export type SuperAssignAccountVendorResult = Account;

export interface SuperCareReceiver {
  care_receiver_id: number;
  first_name: string;
  last_name: string;
  account_id: number;
  account_name: string;
  created_at: string;
  updated_at: string;
}

export type SuperGetCareReceiversResult = Paged<SuperCareReceiver>;

export interface SuperGetCareReceiverDetailsResult {
  care_receiver: SuperCareReceiver;
}

export interface SuperSystemMessage {
  id: number;
  show_from: string | null;
  show_to: string | null;
  heading: string;
  body: string;
  internal_comment: string | null;
  created_at: string;
  created_by: number;
  updated_at: string;
  updated_by: number;
}

export type SuperGetSystemMessagesResult = Paged<SuperSystemMessage>;

export interface SuperCreateSystemMessageData {
  show_from?: string | null;
  show_to?: string | null;
  heading: string;
  body: string;
  internal_comment?: string | null;
}

export type SuperCreateSystemMessageResult = SuperSystemMessage;

export type SuperGetSystemMessageResult = SuperSystemMessage;

export interface SuperUpdateSystemMessageData {
  show_from?: string | null;
  show_to?: string | null;
  heading?: string;
  body?: string;
  internal_comment?: string | null;
}

export type SuperUpdateSystemMessageResult = SuperSystemMessage;

export interface EventInspectorEventHandledByUser {
  id: number;
  impersonal_user: boolean;
  username: string;
  first_name: string;
  last_name: string;
  display_name: string;
}

export interface EventInspectorEventSensor {
  sensor_composite_id: string;
  sensor_id: number;
  sensor_type: SensorType;
  name: null | string;
}

export interface EventInspectorEventZone {
  id: number;
  name: string | null;
  parent: null | {
    id: number;
    name: string;
  };
}

export interface EventInspectorEventCareReceiver {
  id: number;
  first_name: string;
  last_name: string;
}

export interface EventInspectorEvent {
  event_log_id: number;
  event_id: number;
  event_status: string;
  event_type: string;
  received_at: string;
  handled_at: null | string;
  handled_by: null | EventInspectorEventHandledByUser;
  sensor: EventInspectorEventSensor;
  account: null | {
    account_id: number;
    account_code: string;
    name: string;
  };
  zone: null | EventInspectorEventZone;
  care_receiver: null | EventInspectorEventCareReceiver;
}

export type LoadEventInspectorEventsResult = Paged<EventInspectorEvent>;

export interface LoadEventInspectorEventsInput {
  sensor_id?: number;
  sensor_type?: SensorType;
  zone_id?: number;
  account_id?: number;
  event_type?: string;
  from?: string; // yyyy-mm-dd HH:MM:SS
  to?: string; // yyyy-mm-dd HH:MM:SS
}

export interface EventInspectorEventDetails {
  event_id: number;
  event_type: string;
  handled_at: null | string;
  handled_by: null | EventInspectorEventHandledByUser;
  sensor: EventInspectorEventSensor;
  event_logs: Array<{
    event_log_id: number;
    event_status: string;
    received_at: string;
    user: null | EventInspectorEventHandledByUser;
    zone: null | EventInspectorEventZone;
    care_receiver: null | EventInspectorEventCareReceiver;
  }>
  images: null | {
    img_depth: null | string; // Depth image, Base64-encoded JPEG
    img_amp: null | string; // Depth image, Base64-encoded JPEG
    img_raw: null | string; // Depth image, Base64-encoded JPEG
    created_at: null | string; // When the image was stored
    expired_at: null | string; // When the image should expire for clients
  };
}

export type LoadEventInspectorEventDetailsResult = EventInspectorEventDetails;

export interface SuperSendSmsInput {
  to: string;
  message: string;
}

export interface SuperSendEmailInput {
  to: string;
  subject: string;
  body: string;
}

export interface SuperSession {
  id: string;
  user: null | {
    id: number;
    username: string;
    first_name: string;
    last_name: string;
    account: {
      id: number;
      code: string;
      name: string;
    };
  };
  ip_address: string;
  user_agent: string;
  last_activity: string;
  payload: any;
}

export interface SuperGetSessionsResult {
  sessions: SuperSession[];
}

export interface FeatureFlagDefinition {
  feature_flag: string;
  description: string;
}

export interface superGetFeatureFlagDefinitionsResult {
  feature_flag_definitions: FeatureFlagDefinition[];
}

export type FeatureFlagValue = null | string | number | boolean | { [key: string]: FeatureFlagValue; };;

export interface FeatureFlag {
  id: number;
  feature_flag: string;
  negate: boolean;
  user?: null | {
    id: number;
    first_name?: null | string;
    last_name?: null | string;
    username?: null | string;
    email?: null | string;
    account: {
     id: number;
     code: string;
     name: string;
    };
  };
  account?: null | {
    id: number;
    code: string;
    name: string;
  };
  value?: null | number | string;
}

export interface superGetFeatureFlagsResult {
  feature_flags: FeatureFlag[];
}

export type superCreateFeatureFlagResult = FeatureFlag;

export interface superCreateFeatureFlagInput {
  feature_flag: string;
  user_id?: number | null;
  account_id?: number | null;
  negate?: boolean | null;
  value?: number | string | null;
}

export type superUpdateFeatureFlagResult = FeatureFlag;

export interface superUpdateFeatureFlagInput {
  feature_flag: string;
  user_id?: number | null;
  account_id?: number | null;
  value?: number | string | null;
  negate?: boolean | null;
}

export interface AddMobileDevice {
  user_id: number;
}

export type AddMobileDeviceResult = {device_id: string; app_reg: string}|null;

export interface UnasignMobileDeviceFromUser {
  user_id: number;
  mobile_device_id: number;
}

export type UnasignMobileDeviceFromUserResult = string|null;

export interface FullSensorWorriesDataSensor {
  sensor_type: SensorType;
  sensor_id: number;
  sensor_composite_id: string;
  sensor_name: string | null;
  display_name: string | null;
  display_area: string | null;
  is_emulated: boolean;
  is_enabled: boolean;
  is_updating: boolean;
  is_online: boolean;
  upgrade_channel: string;
  upgrade_time: string;
  account: null | { id: number; name: string | null; };
  vendor_account: null | { id: number; name: string | null; };
  zone: null | {
    id: number;
    name: null | string;
    parent: null | {
      id: number;
      name: null | string;
    };
  };
  worries: {
    tunIP: string | null;
    lanIP: string | null;
    wanIP: string | null;
    numberOfUsers: number | null;
    loadAverage: string | null;
    lastBoot: string | null;
    revHw: number | null;
    tDiff: number | null;
    spaceLeft: number | null;
    voltLim: number | null;
    currLim: number | null;
    volt5v: number | null;
    curr5v: number | null;
    volt3v9: number | null;
    curr3v9: number | null;
    voltVin: number | null;
    currVin: number | null;
    cpuTemp: number | null;
    limTemp: number | null;
    timTemp: number | null;
    revsensor: string | null;
    revalgo: string | null;
    revtonet: string | null;
    revalarm: string | null;
    revsnm: string | null;
    revrctc: string | null;
    revss: string | null;
    revsc: string | null;
    revgm: string | null;
    revrm: string | null;
    revaudio: string | null;
    cronRunning: boolean | null;
    distroVersionCurrent: string | null;
    distroVersionAvailable: string | null;
    upgradeAdvice: string | null;
    upgrade_time: string | null;
    upgrade_channel: string | null;
  };
}

export interface GetFullSensorWorriesDataInput {
  account_id?: number;
  vendor_account_id?: number;
  is_online?: boolean;
  is_enabled?: boolean;
  recently_online?: boolean;
  recently_online_threshold?: number;
  emulated?: boolean;
  assigned_to_zone?: boolean; // If set, only select sensors that are/aren't assigned to a zone.
  assigned_to_account?: boolean; // If set, only select sensors that are/aren't assigned to an account.
  zone_id?: number; // If set, only select sensors that aare assigned to this zone or any of its subzones.
}

export interface GetFullSensorWorriesDataResult {
  data: Array<FullSensorWorriesDataSensor>;
}

export interface SensorBillingPeriod {
  sensor_billing_period_id: number;
  sensor_id: number;
  sensor_type: string;
  period_from: string | null;
  period_to: string | null;
  purchase_order: string | null;
  comment: string | null;
  created_at: string;
  updated_at: string;
}

export interface SuperGetSensorBillingPeriodsResult {
  sensor_billing_periods: SensorBillingPeriod[];
}

export interface CreateSensorBillingPeriodData {
  period_from?: string;
  period_to?: string;
  purchase_order?: string;
  comment?: string;
}

export type SuperCreateSensorBillingPeriodResult = SensorBillingPeriod;

export interface UpdateSensorBillingPeriodData {
  period_from?: string;
  period_to?: string;
  purchase_order?: string;
  comment?: string;
}

export type SuperUpdateSensorBillingPeriodResult = SensorBillingPeriod;

export interface GetActiveImageStreamsResult {
  active_image_streams: Array<{
    active_image_stream_id: number;
    user_id: null | number;
    stream_action: string;
    stream_id: null | number;
    sensor_type: SensorType;
    sensor_id: string;
    time_start: string;
    time_touched: string;
    stream_type: null | string;
  }>;
}

export type SuperVendorGetAccountsNewInput = WithSortable<WithPagination<{
  vendor_account_id?: number;
  has_vendors?: boolean | null;
  is_vendor?: boolean | null;
  query?: string;
}>, 'account_id' | 'account_code' | 'name'>;

export type SuperVendorGetAccountsNewResult = Paged<Account>;

export interface SuperVendorGetAccountsInput {
  vendor_account_id?: number;
}

export type SuperVendorGetAccountsResult = Paged<Account>;

export interface SuperVendorCreateAccountData {
  account_code: string;
  name: string;
  default_user_locale?: string;
  disable_all_detailed_images?: boolean;
  require_national_id_for_care_receivers?: boolean;
  disable_national_id_numbers?: boolean;
  password_expiration_days?: null | number;
  log_lifetime_event_images_minutes?: number;
  log_lifetime_change_logs_days?: number;
  log_lifetime_access_logs_days?: number;
  log_lifetime_care_logs_days?: number;
}

export interface SuperVendorCreateAccountResult {
  account: Account;
}

export interface SuperVendorGetAccountDetailsResult {
  account: Account;
}

export interface SuperVendorUpdateAccountData {
  name?: string;
  default_user_locale?: string;
  disable_all_detailed_images?: boolean;
  require_national_id_for_care_receivers?: boolean;
  disable_national_id_numbers?: boolean;
  password_expiration_days?: null | number;
  log_lifetime_event_images_minutes?: number;
  log_lifetime_change_logs_days?: number;
  log_lifetime_access_logs_days?: number;
  log_lifetime_care_logs_days?: number;
  enable_sso?: boolean;
}

export interface SuperVendorUpdateAccountResult {
  account: Account;
}

export interface SuperVendorGetStructureForAccountResult {
  zone: StructureZone;
}

export interface SuperVendorBulkAssignSensorAccountData {
  account_id?: null | number;
  vendor_account_id?: null | number;
  sensors: string;
  sensor_type: SensorType;
}

export interface SuperVendorBulkAssignSensorAccountResult {
  account?: Account | null;
  vendor_account?: Account | null;
  sensor_type: SensorType;
  sensors: number[];
}

export interface SuperVendorSensor {
  id: string;
  sensor_id: number;
  sensor_type: SensorType;
  current_sensor_billing_period?: null | {
    sensor_billing_period_id: number;
    period_from: string; // yyyy-mm-dd
    period_to: string; // yyyy-mm-dd
    purchase_order: null | string;
    comment: null | string;
  };
  is_enabled: boolean;
  is_online: boolean;
  is_updating: boolean;
  on_time: string | null;
  off_time: string | null;
  name: string | null;
  comment: string | null;
  device_id: string | null;
  zone_id: number | null;
  zone_name: string | null;
  parent_zone_id: number | null;
  parent_zone_name: string | null;
  account_id: number | null;
  account_name: string | null;
  vendor_account_id: number | null;
  vendor_account_name: string | null;
  care_receiver_id: number | null;
  care_receiver_first_name: string | null;
  care_receiver_last_name: string | null;
  reg: string; // timestamp
  net: number; // SET OF onLan, onWan, onWifi, onTun, onSTun
  lanIP: string;
  tunIP: string;
  wanIP: string;
  cpuTemp: number;
  limTemp: number;
  timTemp: number;
  lastBoot: number; // set('pwron','reset','wd','net','crash-sensor','crash-toNet','crash-alarm','crash-rctc','crash-gMem','crash-snm'
  uptime: string; // char
  SSID: string; // char(32)
  spaceLeft: number; // int(11)
  voltLim: number; // int(11)
  currLim: number; // int(11)
  volt5v: number; // int(11)
  curr5v: number; // int(11)
  volt3v9: number; // int(11)
  curr3v9: number; // int(11)
  voltVin: number; // int(11)
  currVin: number; // int(11)
  revHw: number; // int(11)
  revsensor: number; // int(11)
  revtonet: number; // int(11)
  revalarm: number; // int(11)
  revsnm: number; // int(11)
  revss: number; // int(11)
  revsc: number; // int(11)
  revgm: number; // int(11)
  revalgo: number; // int(11)
  revrctc: number; // int(11)
  revrm: number; // bigint
  sTime: string; // timestamp
  revaudio: number; // bigint
  service_access: number; // bigint
  wifiStrength: number; // int(10) unsigned
  breadcrumbs:Array<{ zone_id: number; name: string; is_housing_unit: boolean }>;
}

export type SuperVendorGetSensorsResult = Paged<SuperVendorSensor>;

export type SuperVendorGetSensorsInput = WithSortable<WithPagination<{
  onlySensorsWithoutAccount?: boolean;
  account_id?: number;
  vendor_account_id?: number;
  zone_id?: number;
  sensor_type?: 'climax' | 'roommate';
  assigned_to_zone?: boolean;
  status?: 'online' | 'offline';
  query?: string; // Sensor IDs, e.g. '100, 200-220'
}>, 'sensor_id' | 'sensor_type' | 'display_area' | 'display_name' | 'name'>;

export interface SuperVendorGetSensorResult {
  sensor: SuperVendorSensor;
 markingObjects: MarkingObject[];
}

export interface SuperVendorUpdateSensorInput {
  zone_id?: number | null;
  account_id?: number | null;
  vendor_account_id?: number | null;
  name?: string;
  device_id?: string;
  comment?: string;
  is_enabled?: boolean;
}

export interface SuperUpdateSensorsResult {
  sensor: SuperVendorSensor | null;
}

export interface SensorStatusLogEntry {
  id: number;
  reg: string | null;
  net: string | null;
  lanIP: string | null;
  tunIP: string | null;
  wanIP: string | null;
  cpuTemp: number | null;
  limTemp: number | null;
  timTemp: number | null;
  lastBoot: string | null;
  uptime: string | null;
  SSID: string | null;
  spaceLeft: number | null;
  voltLim: number | null;
  currLim: number | null;
  volt5v: number | null;
  curr5v: number | null;
  volt3v9: number | null;
  curr3v9: number | null;
  voltVin: number | null;
  currVin: number | null;
  revHw: string | null;
  revsensor: string | null;
  revtonet: string | null;
  revalarm: string | null;
  revsnm: string | null;
  revss: string | null;
  revsc: string | null;
  revgm: string | null;
  revalgo: string | null;
  revrctc: string | null;
  revrm: string | null;
  revaudio: string | null;
  sTime: string | null;
  service_access: number | null;
  wifiStrength: number | null;
  cronRunning: boolean | null;
  distroVersionCurrent: string | null;
  distroVersionAvailable: string | null;
  upgradeAdvice: string | null;
}

export interface GetSensorStatusLogResult {
  data: Array<SensorStatusLogEntry>;
}

export interface DuplicateSensorSettingsInput {
  sensor_type: SensorType; // The sensor type of the source and destination sensors.
  source_id: string | number; // The ID of the source sensor.
  destination_id: string | number; // The ID of the destination sensor
  overwrite?: boolean; // Whether to overwrite the destination sensor if it already exists.
}

export interface ImpersonateAccountData {
  account_id: number;
  redirect_url?: string | null; // The URL to redirect to after impersonation is stopped
}

export interface ImpersonateAccountResult {
  user: User;
}

export interface ImpersonateUserRolesData {
  user_id: number;
  redirect_url?: string | null; // The URL to redirect to after impersonation is stopped
}

export interface ImpersonateUserRolesResult {
  user: User;
}

export interface SuperSearchInput {
  query: string;
  types?: Array<'sensors' | 'users' | 'accounts'>;
}

export interface SuperSearchSensor {
  sensor_id: number;
  sensor_type: SensorType;
  zone_id: number | null;
  account_id: number | null;
  name: string | null;
  on_time: string | null;
  off_time: string | null;
  off_warning_sent: boolean | null;
  is_updating: boolean | null;
  device_id: string | null;
  comment: string | null;
  is_enabled: boolean | null;
  created_at: string | null;
  updated_at: string | null;
  demo_mode: boolean | null;
  vendor_account_id: number | null;
  is_emulated: boolean | null;
  show_receiving_sensor: boolean | null;
  display_name: string | null;
  display_area: string | null;
  is_online: boolean | null;
  sensor_composite_id: string | null;
}

export interface SuperSearchUser {
  user_id: number;
  username: string;
  email: string | null;
  phone: string | null;
  first_name: string | null;
  last_name: string | null;
  preferred_locale: string | null;
  national_id_number: string | null;
  impersonal_user: boolean;
  display_name: string | null;
  view_timeout: number | null;
  voip_debug: boolean;
  enable_webrtc: boolean;
  vkp_enabled: boolean;
  enabled: boolean;
  last_login_at: string;
  only_personal_devices_for_otp: boolean;
  api_lock: boolean;
  account: {
     account_id: number;
     account_code:string;
     name: string
     root_zone: null | {
         name: string;
         zone_id: number
     }
  };
  user_roles: Array<{
    role: string;
    zone?: {
      name: string;
      zone_id: number;
    };
  }>;
}

export interface SuperSearchResults {
  results: Array<{
    type: 'sensor';
    entry: SuperSearchSensor;
  } | {
    type: 'user';
    entry: SuperSearchUser;
  } | {
    type: 'account';
    entry: Account;
  }>;
}

export interface SuperVendorSetupIntegrationInput {
  parent_zone_id: number;
  zone_name: string;
  username: string;
  password: string;
}

export interface SuperVendorSetupIntegrationResult {
  
}

export interface IntegrationUser {
  user_id: number;
  username: string;
  zone_id: number;
  zone_name: string;
  account: string;
  subzones_count: number;
  sensors_count: number;
  can_delete: boolean;
  cannot_delete_reason: string;
}

export type SuperVendorListIntegrationUsersResult = Array<IntegrationUser>;

export interface SuperVendorIntegrationChangePasswordInput {
  password: string;
}

export interface SuperVendorIntegrationChangePasswordOutput {
  user: any;
}

export interface GetIdentityProviders {
  identity_providers: Array<{
    id: number;
    account_id: number;
    client_id: string;
    client_secret: string;
    azure_tenant_id: string;
    idp_provider: string;
    user_matching_strategy: string;
  }>;
}

export interface GetIdentityProvider {
  identity_provider: {
    id: number;
    account_id: number;
    client_id: string;
    client_secret: string;
    azure_tenant_id: string;
    idp_provider: string;
    user_matching_strategy: "none" | "email" | "username";
}
}

export interface IdentityProviderData {
  client_id: string;
  client_secret: string;
  azure_tenant_id: string;
}

export interface IdentityProvider {
  account: Account;
}

export interface IdentityProviderResult {
  identity_provider: AzureUser;
}

export interface GetImageCollectionSettingsResult {
  settings: Array<{
    setting_key: string;
    setting_value: string;
  }>;
}

export interface UpdateImageCollectionSettingsInput {
  settings: Array<{
    setting_key: string;
    setting_value: string;
  }>;
}

export interface UpdateImageCollectionSettingsResult {
  settings: Array<{
    setting_key: string;
    setting_value: string;
  }>;
}

export interface GetSensorConfigurationResult {
  configuration: {
    [key: string]: string;
  };
}

export interface UpdateSensorConfigurationInput {
  configuration: {
    [key: string]: string;
  };
}

export interface UpdateSensorConfigurationResult {
  configuration: {
    [key: string]: string;
  };
}

export interface GetWholeSensorConfigurationResult {
  configuration: {
    [namespace: string]: {
      [key: string]: string;
    };
  };
}

export interface UpdateWholeSensorConfigurationInput {
  configuration: {
    [namespace: string]: {
      [key: string]: string;
    };
  };
}

export interface UpdateWholeSensorConfigurationResult {
  configuration: {
    [namespace: string]: {
      [key: string]: string;
    };
  };
}

export interface GetEntireGlobalConfigurationResult {
  configuration: {
    [sensorCompositeId: string]: {
      [namespace: string]: {
        [key: string]: string;
      };
    };
  };
  sensors: {
    [sensorCompositeId: string]: {
      sensor_composite_id: string;
      sensor_id: number;
      sensor_type: SensorType;
      name: null | string;
      account: null | { id: number; name: string; code: string; };
      zone: null | { id: number; name: string; api_lock: boolean; };
      zone_display_path: string;
      on_time: string;
      off_time: string;
      is_online: boolean;
      is_updating: boolean;
      is_enabled: boolean;
      demo_mode: boolean;
    };
  };
}

export const login = async (data: LoginData) => apiRequest<LoginData, LoginResult>('post', '/api/auth/login', data);
export const logout = async () => apiRequest<undefined, void>('post', '/api/auth/logout', undefined);
export const me = async () => apiRequest<undefined, MeResult>('get', '/api/auth/me', undefined);
export const getSystemMessages = async () => apiRequest<undefined, SystemMessages>('get', '/api/auth/system-messages', undefined);
export const requestOtpCode = async (data: requestOtpCodeInput) => apiRequest<requestOtpCodeInput, void>('post', '/api/auth/otp/request', data);
export const otpSubmit = async (data: OtpSubmitData) => apiRequest<OtpSubmitData, void>('post', '/api/auth/otp/submit', data);
export const selectRole = async (data: SelectRoleInput) => apiRequest<SelectRoleInput, SelectRoleResult>('post', '/api/auth/select-role', data);
export const forgotPassword = async (data: ForgotPasswordData) => apiRequest<ForgotPasswordData, void>('post', '/api/auth/password-reset/send-request', data);
export const resetPassword = async (token: string, data: ResetPasswordData) => apiRequest<ResetPasswordData, void>('post', `/api/auth/password-reset/update/${encodeURIComponent(token)}`, data);
export const changePassword = async (data: ChangePasswordData) => apiRequest<ChangePasswordData, ChangePasswordResult>('post', '/api/auth/change-password', data);
export const updateProfile = async (data: UpdateProfileInput) => apiRequest<UpdateProfileInput, UpdateProfileOutput>('patch', '/api/auth/profile', data);
export const getAuthUrl = async (accountCode: string) => apiRequest<undefined, GetAuthUrl>('get', `/api/auth/${encodeURIComponent(accountCode)}/azure/url`, undefined);
export const attemptLogin = async (accountCode: string, data: AzureData) => apiRequest<AzureData, AzureResult>('post', `/api/auth/${encodeURIComponent(accountCode)}/azure/login`, data);
export const stopImpersonation = async () => apiRequest<undefined, StopImpersonationResult>('post', '/api/auth/Impersonation::stopImpersonation', undefined);
export const careGetStatus = async () => apiRequest<undefined, CareStatus>('get', '/api/care/status', undefined);
export const getStatusToken = async () => apiRequest<undefined, GetStatusTokenResult>('post', '/api/care/status/status-token', undefined);
export const careGetWebRtcConfig = async () => apiRequest<undefined, CareWebRtcConfig>('get', '/api/care/sip/config', undefined);
export const careKeepStreaming = async (data: CareKeepStreamingInput) => apiRequest<CareKeepStreamingInput, void>('post', '/api/care/stream/keepstreaming', data);
export const loadImage = async () => apiRequest<undefined, void>('get', '/api/care/stream/image', undefined);
export const getStreamToken = async (data: GetStreamTokenInput) => apiRequest<GetStreamTokenInput, GetStreamTokenResult>('post', '/api/care/stream/stream-token', data);
export const addObservationNote = async (data: AddObservationNoteInput) => apiRequest<AddObservationNoteInput, void>('post', '/api/care/stream/addObservationNote', data);
export const markEventAsHandled = async (data: MarkEventAsHandledInput) => apiRequest<MarkEventAsHandledInput, void>('post', '/api/care/event/markEventAsHandled', data);
export const careGetEventSummary = async (eventId: number) => apiRequest<undefined, CareGetEventSummaryResult>('get', `/api/care/event/${encodeURIComponent(eventId)}`, undefined);
export const careGetInfoSummaryForSensor = async (sensorCompositeId: string) => apiRequest<undefined, CareGetInfoSummaryForSensorResult>('get', `/api/care/sensor/${encodeURIComponent(sensorCompositeId)}`, undefined);
export const careGetBehaviorData = async (sensorCompositeId: string) => apiRequest<undefined, CareGetBehaviorDataResult>('post', `/api/care/get-behavior-data/${encodeURIComponent(sensorCompositeId)}`, undefined);
export const getDashboardOverview = async (data: DashboardOverviewInput) => apiRequest<DashboardOverviewInput, DashboardOverviewResult>('post', '/api/dashboard/getDashboardOverview', data);
export const getDashboardEventsLastPeriod = async (data: DashboardEventsLastPeriodInput) => apiRequest<DashboardEventsLastPeriodInput, DashboardEventsLastPeriodResult>('post', '/api/dashboard/getDashboardEventsLastPeriod', data);
export const getEventByHash = async (hash: string) => apiRequest<undefined, GetEventByHashResult>('get', `/api/care/event/hash:${encodeURIComponent(hash)}`, undefined);
export const getStructure = async () => apiRequest<undefined, GetStructureResult>('get', '/api/structure', undefined);
export const getEventTypesForSensor = async (sensorCompositeId: string) => apiRequest<undefined, EventTypesForSensorResult>('get', `/api/sensor/${encodeURIComponent(sensorCompositeId)}/event-types`, undefined);
export const simulateEvent = async (sensorCompositeId: string, data: SimulateEventInput) => apiRequest<SimulateEventInput, void>('post', `/api/sensor/${encodeURIComponent(sensorCompositeId)}/simulate-event`, data);
export const resetAlgorithm = async (sensorCompositeId: string) => apiRequest<undefined, void>('post', `/api/sensor/${encodeURIComponent(sensorCompositeId)}/reset-algorithm`, undefined);
export const setSensorPresence = async (sensorCompositeId: string, data: PresenceInput) => apiRequest<PresenceInput, void>('post', `/api/sensor/${encodeURIComponent(sensorCompositeId)}/set-presence`, data);
export const clearSensorPresence = async (sensorCompositeId: string) => apiRequest<undefined, void>('post', `/api/sensor/${encodeURIComponent(sensorCompositeId)}/clear-presence`, undefined);
export const restartSensor = async (sensorCompositeId: string) => apiRequest<undefined, void>('post', `/api/sensor/${encodeURIComponent(sensorCompositeId)}/restart`, undefined);
export const rebootSensor = async (sensorCompositeId: string) => apiRequest<undefined, void>('post', `/api/sensor/${encodeURIComponent(sensorCompositeId)}/reboot`, undefined);
export const sendUpdateSignal = async (sensorCompositeId: string) => apiRequest<undefined, void>('post', `/api/sensor/${encodeURIComponent(sensorCompositeId)}/update`, undefined);
export const sendShortStatusSignal = async (sensorCompositeId: string) => apiRequest<undefined, void>('post', `/api/sensor/${encodeURIComponent(sensorCompositeId)}/getShortStatus`, undefined);
export const sendConfSignal = async (sensorCompositeId: string) => apiRequest<undefined, void>('post', `/api/sensor/${encodeURIComponent(sensorCompositeId)}/conf`, undefined);
export const fullUpgrade = async (sensorCompositeId: string) => apiRequest<undefined, void>('post', `/api/sensor/${encodeURIComponent(sensorCompositeId)}/full-upgrade`, undefined);
export const moveSensorToServer = async (sensorCompositeId: string, data: MoveSensorToServerInput) => apiRequest<MoveSensorToServerInput, void>('post', `/api/sensor/${encodeURIComponent(sensorCompositeId)}/move-to-server`, data);
export const distroUpgrade = async (sensorCompositeId: string) => apiRequest<undefined, void>('post', `/api/sensor/${encodeURIComponent(sensorCompositeId)}/distro-upgrade`, undefined);
export const searchUsers = async (data: SearchUsersInput) => apiRequest<SearchUsersInput, SearchUsersResult>('post', '/api/users/search', data);
export const getUnassignedSensorsAvailableForUser = async () => apiRequest<undefined, GetUnassignedClimaxResult>('get', '/api/other-available-sensors', undefined);
export const getAccountSettings = async () => apiRequest<undefined, GetAccountSettingsResult>('get', '/api/account/settings', undefined);
export const updateAccountSettings = async (data: UpdateAccountSettingsData) => apiRequest<UpdateAccountSettingsData, UpdateAccountSettingsResult>('patch', '/api/account/settings', data);
export const getCareReceiversByZone = async (zoneId: number) => apiRequest<undefined, GetCareReceiversByZoneResult>('get', `/api/zone/${encodeURIComponent(zoneId)}/care-receivers`, undefined);
export const getCareReceiverDetails = async (careReceiverId: number) => apiRequest<undefined, GetCareReceiverDetailsResult>('get', `/api/care-receivers/${encodeURIComponent(careReceiverId)}`, undefined);
export const deleteCareReceiver = async (careReceiverId: number) => apiRequest<undefined, void>('delete', `/api/care-receivers/${encodeURIComponent(careReceiverId)}`, undefined);
export const searchCareReceivers = async (data: SearchCareReceiversInput) => apiRequest<SearchCareReceiversInput, SearchCareReceiversResult>('post', '/api/care-receivers/search', data);
export const findCareReceiverByNationalId = async (data: FindCareReceiverByNationalIdInput) => apiRequest<FindCareReceiverByNationalIdInput, FindCareReceiverByNationalIdResult>('post', '/api/care-receivers/find', data);
export const addCareReceiver = async (data: AddCareReceiverInput) => apiRequest<AddCareReceiverInput, AddCareReceiverResult>('post', '/api/care-receivers', data);
export const updateCareReceiver = async (careReceiverId: number, data: UpdateCareReceiverData) => apiRequest<UpdateCareReceiverData, UpdateCareReceiverResult>('patch', `/api/care-receivers/${encodeURIComponent(careReceiverId)}`, data);
export const getCareReceiversForZoneXlsx = async (zoneId: number) => apiRequest<undefined, void>('get', `/api/care-receivers/${encodeURIComponent(zoneId)}/care-receivers.xlsx`, undefined);
export const getAvailableRoles = async () => apiRequest<undefined, GetAvailableRolesResult>('get', '/api/permissions/available-roles', undefined);
export const createUserRoles = async (data: CreateUserRolesInput) => apiRequest<CreateUserRolesInput, CreateUserRolesResult>('post', '/api/user-roles', data);
export const updateUserRoles = async (userRoleId: number, data: UpdateUserRolesInput) => apiRequest<UpdateUserRolesInput, UpdateUserRolesResult>('patch', `/api/user-roles/${encodeURIComponent(userRoleId)}`, data);
export const deleteUserRole = async (userRoleId: number) => apiRequest<undefined, void>('delete', `/api/user-roles/${encodeURIComponent(userRoleId)}`, undefined);
export const getUserRolesForZone = async (zoneId: number) => apiRequest<undefined, GetUserRolesForZoneResult>('get', `/api/zones/${encodeURIComponent(zoneId)}/user-roles`, undefined);
export const getSortedUserRolesForZone = async (zoneId: number) => apiRequest<undefined, GetSortedUserRolesForZoneResult>('get', `/api/zones/${encodeURIComponent(zoneId)}/sorted-user-roles`, undefined);
export const getUserRolesForZoneXlsx = async (zoneId: number) => apiRequest<undefined, void>('get', `/api/zones/${encodeURIComponent(zoneId)}/user-roles.xlsx`, undefined);
export const addUserToMobileDevice = async (data: AddUserToMobileDeviceInput) => apiRequest<AddUserToMobileDeviceInput, void>('post', '/api/mobile-devices/add', data);
export const unassignUserFromMobileDevice = async (data: UnassignUserFromMobileDeviceInput) => apiRequest<UnassignUserFromMobileDeviceInput, void>('post', '/api/mobile-devices/unassign', data);
export const getSensorDetails = async (sensorCompositeId: string) => apiRequest<undefined, GetSensorDetailsResult>('get', `/api/sensors/${encodeURIComponent(sensorCompositeId)}`, undefined);
export const getSensorsForZone = async (zoneId: number) => apiRequest<undefined, GetSensorsForZoneResult>('get', `/api/zones/${encodeURIComponent(zoneId)}/sensors`, undefined);
export const getEventSettingsBySensorId = async (sensorCompositeId: string) => apiRequest<undefined, GetEventSettingsBySensorIdResult>('get', `/api/sensors/${encodeURIComponent(sensorCompositeId)}/event-settings`, undefined);
export const updateEventSettingsBySensorId = async (eventType: string, sensorCompositeId: string, data: UpdateEventSettingsBySensorIdInput) => apiRequest<UpdateEventSettingsBySensorIdInput, UpdateEventSettingsBySensorIdResult>('patch', `/api/sensors/${encodeURIComponent(sensorCompositeId)}/event-settings/${encodeURIComponent(eventType)}`, data);
export const getEventSettingsByZoneId = async (zoneId: number) => apiRequest<undefined, GetEventSettingsByZoneIdResult>('get', `/api/zones/${encodeURIComponent(zoneId)}/event-settings`, undefined);
export const updateEventSettingsByZoneId = async (eventType: string, zoneId: number, data: UpdateEventSettingsByZoneIdInput) => apiRequest<UpdateEventSettingsByZoneIdInput, UpdateEventSettingsByZoneIdResult>('patch', `/api/zones/${encodeURIComponent(zoneId)}/event-settings/${encodeURIComponent(eventType)}`, data);
export const updateSensors = async (sensorCompositeId: string, data: UpdateSensorsInput) => apiRequest<UpdateSensorsInput, UpdateSensorsResult>('patch', `/api/sensors/${encodeURIComponent(sensorCompositeId)}`, data);
export const getAlertsForSensor = async (sensorCompositeId: string) => apiRequest<undefined, GetAlertsForSensorResult>('get', `/api/sensors/${encodeURIComponent(sensorCompositeId)}/alerts`, undefined);
export const getSensorsForZoneXlsx = async (zoneId: number) => apiRequest<undefined, void>('get', `/api/sensors/${encodeURIComponent(zoneId)}/sensors.xlsx`, undefined);
export const getAlertSettings = async (zoneId: number) => apiRequest<undefined, GetAlertSettingsResult>('get', `/api/zones/${encodeURIComponent(zoneId)}/alert-settings`, undefined);
export const createAlertSetting = async (zoneId: number, data: CreateAlertSettingInput) => apiRequest<CreateAlertSettingInput, CreateAlertSettingResult>('post', `/api/zones/${encodeURIComponent(zoneId)}/alert-settings`, data);
export const getAlertSetting = async (alertSettingId: number) => apiRequest<undefined, GetAlertSettingResult>('get', `/api/alert-settings/${encodeURIComponent(alertSettingId)}`, undefined);
export const updateAlertSetting = async (alertSettingId: number, data: UpdateAlertSettingInput) => apiRequest<UpdateAlertSettingInput, UpdateAlertSettingResult>('patch', `/api/alert-settings/${encodeURIComponent(alertSettingId)}`, data);
export const deleteAlertSetting = async (alertSettingId: number) => apiRequest<undefined, void>('delete', `/api/alert-settings/${encodeURIComponent(alertSettingId)}`, undefined);
export const createZone = async (data: CreateZoneData) => apiRequest<CreateZoneData, CreateZoneResult>('post', '/api/zones', data);
export const updateZone = async (zoneId: number, data: UpdateZoneData) => apiRequest<UpdateZoneData, UpdateZoneResult>('patch', `/api/zones/${encodeURIComponent(zoneId)}`, data);
export const moveZone = async (zoneId: number, data: MoveZoneData) => apiRequest<MoveZoneData, MoveZoneResult>('post', `/api/zones/${encodeURIComponent(zoneId)}/move`, data);
export const deleteZone = async (zoneId: number) => apiRequest<undefined, void>('delete', `/api/zones/${encodeURIComponent(zoneId)}`, undefined);
export const manageGetIpWhitelistForZone = async (zoneId: number) => apiRequest<undefined, GetIpWhitelistForZoneResult>('get', `/api/zones/${encodeURIComponent(zoneId)}/ip-whitelist`, undefined);
export const manageAddIpWhitelist = async (zoneId: number, data: AddIpWhitelistData) => apiRequest<AddIpWhitelistData, AddIpWhitelistResult>('post', `/api/zones/${encodeURIComponent(zoneId)}/ip-whitelist`, data);
export const manageUpdateIpWhitelist = async (zoneIpWhitelistId: number, zoneId: number, data: UpdateIpWhitelistData) => apiRequest<UpdateIpWhitelistData, UpdateIpWhitelistResult>('put', `/api/zones/${encodeURIComponent(zoneId)}/ip-whitelist/${encodeURIComponent(zoneIpWhitelistId)}`, data);
export const manageDeleteIpWhitelistFromZone = async (zoneIpWhitelistId: number, zoneId: number) => apiRequest<undefined, void>('delete', `/api/zones/${encodeURIComponent(zoneId)}/ip-whitelist/${encodeURIComponent(zoneIpWhitelistId)}`, undefined);
export const getZoneSettings = async (zoneId: number) => apiRequest<undefined, GetSensorsResult>('get', `/api/zones/${encodeURIComponent(zoneId)}/settings`, undefined);
export const updateZoneSettings = async (zoneId: number, data: UpdateZoneSettingsData) => apiRequest<UpdateZoneSettingsData, UpdateZoneSettingsResult>('patch', `/api/zones/${encodeURIComponent(zoneId)}/settings`, data);
export const getMarkingPixelDetails = async (sensorCompositeId: string, data: GetMarkingPixelDetailsInput) => apiRequest<GetMarkingPixelDetailsInput, GetMarkingPixelDetailsResult>('post', `/api/sensors/${encodeURIComponent(sensorCompositeId)}/marking/px`, data);
export const revalidateMarkingPixelValues = async (sensorCompositeId: string, data: RevalidateMarkingPixelValuesInput) => apiRequest<RevalidateMarkingPixelValuesInput, RevalidateMarkingPixelValuesResult>('post', `/api/sensors/${encodeURIComponent(sensorCompositeId)}/marking/revalidate`, data);
export const getMarkingObjects = async (sensorCompositeId: string) => apiRequest<undefined, GetMarkingObjectsResult>('get', `/api/sensors/${encodeURIComponent(sensorCompositeId)}/marking`, undefined);
export const setMarkingObject = async (itemIndex: number, sensorCompositeId: string, data: SetMarkingObjectInput) => apiRequest<SetMarkingObjectInput, SetMarkingResult>('put', `/api/sensors/${encodeURIComponent(sensorCompositeId)}/marking/${encodeURIComponent(itemIndex)}`, data);
export const deleteAllMarkingObjects = async (sensorCompositeId: string) => apiRequest<undefined, DeleteAllMarkingObjectsResult>('delete', `/api/sensors/${encodeURIComponent(sensorCompositeId)}/marking`, undefined);
export const deleteMarkingObject = async (itemIndex: number, sensorCompositeId: string) => apiRequest<undefined, DeleteMarkingObjectResult>('delete', `/api/sensors/${encodeURIComponent(sensorCompositeId)}/marking/${encodeURIComponent(itemIndex)}`, undefined);
export const loadSensorImage = async (sensorCompositeId: string) => apiRequest<undefined, void>('get', `/api/sensors/${encodeURIComponent(sensorCompositeId)}/marking/image`, undefined);
export const resetSensorImage = async (sensorCompositeId: string) => apiRequest<undefined, void>('post', `/api/sensors/${encodeURIComponent(sensorCompositeId)}/marking/reset`, undefined);
export const getArgusConfiguration = async (sensorCompositeId: string) => apiRequest<undefined, GetArgusConfigurationResult>('get', `/api/sensors/${encodeURIComponent(sensorCompositeId)}/marking/argus`, undefined);
export const getArgusDisabledCategories = async (sensorCompositeId: string) => apiRequest<undefined, GetArgusDisabledCategoriesResult>('get', `/api/sensors/${encodeURIComponent(sensorCompositeId)}/marking/argus-disabled-categories`, undefined);
export const setArgusDisabledCategories = async (sensorCompositeId: string, data: SetArgusDisabledCategoriesInput) => apiRequest<SetArgusDisabledCategoriesInput, SetArgusDisabledCategoriesResult>('patch', `/api/sensors/${encodeURIComponent(sensorCompositeId)}/marking/argus-disabled-categories`, data);
export const getSensorArgusConfiguration = async (sensorCompositeId: string) => apiRequest<undefined, GetSensorArgusConfigurationResult>('get', `/api/sensors/${encodeURIComponent(sensorCompositeId)}/argus/configuration`, undefined);
export const setSensorArgusConfiguration = async (sensorCompositeId: string, data: SetSensorArgusConfigurationInput) => apiRequest<SetSensorArgusConfigurationInput, SetSensorArgusConfigurationResult>('patch', `/api/sensors/${encodeURIComponent(sensorCompositeId)}/argus/configuration`, data);
export const lookupObjects = async (data: LookupObjectsInput) => apiRequest<LookupObjectsInput, LookupObjectsResult>('post', '/api/log/lookup', data);
export const getAuditLog = async (data: GetAuditLogInput) => apiRequest<GetAuditLogInput, GetAuditLogResult>('post', '/api/log/audit', data);
export const getEventLog = async (data: GetEventLogInput) => apiRequest<GetEventLogInput, GetEventLogResult>('post', '/api/log/event', data);
export const getStreamAccessLog = async (data: GetStreamAccessLogInput) => apiRequest<GetStreamAccessLogInput, GetStreamAccessLogResult>('post', '/api/log/stream-access', data);
export const getAuditChangeLog = async (data: GetAuditChangeLogInput) => apiRequest<GetAuditChangeLogInput, GetAuditChangeLogResult>('post', '/api/audit/change', data);
export const getVkpLog = async (data: GetVkpLogInput) => apiRequest<GetVkpLogInput, GetVkpLogResult>('post', '/api/log/vkp', data);
export const retryVkpLog = async (data: RetryVkpLogInput) => apiRequest<RetryVkpLogInput, void>('post', '/api/vkp/retry', data);
export const getUser = async (userId: number) => apiRequest<undefined, GetUserResult>('get', `/api/users/${encodeURIComponent(userId)}`, undefined);
export const createUser = async (data: CreateUserData) => apiRequest<CreateUserData, CreateUserResult>('post', '/api/users', data);
export const createImpersonalUser = async (data: CreateImpersonalUserData) => apiRequest<CreateImpersonalUserData, CreateImpersonalUserResult>('post', '/api/users-impersonal', data);
export const updateUser = async (userId: number, data: UpdateUserData) => apiRequest<UpdateUserData, UpdateUserResult>('patch', `/api/users/${encodeURIComponent(userId)}`, data);
export const setUserEnabled = async (userId: number, data: SetUserEnabledInput) => apiRequest<SetUserEnabledInput, SetUserEnabledResult>('patch', `/api/users/${encodeURIComponent(userId)}/enabled`, data);
export const deleteUser = async (userId: number) => apiRequest<undefined, void>('delete', `/api/users/${encodeURIComponent(userId)}`, undefined);
export const getSensorSettings = async (sensorCompositeId: string) => apiRequest<undefined, GetSensorSettingsResult>('get', `/api/sensors/${encodeURIComponent(sensorCompositeId)}/settings`, undefined);
export const updateSensorSetting = async (sensorCompositeId: string, data: UpdateSensorSettingData) => apiRequest<UpdateSensorSettingData, UpdateSensorSettingResult>('patch', `/api/sensors/${encodeURIComponent(sensorCompositeId)}/settings`, data);
export const getEventLogForXlsx = async (to: string, from: string, zoneId: number) => apiRequest<undefined, void>('get', `/api/export/event-logs/${encodeURIComponent(zoneId)}/${encodeURIComponent(from)}/${encodeURIComponent(to)}`, undefined);
export const getAuditForXlsx = async (to: string, from: string, zoneId: string) => apiRequest<undefined, void>('get', `/api/export/audit-logs/${encodeURIComponent(zoneId)}/${encodeURIComponent(from)}/${encodeURIComponent(to)}`, undefined);
export const getStreamAccessForXlsx = async (to: string, from: string, zoneId: number) => apiRequest<undefined, void>('get', `/api/export/stream-logs/${encodeURIComponent(zoneId)}/${encodeURIComponent(from)}/${encodeURIComponent(to)}`, undefined);
export const images = async (imageType: string, sensorCompositeId: string) => apiRequest<undefined, void>('get', `/api/stream/images/${encodeURIComponent(sensorCompositeId)}/${encodeURIComponent(imageType)}/{audioProperty?}`, undefined);
export const mjpegImages = async (imageType: string, sensorCompositeId: string) => apiRequest<undefined, void>('get', `/api/stream/mjpeg/${encodeURIComponent(sensorCompositeId)}/${encodeURIComponent(imageType)}/{audioProperty?}`, undefined);
export const sendStoppedStreamSignal = async (data: SendStoppedStreamSignalInput) => apiRequest<SendStoppedStreamSignalInput, void>('post', '/api/stream/sendStoppedStreamSignal', data);
export const statusStream = async () => apiRequest<undefined, void>('get', '/api/stream/status', undefined);
export const dashboardStream = async () => apiRequest<undefined, void>('get', '/api/stream/dashboard', undefined);
export const rssi = async () => apiRequest<undefined, void>('get', '/api/stream/wireless/rssi', undefined);
export const getTypeScriptDefinitions = async () => apiRequest<undefined, void>('get', '/api/meta/typescript-definitions', undefined);
export const superGetOverviewData = async () => apiRequest<undefined, SuperOverviewData>('get', '/api/super/overview', undefined);
export const superGetOfflineSensors = async (data: SuperGetOfflineSensorsInput) => apiRequest<SuperGetOfflineSensorsInput, SuperGetOfflineSensorsResult>('get', '/api/super/offline-sensors', data);
export const superGetServerDetails = async () => apiRequest<undefined, ServerDetails>('get', '/api/super/server/details', undefined);
export const superGetEndpoints = async () => apiRequest<undefined, SuperEndpoints>('get', '/api/super/server/endpoints', undefined);
export const superGetDefaults = async () => apiRequest<undefined, SuperDefaults>('get', '/api/super/server/defaults', undefined);
export const superGetAllViews = async () => apiRequest<undefined, SuperGetAllViewsResult>('get', '/api/super/server/views/getAllViews', undefined);
export const superGetPermissions = async () => apiRequest<undefined, GetPermissionsResult>('get', '/api/super/roles-and-capabilities', undefined);
export const superPutPermissions = async (data: PutPermissionsData) => apiRequest<PutPermissionsData, PutPermissionsResult>('put', '/api/super/roles-and-capabilities', data);
export const superGetAllRoles = async () => apiRequest<undefined, SuperVendorGlobalUserRolesResult>('get', '/api/super/roles', undefined);
export const superCreateRole = async (data: SuperCreateRoleData) => apiRequest<SuperCreateRoleData, SuperCreateRoleResult>('post', '/api/super/roles', data);
export const superUpdateRole = async (role: string, data: SuperUpdateRoleData) => apiRequest<SuperUpdateRoleData, SuperUpdateRoleResult>('patch', `/api/super/roles/${encodeURIComponent(role)}`, data);
export const superDeleteRole = async (role: string) => apiRequest<undefined, void>('delete', `/api/super/roles/${encodeURIComponent(role)}`, undefined);
export const superGetZones = async () => apiRequest<undefined, SuperGetZonesResult>('get', '/api/super/zones', undefined);
export const superGetUsers = async (data: SuperGetUsersInput) => apiRequest<SuperGetUsersInput, SuperGetUsersResult>('post', '/api/super/users/all', data);
export const superGetUserDetails = async (userId: number) => apiRequest<undefined, SuperGetUserDetailsResult>('get', `/api/super/users/${encodeURIComponent(userId)}`, undefined);
export const superCreateUser = async (data: SuperCreateUserData) => apiRequest<SuperCreateUserData, SuperCreateUserResult>('post', '/api/super/users', data);
export const superUpdateUser = async (userId: number, data: SuperUpdateUserData) => apiRequest<SuperUpdateUserData, SuperUpdateUserResult>('put', `/api/super/users/${encodeURIComponent(userId)}`, data);
export const superDeleteUser = async (userId: number) => apiRequest<undefined, void>('delete', `/api/super/users/${encodeURIComponent(userId)}`, undefined);
export const superCreateUserRole = async (data: SuperCreateUserRoleData) => apiRequest<SuperCreateUserRoleData, SuperCreateUserRoleResult>('post', '/api/super/UserRoles::store', data);
export const superUpdateUserRole = async (userRoleId: number, data: SuperUpdateUserRoleData) => apiRequest<SuperUpdateUserRoleData, SuperUpdateUserRoleResult>('patch', `/api/super/UserRoles::update/${encodeURIComponent(userRoleId)}`, data);
export const superDeleteUserRole = async (userRoleId: number) => apiRequest<undefined, void>('delete', `/api/super/UserRoles::destroy/${encodeURIComponent(userRoleId)}`, undefined);
export const superGetAccountVendors = async (accountId: number) => apiRequest<undefined, SuperGetAccountVendorsResult>('get', `/api/super/accounts/${encodeURIComponent(accountId)}/vendors`, undefined);
export const superAssignAccountVendor = async (vendorAccountId: number, accountId: number) => apiRequest<undefined, SuperAssignAccountVendorResult>('post', `/api/super/accounts/${encodeURIComponent(accountId)}/vendors/${encodeURIComponent(vendorAccountId)}`, undefined);
export const superUnassignAccountVendor = async (vendorAccountId: number, accountId: number) => apiRequest<undefined, void>('delete', `/api/super/accounts/${encodeURIComponent(accountId)}/vendors/${encodeURIComponent(vendorAccountId)}`, undefined);
export const superGetCareReceivers = async () => apiRequest<undefined, SuperGetCareReceiversResult>('get', '/api/super/care-receivers', undefined);
export const superGetCareReceiverDetails = async (careReceiverId: number) => apiRequest<undefined, SuperGetCareReceiverDetailsResult>('get', `/api/super/care-receivers/${encodeURIComponent(careReceiverId)}`, undefined);
export const superDeleteCareReceiver = async (careReceiverId: number) => apiRequest<undefined, void>('delete', `/api/super/care-receivers/${encodeURIComponent(careReceiverId)}`, undefined);
export const superGetSystemMessages = async () => apiRequest<undefined, SuperGetSystemMessagesResult>('get', '/api/super/system-messages', undefined);
export const superCreateSystemMessage = async (data: SuperCreateSystemMessageData) => apiRequest<SuperCreateSystemMessageData, SuperCreateSystemMessageResult>('post', '/api/super/system-messages', data);
export const superGetSystemMessage = async (systemMessageId: number) => apiRequest<undefined, SuperGetSystemMessageResult>('get', `/api/super/system-messages/${encodeURIComponent(systemMessageId)}`, undefined);
export const superUpdateSystemMessage = async (systemMessageId: number, data: SuperUpdateSystemMessageData) => apiRequest<SuperUpdateSystemMessageData, SuperUpdateSystemMessageResult>('put', `/api/super/system-messages/${encodeURIComponent(systemMessageId)}`, data);
export const superDeleteSystemMessage = async (systemMessageId: number) => apiRequest<undefined, void>('delete', `/api/super/system-messages/${encodeURIComponent(systemMessageId)}`, undefined);
export const loadEventInspectorEvents = async (data: LoadEventInspectorEventsInput) => apiRequest<LoadEventInspectorEventsInput, LoadEventInspectorEventsResult>('post', '/api/super/event-inspector/events', data);
export const loadEventInspectorEventDetails = async (eventId: number) => apiRequest<undefined, LoadEventInspectorEventDetailsResult>('post', `/api/super/event-inspector/events/${encodeURIComponent(eventId)}`, undefined);
export const superSendSms = async (data: SuperSendSmsInput) => apiRequest<SuperSendSmsInput, void>('post', '/api/super/sms/send', data);
export const superSendEmail = async (data: SuperSendEmailInput) => apiRequest<SuperSendEmailInput, void>('post', '/api/super/email/send', data);
export const superGetSessions = async () => apiRequest<undefined, SuperGetSessionsResult>('get', '/api/super/sessions', undefined);
export const superGetFeatureFlagDefinitions = async () => apiRequest<undefined, superGetFeatureFlagDefinitionsResult>('get', '/api/super/feature-flag-definitions', undefined);
export const superGetFeatureFlags = async () => apiRequest<undefined, superGetFeatureFlagsResult>('get', '/api/super/feature-flags', undefined);
export const superCreateFeatureFlag = async (data: superCreateFeatureFlagInput) => apiRequest<superCreateFeatureFlagInput, superCreateFeatureFlagResult>('post', '/api/super/feature-flags', data);
export const superUpdateFeatureFlag = async (featureFlagId: number, data: superUpdateFeatureFlagInput) => apiRequest<superUpdateFeatureFlagInput, superUpdateFeatureFlagResult>('put', `/api/super/feature-flags/${encodeURIComponent(featureFlagId)}`, data);
export const superDeleteFeatureFlag = async (featureFlagId: number) => apiRequest<undefined, void>('delete', `/api/super/feature-flags/${encodeURIComponent(featureFlagId)}`, undefined);
export const addMobileDevice = async (data: AddMobileDevice) => apiRequest<AddMobileDevice, AddMobileDeviceResult>('post', '/api/super/mobile-devices/add', data);
export const unasignMobileDeviceUser = async (data: UnasignMobileDeviceFromUser) => apiRequest<UnasignMobileDeviceFromUser, UnasignMobileDeviceFromUserResult>('post', '/api/super/mobile-devices/unassign', data);
export const superReloadRcts = async () => apiRequest<undefined, void>('post', '/api/super/rcts/reload', undefined);
export const clearBootStatus = async (sensorCompositeId: string) => apiRequest<undefined, void>('post', `/api/super/SensorWorriesController::clearBootStatus/${encodeURIComponent(sensorCompositeId)}`, undefined);
export const getFullSensorWorriesData = async (data: GetFullSensorWorriesDataInput) => apiRequest<GetFullSensorWorriesDataInput, GetFullSensorWorriesDataResult>('post', '/api/super/SensorWorriesController::getFullSensorWorriesData', data);
export const superGetSensorBillingPeriods = async (sensorCompositeId: string) => apiRequest<undefined, SuperGetSensorBillingPeriodsResult>('get', `/api/super/sensor-billing-periods/${encodeURIComponent(sensorCompositeId)}`, undefined);
export const superCreateSensorBillingPeriod = async (sensorCompositeId: string, data: CreateSensorBillingPeriodData) => apiRequest<CreateSensorBillingPeriodData, SuperCreateSensorBillingPeriodResult>('post', `/api/super/sensor-billing-periods/${encodeURIComponent(sensorCompositeId)}`, data);
export const superUpdateSensorBillingPeriod = async (sensorBillingPeriodId: string, data: UpdateSensorBillingPeriodData) => apiRequest<UpdateSensorBillingPeriodData, SuperUpdateSensorBillingPeriodResult>('put', `/api/super/sensor-billing-periods/${encodeURIComponent(sensorBillingPeriodId)}`, data);
export const superDeleteSensorBillingPeriod = async (sensorBillingPeriodId: string) => apiRequest<undefined, void>('delete', `/api/super/sensor-billing-periods/${encodeURIComponent(sensorBillingPeriodId)}`, undefined);
export const getSensorsListXlsx = async () => apiRequest<undefined, void>('get', '/api/super/sensors/sensor-list-export.xlsx', undefined);
export const getActiveImageStreams = async () => apiRequest<undefined, GetActiveImageStreamsResult>('get', '/api/super/active-image-streams', undefined);
export const superVendorGetAccountsNew = async (data: SuperVendorGetAccountsNewInput) => apiRequest<SuperVendorGetAccountsNewInput, SuperVendorGetAccountsNewResult>('get', '/api/supervendor/accounts', data);
export const superVendorGetAccounts = async (data: SuperVendorGetAccountsInput) => apiRequest<SuperVendorGetAccountsInput, SuperVendorGetAccountsResult>('post', '/api/supervendor/accounts/all', data);
export const superVendorCreateAccount = async (data: SuperVendorCreateAccountData) => apiRequest<SuperVendorCreateAccountData, SuperVendorCreateAccountResult>('post', '/api/supervendor/accounts', data);
export const superVendorGetAccountDetails = async (accountId: number) => apiRequest<undefined, SuperVendorGetAccountDetailsResult>('get', `/api/supervendor/accounts/${encodeURIComponent(accountId)}`, undefined);
export const superVendorUpdateAccount = async (accountId: number, data: SuperVendorUpdateAccountData) => apiRequest<SuperVendorUpdateAccountData, SuperVendorUpdateAccountResult>('patch', `/api/supervendor/accounts/${encodeURIComponent(accountId)}`, data);
export const superVendorDeleteAccount = async (accountId: number) => apiRequest<undefined, void>('delete', `/api/supervendor/accounts/${encodeURIComponent(accountId)}`, undefined);
export const superVendorGetStructureForAccount = async (accountId: number) => apiRequest<undefined, SuperVendorGetStructureForAccountResult>('get', `/api/super/accounts/${encodeURIComponent(accountId)}/structure`, undefined);
export const superVendorBulkAssignSensorAccount = async (data: SuperVendorBulkAssignSensorAccountData) => apiRequest<SuperVendorBulkAssignSensorAccountData, SuperVendorBulkAssignSensorAccountResult>('post', '/api/supervendor/BulkAssignSensor::bulkAssignAccount', data);
export const superVendorGetSensors = async (data: SuperVendorGetSensorsInput) => apiRequest<SuperVendorGetSensorsInput, SuperVendorGetSensorsResult>('get', '/api/supervendor/Sensors::getSensors', data);
export const superVendorGetSensor = async (sensorCompositeId: string) => apiRequest<undefined, SuperVendorGetSensorResult>('get', `/api/supervendor/Sensors::getSensor/${encodeURIComponent(sensorCompositeId)}`, undefined);
export const superVendorUpdateSensor = async (sensorCompositeId: string, data: SuperVendorUpdateSensorInput) => apiRequest<SuperVendorUpdateSensorInput, SuperUpdateSensorsResult>('patch', `/api/supervendor/Sensors::updateSensor/${encodeURIComponent(sensorCompositeId)}`, data);
export const superVendorDeleteSensor = async (sensorCompositeId: string) => apiRequest<undefined, void>('delete', `/api/supervendor/Sensors::deleteSensor/${encodeURIComponent(sensorCompositeId)}`, undefined);
export const getSensorStatusLog = async (sensorCompositeId: string) => apiRequest<undefined, GetSensorStatusLogResult>('get', `/api/supervendor/Sensors::getSensorStatusLog/${encodeURIComponent(sensorCompositeId)}`, undefined);
export const duplicateSensorSettings = async (data: DuplicateSensorSettingsInput) => apiRequest<DuplicateSensorSettingsInput, void>('post', '/api/supervendor/SensorDuplication::duplicateSettings', data);
export const impersonateAccount = async (data: ImpersonateAccountData) => apiRequest<ImpersonateAccountData, ImpersonateAccountResult>('post', '/api/supervendor/Impersonation::impersonateAccount', data);
export const impersonateUserRoles = async (data: ImpersonateUserRolesData) => apiRequest<ImpersonateUserRolesData, ImpersonateUserRolesResult>('post', '/api/supervendor/Impersonation::impersonateUserRoles', data);
export const superSearch = async (data: SuperSearchInput) => apiRequest<SuperSearchInput, SuperSearchResults>('post', '/api/supervendor/search', data);
export const superVendorSetupIntegration = async (data: SuperVendorSetupIntegrationInput) => apiRequest<SuperVendorSetupIntegrationInput, SuperVendorSetupIntegrationResult>('post', '/api/supervendor/integration/setup', data);
export const superVendorRemoveIntegration = async (zoneId: string) => apiRequest<undefined, void>('delete', `/api/supervendor/integration/${encodeURIComponent(zoneId)}/remove`, undefined);
export const superVendorListIntegrationUsers = async () => apiRequest<undefined, SuperVendorListIntegrationUsersResult>('post', '/api/supervendor/integration/users-list', undefined);
export const superVendorIntegrationChangePassword = async (userId: string, data: SuperVendorIntegrationChangePasswordInput) => apiRequest<SuperVendorIntegrationChangePasswordInput, SuperVendorIntegrationChangePasswordOutput>('patch', `/api/supervendor/integration/${encodeURIComponent(userId)}/change-password`, data);
export const getIdentityProviders = async (accountId: string) => apiRequest<undefined, GetIdentityProviders>('get', `/api/supervendor/account/${encodeURIComponent(accountId)}/identity-providers`, undefined);
export const getIdentityProvider = async (idp_provider: string, accountId: string) => apiRequest<undefined, GetIdentityProvider>('get', `/api/supervendor/account/${encodeURIComponent(accountId)}/identity-providers/${encodeURIComponent(idp_provider)}`, undefined);
export const updateIdentityProvider = async (idp_provider: string, accountId: string, data: IdentityProviderData) => apiRequest<IdentityProviderData, IdentityProviderResult>('put', `/api/supervendor/account/${encodeURIComponent(accountId)}/identity-providers/${encodeURIComponent(idp_provider)}`, data);
export const deleteIdentityProvider = async (idp_provider: string, accountId: string) => apiRequest<undefined, void>('delete', `/api/supervendor/account/${encodeURIComponent(accountId)}/identity-providers/${encodeURIComponent(idp_provider)}`, undefined);
export const getImageCollectionSettings = async (sensorCompositeId: string) => apiRequest<undefined, GetImageCollectionSettingsResult>('get', `/api/supervendor/${encodeURIComponent(sensorCompositeId)}/image-collection-settings`, undefined);
export const updateImageCollectionSettings = async (sensorCompositeId: string, data: UpdateImageCollectionSettingsInput) => apiRequest<UpdateImageCollectionSettingsInput, UpdateImageCollectionSettingsResult>('patch', `/api/supervendor/${encodeURIComponent(sensorCompositeId)}/image-collection-settings`, data);
export const getSensorConfiguration = async (namespace: string, sensorCompositeId: string) => apiRequest<undefined, GetSensorConfigurationResult>('get', `/api/supervendor/${encodeURIComponent(sensorCompositeId)}/sensor-configuration/${encodeURIComponent(namespace)}`, undefined);
export const updateSensorConfiguration = async (namespace: string, sensorCompositeId: string, data: UpdateSensorConfigurationInput) => apiRequest<UpdateSensorConfigurationInput, UpdateSensorConfigurationResult>('patch', `/api/supervendor/${encodeURIComponent(sensorCompositeId)}/sensor-configuration/${encodeURIComponent(namespace)}`, data);
export const getWholeSensorConfiguration = async (sensorCompositeId: string) => apiRequest<undefined, GetWholeSensorConfigurationResult>('get', `/api/supervendor/${encodeURIComponent(sensorCompositeId)}/sensor-configuration`, undefined);
export const updateWholeSensorConfiguration = async (sensorCompositeId: string, data: UpdateWholeSensorConfigurationInput) => apiRequest<UpdateWholeSensorConfigurationInput, UpdateWholeSensorConfigurationResult>('patch', `/api/supervendor/${encodeURIComponent(sensorCompositeId)}/sensor-configuration`, data);
export const getEntireGlobalConfiguration = async () => apiRequest<undefined, GetEntireGlobalConfigurationResult>('get', '/api/supervendor/sensor-configuration', undefined);


//-----------Queries-----------//
export const QueryKeys = {
	careGetBehaviorData: (sensorCompositeId: string) => ['careGetBehaviorData', sensorCompositeId],
	getStructure: () => ['getStructure'],
	getSensorDetails: (sensorCompositeId: string) => ['getSensorDetails', sensorCompositeId],
	getSensorsForZone: (zoneId: number) => ['getSensorsForZone', zoneId],
	getEventSettingsBySensorId: (sensorCompositeId: string) => ['getEventSettingsBySensorId', sensorCompositeId],
	getSensorArgusConfiguration: (sensorCompositeId: string) => ['getSensorArgusConfiguration', sensorCompositeId],
	getSensorSettings: (sensorCompositeId: string) => ['getSensorSettings', sensorCompositeId],
	superGetOverviewData: () => ['superGetOverviewData'],
	superGetOfflineSensors: (data: SuperGetOfflineSensorsInput) => ['superGetOfflineSensors', data],
	superGetUsers: (data: SuperGetUsersInput) => ['superGetUsers', data],
	getFullSensorWorriesData: (data: GetFullSensorWorriesDataInput) => ['getFullSensorWorriesData', data],
	superGetSensorBillingPeriods: (sensorCompositeId: string) => ['superGetSensorBillingPeriods', sensorCompositeId],
	superVendorGetAccountsNew: (data: SuperVendorGetAccountsNewInput) => ['superVendorGetAccountsNew', data],
	superVendorGetAccounts: (data: SuperVendorGetAccountsInput) => ['superVendorGetAccounts', data],
	superVendorGetAccountDetails: (accountId: number) => ['superVendorGetAccountDetails', accountId],
	superVendorGetSensors: (data: SuperVendorGetSensorsInput) => ['superVendorGetSensors', data],
	getIdentityProviders: (accountId: string) => ['getIdentityProviders', accountId],
	getIdentityProvider: (idp_provider: string, accountId: string) => ['getIdentityProvider', idp_provider, accountId],
	getImageCollectionSettings: (sensorCompositeId: string) => ['getImageCollectionSettings', sensorCompositeId],
	getSensorConfiguration: (namespace: string, sensorCompositeId: string) => ['getSensorConfiguration', namespace, sensorCompositeId],
	getWholeSensorConfiguration: (sensorCompositeId: string) => ['getWholeSensorConfiguration', sensorCompositeId],
	getEntireGlobalConfiguration: () => ['getEntireGlobalConfiguration'],
};

export const useCareGetBehaviorDataQuery = (sensorCompositeId: string, options?: UseQueryOptions<CareGetBehaviorDataResult, AxiosError>) =>
	useQuery<CareGetBehaviorDataResult, AxiosError>(
		QueryKeys.careGetBehaviorData(sensorCompositeId),
		() => careGetBehaviorData(sensorCompositeId),
		options
);
export const useGetStructureQuery = (options?: UseQueryOptions<GetStructureResult, AxiosError>) =>
	useQuery<GetStructureResult, AxiosError>(
		QueryKeys.getStructure(),
		() => getStructure(),
		options
);
export const useGetSensorDetailsQuery = (sensorCompositeId: string, options?: UseQueryOptions<GetSensorDetailsResult, AxiosError>) =>
	useQuery<GetSensorDetailsResult, AxiosError>(
		QueryKeys.getSensorDetails(sensorCompositeId),
		() => getSensorDetails(sensorCompositeId),
		options
);
export const useGetSensorsForZoneQuery = (zoneId: number, options?: UseQueryOptions<GetSensorsForZoneResult, AxiosError>) =>
	useQuery<GetSensorsForZoneResult, AxiosError>(
		QueryKeys.getSensorsForZone(zoneId),
		() => getSensorsForZone(zoneId),
		options
);
export const useGetEventSettingsBySensorIdQuery = (sensorCompositeId: string, options?: UseQueryOptions<GetEventSettingsBySensorIdResult, AxiosError>) =>
	useQuery<GetEventSettingsBySensorIdResult, AxiosError>(
		QueryKeys.getEventSettingsBySensorId(sensorCompositeId),
		() => getEventSettingsBySensorId(sensorCompositeId),
		options
);
export const useGetSensorArgusConfigurationQuery = (sensorCompositeId: string, options?: UseQueryOptions<GetSensorArgusConfigurationResult, AxiosError>) =>
	useQuery<GetSensorArgusConfigurationResult, AxiosError>(
		QueryKeys.getSensorArgusConfiguration(sensorCompositeId),
		() => getSensorArgusConfiguration(sensorCompositeId),
		options
);
export const useGetSensorSettingsQuery = (sensorCompositeId: string, options?: UseQueryOptions<GetSensorSettingsResult, AxiosError>) =>
	useQuery<GetSensorSettingsResult, AxiosError>(
		QueryKeys.getSensorSettings(sensorCompositeId),
		() => getSensorSettings(sensorCompositeId),
		options
);
export const useSuperGetOverviewDataQuery = (options?: UseQueryOptions<SuperOverviewData, AxiosError>) =>
	useQuery<SuperOverviewData, AxiosError>(
		QueryKeys.superGetOverviewData(),
		() => superGetOverviewData(),
		options
);
export const useSuperGetOfflineSensorsQuery = (data: SuperGetOfflineSensorsInput, options?: UseQueryOptions<SuperGetOfflineSensorsResult, AxiosError>) =>
	useQuery<SuperGetOfflineSensorsResult, AxiosError>(
		QueryKeys.superGetOfflineSensors(data),
		() => superGetOfflineSensors(data),
		options
);
export const useSuperGetUsersQuery = (data: SuperGetUsersInput, options?: UseQueryOptions<SuperGetUsersResult, AxiosError>) =>
	useQuery<SuperGetUsersResult, AxiosError>(
		QueryKeys.superGetUsers(data),
		() => superGetUsers(data),
		options
);
export const useGetFullSensorWorriesDataQuery = (data: GetFullSensorWorriesDataInput, options?: UseQueryOptions<GetFullSensorWorriesDataResult, AxiosError>) =>
	useQuery<GetFullSensorWorriesDataResult, AxiosError>(
		QueryKeys.getFullSensorWorriesData(data),
		() => getFullSensorWorriesData(data),
		options
);
export const useSuperGetSensorBillingPeriodsQuery = (sensorCompositeId: string, options?: UseQueryOptions<SuperGetSensorBillingPeriodsResult, AxiosError>) =>
	useQuery<SuperGetSensorBillingPeriodsResult, AxiosError>(
		QueryKeys.superGetSensorBillingPeriods(sensorCompositeId),
		() => superGetSensorBillingPeriods(sensorCompositeId),
		options
);
export const useSuperVendorGetAccountsNewQuery = (data: SuperVendorGetAccountsNewInput, options?: UseQueryOptions<SuperVendorGetAccountsNewResult, AxiosError>) =>
	useQuery<SuperVendorGetAccountsNewResult, AxiosError>(
		QueryKeys.superVendorGetAccountsNew(data),
		() => superVendorGetAccountsNew(data),
		options
);
export const useSuperVendorGetAccountsQuery = (data: SuperVendorGetAccountsInput, options?: UseQueryOptions<SuperVendorGetAccountsResult, AxiosError>) =>
	useQuery<SuperVendorGetAccountsResult, AxiosError>(
		QueryKeys.superVendorGetAccounts(data),
		() => superVendorGetAccounts(data),
		options
);
export const useSuperVendorGetAccountDetailsQuery = (accountId: number, options?: UseQueryOptions<SuperVendorGetAccountDetailsResult, AxiosError>) =>
	useQuery<SuperVendorGetAccountDetailsResult, AxiosError>(
		QueryKeys.superVendorGetAccountDetails(accountId),
		() => superVendorGetAccountDetails(accountId),
		options
);
export const useSuperVendorGetSensorsQuery = (data: SuperVendorGetSensorsInput, options?: UseQueryOptions<SuperVendorGetSensorsResult, AxiosError>) =>
	useQuery<SuperVendorGetSensorsResult, AxiosError>(
		QueryKeys.superVendorGetSensors(data),
		() => superVendorGetSensors(data),
		options
);
export const useGetIdentityProvidersQuery = (accountId: string, options?: UseQueryOptions<GetIdentityProviders, AxiosError>) =>
	useQuery<GetIdentityProviders, AxiosError>(
		QueryKeys.getIdentityProviders(accountId),
		() => getIdentityProviders(accountId),
		options
);
export const useGetIdentityProviderQuery = (idp_provider: string, accountId: string, options?: UseQueryOptions<GetIdentityProvider, AxiosError>) =>
	useQuery<GetIdentityProvider, AxiosError>(
		QueryKeys.getIdentityProvider(idp_provider, accountId),
		() => getIdentityProvider(idp_provider, accountId),
		options
);
export const useGetImageCollectionSettingsQuery = (sensorCompositeId: string, options?: UseQueryOptions<GetImageCollectionSettingsResult, AxiosError>) =>
	useQuery<GetImageCollectionSettingsResult, AxiosError>(
		QueryKeys.getImageCollectionSettings(sensorCompositeId),
		() => getImageCollectionSettings(sensorCompositeId),
		options
);
export const useGetSensorConfigurationQuery = (namespace: string, sensorCompositeId: string, options?: UseQueryOptions<GetSensorConfigurationResult, AxiosError>) =>
	useQuery<GetSensorConfigurationResult, AxiosError>(
		QueryKeys.getSensorConfiguration(namespace, sensorCompositeId),
		() => getSensorConfiguration(namespace, sensorCompositeId),
		options
);
export const useGetWholeSensorConfigurationQuery = (sensorCompositeId: string, options?: UseQueryOptions<GetWholeSensorConfigurationResult, AxiosError>) =>
	useQuery<GetWholeSensorConfigurationResult, AxiosError>(
		QueryKeys.getWholeSensorConfiguration(sensorCompositeId),
		() => getWholeSensorConfiguration(sensorCompositeId),
		options
);
export const useGetEntireGlobalConfigurationQuery = (options?: UseQueryOptions<GetEntireGlobalConfigurationResult, AxiosError>) =>
	useQuery<GetEntireGlobalConfigurationResult, AxiosError>(
		QueryKeys.getEntireGlobalConfiguration(),
		() => getEntireGlobalConfiguration(),
		options
);



//-----------Mutations-----------//
export const useSetSensorArgusConfigurationMutation = (options?: UseMutationOptions<SetSensorArgusConfigurationResult, AxiosError, {sensorCompositeId: string; data: SetSensorArgusConfigurationInput}>) =>
	useMutation<SetSensorArgusConfigurationResult, AxiosError, {sensorCompositeId: string; data: SetSensorArgusConfigurationInput}>(
		(params) => setSensorArgusConfiguration(params.sensorCompositeId, params.data),
		options
	);
export const useUpdateSensorSettingMutation = (options?: UseMutationOptions<UpdateSensorSettingResult, AxiosError, {sensorCompositeId: string; data: UpdateSensorSettingData}>) =>
	useMutation<UpdateSensorSettingResult, AxiosError, {sensorCompositeId: string; data: UpdateSensorSettingData}>(
		(params) => updateSensorSetting(params.sensorCompositeId, params.data),
		options
	);
export const useSuperVendorCreateAccountMutation = (options?: UseMutationOptions<SuperVendorCreateAccountResult, AxiosError, {data: SuperVendorCreateAccountData}>) =>
	useMutation<SuperVendorCreateAccountResult, AxiosError, {data: SuperVendorCreateAccountData}>(
		(params) => superVendorCreateAccount(params.data),
		options
	);
export const useSuperVendorUpdateAccountMutation = (options?: UseMutationOptions<SuperVendorUpdateAccountResult, AxiosError, {accountId: number; data: SuperVendorUpdateAccountData}>) =>
	useMutation<SuperVendorUpdateAccountResult, AxiosError, {accountId: number; data: SuperVendorUpdateAccountData}>(
		(params) => superVendorUpdateAccount(params.accountId, params.data),
		options
	);
export const useSuperVendorDeleteAccountMutation = (options?: UseMutationOptions<void, AxiosError, {accountId: number}>) =>
	useMutation<void, AxiosError, {accountId: number}>(
		(params) => superVendorDeleteAccount(params.accountId),
		options
	);
export const useImpersonateAccountMutation = (options?: UseMutationOptions<ImpersonateAccountResult, AxiosError, {data: ImpersonateAccountData}>) =>
	useMutation<ImpersonateAccountResult, AxiosError, {data: ImpersonateAccountData}>(
		(params) => impersonateAccount(params.data),
		options
	);
export const useUpdateIdentityProviderMutation = (options?: UseMutationOptions<IdentityProviderResult, AxiosError, {idp_provider: string; accountId: string; data: IdentityProviderData}>) =>
	useMutation<IdentityProviderResult, AxiosError, {idp_provider: string; accountId: string; data: IdentityProviderData}>(
		(params) => updateIdentityProvider(params.idp_provider, params.accountId, params.data),
		options
	);

export type AudioKey =
  | 'standard_1'
  | 'standard_2'
  | 'gong_1'
  | 'gong_2'
  | 'bell_1'
  | 'bell_2'
  | 'dingeling_1'
  | 'dingeling_2'
  | 'pling_plong_1'
  | 'pling_plong_2'
  | 'bip_1'
  | 'bip_2'
  | 'tropical_1'
  | 'tropical_2'
  | 'alarm_alarm_1'
  | 'alarm_alarm_2'
  | 'scale_1'
  | 'scale_2'
  | 'marimba_1'
  | 'marimba_2'
  | 'melody_1'
  | 'melody_2'
  | 'cosmic_echo_1'
  | 'cosmic_echo_2';

export const AudioKeyDefinitions: AudioKey[] = [
  'standard_1',
  'standard_2',
  'gong_1',
  'gong_2',
  'bell_1',
  'bell_2',
  'dingeling_1',
  'dingeling_2',
  'pling_plong_1',
  'pling_plong_2',
  'bip_1',
  'bip_2',
  'tropical_1',
  'tropical_2',
  'alarm_alarm_1',
  'alarm_alarm_2',
  'scale_1',
  'scale_2',
  'marimba_1',
  'marimba_2',
  'melody_1',
  'melody_2',
  'cosmic_echo_1',
  'cosmic_echo_2',
];

