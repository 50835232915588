import { useState } from 'react';
import {
  FormControl,
  FormGroup,
  InputLabel,
  MenuItem,
  Select,
} from '@material-ui/core';
import { AudioPlayerProvider, useAudioPlayer } from 'react-use-audio-player';

import { AudioPlayer } from '../../../../../components/AudioPlayer/AudioPlayer';
import { PlaySoundButton } from '../../../../../components/AudioPlayer/PlaySoundButton';
import { FormBody } from './lib/FormBody';
import { Panel } from '../../../../../components/Panel';

import { useZoneContext } from '../../../../../lib/ZoneContext';
import useZoneSettingsForm from './lib/useZoneSettingsForm';
import { t } from '../../../../../lib/i18n';
import * as api from '../../../../../serverApi';

//Audio sounds
import standard_1 from '../../../../../assets/audio/notification-sounds/standard_1.mp3';
import standard_2 from '../../../../../assets/audio/notification-sounds/standard_2.mp3';
import gong_1 from '../../../../../assets/audio/notification-sounds/gong_1.mp3';
import gong_2 from '../../../../../assets/audio/notification-sounds/gong_2.mp3';
import bell_1 from '../../../../../assets/audio/notification-sounds/bell_1.mp3';
import bell_2 from '../../../../../assets/audio/notification-sounds/bell_2.mp3';
import dingeling_1 from '../../../../../assets/audio/notification-sounds/dingeling_1.mp3';
import dingeling_2 from '../../../../../assets/audio/notification-sounds/dingeling_2.mp3';
import pling_plong_1 from '../../../../../assets/audio/notification-sounds/pling_plong_1.mp3';
import pling_plong_2 from '../../../../../assets/audio/notification-sounds/pling_plong_2.mp3';
import bip_1 from '../../../../../assets/audio/notification-sounds/bip_1.mp3';
import bip_2 from '../../../../../assets/audio/notification-sounds/bip_2.mp3';
import tropical_1 from '../../../../../assets/audio/notification-sounds/tropical_1.mp3';
import tropical_2 from '../../../../../assets/audio/notification-sounds/tropical_2.mp3';
import alarm_alarm_1 from '../../../../../assets/audio/notification-sounds/alarm_alarm_1.mp3';
import alarm_alarm_2 from '../../../../../assets/audio/notification-sounds/alarm_alarm_2.mp3';
import scale_1 from '../../../../../assets/audio/notification-sounds/scale_1.mp3';
import scale_2 from '../../../../../assets/audio/notification-sounds/scale_2.mp3';
import marimba_1 from '../../../../../assets/audio/notification-sounds/marimba_1.mp3';
import marimba_2 from '../../../../../assets/audio/notification-sounds/marimba_2.mp3';
import melody_1 from '../../../../../assets/audio/notification-sounds/melody_1.mp3';
import melody_2 from '../../../../../assets/audio/notification-sounds/melody_2.mp3';
import cosmic_echo_1 from '../../../../../assets/audio/notification-sounds/cosmic_echo_1.mp3';
import cosmic_echo_2 from '../../../../../assets/audio/notification-sounds/cosmic_echo_2.mp3';

import './MobileAppSounds.scss';

type NotificationSoundType = Record<
  api.AudioKey,
  { label: string; file: string }
>;

const sounds: NotificationSoundType = {
  standard_1: { label: 'Standard 1', file: standard_1 },
  standard_2: { label: 'Standard 2', file: standard_2 },
  gong_1: { label: 'Gong 1', file: gong_1 },
  gong_2: { label: 'Gong 2', file: gong_2 },
  bell_1: { label: 'Bell 1', file: bell_1 },
  bell_2: { label: 'Bell 2', file: bell_2 },
  dingeling_1: { label: 'Dingeling 1 ', file: dingeling_1 },
  dingeling_2: { label: 'Dingeling 2', file: dingeling_2 },
  pling_plong_1: { label: 'Plingplong 1', file: pling_plong_1 },
  pling_plong_2: { label: 'Plingplong 2', file: pling_plong_2 },
  bip_1: { label: 'Bip 1', file: bip_1 },
  bip_2: { label: 'Bip 2', file: bip_2 },
  tropical_1: { label: 'Tropical 1', file: tropical_1 },
  tropical_2: { label: 'Tropical 2', file: tropical_2 },
  alarm_alarm_1: { label: 'Alarm 1', file: alarm_alarm_1 },
  alarm_alarm_2: { label: 'Alarm 2', file: alarm_alarm_2 },
  scale_1: { label: 'Scale 1', file: scale_1 },
  scale_2: { label: 'Scale 2', file: scale_2 },
  marimba_1: { label: 'Marimba 1', file: marimba_1 },
  marimba_2: { label: 'Marimba 2', file: marimba_2 },
  melody_1: { label: 'Melody 1', file: melody_1 },
  melody_2: { label: 'Melody 2', file: melody_2 },
  cosmic_echo_1: { label: 'Cosmic Echo 1', file: cosmic_echo_1 },
  cosmic_echo_2: { label: 'Cosmic Echo 2', file: cosmic_echo_2 },
};

const fields: Array<keyof api.ZoneSettings> = [
  'alarm_audio_key_a',
  'alarm_audio_key_b',
  'alert_audio_key_a',
  'alert_audio_key_b',
];

/**
 * Type guard; ensure the given parameter is a valid `AudioKey` type.
 */
function isAudioKey(key?: string | null | api.AudioKey): key is api.AudioKey {
  return (
    typeof key === 'string' &&
    api.AudioKeyDefinitions.indexOf(key as api.AudioKey) >= 0
  );
}

const getAudioFile = (audioKey?: string | null) => {
  if (isAudioKey(audioKey)) {
    return sounds[audioKey].file;
  }
  return undefined;
};

const MobileAppSoundsForm = () => {
  const ctx = useZoneContext();
  const form = useZoneSettingsForm({ fields });

  const [playingSound, setPlayingSound] = useState<api.AudioKey | null>(null);

  const { player, playing } = useAudioPlayer({
    src: playingSound ? sounds[playingSound].file : undefined,
    autoplay: true,
    onend: () => setPlayingSound(null),
  });

  const handlePreviewSoundClick = (soundTitle?: string | null) => (
    e: React.MouseEvent<HTMLButtonElement>
  ) => {
    /**
     * Stop event bubbling, so menu item onClick is not fired when trying to preview sounds
     */

    e.stopPropagation();

    if (!isAudioKey(soundTitle)) {
      return;
    }

    /**
     * We stop the audio by resetting playingSound if button is clicked twice while playing.
     */
    if (soundTitle === playingSound) {
      setPlayingSound(null);
      player.stop();
      return;
    }
    setPlayingSound(soundTitle);
    player?.play();
  };

  /**
   * Renders custom select menu item with a button to preview audio.
   */
  const renderSoundSelectMenuItems = () =>
    api.AudioKeyDefinitions.map((soundTitle) => {
      const sound = sounds[soundTitle];

      return (
        <MenuItem
          className="d-flex justify-content-between"
          key={soundTitle}
          value={soundTitle}
        >
          {sound.label}
          <PlaySoundButton
            onClick={handlePreviewSoundClick(soundTitle)}
            playing={playing && soundTitle === playingSound}
          />
        </MenuItem>
      );
    });

  /**
   * Removes sound preview button of the selected audio key in dropdown.
   */
  const renderSelectedValue = (value: any) => {
    if (isAudioKey(value)) {
      return sounds[value].label;
    }
    return value;
  };

  return (
    <div className="MobileAppSounds">
      <h2>
        {t('manage.zone.MobileSounds.headingFor')} {ctx.activeZone.name}
      </h2>

      <Panel>
        <strong>{t('common.commonTexts.tips')}: </strong>
        {t(
          'manage.zone.settings.common.theSettingsFromTheParentZoneAreDisplayedInGrayWhenThisIsSelected'
        )}
      </Panel>

      <FormBody zoneSettingsForm={form}>
        <FormGroup className="MobileAppSounds-input">
          <FormControl>
            <InputLabel id="alarm_audio_key_a">
              {t('manage.zone.MobileSounds.alarmAudioA')}
            </InputLabel>
            <Select
              style={{ maxWidth: '200px' }}
              labelId="alarm_audio_key_a"
              name="alarm_audio_key_a"
              disabled={form.disabled}
              value={form.displayValues?.alarm_audio_key_a ?? ''}
              onChange={form.formik.handleChange}
              renderValue={renderSelectedValue}
            >
              {renderSoundSelectMenuItems()}
            </Select>
          </FormControl>
          <AudioPlayer
            className="MobileAppSounds-player"
            file={getAudioFile(form.displayValues?.alarm_audio_key_a)}
          />
        </FormGroup>

        <FormGroup className="MobileAppSounds-input">
          <AudioPlayerProvider>
            <FormControl>
              <InputLabel id="alarm_audio_key_b">
                {t('manage.zone.MobileSounds.alarmAudioB')}
              </InputLabel>
              <Select
                style={{ maxWidth: '200px' }}
                labelId="alarm_audio_key_b"
                name="alarm_audio_key_b"
                disabled={form.disabled}
                value={form.displayValues?.alarm_audio_key_b ?? ''}
                onChange={form.formik.handleChange}
                renderValue={renderSelectedValue}
              >
                {renderSoundSelectMenuItems()}
              </Select>
            </FormControl>
            <AudioPlayer
              className="MobileAppSounds-player"
              file={getAudioFile(form.displayValues?.alarm_audio_key_b)}
            />
          </AudioPlayerProvider>
        </FormGroup>

        <FormGroup className="MobileAppSounds-input">
          <AudioPlayerProvider>
            <FormControl>
              <InputLabel id="alert_audio_key_a">
                {t('manage.zone.MobileSounds.alertAudioA')}
              </InputLabel>
              <Select
                style={{ maxWidth: '200px' }}
                labelId="alert_audio_key_a"
                name="alert_audio_key_a"
                disabled={form.disabled}
                value={form.displayValues?.alert_audio_key_a ?? ''}
                onChange={form.formik.handleChange}
                renderValue={renderSelectedValue}
              >
                {renderSoundSelectMenuItems()}
              </Select>
            </FormControl>
            <AudioPlayer
              className="MobileAppSounds-player"
              file={getAudioFile(form.displayValues?.alert_audio_key_a)}
            />
          </AudioPlayerProvider>
        </FormGroup>

        <FormGroup className="MobileAppSounds-input">
          <AudioPlayerProvider>
            <FormControl>
              <InputLabel id="alert_audio_key_b">
                {t('manage.zone.MobileSounds.alertAudioB')}
              </InputLabel>
              <Select
                style={{ maxWidth: '200px' }}
                labelId="alert_audio_key_b"
                name="alert_audio_key_b"
                disabled={form.disabled}
                value={form.displayValues?.alert_audio_key_b ?? ''}
                onChange={form.formik.handleChange}
                renderValue={renderSelectedValue}
              >
                {renderSoundSelectMenuItems()}
              </Select>
            </FormControl>
            <AudioPlayer
              className="MobileAppSounds-player"
              file={getAudioFile(form.displayValues?.alert_audio_key_b)}
            />
          </AudioPlayerProvider>
        </FormGroup>
      </FormBody>
    </div>
  );
};

export const MobileAppSounds = () => {
  return (
    <AudioPlayerProvider>
      <MobileAppSoundsForm />
    </AudioPlayerProvider>
  );
};
