import React from 'react';
import cs from 'classnames';
import './DashboardHeader.scss';
import { useCurrentTime } from './lib/useCurrentTime';
import { useAppContext } from '../../lib/global';

interface Props {
  className?: string;
}

export function DashboardHeader(props: Props) {
  const time = useCurrentTime();
  const app = useAppContext();

  return (
    <div className={cs('DashboardHeader', props.className)}>
      <div className="DashboardHeader-date">
        {time.format('dddd DD MMM').slice(0, -1)}
      </div>

      <h1 className="DashboardHeader-title">
        {app.user?.active_role?.zone?.name}
      </h1>

      <div>{time.format('HH:mm')}</div>
    </div>
  );
}
