import { Point, sensorImageResolution } from '../types';
import { MarkingObject } from '../types';

import * as mou from './markingObjectUtils';

/**
 * These dimensions are scaled according to the canvas
 */
const drawConfig = {
  penThickness: 0.8,
  labelFont: 'bold 24px sans-serif',
  pointDiameter: 0.8,
  circleDiameter: 4,
};

export function drawPoint(
  canvasCtx: CanvasRenderingContext2D,
  markingObj: MarkingObject,
  point: Point,
  pointIndex: number,
  scale: number
) {
  canvasCtx.fillStyle = markingObj.typeDefinition.markingLineColor ?? '#ccc';
  canvasCtx.beginPath();
  const diameter = markingObj.typeDefinition.circle
    ? drawConfig.circleDiameter
    : drawConfig.pointDiameter;
  canvasCtx.arc(
    point.h * scale,
    point.v * scale,
    (diameter / 2) * scale,
    0,
    2 * Math.PI,
    true
  );
  canvasCtx.fill();
  canvasCtx.closePath();
}

export function drawLine(
  canvasCtx: CanvasRenderingContext2D,
  color: string,
  prevPoint: Point,
  currPoint: Point,
  scale: number
) {
  let scaledPrevPoint: Point = mou.getScaledPoint(prevPoint, scale);
  let scaledCurrPoint: Point = mou.getScaledPoint(currPoint, scale);
  canvasCtx.beginPath();
  canvasCtx.setLineDash([5, 5]); // Draw this for Argus/suggested markings
  canvasCtx.moveTo(scaledPrevPoint.h, scaledPrevPoint.v);
  canvasCtx.lineTo(scaledCurrPoint.h, scaledCurrPoint.v);
  canvasCtx.strokeStyle = color;
  canvasCtx.lineWidth = drawConfig.penThickness * scale;
  canvasCtx.setLineDash([]);
  canvasCtx.stroke();
  canvasCtx.closePath();
}

/**
 * Draw the extended, dashed line for the first and last points of a marking object.
 * @param canvasCtx
 * @param markingObj
 * @param point
 * @param pointIndex
 * @param scale
 * @returns
 */
export function drawExtendedLine(
  canvasCtx: CanvasRenderingContext2D,
  markingObj: MarkingObject,
  point: Point,
  pointIndex: number,
  scale: number
) {
  if (markingObj.typeDefinition.enclosed || markingObj.typeDefinition.circle) {
    return;
  }
  if (markingObj.points.length <= 1) {
    return;
  }
  if (point.e === null || !point.e) {
    return;
  }
  let anotherPoint: Point;
  if (mou.isFirstPoint(markingObj, point)) {
    anotherPoint = markingObj.points[1];
  } else if (mou.isLastPoint(markingObj, point, pointIndex)) {
    anotherPoint = markingObj.points[markingObj.points.length - 2];
  } else {
    return;
  }
  let h, v;
  if (point.h === anotherPoint.h) {
    h = point.h;
    if (point.v < anotherPoint.v) {
      v = 0;
    } else {
      v = sensorImageResolution.h;
    }
  } else if (point.v === anotherPoint.y) {
    v = point.v;
    if (point.h < anotherPoint.h) {
      h = 0;
    } else {
      h = sensorImageResolution.w;
    }
  } else {
    let ratio: number = (point.v - anotherPoint.v) / (point.h - anotherPoint.h);
    if (Math.abs(ratio) > sensorImageResolution.ratio) {
      if (point.v < anotherPoint.v) {
        v = 0;
      } else {
        v = sensorImageResolution.h;
      }
      h = anotherPoint.h + (v - anotherPoint.v) / ratio;
    } else {
      if (point.h < anotherPoint.h) {
        h = 0;
      } else {
        h = sensorImageResolution.w;
      }
      v = anotherPoint.v + (h - anotherPoint.h) * ratio;
    }
  }

  canvasCtx.beginPath();
  canvasCtx.moveTo(point.h * scale, point.v * scale);
  canvasCtx.lineTo(h * scale, v * scale);
  canvasCtx.strokeStyle = markingObj.typeDefinition.markingLineColor ?? '#333';
  canvasCtx.setLineDash([5, 15]);
  canvasCtx.stroke();
  canvasCtx.closePath();
}

/**
 * Draw a label for a single point.
 */
export function drawLabel(
  canvasCtx: CanvasRenderingContext2D,
  markingObj: MarkingObject,
  point: Point,
  pointIndex: number,
  scale: number
) {
  /**
   * If it's the last point to an enclosed object, we
   * skip the label -- it's already been labeled '1'
   */
  if (
    mou.isLastPoint(markingObj, point, pointIndex) &&
    mou.isActualEnclosed(markingObj)
  ) {
    return;
  }
  let label: string = `${pointIndex + 1}`;
  if (markingObj.typeDefinition.circle) {
    if (markingObj.points[0].d) {
      // label = `${label} (${markingObj.points[0].d}m)`;
      label = `${Math.round(markingObj.points[0].d * 100) / 100}m`;
    }
  }
  canvasCtx.fillStyle = markingObj.typeDefinition.markingLineColor ?? '#ccc';
  canvasCtx.font = drawConfig.labelFont;
  // We look at the point/circle diameter to consider how
  // far away from the point we should draw the label.
  const diameter = markingObj.typeDefinition.circle
    ? drawConfig.circleDiameter
    : drawConfig.pointDiameter;
  const offset = diameter / 2 + 0.6;
  canvasCtx.fillText(
    label,
    (point.h + offset) * scale,
    (point.v - offset) * scale
  );
}

/**
 * Draw a single marking object on the canvas.
 * @param canvasCtx
 * @param markingObj
 * @param scale
 * @param highlight When is true, the points are labeled.
 */
export function drawObj(
  canvasCtx: CanvasRenderingContext2D,
  markingObj: MarkingObject,
  scale: number,
  highlight?: boolean
) {
  let pointsCounter: number = markingObj.points.length;
  let prevPoint: Point = markingObj.points[0];
  for (let i = 0; i < pointsCounter; i++) {
    let currPoint: Point = markingObj.points[i];
    drawPoint(canvasCtx, markingObj, currPoint, i, scale);
    if (highlight) {
      drawLabel(canvasCtx, markingObj, currPoint, i, scale);
    }
    if (!markingObj.typeDefinition.circle) {
      drawLine(
        canvasCtx,
        markingObj.typeDefinition.markingLineColor ?? '#ccc',
        prevPoint,
        currPoint,
        scale
      );
    }
    drawExtendedLine(canvasCtx, markingObj, currPoint, i, scale);
    prevPoint = currPoint;
  }
}

/**
 * Draw a list of marking objects on the canvas.
 * @param canvasCtx
 * @param markingObjs
 * @param scale
 * @param selectedMarkingObjectItemNum
 */
export function drawObjs(
  canvasCtx: CanvasRenderingContext2D,
  markingObjs: MarkingObject[],
  scale: number,
  selectedMarkingObjectItemNum?: number
) {
  for (let i = 0, j = markingObjs.length; i < j; i++) {
    drawObj(
      canvasCtx,
      markingObjs[i],
      scale,
      markingObjs[i].item === selectedMarkingObjectItemNum
    );
  }
}
