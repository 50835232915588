import { AlertSettingForSingleSensorType } from './types';

import { t } from '../../../../lib/i18n';

export function formatEventTypesLabel(als: AlertSettingForSingleSensorType) {
  const elements = [];
  if (
    als.include_technical_event_types &&
    als.include_regular_alert_events &&
    als.include_regular_alarm_events
  ) {
    elements.push(t('manage.alerts.formatEventTypesLabel.all'));
  } else {
    if (als.include_regular_alert_events && als.include_regular_alarm_events) {
      elements.push(t('manage.alerts.formatEventTypesLabel.regular'));
    } else {
      if (als.include_regular_alert_events) {
        elements.push(t('manage.alerts.formatEventTypesLabel.alerts'));
      }
      if (als.include_regular_alarm_events) {
        elements.push(t('manage.alerts.formatEventTypesLabel.alarms'));
      }
    }
    if (als.include_technical_event_types) {
      elements.push(t('manage.alerts.formatEventTypesLabel.technical'));
    }
  }

  if (als.include_custom_event_types) {
    elements.push(t('manage.alerts.formatEventTypesLabel.custom'));
  }

  if (!elements.length) {
    elements.push(t('manage.alerts.formatEventTypesLabel.none'));
  }

  return elements.join(', ');
}
