import { CareGetBehaviorDataResult } from '../../../../serverApi';
import { groupSamplesByStreaks } from './groupSamplesByStreaks';

/**
 *
 * @param data:CareGetBehaviorDataResult
 * @returns Processed data for boolean series chart.
 */

export function processBooleanSeriesData(data: CareGetBehaviorDataResult) {
  const { labels, samples, time } = data;

  return labels.map((label, index) => ({
    label,
    values: groupSamplesByStreaks({
      startTime: time.startTime,
      samplePeriodS: time.samplePeriodS,
      samples: samples.map((x) => x[index]),
    }),
  }));
}
