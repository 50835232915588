import { formatCoord } from '../lib/formatCoord';
import { useHoverPointCoordinates } from '../atoms/useHoverPoint';

import './HoverPointDetails.scss';

export function HoverPointDetails() {
  const hoverPoint = useHoverPointCoordinates();

  if (!hoverPoint) {
    return <>&nbsp;</>;
  }

  return (
    <div className="HoverPointDetails">
      <span></span>
      {/* {props.point?.h !== undefined && (
        <span>h={Math.round(props.point.h * 100) / 100}</span>
      )}
      {props.point?.v !== undefined && (
        <span>v={Math.round(props.point.v * 100) / 100}</span>
      )}
      {props.point?.a !== undefined && (
        <span>a={Math.round(props.point.a * 100) / 100}</span>
      )} */}
      {/*eslint-disable i18next/no-literal-string */}
      {hoverPoint.x !== undefined && (
        <span>X: {formatCoord(hoverPoint.x)}</span>
      )}
      {hoverPoint.y !== undefined && (
        <span>Y: {formatCoord(hoverPoint.y)}</span>
      )}
      {hoverPoint.z !== undefined && (
        <span>Z: {formatCoord(hoverPoint.z)}</span>
      )}
    </div>
  );
}
