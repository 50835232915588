import { useState, useEffect, useRef } from 'react';
import { throttle } from 'lodash';

export function useWindowSize() {
  const mounted = useRef(true);
  const [state, setState] = useState(getSizes());

  useEffect(() => {
    function handleResize() {
      if (mounted.current) {
        setState(getSizes());
      }
    }

    const thr = throttle(handleResize, 50);
    window.addEventListener('resize', thr);
    return () => {
      mounted.current = false;
      window.removeEventListener('resize', thr);
    };
  }, []);

  return state;
}

function getSizes() {
  return {
    width: window.innerWidth,
    isMobile: window.innerWidth < 768,
    isTabletOrMobile: window.innerWidth < 992,
  };
}
