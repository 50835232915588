import React, { useEffect, useState } from 'react';
import ModalWrapper from '../ModalWrapper';
import { StructureZone } from '../../../serverApi';
import { useApiCall } from '../../../lib/useApiCall';
import * as api from '../../../serverApi';
import { TreeView } from '../../TreeView';
import { t } from '../../../lib/i18n';

export interface SelectZoneProps {
  isOpen: boolean;
  activeZone?: StructureZone;
  handleModal: () => void;
  onSelect: (zone?: StructureZone) => void;
}

export default function CareSelectZoneModal(props: SelectZoneProps) {
  const structure = useApiCall(api.getStructure);
  const zone = structure.data && structure.data.zone;

  const [activeZone, setActiveZone] = useState<api.StructureZone | undefined>(
    props.activeZone
  );

  useEffect(() => {
    setActiveZone(props.activeZone);
  }, [props.activeZone]);

  const resetModal = () => {};

  const handleSubmit = () => {
    props.onSelect(activeZone);
    props.handleModal();
  };

  return (
    <ModalWrapper
      title={t('components.CareSelectZoneModal.selectZoneOrDwelling')}
      okButtonText={t('common.commonButtons.select')}
      cancelButtonText={t('common.commonButtons.cancel')}
      className="ModalWrapper-with-tree-view"
      isOpen={props.isOpen}
      onHide={() => props.handleModal()}
      onSubmit={handleSubmit}
      onResetModal={resetModal}
    >
      <div>
        {zone && (
          <TreeView
            root={zone}
            className="CareSelectZone-tree"
            selectZone={setActiveZone}
            activeZone={activeZone}
          />
        )}
      </div>
    </ModalWrapper>
  );
}
