/**
 * To add new languages to the frontend, follow the TODOs below.
 */

import * as Sentry from '@sentry/browser';

/**
 * TODO: When adding new languages, import the new JSON file here
 */
import en from '../../translations/en.json';
import nb from '../../translations/nb.json';
import sv from '../../translations/sv.json';
import da from '../../translations/da.json';
import fr from '../../translations/fr.json';
import de from '../../translations/de.json';
import es from '../../translations/es.json';

import * as yup from 'yup';

import { get } from 'lodash';

import moment from 'moment';
import { formatString } from './formatString';
import { FeatureFlagKey } from '../../serverApi';
import { captureMessage } from '../global/sentry';

/**
 * TODO: When adding new languages, add new moment locales here as well when needed.
 */
import 'moment/locale/nb';
import 'moment/locale/sv';
import 'moment/locale/fr';
import 'moment/locale/de';
import 'moment/locale/da';
import 'moment/locale/en-gb';

/**
 * The currently valid locales, including those hidden behind feature flags.
 * TODO: When adding new languages, add new the language key here.
 */
export type Locale = 'en' | 'nb' | 'sv' | 'da' | 'fr' | 'de' | 'es';

/**
 *
 * These are the language definitions of the frontend.
 *
 * TODO: When adding new languages, add new the language definition
 *       here, connecting the language key to the JSON file.
 *
 */
export const localeDefinitions: {
  [key in Locale]: {
    strings: typeof en;
    feature?: FeatureFlagKey; // If this is set, the language option is only shown to users with the given feature flag
  };
} = {
  en: {
    strings: en,
  },
  nb: {
    strings: nb,
  },
  sv: {
    strings: sv,
  },
  da: {
    strings: da,
  },
  fr: {
    strings: fr,
  },
  de: {
    strings: de,
  },
  es: {
    strings: es,
  },
};

/**
 * The valid translation keys. We only look at `nb` for this, as we already
 * have other mechanisms (tests, type checking) to ensure that all locales
 * contain the same keys.
 */
export type TranslationKey = keyof typeof nb;

/**
 * The available locales, in the order in which they should be shown in the language selector
 */
export const locales = Object.keys(localeDefinitions) as Locale[];

/**
 * Whether the provided string is a valid language locale.
 */
export function isLocale(locale: string): locale is Locale {
  return locale in localeDefinitions;
}

/**
 * Override the `en` locale in moment to have the same week settings
 * as Norwegian locale (week starts on Monday=1, first week of the year
 * is the first week with Thursday=4)
 */
moment.updateLocale('en', {
  week: {
    dow: 1,
    doy: 4,
  },
});

/**
 *
 *
 * Get the current global locale.
 *
 *
 */
export function getGlobalLocale(): Locale {
  return localeKey;
}

/**
 *
 *
 * Set the new global locale.
 *
 */
export function setGlobalLocale(locale: Locale | string) {
  if (!isLocale(locale)) {
    throw new Error(`Invalid locale: ${locale}`);
  }

  /**
   * Assign the right language to `t`
   */
  localeKey = locale;

  localeStrings = localeDefinitions[locale].strings;

  /**
   * Assign the right language to moment
   */
  moment.locale(locale);

  /**
   * Assign the right language to yup
   */
  yup.setLocale({
    mixed: {
      required: t('yup.mixed.required'),
    },
    string: {
      email: t('yup.string.email'),
    },
  });

  /**
   * Assign the right language to Sentry
   */
  Sentry.configureScope((scope) => {
    scope.setTag('page_locale', locale);
  });
}

/**
 *
 *
 * The `t` variable will always point to the currently active locale.
 * Note: Since this variable is outside the React component tree, any
 * changes will NOT be automatic and reactive. The I18nProvider must
 * be used in order to trigger remounts when the language changes.
 *
 */
let localeStrings = nb;
let localeKey: Locale = 'nb';

export function hasTranslationKey(key: string): key is TranslationKey {
  return key in localeStrings;
}

export const t = (key: TranslationKey, arg?: any) => {
  // If the key does not exist at all, return the key itself.
  if (key in localeStrings === false) {
    const msg = `Could not find key in translations (${localeKey}): ${key}`;
    console.warn(msg);
    captureMessage(msg);
    //
    // Once we are sure we aren't picking up the above messages anymore,
    // this whole fallback block below can be removed.
    const fallback = get(localeStrings, key);
    if (!fallback) {
      console.warn('Could not find translation for', key);
      return key;
    } else {
      // console.warn('I18N Fallback', key); // Too many of these for the time being!
      if (typeof fallback === 'string') {
        return formatString(fallback, arg);
      } else if (typeof fallback === 'object') {
        const valueKey = `${arg}` as keyof typeof fallback;
        if (arg !== undefined && valueKey in fallback) {
          return formatString(fallback[valueKey], arg);
        } else if ('other' in fallback) {
          return formatString(fallback.other, arg);
        } else {
          console.warn(
            'Translation object for',
            key,
            'contains neither a matching key nor an other key.',
            'Argument:',
            arg
          );
          return key;
        }
      }
      return fallback;
    }
  }

  // Trans is now the translation -- either a string or an object (for pluralizations etc)
  const trans = localeStrings[key as keyof typeof localeStrings] as
    | string
    | {
        [key: string]: string;
      };

  // For calls without any arguments, we can only return the translation string directly ...
  if (arg === undefined) {
    if (typeof trans === 'string') {
      return trans;
    }
    // Or show an error and return the key.
    console.warn(
      'Translation for',
      key,
      'was called without parameters. Expected a simple string, got',
      trans
    );
    return key;
  }

  let translationString = '';

  // The call includes one or more arguments
  if (typeof trans === 'string') {
    // No pluralization object, we use the translation string as-is.
    translationString = trans;
  } else if (typeof trans === 'object') {
    const valueKey = `${arg}` as keyof typeof trans;
    if (valueKey in trans) {
      translationString = trans[valueKey];
    } else if ('other' in trans) {
      translationString = trans.other;
    } else {
      console.warn(
        'Translation object for',
        key,
        'contains neither a matching key nor an other key.'
      );
      return key;
    }
  } else {
    console.warn(
      'Translation for',
      key,
      'failed, expected object or string, got',
      trans
    );
  }
  try {
    return formatString(translationString, arg);
  } catch (err) {
    console.warn('Translation for', key, 'failed, invalid format string:', err);
    return key;
  }
};

// const getBrowserLanguage = () => {
//   return navigator.language || ((navigator as any).userLanguage as string);
// };

/**
 *
 *
 * We start with 'nb' as default.
 *
 */
export const fallbackLocale: Locale = 'nb';

setGlobalLocale(fallbackLocale);

/**
 * Get a list of all translation keys
 */
export function getAllTranslationKeys(): TranslationKey[] {
  return Object.keys(localeStrings) as TranslationKey[];
}

/**
 * Get the name of the language defined in the language itself.
 */
export function getTranslatedLanguageName(locale: Locale): string {
  return localeDefinitions[locale].strings[
    `common.commonLanguages.languages.${locale}`
  ];
}
