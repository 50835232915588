import { CSSProperties, useEffect, useMemo, useRef } from 'react';
import * as d3 from 'd3';
import { useWindowSize } from '../../../lib/useWindowSize';

interface Props {
  data: any[];
  keys: string[];
  colors: { [key: string]: string };
  xValue: (data: any) => any;
  yValue: (data: any, key: string) => any;
  yLabel?: string;
  /**
   * yIcon prop is used to have an optional icon in the beginning of the Y-AXIS
   * Using string type for yIcon because d3.html() method that it's being used to render it
   * accepts only parameter of type string that should consist of valid html
   */
  yIcon?: string;
  settings: {
    width?: number;
    height: number;
    margin: {
      top: number;
      right: number;
      bottom: number;
      left: number;
    };
  };
  style?: CSSProperties;
  xScale: d3.ScaleTime<number, number, never>;
}

export const AreaChart = (props: Props) => {
  const { width = 1, height, margin } = props.settings;
  const { data, xValue, yValue, yLabel, yIcon, xScale, keys, colors } = props;
  const { isMobile } = useWindowSize();
  const ref = useRef<SVGSVGElement>(null);

  /**
   * Create memoized y scale to avoid re-computing it every time the chart is updated.
   */

  const yScale = useMemo(() => {
    return d3
      .scaleLinear()
      .domain([0, 100])
      .domain([
        0,
        Math.max(
          100,
          d3.max(data, (d) => {
            /** Remove date field from data */
            const values: any = Object.values(d).slice(1);
            /** Return the highest value from data object */
            return Math.max(...values) as any;
          })
        ),
      ])
      .range([height - margin.bottom, margin.top]);
  }, [height, margin.bottom, margin.top, data]);

  /**
   * Draw area chart path using d3 library
   */
  useEffect(() => {
    if (width <= 1) {
      return;
    }
    /**
     * Select svg element and set width and height that come from props
     */
    const svg = d3
      .select(ref.current)
      .attr('width', width)
      .attr('height', height)
      .attr(
        'margin',
        `${margin.top} ${margin.right} ${margin.bottom} ${margin.left}`
      );

    /**
     * Before drawing the chart, remove previous chart elements if any.
     * This is useful when chart is being updated, so we don't end up drawing the same chart twice.
     */

    svg.selectAll('*').remove();

    /**
     * Create clip-path element to clip the chart area.
     * We'll use this so that the chart doesn't overflow the svg element.
     */
    svg
      .append('clipPath')
      .attr('id', 'chart-path')
      .append('rect')
      .attr('x', margin.left)
      .attr('y', 0)
      .attr('height', height)
      .attr('width', width - margin.left - margin.right);

    const gy = svg.append('g');
    const gx = svg.append('g');

    const xAxis = (
      g: d3.Selection<SVGGElement, any, any, any>,
      x: d3.ScaleTime<number, number, never>,
      height: number
    ) =>
      g
        .attr('class', 'BehavioralDataChart__axis BehavioralDataChart__axis--x')
        .attr('transform', `translate(0,${height - margin.bottom + 1.5})`)
        .attr('width', width)
        .attr('height', height)
        .style('stroke-width', 3)
        .call(d3.axisBottom(x).tickSize(0).tickValues([]));

    const yAxis = (
      g: d3.Selection<SVGGElement, any, any, any>,
      y: d3.ScaleLinear<number, number, never>,
      title: string
    ) =>
      g
        .attr('transform', `translate(${margin.left},0)`)
        .attr('class', 'BehavioralDataChart__axis BehavioralDataChart__axis--y')
        .attr('fill', 'currentColor')
        .call(d3.axisLeft(y))
        .call((g) => g.select('.domain').remove())
        .call((g) =>
          g
            .selectAll('.title')
            .data([title])
            .join('text')
            .attr('class', 'title')
            .attr('x', -margin.left)
            .attr('y', 10)
            .attr('fill', 'currentColor')
            .attr('text-anchor', 'start')
            .text(yLabel || '')
        );

    /**
     * Draw y axis and render custom tick labels.
     */
    gx.call(xAxis, xScale, height);
    gy.call(yAxis, yScale, width as any) // This started to give `TS2345: Argument of type 'number' is not assignable to parameter of type 'string'.` in Oct 2023
      .selectAll('.tick')
      .each((...args) => renderCustomYAxisTicks(...args, isMobile, yIcon));

    const selectX = (data: any) => xScale(xValue(data));
    const selectY = (data: any, key: string) => {
      return yScale(yValue(data, key));
    };

    const createPath = (key: string, fill: string) => {
      return svg
        .append('path')
        .attr('id', `chart-${key}`)
        .datum(data)
        .attr('clip-path', 'url(#chart-path)')
        .attr('fill', fill)
        .attr(
          'd',
          d3
            .area()
            .defined((d: any) => !isNaN(d[key]))
            .x(selectX)
            .y0(yScale(d3.min(data, (d) => yValue(d, key))))
            .y1((d) => {
              return selectY(d, key);
            })
        );
    };

    keys.forEach((key) => {
      createPath(key, colors[key]);
    });
  }, [
    width,
    height,
    margin,
    yLabel,
    data,
    xValue,
    yValue,
    yIcon,
    yScale,
    xScale,
    isMobile,
    keys,
    colors,
  ]);

  return (
    <svg ref={ref} viewBox={`0 0 ${width} ${height}`} style={props.style} />
  );
};

/**
 * Function used to render custom y axis ticks
 *
 * @param value - tick value
 * @param index
 * @param list - tick node list
 * @returns
 */
const renderCustomYAxisTicks = (
  value: unknown,
  index: number,
  list: d3.BaseType[] | d3.ArrayLike<d3.BaseType>,
  isMobile: boolean,
  yIcon?: string
) => {
  const tick = d3.select(list[index]);

  tick.selectAll('line').remove(); // Remove all line elements from ticks
  /**
   * We render an icon on the first tick if there's a yIcon prop provided
   */
  if (index === 0 && yIcon) {
    tick
      .html(yIcon)
      .select('svg')
      .attr('height', isMobile ? '30px' : '42px')
      .attr('width', isMobile ? '30px' : '42px')
      .attr('x', isMobile ? -35 : -47)
      .attr('y', -21);
    return;
  }
  /**
   * Render small circles on the other ticks, except the last one (we display the value on the last tick).
   */
  if (index < list.length - 1) {
    tick.selectAll('*').remove(); //Remove all child elements before appending circle element

    tick
      .append('circle')
      .attr('r', 3)
      .attr('cx', isMobile ? -19 : -25)
      .attr('cy', 0)
      .style('fill', '#b6b6b3');
  }
};
