import React, { CSSProperties } from 'react';
import cs from 'classnames';

import './Card.scss';

interface Props {
  children?: React.ReactNode;
  noPadding?: boolean;
  className?: string;
  style?: CSSProperties;
}

export function Card(props: Props) {
  return (
    <div
      className={cs(
        'Card',
        props.noPadding ? 'Card--no-padding' : null,
        props.className
      )}
      style={props.style}
    >
      {props.children}
    </div>
  );
}
