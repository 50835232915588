type CompareFunction = (a: string, b: string) => number;

interface SortableZone {
  name?: string;
}

export function sortZones(zones: SortableZone[]) {
  const strcmp: CompareFunction =
    typeof Intl === 'object'
      ? new Intl.Collator('nb', {
          numeric: true,
          sensitivity: 'base',
        }).compare
      : typeof String.prototype.localeCompare !== 'undefined'
      ? (a, b) => a.localeCompare(b)
      : (a, b) => (a === b ? 0 : a > b ? 1 : -1);

  return zones.sort((a, b) => strcmp(a.name ?? '', b.name ?? ''));
}
