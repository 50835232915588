import { SSEProvider } from 'react-hooks-sse';
import * as api from '../../../serverApi';

interface Props {
  sensors: api.GetSensorsForZoneResult['sensors'];
  children: React.ReactNode;
}
export const SensorWifiStrengthSSEProvider = (props: Props) => {
  const sensorsIds = props.sensors
    .filter((sensor) => sensor.sensor_type === 'roommate')
    .map((sensor) => sensor.sensor_id);

  function createEventSource(sensorIds: string[]): EventSource {
    const rootURL = api.getApiRoot();
    const endpoint = `${rootURL}/api/stream/wireless/rssi?sensor_ids=${sensorIds}`;
    const source = new EventSource(endpoint, { withCredentials: true });
    return source;
  }

  if (props.sensors.length === 0) {
    return <>{props.children}</>;
  }

  return (
    <SSEProvider source={() => createEventSource(sensorsIds)}>
      {props.children}
    </SSEProvider>
  );
};
