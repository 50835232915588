import { useAppContext } from '../lib/global';
import { FeatureFlagKey } from '../serverApi';

interface Props {
  feature: FeatureFlagKey;
  children?: any;
  fallback?: any;
}

export function Feature(props: Props) {
  const app = useAppContext();

  if (app.hasFeature(props.feature)) {
    return props.children;
  }

  /**
   * No feature flag set; return null.
   */
  return props.fallback || null;
}
