import { FormikHelpers, FormikValues, useFormik } from 'formik';
import { useZoneContext } from '../../../../lib/ZoneContext';

import * as api from '../../../../serverApi';
import { generatePassword } from '../../../../lib/utils/generatePassword';

export interface PermissionsFields {
  username: string | null;
  password: undefined | api.CreateUserPassword;
  first_name: string | null;
  last_name: string | null;
  email: string | null;
  phone: string | null;
  national_id_number: string | null;
  generate_app: boolean | null;
  roles: api.CreateUserRoleInput[];
  preferred_locale: string;
  only_personal_devices_for_otp: boolean | null;
  external_idp: boolean;
}

export type PermissionsFormik = ReturnType<typeof usePermissionsFormik>;

interface UsePermissionsFormOptions {
  initialValues?: Partial<PermissionsFields>;
  validationSchema?: any | (() => any);
  onSubmit: (
    values: FormikValues,
    formikHelpers: FormikHelpers<PermissionsFields>
  ) => Promise<any>;
}

/**
 * Formik wrapper for event settings forms.
 */
export function usePermissionsFormik(opts: UsePermissionsFormOptions) {
  const ctx = useZoneContext();

  return useFormik<PermissionsFields>({
    initialValues: {
      username: '',
      password: {
        notify: 'email',
        password: generatePassword(),
      },
      first_name: '',
      last_name: '',
      email: '',
      phone: '',
      national_id_number: '',
      generate_app: false,
      preferred_locale: 'null',
      only_personal_devices_for_otp: false,
      external_idp: false,
      roles: [
        {
          role: '',
          zone_id: ctx.activeZone.id,
          valid_from: null,
          valid_to: null,
        },
      ],
      ...opts.initialValues,
    },
    validateOnChange: true,
    validateOnBlur: true,
    validationSchema: opts.validationSchema,
    onSubmit: async (values, formikHelpers) => {
      await opts.onSubmit(values, formikHelpers);
    },
  });
}
