import React from 'react';
import { IconDefinition, Icon } from '../../../components/Icon';
import { t } from '../../../lib/i18n';

import './IconLegendHelp.scss';

interface LegendProps {
  icon: IconDefinition;
  label: string;
  color?: string;
}

const Legend = (props: LegendProps) => (
  <div className="IconLegendHelp-legend">
    <Icon icon={props.icon} style={{ color: props.color }} className="mr-2" />
    <span style={{ lineHeight: '32px' }}>{props.label}</span>
  </div>
);

interface Props {
  hideTitle?: boolean;
}
export function IconLegendHelp(props: Props) {
  return (
    <div className="IconLegendHelp">
      {props.hideTitle ? null : (
        <h1>{t('care.components.IconLegendHelp.symbolExplanations')}</h1>
      )}

      <h2>{t('care.components.IconLegendHelp.infoAndSystem')}</h2>

      <Legend
        icon="info"
        label={t('care.components.IconLegendHelp.latestEvents')}
      />
      <Legend
        icon="roommate-sensor"
        label={t('care.components.IconLegendHelp.anonymousSupervision')}
        color="#ff8422"
      />
      <Legend
        icon="roommate-sensor"
        label={t(
          'care.components.IconLegendHelp.anonymizedSupervisionWithoutColors'
        )}
      />
      <Legend
        icon="roommate-sensor-outline"
        label={t('care.components.common.detailedImage')}
      />
      <Legend
        icon="ear"
        label={t('care.components.common.anonymousListened')}
        color="#ff8422"
      />
      <Legend
        icon="ear"
        label={t('care.components.IconLegendHelp.intrusion')}
      />
      <Legend
        icon="telephone"
        label={t('care.components.IconLegendHelp.conversation')}
      />
      <Legend
        icon="epj"
        label={t('care.components.IconLegendHelp.electronicHealthRecord')}
      />
      <Legend
        icon="check"
        label={t('care.components.IconLegendHelp.receiveEvent')}
      />

      <h2>{t('care.components.IconLegendHelp.roomStatus')}</h2>

      <Legend
        icon="person"
        label={t('care.components.IconLegendHelp.onePersonInTheRoom')}
      />
      <Legend
        icon="persons"
        label={t('care.components.IconLegendHelp.twoPeopleInTheRoom')}
      />
      <Legend
        icon="bed-sleeping"
        label={t('care.components.IconLegendHelp.personInBed')}
      />
      <Legend
        icon="chair-sitting"
        label={t('care.components.IconLegendHelp.personInTheChair')}
      />

      <h2>{t('care.components.IconLegendHelp.alarmAndAlert')}</h2>

      <Legend icon="fall" label={t('common.commonEvents.roommate.fall')} />
      <Legend
        icon="bed-microactivity"
        label={t('common.commonEvents.roommate.lmib')}
      />
      <Legend icon="bed-exit" label={t('common.commonEvents.roommate.oob')} />
      <Legend
        icon="bed-upright"
        label={t('common.commonEvents.roommate.hib')}
      />
      <Legend icon="chair-exit" label={t('common.commonEvents.roommate.ooc')} />
      <Legend icon="room-enter" label={t('common.commonEvents.roommate.ent')} />
      <Legend icon="room-exit" label={t('common.commonEvents.roommate.exi')} />
      <Legend
        icon="door-open"
        label={t('care.components.IconLegendHelp.doorOpened')}
      />
      <Legend icon="activity" label={t('common.commonEvents.roommate.act')} />
      <Legend icon="sound" label={t('common.commonEvents.roommate.hsnd')} />
      <Legend
        icon="wrist-button"
        label={t('care.components.IconLegendHelp.wristButton')}
      />
      <Legend
        icon="panic-button"
        label={t('care.components.IconLegendHelp.alarmButton')}
      />
      <Legend
        icon="pull-cord"
        label={t('care.components.IconLegendHelp.pullCord')}
      />
      <Legend
        icon="timeout"
        label={t('care.components.IconLegendHelp.timeAlert')}
      />
      <Legend
        icon="battery-low"
        label={t('care.components.IconLegendHelp.lowBattery')}
      />
      <Legend
        icon="disconnected"
        label={t('care.components.IconLegendHelp.lostContactWithSensor')}
      />
      <Legend
        icon="connected"
        label={t('care.components.IconLegendHelp.restoredContactWithSensor')}
      />
    </div>
  );
}
