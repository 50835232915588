import React, { useEffect, useState } from 'react';
import * as H from 'history';
import { Prompt } from 'react-router';
import { useRouter } from '../lib/useRouter';
import ConfirmationModal from './modals/confirmation/ConfirmationModal';
import { useBeforeUnloadConfirmation } from '../lib/useBeforeUnloadConfirmation';
import { t } from '../lib/i18n';

interface Props {
  when: boolean;
  message?: string;
}

export const RouteLeavingGuard = (props: Props) => {
  // console.log('RouteLeavingGuard', props.when);
  useBeforeUnloadConfirmation(props.when);

  const router = useRouter();
  const [isOpen, setIsOpen] = useState(false);
  const [confirmed, setConfirmed] = useState(false);
  const [nextLocation, setNextLocation] = useState<string | null>(null);

  useEffect(() => {
    if (confirmed && props.when && nextLocation) {
      router.history.push(nextLocation);
    }
  }, [confirmed]); // eslint-disable-line react-hooks/exhaustive-deps

  function handlePrompt(location: H.Location<unknown>) {
    //TODO: Fix typescript and get _isLoggingOut_ value from location.state.isLoggingOut.
    const stateKeys = location.state ? Object.keys(location.state as {}) : [];
    if (!stateKeys.includes('isLoggingOut') && !confirmed && props.when) {
      setIsOpen(true);
      setNextLocation(location.pathname);
      return false;
    }

    return true;
  }

  const handleCancel = () => {
    setIsOpen(false);
    setConfirmed(false);
  };

  const handleConfirm = () => {
    setIsOpen(false);
    setConfirmed(true);
  };

  return (
    <>
      <Prompt when={props.when} message={handlePrompt} />

      <ConfirmationModal
        showCloseButton={false}
        confirmationData={{
          text: t(
            'common.commonTexts.youAreAboutToLeaveThePageWithoutSavingTheChangesYouHaveMade'
          ),
          isOpen: isOpen,
          onCancel: handleCancel,
          onConfirm: handleConfirm,
        }}
      />
    </>
  );
};
