import React, { useState } from 'react';
import { useQueryClient } from 'react-query';

import { useSensorAdvancedForm } from './useSensorAdvancedForm';
import { SensorAdvancedForm } from './SensorAdvancedForm';
import { SensorContext, useSensorContext } from '../../../../lib/SensorContext';
import { IconButton } from '../../../../components/IconButton';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCaretDown, faCaretUp } from '@fortawesome/pro-solid-svg-icons';
import { useErrorHandler } from '../../../../lib/useErrorHandler';
import { ErrorMessages } from '../../../../components/ErrorMessages';
import { InlineSpinner } from '../../../../components/InlineSpinner';

import * as serverApi from '../../../../serverApi';
import { t } from '../../../../lib/i18n';

import './SensorAdvancedFormButton.scss';

interface SensorAdvancedFormButtonProps {
  onChanged?: () => void;
}

export const SensorAdvancedFormButton = (
  props: SensorAdvancedFormButtonProps
) => {
  const sensor = useSensorContext();
  const sensorSettings = serverApi.useGetSensorSettingsQuery(sensor.id);

  if (sensorSettings.isLoading) {
    return (
      <div className="mt-4">
        <InlineSpinner
          text={t(
            'manage.sensors.details.SensorAdvancedFormButton.loadingSensorSettings'
          )}
        />
      </div>
    );
  }

  return (
    <div className="SensorAdvancedFormButton my-4">
      {!sensorSettings.data ? null : (
        <SensorAdvancedFormButtonComponent
          loading={sensorSettings.isLoading}
          sensorSettings={sensorSettings.data}
          sensor={sensor}
          onChanged={() => {
            if (props.onChanged) {
              props.onChanged();
            }
          }}
        />
      )}
    </div>
  );
};

interface Props {
  sensorSettings: serverApi.SensorSetting;
  sensor: SensorContext;
  loading?: boolean;
  onChanged?: () => void;
}

const SensorAdvancedFormButtonComponent = (props: Props) => {
  const { sensor } = props;
  const errorHandler = useErrorHandler();
  const queryClient = useQueryClient();
  const {
    mutateAsync: updateSensorSettingMutation,
  } = serverApi.useUpdateSensorSettingMutation({
    onSuccess: (data) => {
      const sensorSettingKey = serverApi.QueryKeys.getSensorSettings(sensor.id);
      queryClient.setQueryData(sensorSettingKey, data.sensor_setting);
    },
  });

  const [expanded, setExpanded] = useState(false);

  const values = props.sensorSettings;

  const form = useSensorAdvancedForm({
    initialValues: {
      demo_mode: values.demo_mode,
      modulation_frequency: values.modulation_frequency ?? 15000000,
      sensor_algorithm_config_bits: values.sensor_algorithm_config_bits,
    },
    sensorType: sensor.sensor_type,
    onSubmit: async (val, formikHelpers) => {
      try {
        await updateSensorSettingMutation({
          sensorCompositeId: sensor.id,
          data: val,
        });

        formikHelpers.resetForm({
          values,
        });

        if (props.onChanged) {
          props.onChanged();
        }
      } catch (e) {
        errorHandler.handleError(e);
      }
    },
  });

  const reboot = async () => {
    try {
      await serverApi.rebootSensor(sensor.id);
    } catch (e) {
      errorHandler.handleError(e);
    }
  };

  return (
    <>
      <IconButton
        icon="gear"
        spinIcon={props.loading}
        onClick={() => setExpanded(!expanded)}
      >
        {t('manage.sensors.details.SensorAdvancedFormButton.seeAdvanced')}
        <FontAwesomeIcon
          style={{ marginLeft: 5 }}
          icon={expanded ? faCaretUp : faCaretDown}
        />
      </IconButton>

      {expanded && (
        <SensorAdvancedForm
          form={form}
          onRebootRequest={reboot}
          sensorType={sensor.sensor_type}
        />
      )}

      <ErrorMessages errorData={errorHandler} />
    </>
  );
};
