import React from 'react';
import { Slide, ToastContainer } from 'react-toastify';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimesCircle } from '@fortawesome/pro-light-svg-icons';

const CloseToast = (props: any) => (
  <span role="button" onClick={props.closeToast}>
    <FontAwesomeIcon
      icon={faTimesCircle}
      className="Toastify__toast-close-btn"
    />
  </span>
);

export const ToastContainerComponent = () => {
  return (
    <ToastContainer
      draggable
      closeOnClick
      newestOnTop
      pauseOnHover
      rtl={false}
      hideProgressBar={false}
      autoClose={4000}
      transition={Slide}
      position="top-center"
      closeButton={<CloseToast />}
    />
  );
};
