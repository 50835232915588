import React from 'react';
import classNames from 'classnames';

import './InlineError.scss';

interface Props {
  children: React.ReactNode;
  className?: string;
}

export const InlineError = (props: Props) => (
  <div className={classNames('InlineError', props.className)}>
    {props.children}
  </div>
);
