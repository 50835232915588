import React from 'react';
import {
  FormGroup,
  InputAdornment,
  MenuItem,
  TextField,
} from '@material-ui/core';
import { useApiCall } from '../../../../lib/useApiCall';
import { Icon } from '../../../../components/Icon';

import * as api from '../../../../serverApi';
import { sortAccountsAlphabetically } from '../../../../lib/utils/sortAccountsAlphabetically';

interface Props {
  className?: string;
  error?: string;
  disabled?: boolean;
  accountId: number | undefined;
  accountTypes?: null | 'onlyVendors' | 'noVendors' | 'all';
  onSelect: (accountId: number) => void;
}

const params = {};

export const SelectAccount = (props: Props) => {
  const res = useApiCall(api.superVendorGetAccounts, [params]);

  const at = props.accountTypes ?? 'noVendors';

  const allAccounts = res.data ? sortAccountsAlphabetically(res.data.data) : [];

  const accounts =
    at === 'all'
      ? allAccounts
      : allAccounts.filter((a) => a.is_vendor === (at === 'onlyVendors'));

  const hasValue = accounts.find((a) => a.account_id === props.accountId);

  return (
    <FormGroup className={props.className}>
      <TextField
        select={true}
        disabled={res.loading || props.disabled}
        name="account"
        label="Account"
        value={hasValue && props.accountId ? props.accountId : ''}
        onChange={(e) => {
          props.onSelect(parseInt(e.target.value));
        }}
        error={props.error !== undefined}
        helperText={props.error}
        // inputProps={accounts ? accounts[0] : undefined}
        InputProps={{
          endAdornment: res.loading && (
            <InputAdornment position="end">
              <Icon
                icon="gear"
                spin
                style={{
                  width: 17,
                  marginRight: 20,
                  color: '#9b9b9b',
                }}
              />
            </InputAdornment>
          ),
        }}
      >
        {accounts ? (
          accounts.map((acc) => (
            <MenuItem key={acc.account_id} value={acc.account_id}>
              {acc.name}
            </MenuItem>
          ))
        ) : (
          <MenuItem value="" disabled>
            No available accounts
          </MenuItem>
        )}
      </TextField>
    </FormGroup>
  );
};
