import React from 'react';
import { RouteComponentProps, Switch } from 'react-router';
import { Sensors as SuperSensorVendors } from '../../supervendor/sensors/Sensors';

export function Sensors(props: RouteComponentProps<{}>) {
  return (
    <Switch>
      <SuperSensorVendors rootUrl={props.match.path} />
    </Switch>
  );
}
