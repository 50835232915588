import React, { CSSProperties } from 'react';
import cs from 'classnames';
import { InlineSpinner } from '../../../../components/InlineSpinner';
import { IconButton } from '../../../../components/IconButton';

import { t } from '../../../../lib/i18n';

import './Pager.scss';

interface Props {
  pager?: {
    // from?: number;
    // to?: number;
    current_page?: number;
    // per_page?: number;
    // total: number;
    last_page?: number;
  };
  onPageChange?: (page: number) => void;
  className?: string;
  loading?: boolean;
  style?: CSSProperties;
}

export function Pager(props: Props) {
  const p = props.pager;

  if (props.loading && !p) {
    return <InlineSpinner className="my-3" text="Laster" />;
  }

  if (!p || !p.current_page || !p.last_page) {
    return null;
  }

  function nextPage() {
    if (
      props.onPageChange &&
      p &&
      p.current_page &&
      p.last_page &&
      p.current_page < p.last_page
    ) {
      props.onPageChange(p.current_page + 1);
    }
  }

  function prevPage() {
    if (props.onPageChange && p && p.current_page && p.current_page > 1) {
      props.onPageChange(p.current_page - 1);
    }
  }

  return (
    <div className={cs('Pager', props.className)} style={props.style}>
      <IconButton
        hideLabel={true}
        icon="arrow-left"
        onClick={prevPage}
        disabled={p.current_page < 2 || props.loading}
      >
        {t('manage.logs.ui.Pager.lastPage')}
      </IconButton>

      <span className="ml-3 mr-4">
        {t('manage.logs.ui.Pager.page')} {p.current_page}{' '}
        {t('manage.logs.ui.Pager.of')} {p.last_page}
      </span>

      <IconButton
        hideLabel={true}
        icon="arrow-right"
        onClick={nextPage}
        disabled={p.current_page >= p.last_page || props.loading}
      >
        {t('manage.logs.ui.Pager.nextPage')}
      </IconButton>
    </div>
  );
}
