import { useState } from 'react';
import { RouteComponentProps } from 'react-router';
import { toast } from 'react-toastify';

import { IconButton, InputAdornment, TextField } from '@material-ui/core';
import { Visibility, VisibilityOff, Close } from '@material-ui/icons';

import { Button } from '../../../components/Button';
import { generatePassword } from '../../../lib/utils/generatePassword';
import { Card } from '../../super/shared/Card';
import HeadingBar from '../../super/shared/HeadingBar';

import { t } from '../../../lib/i18n/i18n';
import { superVendorIntegrationChangePassword } from '../../../serverApi';
import { useResetIntegrationUserPasswordFormik } from './lib/useResetIntegrationUserPasswordFormik';
interface Props {
  id: string;
}

export const ResetIntegrationUserPassword = (
  props: RouteComponentProps<Props>
) => {
  const [showPassword, setShowPassword] = useState(false);

  const listIntegrationsURL = props?.match?.url?.substring(
    0,
    props.match.url.indexOf('reset-password') - 1
  );

  const handleGeneratePassword = () => {
    formik.setFieldValue('password', generatePassword('integration'));
  };

  const formik = useResetIntegrationUserPasswordFormik({
    onSubmit: async (values, formikHelpers) => {
      try {
        await superVendorIntegrationChangePassword(props.match.params.id, {
          password: values.password,
        });
        toast.success(t('user.ChangePasswordForm.passwordChangedSuccessfully'));
        formikHelpers.resetForm();
      } catch (e) {
        toast.error(t('user.ChangePasswordForm.errorWhileChangingPassword'));
      }
    },
  });

  return (
    <div className="ResetIntegrationUserPassword">
      <HeadingBar>
        <h2>{t('publicComponents.ResetPassword.resetPassword')}</h2>
        <Button to={listIntegrationsURL}>
          {t('supervendor.Integration.backToIntegrations')}
        </Button>
      </HeadingBar>
      <Card>
        <form onSubmit={formik.handleSubmit}>
          <div className="d-flex align-items-center mb-2">
            <TextField
              className="mr-2"
              type={showPassword ? 'text' : 'password'}
              label="Password"
              name="password"
              style={{ width: '100%', maxWidth: 500 }}
              disabled={formik.isSubmitting}
              error={formik.touched.password && !!formik.errors.password}
              helperText={formik.touched.password && formik.errors.password}
              value={formik.values.password}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    {formik.values.password && (
                      <IconButton
                        aria-label="Clear value"
                        onClick={() => formik.setFieldValue('password', '')}
                        size="small"
                      >
                        <Close />
                      </IconButton>
                    )}
                    <IconButton
                      className="ml-2"
                      size="small"
                      aria-label="Toggle password visibility"
                      onClick={() => setShowPassword(!showPassword)}
                    >
                      {showPassword ? <VisibilityOff /> : <Visibility />}
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />
            <Button onClick={handleGeneratePassword}>Generate password</Button>
          </div>

          <Button
            className="mt-3 d-block"
            type="submit"
            disabled={!formik.dirty || !formik.isValid || formik.isSubmitting}
            spinIcon={formik.isSubmitting}
          >
            Reset
          </Button>
        </form>
      </Card>
    </div>
  );
};
