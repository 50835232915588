import { useEffect, useState } from 'react';

/**
 * A simple countdown timer. This uses setTimeout one second
 * at a time, and keeps track of the time left in the state.
 * It cannot be assumed to be precise.
 */
export const useCountdown = () => {
  const [secondsRemaining, setSecondsRemaining] = useState<
    number | undefined
  >();

  /**
   * Start the timer by providing the number of seconds
   * we want to count from
   *
   * Changing the timeleft state will trigger the useEffect below
   * and start a
   * @param seconds
   */
  const startCountdown = (seconds: number) => {
    setSecondsRemaining(seconds);
  };

  /**
   * Keep track of time left and keep decreasing
   * if it has not expired yet
   */
  useEffect(() => {
    if (secondsRemaining === undefined) {
      return;
    }
    /**
     * If countdown has reached 0, we set the state back to undefined
     * which indicates that there's no active timer
     */
    if (secondsRemaining === 0) {
      setSecondsRemaining(undefined);
    }

    const timer = setTimeout(() => {
      setSecondsRemaining(secondsRemaining - 1);
    }, 1000);

    return () => {
      clearTimeout(timer);
    };
  }, [secondsRemaining]);

  return { secondsRemaining, startCountdown };
};
