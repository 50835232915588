import React from 'react';

import { EventSettingsForm } from '../useEventSettingsForm';
import { Button } from '../../../../../components/Button';
import { ButtonGroup } from '../../../../../components/ButtonGroup';
import { t } from '../../../../../lib/i18n';

export default function EventFormControls(props: { form: EventSettingsForm }) {
  const { form } = props;
  return (
    <ButtonGroup className="my-5">
      <Button
        type="submit"
        color="primary"
        disabled={form.isSubmitting}
        icon={form.isSubmitting ? 'gear' : undefined}
        spinIcon
      >
        {t('common.commonButtons.save')}
      </Button>

      <Button
        onClick={() => {
          form.resetForm();
        }}
      >
        {t('common.commonButtons.cancel')}
      </Button>
    </ButtonGroup>
  );
}
