export default function formatMinutePeriod(seconds: number) {
  const parts: string[] = [];

  let sec = seconds;

  if (sec >= 3600) {
    parts.push(`${Math.floor(sec / 3600)} t`);
    sec %= 3600;
  }

  if (sec >= 60) {
    parts.push(`${Math.floor(sec / 60)} min`);
    sec %= 60;
  }

  if (sec > 0) {
    parts.push(`${sec} sek`);
    sec %= 3600;
  }

  return parts.join(' ');
}
