/**
 * This is the configuration settings for the client-side applications.
 * Note that this is available to all clients and must not contain secrets.
 *
 * In development, it fetches settings from the React preset PORTAL_* environment variables
 *    (set in `client/config/env.js` and `client/config/webpack.config.dev.js`).
 * In production, it fetches the settings from the server-set window.APPCONFIG object.
 *
 */

interface Config {
  sentryDsn: string | undefined;
  sentryEnvironment: (location: Location) => string | undefined;
  gitCommit: string | undefined;
  buildTime: string | undefined;
  apiRootPath: string | undefined;
}

const config: Config = {
  sentryDsn: 'https://a0ace899a14d421cbccb23f97c8f1eab@sentry.io/1777577',

  sentryEnvironment: (location) => {
    if (location.host.includes('mate') && location.host.endsWith('.no')) {
      return location.host;
    }
    return undefined;
  },

  gitCommit: process.env.REACT_APP_GIT_COMMIT,
  buildTime: process.env.REACT_APP_BUILD_TIME,
  apiRootPath:
    (window as any).CONFIG?.API_ROOT ?? process.env.REACT_APP_API_ROOT,
};

export default config;
