import React, { CSSProperties, useState } from 'react';
import cs from 'classnames';
import { Button } from '../../../../components/Button';
import { BlockedUserModal } from './BlockedUserModal';
import { CareReceiverBlockedUser } from '../lib/useCareReceiverFormik';
import { RouteLeavingGuard } from '../../../../components/RouteLeavingGuard';
import { useDataChangeDetect } from '../../../../lib/useDataChangeDetect';

import './BlockedUsersPickerField.scss';
import formatUserLabel from '../../permissions/lib/formatUserLabel';
import { t } from '../../../../lib/i18n';

interface Props {
  label?: string;
  disabled?: boolean;
  error?: string;
  className?: string;
  wrapperStyles?: CSSProperties;

  searchZoneId?: number;
  blockedUsers: CareReceiverBlockedUser[];
  onChange: (blockedUsersIds: CareReceiverBlockedUser[]) => void;
}

export const BlockedUsersPickerField = (props: Props) => {
  const [isOpen, setIsOpen] = useState(false);

  const dataChangeDetect = useDataChangeDetect<CareReceiverBlockedUser[]>(
    props.blockedUsers
  );

  return (
    <div className={cs('BlockedUsersPickerField', props.className)}>
      <RouteLeavingGuard
        when={dataChangeDetect.hasChanged(props.blockedUsers)}
      />

      <div
        className="BlockedUsersPickerField-wrapper"
        style={{ ...props.wrapperStyles }}
      >
        <div className="BlockedUsersPickerField-label">
          {props.label || t('manage.careReceivers.common.superVisionOrder')}
        </div>

        <div
          className="BlockedUsersPickerField-selected-items"
          onClick={() => setIsOpen(!isOpen)}
          data-cy="selected-blocked-users-picker-field"
        >
          {props.blockedUsers.length > 0 && (
            <div>
              <ul>
                {props.blockedUsers.map((bu) => (
                  <li key={bu.blocked_user_id}>{formatUserLabel(bu)}</li>
                ))}
              </ul>
            </div>
          )}
        </div>
      </div>

      <Button
        icon="gear"
        color="primary"
        light={true}
        small={true}
        onClick={() => setIsOpen(!isOpen)}
        data-cy="manage-blocked-users-picker-modal-trigger"
      >
        {t('common.commonButtons.select')}
      </Button>

      <BlockedUserModal
        open={isOpen}
        searchZoneId={props.searchZoneId}
        initialBlockedUsers={props.blockedUsers}
        onCancel={() => setIsOpen(false)}
        onSave={(blockedUsers) => props.onChange(blockedUsers)}
      />
    </div>
  );
};
