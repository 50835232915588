import { createContext, useContext } from 'react';

export interface CareReceiverContext {
  id: number;
  firstName: string | null;
  lastName: string | null;
  nationalIdNumber?: string;
}

const careReceiverContext = createContext<CareReceiverContext | undefined>(
  undefined
);

export const useOptionalCareReceiverContext = () =>
  useContext(careReceiverContext);

export function useCareReceiverContext(): CareReceiverContext {
  const context = useContext(careReceiverContext);
  if (!context) {
    throw new Error('Invalid care receiver context');
  }
  return context as CareReceiverContext;
}

const { Consumer, Provider } = careReceiverContext;
export { Consumer, Provider as CareReceiverProvider };
