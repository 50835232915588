import React from 'react';
import { useZoneContext } from '../../../../lib/ZoneContext';
// import { getGroupByEventTypes } from '../../../lib/utils/eventTypes';
import { Button } from '../../../../components/Button';
import { formatAlertSettingSchedule } from '../../../../lib/utils/formatAlertSettingSchedule';
import { StatusMarker } from '../../../../lib/ui/StatusMarker';
import { SortableTable } from '../../../../components/SortableTable';
import { t } from '../../../../lib/i18n';
import { AlertSettingForSingleSensorType } from '../lib/types';
import LockIcon from '@material-ui/icons/Lock';

interface Props {
  settings?: Array<AlertSettingForSingleSensorType>;
  loading?: boolean;
}

export function AlertSettingsTable(props: Props) {
  const context = useZoneContext();

  const settings = props.settings || [];

  // prettier-ignore
  const columns = [
    { header: t('common.commonTexts.active'), key: 'enabled', sortable: true },
    { header: t('manage.alerts.ui.AlertSettingsTable.alertGroupName'), key: 'name', sortable: true },
    { header: t('common.commonTexts.zoneUnit'), key: 'zone', sortable: true },
    { header: t('manage.alerts.ui.AlertSettingsTable.notificationForm'),  key: 'schedule', sortable: true },
    { header: t('manage.alerts.ui.AlertSettingsTable.receiversHeading'), key: 'recipients', sortable: true, },
    { header: t('manage.alerts.ui.AlertSettingsTable.escalation'), key: 'escalation_recipients', sortable: true, },
    { header: '', key: 'action' },
  ];

  const rows = settings.map((s) => {
    return {
      enabled: (
        <StatusMarker
          data-sort={s.enabled}
          status={s.enabled ? 'success' : 'danger'}
        />
      ),
      name: s.name,
      zone: s.zone.name,
      schedule: formatAlertSettingSchedule(s.schedule),
      recipients: t(
        'manage.alerts.ui.AlertSettingsTable.receivers',
        s.recipients.length
      ),
      escalation_recipients: s.escalation_enabled ? (
        <>
          {s.escalation_recipients &&
            t(
              'manage.alerts.ui.AlertSettingsTable.receivers',
              s.escalation_recipients.length
            )}
        </>
      ) : (
        '-'
      ),
      action: (
        <Button
          icon={s.api_lock ? undefined : 'gear'}
          className="mr-2"
          disabled={s.api_lock}
          to={
            '/manage/' + context.activeZone.id + '/alerts/' + s.alert_setting_id
          }
          light={true}
          small={true}
          color="primary"
        >
          {s.api_lock ? (
            <LockIcon style={{ color: '#818181', width: 30, height: 25 }} />
          ) : (
            t('common.commonButtons.show')
          )}
        </Button>
      ),
    };
  });

  return (
    <SortableTable
      stickyHeader={false}
      wrapperClassName="AlertSettingsTable"
      loading={props.loading}
      columns={columns}
      rows={rows}
      noResultsPlaceholder={
        <>
          {t('common.commonTexts.noAlertsAdded')}{' '}
          {context.activeZone.is_housing_unit
            ? t('common.commonTexts.zone')
            : t('common.commonTexts.apartment')}
          .
        </>
      }
    />
  );
}
