export default function isValidNorwegianId(number?: string | null | undefined) {
  if (typeof number !== 'string') {
    return false;
  }

  if (number.length !== 11) {
    return false;
  }

  if (!/^[0-9]+$/.test(number)) {
    return false;
  }

  var date = number.substring(0, 6);

  if (isDNumber(number)) {
    date =
      (parseInt(date.substring(0, 1), 10) - 4).toString() +
      date.substring(1, 6);
  }

  if (!isDateValid(date)) {
    return false;
  }

  if (calculateFirstChecksum(number.substring(0, 9)).toString() !== number[9]) {
    return false;
  }

  if (
    calculateSecondChecksum(number.substring(0, 10)).toString() !== number[10]
  ) {
    return false;
  }

  return true;
}

function isDateValid(date: string) {
  var day = parseInt(date.substring(0, 2)),
    month = parseInt(date.substring(2, 4)),
    monthLengths = [31, 29, 31, 30, 31, 30, 31, 31, 30, 31, 30, 31];

  if (day < 1 || month < 1) {
    return false;
  }

  if (day > monthLengths[month - 1]) {
    return false;
  }

  return true;
}

function isDNumber(date: string) {
  var day = parseInt(date.substring(0, 2));

  if (day > 40 && day < 72) {
    return true;
  }
  return false;
}

function calculateFirstChecksum(number: string) {
  if (typeof number !== 'string') {
    return false;
  }

  if (number.length !== 9) {
    return false;
  }

  const num = number.split('').map(function (number) {
    return parseInt(number);
  });

  var checksum =
    11 -
    ((3 * num[0] +
      7 * num[1] +
      6 * num[2] +
      1 * num[3] +
      8 * num[4] +
      9 * num[5] +
      4 * num[6] +
      5 * num[7] +
      2 * num[8]) %
      11);

  if (checksum === 10) {
    return false;
  }

  if (checksum === 11) {
    checksum = 0;
  }

  return checksum;
}

function calculateSecondChecksum(number: string) {
  if (typeof number !== 'string') {
    return false;
  }

  if (number.length !== 10) {
    return false;
  }

  const num = number.split('').map(function (number) {
    return parseInt(number);
  });

  var checksum =
    11 -
    ((5 * num[0] +
      4 * num[1] +
      3 * num[2] +
      2 * num[3] +
      7 * num[4] +
      6 * num[5] +
      5 * num[6] +
      4 * num[7] +
      3 * num[8] +
      2 * num[9]) %
      11);

  if (checksum === 10) {
    return false;
  }

  if (checksum === 11) {
    checksum = 0;
  }

  return checksum;
}

function getCentury(individualNumber: number, birthYear: number) {
  let century: number = 19;
  if (individualNumber >= 0 && individualNumber <= 499) {
    century = 19;
  } else if (
    individualNumber >= 500 &&
    individualNumber <= 749 &&
    birthYear >= 55
  ) {
    century = 18;
  } else if (
    individualNumber >= 500 &&
    individualNumber <= 999 &&
    birthYear <= 39
  ) {
    century = 20;
  } else if (
    individualNumber >= 900 &&
    individualNumber <= 999 &&
    birthYear >= 40
  ) {
    century = 19;
  }
  return century;
}

export function getBirthdate(ssn: string) {
  if (!isValidNorwegianId(ssn)) {
    return undefined;
  }

  var birthDay = parseInt(ssn.substring(0, 2), 10);
  var birthMonth = parseInt(ssn.substring(2, 4), 10);
  var birthYearWithoutCentury = parseInt(ssn.substring(4, 6), 10);
  var individualNumber = parseInt(ssn.substring(6, 9), 10);
  var century = getCentury(individualNumber, birthYearWithoutCentury);
  var birthYear = century + birthYearWithoutCentury;

  if (exports.isDNumber(ssn)) {
    birthDay = birthDay - 40;
  }

  // Minus one one the month because months are zero-indexed
  return new Date(birthYear, birthMonth - 1, birthDay);
}
