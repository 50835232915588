import cs from 'classnames';

import { DashboardEventCounter } from './DashboardEventCounter';
import { InlineSpinner } from '../../components/InlineSpinner';

import { t } from '../../lib/i18n';

import './Dashboard.scss';
import { DashboardEventsLastPeriodResult } from '../../serverApi';

interface Props {
  className?: string;
  events?: DashboardEventsLastPeriodResult;
  loading: boolean;
}

export function DashboardFooter(props: Props) {
  return (
    <div className={cs('DashboardFooter', props.className)}>
      <h2 className="Dashboard-footer-title">{t('dashboard.footer.title')}</h2>
      {props.loading ? (
        <InlineSpinner />
      ) : (
        <DashboardEventCounter
          events={
            props.events?.data.map((e) => ({
              count: e.event_type_count,
              eventType: e.event_type,
              sensorType: e.sensor_type,
            })) ?? []
          }
        />
      )}
    </div>
  );
}
