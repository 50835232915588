import { FormikHelpers, useFormik } from 'formik';
import * as Yup from 'yup';

export interface LoginFields {
  account: string;
  username: string;
  password: string;
}

interface UseLoginFormikOptions {
  initialValues?: Partial<LoginFields>;
  onSubmit: (
    values: LoginFields,
    formikHelpers: FormikHelpers<LoginFields>
  ) => Promise<any>;
}

export const useLoginFormik = (opts: UseLoginFormikOptions) => {
  const validationSchema = Yup.object().shape({
    account: Yup.string().required(),
    username: Yup.string().required(),
    password: Yup.string().required(),
  });

  return useFormik({
    initialValues: {
      account: '',
      username: '',
      password: '',
      ...opts.initialValues,
    },
    enableReinitialize: true,
    validationSchema,
    onSubmit: async (values, formHelpers) => {
      await opts.onSubmit(values, formHelpers);
    },
  });
};
