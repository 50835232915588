import React, { useState } from 'react';
import { FormGroup, TextField } from '@material-ui/core';
import ModalWrapper from '../../../../components/modals/ModalWrapper';
import {
  errorIsObjectError,
  useErrorHandler,
} from '../../../../lib/useErrorHandler';
import { ErrorMessages } from '../../../../components/ErrorMessages';
import { Panel } from '../../../../components/Panel';
import { SelectVendor } from './SelectVendor';
import { isAxiosError } from '../../../../lib/utils/errorUtils';

import { useFormik } from 'formik';

import { t, TranslationKey } from '../../../../lib/i18n';
import * as Yup from 'yup';
import * as api from '../../../../serverApi';

import './BulkAssignVendorModal.scss';
import { generateAutoCompleteValue } from '../../../../lib/utils/generateAutoCompleteValue';

export interface BulkAssignVendorModalProps {
  isOpen: boolean;
  toggle: () => void;
  onChanged?: () => void;
}

interface BulkAssignAccountFields {
  accountId: number | undefined;
  sensorIds: string;
  sensor_type: api.SensorType;
}

export default function BulkAssignVendorModal(
  props: BulkAssignVendorModalProps
) {
  const errorHandler = useErrorHandler();

  const [responseData, setResponseData] = useState<
    api.SuperVendorBulkAssignSensorAccountResult | undefined
  >();

  const formik = useFormik<BulkAssignAccountFields>({
    initialValues: {
      accountId: undefined,
      sensorIds: '',
      sensor_type: 'roommate',
    },
    validationSchema: () =>
      Yup.object().shape({
        accountId: Yup.number().required(),
        sensorIds: Yup.string().required(),
        sensor_type: Yup.string().required(),
      }),
    onSubmit: async (values, helpers) => {
      if (!values.accountId) {
        return;
      }
      try {
        const res = await api.superVendorBulkAssignSensorAccount({
          vendor_account_id: values.accountId,
          sensors: values.sensorIds,
          sensor_type: values.sensor_type,
        });

        errorHandler.reset();
        helpers.resetForm();

        setResponseData(res);

        if (props.onChanged) {
          props.onChanged();
        }
      } catch (e) {
        /**
         * Handle custom validation error when assigning multiple sensors fails.
         * This validation error response format is different than the normal validation error response
         * because we also need the sensor_id and the code from server in order to display the errors.
         */
        if (isAxiosError(e) && e.response?.status === 422) {
          if (
            e.response?.data?.errors &&
            Array.isArray(e.response?.data?.errors) &&
            e.response.data.errors.length > 0
          ) {
            const errors = e.response.data.errors.map(
              (error: { code: string; sensor_id: string }) => {
                const errorCode = error.code;
                const errorMessage = t(
                  `common.serverError.${errorCode}` as TranslationKey
                );

                return `${error.sensor_id} - ${errorMessage}`;
              }
            );

            errorHandler.setArrayError(errors);
            return;
          }
        }
        errorHandler.handleError(e);
      }
    },
  });

  const resetModal = () => {
    errorHandler.reset();
    setResponseData(undefined);
    formik.resetForm();
  };

  const getSensorsError = () => {
    if (formik.touched.sensorIds && formik.errors.sensorIds) {
      return formik.errors.sensorIds;
    }
    if (
      errorIsObjectError(errorHandler.error) &&
      errorHandler.error['sensor_type']
    ) {
      return errorHandler.error['sensor_type'];
    }

    return undefined;
  };

  const handleOnHide = () => {
    responseData ? clearResponse() : props.toggle();
  };

  const handleOnSubmit = () => {
    responseData ? props.toggle() : formik.submitForm();
  };

  const clearResponse = () => setResponseData(undefined);

  const sensorError = getSensorsError();

  return (
    <ModalWrapper
      title="Bulk assign vendor sensors"
      className="BulkAssignVendorModal"
      okButtonText={responseData ? 'Close' : 'OK'}
      cancelButtonText={responseData ? 'Assign more' : 'Cancel'}
      isOpen={props.isOpen}
      loading={formik.isSubmitting}
      onHide={handleOnHide}
      onSubmit={handleOnSubmit}
      onResetModal={resetModal}
      onToggle={props.toggle}
    >
      {responseData ? (
        <div className="BulkAssignVendorModal-response-summary">
          <span>
            Account <strong>{responseData?.account?.name} </strong> was assigned
            to {responseData.sensors.length > 1 ? 'sensors' : 'sensor'}{' '}
            <strong>{responseData.sensors.join(', ')}</strong>
          </span>
        </div>
      ) : (
        <>
          <SelectVendor
            className="mb-3"
            disabled={formik.isSubmitting}
            error={
              formik.touched.accountId && formik.errors.accountId
                ? formik.errors.accountId
                : undefined
            }
            accountId={formik.values.accountId}
            onSelect={(accountId) => {
              formik.setFieldTouched('accountId');
              formik.setFieldValue('accountId', accountId);
            }}
          />

          <FormGroup className="mb-3">
            <TextField
              type="text"
              name="sensorIds"
              label="Sensor IDs"
              disabled={formik.isSubmitting}
              value={formik.values.sensorIds}
              error={sensorError !== undefined}
              helperText={sensorError}
              onChange={formik.handleChange}
              autoComplete={generateAutoCompleteValue()}
            />
            <Panel className="mt-2">
              Tips: Enter comma-separated list of sensor IDs. Intervals are also
              allowed. E.g. 100,110,120-125 (inclusive).
            </Panel>
          </FormGroup>

          <FormGroup>
            <TextField
              type="text"
              name="sensor_type"
              label="Sensor type"
              disabled={formik.isSubmitting}
              value={formik.values.sensor_type}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              error={formik.touched.sensor_type && !!formik.errors.sensor_type}
              helperText={
                formik.touched.sensor_type && formik.errors.sensor_type
                  ? formik.errors.sensor_type
                  : undefined
              }
              autoComplete={generateAutoCompleteValue()}
            />
          </FormGroup>
        </>
      )}

      <ErrorMessages
        renderFieldErrors
        className="mt-4"
        errorData={errorHandler}
      />
    </ModalWrapper>
  );
}
