import React from 'react';
import { useZoneContext } from '../../../../../lib/ZoneContext';
import useZoneSettingsForm from './lib/useZoneSettingsForm';
import { Panel } from '../../../../../components/Panel';
import { FormBody } from './lib/FormBody';
import * as api from '../../../../../serverApi';
import {
  FormControlLabel,
  FormGroup,
  Radio,
  RadioGroup,
} from '@material-ui/core';

import { t } from '../../../../../lib/i18n';

const fields: Array<keyof api.ZoneSettings> = [
  'max_call_duration_oneway_audio',
  'max_call_duration_oneway_audio_anonymised',
  'max_call_duration_twoway_audio',
];

const FormHeading = (props: {
  children: React.ReactNode;
  disabled?: boolean;
}) => {
  return (
    <h6 style={{ color: props.disabled ? 'rgba(0, 0, 0, 0.38)' : '#212529' }}>
      <strong>{props.children}</strong>
    </h6>
  );
};

export const AudioTimeout = () => {
  const ctx = useZoneContext();
  const form = useZoneSettingsForm({ fields });

  function onChange(event: React.ChangeEvent<HTMLInputElement>, value: string) {
    const n = parseInt(value, 10);
    form.formik.setFieldValue(event.target.name, n);
    form.formik.setFieldTouched(event.target.name);
  }

  return (
    <div>
      <h2>
        {t('manage.zone.AudioTimeout.headingFor')} {ctx.activeZone.name}
      </h2>

      <Panel>
        <strong>{t('common.commonTexts.tips')}: </strong>
        {t(
          'manage.zone.settings.common.theSettingsFromTheParentZoneAreDisplayedInGrayWhenThisIsSelected'
        )}
      </Panel>

      <FormBody zoneSettingsForm={form}>
        <FormGroup className="mb-5">
          <FormHeading disabled={form.disabled}>
            {t('manage.zone.AudioTimeout.timeLimitForListening')}
          </FormHeading>
          <RadioGroup
            name="max_call_duration_oneway_audio"
            value={`${
              form.displayValues?.max_call_duration_oneway_audio ?? ''
            }`}
            onChange={onChange}
          >
            <FormControlLabel
              disabled={form.disabled}
              value="0"
              control={<Radio color="primary" />}
              label={t('manage.zone.settings.common.noTimeLimit')}
            />

            <FormControlLabel
              disabled={form.disabled}
              value="30"
              control={<Radio color="primary" />}
              label={`30 ${t('common.seconds', 30)}`}
            />

            <FormControlLabel
              disabled={form.disabled}
              value="60"
              control={<Radio color="primary" />}
              label={`1 ${t('common.minutes', 1)}`}
            />

            <FormControlLabel
              disabled={form.disabled}
              value="300"
              control={<Radio color="primary" />}
              label={`5 ${t('common.minutes', 5)}`}
            />
          </RadioGroup>
        </FormGroup>

        <FormGroup className="mb-5">
          <FormHeading disabled={form.disabled}>
            {t('manage.zone.AudioTimeout.timeLimitForAnonymousListening')}
          </FormHeading>
          <RadioGroup
            name="max_call_duration_oneway_audio_anonymised"
            value={`${
              form.displayValues?.max_call_duration_oneway_audio_anonymised ??
              ''
            }`}
            onChange={onChange}
          >
            <FormControlLabel
              disabled={form.disabled}
              value="0"
              control={<Radio color="primary" />}
              label={t('manage.zone.settings.common.noTimeLimit')}
            />

            <FormControlLabel
              disabled={form.disabled}
              value="30"
              control={<Radio color="primary" />}
              label={`30 ${t('common.seconds', 30)}`}
            />

            <FormControlLabel
              disabled={form.disabled}
              value="60"
              control={<Radio color="primary" />}
              label={`1 ${t('common.minutes', 1)}`}
            />

            <FormControlLabel
              disabled={form.disabled}
              value="300"
              control={<Radio color="primary" />}
              label={`5 ${t('common.minutes', 5)}`}
            />
          </RadioGroup>
        </FormGroup>

        <FormGroup className="mb-5">
          <FormHeading disabled={form.disabled}>
            {t('manage.zone.AudioTimeout.timeLimitForTwoWaySpeech')}
          </FormHeading>
          <RadioGroup
            name="max_call_duration_twoway_audio"
            value={`${
              form.displayValues?.max_call_duration_twoway_audio ?? ''
            }`}
            onChange={onChange}
          >
            <FormControlLabel
              disabled={form.disabled}
              value="0"
              control={<Radio color="primary" />}
              label={t('manage.zone.settings.common.noTimeLimit')}
            />

            <FormControlLabel
              disabled={form.disabled}
              value="30"
              control={<Radio color="primary" />}
              label={`30 ${t('common.seconds', 30)}`}
            />

            <FormControlLabel
              disabled={form.disabled}
              value="60"
              control={<Radio color="primary" />}
              label={`1 ${t('common.minutes', 1)}`}
            />

            <FormControlLabel
              disabled={form.disabled}
              value="300"
              control={<Radio color="primary" />}
              label={`5 ${t('common.minutes', 5)}`}
            />
          </RadioGroup>
        </FormGroup>
      </FormBody>
    </div>
  );
};
