import { FormikHelpers, FormikValues, useFormik } from 'formik';
import { useZoneContext } from '../../../../lib/ZoneContext';

import * as api from '../../../../serverApi';

export interface ImpersonalUsersFields {
  display_name?: string;
  phone?: string;
  phone2?: string;
  preferred_locale: string;
  roles: api.CreateUserRoleInput[];
}

export type ImpersonalUsersFormik = ReturnType<typeof useImpersonalUsersFormik>;

interface UseImpersonalUsersFormikOptions {
  initialValues?: ImpersonalUsersFields;
  validationSchema?: any | (() => any);
  onSubmit: (
    values: FormikValues,
    formikHelpers: FormikHelpers<ImpersonalUsersFields>
  ) => Promise<any>;
}

export function useImpersonalUsersFormik(
  opts: UseImpersonalUsersFormikOptions
) {
  const ctx = useZoneContext();

  return useFormik<ImpersonalUsersFields>({
    initialValues: {
      display_name: '',
      phone: '',
      preferred_locale: 'nb',
      roles: [
        {
          zone_id: ctx.activeZone.id,
          valid_from: null,
          valid_to: null,
        },
      ] as api.CreateUserRoleInput[],
      ...opts.initialValues,
    },
    validationSchema: opts.validationSchema,
    onSubmit: async (values, formikHelpers) => {
      await opts.onSubmit(values, formikHelpers);
    },
  });
}
