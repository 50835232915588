import React from 'react';
import { Pager } from '../../areas/manage/logs/ui/Pager';
import { FormGroup, MenuItem, TextField } from '@material-ui/core';
import { UsePagination } from '../../hooks/usePagination';

import './Pagination.scss';

interface Props {
  loading?: boolean;
  pagination: UsePagination;
}

export const Pagination: React.FC<Props> = (props) => {
  const { pagination } = props;

  return (
    <div className="Pagination">
      <Pager
        loading={props.loading}
        pager={
          props.loading
            ? undefined
            : {
                current_page: pagination.currentPage,
                last_page: pagination.totalPages.length,
              }
        }
        onPageChange={(page) => pagination.setCurrentPage(page)}
      />

      <FormGroup className="ml-3" style={{ width: 100 }}>
        <TextField
          disabled={props.loading}
          type="number"
          label="Go to page"
          style={{ maxWidth: 100 }}
          value={pagination.currentPage}
          onChange={(e) => {
            const val = parseInt(e.target.value);
            if (!val || val > pagination.totalPages.length || val < 1) {
              return;
            }
            pagination.setCurrentPage(parseInt(e.target.value));
          }}
        />
      </FormGroup>

      <FormGroup className="ml-3" style={{ width: 100 }}>
        <TextField
          disabled={props.loading}
          select={true}
          variant="outlined"
          className="w-100"
          label="Per page"
          value={pagination.itemsPerPage}
          onChange={(e) => {
            pagination.setCurrentPage(1);
            pagination.setItemsPerPage(parseInt(e.target.value));
          }}
        >
          <MenuItem value={10}>10</MenuItem>
          <MenuItem value={20}>20</MenuItem>
          <MenuItem value={50}>50</MenuItem>
          <MenuItem value={100}>100</MenuItem>
          <MenuItem value={500}>500</MenuItem>
        </TextField>
      </FormGroup>
    </div>
  );
};
