import { createContext, useContext } from 'react';
import { Locale } from './i18n';

export interface I18nContext {
  locale: Locale;
  setLocale: (locale: string | Locale) => void;
}

export const i18nContext = createContext<I18nContext | undefined>(undefined);

export function useI18nContext() {
  const ctx = useContext(i18nContext);
  if (!ctx) {
    throw new Error('Cannot call `useI18nContext` outside an I18nProvider.');
  }
  return ctx;
}
