import React from 'react';
import cs from 'classnames';

import './ButtonGroup.scss';

interface Props {
  direction?: 'horizontal' | 'vertical';
  children?: React.ReactNode;
  className?: string;
  style?: React.CSSProperties;
}

export function ButtonGroup(props: Props) {
  const vertical = props.direction === 'vertical';
  return (
    <div
      style={props.style}
      className={cs(
        'ButtonGroup',
        vertical ? 'ButtonGroup--vertical' : 'ButtonGroup--horizontal',
        props.className
      )}
    >
      {props.children}
    </div>
  );
}
