import React, { ChangeEvent, useEffect, useState } from 'react';
import { Checkbox, FormControlLabel, FormGroup } from '@material-ui/core';
import { useSensorContext } from '../../../../lib/SensorContext';
import * as api from '../../../../serverApi';
import { useErrorHandler } from '../../../../lib/useErrorHandler';
import { ErrorMessages } from '../../../../components/ErrorMessages';
import { InlineSpinner } from '../../../../components/InlineSpinner';
import { t } from '../../../../lib/i18n';
import { useZoneContext } from '../../../../lib/ZoneContext';
import { useQueryClient } from 'react-query';
import {
  QueryKeys,
  useUpdateSensorSettingMutation,
} from '../../../../serverApi';
import setQueryDataForSensor from '../lib/setQueryDataForSensor';

interface Props {
  onChanged?: () => void;
}

export const SensorStatusCheckbox = (props: Props) => {
  const ctx = useZoneContext();
  const sensorCtx = useSensorContext();
  const errorHandler = useErrorHandler();
  const queryClient = useQueryClient();

  const [checked, setChecked] = useState<boolean>(sensorCtx.is_enabled);

  const { status, mutateAsync } = useUpdateSensorSettingMutation({
    onSuccess: (data) => {
      // queryKey that we used when we fetched the data.
      const queryKey = QueryKeys.getSensorDetails(sensorCtx.id);

      // read previous data from cache.
      const previousData = queryClient.getQueryData<api.GetSensorDetailsResult>(
        queryKey
      );

      if (!previousData?.sensor) {
        return;
      }

      // if updateSensorSettings return all sensor details, then we don't have to fetch previous sensor data
      setQueryDataForSensor(queryClient, {
        ...previousData.sensor,
        is_enabled: !!data.sensor_setting.is_enabled,
      });

      // update zone sensors cache data
      queryClient.setQueryData<api.GetSensorsForZoneResult>(
        QueryKeys.getSensorsForZone(ctx.activeZone.id),
        (prev) => {
          if (!prev?.sensors) {
            return {
              sensors: [],
            };
          }

          return {
            sensors: prev.sensors.map((x) =>
              x.id === sensorCtx.id
                ? { ...x, is_enabled: !!data.sensor_setting.is_enabled }
                : x
            ),
          };
        }
      );
    },
    // onSettled: () =>
    //   queryClient.invalidateQueries(
    //     QueryKeys.getSensorsForZone(ctx.activeZone.id)
    //   ),
  });

  useEffect(() => {
    setChecked(sensorCtx.is_enabled);
  }, [sensorCtx]);

  const onCheckboxChange = async (e: ChangeEvent<HTMLInputElement>) => {
    const checkedValue = e.target.checked;

    e.persist();

    if (!sensorCtx || !sensorCtx.id) {
      return;
    }

    try {
      await mutateAsync({
        sensorCompositeId: sensorCtx.id,
        data: {
          is_enabled: checkedValue,
        },
      });
      // setChecked(checkedValue);
    } catch (e) {
      errorHandler.handleError(e);
    }
  };

  const loading = status === 'loading';

  return (
    <FormGroup>
      <FormControlLabel
        disabled={loading}
        control={
          <Checkbox
            color="primary"
            value="is_enabled"
            checked={checked}
            onChange={(e) => onCheckboxChange(e)}
          />
        }
        label={
          <span>
            {t('manage.sensors.details.SensorStatusCheckbox.sensorIsActivated')}{' '}
            {loading && <InlineSpinner className="ml-1" />}
          </span>
        }
      />

      <ErrorMessages errorData={errorHandler} />
    </FormGroup>
  );
};
