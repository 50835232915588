import { StructureZone } from '../../../../../../serverApi';

/**
 * Find parent of a zone by searching recursively through root zone descendants.
 *
 * @param rootZone top-most zone
 * @param activeZone selected zone
 * @returns parent zone
 */

export const getParentZone = (
  rootZone: StructureZone,
  activeZone: StructureZone
) => {
  let parentZone: StructureZone | undefined;

  /**
   * If active zone has no parent, this means it's the root zone itself,
   * so we return undefined since root zone can't have any parent.
   */

  if (!activeZone.parent) {
    return undefined;
  }

  /**
   * See if parent zone of the selected one is the top-most/root zone.
   */

  if (rootZone.id === activeZone.parent.id) {
    return rootZone;
  }

  /**
   * If root zone is not parent of active zone, we search recursively throgh root zone descendants
   * until parent zone is found.
   */

  if (rootZone.zones && rootZone.zones.length > 0) {
    const parent = rootZone.zones?.find(
      (zone) => zone.id === activeZone.parent?.id
    );

    if (parent) {
      return parent;
    }

    for (let zone of rootZone.zones) {
      parentZone = getParentZone(zone, activeZone) || parentZone;
    }
  }
  return parentZone;
};
