import React, { useState } from 'react';
import { FormGroup, TextField } from '@material-ui/core';
import { Button } from '../../../components/Button';
import { useZoneContext } from '../../../lib/ZoneContext';
import {
  CareReceiverBlockedUser,
  CareReceiverFormik,
} from './lib/useCareReceiverFormik';
import { InlineError } from '../../../components/InlineError';
import { RouteLeavingGuard } from '../../../components/RouteLeavingGuard';
import { ZonePickerField } from '../../../components/ZonePickerField/ZonePickerField';
import {
  SearchCareReceiver,
  SearchCareReceiverType,
} from './search/SearchCareReceiver';
import { BlockedUsersPickerField } from './blockedUsers/BlockedUsersPickerField';
import { useHandleCancel } from '../../../lib/useHandleCancel';

import { t } from '../../../lib/i18n';

import * as api from '../../../serverApi';
import { useAccount } from '../../../lib/useAccount';
import { generateAutoCompleteValue } from '../../../lib/utils/generateAutoCompleteValue';

interface CareReceiverFormProps {
  mode: 'create' | 'edit';
  error?: string;
  form?: CareReceiverFormik;
  loading?: boolean;
  selectedZone?: api.StructureZone;
  disableNationalId?: boolean;
  onSelectZone?: (zoneId?: number) => void;
  onCareReceiverAutofill?: (careReceiver: SearchCareReceiverType) => void;
}

export function CareReceiverForm(props: CareReceiverFormProps) {
  const hc = useHandleCancel();
  const zoneCtx = useZoneContext();
  const account = useAccount();

  const [autoFilled, setAutoFilled] = useState(false);

  const setSelectedZone = (zoneId?: number) => {
    props.form?.setFieldValue('zone_id', zoneId || null);
    props.form?.setFieldTouched('zone_id');
    if (props.onSelectZone) {
      props.onSelectZone(zoneId);
    }
  };

  const handleCareReceiverAutofill = (careReceiver: SearchCareReceiverType) => {
    setAutoFilled(true);

    props.form?.setFieldValue('first_name', careReceiver?.first_name);
    props.form?.setFieldTouched('first_name');

    props.form?.setFieldValue('last_name', careReceiver?.last_name);
    props.form?.setFieldTouched('last_name');

    props.form?.setFieldValue('blocked_users', careReceiver?.blocked_users);
    props.form?.setFieldTouched('blocked_users');

    if (careReceiver && careReceiver.zone) {
      setSelectedZone(careReceiver.zone.id);
    }

    if (props.onCareReceiverAutofill) {
      props.onCareReceiverAutofill(careReceiver);
    }

    if (props.form?.errors.national_id_number) {
      props.form?.setErrors({});
    }
  };

  const handleCareReceiverNotFound = () => {
    if (props.form?.errors.national_id_number) {
      props.form?.setErrors({});
    }
  };

  const handleBlockedUsersChange = (
    blockedUsers: CareReceiverBlockedUser[]
  ) => {
    props.form?.setFieldValue('blocked_users', blockedUsers);
    props.form?.setFieldTouched('blocked_users');
  };

  const resetForm = () => {
    setAutoFilled(false);
    props.form?.resetForm();
    setSelectedZone(undefined);
    if (props.onCareReceiverAutofill) {
      props.onCareReceiverAutofill(null);
    }
  };

  const navigateBack = () => {
    hc.handleCancel(
      props.form?.dirty,
      `/manage/${zoneCtx.activeZone.id}/care-receivers`,
      () => props.form?.resetForm()
    );
  };

  const handleCancelClick = () => {
    if (props.mode === 'create') {
      resetForm();
      return;
    }
    navigateBack();
  };

  const disableInput = props.loading;

  const defaultZoneId =
    zoneCtx.activeZone.is_housing_unit &&
    zoneCtx.activeZone.care_receiver === null
      ? zoneCtx.activeZone.id
      : undefined;

  // Only housing units without a care receiver should be selectable.
  const getDisabledZoneIds = (z?: api.StructureZone): number[] => {
    let response = [] as number[];

    if (!z) {
      return [] as number[];
    }

    if (!z.is_housing_unit || z.care_receiver) {
      response = response.concat(z.id);
    }

    z.zones?.forEach((zone: api.StructureZone) => {
      response = response.concat(getDisabledZoneIds(zone));
    });

    return response;
  };

  const disabledZoneIds = getDisabledZoneIds(zoneCtx.rootZone);

  if (!props.form) {
    return null;
  }

  return (
    <>
      <RouteLeavingGuard when={props.form?.dirty} />
      <form className="CareReceiverForm" onSubmit={props.form.handleSubmit}>
        {!account.disable_national_id_numbers && (
          <FormGroup className="mb-3">
            <SearchCareReceiver
              label={t('common.commonTexts.dateOfBirthday')}
              name="national_id_number"
              autoFocus={true}
              disabled={props.disableNationalId || disableInput || autoFilled}
              value={props.form.values.national_id_number}
              error={props.form.errors.national_id_number}
              onChange={(e) => props.form?.handleChange(e)}
              onBlur={(e) => props.form?.handleBlur(e)}
              onAutoFill={handleCareReceiverAutofill}
              onNotFound={handleCareReceiverNotFound}
            />
          </FormGroup>
        )}

        <FormGroup className="mb-3">
          <TextField
            type="text"
            name="first_name"
            label={t('common.commonTexts.firstNameAndMiddleName')}
            disabled={disableInput}
            value={props.form.values.first_name}
            style={{ maxWidth: '400px' }}
            onChange={props.form.handleChange}
            onBlur={props.form.handleBlur}
            error={props.form.errors.first_name !== undefined}
            helperText={props.form.errors.first_name}
            autoComplete={generateAutoCompleteValue()}
          />
        </FormGroup>

        <FormGroup className="mb-3">
          <TextField
            type="text"
            name="last_name"
            label={t('common.commonTexts.surname')}
            disabled={disableInput}
            onChange={props.form.handleChange}
            onBlur={props.form.handleBlur}
            value={props.form.values.last_name}
            style={{ maxWidth: '400px' }}
            error={props.form.errors.last_name !== undefined}
            helperText={props.form.errors.last_name}
            autoComplete={generateAutoCompleteValue()}
          />
        </FormGroup>

        <ZonePickerField
          error={props.form.errors.zone_id}
          className="mb-3"
          label={t('common.commonTexts.housingUnit')}
          textFieldStyle={{
            maxWidth: '400px',
            flex: '1 1 auto',
          }}
          initialZoneId={defaultZoneId}
          selectedZone={
            props.selectedZone
              ? {
                  id: props.selectedZone?.id,
                  name: props.selectedZone.name,
                }
              : undefined
          }
          disabledZoneIds={disabledZoneIds}
          onSelectZone={(zone) => setSelectedZone(zone?.id)}
        />

        <BlockedUsersPickerField
          wrapperStyles={{
            maxWidth: '400px',
            flex: '1 1 auto',
          }}
          className="mb-3"
          searchZoneId={props.selectedZone?.id}
          blockedUsers={props.form.values.blocked_users}
          onChange={(blockedUsers) => handleBlockedUsersChange(blockedUsers)}
        />

        <div className="mt-5">
          <Button
            color="primary"
            type="submit"
            icon={props.form?.isSubmitting ? 'gear' : undefined}
            spinIcon={props.form?.isSubmitting}
            disabled={props.form?.isSubmitting}
            small={true}
          >
            {t('common.commonButtons.save')}
          </Button>
          <Button
            disabled={!props.form.dirty}
            style={{ marginLeft: 15 }}
            onClick={handleCancelClick}
          >
            {t('common.commonButtons.cancel')}
          </Button>
          {props.error && <InlineError>{props.error}</InlineError>}
        </div>
      </form>
    </>
  );
}
