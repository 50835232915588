import React from 'react';
import { FormGroup, TextField, MenuItem } from '@material-ui/core';

import { EventSettingsForm } from '../useEventSettingsForm';
import { ErrorComponent } from '../../../../../components/forms/shared/ErrorComponent';
import { t } from '../../../../../lib/i18n';
import { GreenCircleIndicator } from '../../../common/ManageSidebar';

export default function EventLevelControl(props: { form: EventSettingsForm }) {
  const { form } = props;

  const handleLevelChange = async (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.value === 'info' || e.target.value === 'hidden') {
      await form.setFieldValue('level', e.target.value);
      form.setFieldTouched('level', true, true);
      return;
    }
    const values = e.target.value.split('_');

    /**
     * Wait for the Promise until new field values are set (level and audio_set), then validate the fields
     */
    await form.setFieldValue('level', values[0]);
    await form.setFieldValue('audio_set', values[1]);
    form.setFieldTouched('level', true, true);
    form.setFieldTouched('audio_set', true, true);
  };

  const value =
    form.values.level === 'info' || form.values.level === 'hidden'
      ? form.values.level
      : `${form.values.level}_${form.values.audio_set}`;

  const fieldHasChanged =
    `${form.values.level}_${form.values.audio_set}` !==
    `${form.initialValues.level}_${form.initialValues.audio_set}`;

  return (
    <FormGroup
      className="mt-4 position-relative"
      style={{ minWidth: '200px', width: 'fit-content' }}
    >
      <TextField
        select={true}
        value={value}
        onChange={handleLevelChange}
        label={t('manage.sensors.events.controls.EventLevelControl.alertLevel')}
      >
        {form.values.level === 'hidden' && (
          <MenuItem value="hidden">
            {t('manage.sensors.events.controls.EventLevelControl.hidden')}
          </MenuItem>
        )}
        {form.values.level === 'info' && (
          <MenuItem value="info">
            {t('manage.sensors.events.controls.EventLevelControl.info')}
          </MenuItem>
        )}

        <MenuItem value="alert_a">
          {t('manage.zone.MobileSounds.alertAudioA')}
        </MenuItem>

        <MenuItem value="alert_b">
          {t('manage.zone.MobileSounds.alertAudioB')}
        </MenuItem>

        <MenuItem value="alarm_a">
          {t('manage.zone.MobileSounds.alarmAudioA')}
        </MenuItem>

        <MenuItem value="alarm_b">
          {t('manage.zone.MobileSounds.alarmAudioB')}
        </MenuItem>
      </TextField>
      {form.errors.level && <ErrorComponent msg={form.errors.level} />}
      {fieldHasChanged && (
        <GreenCircleIndicator style={{ right: '-15px', top: '70%' }} />
      )}
    </FormGroup>
  );
}
