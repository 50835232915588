import React from 'react';
import { RadioGroup, FormControlLabel, Radio } from '@material-ui/core';
import useZoneSettingsForm from './lib/useZoneSettingsForm';
import { Panel } from '../../../../../components/Panel';
import { useZoneContext } from '../../../../../lib/ZoneContext';
import { FormBody } from './lib/FormBody';
import * as api from '../../../../../serverApi';
import { t } from '../../../../../lib/i18n';

const fields: Array<keyof api.ZoneSettings> = ['reflex_presence_timeout'];

export function ReflexTimeout() {
  const form = useZoneSettingsForm({ fields });
  const ctx = useZoneContext();

  function onChange(event: React.ChangeEvent<HTMLInputElement>, value: string) {
    const n = parseInt(value, 10);
    form.formik.setFieldValue('reflex_presence_timeout', n);
    form.formik.setFieldTouched('reflex_presence_timeout');
  }

  return (
    <div>
      <h2>
        {t('manage.zone.ReflexTimeout.headingFor')} {ctx.activeZone.name}
      </h2>

      <Panel>
        <strong>{t('common.commonTexts.tips')}:</strong>{' '}
        {t(
          'manage.zone.settings.common.theSettingsFromTheParentZoneAreShownInGrayWhenThisIsSelected'
        )}
      </Panel>

      <FormBody zoneSettingsForm={form}>
        <RadioGroup
          name="reflex_presence_timeout"
          value={`${form.displayValues?.reflex_presence_timeout ?? ''}`}
          onChange={onChange}
        >
          <FormControlLabel
            disabled={form.disabled}
            value="900"
            control={<Radio color="primary" />}
            label={`15 ${t('common.minutes', 15)}`}
          />

          <FormControlLabel
            disabled={form.disabled}
            value="1800"
            control={<Radio color="primary" />}
            label={`30 ${t('common.minutes', 30)}`}
          />

          <FormControlLabel
            disabled={form.disabled}
            value="3600"
            control={<Radio color="primary" />}
            label={`1 ${t('common.hours', 1)}`}
          />

          <FormControlLabel
            disabled={form.disabled}
            value="7200"
            control={<Radio color="primary" />}
            label={`2 ${t('common.hours', 2)}`}
          />

          <FormControlLabel
            disabled={form.disabled}
            value="14400"
            control={<Radio color="primary" />}
            label={`4 ${t('common.hours', 4)}`}
          />

          <FormControlLabel
            disabled={form.disabled}
            value="28800"
            control={<Radio color="primary" />}
            label={`8 ${t('common.hours', 8)}`}
          />
        </RadioGroup>
      </FormBody>
    </div>
  );
}
