import React from 'react';
import { FormGroup, TextField, MenuItem, FormLabel } from '@material-ui/core';

import { EventSettingsForm } from '../useEventSettingsForm';
import { ErrorComponent } from '../../../../../components/forms/shared/ErrorComponent';
import { EventSettings } from '../../../../../serverApi';
import { GreenCircleIndicator } from '../../../common/ManageSidebar';

interface Props {
  form: EventSettingsForm;

  /**
   * The label for the form control.
   */
  label: string;

  /**
   * The field in EventSettings that this control should modify the value of.
   */
  field: keyof Omit<
    EventSettings,
    'active' | 'level' | 'enabled_from' | 'enabled_to'
  >;

  /**
   * The available options of the select control.
   */
  options: Array<{
    label: string;
    value: number | string;
  }>;

  /**
   * Optional add an description to the select field
   */
  description?: string;
}

export default function EventLevelControl(props: Props) {
  const { form } = props;

  const initial = form.initialValues[props.field];
  const current = form.values[props.field];

  const initialFieldValue =
    typeof initial === 'number'
      ? initial
      : typeof initial === 'string' && parseInt(initial);

  const currentFieldValue =
    typeof current === 'number'
      ? current
      : typeof current === 'string' && parseInt(current);

  const fieldHasChanged = initialFieldValue !== currentFieldValue;

  return (
    <FormGroup
      className="mt-4 position-relative"
      style={{ minWidth: '200px', width: 'fit-content' }}
    >
      {props.description && <FormLabel> {props.description}</FormLabel>}
      <TextField
        select={true}
        value={form.values[props.field] || ''}
        onChange={form.handleChange}
        name={props.field}
        label={props.label}
      >
        {props.options.map((opt) => (
          <MenuItem key={opt.value} value={opt.value}>
            {opt.label}
          </MenuItem>
        ))}
      </TextField>

      {form.errors[props.field] && (
        <ErrorComponent msg={form.errors[props.field]} />
      )}
      {fieldHasChanged && (
        <GreenCircleIndicator style={{ right: '-15px', top: '70%' }} />
      )}
    </FormGroup>
  );
}
