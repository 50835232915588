import { useContext } from 'react';
import { appContext } from './appContext';
import type { AppContext } from './appContext';

export function useAppContext(): AppContext {
    const ctx = useContext(appContext);
    if (!ctx) {
        throw new Error("Cannot call `useAppContext` outside an AppContextProvider.");
    }
    return ctx;
}