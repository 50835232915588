import React from 'react';
import { Header } from '../../components/Header/Header';
import { Dashboard } from './Dashboard';

export function DashboardContainer() {
  return (
    <>
      <Header className="App-header" />
      <Dashboard />
    </>
  );
}
