import { useState, CSSProperties } from 'react';
import cs from 'classnames';
import { IconButton } from '../../../../../components/IconButton';
import { ButtonGroup } from '../../../../../components/ButtonGroup';
import { t } from '../../../../../lib/i18n';
import { useWindowSize } from '../../../../../lib/useWindowSize';
import { VideoModal } from '../../../../../components/modals/VideoModal';

import './HelpFooter.scss';

interface Props {
  className?: string;
  style?: CSSProperties;

  /**
   * Show a warning about the marking engine being simulated (i.e. RCTS not in use).
   */
  showSimulationWarning?: boolean;
}

export function HelpFooter(props: Props) {
  const windowSize = useWindowSize();
  const [isOpen, setIsOpen] = useState(false);

  const id = t('common.video.manage.marking.vimeo');
  const link = `https://player.vimeo.com/video/${id}`;

  function handleHelpClick() {
    if (windowSize.isMobile) {
      // If mobile send directly to vimeo
      window.location.href = link;
    } else {
      setIsOpen(true);
    }
  }

  return (
    <div className={cs('HelpFooter', props.className)} style={props.style}>
      {props.showSimulationWarning && (
        <div className="HelpFooter-simulation">
          <strong>
            {t('manage.sensors.marking.components.HelpFooter.testMode')}
          </strong>
          <br />
          {t('manage.sensors.marking.components.HelpFooter._info')}
        </div>
      )}
      <h3 className="HelpFooter-heading">
        {t('manage.sensors.marking.components.HelpFooter.doYouNeedHelp')}
      </h3>

      <ButtonGroup direction="horizontal">
        <IconButton icon="play" onClick={handleHelpClick}>
          {t('manage.sensors.marking.components.HelpFooter.seeVideo')}
        </IconButton>
        <IconButton
          icon="pdf"
          color="transparent"
          target="_blank"
          href={t('common.url.markingUserGuidePdf')}
        >
          {t('manage.sensors.marking.components.HelpFooter.seePdf')}
        </IconButton>
      </ButtonGroup>

      <VideoModal
        isOpen={isOpen}
        title={t('common.video.manage.marking.title')}
        src={link}
        onToggle={() => setIsOpen(false)}
      />
    </div>
  );
}
