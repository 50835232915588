import { addSecondsToDate } from './addSecondsToDate';

interface Opts {
  streaks: Array<{ startDate: Date; streak: number }>;
  scaleFunction: (val: Date) => number;
  samplePeriodS: number;
}

export function getPausedSensorRanges(opts: Opts) {
  const ranges: Array<{ startX: number; endX: number }> = Array(
    opts.streaks.length
  );

  opts.streaks.forEach((d, idx) => {
    const startX = opts.scaleFunction(d.startDate);
    const endX = opts.scaleFunction(
      addSecondsToDate(d.startDate, opts.samplePeriodS * d.streak)
    );

    ranges[idx] = {
      startX,
      endX,
    };
  });

  return ranges;
}
