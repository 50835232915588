import { formatCoord } from '../lib/formatCoord';
import { MarkingObject, Point, CHAIR_SIZE } from '../types';
import { HoverPointDetails } from './HoverPointDetails';
import { Checkbox } from '@material-ui/core';
import * as mou from '../lib/markingObjectUtils';

interface Props {
  points: Point[];
  markingObject: MarkingObject;

  onChangePointDiameter?: (
    markingObjItemNum: number,
    pointIndex: number,
    customDiameter: boolean,
    diameter: number | undefined
  ) => void;

  onTogglePointExtended?: (
    markingObjItemNum: number,
    pointIndex: number
  ) => void;

  selectedMarkingObjectItemNum?: number;
}

export function MarkingObjectPointsTable(props: Props) {
  const obj = props.markingObject;

  return (
    <table>
      <thead>
        <tr>
          <th colSpan={8}>
            <HoverPointDetails />
          </th>
        </tr>
        <tr>
          <th>#</th>
          <th>X</th>
          <th>Y</th>
          <th>Z</th>
          {/* <th>H</th> */}
          {/* <th>V</th> */}
          {/* <th>A</th> */}
          {obj.typeDefinition.enclosed ? null : (
            <th>{obj.typeDefinition.circle ? 'd' : 'e'}</th>
          )}
        </tr>
      </thead>
      <tbody>
        {props.points.map((point, pointIndex) => (
          <tr key={pointIndex} className={point.status}>
            <td>{pointIndex + 1}</td>
            <td>{formatCoord(point.x)}</td>
            <td>{formatCoord(point.y)}</td>
            <td>{formatCoord(point.z)}</td>
            {/* <td>{point.h}</td> */}
            {/* <td>{point.v}</td> */}
            {/* <td>{point.a}</td> */}
            {obj.typeDefinition.enclosed ? null : (
              <td>
                {obj.typeDefinition.circle ? (
                  <div>
                    {/*
                      Option for extending first, last line for all
                      non-enclosed shapes that are not chair circle
                    */}
                    <select
                      //ref = {this.selectRef}
                      value={mou.getChairDiameterType(point.d)}
                      style={{ width: '100px' }}
                      onChange={(e) => {
                        if (
                          props.onChangePointDiameter &&
                          props.selectedMarkingObjectItemNum !== undefined
                        ) {
                          props.onChangePointDiameter(
                            props.selectedMarkingObjectItemNum,
                            pointIndex,
                            e.currentTarget.value === 'C',
                            e.currentTarget.value in CHAIR_SIZE
                              ? CHAIR_SIZE[
                                  e.currentTarget.value as 'S' | 'M' | 'L'
                                ]
                              : undefined
                          );
                        }
                      }}
                    >
                      {/* eslint-disable i18next/no-literal-string */}
                      {Object.keys(CHAIR_SIZE).map((sz) => (
                        <option key={sz} value={sz as keyof typeof CHAIR_SIZE}>
                          {sz} ({CHAIR_SIZE[sz as keyof typeof CHAIR_SIZE]}
                          m)
                        </option>
                      ))}
                      <option value="C">C (?)m</option>
                    </select>

                    <input
                      type="number"
                      min={0.1}
                      max={3.0}
                      value={point.d}
                      style={{
                        display: obj.hasCustomDiameter ? 'block' : 'none', // This was only to be visible if custom is selected
                        width: '100px',
                      }}
                      onChange={(e) => {
                        if (
                          props.onChangePointDiameter &&
                          props.selectedMarkingObjectItemNum !== undefined
                        ) {
                          props.onChangePointDiameter(
                            props.selectedMarkingObjectItemNum,
                            pointIndex,
                            true,
                            parseFloat(e.currentTarget.value)
                          );
                        }
                      }}
                    />
                  </div>
                ) : (
                  <>
                    {/*
                      Option for extending first, last line for all
                      non-enclosed shapes that are not chair circle
                    */}
                    {pointIndex === 0 ||
                    pointIndex === obj.points.length - 1 ? (
                      <Checkbox
                        color="primary"
                        checked={Boolean(point.e)}
                        onChange={() => {
                          if (
                            props.onTogglePointExtended &&
                            props.selectedMarkingObjectItemNum !== undefined
                          ) {
                            props.onTogglePointExtended(
                              props.selectedMarkingObjectItemNum,
                              pointIndex
                            );
                          }
                        }}
                      />
                    ) : null}
                  </>
                )}
              </td>
            )}
          </tr>
        ))}
      </tbody>
    </table>
  );
}
