import React, { useState } from 'react';
import cs from 'classnames';

import qs from 'query-string';

// import { useAuth } from '../../lib/auth';
import { Redirect } from 'react-router';
import { useRouter } from '../../lib/useRouter';
import rolle_sone_green from '../../images/rolle-sone-green.png';

import { useAppContext } from '../../lib/global';
import { useAppContainerOverflow } from '../../lib/useAppContainerOverflow';
import { ErrorComponent } from '../../components/forms/shared/ErrorComponent';

import OneTimeCode from './OneTimeCode';

import { LayoutWithBackground } from './LayoutWithBackground';
import { CenteredCard } from './CenteredCard';
import { PublicHeader } from './PublicHeader';
import { PublicFooter } from './PublicFooter';
import { Button } from '../../components/Button';
import { t, TranslationKey } from '../../lib/i18n';
import * as api from '../../serverApi';

import './SelectRole.scss';
import { useApiCall } from '../../lib/useApiCall';

export function SelectRole() {
  useAppContainerOverflow();

  const app = useAppContext();
  const router = useRouter();
  const [requireOneTimeCode, setRequireOneTimeCode] = useState(false);
  const [roleId, setRoleId] = useState<number | undefined>(undefined);
  const active_role = app.user ? app.user.active_role : undefined;

  const res = useApiCall(api.me);
  const roles = res.data?.user?.roles ?? app.user?.roles;
  const selected_role = roles && roles.find((r) => r.id === roleId);

  if (app.isAuthenticated && app.user?.password_expiration_days === 0) {
    const url =
      router.location.pathname === '/'
        ? '/change-password'
        : `/change-password?redirect=${encodeURIComponent(
            router.location.pathname
          )}`;
    return <Redirect to={url} />;
  }

  function redirectAfterRoleSelection() {
    const values = qs.parse(router.location.search);
    const redirect = values.redirect ? values.redirect.toString() : '/';
    router.history.push(redirect);
  }

  async function selectRole(roleId: number) {
    const selectedRole = roles && roles.find((role) => role.id === roleId);
    if (!selectedRole || !app.user) {
      return;
    }

    if (
      !app.user.one_time_code_provided &&
      selectedRole.capabilities.includes('requireOneTimeCode')
    ) {
      setRoleId(roleId);
      setRequireOneTimeCode(true);
      return;
    }

    if (await app.setActiveRole(roleId)) {
      redirectAfterRoleSelection();
    }
  }

  async function handleOneTimeCodeSubmit() {
    if (!roleId) {
      return;
    }

    setRoleId(undefined);
    app.setOneTimeCodeProvided();

    if (await app.setActiveRole(roleId)) {
      redirectAfterRoleSelection();
    }
  }

  return (
    <div className="App" id="app">
      <div className="App-body" id="appBody">
        <LayoutWithBackground>
          <div className="SelectRole">
            <PublicHeader />
            <CenteredCard showIcon={false}>
              <div className="title-container">
                <h1>{t('publicComponents.SelectRole.selectRoleAndZone')}</h1>
                <img src={rolle_sone_green} alt="" />
              </div>

              {roles && roles.length > 0 ? (
                <>
                  <ul className="roles-selection-list">
                    {roles.map((r) => (
                      <li
                        key={r.id}
                        data-cy={`role-id-${r.id}`}
                        role="button"
                        onClick={() => selectRole(r.id)}
                        className={cs(
                          'role-item',
                          active_role && r.id === active_role.id
                            ? 'selected'
                            : undefined
                        )}
                      >
                        <strong>
                          {t(`common.roles.${r.role}` as TranslationKey)}
                        </strong>
                        {r.zone && (
                          <span>
                            {' '}
                            {t('publicComponents.SelectRole.for')}{' '}
                            <strong>{r.zone.name}</strong>
                          </span>
                        )}
                      </li>
                    ))}
                  </ul>

                  {app.error && <ErrorComponent msg={app.error} />}

                  {/* <div className="role-selection-actions actions-wrapper">
                <div className="actions-wrapper">
                  <button
                    className="btn btn-default btn-green btn-continue"
                    onClick={saveAndContinue}
                  >
                    Lagre og gå videre
                  </button>
                   <button className="btn btn-default btn-grey btn-cancel">
                    Avbryt
                  </button>
                </div>
              </div> */}
                </>
              ) : (
                <>
                  <div className="mb-2">
                    {t(
                      'publicComponents.SelectRole.noAccessIsRegisteredOnYourSystemUser'
                    )}
                    .
                  </div>
                </>
              )}

              {requireOneTimeCode && (
                <OneTimeCode
                  onSubmit={handleOneTimeCodeSubmit}
                  devices={selected_role ? selected_role.otp_devices : []}
                />
              )}

              <div className="d-flex justify-content-end mt-5">
                <Button
                  to="/"
                  onClick={(e) => {
                    e.preventDefault();
                    app.requestLogout();
                  }}
                >
                  {t('common.commonTexts.logOut')}
                </Button>
              </div>
            </CenteredCard>
            <PublicFooter />
          </div>
        </LayoutWithBackground>
      </div>
    </div>
  );
}
