import React from 'react';
import { RouteComponentProps } from 'react-router';
import { IconButton } from '../../../../components/IconButton';
import { useApiCall } from '../../../../lib/useApiCall';
import { useZoneContext } from '../../../../lib/ZoneContext';
import LoadingPlaceholder from '../../../../lib/ui/LoadingPlaceholder';
import { EditCareReceiverForm } from './EditCareReceiverForm';

import { t } from '../../../../lib/i18n';
import * as api from '../../../../serverApi';

export function EditCareReceiver(
  props: RouteComponentProps<{ careReceiverId: string }>
) {
  const zoneContext = useZoneContext();

  const res = useApiCall(api.getCareReceiverDetails, [
    parseInt(props.match.params.careReceiverId, 10),
  ]);

  if (res.loading) {
    return <LoadingPlaceholder heading={t('common.commonTexts.loading')} />;
  }

  if (!res.data) {
    return null;
  }

  const careReceiver = res.data;

  return (
    <div className="EditCareReceiver">
      <h1>
        {t('manage.careReceivers.edit.EditCareReceiver.changeServiceRecipient')}
      </h1>

      <IconButton
        icon="arrow-left"
        className="my-4"
        to={`/manage/${zoneContext.activeZone.id}/care-receivers/${careReceiver.care_receiver_id}`}
      >
        {t('common.commonButtons.back')}
      </IconButton>

      <EditCareReceiverForm
        onEdit={zoneContext.reload}
        careReceiver={careReceiver}
      />
    </div>
  );
}
