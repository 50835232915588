import React from 'react';
import { RouteComponentProps, Switch, Route } from 'react-router';
import { ListCareReceivers } from './ListCareReceivers';
import { CareReceiverDetails } from './CareReceiverDetails';

export function CareReceivers(props: RouteComponentProps<{}>) {
  return (
    <Switch>
      <Route
        exact={true}
        path={props.match.path}
        component={ListCareReceivers}
      />
      <Route
        exact={true}
        path={`${props.match.path}/:careReceiverId`}
        component={CareReceiverDetails}
      />
    </Switch>
  );
}
