import { EventType, SensorType } from '../serverApi';

const excluded = [
  'startup',
  'offline',
  'online',
  'floor',
  'bedmark',
  'speech',
  'battery',
  'tamper',
];

/**
 * Check if event type should be excluded from vkp
 */
export function isEventTypeExcludedFromVkp(
  sensor_type: SensorType,
  event_type: EventType
) {
  return excluded.includes(event_type);
}
