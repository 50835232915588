import React from 'react';
import useZoneSettingsForm from './lib/useZoneSettingsForm';
import { Panel } from '../../../../../components/Panel';
import { FormBody } from './lib/FormBody';
import { FormControlLabel, RadioGroup, Radio } from '@material-ui/core';
import { useZoneContext } from '../../../../../lib/ZoneContext';
import { Event } from '../../../../care/lib/CareContext';
import EventCard from '../../../../care/components/EventCard';
import * as api from '../../../../../serverApi';
import { t } from '../../../../../lib/i18n';

import './SensorDisplayName.scss';

const fields: Array<keyof api.ZoneSettings> = [
  'sensor_display_area_format',
  'sensor_display_name_format',
];

export function SensorDisplayName() {
  const ctx = useZoneContext();
  const form = useZoneSettingsForm({ fields });

  const options = [
    {
      label: t('manage.zone.SensorDisplayName.zone_housingUnit_sensor'),
      area_format: '{parent_zone_name}',
      name_format: '{zone_name}{sep}{sensor_name}',
      area_example: t(
        'manage.zone.SensorDisplayName.zone_housingUnit_sensor.areaExample'
      ),
      name_example: t(
        'manage.zone.SensorDisplayName.zone_housingUnit_sensor.nameExample'
      ),
    },
    {
      label: t('manage.zone.SensorDisplayName.zone_person_sensor'),
      area_format: '{parent_zone_name}',
      name_format:
        '{care_receiver_first_name} {care_receiver_last_name}{sep}{sensor_name}',
      area_example: t(
        'manage.zone.SensorDisplayName.zone_person_sensor.areaExample'
      ),
      name_example: t(
        'manage.zone.SensorDisplayName.zone_person_sensor.nameExample'
      ),
    },
    {
      label: t('manage.zone.SensorDisplayName.person_sensor'),
      area_format: '{care_receiver_first_name} {care_receiver_last_name}',
      name_format: '{sensor_name}',
      area_example: t(
        'manage.zone.SensorDisplayName.person_sensor.areaExample'
      ),
      name_example: t(
        'manage.zone.SensorDisplayName.person_sensor.nameExample'
      ),
    },
  ];

  const selectedIndex = options.findIndex(
    (a) =>
      a.area_format === form.displayValues?.sensor_display_area_format &&
      a.name_format === form.displayValues?.sensor_display_name_format
  );

  function onChange(e: unknown, value: string) {
    if (value === undefined || value === null) {
      return;
    }
    const ix = parseInt(value, 10);
    if (options[ix]) {
      const { area_format, name_format } = options[ix];
      form.formik.setFieldValue('sensor_display_area_format', area_format);
      form.formik.setFieldValue('sensor_display_name_format', name_format);
      form.formik.setFieldTouched('sensor_display_area_format');
      form.formik.setFieldTouched('sensor_display_name_format');
    }
  }

  return (
    <div className="SensorDisplayName">
      <h2>
        {t('manage.zone.SensorDisplayName.headingFor')} {ctx.activeZone.name}
      </h2>

      <Panel>
        <p>
          <strong>{t('common.commonTexts.tips')}:</strong>{' '}
          {t(
            'manage.zone.settings.common.theSettingsFromTheParentZoneAreDisplayedInGrayWhenThisIsSelected'
          )}
        </p>
        <p>
          {t(
            'manage.zone.SensorDisplayName.theTextIsDisplayedOnTheSupervisionPageAndInNotificationsAmongOtherThings'
          )}
        </p>
      </Panel>

      <FormBody zoneSettingsForm={form}>
        <RadioGroup name="light" value={selectedIndex} onChange={onChange}>
          {options.map((o, i) => {
            return (
              <div className="SensorDisplayName-option" key={i}>
                <div className="SensorDisplayName-option-card">
                  <EventCard
                    event={
                      {
                        level: 'alarm',
                        event_type: 'fall',
                        sensor: {
                          display_area: o.area_example,
                          display_name: o.name_example,
                        },
                      } as Event
                    }
                    style={{ margin: 0 }}
                    onClick={() => onChange(undefined, `${i}`)}
                  />
                </div>
                <div className="SensorDisplayName-option-label">
                  <FormControlLabel
                    disabled={form.disabled}
                    value={i}
                    control={<Radio color="primary" />}
                    label={o.label}
                  />
                </div>
              </div>
            );
          })}
        </RadioGroup>
      </FormBody>
    </div>
  );
}
