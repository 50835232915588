import { FormikHelpers, useFormik } from 'formik';
import { generatePassword } from '../../../../lib/utils/generatePassword';
import * as Yup from 'yup';
import * as API from '../../../../serverApi';
import { t } from '../../../../lib/i18n';

export interface SetupIntegrationFields
  extends Omit<API.SuperVendorSetupIntegrationInput, 'parent_zone_id'> {
  parent_zone_id: number | null;
  account_id: number | null;
}

export type SetupIntegrationFormik = ReturnType<
  typeof useSetupIntegrationFormik
>;

interface UseSetupIntegrationFormOptions {
  validationSchema?: any | (() => any);
  onSubmit: (
    values: SetupIntegrationFields,
    formikHelpers: FormikHelpers<SetupIntegrationFields>
  ) => Promise<any>;
}

export function useSetupIntegrationFormik(
  opts: UseSetupIntegrationFormOptions
) {
  return useFormik<SetupIntegrationFields>({
    initialValues: {
      account_id: null,
      parent_zone_id: null,
      zone_name: '',
      username: '',
      password: generatePassword('integration'),
    },
    validateOnBlur: false,
    validateOnChange: true,
    validationSchema: Yup.object().shape({
      account_id: Yup.number()
        .integer()
        .required()
        .typeError('Invalid account'),
      parent_zone_id: Yup.number()
        .integer()
        .required()
        .typeError('Invalid zone'),
      zone_name: Yup.string().required(),
      username: Yup.string()
        .required()
        .matches(
          /^[A-Za-z0-9_-]+$/,
          t('supervendor.Integration.usernameRegex')
        ),
      password: Yup.string().required(),
    }),
    onSubmit: async (values, formikHelpers) => {
      await opts.onSubmit(values, formikHelpers);
    },
  });
}
