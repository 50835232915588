import React from 'react';
import Select from 'react-select';
import cn from 'classnames';

const reactSelectStyles = {
  menu: (provided: any) => ({
    ...provided,
    zIndex: 100,
  }),
};

type SensorTypeOption = {
  label: string;
  value: string | number;
};

interface Props {
  name: string;
  disabled?: boolean;
  placeholder?: string;
  className?: string;
  value?: string | number;
  options: SensorTypeOption[];
  onChange: (sensorType: SensorTypeOption) => void;
}

export const SelectFilter = (props: Props) => {
  return (
    <Select
      isSearchable
      isClearable
      styles={reactSelectStyles}
      name={props.name}
      isDisabled={props.disabled}
      placeholder={props.placeholder || 'Select'}
      className={cn('SelectFilter', props.className)}
      options={props.options}
      value={props.options.find((z) => z.value === props.value) || null}
      getOptionLabel={(data: any) => data['label']}
      getOptionValue={(data: any) => data['value'].toString()}
      onChange={(s: any) => props.onChange(s)}
    />
  );
};
