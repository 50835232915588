import { path } from 'd3';
import { addSecondsToDate } from './addSecondsToDate';

interface Opts {
  /**
   * The data streaks
   */
  streaks: Array<{ startDate: Date; streak: number }>;

  /**
   * The scaling function that will accept a X-axis domain value
   * and respond with the specific SVG X-axis position.
   */
  scaleFunction: (val: Date) => number;

  /**
   * Number of seconds in each sample period.
   */
  samplePeriodS: number;
}

/**
 *
 * Generates path for boolean series chart based on the values, scaled according to a
 * provided scale function (typically from the xScale function)
 *
 * @param {Opts} Required values and options for the function
 * @returns {string} Path definition for boolean series bar
 */

const MIN_STREAK_WIDTH = 2; //The minimum width in pixels of each streak when displaying the chart.

export function generatePathForBooleanSeries(opts: Opts): string {
  const p = path();

  opts.streaks.forEach((d) => {
    const startX = opts.scaleFunction(d.startDate);
    const realEndX = opts.scaleFunction(
      addSecondsToDate(d.startDate, opts.samplePeriodS * d.streak)
    );

    const endX = Math.max(realEndX, startX + MIN_STREAK_WIDTH);

    p.moveTo(startX, 0);
    p.lineTo(endX, 0);
  });

  return p.toString();
}
