import * as api from '../../../serverApi';

/**
 * Returns the keys of the zones in the format that
 * the match-sorter needs to filter the zone data.
 * E.x.
 * [
 *   'name',
 *   'zones.0.name',
 *   'zones.0.zones.0.name',
 *   'zones.0.zones.0.zones.0.name',
 *   'zones.0.zones.1.name',
 *   'zones.0.zones.1.zones.0.name',
 *]
 */
export function getAllZoneKeys(
  zones: api.StructureZone[] | null,
  preStr?: string,
  index?: number
): string[] {
  if (preStr === undefined && index === undefined) {
    return ['name'].concat(
      (zones ?? [])
        .filter((zone) => !!zone.zones && zone.zones.length > 0)
        .map((zone) => {
          return (
            zone.zones &&
            zone.zones.length > 0 &&
            zone.zones.map((zone, index) =>
              getAllZoneKeys(zone.zones, '', index)
            )
          );
        })
        .flat(Infinity) as string[]
    );
  }

  const prependString = `${preStr ? preStr + '.' : ''}zones.${index}`;

  return [`${prependString}.name`].concat(
    (zones ?? [])
      .map((zone, index) => getAllZoneKeys(zone.zones, prependString, index))
      .flat(Infinity) as string[]
  );
}
