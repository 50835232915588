import { StructureZone } from '../../../../../../serverApi';
import { getParentZone } from './getParentZone';
/**
 *
 * Recursive function to find all ancestors/parents of a zone
 *
 * @param rootZone top most zone
 * @param zone selected zone
 * @returns ancestors/parents of selected zone;
 */
export const getZoneAncestors = (
  rootZone: StructureZone,
  zone: StructureZone
): StructureZone[] => {
  let accumulator: StructureZone[] = [];

  const parentZone = getParentZone(rootZone, zone);

  if (parentZone) {
    const otherAncestors = getZoneAncestors(rootZone, parentZone);

    accumulator = [...accumulator, parentZone, ...otherAncestors];
  }

  return accumulator;
};
