import React from 'react';
import { RouteComponentProps } from 'react-router';
import * as api from '../../../serverApi';

import { useApiCall } from '../../../lib/useApiCall';
import { SensorContextProvider } from '../../../lib/SensorContext';

import LoadingPlaceholder from '../../../lib/ui/LoadingPlaceholder';

import SensorEventSettings from './events/SensorEventSettings';

import { IconButton } from '../../../components/IconButton';
import getFirstSegmentRelativeToRoot from '../../../lib/getFirstSegmentRelativeToRoot';
import { useRouter } from '../../../lib/useRouter';
import { useWindowSize } from '../../../lib/useWindowSize';
import { useZoneContext } from '../../../lib/ZoneContext';
import { t, TranslationKey } from '../../../lib/i18n';
import { getSensorIdFromCompositeId } from '../../../lib/parseSensorCompositeId';

interface Props extends RouteComponentProps<{ sensorId: string }> {}

export default function AlertDetails(props: Props) {
  const router = useRouter();
  const ctx = useZoneContext();
  const windowSize = useWindowSize();

  const sensorId = props.match.params.sensorId;
  const res = useApiCall(api.getSensorDetails, [sensorId]);

  if (res.loading) {
    return <LoadingPlaceholder heading={t('common.commonTexts.loading')} />;
  }

  if (!res.data) {
    return null;
  }

  const currentSegment = getFirstSegmentRelativeToRoot(
    router.match.url,
    router.location.pathname
  );

  const backUrl =
    currentSegment === '' || !windowSize.isTabletOrMobile
      ? `/manage/${ctx.activeZone.id}/sensors`
      : router.match.url;

  return (
    <>
      <SensorContextProvider
        value={{
          id: res.data.sensor.id,
          name: res.data.sensor.name,
          sensor_type: res.data.sensor.sensor_type,
          is_enabled: !!res.data.sensor.is_enabled,
          zone: res.data.sensor?.zone
            ? {
                id: res.data.sensor.zone.id,
                name: res.data.sensor.zone.name,
                parent: null,
              }
            : undefined,
          careReceiver: res.data.sensor.care_receiver,
          eventTypes: res.data.sensor.event_types,
          markingsDefined: res.data.sensor.markings_defined,
          reload: res.reload,
        }}
      >
        <h1>
          {t('manage.sensors.events.alertOverviewFor')}{' '}
          {t(
            `common.commonSensors.${res.data.sensor.sensor_type}` as TranslationKey
          )}
          , #{getSensorIdFromCompositeId(res.data.sensor.id)}
        </h1>

        <div className="mt-4 mb-4">
          <IconButton icon="arrow-left" to={backUrl}>
            {t('common.commonButtons.back')}
          </IconButton>
        </div>

        <SensorEventSettings />
      </SensorContextProvider>
    </>
  );
}
