import React from 'react';

import './LabeledValue.scss';

interface Props {
  label?: string;
  children?: React.ReactNode;
  /**
   * Data testing attribute
   */
  dataCy?: string;
}

export function LabeledValue(props: Props) {
  return (
    <div className="LabeledValue" data-cy={props.dataCy}>
      {props.label && <div className="LabeledValue-label">{props.label}</div>}
      <div className="LabeledValue-value">{props.children}</div>
    </div>
  );
}
