import classNames from 'classnames';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSpinner } from '@fortawesome/pro-light-svg-icons';
import { Button } from 'reactstrap';
import { UserFormik } from '../lib/useUsersFormik';
import { UserFormDetails } from './UserFormDetails';
import { UserFormRoles } from './UserFormRoles';
import { SuperUser } from '../../../../serverApi';
import { UserFormRolesTable } from './UserFormRolesTable';

interface Props {
  formik: UserFormik;
  userId?: number;
  userRoles?: SuperUser['user_roles'];
  className?: string;
  mode: 'create' | 'edit';
  readOnly?: boolean;
}

export const UserForm = (props: Props) => {
  const { values, setFieldValue } = props.formik;

  const handleDelete = (index: number) => {
    if (!values.roles) {
      return;
    }

    const tmp = [...values.roles];
    tmp.splice(index, 1);
    setFieldValue('roles', tmp);
  };

  const addRole = (role: string) => {
    if (role === '') {
      return;
    }

    setFieldValue('roles', [...(values.roles ?? []), role]);
  };

  return (
    <div className={classNames('UserForm', props.className)}>
      <UserFormDetails
        mode={props.mode}
        formik={props.formik}
        readOnly={props.readOnly}
      />

      {props.mode === 'create' ? (
        <UserFormRolesTable
          setSelectedRole={addRole}
          handleDelete={handleDelete}
          disabled={props.formik.isSubmitting}
          roles={(values.roles ?? []).map((role) => ({ role }))}
        />
      ) : (
        <UserFormRoles
          userId={props.userId}
          formik={props.formik}
          readOnly={props.readOnly}
        />
      )}

      <div className="mt-3">
        <Button
          color="primary"
          disabled={props.formik.isSubmitting || props.readOnly}
          onClick={props.formik.submitForm}
        >
          Save
          {props.formik.isSubmitting && (
            <FontAwesomeIcon spin className="ml-2" icon={faSpinner} />
          )}
        </Button>
      </div>
    </div>
  );
};
