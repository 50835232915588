import React from 'react';
import { Switch, Route, RouteComponentProps } from 'react-router';
import { ListAlertSettings } from './ListAlertSettings';
import { NewAlertSetting } from './NewAlertSetting';
import { EditAlertSetting } from './EditAlertSetting';
import { AlertSettingDetails } from './AlertSettingDetails';
import { useRedirectOnZoneChange } from '../../../lib/useRedirectOnZoneChange';

export function Alerts(props: RouteComponentProps<{}>) {
  useRedirectOnZoneChange({ relativePath: '/alerts' });
  return (
    <Switch>
      <Route
        exact={true}
        path={props.match.path}
        component={ListAlertSettings}
      />

      <Route
        exact={true}
        path={`${props.match.path}/new`}
        component={NewAlertSetting}
      />

      <Route
        exact={true}
        path={`${props.match.path}/:id([0-9]+)`}
        component={AlertSettingDetails}
      />

      <Route
        exact={true}
        path={`${props.match.path}/:id([0-9]+)/edit`}
        component={EditAlertSetting}
      />
    </Switch>
  );
}
