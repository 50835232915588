import React from 'react';
import cs from 'classnames';

import './ToolbarButton.scss';
import { Icon, IconDefinition } from '../../../components/Icon';

interface ToolbarButtonProps {
  className?: string;
  disabled?: boolean;
  active?: boolean;
  hidden?: boolean;
  label?: string;
  onClick?: (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
  icon?: IconDefinition;
  accentColor?: boolean;
}

export function ToolbarButton(props: ToolbarButtonProps) {
  if (props.hidden) {
    return null;
  }
  return (
    <button
      className={cs(
        'ToolbarButton',
        props.active ? 'ToolbarButton--active' : undefined,
        props.accentColor ? 'ToolbarButton--accentColor' : undefined,
        props.className
      )}
      title={props.label}
      disabled={props.disabled}
      onClick={props.onClick}
    >
      {props.icon && <Icon icon={props.icon} />}
      {props.label && <div className="sr-only">{props.label}</div>}
    </button>
  );
}
