import { useEffect } from 'react';
import * as api from '../serverApi';
interface IdleKeepAliveOptions {
  when: boolean;
}

/**
 *
 * Keep connection with server alive by firing a request at '/me' endpoint every 5 minutes,
 * that resets the idle timeleft value
 *
 * @param when; when to keep connection alive
 */
export const useIdleKeepAlive = (opts: IdleKeepAliveOptions) => {
  useEffect(() => {
    let interval: NodeJS.Timeout | undefined;

    if (opts.when) {
      interval = setInterval(async () => {
        await api.me();
      }, 500000);
    }
    return () => interval && clearInterval(interval);
  }, [opts.when]);
};
