import React from 'react';
import { Link } from 'react-router-dom';

import {
  SuperVendorGetAccountsNewInput,
  useSuperVendorGetAccountsNewQuery,
} from '../../../serverApi';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSyncAlt, faSpinnerThird } from '@fortawesome/pro-solid-svg-icons';

import TableCell from '@material-ui/core/TableCell';
import { useSuperStyles } from '../useSuperStyles';
import { Button } from '@material-ui/core';
import HeadingBar from '../shared/HeadingBar';
import { Card } from '../shared/Card';
import { Table } from '../shared/Table';
import useFilters from '../shared/filters/useFilters';
import { transformSortStringToObject } from '../../../lib/transformSortStringToObject';
import { UsePagination } from '../../../hooks/usePagination';
import { Pagination } from '../../../components/Pagination/Pagination';

const columns = [
  { key: 'account_id', header: 'Account ID', sortable: true },
  { key: 'name', header: 'Name', sortable: true },
  { key: 'account_code', header: 'Account code', sortable: true },
  { key: 'actions', header: '' },
];

export function ListVendors() {
  const filters = useFilters<SuperVendorGetAccountsNewInput>({
    initialValues: { is_vendor: true, sort: 'name asc' },
    key: 'super-vendors',
  });

  const res = useSuperVendorGetAccountsNewQuery(filters.values, {
    keepPreviousData: true,
  });
  const c = useSuperStyles();

  if (res.error) {
    return <span>Error!</span>;
  }

  const handleSortClick = (columnKey: string) => {
    const currentSort = transformSortStringToObject(filters.values.sort);

    /**
     * We sort by ascending order if there's no previous sorting column or we're switching the
     * column that's being sorted
     */
    if (!currentSort || currentSort.key !== columnKey) {
      filters.setFilter('sort', `${columnKey} asc`);
      return;
    }

    /**
     * Otherwise, just switch the sorting order
     */
    if (currentSort?.key === columnKey) {
      const newSortOrder = currentSort.type === 'asc' ? 'desc' : 'asc';

      filters.setFilter('sort', `${columnKey} ${newSortOrder}`);
    }
  };

  let accounts = res && res.data && res.data.data ? res.data.data : [];

  const rows = accounts.map((account) => {
    return {
      id: account.account_id,
      account_id: (
        <TableCell component="th" scope="row">
          {account.account_id}
        </TableCell>
      ),
      name: <TableCell>{account.name}</TableCell>,
      account_code: <TableCell>{account.account_code}</TableCell>,
      actions: (
        <TableCell>
          <Button component={Link} to={`/super/accounts/${account.account_id}`}>
            Vis
          </Button>
        </TableCell>
      ),
    };
  });

  const paginationData: UsePagination = {
    data: accounts,
    currentPage: filters.values.page || 1,
    itemsPerPage: filters.values.page_size || 100,
    totalPages: Array.from({ length: res.data?.last_page || 1 }),
    setCurrentPage(page: number | ((page: number) => number)) {
      filters.setFilter('page', page);
    },
    setItemsPerPage(itemsPerPage: number | ((itemsPerPage: number) => number)) {
      filters.setFilter('page_size', itemsPerPage);
    },
    reset() {
      filters.setFilters({
        page: 1,
        page_size: filters.values.page_size || 100,
      });
    },
  };

  return (
    <>
      <HeadingBar>
        <h2>Vendors</h2>

        <div
          style={{
            height: '25px',
            width: '25px',
            fontSize: '25px',
            lineHeight: '25px',
          }}
        >
          {res.isLoading && (
            <FontAwesomeIcon icon={faSpinnerThird} spin={true} />
          )}
        </div>
        <Button onClick={() => res.refetch()} disabled={res.isLoading}>
          <FontAwesomeIcon
            icon={faSyncAlt}
            spin={res.isLoading}
            className="mr-2"
          />
          Refresh
        </Button>
      </HeadingBar>

      <Card>
        <Pagination loading={res.isLoading} pagination={paginationData} />
        <Table
          className={c.table}
          columns={columns}
          rows={rows}
          loading={res.isLoading}
          sortColumn={transformSortStringToObject(filters.values.sort)}
          onSortClick={handleSortClick}
        />
      </Card>
    </>
  );
}
