import React, { PropsWithChildren } from 'react';
import cs from 'classnames';

import './HeadingBar.scss';

interface Props extends PropsWithChildren<{}> {
  className?: string;
}

export function HeadingBar(props: Props) {
  return (
    <div className={cs('HeadingBar', props.className)}>{props.children}</div>
  );
}

export default HeadingBar;
