import React, { useState } from 'react';
import * as Sentry from '@sentry/browser';

import ModalWrapper from '../../ModalWrapper';
import * as api from '../../../../serverApi';
import { useApiCall } from '../../../../lib/useApiCall';
import { TreeView } from '../../../TreeView';
import { t } from '../../../../lib/i18n';
import { isAxiosError } from '../../../../lib/utils/errorUtils';

export interface SensorSetZoneProps {
  isOpen: boolean;
  onSubmit: () => void;
  onToggle: () => void;
  accountId: number;
  sensor: api.SuperVendorSensor;
}

export default function SensorSetZoneModal(props: SensorSetZoneProps) {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');
  const accountStructure = useApiCall(api.superVendorGetStructureForAccount, [
    props.accountId,
  ]);

  const resetModal = () => {
    setError('');
    setActiveZone(undefined);
  };

  const zone = accountStructure?.data?.zone;

  const [activeZone, setActiveZone] = useState<api.StructureZone | undefined>(
    zone
  );

  const handleSubmit = async () => {
    if (!activeZone) {
      return;
    }

    try {
      setError('');
      setLoading(true);
      await api.superVendorUpdateSensor(props.sensor.id, {
        zone_id: activeZone.id,
      });
      props.onSubmit();
    } catch (e) {
      Sentry.captureException(e);
      if (isAxiosError(e)) {
        setError(e.message);
      } else {
        setError(String(e));
      }
      setLoading(false);
    }
  };

  const getNonHousingUnitZoneIds = (zone: api.StructureZone) => {
    let disabledZones: number[] = [];

    if (!zone?.is_housing_unit) {
      disabledZones.push(zone?.id);
    }
    //Break the recursion if there are no nesting zones
    if (!zone.zones || zone.zones.length === 0) {
      return disabledZones;
    }

    zone.zones.forEach((z) => {
      disabledZones = disabledZones.concat(getNonHousingUnitZoneIds(z));
    });

    return disabledZones;
  };

  return (
    <ModalWrapper
      title={t('components.SensorSetZoneModal.selectHousingUnit')}
      okButtonText={t('common.commonButtons.select')}
      cancelButtonText={t('common.commonButtons.cancel')}
      className="ModalWrapper-with-tree-view"
      isOpen={props.isOpen}
      loading={loading}
      onHide={props.onToggle}
      onSubmit={handleSubmit}
      onResetModal={resetModal}
    >
      <div>
        {error && <div className="alert alert-danger">{error}</div>}

        {zone && (
          <TreeView
            root={zone}
            className="SensorSetZone-tree"
            selectZone={setActiveZone}
            activeZone={activeZone}
            disabledZoneIds={getNonHousingUnitZoneIds(zone)}
          />
        )}
      </div>
    </ModalWrapper>
  );
}
