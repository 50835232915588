import React from 'react';
import {
  MenuItem,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableFooter,
  TableHead,
  TableRow,
  TextField,
} from '@material-ui/core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faArrowDown,
  faArrowUp,
  faChevronLeft,
  faChevronRight,
} from '@fortawesome/pro-solid-svg-icons';
import { Button } from '../Button';
import { usePagination, useSortBy, useTable } from 'react-table';
import LoadingPlaceholder from '../../lib/ui/LoadingPlaceholder';

import './ReactTable.scss';
import { t } from '../../lib/i18n';

interface Props {
  loading?: boolean;
  placeholderText?: string;
  [key: string]: any;
}

export const ReactTable = (props: Props) => {
  const { columns = [], data, ...restOptions } = props;

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    page,

    canPreviousPage,
    canNextPage,
    pageOptions,
    gotoPage,
    nextPage,
    previousPage,
    setPageSize,
    state: { pageIndex, pageSize },
  }: any = useTable<any>(
    {
      columns,
      data,
      initialState: { pageIndex: 0, ...props.initialState },
      ...restOptions,
    },
    useSortBy,
    usePagination
  );

  const colSpan = columns?.length || 5;

  return (
    <TableContainer>
      <Table {...getTableProps()} className="ReactTable" padding="none">
        <TableHead>
          {headerGroups.map((headerGroup: any) => (
            <TableRow {...headerGroup.getHeaderGroupProps()}>
              {headerGroup.headers.map((column: any) =>
                column.hidden ? null : (
                  <TableCell
                    {...column.getHeaderProps(column.getSortByToggleProps())}
                    style={{ width: 220, padding: 5 }}
                  >
                    {column.render('Header')}
                    <span className="ml-1">
                      {column.isSorted ? (
                        column.isSortedDesc ? (
                          <FontAwesomeIcon icon={faArrowDown} />
                        ) : (
                          <FontAwesomeIcon icon={faArrowUp} />
                        )
                      ) : (
                        ''
                      )}
                    </span>
                  </TableCell>
                )
              )}
            </TableRow>
          ))}
        </TableHead>
        <TableBody {...getTableBodyProps()}>
          {props.loading ? (
            <TableRow>
              <TableCell colSpan={colSpan} style={{ padding: 5 }}>
                <LoadingPlaceholder />
              </TableCell>
            </TableRow>
          ) : (
            <>
              {!page || page.length === 0 ? (
                <TableRow>
                  <TableCell colSpan={colSpan} style={{ padding: 5 }}>
                    <span
                      style={{
                        display: 'flex',
                        justifyContent: 'center',
                        padding: 10,
                      }}
                    >
                      <em>{props.placeholderText || 'No data !'}</em>
                    </span>
                  </TableCell>
                </TableRow>
              ) : (
                page.map((row: any) => {
                  prepareRow(row);
                  return (
                    <TableRow {...row.getRowProps()}>
                      {row.cells.map((cell: any) => {
                        return (
                          <TableCell
                            {...cell.getCellProps()}
                            style={{ padding: 5 }}
                          >
                            {cell.render('Cell')}
                          </TableCell>
                        );
                      })}
                    </TableRow>
                  );
                })
              )}
            </>
          )}
        </TableBody>
        {!props.loading && (
          <TableFooter classes={{ root: 'ReactTable-footer' }}>
            <TableRow>
              <TableCell colSpan={colSpan}>
                <div className="my-1 d-inline-flex align-items-center">
                  <Button
                    small
                    variant="contained"
                    onClick={() => previousPage()}
                    disabled={!canPreviousPage}
                  >
                    <FontAwesomeIcon icon={faChevronLeft} />
                  </Button>

                  <span className="my-3 mx-3">
                    {t('components.ReactTable.page')}:
                    <strong className="ml-1">
                      {pageIndex + 1} {t('common.commonTexts.of')}{' '}
                      {page.length === 0 ? 1 : pageOptions.length}
                    </strong>
                  </span>

                  <Button
                    small
                    variant="contained"
                    onClick={() => nextPage()}
                    disabled={!canNextPage}
                  >
                    <FontAwesomeIcon icon={faChevronRight} />
                  </Button>
                </div>

                <div className="my-1 mx-3 d-inline-flex align-items-center">
                  <span> {t('components.ReactTable.goToPage')}:</span>
                  <TextField
                    disabled={page.length === 0}
                    type="number"
                    className="ml-3"
                    defaultValue={1}
                    onChange={(e) => {
                      const page = e.target.value
                        ? Number(e.target.value) - 1
                        : 0;
                      gotoPage(page);
                    }}
                    style={{ width: '80px', display: 'inline-block' }}
                  />
                </div>

                <div className="my-1 mx-3 d-inline-flex align-items-center">
                  <span>{t('common.commonButtons.show')}:</span>

                  <TextField
                    select
                    disabled={page.length === 0}
                    InputProps={{ style: { width: '80px' } }}
                    className="ml-2"
                    value={pageSize}
                    onChange={(e) => {
                      setPageSize(Number(e.target.value));
                    }}
                  >
                    {[5, 10, 20, 30, 40, 50].map((pageSize) => (
                      <MenuItem key={pageSize} value={pageSize}>
                        {pageSize}
                      </MenuItem>
                    ))}
                  </TextField>
                </div>
              </TableCell>
            </TableRow>
          </TableFooter>
        )}
      </Table>
    </TableContainer>
  );
};
