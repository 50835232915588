import React, { useState } from 'react';
import { toast } from 'react-toastify';
import {
  CareReceiverBlockedUser,
  useCareReceiverFormik,
} from '../lib/useCareReceiverFormik';
import { CareReceiverForm } from '../CareReceiverForm';
import { useRouter } from '../../../../lib/useRouter';
import { useZoneContext } from '../../../../lib/ZoneContext';
import {
  errorIsString,
  useErrorHandler,
} from '../../../../lib/useErrorHandler';

import { t } from '../../../../lib/i18n';
import * as yup from 'yup';
import * as api from '../../../../serverApi';
import { useZoneTreeContext } from '../../../care/lib/ZoneTreeContext';
import { useAccount } from '../../../../lib/useAccount';
import isValidNorwegianId from '../../../../lib/isValidNorwegianId';
import { isValidationError } from '../../../../lib/utils/errorUtils';

interface Props {
  careReceiver: api.CareReceiverDetails;
  onEdit?: () => void;
}
export function EditCareReceiverForm(props: Props) {
  const router = useRouter();
  const ctx = useZoneContext();
  const treeCtx = useZoneTreeContext();
  const errorHandler = useErrorHandler();

  const careReceiver = props.careReceiver;
  const account = useAccount();
  const initialZone = treeCtx.getZoneById(careReceiver.zone?.id);

  const [selectedZone, setSelectedZone] = useState<
    api.StructureZone | undefined
  >(initialZone);

  const handleSetSelectedZone = (zoneId?: number) => {
    const zone = treeCtx.getZoneById(zoneId);
    setSelectedZone(zone);
  };

  const formik = useCareReceiverFormik({
    initialValues: {
      national_id_number: careReceiver.national_id_number ?? null,
      first_name: careReceiver.first_name ?? null,
      last_name: careReceiver.last_name ?? null,
      zone_id: careReceiver.zone?.id ?? null,
      blocked_users: careReceiver.blocked_users,
      nationalIdRequired:
        !account.disable_national_id_numbers &&
        account.require_national_id_for_care_receivers,
    },
    validationSchema: () =>
      yup.object().shape({
        national_id_number: yup
          .string()
          .nullable()
          .when('nationalIdRequired', {
            is: true,
            then: yup
              .string()
              .required(t('common.commonTexts.birthNumberorDnumberIsRequired')),
            otherwise: yup.string(),
          })
          .test(
            'national id number',
            t('common.commonTexts.invalidBirthNumber'),
            (x) =>
              !!props.careReceiver.national_id_number ||
              account.disable_national_id_numbers ||
              isValidNorwegianId(x) ||
              !x
          ),
        first_name: yup.string().nullable(),
        last_name: yup.string().nullable(),
        zone_id: yup.number().integer().nullable(),
      }),
    onSubmit: async (values, formikHelpers) => {
      try {
        await api.updateCareReceiver(props.careReceiver.care_receiver_id, {
          first_name: values.first_name,
          last_name: values.last_name,
          zone_id: values.zone_id,
          blocked_user_ids: values.blocked_users.map(
            (bu: CareReceiverBlockedUser) => bu.blocked_user_id
          ),
          ...(values.national_id_number &&
          isValidNorwegianId(values.national_id_number)
            ? {
                national_id_number: values.national_id_number,
              }
            : {}),
        });

        formikHelpers.resetForm(values);
        toast.success(
          t(
            'manage.careReceivers.edit.EditCareReceiverForm.careReceiverUpdatedSuccessfully'
          )
        );

        if (props.onEdit) {
          props.onEdit();
        }

        // If we set zone_id to null for a care receiver, we should redirect to all care receivers list,
        router.history.push(
          `/manage/${values.zone_id ?? ctx.activeZone.id}/care-receivers${
            values.zone_id ? `/${props.careReceiver.care_receiver_id}` : ''
          }`
        );
      } catch (e) {
        if (isValidationError(e)) {
          formikHelpers.setErrors(e.response.data.errors);
          for (const [key, value] of Object.entries(e.response?.data.errors)) {
            if (Array.isArray(value)) {
              formikHelpers.setFieldError(
                key,
                value
                  .map((v) => {
                    if (
                      key === 'national_id_number' &&
                      v === 'validation.required'
                    ) {
                      return t(
                        'common.commonTexts.birthNumberorDnumberIsRequired'
                      );
                    }
                    return v;
                  })
                  .join()
              );
            }
          }
        }
        errorHandler.handleError(e);
      }
    },
  });

  return (
    <CareReceiverForm
      mode="edit"
      form={formik}
      error={errorIsString(errorHandler.error) ? errorHandler.error : undefined}
      selectedZone={selectedZone}
      onSelectZone={(zoneId) => handleSetSelectedZone(zoneId)}
      disableNationalId={props.careReceiver.national_id_number ? true : false}
    />
  );
}
