import { useAppContext } from '../../../lib/global';
import { useDashboardEventCounterData } from './useDashboardEventCounterData';
import { useDashboardOverviewData } from './useDashboardOverviewData';

interface Opts {
  skip?: boolean;
}

export function useDashboardPollEvents(opts: Opts) {
  const app = useAppContext();
  const overview = useDashboardOverviewData(
    app.user?.active_role?.zone?.zone_id,
    opts.skip
  );

  const eventCounter = useDashboardEventCounterData(
    app.user?.active_role?.zone?.zone_id,
    opts.skip
  );
  return {
    overview: overview.data,
    eventCounter: eventCounter.data,
    loadingOverview: overview.loading,
    loadingEventCounter: eventCounter.loading,
  };
}
