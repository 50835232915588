import * as api from '../serverApi';

/**
 * Return only housing unit ID-s.
 * @param zone - root zone structure.
 */
const getHousingUnitsIds = (zone?: api.StructureZone): number[] => {
  let response = [] as number[];

  if (!zone) {
    return [] as number[];
  }

  if (zone.hasOwnProperty('is_housing_unit') && zone.is_housing_unit) {
    response = response.concat(zone.id);
  }

  if (zone.zones && zone.zones.length > 0) {
    zone.zones &&
      zone.zones.forEach((zone: api.StructureZone) => {
        response = response.concat(getHousingUnitsIds(zone));
      });
  }

  return response;
};

export default getHousingUnitsIds;
