import { MarkingObject, Point, CHAIR_SIZE } from '../types';

export function isActualEnclosed(mo: MarkingObject) {
  if (mo.points.length < 3) {
    return false;
  }

  return (
    mo.points[0].h === mo.points[mo.points.length - 1].h &&
    mo.points[0].v === mo.points[mo.points.length - 1].v
  );
}

export function getDistance(p1: Point, p2: Point) {
  return Math.sqrt(Math.pow(p2.h - p1.h, 2) + Math.pow(p2.v - p1.v, 2));
}

export function getScaledPoint(point: Point, scale: number): Point {
  return {
    h: point.h * scale,
    v: point.v * scale,
  };
}

export function isSnappedPoint(markingObject: MarkingObject, point: Point) {
  const snapRadius = markingObject.type === 'reflex' ? 2 : 10;
  if (!markingObject.typeDefinition.enclosed) {
    return false;
  }
  if (markingObject.points.length < 2) {
    return false;
  }
  return getDistance(markingObject.points[0], point) < snapRadius;
}

export function getChairDiameterType(diameter: number | undefined) {
  if (typeof diameter !== 'number') {
    return 'C';
  }
  if (Math.round(diameter * 10) === Math.round(CHAIR_SIZE.S * 10)) {
    return 'S';
  } else if (Math.round(diameter * 10) === Math.round(CHAIR_SIZE.M * 10)) {
    return 'M';
  } else if (Math.round(diameter * 10) === Math.round(CHAIR_SIZE.L * 10)) {
    return 'L';
  } else {
    return 'C';
  }
}

export function isFirstPoint(markingObject: MarkingObject, point: Point) {
  return (
    markingObject.points.indexOf(point) === 0 &&
    isTwoPointsEqual(point, markingObject.points[0])
  );
}

export function isLastPoint(
  markingObject: MarkingObject,
  point: Point,
  pointIndex: number
) {
  return (
    pointIndex === markingObject.points.length - 1 &&
    isTwoPointsEqual(
      point,
      markingObject.points[markingObject.points.length - 1]
    )
  );
}

export function isTwoPointsEqual(point1: Point, point2: Point) {
  return (
    point1.e === point2.e &&
    point1.d === point2.d &&
    point1.h === point2.h &&
    point1.v === point2.v
  );
}

export function isEqual(a: Array<any>, b: Array<any>) {
  // if length is not equal
  if (a.length !== b.length) {
    return false;
  } else {
    // comapring each element of array
    for (var i = 0; i < a.length; i++) {
      if (a[i] !== b[i]) {
        return false;
      }
    }
    return true;
  }
}

export function trimClosingPoint(a: Array<Point>) {
  if (!a) {
    return a;
  }
  return a.length > 2 && isTwoPointsEqual(a[0], a[a.length - 1])
    ? a.slice(0, -1)
    : a;
}

export function arePointCoordinatesEqual(a: Array<Point>, b: Array<Point>) {
  if (!a || !b) {
    return false;
  }
  const at = trimClosingPoint(a);
  const bt = trimClosingPoint(b);

  if (at.length !== bt.length) {
    return false;
  }

  // Compare each point
  for (let i = 0; i < at.length; i++) {
    if (at[i].h !== bt[i].h || at[i].v !== bt[i].v) {
      return false;
    }
  }
  return true;
}

// import {
//     markingObjectDefinitions,
//     MarkingObjectType,
//     MarkingObjType,
//     enclosedObjects,
//     Point,
//     CHAIR_SIZE,
//   } from './marking';

//   // Note that types should start with uppercase characters (TS and React convention)
//   export class MarkingObj {
//     // Public readonly means that the field can be accessed by wherever, but TS will
//     // deny any attempts to write to the field from outside the class constructor.
//     public readonly id: number;
//     public readonly type: MarkingObjectType;
//     public readonly objId: string;
//     public readonly color: string;
//     public readonly isChairCircle: boolean;

//     public readonly isEnclosed: boolean;
//     public readonly isModified: boolean = false;

//     private snapRadius: number = 10;

//     constructor(id: number, type: MarkingObjectType) {
//       // Good practice to collect initialisations to the constructor
//       this.id = id;
//       this.type = type;
//       this.objId = `obj ${this.id}`;
//       this.color = markingObjectDefinitions[this.type].color;
//       this.isChairCircle = this.type === MarkingObjType.CHAIR_CIRCLE;
//       this.isEnclosed = enclosedObjects.indexOf(this.type) !== -1;
//       if (this.type === MarkingObjType.REFLEX) {
//         this.snapRadius = 2;
//       }
//     }

//     // Instead of defining isChair as a separate field, you can
//     // do e.g `if (obj.type === 'chair') {` -- TS will complain if you try to
//     // compare obj.type with anything other than a MarkingObjectType.
//     public isChair: boolean = this.type === MarkingObjType.CHAIR;

//     private points: Point[] = [];

//     pushPoint(point: Point) {
//       if (this.isChairCircle) {
//         if (point.d === undefined) {
//           point.d = 0.7;
//         }
//       }
//       if (this.isSnappedPoint(point)) {
//         this.points.push(this.getFirstPoint());
//       } else {
//         this.clearPointExtended(this.getNumPoints() - 1);
//         this.points.push(point);
//       }
//     }

//     popPoint() {
//       this.points.pop();
//     }

//     setPoint(pointIndex: number, point: Point) {
//       this.points[pointIndex] = point;
//     }

//     togglePointExtended(pointIndex: number) {
//       if (this.isEnclosed || this.isChairCircle || this.getNumPoints() < 2) {
//         return;
//       }
//       if (pointIndex === 0 || pointIndex === this.getNumPoints() - 1) {
//         //toggle only for either the first point or the last point
//         this.points[pointIndex].e = !this.points[pointIndex].e;
//       }
//     }

//     setPointExtended(pointIndex: number, extended: boolean) {
//       if (this.isEnclosed || this.isChairCircle || this.getNumPoints() < 2) {
//         return;
//       }
//       this.points[pointIndex].e = extended;
//     }

//     clearPointExtended(pointIndex: number) {
//       if (this.isEnclosed || this.isChairCircle || this.getNumPoints() < 2) {
//         return;
//       }
//       //clear an optional attribute e for this point  isActualEnclosed() {
//     if (this.getNumPoints() < 3) {
//         return false;
//       } else {
//         let firstPoint: Point = this.getFirstPoint();
//         let lastPoint: Point = this.getLastPoint();
//         return firstPoint.x === lastPoint.x && firstPoint.y === lastPoint.y;
//       }
//     }

//       this.points[pointIndex].e = undefined;
//     }

//     setPointDiameter(pointIndex: number, e: any) {
//       if (e.target instanceof HTMLSelectElement) {
//         //console.log("HTMLSelectElement");
//         const { value } = e.target;
//         if (value === 'S') {
//           this.points[pointIndex].d = CHAIR_SIZE.S;
//         } else if (value === 'M') {
//           this.points[pointIndex].d = CHAIR_SIZE.M;
//         } else if (value === 'L') {
//           this.points[pointIndex].d = CHAIR_SIZE.L;
//         }
//         this.points[pointIndex].c = value === 'C';
//       } else if (e.target instanceof HTMLInputElement) {
//         //console.log("HTMLInputElement")
//         this.points[pointIndex].d = Number(e.target.value); //diameter;
//         this.points[pointIndex].c = true;
//       }
//     }

//     getChairDiameterType(diameter: any) {
//       if (Math.round(diameter * 10) === Math.round(CHAIR_SIZE.S * 10)) {
//         //console.log(`${diameter} is S`);
//         return 'S';
//       } else if (Math.round(diameter * 10) === Math.round(CHAIR_SIZE.M * 10)) {
//         //console.log(`${diameter} is M`);
//         return 'M';
//       } else if (Math.round(diameter * 10) === Math.round(CHAIR_SIZE.L * 10)) {
//         //console.log(`${diameter} is L`);
//         return 'L';
//       } else {
//         //console.log(`${diameter} is C`);
//         return 'C';
//       }
//     }

//     isCustomDiameter(diameter: any) {
//       return this.getChairDiameterType(diameter) === 'C';
//     }

//     deletePoint(pointIndex: number) {
//       this.points.splice(pointIndex, 1);
//     }

//     clearPoints() {
//       // An object of type MarkingObj _must_ have a valid MarkingObjectType,
//       // so `this.type = ''` is not valid. Maybe it would be better to reset
//       // just the points?
//       // this.type = '';

//       // Immutability is a good practice in JS; create new objects instead of mutating old ones.
//       // Especially in frontend code that runs asynchronously, it might be that a component is
//       // still referring to the old array: assigning a new array will leave the old one in its
//       // place until it is no longer in use, and JavaScript garbage collection will clean it up.
//       // And it's easier to read.
//       this.points = [];

//       // this.points.splice(0, this.getNumPoints());
//     }
