import * as api from '../../../../serverApi';

type SingleSearchResult = api.SuperSearchResults['results'][0];

export function getTargetLinkForSearchResult(
  item: SingleSearchResult,
  area: 'super' | 'vendor'
): string | null {
  if (item.type === 'sensor') {
    return `/${area}/sensors/${item.entry.sensor_type}${item.entry.sensor_id}`;
  }

  if (item.type === 'account') {
    return `/${area}/accounts/${item.entry.account_id}`;
  }

  if (item.type === 'user') {
    return `/${area}/users/${item.entry.user_id}`;
  }

  return null;
}
