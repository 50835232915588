import React from 'react';
import { RouteComponentProps } from 'react-router';
import * as api from '../../../serverApi';
import { useApiCall } from '../../../lib/useApiCall';
import { InlineSpinner } from '../../../components/InlineSpinner';
import { useZoneContext } from '../../../lib/ZoneContext';
import getParentPath from '../../../lib/getParentPath';
import { IconButton } from '../../../components/IconButton';
import { AlertSettingDetailsCard } from './ui/AlertSettingDetailsCard';
import { ButtonGroup } from '../../../components/ButtonGroup';
import { Button } from '../../../components/Button';
import { useConfirmation } from '../../../lib/confirmation/ConfirmationContext';
import { useRouter } from '../../../lib/useRouter';
import { BorderWrapper } from '../../../components/BorderWrapper';
import { t } from '../../../lib/i18n';

type Props = RouteComponentProps<{ id: string }>;

export function AlertSettingDetails(props: Props) {
  const confirmation = useConfirmation();
  const alertSettingId = parseInt(props.match.params.id, 10);
  const res = useApiCall(api.getAlertSetting, [alertSettingId]);
  const ctx = useZoneContext();
  const router = useRouter();

  if (res.loading) {
    return <InlineSpinner text={t('common.commonTexts.loading')} />;
  }

  if (res.data === undefined) {
    return <p>{t('common.commonTexts.error')}</p>;
  }

  async function onDelete() {
    await confirmation.confirm(
      t('common.commonTexts.toConfirmDeletionPressOk')
    );
    await api.deleteAlertSetting(alertSettingId);
    router.history.push(getParentPath(props.location.pathname));
  }

  const isAlertSettingLocked = res.data.api_lock;

  return (
    <div className="AlertSettingDetails">
      <h1>
        {t('manage.alerts.common.notificationRulesAndRecipients')}:{' '}
        {ctx.activeZone.name}
      </h1>

      <div className="my-4">
        <IconButton
          icon="arrow-left"
          to={getParentPath(props.location.pathname)}
        >
          {t('common.commonButtons.back')}
        </IconButton>
      </div>

      <BorderWrapper>
        <AlertSettingDetailsCard
          alertSetting={{
            ...res.data,
            event_types: res.data.event_types
              .filter((x) => x.sensor_type === 'roommate')
              .map((x) => x.event_type),
          }}
        />
        <ButtonGroup>
          <Button
            disabled={isAlertSettingLocked}
            to={props.location.pathname + '/edit'}
            color="primary"
          >
            {t('common.commonButtons.edit')}
          </Button>

          <Button to={getParentPath(props.location.pathname)}>
            {t('common.commonButtons.back')}
          </Button>

          <Button
            disabled={isAlertSettingLocked}
            color="secondary"
            onClick={onDelete}
          >
            {t('common.commonButtons.delete')}
          </Button>
        </ButtonGroup>
      </BorderWrapper>
    </div>
  );
}
