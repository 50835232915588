import React, { useState } from 'react';
import { AssignSensorFormik } from './lib/useAssignSensorFormik';
import { AvailableOtherSensorsListSelect } from './AvailableOtherSensorsListSelect';
import {
  Checkbox,
  FormControlLabel,
  FormGroup,
  ListItemText,
  TextField,
} from '@material-ui/core';
import { ZonePickerField } from '../../../components/ZonePickerField/ZonePickerField';
import { Button } from '../../../components/Button';
import { InlineError } from '../../../components/InlineError';
import { ButtonGroup } from '../../../components/ButtonGroup';
import * as serverApi from '../../../serverApi';
import { RouteLeavingGuard } from '../../../components/RouteLeavingGuard';
import { t } from '../../../lib/i18n';
import { useHandleCancel } from '../../../lib/useHandleCancel';
import { useZoneContext } from '../../../lib/ZoneContext';
import { Panel } from '../../../components/Panel';
import { getLabelForSensorType } from '../../../lib/getLabelForSensorType';
import { generateAutoCompleteValue } from '../../../lib/utils/generateAutoCompleteValue';

interface Props {
  error?: string;
  form?: AssignSensorFormik;
  selectedZone?: serverApi.StructureZone;
  onSelectZone?: (zoneId?: number) => void;
  hasSerial: boolean;
}

export function ClimaxSensorForm(props: Props) {
  const hc = useHandleCancel();
  const zoneCtx = useZoneContext();
  const [selectedSensor, setSelectedSensor] = useState<
    undefined | serverApi.UnassignedClimaxSensorDetails
  >(undefined);

  const setSelectedZone = (zoneId?: number) => {
    props.form?.setFieldValue('zone_id', zoneId || null);
    props.form?.setFieldTouched('zone_id');
    if (props.onSelectZone) {
      props.onSelectZone(zoneId);
    }
  };

  const onSelectedSensor = (s: serverApi.UnassignedClimaxSensorDetails) => {
    props.form?.setFieldValue('serial', `${s.sensor_type}${s.sensor_id}`);
    props.form?.setFieldTouched('serial');
    setSelectedSensor(s);
  };

  const getDisabledZoneIds = (z?: serverApi.StructureZone): number[] => {
    let response: number[] = [];
    if (!z) {
      return response;
    }

    if (!z.is_housing_unit || (z.zones && z.zones.length > 0)) {
      response = response.concat(z.id);
    }

    z.zones?.forEach((zone) => {
      response = response.concat(getDisabledZoneIds(zone));
    });
    return response;
  };

  if (!props.form) {
    return null;
  }

  const selectedZone = props.selectedZone
    ? {
        id: props.selectedZone?.id,
        name: props.selectedZone.name,
      }
    : undefined;
  const disabledZoneIds = getDisabledZoneIds(zoneCtx.rootZone);
  const disabled = props.form.isSubmitting;

  const selectedSensorLabel = selectedSensor
    ? getLabelForSensorType(selectedSensor.sensor_type)
    : '';

  return (
    <div className="ClimaxSensorForm">
      <RouteLeavingGuard when={props.form?.dirty} />

      <Panel className="mt-4">
        <h3>{t('common.commonTexts.tips')}</h3>
        {t('manage.sensors.assign.AssignNewSensor.climaxTip')}
      </Panel>

      <form className="mt-3" onSubmit={props.form?.handleSubmit}>
        <div style={{ maxWidth: 400 }}>
          <ListItemText>
            {t('manage.sensors.assign.AssignNewSensor.availableSensors')}
          </ListItemText>
          <AvailableOtherSensorsListSelect
            selectedSensor={props.form.values['serial']}
            onSelectedSensor={onSelectedSensor}
          />

          <ListItemText className="mt-3">
            {t('manage.sensors.assign.AssignNewSensor.selectedSensor')}
          </ListItemText>

          <TextField
            value={selectedSensorLabel}
            onChange={() => {}}
            placeholder={t(
              'manage.sensors.assign.AssignNewSensor.chooseSensorPlaceholder'
            )}
            autoComplete={generateAutoCompleteValue()}
          />
        </div>

        <FormControlLabel
          control={
            <Checkbox
              color="primary"
              disabled={disabled}
              checked={props.form?.values.is_enabled}
              onChange={(e) => {
                props.form?.setFieldValue('is_enabled', e.target.checked);
                props.form?.setFieldTouched('is_enabled');
              }}
            />
          }
          label={t('manage.sensors.SensorForm.activateSensor')}
          className="mb-3 mt-3"
        />

        <FormGroup className="mb-3">
          <TextField
            type="text"
            name="name"
            label={t('manage.sensors.common.sensorName')}
            disabled={disabled}
            value={props?.form.values.name}
            style={{ maxWidth: '400px' }}
            onChange={props.form.handleChange}
            onBlur={props.form.handleBlur}
            error={props.form.errors.name !== undefined}
            helperText={props.form.errors.name}
            autoComplete={generateAutoCompleteValue()}
          />
        </FormGroup>

        <ZonePickerField
          disabled={disabled}
          label={t('common.commonTexts.housingUnit')}
          className="mb-3"
          textFieldStyle={{
            maxWidth: '400px',
            flex: '1 1 auto',
          }}
          error={props.form?.touched.zone_id ? props.form.errors.zone_id : ''}
          disabledZoneIds={disabledZoneIds}
          initialZoneId={props.selectedZone?.id}
          selectedZone={selectedZone}
          onSelectZone={(zone) => setSelectedZone(zone?.id)}
          onClear={() => setSelectedZone(undefined)}
        />

        <ButtonGroup direction="horizontal" className="mt-5">
          <Button
            color="primary"
            type="submit"
            disabled={disabled}
            icon={props.form?.isSubmitting ? 'gear' : undefined}
            spinIcon={props.form?.isSubmitting}
            small={true}
          >
            {t('common.commonButtons.ok')}
          </Button>
          <Button
            onClick={() => {
              hc.handleCancel(props.form?.dirty, null, () =>
                props.form?.resetForm()
              );
            }}
          >
            {t('common.commonButtons.cancel')}
          </Button>
        </ButtonGroup>

        {props.error && <InlineError>{props.error}</InlineError>}
      </form>
    </div>
  );
}
