import { InlineSpinner } from '../../components/InlineSpinner';
import { DashboardHeader } from './DashboardHeader';
import { DashboardHousingUnitOverview } from './DashboardHousingUnitOverview';
import { DashboardFooter } from './DashboardFooter';

import { useAppContext } from '../../lib/global';
import { useDashboardSseEvents } from './lib/useDashboardSseEvents';
import { useDashboardPollEvents } from './lib/useDashboardPollEvents';

import './Dashboard.scss';
import { useIdleKeepAlive } from '../../lib/useIdleKeepAlive';
import { Icon } from '../../components/Icon';
import { t } from '../../lib/i18n';

export function Dashboard() {
  const app = useAppContext();
  const sseEnabled = app.hasFeature('enable-status-streaming');
  useIdleKeepAlive({ when: sseEnabled });
  const pollData = useDashboardPollEvents({ skip: sseEnabled });
  const sseData = useDashboardSseEvents({ skip: !sseEnabled });

  const data = sseEnabled ? sseData : pollData;

  const showConnectionWarning = sseData.error && sseData.timeUntilNextRetry;

  return (
    <div className="App" id="app">
      <div className="App-body" id="appBody">
        <div className="Dashboard">
          <div className="Dashboard--green">
            <DashboardHeader className="Dashboard-header" />

            <span className="Dashboard-divideline"></span>
            {(data.loadingOverview && !data.overview) || !data.overview ? (
              <InlineSpinner />
            ) : (
              <DashboardHousingUnitOverview
                className="Dashboard-overview"
                units={data.overview}
              />
            )}
            {showConnectionWarning && (
              <div className="Dashboard-connection-interrupted-wrapper">
                <div className="Dashboard-connection-interrupted">
                  <Icon
                    className="Dashboard-connection-interrupted-icon"
                    icon="disconnected"
                  />
                  <div>
                    <h2 className="m-0">
                      {t('dashboard.connectionInterrupted')}
                    </h2>
                    <p className="ml-1">
                      {t('dashboard.retryingIn')} {sseData.timeUntilNextRetry}
                    </p>
                  </div>
                </div>
              </div>
            )}
          </div>

          <DashboardFooter
            className="Dashboard-footer"
            events={data.eventCounter}
            loading={data.loadingEventCounter}
          />
        </div>
      </div>
    </div>
  );
}
