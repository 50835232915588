import React from 'react';
import { FormGroup, TextField } from '@material-ui/core';
import { Panel } from '../../../../components/Panel';
import { generateAutoCompleteValue } from '../../../../lib/utils/generateAutoCompleteValue';

interface Props {
  className?: string;
  error?: string;
  disabled?: boolean;
  value: string;
  onChange: (ids: string) => void;
}

export const SensorsField = (props: Props) => {
  return (
    <FormGroup className={props.className}>
      <TextField
        type="text"
        name="code"
        label="Sensor IDs"
        disabled={props.disabled}
        value={props.value}
        onChange={(e) => props.onChange(e.target.value)}
        error={props.error !== undefined}
        helperText={props.error}
        autoComplete={generateAutoCompleteValue()}
      />
      <Panel className="mt-2">
        Tips: Enter comma-separated list of sensor IDs. Intervals are also
        allowed. E.g. 100,110,120-125 (inclusive).
      </Panel>
    </FormGroup>
  );
};
