import React from 'react';
import * as api from '../../../../serverApi';
import Select from 'react-select';
import cn from 'classnames';

const reactSelectStyles = {
  menu: (provided: any) => ({
    ...provided,
    zIndex: 100,
  }),
};

interface Props {
  disabled?: boolean;
  className?: string;
  value?: number | null;
  onChange: (account: api.Account) => void;
}

export const VendorFilter = (props: Props) => {
  const vendors = api.useSuperVendorGetAccountsNewQuery({ is_vendor: true });

  return (
    <Select
      isSearchable
      isClearable
      styles={reactSelectStyles}
      isDisabled={vendors.isLoading || props.disabled}
      isLoading={vendors.isLoading}
      placeholder="Vendor"
      className={cn('VendorFilter', props.className)}
      options={vendors?.data?.data || []}
      value={
        vendors?.data?.data?.find((z) => z.account_id === props.value) || null
      }
      getOptionLabel={(data: api.Account) => data['name']}
      getOptionValue={(data: api.Account) => data['account_id'].toString()}
      onChange={(s: any) => {
        props.onChange(s);
      }}
    />
  );
};
