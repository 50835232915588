import { useArgusAtom } from './argus';
import { Feature } from '../../../../../components/Feature';
import { useEffect } from 'react';
import * as api from '../../../../../serverApi';
import { t } from '../../../../../lib/i18n';

interface Props {
  sensorCompositeId?: string;
}

export function ArgusControls(props: Props) {
  const [atom, setAtom] = useArgusAtom();

  useEffect(() => {
    if (props.sensorCompositeId) {
      api
        .getArgusDisabledCategories(props.sensorCompositeId)
        .then((res) => {
          setAtom({
            ...atom,
            disabledCategories: {
              bed: res.categories.includes('bed'),
              chair: res.categories.includes('chair'),
              seating_group: res.categories.includes('seating_group'),
            },
          });
        })
        .catch((err) => {
          console.error('Failed to get disabled categories', err);
        });
    }
  }, [props.sensorCompositeId]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <div
      style={{
        border: '1px solid #ccc',
        borderRadius: '4px',
        backgroundColor: 'rgba(255,255,255,0.5)',
        padding: '4px',
        display: 'flex',
        flexFlow: 'column nowrap',
        marginTop: '8px',
        marginBottom: '8px',
      }}
    >
      <strong>{t('components.ArgusControls.markingSuggestionsHeading')}</strong>

      <label style={{ marginLeft: '6px' }}>
        <input
          type="checkbox"
          style={{ marginRight: '6px' }}
          checked={atom.showSuggestions}
          onChange={(e) => {
            setAtom({
              ...atom,
              showSuggestions: e.target.checked,
            });
          }}
        />
        {t('components.ArgusControls.showSuggestions')}
      </label>

      <label
        style={{
          marginLeft: '6px',
          color: atom.showSuggestions ? undefined : '#888',
        }}
      >
        <input
          type="checkbox"
          style={{ marginRight: '6px' }}
          disabled={!atom.showSuggestions}
          checked={atom.showMatchedSuggestions}
          onChange={(e) => {
            setAtom({
              ...atom,
              showMatchedSuggestions: e.target.checked,
            });
          }}
        />
        {t('components.ArgusControls.showMatchedSuggestions')}
      </label>

      <Feature feature="show-argus-markings-for-unsupported-types">
        <label
          style={{
            marginLeft: '6px',
            color: atom.showSuggestions ? undefined : '#888',
          }}
        >
          <input
            type="checkbox"
            style={{ marginRight: '6px' }}
            disabled={!atom.showSuggestions}
            checked={atom.showUnsupportedSuggestions}
            onChange={(e) => {
              setAtom({
                ...atom,
                showUnsupportedSuggestions: e.target.checked,
              });
            }}
          />
          {t('components.ArgusControls.showUnsupportedSuggestions')}
        </label>
      </Feature>

      <div>{t('components.ArgusControls.disabledCategories')}</div>

      <div>
        <label style={{ marginLeft: '6px' }}>
          <input
            type="checkbox"
            style={{ marginRight: '2px' }}
            checked={atom.disabledCategories.bed}
            onChange={(e) => {
              setAtom({
                ...atom,
                disabledCategories: {
                  ...atom.disabledCategories,
                  bed: e.target.checked,
                },
              });
            }}
          />
          {t('manage.sensors.marking.markingObjects.bed')}
        </label>

        <label style={{ marginLeft: '8px' }}>
          <input
            type="checkbox"
            style={{ marginRight: '2px' }}
            checked={atom.disabledCategories.chair}
            onChange={(e) => {
              setAtom({
                ...atom,
                disabledCategories: {
                  ...atom.disabledCategories,
                  chair: e.target.checked,
                },
              });
            }}
          />
          {t('manage.sensors.marking.markingObjects.chair_circle')}
        </label>

        <label style={{ marginLeft: '8px' }}>
          <input
            type="checkbox"
            style={{ marginRight: '2px' }}
            checked={atom.disabledCategories.seating_group}
            onChange={(e) => {
              setAtom({
                ...atom,
                disabledCategories: {
                  ...atom.disabledCategories,
                  seating_group: e.target.checked,
                },
              });
            }}
          />
          {t('manage.sensors.marking.markingObjects.chair_area')}
        </label>
      </div>
    </div>
  );
}
