import { Modal } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimesCircle } from '@fortawesome/pro-light-svg-icons';
import * as api from '../../serverApi';

import './ViewImageModal.scss';
import { ReadOnlyMarkingCanvasOverlay } from '../ReadOnlyMarkingCanvasOverlay';

export interface Props {
  isOpen: boolean;
  imgSrc?: string;
  streamType?: api.StreamType;
  markingObjects?: api.MarkingObject[];
  onLoad?: () => void;
  onError?: () => void;
  handleModal: () => void;
}

export default function ViewImageModal(props: Props) {
  function handleToggle() {
    props.handleModal();
  }

  return (
    <Modal
      backdrop={false}
      fade={false}
      isOpen={props.isOpen}
      toggle={handleToggle}
      className="ViewImageModal"
    >
      <span
        role="button"
        className="ViewImageModal-close"
        onClick={handleToggle}
      >
        <FontAwesomeIcon icon={faTimesCircle} />
      </span>

      <div className="ViewImageModal-body" onClick={handleToggle}>
        <img
          alt=""
          src={props.imgSrc}
          className="ViewImageModal-image"
          onLoad={props.onLoad}
          onError={props.onError}
        />
        {props.markingObjects && props.streamType !== 'depth' ? (
          <ReadOnlyMarkingCanvasOverlay markingObjects={props.markingObjects} />
        ) : null}
      </div>
    </Modal>
  );
}
