import React from 'react';
import { Button } from '../../../../components/Button';
import { InlineSpinner } from '../../../../components/InlineSpinner';
import * as API from '../../../../serverApi';
import { useErrorHandler } from '../../../../lib/useErrorHandler';
import { useAppContext } from '../../../../lib/global';
import { FullScreenLoader } from '../../../../components/FullScreenLoader/FullScreenLoader';
import { ErrorMessages } from '../../../../components/ErrorMessages';

interface Props {
  userId: number;
  className?: string;
}

export const ImpersonateButton: React.FC<Props> = (props) => {
  const app = useAppContext();
  const errorHandler = useErrorHandler();
  const [loading, setLoading] = React.useState(false);

  const handleOnClick = props.userId
    ? async () => {
        try {
          setLoading(true);
          await API.impersonateUserRoles({
            user_id: props.userId,
            redirect_url: '/super',
          });
          window.location.reload();
        } catch (e) {
          setLoading(false);
          errorHandler.handleError(e);
        }
      }
    : () => {};

  if (loading) {
    return <FullScreenLoader />;
  }

  if (
    !app.hasCapability('superImpersonateUserRoles') &&
    !app.hasCapability('vendorImpersonateUserRoles')
  ) {
    return null;
  }

  return (
    <>
      <Button
        disabled={loading}
        onClick={handleOnClick}
        className={props.className}
      >
        Impersonate roles
        {loading && <InlineSpinner className="ml-1" />}
      </Button>

      <ErrorMessages className="my-1" errorData={errorHandler} />
    </>
  );
};
