import { TextField, TextFieldProps } from '@material-ui/core';
import { debounce } from 'lodash';
import React, { ChangeEventHandler, useMemo, useState } from 'react';

interface Props extends Omit<TextFieldProps, 'onChange' | 'error'> {
  onChange: (value: string) => void;
  delay?: number;
  error?: string | null;
}
/**
 *
 * Default Material UI TextField component with an additional debounce functionality
 * that fires the onChange callback after a specified delay.
 *
 * @param props - TextFieldProps with an additional delay property
 * @returns MaterialUI TextField
 */
export const DebouncedInput = (props: Props) => {
  const { value, onChange, delay, error, helperText, variant, ...rest } = props;

  const [inputValue, setInputValue] = useState(value);

  React.useEffect(() => {
    setInputValue(value);
  }, [value]);

  const handleValueChange: ChangeEventHandler<HTMLInputElement> = (event) => {
    const currentValue = event.target.value;
    setInputValue(currentValue);
    handleDebouncedChange(currentValue);
  };

  const handleDebouncedChange = useMemo(
    () =>
      debounce((value: string) => {
        onChange(value);
      }, delay || 400),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [delay]
  );

  return (
    <TextField
      value={inputValue || ''}
      onChange={handleValueChange}
      error={!!props.error}
      helperText={props.error}
      variant={props.variant || 'outlined'}
      {...rest}
    />
  );
};
