import CareIndicator from './CareIndicator';

import { t } from '../../../lib/i18n';
import { useCareContext } from '../lib/CareContext';

import './CareIndicators.scss';

type Mode = 'alarms' | 'alerts' | 'sensors' | 'info';

interface Props {
  alarmCount?: number | undefined; // "Alarmer"
  alertCount?: number | undefined; // "Varsler"
  sensorCount?: number | undefined; // "Tilsyn"
  mode?: Mode;
  onSelectMode?: (area: Mode) => void;
  onClickAlarms?: () => void;
  onClickAlerts?: () => void;
  attentionAlarms?: boolean;
  attentionAlerts?: boolean;
}

export default function CareIndicators(props: Props) {
  const care = useCareContext();

  const select = (area: Mode) => () => {
    if (props.onSelectMode) {
      props.onSelectMode(area);
    }

    if (area === 'alarms' && props.onClickAlarms) {
      props.onClickAlarms();
    }

    if (area === 'alerts' && props.onClickAlerts) {
      props.onClickAlerts();
    }
  };

  return (
    <div className="CareIndicators">
      <CareIndicator
        type="alarms"
        title={t('care.components.CareIndicators.alarms')}
        value={props.alarmCount}
        selected={props.mode === 'alarms'}
        onSelect={select('alarms')}
        extraAttention={props.attentionAlarms}
      />

      <CareIndicator
        type="alerts"
        title={t('care.components.CareIndicators.alerts')}
        value={props.alertCount}
        selected={props.mode === 'alerts'}
        onSelect={select('alerts')}
        extraAttention={props.attentionAlerts}
      />

      <CareIndicator
        type="sensors"
        title={t('care.components.CareIndicators.sensorCount')}
        value={props.sensorCount}
        selected={props.mode === 'sensors'}
        onSelect={select('sensors')}
      />

      <CareIndicator
        type="info"
        selected={props.mode === 'info'}
        onSelect={() => {
          care.setStreamType(undefined);
          select('info')();
        }}
      />
    </div>
  );
}
