import { useState } from 'react';

function valueToString(value: any): string | undefined {
  switch (typeof value) {
    case 'string':
      return value;

    case 'number':
      return value.toString();

    case 'boolean':
      return value ? '1' : '0';

    case 'object':
      return JSON.stringify(value);
  }
  return undefined;
}

function checkSupportValue(value: any) {
  if (!supportedTypes.includes(typeof value)) {
    throw Error('Type no supported');
  }
}

const supportedTypes = ['string', 'number', 'boolean', 'object'];
/**
 * TODO: Parse object item after type, objects must be converted to
 * json string, and numbers must be converted to strings
 */
export function useSessionPersistedState<T>(
  key: string,
  defaultValue: T
): [T, (state: T) => void] {
  const parseStoredValue = (): T => {
    checkSupportValue(defaultValue);

    const storedValue = window.sessionStorage.getItem(key);
    if (!storedValue) {
      return defaultValue;
    }

    let value: any = defaultValue;
    switch (typeof defaultValue) {
      case 'string':
        value = (storedValue as unknown) as T;
        break;

      case 'number':
        value = parseFloat(storedValue);
        break;

      case 'boolean':
        value = storedValue === '1' ? true : false;
        break;

      case 'object':
        value = JSON.parse(storedValue);
        break;
    }
    return (value as unknown) as T;
  };

  const [item, setItemState] = useState<T>(parseStoredValue());

  const setItem = (input: T) => {
    checkSupportValue(input);
    window.sessionStorage.setItem(key, valueToString(input) ?? '');
    setItemState(input);
  };

  return [item, setItem];
}
