import React from 'react';
import classNames from 'classnames';

import './LogsFilterWrapper.scss';

interface Props {
  children: React.ReactNode;
  className?: string;
}
export const LogsFilterWrapper = (props: Props) => {
  return (
    <div className={classNames('LogsFilterWrapper', props.className)}>
      {props.children}
    </div>
  );
};
