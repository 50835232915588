import React from 'react';
import * as api from '../../../../../serverApi';
import { SensorSettings } from './SensorSettings';

import { ActionButton } from '../../../../../components/ActionButton';

import { IpAddressesCard } from './IpAddressesCard';
import { AssignmentDetailsCard } from '../shared/AssignmentDetailsCard';
import { SimulateEventPanel } from '../../../../../components/SimulateEventPanel';
import { SensorUpgradeSettings } from './SensorUpgradeSettings';
import { SensorStreaming } from './SensorStreaming';
import { SensorHistory } from './SensorHistory';

import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import Typography from '@material-ui/core/Typography';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { Card } from '../../../../super/shared/Card';
import { useAppContext } from '../../../../../lib/global';
import { BillingAndPO } from './BillingAndPO/BillingAndPO';
import { SensorWirelessStatus } from './wireless/SensorWirelessStatus';
import { AssignSensorSystem } from './AssignSensorSystem';

interface Props {
  sensor: api.SuperVendorSensor;
  onRequestReload?: () => void;
  markingObjects?: api.MarkingObject[];
}

export function SensorRoomMateDetails(props: Props) {
  const app = useAppContext();
  const sensor = props.sensor;
  const sensorCompositeId = `${sensor.sensor_type}${sensor.sensor_id}`;

  const hasSuperCapability = app.hasCapability('globalSuper');

  return (
    <>
      <AssignmentDetailsCard
        sensor={props.sensor}
        onRequestReload={props.onRequestReload}
      />

      <div
        style={{
          display: 'flex',
          flexFlow: 'row nowrap',
          marginLeft: '-1rem',
          marginRight: '-1rem',
          marginTop: '2rem',
        }}
      >
        <IpAddressesCard
          style={{ flex: '1 1 0', marginLeft: '1rem', marginRight: '1rem' }}
          sensor={sensor}
        />

        {hasSuperCapability && (
          <SensorUpgradeSettings
            style={{ flex: '1 1 0', marginLeft: '1rem', marginRight: '1rem' }}
            sensor_type={sensor.sensor_type}
            sensor_id={sensor.sensor_id}
          />
        )}

        <Card
          style={{ flex: '1 1 0', marginLeft: '1rem', marginRight: '1rem' }}
        >
          <h3>Actions</h3>

          {hasSuperCapability && (
            <>
              <ActionButton
                className="mr-1 mb-1"
                action={api.sendShortStatusSignal}
                label="getShortStatus"
                parameters={[sensorCompositeId]}
              />

              <ActionButton
                className="mr-1 mb-1"
                action={api.sendConfSignal}
                label="Conf"
                parameters={[sensorCompositeId]}
              />

              <ActionButton
                className="mr-1 mb-1"
                action={api.sendUpdateSignal}
                label="Update"
                parameters={[sensorCompositeId]}
              />
            </>
          )}
          <ActionButton
            className="mr-1 mb-1"
            action={api.rebootSensor}
            label="Reboot"
            parameters={[sensorCompositeId]}
          />

          <ActionButton
            className="mr-1 mb-1"
            action={api.restartSensor}
            label="Restart"
            parameters={[sensorCompositeId]}
          />

          <ActionButton
            className="mr-1 mb-1"
            action={api.resetAlgorithm}
            label="Reset algo"
            parameters={[sensorCompositeId]}
          />

          {hasSuperCapability && (
            <ActionButton
              className="mr-1 mb-1"
              label="Full upgrade"
              action={api.fullUpgrade}
              requestConfirmation={true}
              parameters={[sensorCompositeId]}
            />
          )}

          {hasSuperCapability && (
            <ActionButton
              className="mr-1 mb-1"
              label="Distro upgrade"
              action={api.distroUpgrade}
              requestConfirmation={true}
              parameters={[sensorCompositeId]}
            />
          )}
        </Card>
      </div>

      <Card className="Card--accordion my-4">
        <Accordion TransitionProps={{ unmountOnExit: true }}>
          <AccordionSummary expandIcon={<ExpandMoreIcon />}>
            <Typography>Simulate sensor events</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <SimulateEventPanel
              sensor_type={props.sensor.sensor_type}
              sensor_id={props.sensor.sensor_id}
              showEventSettings={true}
            />
          </AccordionDetails>
        </Accordion>

        {hasSuperCapability && (
          <Accordion TransitionProps={{ unmountOnExit: true }}>
            <AccordionSummary expandIcon={<ExpandMoreIcon />}>
              <Typography>Sensor settings</Typography>
            </AccordionSummary>
            <AccordionDetails>
              <SensorSettings sensor={sensor} />
            </AccordionDetails>
          </Accordion>
        )}

        <Accordion TransitionProps={{ unmountOnExit: true }}>
          <AccordionSummary expandIcon={<ExpandMoreIcon />}>
            <Typography>Status history log</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <SensorHistory sensor={sensor} />
          </AccordionDetails>
        </Accordion>

        <Accordion TransitionProps={{ unmountOnExit: true }}>
          <AccordionSummary expandIcon={<ExpandMoreIcon />}>
            <Typography>Sensor streaming</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <SensorStreaming
              sensor={sensor}
              markingObjects={props.markingObjects}
            />
          </AccordionDetails>
        </Accordion>

        {hasSuperCapability && (
          <Accordion TransitionProps={{ unmountOnExit: true }}>
            <AccordionSummary expandIcon={<ExpandMoreIcon />}>
              <Typography>Sensor wireless status</Typography>
            </AccordionSummary>
            <AccordionDetails>
              <SensorWirelessStatus sensor={sensor} />
            </AccordionDetails>
          </Accordion>
        )}

        {hasSuperCapability && (
          <Accordion TransitionProps={{ unmountOnExit: true }}>
            <AccordionSummary expandIcon={<ExpandMoreIcon />}>
              <Typography>Billing and PO</Typography>
            </AccordionSummary>
            <AccordionDetails>
              <BillingAndPO sensor={sensor} />
            </AccordionDetails>
          </Accordion>
        )}
        {hasSuperCapability && (
          <Accordion TransitionProps={{ unmountOnExit: true }}>
            <AccordionSummary expandIcon={<ExpandMoreIcon />}>
              <Typography>Move to another server</Typography>
            </AccordionSummary>
            <AccordionDetails>
              <AssignSensorSystem sensor={sensor} />
            </AccordionDetails>
          </Accordion>
        )}
      </Card>
    </>
  );
}
