import { useEffect, useRef } from 'react';
import { useApiCall } from '../../../../../lib/useApiCall';
import * as api from '../../../../../serverApi';
import { FrontendMarkingObjectType, MarkingObject, Point } from '../types';
import { ArgusDetection, useArgusAtom } from './argus';
import { isSupportedMarkingType } from './isSupportedMarkingType';
import { useAppContext } from '../../../../../lib/global';
import { ArgusDetectionShape } from './ArgusDetectionShape';
import { useIgnoredDetections } from './useIgnoredDetections';

interface Props {
  sensorId?: string;
  width: number;
  height: number;

  // The markings are provided only so that we can skip any suggestions that are
  // already present in the set.
  // TODO
  markingObjects?: MarkingObject[];

  addSuggestedMarkingObject?: (obj: {
    type: FrontendMarkingObjectType;
    points: Point[];
  }) => void;
}

export function ArgusSuggestions(props: Props) {
  const app = useAppContext();
  const [atom, setAtom] = useArgusAtom();

  const ignoredDetectionsTimestampThreshold =
    app.getFeatureValueNumber('argus-ignored-detections-timeout') ?? 30;

  const [ignoredDetections, setIgnoredDetections] = useIgnoredDetections();

  const res = useApiCall(api.getArgusConfiguration, [props.sensorId ?? ''], {
    skip: !props.sensorId && !atom.showSuggestions,
  });

  /**
   * Update the server whenever the disabled categories change
   */
  const firstRender = useRef('');
  const bed = atom.disabledCategories.bed ?? false;
  const chair = atom.disabledCategories.chair ?? false;
  const seating_group = atom.disabledCategories.seating_group ?? false;
  useEffect(() => {
    if (firstRender.current !== props.sensorId) {
      firstRender.current = props.sensorId ?? '';
      return;
    }
    if (props.sensorId) {
      api.setArgusDisabledCategories(props.sensorId, {
        bed,
        chair,
        seating_group,
      });
    }
  }, [props.sensorId, bed, chair, seating_group]);

  if (!atom.showSuggestions) {
    return null;
  }

  const rejectSuggestion = (
    detection: ArgusDetection,
    detectionIndex: number
  ) => {
    if (
      detection.type === 'chair' ||
      detection.type === 'bed' ||
      detection.type === 'seating_group'
    ) {
      setAtom({
        ...atom,
        disabledCategories: {
          ...atom.disabledCategories,
          [detection.type]: true,
        },
      });

      if (detection.uid) {
        setIgnoredDetections([
          ...ignoredDetections,
          {
            timestamp: Date.now(),
            uid: detection.uid,
            sensorCompositeId: props.sensorId,
          },
        ]);
      }
    }

    // send a request to the backend to add the given type
    // to the servers 'disabled' categories
  };

  const acceptSuggestion = (
    detection: ArgusDetection,
    detectionIndex: number
  ) => {
    if (!props.addSuggestedMarkingObject) {
      return;
    }

    const suggestedType: FrontendMarkingObjectType | null =
      detection.type === 'bed'
        ? 'bed'
        : detection.type === 'chair' && detection.points.length < 2
        ? 'chair_circle'
        : detection.type === 'chair'
        ? 'chair_area'
        : null;

    if (!suggestedType) {
      console.error('Cannot accept suggestion of type', detection.type);
      return;
    }

    if (detection.uid) {
      setIgnoredDetections([
        ...ignoredDetections,
        {
          timestamp: Date.now(),
          uid: detection.uid,
          sensorCompositeId: props.sensorId,
        },
      ]);
    }

    props.addSuggestedMarkingObject({
      type: suggestedType,
      points: detection.points,
    });
  };

  /**
   * Only respect ignored detections newer than this threshold
   */
  const timestampThreshold =
    Date.now() - ignoredDetectionsTimestampThreshold * 1000;

  const detections =
    res?.data?.detections
      ?.map((d, index) => ({
        ...d,
        index,
      }))
      .filter((d) => {
        /**
         * Filter away any explicitly ignored suggestions (i.e. rejected or just accepted)
         */
        if (
          ignoredDetections.find(
            (ignoredDetection) =>
              ignoredDetection.sensorCompositeId === props.sensorId &&
              ignoredDetection.uid === d.uid &&
              ignoredDetection.timestamp > timestampThreshold
          )
        ) {
          return false;
        }

        /**
         * Filter away any detections that correspond to one of the existing marking
         * objects, unless the user explicitly wants to see them as well
         */
        if (
          !atom.showMatchedSuggestions &&
          typeof d.item === 'number' &&
          d.item > -1
        ) {
          return false;
        }

        /**
         * Filter away any detections that are of a type that is disabled
         */
        if (
          (atom.disabledCategories.bed && d.type === 'bed') ||
          (atom.disabledCategories.chair && d.type === 'chair') ||
          (atom.disabledCategories.seating_group && d.type === 'seating_group')
        ) {
          return false;
        }

        /**
         * Filter away any detections of an unsupported type, unless the user explicitly wants them
         */
        if (
          !isSupportedMarkingType(d.type) &&
          (!app.hasFeature('show-argus-markings-for-unsupported-types') ||
            !atom.showUnsupportedSuggestions)
        ) {
          return false;
        }

        return true;
      }) ?? [];

  return (
    <svg
      style={{
        position: 'absolute',
        top: 0,
        left: 0,
        width: '100%',
        height: '100%',
        pointerEvents: 'none',
      }}
      viewBox="0 0 160 120"
    >
      {detections.map((detection, detectionIndex) => (
        <ArgusDetectionShape
          key={detectionIndex}
          detectionIndex={detectionIndex}
          detection={detection}
          acceptSuggestion={acceptSuggestion}
          rejectSuggestion={rejectSuggestion}
        />
      ))}
    </svg>
  );
}
