import React, { useState } from 'react';
import moment from 'moment';

import ViewImageModal from '../../components/modals/ViewImageModal';

import { Event, useCareContext } from './lib/CareContext';
import { useApiCall } from '../../lib/useApiCall';
import * as api from '../../serverApi';

import './EventSummary.scss';
import { getLabelForEventType } from '../../lib/getLabelForEventType';
import { EventReceivedBySummary } from './components/EventReceivedBySummary';
import { Icon } from '../../components/Icon';
import { t } from '../../lib/i18n';

interface Props {
  event: Event;
}

interface Base64ImageProps {
  img: string;
  onClick?: (src: string) => void;
}

function Base64Image(props: Base64ImageProps) {
  const handleClick = () => {
    if (props.onClick) {
      props.onClick(`data:image/jpeg;base64, ${props.img}`);
    }
  };

  return (
    <img
      alt=""
      src={`data:image/jpeg;base64, ${props.img}`}
      onClick={() => handleClick()}
    />
  );
}

export const EventSummary = (props: Props) => {
  const care = useCareContext();
  const res = useApiCall(api.careGetEventSummary, [props.event.event_id]);

  const [isOpen, setIsOpen] = useState(false);
  const [activeImg, setActiveImg] = useState<string | undefined>(undefined);

  if (!res.data) {
    return null;
  }

  const event = res.data;

  const handleImageModal = () => setIsOpen(!isOpen);

  const handleImageClick = (src: string) => {
    setActiveImg(src);
    handleImageModal();
  };

  const handleSupervisionClick = () => {
    care.setSidebarMode('sensors');
    care.selectSensor(event.sensor.id);
  };

  return (
    <div className="EventSummary">
      <div className="EventSummary-header">
        <div className="EventSummary-bold">
          {getLabelForEventType(event.event_type, event.sensor.sensor_type)}
        </div>
        <div className="EventSummary-bold">
          {event.sensor.display_area}
          <br />
          {event.sensor.display_name}
        </div>

        <div>
          {moment(event.event_at_sensor_local).format('HH:mm / D. MMMM YYYY')}
        </div>

        {props.event.sensor.sensor_type === 'roommate' &&
        !props.event.is_technical ? (
          <button
            className="EventSummary-supervision-button"
            onClick={handleSupervisionClick}
          >
            <Icon icon="roommate-sensor" />
            {t('event.ReceiptPage.streamSensor')}
          </button>
        ) : null}
      </div>

      <EventReceivedBySummary receivedBySensor={event.received_by_sensor} />

      {event.images.map((eventImage) => (
        <div className="EventSummary-image" key={eventImage.type}>
          <Base64Image
            img={eventImage.data}
            onClick={(src) => handleImageClick(src)}
          />
        </div>
      ))}

      <ViewImageModal
        isOpen={isOpen}
        imgSrc={activeImg}
        handleModal={handleImageModal}
      />
    </div>
  );
};
