import React from 'react';
import {
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
} from '@material-ui/core';
import { TableWrapper } from '../../../../components/TableWrapper';
import { Button } from '../../../../components/Button';
import { formatAlertSettingSchedule } from '../../../../lib/utils/formatAlertSettingSchedule';
import { useZoneContext } from '../../../../lib/ZoneContext';
import { StatusMarker } from '../../../../lib/ui/StatusMarker';
import { t } from '../../../../lib/i18n';
import { AlertSettingForSingleSensorType } from '../../alerts/lib/types';
import formatUserLabel from '../../permissions/lib/formatUserLabel';

interface Props {
  settings?: Array<AlertSettingForSingleSensorType>;
  loading?: boolean;
}
export function SensorAlertSettingsTable(props: Props) {
  const context = useZoneContext();

  if (props.settings === undefined || props.settings.length === 0) {
    return (
      <div
        style={{
          flex: 1,
          textAlign: 'center',
          padding: '20px 0',
          fontSize: '30px',
          color: '#666',
        }}
      >
        {t(
          'manage.sensors.ui.SensorAlertSettingsTable.noWarningRulesForThisSensor'
        )}
        .
      </div>
    );
  }

  return (
    <div className="SensorAlertSettingsTable">
      <TableWrapper>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>{t('common.commonTexts.active')}</TableCell>
              <TableCell>
                {t('manage.sensors.ui.SensorAlertSettingsTable.alertGroupName')}
              </TableCell>
              <TableCell>{t('common.commonTexts.zoneUnit')}</TableCell>
              <TableCell>
                {t(
                  'manage.sensors.ui.SensorAlertSettingsTable.notificationForm'
                )}
              </TableCell>
              <TableCell>{t('common.commonTexts.receivers')}</TableCell>
              <TableCell></TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {props.settings.map((s, i) => (
              <TableRow key={i} hover={true}>
                <TableCell style={{ fontSize: '18px', paddingLeft: '29px' }}>
                  <StatusMarker status={s.enabled ? 'success' : 'danger'} />
                </TableCell>

                <TableCell>{s.name}</TableCell>
                <TableCell>{s.zone.name}</TableCell>
                <TableCell>{formatAlertSettingSchedule(s.schedule)}</TableCell>
                <TableCell>
                  {s.recipients.map(formatUserLabel).join(', ')}
                </TableCell>
                <TableCell>
                  <Button
                    className="mr-2"
                    to={
                      '/manage/' +
                      context.activeZone.id +
                      '/alerts/' +
                      s.alert_setting_id
                    }
                    light={true}
                    small={true}
                    color="primary"
                  >
                    {t('common.commonButtons.show')}
                  </Button>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableWrapper>
    </div>
  );
}
