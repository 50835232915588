import { t } from './i18n';
import { SensorType } from '../serverApi';
import { TranslationKey } from './i18n/i18n';

export function getLabelForEventType(
  eventType: string,
  sensorType: SensorType | string
) {
  return t(`common.commonEvents.${sensorType}.${eventType}` as TranslationKey);
}
