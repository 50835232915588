import MaterialUITooltip, { TooltipProps } from '@material-ui/core/Tooltip';

interface Props extends TooltipProps {}
export const Tooltip = (props: Props) => {
  const { arrow, ...rest } = props;
  return (
    <MaterialUITooltip arrow={arrow ?? true} {...rest}>
      {props.children}
    </MaterialUITooltip>
  );
};
