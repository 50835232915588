import {useState, useEffect} from 'react';
import moment from 'moment';

export function useCurrentTime() {
    const [time, setTime] = useState(moment());

    // Update time every minute
    useEffect(() => {
        const interval = setInterval(() => setTime(moment()), 60 * 1000);
        return () => {
          clearInterval(interval);
        };
    }, []);

    return time;
}