import React from 'react';
import {
  FormGroup,
  InputAdornment,
  MenuItem,
  TextField,
} from '@material-ui/core';
import { Icon } from '../../../../components/Icon';

import * as api from '../../../../serverApi';

interface Props {
  className?: string;
  error?: string;
  disabled?: boolean;
  accountId: number | undefined;
  onSelect: (accountId: number) => void;
}

export const SelectVendor = (props: Props) => {
  const res = api.useSuperVendorGetAccountsNewQuery({ is_vendor: true });
  const vendors = res.data ? res.data.data : null;

  return (
    <FormGroup className={props.className}>
      <TextField
        select={true}
        disabled={res.isLoading || props.disabled}
        name="vendor"
        label="Vendor"
        value={props.accountId || ''}
        onChange={(e) => {
          props.onSelect(parseInt(e.target.value));
        }}
        error={props.error !== undefined}
        helperText={props.error}
        inputProps={vendors ? vendors[0] : undefined}
        InputProps={{
          endAdornment: res.isLoading && (
            <InputAdornment position="end">
              <Icon
                icon="gear"
                spin
                style={{
                  width: 17,
                  marginRight: 20,
                  color: '#9b9b9b',
                }}
              />
            </InputAdornment>
          ),
        }}
      >
        {vendors ? (
          vendors.map((acc) => (
            <MenuItem key={acc.account_id} value={acc.account_id}>
              {acc.name}
            </MenuItem>
          ))
        ) : (
          <MenuItem value="" disabled>
            No available vendors
          </MenuItem>
        )}
      </TextField>
    </FormGroup>
  );
};
