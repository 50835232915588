import HeadingBar from '../../super/shared/HeadingBar';
import { Button, Card } from '@material-ui/core';
import { AccountForm } from './AccountForm';
import { ErrorMessages } from '../../../components/ErrorMessages';
import { useRouter } from '../../../lib/useRouter';
import { useErrorHandler } from '../../../lib/useErrorHandler';
import { useAccountFormik } from './lib/useAccountFormik';
import * as yup from 'yup';
import { t } from '../../../lib/i18n';
import { useSuperVendorCreateAccountMutation } from '../../../serverApi';
import { useQueryClient } from 'react-query';
import setQueryDataForAccount from './lib/setQueryDataForAccount';
import { isValidationError } from '../../../lib/utils/errorUtils';

export function NewAccount() {
  const router = useRouter();
  const errorHandler = useErrorHandler();

  const queryClient = useQueryClient();

  const { mutateAsync } = useSuperVendorCreateAccountMutation({
    onSuccess: (data) => {
      setQueryDataForAccount(queryClient, data.account);
      router.history.push('/super/accounts');
    },
  });

  const formik = useAccountFormik({
    validationSchema: () =>
      yup.object().shape({
        name: yup.string().required(),
        account_code: yup.string().required(),
      }),
    onSubmit: async (values, formikHelpers) => {
      try {
        await mutateAsync({ data: values });
      } catch (e) {
        errorHandler.handleError(e);

        if (isValidationError(e)) {
          formikHelpers.setErrors(e.response?.data.errors);
          if (e.response.data.errors['password.password']) {
            formikHelpers.setFieldError(
              'password',
              e.response.data.errors['password.password'][0]
            );
          }
        }
      }
    },
  });

  return (
    <div className="NewAccount">
      <HeadingBar>
        <h1> {t('supervendor.NewAccount.title')}</h1>
      </HeadingBar>

      <Card>
        <div
          style={{
            padding: 30,
          }}
        >
          <AccountForm form={formik} loading={formik.isSubmitting} />
          <Button
            variant="contained"
            color="primary"
            disabled={formik.isSubmitting}
            onClick={formik.submitForm}
          >
            {t('supervendor.AccountForm.save')}
          </Button>
          <ErrorMessages tag="span" className="m-3" errorData={errorHandler} />
        </div>
      </Card>
    </div>
  );
}
