import React from 'react';
import { IconButton } from '../../../components/IconButton';
import { useRouter } from '../../../lib/useRouter';
import getParentPath from '../../../lib/getParentPath';
import { useApiCall } from '../../../lib/useApiCall';
import { RouteComponentProps } from 'react-router';
import { InlineSpinner } from '../../../components/InlineSpinner';
import { SensorAlertSettingsTable } from './ui/SensorAlertSettingsTable';

import * as api from '../../../serverApi';
import { t } from '../../../lib/i18n';
import { AlertSettingForSingleSensorType } from '../alerts/lib/types';

interface Props extends RouteComponentProps<{ sensorId: string }> {}

export const ListSensorAlertSettings = (props: Props) => {
  const router = useRouter();
  const sensorId = props.match.params.sensorId;
  const alertSettings = useApiCall(api.getAlertsForSensor, [sensorId]);
  const res = useApiCall(api.getSensorDetails, [sensorId]);

  const alertSettingsForSensor:
    | undefined
    | AlertSettingForSingleSensorType[] = alertSettings.data?.alert_settings.map(
    (a) => ({
      ...a,
      event_types: a.event_types
        .filter((b) => b.sensor_type === 'roommate')
        .map((b) => b.event_type),
    })
  );

  return (
    <>
      <h1>
        {t('manage.sensors.ListSensorAlertSettings.notificationSummary')}
        {res.data ? (
          <>
            {' '}
            {t('common.commonTexts.for')}{' '}
            {res.data.sensor.name ||
              `${t('common.commonTexts.sensor')} ${res.data.sensor.id}`}
          </>
        ) : null}
      </h1>

      <div className="my-4">
        <IconButton
          icon="arrow-left"
          to={getParentPath(getParentPath(router.location.pathname))}
        >
          {t('common.commonButtons.back')}
        </IconButton>
      </div>

      {alertSettings.loading ? (
        <InlineSpinner text={t('common.commonTexts.loading')} />
      ) : (
        <SensorAlertSettingsTable settings={alertSettingsForSensor} />
      )}
    </>
  );
};
