import React, { useState } from 'react';
import {
  TextField,
  FormControl,
  FormLabel,
  RadioGroup,
  Radio,
  FormControlLabel,
  FormGroup,
  Checkbox,
} from '@material-ui/core';

import { BorderWrapper } from '../../../../components/BorderWrapper';
import { PermissionsFormik } from '../lib/usePermissionsFormik';

import { RouteLeavingGuard } from '../../../../components/RouteLeavingGuard';

import { t } from '../../../../lib/i18n';
import { generatePassword } from '../../../../lib/utils/generatePassword';

import { LanguageSelector } from '../../../../components/LanguageSelector';
import { useAccount } from '../../../../lib/useAccount';
import { generateAutoCompleteValue } from '../../../../lib/utils/generateAutoCompleteValue';
import { getTranslatedError } from '../../../../lib/getTranslatedError';

type PasswordType = 'manual' | 'auto' | undefined;

interface Props {
  title?: string;
  mode?: 'create' | 'edit';
  form: PermissionsFormik;
  disableNationalId?: boolean;
}

export function PersonaliaForm(props: Props) {
  const account = useAccount();
  const [passwordType, setPasswordType] = useState<PasswordType>(
    props.form.values.password ? 'auto' : undefined
  );

  const handleUserTypeChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.value === 'external_idp') {
      return props.form.setFieldValue('external_idp', true);
    }
    props.form.setFieldValue('external_idp', false);
  };

  const onPasswordTypeChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const val = e.target.value;
    props.form.setFieldTouched('password');
    props.form.setFieldValue('password', {
      notify: props.form.values.password?.notify || 'none',
      password: val === 'auto' ? generatePassword() : '',
    });

    setPasswordType(val as PasswordType);
  };

  const onNotifyChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const val = e.target.value;
    if (['email', 'sms', 'none'].includes(val)) {
      props.form.setFieldTouched('password');
      props.form.setFieldValue('password', {
        ...props.form.values.password,
        notify: val,
      });
    }
  };

  const { errors, touched } = props.form;

  return (
    <div className="Personalia">
      <RouteLeavingGuard when={props.form.dirty} />
      <BorderWrapper>
        {props.title && <h3>{props.title}</h3>}
        <FormGroup className="mt-3">
          <TextField
            autoFocus={props?.mode === 'create'}
            className="w-100"
            label={t('manage.permissions.forms.PersonaliaForm.username')}
            name="username"
            value={props.form.values.username}
            onChange={props.form.handleChange}
            onBlur={props.form.handleBlur}
            error={touched.username && errors.username !== undefined}
            helperText={
              touched.username && getTranslatedError(errors.username || '')
            }
            autoComplete={generateAutoCompleteValue()}
          />
        </FormGroup>
        <FormGroup className="mt-3">
          {!account.disable_national_id_numbers && (
            <TextField
              type="text"
              className="w-100"
              name="national_id_number"
              disabled={props.disableNationalId}
              label={t(
                'manage.permissions.forms.PersonaliaForm.birthNumberorDnumber'
              )}
              value={props.form.values.national_id_number}
              onChange={props.form.handleChange}
              onBlur={(e) => {
                props.form.validateForm(props.form.values);
                props.form.handleBlur(e);
              }}
              error={
                touched.national_id_number &&
                errors.national_id_number !== undefined
              }
              helperText={
                touched.national_id_number && errors.national_id_number
              }
              autoComplete={generateAutoCompleteValue()}
            />
          )}
        </FormGroup>
        <FormGroup className="mt-3">
          <TextField
            label={t('common.commonTexts.firstNameAndMiddleName')}
            name="first_name"
            value={props.form.values.first_name}
            onChange={props.form.handleChange}
            onBlur={props.form.handleBlur}
            error={touched.first_name && errors.first_name !== undefined}
            helperText={touched.first_name && errors.first_name}
            autoComplete={generateAutoCompleteValue()}
          />
        </FormGroup>
        <FormGroup className="mt-3">
          <TextField
            label={t('common.commonTexts.surname')}
            className="w-100"
            name="last_name"
            value={props.form.values.last_name}
            onChange={props.form.handleChange}
            onBlur={props.form.handleBlur}
            autoComplete={generateAutoCompleteValue()}
          />
        </FormGroup>
        <FormGroup className="mt-3">
          <TextField
            label={t('manage.permissions.forms.PersonaliaForm.telephone')}
            className="w-100"
            type="phone"
            name="phone"
            value={props.form.values.phone}
            onChange={props.form.handleChange}
            onBlur={props.form.handleBlur}
            error={touched.phone && errors.phone !== undefined}
            helperText={touched.phone && errors.phone}
            autoComplete={generateAutoCompleteValue()}
          />
        </FormGroup>
        <FormGroup className="mt-3">
          <TextField
            label={t('common.commonInputs.email')}
            className="w-100"
            type="email"
            name="email"
            value={props.form.values.email}
            onChange={props.form.handleChange}
            onBlur={props.form.handleBlur}
            error={touched.email && errors.email !== undefined}
            helperText={touched.email && errors.email}
            autoComplete={generateAutoCompleteValue()}
          />
        </FormGroup>

        <LanguageSelector
          name="preferred_locale"
          value={props.form.values.preferred_locale}
          onChange={props.form.handleChange}
        />

        {account.enable_sso && (
          <FormGroup className="mt-3">
            <RadioGroup
              name="user_type"
              value={props.form.values.external_idp ? 'external_idp' : 'normal'}
              onChange={handleUserTypeChange}
            >
              <FormControlLabel
                value="external_idp"
                control={<Radio color="primary" />}
                label={t('manage.permissions.forms.PersonaliaForm.ssoUser')}
              />
              <FormControlLabel
                value="normal"
                control={<Radio color="primary" />}
                label={t(
                  'manage.permissions.forms.PersonaliaForm.withUsernameAndPassword'
                )}
              />
            </RadioGroup>
          </FormGroup>
        )}

        {(!props.form.values.external_idp || !account.enable_sso) && (
          <>
            <FormGroup className="mt-3">
              <FormControl component="fieldset" className="w-100">
                <RadioGroup
                  aria-label="user"
                  name="password_type"
                  value={passwordType}
                  onChange={onPasswordTypeChange}
                >
                  <FormControlLabel
                    value="auto"
                    control={<Radio color="primary" />}
                    label={t(
                      'manage.permissions.forms.PersonaliaForm.createANewPassword'
                    )}
                  />

                  {passwordType === 'auto' && props.form.values.password && (
                    <div>
                      {t('manage.permissions.forms.PersonaliaForm.newPassword')}
                      : <b>{props.form.values.password.password}</b>
                    </div>
                  )}

                  <FormControlLabel
                    value="manual"
                    control={<Radio color="primary" />}
                    label={t(
                      'manage.permissions.forms.PersonaliaForm.chooseANewPassword'
                    )}
                  />

                  {passwordType === 'manual' && (
                    <TextField
                      type="password"
                      label={t('common.commonInputs.password')}
                      name="password"
                      error={touched.password && errors.password !== undefined}
                      helperText={touched.password && errors.password}
                      onChange={(e) => {
                        props.form.setFieldTouched('password');
                        props.form.setFieldValue('password', {
                          ...props.form.values.password,
                          password: e.target.value,
                        });
                      }}
                      autoComplete={generateAutoCompleteValue()}
                    />
                  )}
                </RadioGroup>
              </FormControl>
            </FormGroup>
            {props.form.values.password && (
              <FormGroup className="mt-3">
                <FormControl component="fieldset" className="w-100">
                  <FormLabel component="legend">
                    {t(
                      'manage.permissions.forms.PersonaliaForm.sendMessageToServiceProvider'
                    )}
                    :
                  </FormLabel>
                  <RadioGroup
                    aria-label="user"
                    name="user"
                    value={props.form.values.password?.notify}
                    onChange={onNotifyChange}
                  >
                    <FormControlLabel
                      value="none"
                      control={<Radio color="primary" />}
                      label={t(
                        'manage.permissions.forms.PersonaliaForm.noMessage'
                      )}
                    />

                    <FormControlLabel
                      value="email"
                      control={<Radio color="primary" />}
                      label={t(
                        'manage.permissions.forms.PersonaliaForm.sendNewPasswordByEmail'
                      )}
                    />
                    <FormControlLabel
                      value="sms"
                      control={<Radio color="primary" />}
                      label={t(
                        'manage.permissions.forms.PersonaliaForm.sendNewPasswordBySms'
                      )}
                    />
                  </RadioGroup>
                </FormControl>
              </FormGroup>
            )}

            <FormGroup className="mt-3">
              <FormControlLabel
                control={
                  <Checkbox
                    checked={
                      props.form.values.only_personal_devices_for_otp ?? false
                    }
                    value={props.form.values.only_personal_devices_for_otp}
                    onChange={(e) => {
                      props.form.setFieldValue(
                        'only_personal_devices_for_otp',
                        e.currentTarget.checked
                      );
                      props.form.setFieldTouched(
                        'only_personal_devices_for_otp'
                      );
                    }}
                    color="primary"
                  />
                }
                label={t(
                  'manage.permissions.forms.PersonaliaForm.onlyPersonal'
                )}
              />
            </FormGroup>
          </>
        )}
      </BorderWrapper>
    </div>
  );
}
