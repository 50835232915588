import { scaleUtc } from 'd3';
import { useMemo, useRef } from 'react';

import { generatePathForBooleanSeries } from './helpers/generatePathForBooleanSeries';

const BAR_HEIGHT = 24;
interface Props {
  values: { startDate: Date; streak: number }[];
  domain: [Date, Date] | [undefined, undefined];
  samplePeriodS: number;
  width: number;
}

function BooleanSeriesItem({
  values = [],
  domain,
  samplePeriodS,
  width,
}: Props) {
  const target = useRef(null);

  const xScale = useMemo(() => {
    return scaleUtc()
      .domain(domain as any)
      .range([0, width]);
  }, [domain, width]);

  const d = generatePathForBooleanSeries({
    streaks: values,
    scaleFunction: xScale,
    samplePeriodS,
  });

  return (
    <div className="BehavioralDataChart__boolean-series-item" ref={target}>
      {width > 1 && (
        <svg width={width} height={BAR_HEIGHT}>
          <g transform={`translate(0,${BAR_HEIGHT / 2})`}>
            <line x2={width}></line>
            <path d={d} strokeWidth={BAR_HEIGHT}></path>
          </g>
        </svg>
      )}
    </div>
  );
}

export default BooleanSeriesItem;
