import React, { useState } from 'react';
import { Button } from '../../../../components/Button';
import { useConfirmation } from '../../../../lib/confirmation/ConfirmationContext';
import { ErrorMessages } from '../../../../components/ErrorMessages';
import { useErrorHandler } from '../../../../lib/useErrorHandler';
import { useCareReceiverContext } from '../lib/CareReceiverContext';
import * as api from '../../../../serverApi';
import { t } from '../../../../lib/i18n';

interface Props {
  isSingleAssociation?: boolean;
  onRemove?: () => void;
}

export const RemoveCareReceiverButton = (props: Props) => {
  const cr = useCareReceiverContext();
  const confirmation = useConfirmation();
  const errorHandler = useErrorHandler();

  const [deleting, setDeleting] = useState(false);

  const handleClick = () => {
    if (!cr.id) {
      return;
    }
    confirmation
      .confirm(t('common.commonTexts.toConfirmDeletionPressOk'))
      .then(async () => {
        setDeleting(true);
        try {
          await api.deleteCareReceiver(cr.id);
          setDeleting(false);
          if (props.onRemove) {
            props.onRemove();
          }
        } catch (error) {
          setDeleting(false);
          errorHandler.handleError(error);
        }
      })
      .catch(() => {});
  };

  if (!cr) {
    return null;
  }

  return (
    <>
      <Button
        color="secondary"
        disabled={!props.isSingleAssociation || deleting}
        onClick={handleClick}
        icon={deleting ? 'gear' : undefined}
        spinIcon={deleting}
        data-cy="manage-care-receiver-remove"
      >
        {t('common.commonButtons.delete')}
      </Button>
      <ErrorMessages className="my-2" errorData={errorHandler} />
    </>
  );
};
