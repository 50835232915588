import React, { useRef, useState, useCallback, useEffect } from 'react';
import { debounce } from 'lodash';
import cs from 'classnames';

import {
  MarkingObject,
  sensorImageResolution,
  Point,
  FrontendMarkingObjectType,
} from '../types';
import * as draw from '../lib/drawingUtils';
import { toast } from 'react-toastify';
import { useSensorImageLoader } from '../lib/useSensorImageLoader';
import { t } from '../../../../../lib/i18n';
import { ArgusSuggestions } from '../argus/ArgusSuggestions';

import './MarkingCanvas.scss';
import { Feature } from '../../../../../components/Feature';
import { useHoverPoint } from '../atoms/useHoverPoint';
import { useRateLimitedHoverPosition } from '../lib/useRateLimitedHoverPosition';

interface Props {
  // The URL of the background sensor image.
  sensorImageSrc?: string;

  /**
   * An array of the marking objects to be drawn on the canvas.
   */
  markingObjects?: MarkingObject[];

  removeLastPoint: () => void;

  addPoint: (p: { h: number; v: number }) => void;

  selectedMarkingObjectItemNum?: number;

  className?: string;

  refreshImage?: () => void;

  sensorId?: string;

  addSuggestedMarkingObject?: (obj: {
    type: FrontendMarkingObjectType;
    points: Point[];
  }) => void;
}

function MarkingCanvas(props: Props) {
  const markingRef = useRef<HTMLCanvasElement>(null);

  const [dim, setDim] = useState({
    width: 160,
    height: 120,
    scale: 1.0,
  });

  const imgLoader = useSensorImageLoader({
    refreshImage: props.refreshImage,
    sensorImageSrc: props.sensorImageSrc,
  });

  const img = imgLoader.img;

  const selectMarkingObject = props.markingObjects?.find(
    (mo) => mo.item === props.selectedMarkingObjectItemNum
  );

  const hoverPoint = useHoverPoint({
    sensorCompositeId: props.sensorId,
    skip: selectMarkingObject === undefined,
  });

  /**
   * Get the mouse position within the image coordinate system (160x120)
   */
  const getRelativeMousePosition = useCallback(
    (e: React.MouseEvent<HTMLCanvasElement>) => {
      if (!markingRef.current) {
        return undefined;
      }
      const rect = markingRef.current.getBoundingClientRect();
      return {
        h: Math.floor((e.clientX - rect.left) / dim.scale),
        v: Math.floor((e.clientY - rect.top) / dim.scale),
      };
    },
    [markingRef, dim]
  );

  const hoverPointCallback = useCallback(
    (p: { x: number; y: number } | null) => {
      if (!p) {
        hoverPoint.clear();
      } else {
        const h = Math.floor(p.x / dim.scale);
        const v = Math.floor(p.y / dim.scale);
        hoverPoint.set({ h, v });
      }
    },
    [hoverPoint, dim.scale]
  );

  useRateLimitedHoverPosition(markingRef, 250, 100, hoverPointCallback);

  /**
   * Add a new point to the current marking object when the mouse is clicked.
   */
  function mouseClick(e: React.MouseEvent<HTMLCanvasElement>) {
    if (props.selectedMarkingObjectItemNum === undefined) {
      toast.success(
        t(
          'manage.sensors.marking.components.MarkingCanvas.youMustSelectASelectionFirst'
        )
      );
      return;
    }
    if (props.addPoint) {
      const p = getRelativeMousePosition(e);
      if (p) {
        props.addPoint(p);
      }
    }
  }

  /**
   * Remove the last point of the current marking object when the right mouse button is clicked.
   */
  function mouseRightClick(e: React.MouseEvent<HTMLCanvasElement>) {
    if (props.selectedMarkingObjectItemNum === undefined) {
      toast.success(
        t(
          'manage.sensors.marking.components.MarkingCanvas.youMustSelectASelectionFirst'
        )
      );
      return;
    }
    if (props.removeLastPoint) {
      e.preventDefault();
      props.removeLastPoint();
    }
  }

  /**
   * Set a resize handler to make sure the scale coefficient is always correct.
   */
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const handleResize = useCallback(
    debounce(() => {
      // In order to resize, we need to access the parents parent to get its size; this
      // will be our reference point, and the canvas must fit within those constraints.
      if (!markingRef.current?.parentElement?.parentElement) {
        return;
      }
      const constraints = {
        width: Math.max(
          markingRef.current.parentElement.parentElement.clientWidth,
          400
        ),
        height: Math.max(
          markingRef.current.parentElement.parentElement.clientHeight,
          400 * sensorImageResolution.ratio
        ),
      };

      if (
        constraints.width * sensorImageResolution.ratio >
        constraints.height
      ) {
        // Constrained by height
        setDim({
          width: constraints.height / sensorImageResolution.ratio,
          height: constraints.height,
          scale: constraints.height / sensorImageResolution.h,
        });
      } else {
        // Constrained by width
        setDim({
          width: constraints.width,
          height: constraints.width * sensorImageResolution.ratio,
          scale: constraints.width / sensorImageResolution.w,
        });
      }
    }, 100),
    []
  );

  /**
   * Attach the resize event handler
   */
  useEffect(() => {
    handleResize();
    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, [handleResize]);

  const redraw = useCallback(() => {
    if (markingRef.current && props.markingObjects) {
      const ctx = markingRef.current.getContext('2d');
      if (ctx) {
        const imgHeight =
          markingRef.current.width / (img.current.width / img.current.height);
        markingRef.current.height = imgHeight;
        ctx.drawImage(img.current, 0, 0, markingRef.current.width, imgHeight);
        draw.drawObjs(
          ctx,
          props.markingObjects,
          dim.scale,
          props.selectedMarkingObjectItemNum
        );
      }
    }
  }, [dim, props.selectedMarkingObjectItemNum, props.markingObjects]); // eslint-disable-line react-hooks/exhaustive-deps

  /**
   * Redraw the canvas whenever something changes!
   */
  useEffect(() => {
    redraw();
  }, [
    imgLoader.loading,
    props.markingObjects,
    dim,
    props.selectedMarkingObjectItemNum,
    redraw,
  ]);

  return (
    <div
      className={cs('MarkingCanvas', props.className)}
      style={{
        width: `${dim.width}px`,
        height: `${dim.height}px`,
        position: 'relative',
      }}
    >
      <canvas
        ref={markingRef}
        onClick={mouseClick}
        onContextMenu={mouseRightClick}
        className="MarkingCanvas-marking"
        height={dim.height}
        width={dim.width}
      />

      <Feature feature="show-argus-markings">
        <ArgusSuggestions
          sensorId={props.sensorId}
          width={dim.width}
          height={dim.height}
          addSuggestedMarkingObject={props.addSuggestedMarkingObject}
          markingObjects={props.markingObjects}
        />
      </Feature>

      {imgLoader.loading ? (
        <div className="MarkingCanvas-loading">{imgLoader.loadingMessage}</div>
      ) : null}
    </div>
  );
}

export default MarkingCanvas;
