import React from 'react';
import cs from 'classnames';

import { Icon } from '../../components/Icon';
import { DashboardZoneSummary } from './lib/evaluateDashboardOverviewZone';

import './DashboardHousingUnit.scss';

interface Props {
  zone?: DashboardZoneSummary;
  className?: string;
}

export function DashboardHousingUnit(props: Props) {
  if (!props.zone) {
    return null;
  }

  return (
    <div
      className={cs(
        'DashboardHousingUnit',
        `DashboardHousingUnit--${props.zone.modifier}`,
        props.className
      )}
    >
      <span>{props.zone.label}</span>

      {props.zone.icon ? (
        <Icon
          className="DashboardHousingUnit-icon"
          icon={props.zone.icon}
          alt=""
        />
      ) : null}
    </div>
  );
}
