import React from 'react';
import { TextField, FormGroup } from '@material-ui/core';
import ModalWrapper from '../ModalWrapper';
import * as api from '../../../serverApi';
import { RouteLeavingGuard } from '../../RouteLeavingGuard';
import {
  errorIsObjectError,
  useErrorHandler,
} from '../../../lib/useErrorHandler';
import { ErrorMessages } from '../../ErrorMessages';
import { useFormik } from 'formik';
import { useConfirmation } from '../../../lib/confirmation/ConfirmationContext';
import { t } from '../../../lib/i18n';
import { generateAutoCompleteValue } from '../../../lib/utils/generateAutoCompleteValue';

export interface UpdateSensorProps {
  isOpen: boolean;
  sensorId: string;
  currentSensorName: string | null;
  handleModal: () => void;
  onUpdate?: () => void;
}

export interface UpdateSensorFields {
  name: string | null;
}

export function UpdateSensorModal(props: UpdateSensorProps) {
  const errorHandler = useErrorHandler();
  const confirmation = useConfirmation();

  const formik = useFormik<UpdateSensorFields>({
    initialValues: {
      name: props.currentSensorName,
    },
    onSubmit: async (values, helpers) => {
      try {
        errorHandler.error && errorHandler.reset();
        await api.updateSensors(props.sensorId, {
          name: values.name || '',
        });

        helpers.resetForm({ values: { name: values.name } });
        props.handleModal();
        props.onUpdate && props.onUpdate();
      } catch (error) {
        errorHandler.handleError(error);
      }
    },
  });

  const handleOnReset = () => {
    errorHandler.reset();
    formik.resetForm({ values: { name: props.currentSensorName } });
  };

  const handleOnHide = () => {
    if (!formik.dirty) {
      props.handleModal();
    } else {
      confirmation
        .confirm(
          t('common.commonTexts.unSavedChangesHaveBeenMadeDoYouWantToContinue')
        )
        .then(() => {
          props.handleModal();
        })
        .catch(() => {});
    }
  };

  const getNameError = () => {
    const errors = formik.errors;
    const touched = formik.touched;

    if (!errorHandler.error && !errors) {
      return undefined;
    }

    if (touched['name'] && errors['name']) {
      return errors['name'];
    }

    return (
      (errorIsObjectError(errorHandler.error) && errorHandler.error.name) ||
      undefined
    );
  };

  const error = getNameError();

  return (
    <ModalWrapper
      title={t('components.UpdateSensorModal.renameSensor')}
      okButtonText={t('common.commonButtons.save')}
      cancelButtonText={t('common.commonButtons.cancel')}
      isOpen={props.isOpen}
      loading={formik.isSubmitting}
      onHide={handleOnHide}
      onResetModal={handleOnReset}
      onSubmit={formik.submitForm}
    >
      <div className="name-wrapper">
        <RouteLeavingGuard when={formik.dirty} />
        <FormGroup>
          <TextField
            type="text"
            name="name"
            label={t('common.commonInputs.name')}
            value={formik.values.name || ''}
            disabled={formik.isSubmitting}
            error={!!error}
            helperText={error}
            onKeyPress={(e) => {
              if (e.key === 'Enter') {
                e.preventDefault();
                e.stopPropagation();
                formik.submitForm();
              }
            }}
            onBlur={formik.handleBlur}
            onChange={formik.handleChange}
            autoComplete={generateAutoCompleteValue()}
          />
        </FormGroup>
        <ErrorMessages className="my-2" errorData={errorHandler} />
      </div>
    </ModalWrapper>
  );
}
