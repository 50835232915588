import React from 'react';
import { useZoneContext } from '../../../../lib/ZoneContext';
import { useRouter } from '../../../../lib/useRouter';
import { IconButton } from '../../../../components/IconButton';
import getParentPath from '../../../../lib/getParentPath';
import { CreateCareReceiver } from './CreateCareReceiver';
import { t } from '../../../../lib/i18n';

export function AddCareReceiver() {
  const router = useRouter();
  const ctx = useZoneContext();

  // If the current zone is a housing unit _without_ a care receiver, then
  // set the default zone for the form to the current zone. Otherwise,
  // the zone needs to be selected.
  const defaultZoneId =
    ctx.activeZone.is_housing_unit && ctx.activeZone.care_receiver === null
      ? ctx.activeZone.id
      : undefined;

  return (
    <div className="AddCareReceiver">
      <h1>{t('manage.careReceivers.common.addServiceRecipient')}</h1>

      <div className="d-flex align-items-start justify-content-between my-4">
        <div>
          <IconButton icon="arrow-left" to={getParentPath(router.match.path)}>
            {t('common.commonButtons.back')}
          </IconButton>
        </div>
      </div>

      <CreateCareReceiver onCreate={ctx.reload} initialZoneId={defaultZoneId} />
    </div>
  );
}
