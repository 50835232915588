import React from 'react';
import moment, { Moment } from 'moment';
import { FormGroup } from '@material-ui/core';
import {
  KeyboardTimePicker,
  MuiPickersUtilsProvider,
} from '@material-ui/pickers';
import MomentUtils from '@date-io/moment';
import { TimeIntervalPickerValueType } from './types';
import { t } from '../lib/i18n';

import './TimeIntervalPicker.scss';
import { getGlobalLocale } from '../lib/i18n/i18n';

export interface Props {
  disabled?: boolean;
  disableExtraDayInfo?: boolean;
  extraDayInfoText?: string;
  fromValue?: string | null;
  toValue?: string | null;
  onChange: (time: TimeIntervalPickerValueType) => void;
}

interface State {
  time_to: Moment | null;
  time_from: Moment | null;
  startDate: string;
  onChange: (time: TimeIntervalPickerValueType) => void;
  showNextDayInfo: boolean;
}

const _getInitialState = (props: Props): State => {
  return {
    time_to: props.toValue ? moment(props.toValue, 'HH:mm') : null,
    time_from: props.fromValue ? moment(props.fromValue, 'HH:mm') : null,
    startDate: '',
    onChange: (time: TimeIntervalPickerValueType) => props.onChange(time),
    showNextDayInfo: false,
  };
};

class TimeIntervalPicker extends React.Component<Props, State> {
  state = _getInitialState(this.props);

  _handleFromChange = (date: Moment | null) => {
    this.setState(
      (prevState) => ({
        ...prevState,
        time_from: date,
      }),
      () => {
        this._response();
      }
    );
  };

  componentDidUpdate(prevProps: Props, prevState: State) {
    if (prevProps.fromValue !== this.props.fromValue) {
      this.setState({
        time_from: this.props.fromValue
          ? moment(this.props.fromValue, 'HH:mm')
          : null,
      });
    }
    if (prevProps.toValue !== this.props.toValue) {
      this.setState({
        time_to: this.props.toValue
          ? moment(this.props.toValue, 'HH:mm')
          : null,
      });
    }
  }

  _handleToChange = (date: Moment | null) => {
    this.setState(
      (prevState) => ({
        ...prevState,
        time_to: date,
      }),
      () => {
        this._response();
      }
    );
  };

  _response = () => {
    const { time_from, time_to } = this.state;
    // Only sending valid changes
    if (time_from !== null && time_to !== null) {
      this.props.onChange({
        time_from: time_from.format('HH:mm:ss'),
        time_to: time_to.format('HH:mm:ss'),
      });
    }
  };

  _checkTimeForInfo = () => {
    const { time_from, time_to } = this.state;
    if (!time_from || !time_to) {
      return false;
    }

    return time_to?.isBefore(time_from, 'minutes');
  };

  /**
   * NOTE: Comoponent is wrapped in MuiPickersUtilsProvider,
   * could be moved to root file for better performance.
   */

  render() {
    const { disabled, disableExtraDayInfo, extraDayInfoText } = this.props;

    const showInfo = this._checkTimeForInfo();

    return (
      <div className="TimeIntervalPicker">
        <div className="TimeIntervalPicker-inputs">
          <MuiPickersUtilsProvider
            locale={getGlobalLocale()}
            utils={MomentUtils}
          >
            <FormGroup>
              <KeyboardTimePicker
                disabled={disabled}
                label={t('common.commonInputs.from')}
                clearable
                ampm={false}
                minutesStep={5}
                value={this.state.time_from}
                onChange={this._handleFromChange}
                format={'HH:mm'}
                invalidDateMessage={t(
                  'components.TimeIntervalPicker.incorrectTimeFormat'
                )}
                KeyboardButtonProps={{
                  style: {
                    display: 'none',
                  },
                }}
                style={{ maxWidth: '100px' }}
              />
            </FormGroup>
            <FormGroup>
              <KeyboardTimePicker
                disabled={disabled}
                label={t('common.commonInputs.to')}
                clearable
                ampm={false}
                minutesStep={5}
                value={this.state.time_to}
                onChange={this._handleToChange}
                format={'HH:mm'}
                invalidDateMessage={t(
                  'components.TimeIntervalPicker.incorrectTimeFormat'
                )}
                KeyboardButtonProps={{
                  style: {
                    display: 'none',
                  },
                }}
                style={{ maxWidth: '100px' }}
              />
            </FormGroup>
          </MuiPickersUtilsProvider>
        </div>
        {disableExtraDayInfo ? null : showInfo ? (
          <div className="TimeIntervalPicker-info">
            <i>
              {extraDayInfoText ||
                t('components.TimeIntervalPicker.plusOneDay')}
            </i>
          </div>
        ) : null}
      </div>
    );
  }
}

export default TimeIntervalPicker;
