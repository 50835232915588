import { useMemo } from 'react';
import { RouteComponentProps } from 'react-router';
import * as api from '../../../serverApi';
import { useApiCall } from '../../../lib/useApiCall';
import { SensorContextProvider } from '../../../lib/SensorContext';

import LoadingPlaceholder from '../../../lib/ui/LoadingPlaceholder';

import { MarkingApp } from './marking/MarkingApp';

import { t } from '../../../lib/i18n';

interface Props extends RouteComponentProps<{ sensorId: string }> {}

export default function Marking(props: Props) {
  const sensorId = props.match.params.sensorId;
  const res = useApiCall(api.getSensorDetails, [sensorId]);

  const value = useMemo(() => {
    if (!res.data) {
      return null;
    }

    return {
      id: res?.data.sensor.id,
      name: res?.data.sensor.name,
      sensor_type: res?.data.sensor.sensor_type,
      zone: res?.data.sensor.zone,
      careReceiver: res?.data.sensor.care_receiver,
      eventTypes: res?.data.sensor.event_types,
      reload: res.reload,
    };
  }, [res]);

  if (res.loading) {
    return <LoadingPlaceholder heading={t('common.commonTexts.loading')} />;
  }

  if (!value) {
    return null;
  }

  return (
    <SensorContextProvider value={value}>
      <MarkingApp sensorId={value.id} pathname={props.location.pathname} />
    </SensorContextProvider>
  );
}
