import React, { ChangeEvent } from 'react';
import {
  Checkbox,
  FormControlLabel,
  FormGroup,
  Radio,
  RadioGroup,
  TextField,
} from '@material-ui/core';
import { CreateUserFields, UserFormik } from '../lib/useUsersFormik';
import { generateAutoCompleteValue } from '../../../../lib/utils/generateAutoCompleteValue';
import { SelectAccount } from '../../../supervendor/sensors/bulk-assign-account/SelectAccount';

interface Props {
  formik: UserFormik;
  mode?: 'create' | 'edit';
  readOnly?: boolean;
}

export const UserFormDetails = (props: Props) => {
  const { formik } = props;
  const { touched, errors } = formik;

  const setFormikFieldValue = async (
    name: keyof CreateUserFields,
    value: string | boolean
  ) => {
    await formik.setFieldTouched(name);
    await formik.setFieldValue(name, value);
  };

  const handleImpersonalInputChange = async (
    e: ChangeEvent<HTMLInputElement>
  ) => {
    const value = e.target.value === 'true';
    await setFormikFieldValue('impersonal_user', value);

    if (value) {
      await setFormikFieldValue('username', '');
      await setFormikFieldValue('first_name', '');
      await setFormikFieldValue('last_name', '');
      return;
    }

    await setFormikFieldValue('username', formik.initialValues.username || '');
    await setFormikFieldValue(
      'first_name',
      formik.initialValues.first_name || ''
    );
    await setFormikFieldValue(
      'last_name',
      formik.initialValues.last_name || ''
    );
  };

  return (
    <div className="UserDetailsForm pr-4">
      <FormGroup>
        <TextField
          className="w-100"
          label="Brukernavn"
          name="username"
          disabled={formik.values.impersonal_user}
          value={formik.values.username ?? ''}
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          error={touched.username && errors.username !== undefined}
          helperText={touched.username && errors.username}
          autoComplete={generateAutoCompleteValue()}
          inputProps={{ readOnly: props.readOnly }}
        />
      </FormGroup>

      <FormGroup className="mt-3">
        <TextField
          label="Fornavn og mellomnavn:"
          name="first_name"
          disabled={formik.values.impersonal_user}
          value={formik.values.first_name ?? ''}
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          autoComplete="off"
          inputProps={{ readOnly: props.readOnly }}
        />
      </FormGroup>

      <FormGroup className="mt-3">
        <TextField
          label="Etternavn"
          className="w-100"
          name="last_name"
          disabled={formik.values.impersonal_user}
          value={formik.values.last_name ?? ''}
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          autoComplete={generateAutoCompleteValue()}
          inputProps={{ readOnly: props.readOnly }}
        />
      </FormGroup>

      <FormGroup className="mt-3">
        <TextField
          className="w-100"
          label="Display name"
          name="display_name"
          disabled={!formik.values.impersonal_user}
          value={formik.values.display_name ?? ''}
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          autoComplete={generateAutoCompleteValue()}
          inputProps={{ readOnly: props.readOnly }}
        />
      </FormGroup>

      <FormGroup className="mt-3">
        <SelectAccount
          error={
            touched.account_id && errors.account_id
              ? errors.account_id
              : undefined
          }
          accountId={formik.values.account_id ?? undefined}
          accountTypes="all"
          onSelect={(accountId) => {
            formik.setFieldTouched('account_id');
            formik.setFieldValue('account_id', accountId);
          }}
          disabled={props.readOnly}
        />
      </FormGroup>

      <FormGroup className="mt-3">
        <TextField
          label="E-post"
          className="w-100"
          type="email"
          name="email"
          value={formik.values.email ?? ''}
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          error={touched.email && errors.email !== undefined}
          helperText={touched.email && errors.email}
          autoComplete={generateAutoCompleteValue()}
          inputProps={{ readOnly: props.readOnly }}
        />
      </FormGroup>

      <FormGroup className="mt-3">
        <TextField
          label="Telefon"
          className="w-100"
          type="text"
          name="phone"
          value={formik.values.phone ?? ''}
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          error={touched.phone && errors.phone !== undefined}
          helperText={touched.phone && errors.phone}
          autoComplete={generateAutoCompleteValue()}
          inputProps={{ readOnly: props.readOnly }}
        />
      </FormGroup>

      {props.mode === 'create' && (
        <FormControlLabel
          className="mt-3"
          label="Send generated password by email"
          control={
            <Checkbox
              color="primary"
              name="reset"
              checked={formik.values.send_generated_password_by_email}
              onChange={(e) => {
                formik.setFieldValue(
                  'send_generated_password_by_email',
                  e.target.checked
                );
                formik.setFieldTouched('send_generated_password_by_email');
              }}
            />
          }
        />
      )}

      <RadioGroup
        className="mt-3"
        name="impersonal_user"
        value={String(formik.values.impersonal_user.toString() === 'true')}
        onChange={(e) => handleImpersonalInputChange(e)}
        onBlur={formik.handleBlur}
      >
        <FormControlLabel
          value="true"
          control={<Radio color="primary" />}
          label="Impersonal user"
          disabled={props.readOnly}
        />

        <FormControlLabel
          value="false"
          control={<Radio color="primary" />}
          label="Personal user"
          disabled={props.readOnly}
        />
      </RadioGroup>
    </div>
  );
};
