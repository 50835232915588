import React, { useState } from 'react';
import cs from 'classnames';

import { List, ListItem, ListItemText, ListItemIcon } from '@material-ui/core';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faSitemap,
  faUsers,
  faListUl,
  faStreetView,
  faBuilding,
  faUser,
  faSearch,
  faUsersCog,
} from '@fortawesome/pro-solid-svg-icons';

import Collapse from '@material-ui/core/Collapse';

import ExpandLess from '@material-ui/icons/ExpandLess';
import ExpandMore from '@material-ui/icons/ExpandMore';
import { useRouter } from '../../../lib/useRouter';
import { AppContext, useAppContext } from '../../../lib/global';

import './SuperVendorNavigation.scss';

const areas: Array<
  {
    icon: JSX.Element;
    label: string;
    style?: React.CSSProperties;
    condition?: (ctx: AppContext) => boolean;
  } & (
    | {
        path: string;
        exact?: boolean;
        navigateAsLink?: boolean;
      }
    | {
        children?: Array<{
          icon: JSX.Element;
          label: string;
          path: string;
          exact?: boolean;
        }>;
      }
  )
> = [
  {
    icon: <FontAwesomeIcon icon={faStreetView} />,
    label: 'Overview',
    path: '',
    exact: true,
    condition: (c) => c.hasCapability('globalSuper'),
  },
  {
    icon: <FontAwesomeIcon icon={faListUl} />,
    label: 'Sensors',
    path: 'sensors',
    condition: (c) =>
      c.hasCapability('globalSuper') || c.hasCapability('vendor'),
  },
  {
    icon: <FontAwesomeIcon icon={faBuilding} />,
    label: 'Accounts',
    path: 'accounts',
    condition: (c) =>
      c.hasCapability('globalSuper') || c.hasCapability('vendorReadAccounts'),
  },
  {
    icon: <FontAwesomeIcon icon={faBuilding} />,
    label: 'Vendors',
    path: 'vendors',
    condition: (c) => c.hasCapability('globalSuper'),
  },
  {
    icon: <FontAwesomeIcon icon={faUsers} />,
    label: 'Users',
    path: 'users',
    condition: (c) => c.hasCapability('globalSuper'),
  },
  {
    icon: <FontAwesomeIcon icon={faUsersCog} />,
    label: 'Integration',
    path: 'integrations',
    condition: (c) =>
      c.hasCapability('globalSuper') ||
      c.hasCapability('vendorSetupIntegration'),
  },
  {
    icon: <FontAwesomeIcon icon={faSearch} />,
    label: 'Inspect data',
    condition: (c) => c.hasCapability('globalSuper'),
    children: [
      {
        icon: <FontAwesomeIcon icon={faSitemap} />,
        label: 'Zones',
        path: 'zones',
      },
      {
        icon: <FontAwesomeIcon icon={faUser} />,
        label: 'Care receivers',
        path: 'care-receivers',
      },
    ],
  },
  {
    style: { marginTop: '96px' },
    icon: <div style={{ fontSize: '20px', fontWeight: 'bold' }}>Ω</div>,
    label: 'Omega',
    path: '/omega',
    navigateAsLink: true,
    condition: (c) => c.hasCapability('globalSuper'),
  },
];

interface Props {
  className?: string;
  onItemClick?: () => void;
}

export function SuperVendorNavigation(props: Props) {
  const app = useAppContext();

  const { match, location, history } = useRouter();

  // A map of opened fields
  const [opened, setOpened] = useState<{ [key: string]: boolean }>({});

  return (
    <nav className={cs('SuperVendorNavigation', props.className)}>
      <List>
        {areas.map((area) => {
          // If this has a condition, check that before we continue.
          if (area.condition && !area.condition(app)) {
            return null;
          }

          // Is this a subarea/expanded field?
          if ('path' in area) {
            const path = area.path ? `${match.url}/${area.path}` : '';

            const click = area.navigateAsLink
              ? () => {
                  window.location.href = area.path;
                }
              : () => {
                  history.push(path);
                  props.onItemClick && props.onItemClick();
                };

            return (
              <ListItem
                button
                key={area.path}
                onClick={click}
                style={area.style}
                selected={
                  area.exact
                    ? area.path === location.pathname
                    : location.pathname.indexOf(path) === 0
                }
              >
                <ListItemIcon>{area.icon}</ListItemIcon>
                <ListItemText primary={area.label} />
              </ListItem>
            );
          }

          const open = opened[area.label] ?? false;
          const click = () => {
            setOpened({
              ...opened,
              [area.label]: !open,
            });
          };

          return (
            <React.Fragment key={area.label}>
              <ListItem button key={area.label} onClick={click}>
                <ListItemIcon>{area.icon}</ListItemIcon>
                <ListItemText primary={area.label} />
                {open ? <ExpandLess /> : <ExpandMore />}
              </ListItem>

              <Collapse in={open} timeout="auto" unmountOnExit>
                <List component="div" disablePadding>
                  {area.children?.map((ch) => {
                    const path = ch.path ? `${match.url}/${ch.path}` : '';
                    return (
                      <ListItem
                        button
                        key={ch.path}
                        onClick={() => {
                          history.push(path);
                          props.onItemClick && props.onItemClick();
                        }}
                        selected={
                          ch.exact
                            ? ch.path === location.pathname
                            : location.pathname.indexOf(path) === 0
                        }
                        style={{
                          paddingLeft: '40px',
                        }}
                      >
                        <ListItemIcon>{ch.icon}</ListItemIcon>
                        <ListItemText primary={ch.label} />
                      </ListItem>
                    );
                  })}
                </List>
              </Collapse>
            </React.Fragment>
          );
        })}
      </List>
    </nav>
  );
}
