import { FormikHelpers, useFormik } from 'formik';
import * as Yup from 'yup';
import { t } from '../../../../lib/i18n/i18n';

export interface ResetIntegrationUserPassword {
  password: string;
}

interface UseResetIntegrationUserPasswordFormOptions {
  validationSchema?: any | (() => any);
  onSubmit: (
    values: ResetIntegrationUserPassword,
    formikHelpers: FormikHelpers<ResetIntegrationUserPassword>
  ) => Promise<any>;
}

export const useResetIntegrationUserPasswordFormik = (
  opts: UseResetIntegrationUserPasswordFormOptions
) => {
  return useFormik<ResetIntegrationUserPassword>({
    initialValues: {
      password: '',
    },
    validateOnBlur: true,
    validateOnChange: true,
    initialTouched: { password: true },
    validationSchema: Yup.object().shape({
      password: Yup.string()
        .required()
        .test('password', t('common.commonTexts._passwordTips'), (value) => {
          const regx = /(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{8,}$/;
          return value ? regx.test(value) : false;
        }),
    }),
    onSubmit: async (values, formikHelpers) => {
      await opts.onSubmit(values, formikHelpers);
    },
  });
};

export type ResetIntegrationUserPasswordFormik = ReturnType<
  typeof useResetIntegrationUserPasswordFormik
>;
