import React, { useState } from 'react';
import ModalWrapper from '../ModalWrapper';
import { useApiCall } from '../../../lib/useApiCall';
import { TreeView } from '../../TreeView';
import { useErrorHandler } from '../../../lib/useErrorHandler';
import { ErrorMessages } from '../../ErrorMessages';
import { RouteLeavingGuard } from '../../RouteLeavingGuard';
import { useDataChangeDetect } from '../../../lib/useDataChangeDetect';
import { useSensorContext } from '../../../lib/SensorContext';
import { t } from '../../../lib/i18n';

import * as api from '../../../serverApi';
import { Checkbox, FormControlLabel } from '@material-ui/core';

export interface MoveSensorProps {
  isOpen: boolean;
  handleModal: () => void;
  onMove?: () => void;
}

export function MoveSensorModal(props: MoveSensorProps) {
  const errorHandler = useErrorHandler();
  const sensor = useSensorContext();

  const [loading, setLoading] = useState(false);

  const structure = useApiCall(api.getStructure);

  const zone = structure.data && structure.data.zone;

  const [reset, setReset] = useState(true);
  const [activeZone, setActiveZone] = useState<api.StructureZone | undefined>(
    zone
  );

  const detectDataChange = useDataChangeDetect<api.StructureZone | undefined>(
    zone
  );

  const resetModal = () => {
    errorHandler.reset();
    setActiveZone(undefined);
  };

  const handleSubmit = async () => {
    const sensorId = sensor.id;
    const zoneId = activeZone && activeZone.id;

    if (!sensorId || !zoneId) {
      return;
    }

    try {
      setLoading(true);
      await api.updateSensors(sensorId, {
        zone_id: zoneId,
        move: true,
        factoryResetSettings: reset,
      });
      setLoading(false);
      props.handleModal();
      if (props.onMove) {
        props.onMove();
      }
    } catch (error) {
      errorHandler.handleError(error);
      setLoading(false);
    }
  };

  const getDisabledZoneIds = (z?: api.StructureZone): number[] => {
    let response = [] as number[];

    if (!z) {
      return [] as number[];
    }

    if ((z.zones && z.zones.length > 0) || !z.is_housing_unit || z.api_lock) {
      response = response.concat(z.id);
      z?.zones?.forEach((zone: api.StructureZone) => {
        response = response.concat(getDisabledZoneIds(zone));
      });
    }

    return sensor.zone?.id ? [sensor.zone.id, ...response] : response;
  };

  const disabledZoneIds = getDisabledZoneIds(zone);

  return (
    <ModalWrapper
      title={t('components.MoveSensorModal.title', sensor.name || '')}
      okButtonText={t('common.commonButtons.move')}
      cancelButtonText={t('common.commonButtons.cancel')}
      className="ModalWrapper-with-tree-view"
      isOpen={props.isOpen}
      loading={loading}
      onHide={() => props.handleModal()}
      onSubmit={handleSubmit}
      onResetModal={resetModal}
    >
      <div>
        <RouteLeavingGuard when={detectDataChange.hasChanged(activeZone)} />
        {zone ? (
          <>
            <h2>{zone.name || ''}</h2>
            <TreeView
              root={zone}
              className="MoveSensor-tree"
              selectZone={setActiveZone}
              activeZone={activeZone}
              disabledZoneIds={disabledZoneIds}
            />
          </>
        ) : null}
        <FormControlLabel
          className="mt-3"
          label={t('components.MoveSensorModal.reset')}
          control={
            <Checkbox
              name="reset"
              checked={reset}
              onChange={(e) => setReset(e.target.checked)}
            />
          }
        />

        <ErrorMessages className="my-3" errorData={errorHandler} />
      </div>
    </ModalWrapper>
  );
}
