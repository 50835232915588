import React, { useContext } from 'react';

export interface ManageContext {
  isSidebarOpen: boolean;
  toggleSidebar: () => void;
}

const manageContext = React.createContext<ManageContext>({
  isSidebarOpen: false,
  toggleSidebar: () => {},
});

export function useManageContext(): ManageContext {
  return useContext(manageContext);
}

const { Provider } = manageContext;

export { Provider };
