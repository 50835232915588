import React from 'react';

import './HelpBlocks.scss';

interface Props {
  children?: React.ReactNode;
}

export function HelpBlocks(props: Props) {
  return <div className="HelpBlocks">{props.children}</div>;
}
