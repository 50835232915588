import * as Sentry from '@sentry/browser';
import config from '../../config';

const environment = config.sentryEnvironment(window.location);

if (environment && config.sentryDsn) {
  Sentry.init({
    environment,
    release: config.gitCommit,
    dsn: config.sentryDsn,
    beforeSend: (event) => {
      /**
       * Ignore errors thrown from IE11 browser
       */
      if (navigator.userAgent.toUpperCase().indexOf('TRIDENT/') !== -1) {
        return null;
      }
      return event;
    },
  });

  /**
   * Attach Sentry to window for more convenient testing of error handling in QA/Prod.
   */
  // (window as any).Sentry = Sentry;
}

export function captureMessage(message: string) {
  Sentry.captureMessage(message);
}
