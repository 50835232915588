import cs from 'classnames';
import { MouseEventHandler } from 'react';

import './HamburgerButton.scss';

interface Props {
  className?: string;
  open?: boolean;
  onClick?: MouseEventHandler<HTMLButtonElement>;
}

export const HamburgerButton = (props: Props) => {
  return (
    <button
      className={cs('HamburgerButton', props.open && 'HamburgerButton--open')}
      onClick={props.onClick}
    >
      <span></span>
      <span></span>
      <span></span>
      <span></span>
    </button>
  );
};
