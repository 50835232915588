import React from 'react';
import { FormControlLabel, RadioGroup, Radio } from '@material-ui/core';

import useZoneSettingsForm from './lib/useZoneSettingsForm';
import { Panel } from '../../../../../components/Panel';
import { useZoneContext } from '../../../../../lib/ZoneContext';
import { FormBody } from './lib/FormBody';

import ObservationImageBoth from '../../../../../images/observation/both.jpg';
import ObservationImageDepth from '../../../../../images/observation/depth.jpg';
import ObservationImageAmp from '../../../../../images/observation/amp.jpg';
import ObservationImageNone from '../../../../../images/observation/none.svg';
import * as api from '../../../../../serverApi';
import { t } from '../../../../../lib/i18n';
import { useAccount } from '../../../../../lib/useAccount';

const fields: Array<keyof api.ZoneSettings> = [
  'care_event_image_detailed',
  'care_event_image_anonymised',
];

export function EventImage() {
  const ctx = useZoneContext();
  const form = useZoneSettingsForm({ fields });
  const account = useAccount();

  // prettier-ignore
  function onChange(event: React.ChangeEvent<HTMLInputElement>, value: string) {
    form.formik.setFieldValue('care_event_image_detailed', value === 'both' || value === 'detailed');
    form.formik.setFieldValue('care_event_image_anonymised', value === 'both' || value === 'anonymised');
    form.formik.setFieldTouched('care_event_image_detailed');
    form.formik.setFieldTouched('care_event_image_anonymised');
  }

  let value = 'none';

  if (form.displayValues?.care_event_image_detailed === true) {
    value = form.displayValues?.care_event_image_anonymised
      ? 'both'
      : 'detailed';
  } else if (form.displayValues?.care_event_image_detailed === false) {
    value = form.displayValues?.care_event_image_anonymised
      ? 'anonymised'
      : 'none';
  }

  return (
    <div>
      <h2>
        {t('manage.zone.EventImage.headingFor')} {ctx.activeZone.name}
      </h2>

      <Panel>
        <p>
          <strong>{t('common.commonTexts.tips')}:</strong>{' '}
          {t('manage.zone.EventImage.tips')}
        </p>
      </Panel>

      <FormBody zoneSettingsForm={form}>
        <div className="radio-with-image">
          <RadioGroup name="light" value={value} onChange={onChange}>
            {!account.disable_all_detailed_images && (
              <>
                <div className="radio-with-image-option">
                  <img
                    src={ObservationImageBoth}
                    alt={t('manage.zone.EventImage.bothImages')}
                    className="mt-2 mr-3"
                  />
                  <FormControlLabel
                    disabled={form.disabled}
                    value="both"
                    control={<Radio color="primary" />}
                    label={t('manage.zone.EventImage.bothImages')}
                  />
                </div>

                <div className="radio-with-image-option">
                  <img
                    src={ObservationImageAmp}
                    alt={t('manage.zone.EventImage.detailedOnly')}
                    className="mt-2 mr-3"
                  />
                  <FormControlLabel
                    disabled={form.disabled}
                    value="detailed"
                    control={<Radio color="primary" />}
                    label={t('manage.zone.EventImage.detailedOnly')}
                  />
                </div>
              </>
            )}

            <div className="radio-with-image-option">
              <img
                src={ObservationImageDepth}
                alt={t('manage.zone.EventImage.anonymisedOnly')}
                className="mt-2 mr-3"
              />
              <FormControlLabel
                disabled={form.disabled}
                value="anonymised"
                control={<Radio color="primary" />}
                label={t('manage.zone.EventImage.anonymisedOnly')}
              />
            </div>

            <div className="radio-with-image-option">
              <img
                src={ObservationImageNone}
                alt={t('manage.zone.EventImage.noImage')}
                className="mt-2 mr-3"
              />
              <FormControlLabel
                disabled={form.disabled}
                value="none"
                control={<Radio color="primary" />}
                label={t('manage.zone.EventImage.noImage')}
              />
            </div>
          </RadioGroup>
        </div>
      </FormBody>
    </div>
  );
}
