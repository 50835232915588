import { atom, useAtom } from 'jotai';

import * as api from '../../../../../serverApi';

interface ArgusState {
  showSuggestions: boolean;
  showMatchedSuggestions: boolean;
  showUnsupportedSuggestions: boolean;

  disabledCategories: {
    bed: boolean;
    chair: boolean;
    seating_group: boolean;
  };
}

const argusAtom = atom<ArgusState>({
  showSuggestions: true,
  showMatchedSuggestions: false,
  showUnsupportedSuggestions: false,

  disabledCategories: {
    bed: false,
    chair: false,
    seating_group: false,
  },
});

export const useArgusAtom = () => useAtom(argusAtom);

export type ArgusDetection = api.ArgusDetections['detections'][0];
