import React, { useMemo, useState } from 'react';
import ModalWrapper from './ModalWrapper';
import { FormControlLabel, RadioGroup, Radio } from '@material-ui/core';
import * as api from '../../serverApi';
import { getLabelForEventType } from '../../lib/getLabelForEventType';
import { t } from '../../lib/i18n';
import { EventTypeIcon } from '../../lib/utils/EventTypeIcon';
import { useApiCall } from '../../lib/useApiCall';
import { InlineSpinner } from '../InlineSpinner';

import './SimulateModal.scss';

interface Props {
  isOpen: boolean;
  sensorId: number;
  sensorType: api.SensorType;
  onHide: () => void;
}

const whitelistedEvents: api.EventType[] = [
  'fall',
  'oob',
  'hib',
  'act',
  'ent',
  'exi',
  'ooc',
  'tlib',
  'hsnd',
  'lmib',
  'markings',
];

export function SimulateModal(props: Props) {
  const sensorCompositeId = `${props.sensorType}${props.sensorId}`;
  const [loading, setLoading] = useState(false);
  const [selectedEventType, setSelectedEventType] = useState<
    string | undefined
  >(undefined);
  const res = useApiCall(api.getEventTypesForSensor, [sensorCompositeId]);

  const handleSend = async () => {
    if (!selectedEventType) {
      return;
    }
    setLoading(true);
    try {
      await api.simulateEvent(sensorCompositeId, {
        eventType: selectedEventType,
      });
      setLoading(false);
      setSelectedEventType(undefined);
      props.onHide();
    } catch (err) {
      console.error(err);
      setLoading(false);
      throw err;
    }
  };

  /**
   * The list of event types to allow the user to select from.
   */
  const eventTypesToShow: Array<api.EventType> = useMemo(() => {
    if (!res.data?.data) {
      return [];
    }
    const eventTypes =
      res.data.data
        .filter((e) => whitelistedEvents.includes(e.event_type))
        .map((e) => e.event_type) ?? [];
    // Always show markings:
    // eventTypes.push('markings');
    return eventTypes;
  }, [res.data]);

  /**
   * Whether the currently selected event type is enabled on the sensor.
   */
  const isSelectedEventEnabled =
    res?.data?.data.find((e) => e.event_type === selectedEventType)?.enabled ??
    undefined;

  return (
    <ModalWrapper
      className="SimulateModal"
      isOpen={props.isOpen}
      onHide={() => {
        props.onHide();
        setSelectedEventType(undefined);
      }}
      okButtonText="Send"
      loading={loading}
      onSubmit={handleSend}
      disableSubmit={!selectedEventType}
      title={t('components.SimulateEvent.title')}
    >
      {res.loading ? (
        <InlineSpinner className="p-4" />
      ) : (
        <RadioGroup name="event_type" value={selectedEventType ?? ''}>
          {eventTypesToShow.map((eventType) => (
            <FormControlLabel
              key={eventType}
              value={eventType}
              control={<Radio color="primary" />}
              onClick={() => setSelectedEventType(eventType)}
              label={
                <>
                  <EventTypeIcon
                    sensorType="roommate"
                    eventType={eventType}
                    style={{
                      width: '22px',
                      height: '22px',
                      marginLeft: '8px',
                      marginRight: '6px',
                    }}
                  />
                  <div style={{ lineHeight: '22px', display: 'inline-block' }}>
                    {getLabelForEventType(eventType, 'roommate')}
                  </div>
                </>
              }
            />
          ))}
        </RadioGroup>
      )}
      <div
        className="SimulateModal-note"
        style={{
          visibility:
            !selectedEventType || isSelectedEventEnabled ? 'hidden' : 'visible',
        }}
      >
        <strong>{t('components.SimulateEventPanel.noteTitle')}</strong>
        <br />
        {t('components.SimulateEventPanel.note')}
      </div>
    </ModalWrapper>
  );
}
