import React, {
  PropsWithChildren,
  useEffect,
  useState,
  useRef,
  useCallback,
} from 'react';
import * as api from '../../../serverApi';

import {
  Provider,
  StreamType,
  SidebarMode,
  CareContext,
  Event,
  Sensor,
} from './CareContext';
import { PresenceModal } from '../../../components/modals/PresenceModal';
import { useSensorEventAttention } from './useSensorEventAttention';
import { useAppContext } from '../../../lib/global';
import HelpModal from '../../../components/modals/care/HelpModal';
import { useWindowSize } from '../../../lib/useWindowSize';
import { useRouter } from '../../../lib/useRouter';
import { useSensorStatus } from './useSensorStatus';
import { useLocalStorageState } from './useLocalStorageState';
import { useQueryParameters } from '../../../lib/useQueryParameters';

export default function CareContextProvider(props: PropsWithChildren<{}>) {
  const app = useAppContext();
  const router = useRouter();
  const windowSize = useWindowSize();
  const sensorStatus = useSensorStatus();
  const queryParams = useQueryParameters();

  const { alarms, alerts, sensors, event, sensor, dataTimestamp } =
    sensorStatus;
  const alarmAttention = useSensorEventAttention(alarms);
  const alertAttention = useSensorEventAttention(alerts);
  const [showDisableModal, setShowDisableModal] = useState(false);
  const [showHelpModal, setShowHelpModal] = useState(false);
  const [showMainContentMobile, setShowMainContentMobile] = useState(
    router.location.pathname !== '/care'
  );

  // The change hashes are used to keep track of changes to trigger extraAttention on the CareIndicators

  const [selectedSensor, setSelectedSensor] = useState<Sensor | undefined>(
    undefined
  );

  const [selectedEvent, setSelectedEvent] = useState<Event | undefined>(
    undefined
  );

  const [streamType, setStreamType] = useState<StreamType | undefined>('depth');
  const [sidebarMode, setSidebarModeState] = useState<SidebarMode>('sensors');

  const [showInfo, setShowInfo] = useState(false);

  const [activeZone, setActiveZone] = useState<api.StructureZone>();

  const [searchEnabled, setSearchEnabled] = useLocalStorageState<boolean>(
    'care-area-search-enabled',
    false
  );

  //Keep track if audio is continuing after image streaming stopped
  const [continueAudioStreaming, setContinueAudioStreaming] = useState(false);

  /**
   *
   * In ReceiptPage.tsx:221, there's a button that sends the user to care area (supervision) with an
   * additional 'start_streaming' url parameter that contains the sensor_composite_id.
   * If parameter is provided, we start streaming for that sensor.
   *
   */
  const sensorIdFromUrl = queryParams.get('start_streaming');
  const streamSensorFromUrlParam = useCallback(async () => {
    /**
     * If sensors aren't available still, or there aren't any sensors in the list, we skip selecting the sensor
     */
    if (
      !sensors ||
      sensors.length === 0 ||
      Object.keys(sensor).length === 0 ||
      !sensorIdFromUrl
    ) {
      return;
    }

    /**
     * In case sensor provided from url doesn't exist (which would be the case if the user doesn't have access to the sensor),
     * we logout the user and redirect it to login screen.
     */
    if (!sensor[sensorIdFromUrl]) {
      await app.requestLogout('/login');
    }

    selectSensor(sensorIdFromUrl);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sensors, sensorIdFromUrl]);

  useEffect(() => {
    streamSensorFromUrlParam();
  }, [streamSensorFromUrlParam]);

  /**
   * Check if users_status on selected sensor has changed when new sensors is received.
   * If it is updated the selected sensor
   */
  useEffect(() => {
    /**
     * Don't update selected sensor if there's no current sensor selected.
     */
    if (!selectedSensor) {
      return;
    }

    setSelectedSensor(
      sensors?.find(
        (x) => x.sensor_composite_id === selectedSensor?.sensor_composite_id
      )
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [JSON.stringify(sensors)]);

  // /**
  //  * On screen resize always reset sidebar state to false.
  //  * When going to a smaller screen from a larger screen sidebar
  //  * should be close by default
  //  */
  useEffect(() => {
    if (windowSize.isMobile) {
      setShowMainContentMobile(selectedSensor !== undefined);
    } else {
      setShowMainContentMobile(false);
    }
  }, [windowSize, setShowMainContentMobile, selectedSensor]);

  /**
   * When we get an updated list of sensors, make sure the currently
   * selected sensor is part of that list. Otherwise, unselect it.
   */
  useEffect(() => {
    if (selectedSensor && !sensor[selectedSensor.sensor_composite_id]) {
      setSelectedSensor(undefined);
    }
  }, [sensor, selectedSensor, setSelectedSensor]);

  /**
   * When we get an updated list of events, make sure the currently
   * selected event is part of that list. Otherwise, unselect it.
   */
  useEffect(() => {
    if (selectedEvent && !event[selectedEvent.event_id]) {
      setSelectedEvent(undefined);
    }
  }, [event, selectedEvent]);

  /**
   * When the selected sensor changes, we close the info screen if it's open
   */
  const sensorId = selectedSensor?.sensor_composite_id;
  useEffect(() => {
    if (streamType && showInfo) {
      setShowInfo(false);
    }
  }, [sensorId, streamType, showInfo, setShowInfo]);

  /**
   * Keep track of previous sensorStatus, and switch back to sensors
   * view if we handled the last event.
   */
  // Store the previous value
  const ref = useRef<typeof sensorStatus>(sensorStatus);
  useEffect(() => {
    ref.current = sensorStatus;
  }, [sensorStatus]);
  const prevSensorStatus = ref.current;
  // Act upon changes
  useEffect(() => {
    if (
      alarms?.length === 0 &&
      prevSensorStatus.alarms?.length &&
      sidebarMode === 'alarms'
    ) {
      setSidebarMode('sensors');
    }
    if (
      alerts?.length === 0 &&
      prevSensorStatus.alerts?.length &&
      sidebarMode === 'alerts'
    ) {
      setSidebarMode('sensors');
    }
  }, [alarms, alerts, sidebarMode]); // eslint-disable-line react-hooks/exhaustive-deps

  /**
   * When we update the sidebar mode, we also reset the extra attention if need be.
   * UPDATE: Comment this out; we always handle this by clicking.
   */
  // useEffect(() => {
  //   if (sidebarMode === 'alerts' && att.alerts) {
  //     att.clearAlertAttention();
  //   }
  //   if (sidebarMode === 'alarms' && att.alarms) {
  //     att.clearAlarmAttention();
  //   }
  // }, [sidebarMode, att.alerts, att.alarms]); // eslint-disable-line react-hooks/exhaustive-deps

  function selectSensor(sensorId: string | undefined) {
    //Reset audio streaming continued state after selected sensor is changed
    continueAudioStreaming && setContinueAudioStreaming(false);

    // TODO: reset any timeouts?
    if (selectedSensor?.sensor_composite_id === sensorId) {
      setSelectedSensor(undefined);
      return;
    }
    const sensor =
      sensorId && sensors
        ? sensors.find((s) => s.sensor_composite_id === sensorId)
        : undefined;
    if (selectedEvent) {
      setSelectedEvent(undefined);
    }

    if (showInfo || sidebarMode === 'info') {
      setStreamType(undefined);
    } else if (
      sensor?.media.observation_image_anonymised &&
      app.hasCapability('observationAnonymised')
    ) {
      if (streamType !== 'depth') {
        setStreamType('depth');
      }
    } else if (
      sensor?.media.observation_image_detailed &&
      app.hasCapability('observationDetailed')
    ) {
      if (streamType !== 'amp') {
        setStreamType('amp');
      }
    } else {
      setStreamType(undefined);
    }

    setSelectedSensor(sensor);
    router.history.push('/care');
  }

  function selectEvent(event_id: number | undefined) {
    if (selectedEvent?.event_id === event_id) {
      setSelectedEvent(undefined);
      return;
    }
    const evt = event_id && event ? event[event_id] : undefined;
    if (evt) {
      setSelectedSensor(undefined);
    }
    setSelectedEvent(evt);
    router.history.push('/care');
  }

  async function markEventAsHandled(
    event: Event,
    note?: string,
    vkp?: boolean
  ) {
    await api.markEventAsHandled({
      sensor_id: event.sensor.sensor_composite_id,
      event_type: event.event_type,
      event_id: event.event_id,
      note: note,
      vkp: vkp,
    });
  }

  function presenceSensor(id: string) {
    setShowDisableModal(true);
  }

  /**
   * When we switch sidebar modes, ensure we don't have a UI state not correlated to the sidebar.
   */
  function setSidebarMode(mode: SidebarMode) {
    //Check wether previous sidebar mode is 'info' and the provided one is 'sensors', if yes we don't clear the selected sensor.
    if (sidebarMode === 'info' && mode === 'sensors') {
      return setSidebarModeState(mode);
    }

    if (selectedSensor && mode !== 'info') {
      // We retain the selected sensor when we are selecting the info area (but not the reverse)
      setSelectedSensor(undefined);
    }
    if (selectedEvent) {
      setSelectedEvent(undefined);
    }
    setSidebarModeState(mode);
  }

  function toggleMainContent() {
    if (windowSize.isMobile) {
      setShowMainContentMobile(!showMainContentMobile);
    }
  }

  const handleActiveZoneSelect = (zone?: api.StructureZone) =>
    setActiveZone(zone);

  /**
   * Every time status.data changes, we filter the new alarms and alerts and see if they have changed.
   */

  const context: CareContext = {
    event,
    sensor,
    alerts,
    alarms,
    sensors,
    dataTimestamp,
    selectedSensor,
    selectSensor,
    selectedEvent,
    selectEvent,
    sidebarMode,
    setSidebarMode,
    streamType,
    setStreamType,
    attentionAlarms: alarmAttention.attention,
    attentionAlerts: alertAttention.attention,
    clearAlarmAttention: alarmAttention.clear,
    clearAlertAttention: alertAttention.clear,
    markEventAsHandled,
    setShowDisableModal,
    setShowHelpModal,
    presenceSensor,
    showInfo,
    setShowInfo,
    showMainContentMobile,
    toggleMainContent,
    activeZone,
    handleActiveZoneSelect,
    searchEnabled,
    setSearchEnabled,
    continueAudioStreaming,
    setContinueAudioStreaming,
  };

  return (
    <Provider value={context}>
      {props.children}
      <PresenceModal
        isOpen={showDisableModal}
        onClose={() => {
          setShowDisableModal(false);
        }}
      />
      <HelpModal
        isOpen={showHelpModal}
        onClose={() => {
          setShowHelpModal(false);
        }}
      />
    </Provider>
  );
}
