import React from 'react';
import { List, ListItem, ListItemIcon, ListItemText } from '@material-ui/core';
import { useRouter } from '../../../../lib/useRouter';
import { Switch, Route, Redirect } from 'react-router';
import cs from 'classnames';
import getFirstSegmentRelativeToRoot from '../../../../lib/getFirstSegmentRelativeToRoot';
import { StatusMarker } from '../../../../lib/ui/StatusMarker';
import { useWindowSize } from '../../../../lib/useWindowSize';
import { useSensorEnabledEvents } from './SensorEnabledEventsContext';
import { SensorEnabledEventsContextProvider } from './SensorEnabledEventsContextProvider';
import { t } from '../../../../lib/i18n';
import { EventTypeIcon } from '../../../../lib/utils/EventTypeIcon';

import './SensorEventSettings.scss';
import { getLabelForEventType } from '../../../../lib/getLabelForEventType';
import { useSensorContext } from '../../../../lib/SensorContext';
import { getSensorTypeFromCompositeId } from '../../../../lib/parseSensorCompositeId';

function SensorEventSettingsIndex() {
  const router = useRouter();
  const windowSize = useWindowSize();
  const { events } = useSensorEnabledEvents();

  if (windowSize.isTabletOrMobile) {
    return (
      <SensorEventSettingsSidebar className="SensorEventSettings-sidebar--mobile" />
    );
  }

  if (events.length > 0) {
    return <Redirect to={`${router.match.url}/${events[0].key}`} />;
  } else {
    return <p>{t('manage.sensors.events.noNotificationTypeAvailable')}</p>;
  }
}

function SensorEventSettingsSidebar(props: { className?: string }) {
  const router = useRouter();
  const { events } = useSensorEnabledEvents();
  const sensorContext = useSensorContext();
  const sensorType = getSensorTypeFromCompositeId(sensorContext.id);

  const currentSegment = getFirstSegmentRelativeToRoot(
    router.match.url,
    router.location.pathname
  );

  if (!sensorType) {
    console.warn('Invalid sensor type from ID:', sensorContext.id);
    return null;
  }

  return (
    <div className={cs('SensorEventSettings-sidebar', props.className)}>
      <h2>{t('manage.sensors.events.SensorEventsSettings.noticeOverview')}</h2>
      <List>
        {events.map((e) => {
          if (!e.settingsFormComponent) {
            return null;
          }
          return (
            <ListItem
              disableGutters={true}
              disableRipple={true}
              button
              key={e.key}
              onClick={() => {
                router.history.push(`${router.match.url}/${e.key}`);
              }}
              selected={e.key === currentSegment}
            >
              <div style={{ width: '30px' }}>
                {e.sensor.enabled && (
                  <StatusMarker
                    status="success"
                    style={{
                      fill: undefined,
                      width: '24px',
                      height: '24px',
                    }}
                  />
                )}
              </div>

              <ListItemIcon>
                <EventTypeIcon
                  sensorType={sensorType}
                  eventType={e.key}
                  style={{
                    width: '30px',
                    height: '30px',
                    fill: 'currentColor',
                  }}
                />
              </ListItemIcon>

              <ListItemText primary={getLabelForEventType(e.key, sensorType)} />
            </ListItem>
          );
        })}
      </List>
    </div>
  );
}

function SensorEventSettingsContent() {
  const router = useRouter();
  const { events } = useSensorEnabledEvents();

  return (
    <div className="SensorEventSettings-content">
      <Switch>
        <Route
          exact
          path={router.match.url}
          component={SensorEventSettingsIndex}
        />

        {events.map((e) => {
          if (!e.settingsFormComponent) {
            return null;
          }
          const Comp = e.settingsFormComponent;
          return (
            <Route
              key={e.key}
              path={`${router.match.url}/${e.key}`}
              render={() => <Comp initialValues={e.sensor} />}
            />
          );
        })}
        <Route render={() => <Redirect to={router.match.url} />} />
      </Switch>
    </div>
  );
}

export default function SensorEventSettings() {
  return (
    <div className="SensorEventSettings">
      <SensorEnabledEventsContextProvider>
        <SensorEventSettingsSidebar className="SensorEventSettings-sidebar--desktop" />

        <SensorEventSettingsContent />
      </SensorEnabledEventsContextProvider>
    </div>
  );
}
