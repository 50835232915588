import React, { CSSProperties, useCallback, useEffect, useState } from 'react';
import cn from 'classnames';
import { InputAdornment, TextField } from '@material-ui/core';
import { t } from '../../../../lib/i18n';

import * as api from '../../../../serverApi';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes } from '@fortawesome/pro-solid-svg-icons';
import { Icon } from '../../../../components/Icon';
import SelectZoneModal from '../../components/SelectZoneModal';
import { StructureZone } from '../../../../serverApi';
import { useErrorHandler } from '../../../../lib/useErrorHandler';

interface Props {
  disabled?: boolean;
  disableApiLockedZones?: boolean;
  disableHousingUnits?: boolean;
  value?: number;
  accountId?: number;
  onChange: (zone?: StructureZone) => void;
  style?: CSSProperties;
  variant?: 'standard' | 'filled' | 'outlined';
  error?: string;
  modalTitle?: string;
  placeholder?: string;
  className?: string;
}

export const ZoneFilter = (props: Props) => {
  const [zoneModal, setZoneModal] = useState<boolean>(false);
  const [selectedZone, setSelectedZone] = useState<StructureZone | undefined>(
    undefined
  );

  const [loadingZones, setLoadingZone] = useState(false);
  const errorHandler = useErrorHandler();

  const getStructureForAccount = useCallback(async () => {
    if (!selectedZone && props.value && props.accountId) {
      try {
        setLoadingZone(true);
        const data = await api.superVendorGetStructureForAccount(
          props.accountId
        );
        const zone = data?.zone?.zones?.find((zone) => zone.id === props.value);
        setSelectedZone(zone);
      } catch (e) {
        errorHandler.handleError(e);
      } finally {
        setLoadingZone(false);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.value, props.accountId]);

  useEffect(() => {
    getStructureForAccount();
  }, [getStructureForAccount]);

  // Reset selected zone if there isn't a selected account_id
  useEffect(() => {
    setSelectedZone(undefined);
  }, [props.accountId]);

  return (
    <>
      <TextField
        variant={props?.variant || 'outlined'}
        margin="dense"
        name="zone"
        type="text"
        className={cn('ZoneFilter', props.className)}
        placeholder={
          props.placeholder ??
          t('components.ZonePickerModal.selectZoneOrHousingUnit')
        }
        value={selectedZone?.name || ''}
        disabled={!props.accountId || props.disabled || loadingZones}
        style={props.style}
        error={props.error !== undefined || !!errorHandler.error}
        helperText={props.error || errorHandler.error}
        inputProps={{
          readOnly: true,
          style: {
            cursor: 'pointer',
            textOverflow: 'ellipsis',
          },
          onClick: () => setZoneModal(true),
        }}
        InputProps={{
          endAdornment: (
            <InputAdornment position="end">
              {selectedZone && (
                <FontAwesomeIcon
                  icon={faTimes}
                  className="mr-1 mt-1"
                  style={{ cursor: 'pointer' }}
                  onClick={() => {
                    props.onChange(undefined);
                    setSelectedZone(undefined);
                  }}
                />
              )}
              <span role="button" onClick={() => setZoneModal(true)}>
                <Icon icon="zone-tree" className="RoleForm--select-tree-icon" />
              </span>
            </InputAdornment>
          ),
        }}
      />

      {props.accountId && (
        <SelectZoneModal
          disableApiLockedZones={props.disableApiLockedZones}
          disableHousingUnits={props.disableHousingUnits}
          modalTitle={props.modalTitle}
          isOpen={zoneModal}
          accountId={props.accountId}
          toggle={() => setZoneModal(false)}
          onSelect={(zone) => {
            setSelectedZone(zone);
            props.onChange(zone);
          }}
        />
      )}
    </>
  );
};
