import { useMemo } from 'react';
import * as api from '../../../serverApi';
import { useApiCall } from '../../../lib/useApiCall';
import useInterval from '../../care/lib/useInterval';

// Update every minute
const refreshInterval = 30 * 1000;

export function useDashboardEventCounterData(
  zone_id: string | number | undefined | null,
  skipQuery?: boolean
) {
  const params = useMemo(
    () => ({
      zone_id:
        (typeof zone_id === 'string' ? parseInt(zone_id, 10) : zone_id) ?? 0,
    }),
    [zone_id]
  );

  const res = useApiCall(api.getDashboardEventsLastPeriod, [params], {
    skip: !zone_id || skipQuery,
  });

  useInterval(res.reload, refreshInterval);

  return {
    data: res.data,
    loading: res.loading,
    error: res.error,
  };
}
