import React, { useState } from 'react';
import cs from 'classnames';
import { useCombobox } from 'downshift';
import { debounce } from 'lodash';
import {
  InputAdornment,
  InputBase,
  Paper,
  IconButton,
  List,
} from '@material-ui/core';
import { ListItem } from '@material-ui/core';
import * as Sentry from '@sentry/browser';

import SearchIcon from '@material-ui/icons/Search';
import LoadingPlaceholder from '../../../../lib/ui/LoadingPlaceholder';
import * as api from '../../../../serverApi';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes } from '@fortawesome/pro-solid-svg-icons';
import { useRouter } from '../../../../lib/useRouter';
import useSearchStyles from './useSearchStyles';

import { getTargetLinkForSearchResult } from './getTargetLinkForSearchResult';
import { SearchResult } from './SearchResult';

import './HeaderSearch.scss';

type SingleSearchResult = api.SuperSearchResults['results'][0];

interface Props {
  area: 'super' | 'vendor';
}

export function HeaderSearch(props: Props) {
  const classes = useSearchStyles();
  const router = useRouter();

  const [query, setQuery] = React.useState('');
  const [searchResults, setSearchResults] = useState<SingleSearchResult[]>([]);
  const [loading, setLoading] = React.useState(false);

  const debouncedSuperSearch = React.useMemo(() => {
    return debounce(async (nextValue: any) => {
      try {
        setLoading(true);
        const res = await api.superSearch({ query: nextValue });
        setSearchResults(res.results);
      } catch (e) {
        Sentry.captureException(e);
      } finally {
        setLoading(false);
      }
    }, 300);
  }, []);

  const cmb = useCombobox({
    items: searchResults,
    // circularNavigation: true,
    // initialIsOpen: true/false,
    itemToString: () => query,

    onInputValueChange: (change) => {
      if (change.type !== useCombobox.stateChangeTypes.InputChange) {
        return;
      }
      setQuery(change.inputValue ?? '');
      if (change.inputValue && change.inputValue.length >= 3) {
        debouncedSuperSearch(query);
      }
    },

    onSelectedItemChange: (changes) => {
      if (changes.selectedItem) {
        const url = getTargetLinkForSearchResult(
          changes.selectedItem,
          props.area
        );
        if (url) {
          router.history.push(url);
        }
      }
    },

    stateReducer: (state, actionAndChanges) => {
      switch (actionAndChanges.type) {
        // case useCombobox.stateChangeTypes.InputBlur:
        //   // Prevent closing menu on blur
        //   return state;
        case useCombobox.stateChangeTypes.ItemClick:
          return {
            ...actionAndChanges.changes,
            isOpen: false,
            inputValue: '',
          };
        case useCombobox.stateChangeTypes.InputKeyDownEnter:
          return {
            ...actionAndChanges.changes,
            isOpen: false,
            inputValue: '',
          };
        default:
          return actionAndChanges.changes;
      }
    },
  });

  return (
    <div className="HeaderSearch">
      <label
        {...cmb.getLabelProps({
          className: 'sr-only ',
        })}
      >
        Search
      </label>
      <div {...cmb.getComboboxProps()}>
        <div className={classes.search}>
          <div className={classes.searchIcon}>
            <SearchIcon />
          </div>

          <InputBase
            {...cmb.getInputProps({
              refKey: 'inputRef',
              onKeyDown: (evt) => {
                /**
                 * When we click enter on the text field when no specific item is selected,
                 * we jump to the first result to make quick navigation simpler.
                 */
                if (
                  evt.key === 'Enter' &&
                  cmb.highlightedIndex === -1 &&
                  searchResults.length > 0
                ) {
                  const url = getTargetLinkForSearchResult(
                    searchResults[0],
                    props.area
                  );
                  if (url) {
                    router.history.push(url);
                    setQuery('');
                    cmb.setInputValue('');
                    cmb.closeMenu();
                  }
                }

                /**
                 * When not navigating in the menu, Home/End should be for the text field selection
                 */
                // if (cmb.highlightedIndex === -1) {
                if (evt.key === 'Home' || evt.key === 'End') {
                  (evt.nativeEvent as any).preventDownshiftDefault = true;
                }
                // }
              },
            })}
            placeholder="Search"
            classes={{
              root: classes.inputRoot,
              input: classes.inputInput,
            }}
            onFocus={() => {
              if (!cmb.isOpen && query) {
                cmb.openMenu();
              }
            }}
            endAdornment={
              query?.length > 0 ? (
                <InputAdornment position="end">
                  <IconButton
                    onClick={() => {
                      setSearchResults([]);
                      setQuery('');
                      cmb.setInputValue('');
                      cmb.closeMenu();
                    }}
                  >
                    <FontAwesomeIcon
                      icon={faTimes}
                      style={{ color: '#fff', fontSize: 20 }}
                    />
                  </IconButton>
                </InputAdornment>
              ) : undefined
            }
          />
        </div>
      </div>

      <Paper className="HeaderSearch-list" {...cmb.getMenuProps()}>
        {cmb.isOpen && loading && (
          <ListItem>
            <LoadingPlaceholder style={{ fontSize: 18, width: '100%' }} />
          </ListItem>
        )}

        {cmb.isOpen && !loading && !searchResults.length && (
          <ListItem>
            <div style={{ padding: '16px' }}>No results</div>
          </ListItem>
        )}

        {cmb.isOpen && !loading && searchResults.length > 0 && (
          <div className="HeaderSearch-results">
            <List className="HeaderSearch-results-list">
              {searchResults.map((item, index) => {
                return (
                  <ListItem
                    key={`${item.type}-${index}`}
                    className={cs(
                      index === cmb.highlightedIndex
                        ? classes.highlighted
                        : undefined
                    )}
                    {...cmb.getItemProps({
                      item,
                      index,
                    })}
                  >
                    <SearchResult item={item} />
                  </ListItem>
                );
              })}
            </List>
          </div>
        )}
      </Paper>
    </div>
  );
}
