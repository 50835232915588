import React, { CSSProperties } from 'react';
import cs from 'classnames';
import { EventTypeIcon } from '../../../lib/utils/EventTypeIcon';
import { CareCard } from './CareCard';
import * as api from '../../../serverApi';
import { ClickEvent } from '../../../components/LongPressButton';

import './EventCard.scss';

interface Props {
  event: api.CareEvent;
  selected?: boolean;
  className?: string;
  style?: CSSProperties;
  onClick?: (e: ClickEvent) => void;
}

export default function EventCard(props: Props) {
  return (
    <CareCard
      className={cs(
        'EventCard',
        `EventCard--${props.event.level}`,
        props.className
      )}
      style={props.style}
      sensor={props.event.sensor}
      icon={
        <EventTypeIcon
          className="EventCard-icon"
          sensorType={props.event.sensor.sensor_type}
          eventType={props.event.event_type}
        />
      }
      selected={props.selected}
      onClick={props.onClick}
    />
  );
}
