import React from 'react';

import useEventSettingsForm, {
  EventFormProps,
} from '.././useEventSettingsForm';

import EventActiveControl from '.././controls/EventActiveControl';
import EventLevelControl from '.././controls/EventLevelControl';
import EventTimeIntervalControl from '.././controls/EventTimeIntervalControl';
import EventFormControls from '.././controls/EventFormControls';
import PlayEventSoundControl from '.././controls/PlayEventSoundControl';
import EventSelectControl from '.././controls/EventSelectControl';
import EventNumberControl from '.././controls/EventNumberControl';
import { RouteLeavingGuard } from '../../../../../components/RouteLeavingGuard';
import { Panel } from '../../../../../components/Panel';
import { t } from '../../../../../lib/i18n';
import { getLabelForEventType } from '../../../../../lib/getLabelForEventType';
import { MissingMarkingWarningPanel } from '../controls/MissingMarkingWarningPanel';

export default function LastMovementEventForm(props: EventFormProps) {
  const form = useEventSettingsForm({
    sensorType: 'roommate',
    eventType: 'lmib',

    initialValues: props.initialValues,
    scales: {
      quarantine2: 60,
    },
  });

  return (
    <form onSubmit={form.handleSubmit}>
      <RouteLeavingGuard when={form.dirty} />
      <h2>{getLabelForEventType('lmib', 'roommate')}</h2>

      <MissingMarkingWarningPanel
        onlyWarnWhenMissing={true}
        markingType="bed"
      />

      <Panel>
        <span
          dangerouslySetInnerHTML={{
            __html: t('manage.sensors.events.LastMovementEventForm.firstInfo'),
          }}
        />
      </Panel>

      <EventActiveControl form={form} />

      <EventTimeIntervalControl form={form} />

      <EventNumberControl
        form={form}
        label={t(
          'manage.sensors.events.LastMovementEventForm.timeWithoutMovementInMinutes'
        )}
        field="quarantine2"
      />

      <EventSelectControl
        form={form}
        label={t('manage.sensors.events.LastMovementEventForm.sensitivity')}
        field="threshold2"
        options={[
          {
            label: t(
              'manage.sensors.events.LastMovementEventForm.sensitivity.low'
            ),
            value: 40,
          },
          {
            label: t(
              'manage.sensors.events.LastMovementEventForm.sensitivity.medium'
            ),
            value: 25,
          },
          {
            label: t(
              'manage.sensors.events.LastMovementEventForm.sensitivity.high'
            ),
            value: 15,
          },
        ]}
      />

      <EventLevelControl form={form} />

      <PlayEventSoundControl form={form} />

      <EventFormControls form={form} />
    </form>
  );
}
