import * as api from '../serverApi';

export interface ReducedZone {
  zone_id: number;
  zone_name: string | null;
  parent_zone: null | {
    id: number;
    name: string | null;
  };
  care_receiver: null | {
    id: number;
    first_name: string | null;
    last_name: string | null;
  };
  sensors: Array<{
    sensor_id: string;
    name: string | null;
  }>;
}

function groupSensorsByZone(sensors: api.CareSensor[]): ReducedZone[] {
  const zones = sensors.reduce((acc, cur) => {
    if (!acc[cur.zone_id]) {
      acc[cur.zone_id] = {
        zone_id: cur.zone_id,
        zone_name: cur.zone_name,
        parent_zone: cur.parent_zone_id
          ? {
              id: cur.parent_zone_id,
              name: cur.parent_zone_name,
            }
          : null,
        care_receiver: cur.care_receiver
          ? {
              id: cur.care_receiver.id,
              first_name: cur.care_receiver.first_name,
              last_name: cur.care_receiver.last_name,
            }
          : null,
        sensors: [],
      };
    }

    acc[cur.zone_id].sensors.push({
      sensor_id: cur.sensor_composite_id,
      name: cur.sensor_name,
    });

    return acc;
  }, {} as { [id: number]: ReducedZone });

  const arr = Object.values(zones);

  return arr;
}

export default groupSensorsByZone;
