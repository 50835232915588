import React, { useState } from 'react';
import { I18nContext, i18nContext } from './i18nContext';
import { fallbackLocale, isLocale, Locale, setGlobalLocale } from './i18n';

interface Props {
  preferredLocale?: Locale;
  onChangeLocale?: (locale: Locale) => void;
  children?: React.ReactNode;
}

export const LANG_LOCAL_STORAGE_KEY = 'lang-locale';

/**
 *
 * A React provider wrapper around the global i18n state.
 * The internal state `locale` should and must always match
 * the locale from `i18n.ts`.
 *
 */
export function I18nProvider(props: Props) {
  const [locale, setLocaleState] = useState(
    props.preferredLocale ?? fallbackLocale
  );

  function setLocale(locale: string | Locale) {
    if (!isLocale(locale)) {
      throw new Error(`Invalid locale "${locale}"`);
    }
    setLocaleState(locale);
    setGlobalLocale(locale);
    document.documentElement.lang = locale;
    localStorage.setItem(LANG_LOCAL_STORAGE_KEY, locale);
    props.onChangeLocale?.(locale);
  }

  const context: I18nContext = {
    locale,
    setLocale,
  };

  return (
    <i18nContext.Provider value={context}>
      {props.children}
    </i18nContext.Provider>
  );
}

/**
 *
 * Alternative approach:
 * We set the Provider key here to invalidate the whole subtree when the locale changes.
 * Somewhat brutal approach, but changing the language is a low-frequency action.
 */
