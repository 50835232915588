/*
    List of all available areas throughout the app
 */
const availableAreas = [
  'super',
  'vendor',
  'manage',
  'care',
  'dashboard',
] as const;

/**
 *
 * Get the current area from url
 *
 * @returns "super" | "vendor" | "manage" | "care" | "dashboard" | undefined
 */
export const getCurrentArea = (): typeof availableAreas[number] | undefined => {
  /**
   * The first segment of url always contains the area we're logged in to
   */
  const firstSegment = window.location.pathname.split('/')[1];
  /**
   * We initialize the current area with 'undefined' value
   */
  let currentArea = undefined;

  /**
   * If the first segment is one of the available areas, we append that to the currentArea var
   */
  availableAreas.forEach((area) => {
    if (area === firstSegment) {
      currentArea = firstSegment;
    }
  });

  return currentArea;
};
