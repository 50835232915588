import React from 'react';
import * as api from '../../../../serverApi';
import { TableWrapper } from '../../../../components/TableWrapper';

import {
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
} from '@material-ui/core';
import { differenceInSeconds, parseISO } from 'date-fns';
import { TableRowPlaceholder } from '../../common/TableRowPlaceholder';
import { InlineSpinner } from '../../../../components/InlineSpinner';
import { t } from '../../../../lib/i18n';

interface Props {
  entries?: api.StreamAccessLogEntry[];
  loading?: boolean;
}

const streamTypes = ['depth', 'amp', 'raw', 'activity_overview'];

function isValidStreamType(
  type: string | null
): type is 'depth' | 'amp' | 'raw' | 'activity_overview' {
  return type !== null && streamTypes.includes(type);
}

export function ObservationLogEntriesTable(props: Props) {
  return (
    <TableWrapper>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell>{t('manage.logs.common.pointInTime')}</TableCell>
            <TableCell>
              {t('manage.logs.observation.ObservationLogEntriesTable.length')}
            </TableCell>
            <TableCell>
              {t(
                'manage.logs.observation.ObservationLogEntriesTable.servicePractitioner'
              )}
            </TableCell>
            <TableCell>
              {t('manage.logs.observation.ObservationLogEntriesTable.place')}
            </TableCell>
            <TableCell>
              {t(
                'manage.logs.observation.ObservationLogEntriesTable.theRecipient'
              )}
            </TableCell>
            <TableCell>
              {t('manage.logs.observation.ObservationLogEntriesTable.type')}
            </TableCell>
            <TableCell>
              {t('manage.logs.observation.ObservationLogEntriesTable.audio')}
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {props.entries && props.entries.length > 0 ? (
            props.entries.map((e) => (
              <TableRow key={e.access_log_id}>
                <TableCell>{e.time_start_user_local}</TableCell>
                <TableCell>
                  {e.time_stop_user_local === e.time_start_user_local
                    ? null
                    : `${differenceInSeconds(
                        parseISO(e.time_stop_user_local),
                        parseISO(e.time_start_user_local)
                      )} s`}
                </TableCell>

                <TableCell>
                  {e.user &&
                    (e.user.impersonal_user ? (
                      <>{e.user.display_name}</>
                    ) : (
                      <>
                        {e.user.first_name} {e.user.last_name}{' '}
                        {e.user.username ? ` (${e.user.username})` : ''}
                      </>
                    ))}
                </TableCell>

                <TableCell>
                  {e.zone ? (
                    <>
                      {e.zone.parent && <>{e.zone.parent.name} &raquo; </>}
                      {e.zone.name}
                      {e.sensor?.name && <> &raquo; {e.sensor.name}</>}
                    </>
                  ) : null}
                </TableCell>

                <TableCell>
                  {e.care_receiver && (
                    <>
                      {e.care_receiver.first_name} {e.care_receiver.last_name}
                    </>
                  )}
                </TableCell>

                <TableCell>
                  {isValidStreamType(e.stream_type) &&
                    t(
                      `manage.logs.observation.ObservationLogEntriesTable.field.streamType.${e.stream_type}`
                    )}
                </TableCell>

                <TableCell>
                  {e.audio_property &&
                    t(
                      `manage.logs.observation.ObservationLogEntriesTable.field.audioProperty.${e.audio_property}`
                    )}
                </TableCell>
              </TableRow>
            ))
          ) : (
            <TableRowPlaceholder colSpan={7}>
              {props.loading ? (
                <InlineSpinner text={t('common.commonTexts.loading')} />
              ) : (
                <>
                  {t(
                    'manage.logs.common.thereAreNoEntriesInTheLogThatMatchTheFilters'
                  )}
                </>
              )}
            </TableRowPlaceholder>
          )}
        </TableBody>
      </Table>
    </TableWrapper>
  );
}
