import { MenuItem, Select } from '@material-ui/core';
import React from 'react';
import { t } from '../lib/i18n';
import cs from 'classnames';
import { localeDefinitions, locales } from '../lib/i18n/i18n';
import { useAppContext } from '../lib/global';

interface Props {
  className?: string;
  name?: string;
  value?: string;
  disableDefaultValue?: boolean;
  onChange?: (
    event: React.ChangeEvent<{
      name?: string | undefined;
      value: unknown;
    }>
  ) => void;
}

export function LanguageSelector(props: Props) {
  const app = useAppContext();

  return (
    <div className={cs('LanguageSelector', props.className)}>
      <span
        style={{
          color: '#3f3f3f',
          display: 'block',
          fontSize: '15px',
          fontFamily: 'Roboto',
          fontWeight: 500,
          marginTop: 15,
        }}
      >
        {t('common.commonLanguages.language')}
      </span>
      <Select
        name={props.name}
        value={props.value}
        onChange={props.onChange && props.onChange}
        style={{ marginTop: 0 }}
      >
        {!props.disableDefaultValue && (
          <MenuItem value="null">
            {t('components.LanguageSelector.standardAccountLanguage')}
          </MenuItem>
        )}

        {locales.map((lang) => {
          const definition = localeDefinitions[lang];

          /**
           * If the language has a feature requirement, and the user
           * does not have that feature, do not show the language.
           */
          if (definition.feature && !app.hasFeature(definition.feature)) {
            return null;
          }

          return (
            <MenuItem key={lang} value={lang}>
              {t(`common.commonLanguages.languages.${lang}`)}
            </MenuItem>
          );
        })}
      </Select>
    </div>
  );
}
