import React from 'react';
import { InlineError } from '../../../../../components/InlineError';
import { t } from '../../../../../lib/i18n';

interface Props {
  error: any;
}

export const MarkingErrorHandler = (props: Props) => {
  if (!props.error) {
    return null;
  }

  if (!props.error.response) {
    return (
      <InlineError>
        {t(
          'manage.sensors.marking.components.MarkingErrorHandler.noResponseFromServer'
        )}
      </InlineError>
    );
  }

  if (props.error.response.status >= 500) {
    return (
      <InlineError>
        {t('manage.sensors.marking.components.MarkingErrorHandler.serverError')}
      </InlineError>
    );
  }

  if (
    props.error.response.status === 422 &&
    Object.keys(props.error.response.data).length > 0
  ) {
    const keys = Object.keys(props.error.response.data);
    const errors = keys.map((k) => props.error.response.data[k]);
    return (
      <>
        {errors.map((e) => (
          <div key={e}>
            <InlineError>
              <span>{e}</span>
            </InlineError>
          </div>
        ))}
      </>
    );
  }

  return <InlineError>{props.error.message}</InlineError>;
};
