import roommateLogoGreen from '../../images/logo-green.svg';
import { PublicLanguageSelector } from './PublicLanguageSelector';

import './PublicHeader.scss';

export const PublicHeader = () => {
  return (
    <div className="PublicHeader">
      <div className="PublicHeader__logo-wrapper">
        <a href="https://roommate.no">
          <img
            src={roommateLogoGreen}
            alt="Roommate logo"
            className="PublicHeader-logo"
          />
        </a>
      </div>
      <PublicLanguageSelector />
    </div>
  );
};
