import React, { useEffect, useState } from 'react';
import { TextField } from '@material-ui/core';
import * as Sentry from '@sentry/browser';

import { Button } from '../../../../../../components/Button';
import { useFormik } from 'formik';
import { getGlobalLocale, t } from '../../../../../../lib/i18n/i18n';
import moment from 'moment';
import MomentUtils from '@date-io/moment';
import {
  KeyboardDatePicker,
  MuiPickersUtilsProvider,
} from '@material-ui/pickers';
import { MaterialUiPickersDate } from '@material-ui/pickers/typings/date';
import { SensorBillingPeriod } from '../../../../../../serverApi';
import * as yup from 'yup';
import * as api from '../../../../../../serverApi';

import './BillingAndPoForm.scss';
import { isValidationError } from '../../../../../../lib/utils/errorUtils';

const dateFormat = 'YYYY-MM-DD';

interface Props {
  sensorId: string;
  onCreate: (res: SensorBillingPeriod) => void;
}

export const BillingAndPoForm = (props: Props) => {
  const [visible, setVisible] = useState(false);

  const formik = useFormik({
    initialValues: {
      period_from: '',
      period_to: '',
      comment: '',
      purchase_order: '',
    },
    validateOnBlur: false,
    validationSchema: () =>
      yup.object().shape({
        period_from: yup.string().nullable(),
        period_to: yup.string().nullable(),
        purchase_order: yup.string().nullable(),
        comment: yup.string().nullable(),
      }),
    onSubmit: async (values, helpers) => {
      try {
        const res = await api.superCreateSensorBillingPeriod(props.sensorId, {
          period_from: values.period_from,
          period_to: values.period_to,
          purchase_order: values.purchase_order,
          comment: values.comment,
        });
        helpers.resetForm();
        props.onCreate(res);
      } catch (e) {
        Sentry.captureException(e);
        const errors = isValidationError(e) && e.response?.data?.errors;

        if (!errors) {
          return;
        }

        const errorsObject: { [key: string]: string } = {};
        Object.keys(errors).forEach((key: string) => {
          if (errors.hasOwnProperty(key)) {
            errorsObject[key] = errors[key][0];
          }
        });
        helpers.setErrors(errorsObject);
      }
    },
  });

  useEffect(() => {
    formik.setErrors({});
    formik.resetForm();
  }, [visible]); // eslint-disable-line react-hooks/exhaustive-deps

  const handleDateChange = (name: string, e: MaterialUiPickersDate) => {
    formik.setFieldValue(name, e?.format(dateFormat));
    formik.setFieldTouched(name);
  };

  const periodFromMaxDate = formik.values.period_to
    ? moment(formik.values.period_to, dateFormat)
    : null;

  const periodToMinDate = formik.values.period_from
    ? moment(formik.values.period_from, dateFormat)
    : null;

  return (
    <div className="BillingAndPoForm">
      <Button variant="contained" onClick={() => setVisible(!visible)}>
        {visible ? 'Close' : 'Add new'}
      </Button>

      {visible && (
        <form onSubmit={formik.handleSubmit}>
          <div className="BillingAndPoForm__wrapper">
            <MuiPickersUtilsProvider
              locale={getGlobalLocale()}
              utils={MomentUtils}
            >
              <KeyboardDatePicker
                autoFocus
                margin="dense"
                className="BillingAndPoForm__input mr-4"
                style={{ maxWidth: 180 }}
                label={t('manage.logs.ui.Filters.periodFrom')}
                okLabel={t('common.commonButtons.ok')}
                cancelLabel={t('common.commonButtons.cancel')}
                inputVariant="outlined"
                format={dateFormat}
                maxDate={periodFromMaxDate || undefined}
                value={formik.values.period_from || null}
                error={
                  formik.touched.period_from && !!formik.errors.period_from
                }
                helperText={formik.errors.period_from}
                onChange={(e) => handleDateChange('period_from', e)}
              />

              <KeyboardDatePicker
                margin="dense"
                className="BillingAndPoForm__input mr-4"
                style={{ maxWidth: 180 }}
                label={t('manage.logs.ui.Filters.periodTo')}
                okLabel={t('common.commonButtons.ok')}
                cancelLabel={t('common.commonButtons.cancel')}
                inputVariant="outlined"
                format={dateFormat}
                minDate={periodToMinDate || undefined}
                value={formik.values.period_to || null}
                error={formik.touched.period_to && !!formik.errors.period_to}
                onChange={(e) => handleDateChange('period_to', e)}
              />
            </MuiPickersUtilsProvider>

            <TextField
              margin="dense"
              variant="outlined"
              placeholder="Purchase order"
              className="BillingAndPoForm__input mr-4"
              style={{ maxWidth: 180 }}
              name="purchase_order"
              value={formik.values.purchase_order}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
            />

            <TextField
              margin="dense"
              variant="outlined"
              placeholder="Comment"
              className="BillingAndPoForm__input mr-4"
              style={{ maxWidth: 180 }}
              name="comment"
              value={formik.values.comment}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
            />

            <Button
              type="submit"
              variant="contained"
              disabled={formik.isSubmitting}
            >
              {formik.isSubmitting ? 'Saving' : 'Save'}
            </Button>
          </div>
        </form>
      )}
    </div>
  );
};
