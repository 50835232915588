import React, { useState } from 'react';
import cs from 'classnames';
import ItemPicker from './ItemPicker';
import * as api from '../../serverApi';
import formatUserLabel from '../../areas/manage/permissions/lib/formatUserLabel';
import { IconButton } from '../IconButton';
import { t } from '../../lib/i18n';

import './UserSelector.scss';

/**
 * The user object used by the UserSelector. Should be congruent with AlertSettingRecipient.
 */
export interface UserSelectorUser {
  id: number;
  username: string;
  impersonal_user: boolean;
  phone: null | string;
  first_name: string | null;
  last_name: string | null;
  display_name: string | null;
  user_roles?: null | Array<{
    role: string;
    zone: null | {
      id: number;
      name: string | null;
    };
  }>;
}

interface Props {
  label?: string;
  disabled?: boolean;
  error?: string | null;
  recipients: UserSelectorUser[];

  /**
   * The zone_id to pass to the users search API.
   */
  searchZoneId?: number;

  onChange(recipients: UserSelectorUser[]): void;
}

export function UserSelector(props: Props) {
  const [show, setShow] = useState(false);

  async function searchForUsers(query: string) {
    const res = await api.searchUsers({ query, zone_id: props.searchZoneId });

    if (!res || res instanceof Error) {
      return [];
    } else {
      return res.results;
    }
  }

  const submit = (selectedItems: UserSelectorUser[]) => {
    props.onChange(selectedItems);
  };

  return (
    <div className="UserSelector">
      <strong>{props?.label}</strong>

      <div
        className={cs(
          'UserSelector-list',
          !!props.error && 'UserSelector-list--error'
        )}
      >
        <ul>
          {!props.recipients || props.recipients.length === 0 ? (
            <div style={{ color: '#909090' }}>
              {t('components.UserSelector.noRecipientsAdded')}
            </div>
          ) : (
            props.recipients.map((r) => (
              <li key={r.id}>{formatUserLabel(r)}</li>
            ))
          )}
        </ul>
      </div>

      {props.error && <div className="text-danger my-2">{props.error}</div>}

      <IconButton
        disabled={props.disabled}
        color="primary"
        icon="plus"
        onClick={() => setShow(!show)}
      >
        {t('components.UserSelector.addOrChangeRecipients')}
      </IconButton>

      <ItemPicker
        heading={t('components.UserSelector.receivers')}
        isOpen={show}
        toggleState={() => setShow(false)}
        onSave={submit}
        selectedItemsPlaceholder={t('components.UserSelector.noUsersSelected')}
        initialSelectedItems={props.recipients}
        searchFunction={searchForUsers}
      />
    </div>
  );
}
