import React from 'react';
import cs from 'classnames';

import { EventTypeIcon } from '../../lib/utils/EventTypeIcon';
import { SensorType } from '../../serverApi';

import './DashboardEventCounter.scss';
import { t } from '../../lib/i18n';

export interface CountEvent {
  sensorType: SensorType;
  eventType: string;
  count: number;
}

interface Props {
  events: CountEvent[];
  className?: string;
}

export function DashboardEventCounter(props: Props) {
  if (props.events.length === 0) {
    return <p>{t('dashboard.noEvents24')}</p>;
  }

  return (
    <ul className={cs('DashboardEventCounter', props.className)}>
      {props.events.map((evt, ix) => (
        <li key={ix}>
          <EventTypeIcon
            eventType={evt.eventType}
            sensorType={evt.sensorType}
          />
          {evt.count}
        </li>
      ))}
    </ul>
  );
}
