import { TableCell } from '@material-ui/core';
import * as serverApi from '../../../serverApi';
import { t } from '../../../lib/i18n';
import { useSuperStyles } from '../useSuperStyles';
import { Table } from '../shared/Table';

interface Props {
  data: serverApi.SuperOverviewData['nhn_certificates'];
  loading?: boolean;
}

const columns = [
  {
    key: 'account_name',
    header: t('super.Overview.nhnCertificates.accountName'),
  },
  {
    key: 'account_code',
    header: t('supervendor.AccountForm.accountCode'),
  },
  {
    key: 'days_left',
    header: t('super.Overview.nhnCertificates.daysLeft'),
  },
];

export const NhnCertificatesTable = (props: Props) => {
  const classes = useSuperStyles();

  /**
   * Render custom (friendlier) message if certificate has expired.
   */
  const renderDaysLeft = (daysLeft: number) => {
    if (daysLeft < 0) {
      return `${t(
        'super.Overview.nhnCertificates.certificateExpired'
      )} ${Math.abs(daysLeft)} ${t('super.Overview.nhnCertificates.daysAgo')}`;
    }
    return daysLeft;
  };

  /**
   *
   * Get row background color for NHN Certificates table depending on days left until certificate expires.
   * Yellow warning when there's 3 months left, red warning when there's 1 month left.
   *
   * @param daysLeft until certificate expires (negative value when it has expired)
   * @returns hex color code
   */
  const getRowBackgroundColor = (daysLeft: number) => {
    if (daysLeft < 0) {
      return '#ff3333';
    }
    if (daysLeft <= 30) {
      return '#ff9999';
    }
    if (daysLeft <= 90) {
      return '#fff3cd';
    }
  };

  const rows = props.data.map((certificate) => ({
    id: certificate.account_id,
    style: {
      backgroundColor: getRowBackgroundColor(certificate.days_left),
    },
    account_name: <TableCell>{certificate.name}</TableCell>,
    account_code: <TableCell>{certificate.account_code}</TableCell>,
    days_left: <TableCell>{renderDaysLeft(certificate.days_left)}</TableCell>,
  }));

  return (
    <>
      <h5 className="mb-2">NHN certificates</h5>
      <Table
        className={classes.table}
        columns={columns}
        rows={rows}
        loading={props.loading}
      />
    </>
  );
};
