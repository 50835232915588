import * as api from '../../../serverApi';
import { ImageStreamer } from '../ImageStreamer';

import './MarkingOverview.scss';

interface Props {
  sensorCompositeId?: string;
  markingObjectsLoading?: boolean;

  /**
   * An array of the marking objects to be drawn on the canvas.
   */
  markingObjects?: api.MarkingObject[];
}

export function MarkingOverview(props: Props) {
  return (
    <div className="MarkingOverview">
      <div className="MarkingOverview-image">
        {props.markingObjectsLoading && <div>Loading ...</div>}
        {props.markingObjects && (
          <ImageStreamer
            useSingleMarkingImage={true}
            markingObjects={props.markingObjects}
            streamType="amp"
            throttleInterval={1000}
            disableExplicitSizing={true}
            sensorId={props.sensorCompositeId ?? ''}
          />
        )}
      </div>
    </div>
  );
}
