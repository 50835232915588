import React from 'react';
import { PersonaliaForm } from './PersonaliaForm';
import { Button } from '../../../../components/Button';
import { useZoneContext } from '../../../../lib/ZoneContext';
import { useRouter } from '../../../../lib/useRouter';
import { useHandleCancel } from '../../../../lib/useHandleCancel';
import { usePermissionsFormik } from '../lib/usePermissionsFormik';

import { t } from '../../../../lib/i18n';
import * as yup from 'yup';
import * as api from '../../../../serverApi';
import { useErrorHandler } from '../../../../lib/useErrorHandler';
import { ErrorMessages } from '../../../../components/ErrorMessages';
import isValidNorwegianId from '../../../../lib/isValidNorwegianId';
import { useAccount } from '../../../../lib/useAccount';
import { isValidationError } from '../../../../lib/utils/errorUtils';

interface Props {
  data: api.GetUserResult;
  onAfterChange?: () => void;
}

export const EditUserForm = (props: Props) => {
  const zoneCtx = useZoneContext();
  const router = useRouter();
  const hc = useHandleCancel();
  const errorHandler = useErrorHandler();
  const account = useAccount();

  const userData = props.data;
  const hasNationalId = userData.national_id_number !== null;

  const formik = usePermissionsFormik({
    initialValues: {
      username: userData.username,
      first_name: userData.first_name ?? '',
      last_name: userData.last_name ?? '',
      email: userData.email ?? '',
      phone: userData.phone ?? '',
      password: undefined,
      only_personal_devices_for_otp:
        userData.only_personal_devices_for_otp ?? false,
      national_id_number: userData.national_id_number ?? '',
      preferred_locale: userData.preferred_locale ?? 'null',
      external_idp: userData.external_idp,
    },
    validationSchema: () =>
      yup.object().shape(
        {
          username: yup.string().required(),
          national_id_number: yup
            .string()
            .test(
              'national id number',
              t('common.commonTexts.invalidBirthNumber'),
              (x) =>
                !account.disable_national_id_numbers ||
                hasNationalId ||
                !x ||
                isValidNorwegianId(x)
            ),
          first_name: yup.string().when('email', {
            is: (values: unknown) => !values,
            then: yup
              .string()
              .required(
                t('manage.permissions.common.firstNameOrEmailIsRequired')
              ),
            otherwise: yup.string().nullable(),
          }),
          last_name: yup.string().nullable(),
          email: yup
            .string()
            .email()
            .when('first_name', {
              is: (values: unknown) => !values,
              then: yup
                .string()
                .email()
                .required(
                  t('manage.permissions.common.firstNameOrEmailIsRequired')
                ),
            }),
          phone: yup
            .string()
            .test(
              'Serial',
              t(
                'manage.permissions.common.thePhoneNumberMustContainOnlyDigits'
              ),
              (v) => (typeof v === 'string' ? /^\+?[0-9 ]+$/.test(v) : true)
            )
            .nullable(),
        },
        [['email', 'first_name']]
      ),

    onSubmit: async (values, formikHelpers) => {
      try {
        formikHelpers.setSubmitting(true);
        await api.updateUser(props.data.user_id, {
          username: values.username,
          password: values.password,
          first_name: values.first_name,
          national_id_number: hasNationalId
            ? undefined
            : values.national_id_number,
          last_name: values.last_name,
          email: values.email,
          phone: values.phone,
          only_personal_devices_for_otp: values.only_personal_devices_for_otp,
          preferred_locale:
            values.preferred_locale === 'null' ? null : values.preferred_locale,
        });
        formikHelpers.resetForm(values);
        if (props.onAfterChange) {
          props.onAfterChange();
        }
        router.history.push(
          '/manage/' + zoneCtx.activeZone.id + '/permissions'
        );
      } catch (e) {
        errorHandler.handleError(e);
        formikHelpers.setSubmitting(false);
        if (isValidationError(e)) {
          formikHelpers.setErrors(e.response?.data.errors);
          if (e.response?.data.errors['password.password']) {
            formikHelpers.setFieldError(
              'password',
              e.response.data.errors['password.password'][0]
            );
          }
        }
      }
    },
  });

  return (
    <div className="EditUserForm">
      <PersonaliaForm
        title={t('manage.permissions.forms.EditUserForm.changeServiceProvider')}
        form={formik}
        disableNationalId={hasNationalId}
      />

      <div className="mt-3">
        <Button
          type="submit"
          color="primary"
          className="ml-auto mr-2"
          disabled={formik.isSubmitting || !formik.isValid}
          spinIcon={formik.isSubmitting}
          icon={formik.isSubmitting ? 'gear' : undefined}
          onClick={formik.submitForm}
        >
          {t('common.commonButtons.save')}
        </Button>
        <Button
          className="ml-auto mr-2"
          onClick={() =>
            hc.handleCancel(
              formik.dirty,
              `/manage/${zoneCtx.activeZone.id}/permissions/${userData.user_id}`,
              () => formik.resetForm()
            )
          }
        >
          {t('common.commonButtons.cancel')}
        </Button>

        <ErrorMessages
          className="my-2"
          errorData={errorHandler}
          renderFieldErrors={true}
        />
      </div>
    </div>
  );
};
