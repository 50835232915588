import React, { useEffect, useState } from 'react';
import { FormControl, FormGroup, MenuItem, TextField } from '@material-ui/core';
import { toast } from 'react-toastify';

import { ButtonGroup } from '../../../../components/ButtonGroup';
import { Button } from '../../../../components/Button';
import { useDataChangeDetect } from '../../../../lib/useDataChangeDetect';
import { RouteLeavingGuard } from '../../../../components/RouteLeavingGuard';

import * as serverApi from '../../../../serverApi';

import './LogStorage.scss';
import { useErrorHandler } from '../../../../lib/useErrorHandler';
import { ErrorMessages } from '../../../../components/ErrorMessages';
import { useHandleCancel } from '../../../../lib/useHandleCancel';
import { t } from '../../../../lib/i18n';

interface LogStorageState {
  log_lifetime_event_images_minutes: number | undefined;
  log_lifetime_change_logs_days: number | undefined;
  log_lifetime_access_logs_days: number | undefined;
  log_lifetime_care_logs_days: number | undefined;
}

export default function LogStorage() {
  const [loading, setLoading] = useState(true);
  const [state, setState] = useState<LogStorageState>({
    log_lifetime_event_images_minutes: undefined,
    log_lifetime_change_logs_days: undefined,
    log_lifetime_access_logs_days: undefined,
    log_lifetime_care_logs_days: undefined,
  });

  const hc = useHandleCancel();
  const errorHandler = useErrorHandler();
  const dataChangeDetect = useDataChangeDetect<LogStorageState>(state);

  const setStateData = (data: serverApi.AccountSettingsResult) => {
    setState({
      log_lifetime_event_images_minutes: data.log_lifetime_event_images_minutes,
      log_lifetime_change_logs_days: data.log_lifetime_change_logs_days,
      log_lifetime_access_logs_days: data.log_lifetime_access_logs_days,
      log_lifetime_care_logs_days: data.log_lifetime_care_logs_days,
    });
    dataChangeDetect.setData({
      log_lifetime_event_images_minutes: data.log_lifetime_event_images_minutes,
      log_lifetime_change_logs_days: data.log_lifetime_change_logs_days,
      log_lifetime_access_logs_days: data.log_lifetime_access_logs_days,
      log_lifetime_care_logs_days: data.log_lifetime_care_logs_days,
    });
  };

  const onChange = (
    e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>
  ) => {
    setState({
      ...state,
      [e.target.name]: parseInt(e.target.value, 10),
    });
  };

  useEffect(() => {
    serverApi
      .getAccountSettings()
      .then((data) => {
        setLoading(false);
        setStateData(data);
      })
      .catch((e) => {
        errorHandler.handleError(e);
        setLoading(false);
      });
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const onSubmit = () => {
    setLoading(true);
    serverApi
      .updateAccountSettings({
        log_lifetime_event_images_minutes:
          state.log_lifetime_event_images_minutes,
        log_lifetime_change_logs_days: state.log_lifetime_change_logs_days,
        log_lifetime_access_logs_days: state.log_lifetime_access_logs_days,
        log_lifetime_care_logs_days: state.log_lifetime_care_logs_days,
      })
      .then((data) => {
        setLoading(false);
        setStateData(data);
        toast.success(t('manage.account.AccountSettings.savedSuccessfully'));
      })
      .catch((e) => {
        errorHandler.handleError(e);
        toast.error(t('manage.account.AccountSettings.errorWhileSaving'));
        setLoading(false);
      });
  };

  return (
    <div className="LogStorage">
      <RouteLeavingGuard when={dataChangeDetect.hasChanged(state)} />

      <h1>{t('manage.account.LogStorage.automaticDeletionOfPhotosAndLogs')}</h1>

      <div>
        <FormGroup className="LogStorage-input-wrapper">
          <FormControl>
            <TextField
              style={{ maxWidth: 400 }}
              disabled={loading}
              select={true}
              name="log_lifetime_event_images_minutes"
              value={state.log_lifetime_event_images_minutes || ''}
              onChange={onChange}
              label={t('manage.account.LogStorage.deleteImagesFromEvents')}
            >
              <MenuItem value="30">30 {t('common.minutes', 30)}</MenuItem>
              <MenuItem value="120">2 {t('common.hours', 2)}</MenuItem>
              <MenuItem value="1440">24 {t('common.hours', 24)}</MenuItem>
              <MenuItem value="10080">1 {t('common.weeks', 1)}</MenuItem>
            </TextField>
          </FormControl>
        </FormGroup>
      </div>

      <div>
        <FormGroup className="LogStorage-input-wrapper">
          <FormControl>
            <TextField
              style={{ maxWidth: 400 }}
              disabled={loading}
              select={true}
              name="log_lifetime_change_logs_days"
              value={state.log_lifetime_change_logs_days || ''}
              onChange={onChange}
              label={t('manage.account.LogStorage.deleteChangeLogs')}
            >
              <MenuItem value="183">6 {t('common.months', 6)}</MenuItem>
              <MenuItem value="365">1 {t('common.years', 1)}</MenuItem>
              <MenuItem value="730">2 {t('common.years', 2)}</MenuItem>
              <MenuItem value="1825">5 {t('common.years', 5)}</MenuItem>
            </TextField>
          </FormControl>
        </FormGroup>
      </div>

      <div>
        <FormGroup className="LogStorage-input-wrapper">
          <FormControl>
            <TextField
              style={{ maxWidth: 400 }}
              disabled={loading}
              select={true}
              name="log_lifetime_care_logs_days"
              value={state.log_lifetime_care_logs_days || ''}
              onChange={onChange}
              label={t(
                'manage.account.LogStorage.deleteLogsForEventsAndOversight'
              )}
            >
              <MenuItem value="7">1 {t('common.weeks', 1)}</MenuItem>
              <MenuItem value="14">2 {t('common.weeks', 2)}</MenuItem>
              <MenuItem value="30">30 {t('common.days', 30)}</MenuItem>
              <MenuItem value="90">90 {t('common.days', 90)}</MenuItem>
            </TextField>
          </FormControl>
        </FormGroup>
      </div>

      <div>
        <FormGroup className="LogStorage-input-wrapper">
          <FormControl>
            <TextField
              style={{ maxWidth: 400 }}
              disabled={loading}
              select={true}
              name="log_lifetime_access_logs_days"
              value={state.log_lifetime_access_logs_days || ''}
              onChange={onChange}
              label={t('manage.account.LogStorage.deleteAccessLogs')}
            >
              <MenuItem value="7">1 {t('common.weeks', 1)}</MenuItem>
              <MenuItem value="14">2 {t('common.weeks', 2)}</MenuItem>
              <MenuItem value="30">30 {t('common.days', 30)}</MenuItem>
              <MenuItem value="90">90 {t('common.days', 90)}</MenuItem>
            </TextField>
          </FormControl>
        </FormGroup>
      </div>

      <ButtonGroup className="mt-3">
        <Button
          type="submit"
          color="primary"
          disabled={loading}
          icon={loading ? 'gear' : undefined}
          spinIcon={loading}
          onClick={onSubmit}
        >
          {t('common.commonButtons.save')}
        </Button>
        <Button
          onClick={() =>
            hc.handleCancel(dataChangeDetect.hasChanged(state), null, () =>
              dataChangeDetect.resetData()
            )
          }
        >
          {t('common.commonButtons.cancel')}
        </Button>
      </ButtonGroup>

      <ErrorMessages className="my-3" errorData={errorHandler} />
    </div>
  );
}
