import { t } from '../../../lib/i18n';

export default function getCareReceiverDisplayName(
  cr:
    | undefined
    | null
    | {
        id?: number | null;
        firstName?: string | null;
        lastName?: string | null;
        first_name?: string | null;
        last_name?: string | null;
      }
) {
  if (!cr?.id) {
    return undefined;
  }
  if (cr.firstName || cr.first_name || cr.lastName || cr.last_name) {
    return `${cr.firstName || cr.first_name || ''} ${
      cr.lastName || cr.last_name || ''
    }`.trim();
  }
  return t(
    'manage.sensors.getCareReceiverDisplayName.serviceRecipientWithoutName'
  );
}
