import React from 'react';
import cs from 'classnames';

import './Panel.scss';

interface Props {
  color?: 'success' | 'warning' | 'danger';
  children?: React.ReactNode;
  style?: React.CSSProperties;
  className?: string;
}

export function Panel(props: Props) {
  const color = props.color ?? 'success';
  return (
    <div
      style={props.style}
      className={cs('Panel', `Panel--${color}`, props.className)}
    >
      {props.children}
    </div>
  );
}
