import React, { useContext, useState } from 'react';
import { Snackbar, SnackbarContent } from '@material-ui/core';
import { useManageStyles } from '../areas/manage/useManageStyles';

export interface SnackbarContext {
  error: (text: string) => void;
  info: (text: string) => void;
}

const snackbarContext = React.createContext<SnackbarContext>({
  error: () => {},
  info: () => {},
});

export const useSnackbar = () => useContext(snackbarContext);

const { Provider } = snackbarContext;

export function SnackProvider(props: { children: React.ReactNode }) {
  const classes = useManageStyles();
  const [_error, setError] = useState({ show: false, message: '' });
  const [_info, setInfo] = useState({ show: false, message: '' });

  function info(text: string) {
    setInfo({
      show: true,
      message: text,
    });
  }

  function error(text: string) {
    setError({
      show: true,
      message: text,
    });
  }

  function closeInfo() {
    setInfo({ show: false, message: '' });
  }

  function closeError() {
    setError({ show: false, message: '' });
  }

  return (
    <Provider
      value={{
        info,
        error,
      }}
    >
      {props.children}

      <Snackbar
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        open={_error.show}
        autoHideDuration={6000}
        onClose={closeError}
      >
        <SnackbarContent
          className={classes['error']}
          aria-describedby="client-snackbar"
          message={
            <span id="client-snackbar">
              {/*<Icon className={clsx(classes.icon, classes.iconVariant)} />*/}
              {_error.message}
            </span>
          }
        />
      </Snackbar>

      <Snackbar
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        open={_info.show}
        autoHideDuration={6000}
        onClose={closeInfo}
      >
        <SnackbarContent
          className={classes['success']}
          aria-describedby="client-snackbar"
          message={
            <span id="client-snackbar">
              {/*<Icon className={clsx(classes.icon, classes.iconVariant)} />*/}
              {_info.message}
            </span>
          }
        />
      </Snackbar>
    </Provider>
  );
}

/*

interface SharedSensorContext {
  zone?: {
    id: number;
    name: string;
  };
  careReceiver?: {
    id: number;
    firstName: string | null;
    lastName: string | null;
  };
  eventTypes?: {
    [key: string]: {
      active: boolean;
      level: string; // hidden, info, alert, alarm
    };
  };
  reload?: () => void;
}

export interface OptionalSensorContext extends SharedSensorContext {
  id?: number;
  name?: string | null;
}

export interface SensorContext extends SharedSensorContext {
  id: number;
  name: string | null;
}

const sensorContext = React.createContext<OptionalSensorContext>({
  id: undefined,
  name: undefined,
});

const { Consumer, Provider } = sensorContext;

export const useOptionalSensorContext = () => useContext(sensorContext);

export function useSensorContext(): SensorContext {
  const context = useContext(sensorContext);
  if (context.id === undefined) {
    throw new Error('Invalid sensor context -- sensor not found.');
  }
  return context as SensorContext;
}

export { Consumer, Provider as SNContextProvider };
*/
