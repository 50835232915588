/**
 * These icons correspond to the values of sensors_roommate.user_status.
 */

import { IconDefinition } from './components/Icon';

export type UserStatusType =
  | 'uk'
  | 'uu'
  | 'us'
  | 'ul'
  | 'of'
  | 'lo'
  | 'ib'
  | 'nu'
  | 'mu'
  | 'ub'
  | 'ic'
  | 'ps';

export const userStatusTypes: {
  [user_status in UserStatusType]: {
    label: string;
    icon: IconDefinition | null;
  };
} = {
  uk: { label: 'Unknown', icon: 'person' },
  uu: { label: 'User upright', icon: 'person' },
  us: { label: 'User sitting', icon: 'person' },
  ul: { label: 'User laying', icon: 'person' },
  of: { label: 'On floor', icon: 'person' },
  lo: { label: 'Ukjent', icon: null },
  ib: { label: 'In bed', icon: 'bed-sleeping' },
  nu: { label: 'No users', icon: null },
  mu: { label: 'Multiple users', icon: 'persons' },
  ub: { label: 'Up in bed', icon: 'bed-sleeping' },
  ic: { label: 'In chair', icon: 'chair-sitting' },
  ps: { label: 'Presence', icon: 'person' },
};
