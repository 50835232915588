import { CSSProperties } from 'react';
import cs from 'classnames';

import { FrontendMarkingObjectType, MarkingObject } from '../types';

import { IconButton } from '../../../../../components/IconButton';
import { MarkingObjectControls } from './MarkingObjectControls';
import { useSensorContext } from '../../../../../lib/SensorContext';

import { AddNewMarkingObject } from './AddNewMarkingObject';
import { isEqual } from '../lib/markingObjectUtils';

import { SelectMarkingObject } from '../ui/SelectMarkingObject';
import { HelpFooter } from '../ui/HelpFooter';
import { RouteLeavingGuard } from '../../../../../components/RouteLeavingGuard';
import { useConfirmation } from '../../../../../lib/confirmation/ConfirmationContext';
import { ResetImageModal } from '../ui/ResetImageModal';
import { MarkingErrorHandler } from './MarkingErrorHandler';

import { t } from '../../../../../lib/i18n';

import './MarkingSidebar.scss';
import { ArgusControls } from '../argus/ArgusControls';
import { Feature } from '../../../../../components/Feature';

interface Props {
  className?: string;
  style?: CSSProperties;

  error?: any; // { [key: string]: string };

  /**
   * Path pushed to history when the "Back" button is clicked.
   */
  clickBackTarget?: string;

  /**
   * Event triggered when "Refresh image" button is clicked.
   */
  onRequestRefreshImage?: () => void;

  /**
   * Event triggered when "Reset image" button is clicked.
   */
  onRequestResetImage?: () => void;

  /**
   * Controls when the ResetImageModal is opened
   */
  showResetImageModal?: boolean;

  /**
   * Event triggered when user creates a new marking object of the given type.
   */
  onAddMarkingObject?: (objectType: FrontendMarkingObjectType) => void;

  /**
   * Event triggered when the user clicks delete on the marking object with the given index.
   */
  onDeleteMarkingObject?: (itemNum: number) => void;

  /**
   * An array of the currently stored marking objects
   */
  markingObjects?: MarkingObject[];

  /**
   * The item number of the currently selected/active marking object.
   */
  selectedMarkingObjectItemNum?: number;

  /**
   * Show a warning about the marking engine being simulated (i.e. RCTS not in use).
   */
  showSimulationWarning?: boolean;

  saveMarkingObject?: (itemNum: number) => void;
  resetMarkingObject?: (itemNum: number) => void;

  /**
   * Event triggered when the user selects a marking object (or unselects)
   */
  setSelectedMarkingObjectItemNum?: (itemNum: number | undefined) => void;

  onChangePointDiameter?: (
    markingObjItemNum: number,
    pointIndex: number,
    customDiameter: boolean,
    diameter: number | undefined
  ) => void;

  onTogglePointExtended?: (
    markingObjItemNum: number,
    pointIndex: number
  ) => void;

  sensorCompositeId?: string;
}

export function MarkingSidebar(props: Props) {
  const confirmation = useConfirmation();
  const sensorCtx = useSensorContext();

  const selectedMarkingObject =
    props.markingObjects && props.selectedMarkingObjectItemNum !== undefined
      ? props.markingObjects.find(
          (mo) => mo.item === props.selectedMarkingObjectItemNum
        )
      : undefined;

  const unsavedChanges =
    selectedMarkingObject &&
    (!selectedMarkingObject.original ||
      !isEqual(
        selectedMarkingObject.points,
        selectedMarkingObject.original.points
      ));

  const onSaveSelected = () => {
    if (
      props.selectedMarkingObjectItemNum !== undefined &&
      props.saveMarkingObject
    ) {
      props.saveMarkingObject(props.selectedMarkingObjectItemNum);
    }
  };

  const onSetSelectedMarkingObject = async (
    markingObjItemNum: number | undefined
  ) => {
    if (props.setSelectedMarkingObjectItemNum === undefined) {
      return;
    }

    if (unsavedChanges) {
      try {
        await confirmation.confirm(
          t(
            'manage.sensors.marking.components.MarkingSidebar.youHaveUnsavedChangesThatWillBeRejectedIfYouSelectAnotherSelectionDoYouWantToContinue'
          )
        );
        resetSelectedMarkingObject();
        props.setSelectedMarkingObjectItemNum(markingObjItemNum);
      } catch (e) {}
      return;
    }

    props.setSelectedMarkingObjectItemNum(markingObjItemNum);
  };

  const onAddNewMarkingObject = async (
    objectType: FrontendMarkingObjectType
  ) => {
    if (!props.onAddMarkingObject) {
      return;
    }

    if (unsavedChanges) {
      try {
        await confirmation.confirm(
          t(
            'manage.sensors.marking.components.MarkingSidebar.youHaveUnsavedChangesThatWillBeRejectedIfYouAddANewSelectionDoYouWantToContinue'
          )
        );
        resetSelectedMarkingObject();
        props.onAddMarkingObject(objectType);
      } catch (e) {}
      return;
    }

    props.onAddMarkingObject(objectType);
  };

  const resetSelectedMarkingObject = () => {
    if (
      props.selectedMarkingObjectItemNum !== undefined &&
      props.resetMarkingObject
    ) {
      props.resetMarkingObject(props.selectedMarkingObjectItemNum);
    }
  };

  const onCancelSelected = unsavedChanges
    ? resetSelectedMarkingObject
    : undefined;

  return (
    <div className={cs('MarkingSidebar', props.className)} style={props.style}>
      <RouteLeavingGuard when={!!unsavedChanges} />

      <div>
        <IconButton
          icon="arrow-left"
          color="default"
          to={props.clickBackTarget}
          disabled={!props.clickBackTarget}
        >
          {t('common.commonButtons.back')}
        </IconButton>
      </div>

      <div className="MarkingSidebar-info">
        <h2 className="MarkingSidebar-info-text">
          {sensorCtx.zone?.parent?.name}
        </h2>

        <h2 className="MarkingSidebar-info-text">{sensorCtx.zone?.name}</h2>

        {sensorCtx.name && (
          <h2 className="MarkingSidebar-info-text">{sensorCtx.name}</h2>
        )}
      </div>

      {/* <div>
        <IconButton
          icon="refresh"
          color="default"
          onClick={props.onRequestRefreshImage}
          disabled={!props.onRequestRefreshImage}
        >
          {t('manage.sensors.marking.components.MarkingSidebar.updateImage')}
        </IconButton>
      </div> */}

      <div>
        <IconButton
          icon="refresh"
          color="default"
          onClick={props.onRequestResetImage}
          disabled={!props.onRequestResetImage}
        >
          {t('manage.sensors.marking.components.MarkingSidebar.resetImage')}
        </IconButton>

        <ResetImageModal isOpen={!!props.showResetImageModal} />
      </div>

      <AddNewMarkingObject
        className="mt-5 mb-4"
        // disabled={unsavedChanges}
        onAdd={onAddNewMarkingObject}
        markingObjects={props.markingObjects}
      />

      <h3 className="MarkingSidebar-heading">
        {t(
          'manage.sensors.marking.components.MarkingSidebar.existingSelections'
        )}
      </h3>

      <SelectMarkingObject
        markingObjects={props.markingObjects}
        setSelectedMarkingObjectItemNum={onSetSelectedMarkingObject}
        selectedMarkingObjectItemNum={props.selectedMarkingObjectItemNum}
      />

      <MarkingErrorHandler error={props.error} />

      {props.selectedMarkingObjectItemNum !== undefined && (
        <MarkingObjectControls
          markingObject={selectedMarkingObject}
          selectedMarkingObjectItemNum={props.selectedMarkingObjectItemNum}
          onUnselectMarkingObject={() =>
            props.setSelectedMarkingObjectItemNum?.(undefined)
          }
          hasUnsavedChanges={unsavedChanges}
          onChangePointDiameter={props.onChangePointDiameter}
          onTogglePointExtended={props.onTogglePointExtended}
          onClickSave={onSaveSelected}
          onClickCancel={onCancelSelected}
          onClickDelete={() => {
            confirmation
              .confirm(t('common.commonTexts.toConfirmDeletionPressOk'))
              .then(() => {
                if (props.selectedMarkingObjectItemNum !== undefined) {
                  props.onDeleteMarkingObject?.(
                    props.selectedMarkingObjectItemNum
                  );
                  props.setSelectedMarkingObjectItemNum?.(undefined);
                }
              })
              .catch(() => {});
          }}
        />
      )}

      <Feature feature="show-argus-markings">
        <ArgusControls sensorCompositeId={props.sensorCompositeId} />
      </Feature>

      <HelpFooter
        className="MarkingSidebar-help"
        showSimulationWarning={props.showSimulationWarning}
      />
    </div>
  );
}
