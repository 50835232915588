import React from 'react';

import { Panel } from '../../../../../components/Panel';
import { t, TranslationKey } from '../../../../../lib/i18n';
import { useSensorContext } from '../../../../../lib/SensorContext';
import { MarkingObjectType } from '../../../../../serverApi';

interface Props {
  /**
   * Only show warning if the required marking type is missing.
   */
  onlyWarnWhenMissing?: boolean;

  /**
   * The marking object type for which to display a warning.
   */
  markingType: MarkingObjectType;
}

/**
 * Display a warning about this event type requiring a marking
 * of a specific to be set on the sensor.
 */
export function MissingMarkingWarningPanel(props: Props) {
  const sensorCtx = useSensorContext();

  if (
    props.onlyWarnWhenMissing &&
    sensorCtx.markingsDefined?.includes(props.markingType)
  ) {
    return null;
  }

  return (
    <Panel color="warning" className="mb-3">
      <p>
        {t('manage.sensors.MissingMarkingWarningPanel.warning')}{' '}
        <em>
          {t(
            `manage.sensors.marking.markingObjects.${props.markingType}` as TranslationKey
          )}
        </em>
      </p>
    </Panel>
  );
}
