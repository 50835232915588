import React from 'react';
import { debounce } from 'lodash';
import * as Sentry from '@sentry/browser';

import ModalWrapper from '../modals/ModalWrapper';

import { UserSelectorUser } from './UserSelector';

import { Checkbox, FormControlLabel } from '@material-ui/core';
import { map, memoize } from 'lodash';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimesCircle } from '@fortawesome/pro-light-svg-icons';
import LoadingPlaceholder from '../../lib/ui/LoadingPlaceholder';
import FilterInput from '../FilterInput/FilterInput';
import formatUserLabel from '../../areas/manage/permissions/lib/formatUserLabel';
import { t } from '../../lib/i18n';

import './ItemPicker.scss';

export interface Props {
  /**
   * An array of the items initially selected. If the availableItems does not contain
   * the initially selected items, the behaviour is undefined.
   */
  initialSelectedItems?: UserSelectorUser[];

  /**
   * The heading shown at the top of the modal.
   */
  heading: string;

  /**
   * All items available for selection.
   */
  availableItems?: UserSelectorUser[];

  /**
   * The text to display in place of the items when no items are selected.
   */
  selectedItemsPlaceholder?: string;

  /**
   * Whether the modal should show a loading indicator. If this is true, the modal
   * will expect availableItems and/or initialSelectedItems to change.
   */
  loading?: boolean;

  /**
   * Indicator for submit loading; i.e. the modal is loading after submit has been pressed.
   */
  submitLoading?: boolean;

  /**
   * The placeholder text to display in the filter text field.
   */
  filterPlaceholder?: string;

  /**
   * Whether to close the modal when saving/submitting. Default true.
   */
  toggleOnSave?: boolean;

  /**
   * Whether only a single item can be selected at a time.
   */
  singleSelect?: boolean;

  /**
   * This is called when the user clicks submit/save.
   */
  onSave: (selectedItems: UserSelectorUser[]) => void;

  /**
   * Whether the modal is open.
   */
  isOpen?: boolean;

  /**
   * Toggle the modal open state.
   */
  toggleState: () => void;

  searchFunction?: (query: string) => Promise<UserSelectorUser[]>;
}

export interface State {
  fetching: boolean;

  /**
   * The items currently selected.
   */
  selectedItems: UserSelectorUser[];

  /**
   * The current filter query and contents of the filter text field.
   */
  filter: string;

  showOnlyImpersonal: boolean;

  searchData: UserSelectorUser[];
}

export default class ItemPicker extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      fetching: false,
      filter: '',
      showOnlyImpersonal: true,
      searchData: [],
      selectedItems: this.props.initialSelectedItems || [],
    };
  }

  componentDidUpdate(prevProps: Props) {
    if (this.props.isOpen === true && prevProps.isOpen === false) {
      this.setState({
        filter: '',
        selectedItems: this.props.initialSelectedItems || [],
      });
    }
  }

  componentDidMount() {
    this.fetchSearch('');
  }

  handleSave = () => {
    this.props.onSave(this.state.selectedItems || []);
    if (this.props.toggleOnSave === undefined || this.props.toggleOnSave) {
      this.props.toggleState();
    }
  };

  fetchSearch = debounce(async (query: string) => {
    if (this.props.searchFunction) {
      try {
        const res = await this.props.searchFunction(query);
        this.setState({
          searchData: res,
          fetching: false,
        });
      } catch (e) {
        Sentry.captureException(e);
        this.setState({
          ...this.state,
          fetching: false,
        });
      }
    }
  }, 150);

  onFilterChange = (filter: string) => {
    this.setState({ ...this.state, fetching: true });
    this.fetchSearch(filter);
    this.setState({ filter });
  };

  onImpersonalChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    this.setState({
      ...this.state,
      showOnlyImpersonal: e.target.checked,
    });
  };

  select = (item: UserSelectorUser) => {
    if (this.props.singleSelect) {
      return this.setState({ selectedItems: [item] });
    } else {
      this.setState((prevState) => ({
        selectedItems: [...prevState.selectedItems, item],
      }));
    }
  };

  unselect = (item: UserSelectorUser) =>
    this.setState((prevState) => ({
      // If we are removing a new item, we match by label instead
      selectedItems: prevState.selectedItems.filter((i) => i.id !== item.id),
    }));

  selectHandler = memoize((item: UserSelectorUser) => () => this.select(item));

  unselectHandler = memoize(
    (item: UserSelectorUser) => () => this.unselect(item)
  );
  render() {
    const props = this.props;

    const filtered = this.state.showOnlyImpersonal
      ? this.state.searchData.filter((e) => e.impersonal_user)
      : this.state.searchData;

    const visibleItems = filtered.filter(
      (item) =>
        this.state.selectedItems.findIndex((i) => i.id === item.id) === -1
    );

    return (
      <ModalWrapper
        size="lg"
        isOpen={!!this.props.isOpen}
        onHide={() => this.props.toggleState()}
        onSubmit={() => this.handleSave()}
        okButtonText={t('common.commonButtons.save')}
        className="ItemPicker"
      >
        <div className="ItemPickerBody">
          {this.props.loading || props.submitLoading ? (
            <LoadingPlaceholder />
          ) : (
            <>
              <div className="ItemPickerBody-left">
                <h2>{t('components.ItemPicker.receivers')}</h2>
                <div className="ItemPickerBody-selected-items">
                  {
                    this.state.selectedItems &&
                    this.state.selectedItems.length > 0
                      ? map(this.state.selectedItems, (item, ix) => (
                          <div
                            className="ItemPickerBody-selected-item"
                            key={ix}
                          >
                            <span
                              role="button"
                              className="ItemPickerBody-selected-item-close"
                              onClick={this.unselectHandler(item)}
                            >
                              <FontAwesomeIcon icon={faTimesCircle} />
                            </span>
                            <span className="ItemPickerBody-selected-item-label">
                              {formatUserLabel(item)}
                            </span>
                          </div>
                        ))
                      : null // here we can show a message "No selected items or something"
                  }
                </div>
              </div>

              <div className="ItemPickerBody-right">
                <h2>{t('components.ItemPicker.select')}</h2>

                <FilterInput
                  loading={this.state.fetching}
                  placeholder={t('common.commonInputs.name')}
                  className="w-100 mb-0"
                  onChange={(name, value) => this.onFilterChange(value)}
                />
                <div className="ItemPickerBody-search-results">
                  {visibleItems.length > 0 ? (
                    <>
                      {visibleItems.map((item: UserSelectorUser) => (
                        <div
                          key={item.id}
                          role="button"
                          className="ItemPickerBody-search-results-item"
                          onClick={this.selectHandler(item)}
                        >
                          {item.user_roles && item.user_roles.length ? (
                            <div>
                              {item.user_roles.length > 1
                                ? t(
                                    'manage.permissions.UserOverview.multipleRoles'
                                  )
                                : item.user_roles[0].zone?.name}
                            </div>
                          ) : null}
                          <div>{formatUserLabel(item)}</div>
                        </div>
                      ))}
                    </>
                  ) : (
                    <div className="ItemPickerBody-search-results-placeholder">
                      {t('common.commonTexts.noItemsInTheList')}
                    </div>
                  )}
                </div>

                <FormControlLabel
                  className="ItemPickerBody-user-type-checkbox"
                  value="impersonal"
                  control={
                    <Checkbox
                      checked={this.state.showOnlyImpersonal}
                      onChange={(e) => this.onImpersonalChange(e)}
                      value="impersonal"
                      color="primary"
                    />
                  }
                  label={t('components.ItemPicker.showOnlyWatchPhones')}
                />
              </div>
            </>
          )}
        </div>
      </ModalWrapper>
    );
  }
}
