import { ReactNode } from 'react';

interface Props {
  tz?: string | null;
  children?: ReactNode;
}

/**
 * Only render the children if the timezone is different from the user's.
 * If the browser doesn't support Intl, don't render the children.
 */
export function WhenTimezoneDiffers(props: Props) {
  if (!props.tz) {
    return null;
  }

  const browserTimeZone = Intl?.DateTimeFormat()?.resolvedOptions()?.timeZone;

  // Possible fallback for browsers that don't support Intl?
  // const browserTimeOffset = new Date().getTimezoneOffset();

  if (!browserTimeZone || browserTimeZone === props.tz) {
    return null;
  }

  return <>{props.children}</>;
}
