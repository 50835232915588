import { useEffect, useRef, useState } from 'react';
import qs from 'query-string';

import { useAppContext } from '../../../lib/global';
import * as api from '../../../serverApi';
import { parseSseEventData } from '../../care/lib/parseSseEventData';
import { evaluateDashboardOverviewZone } from './evaluateDashboardOverviewZone';
import { useCountdown } from './useCountdown';

interface Opts {
  skip?: boolean;
}

export const useDashboardSseEvents = (opts: Opts) => {
  /**
   * Keep track of the last time we received data from the SSE connection
   */
  const lastDataReceivedAt = useRef<number>(Date.now());

  /**
   * Keep track of whether we believe the connection to be healthy
   */
  const believeToBeHealthy = useRef<boolean>(true);

  /**
   * The SSE EventSource object
   */
  const sse = useRef<EventSource | null>(null);

  const app = useAppContext();

  const [overview, setOverview] = useState<api.DashboardOverviewZoneData[]>([]);
  const [eventCounter, setEventCounter] =
    useState<api.DashboardEventsLastPeriodResult>();
  const [userStatus, setUserStatus] = useState<Record<string, string>>({});

  const [loadingOverview, setLoadingOverview] = useState(true);
  const [loadingEventCounter, setLoadingEventCounter] = useState(true);

  const [error, setError] = useState(false);
  const [retries, setRetries] = useState(0); // Keep track of retries

  const { secondsRemaining, startCountdown } = useCountdown();

  const retry = () => {
    setRetries((prev) => prev + 1); // Retry function; used to reinitialize SSE connection
  };

  /**
   * Track the timer and retry when it ends
   * Timer is started when the SSE connection is interrupted
   */
  useEffect(() => {
    if (secondsRemaining === undefined) {
      return;
    }

    if (secondsRemaining === 0) {
      retry();
    }
  }, [secondsRemaining]);

  useEffect(() => {
    //Skip creating event source if skip option is true
    if (opts.skip) {
      return;
    }

    /**
     * Get streaming intervals from user feature flags and parse into query string
     */

    const structureInterval = app.getFeatureValueNumber(
      'interval-dashboard-structure'
    );
    const eventsInterval = app.getFeatureValueNumber(
      'interval-dashboard-events'
    );
    const queryParams = qs.stringify({
      zone_id: app.user?.active_role?.zone?.zone_id,
      structureinterval: structureInterval,
      eventsinterval: eventsInterval,
    });

    const rootURL = api.getApiRoot();
    const endpoint = `${rootURL}/api/stream/dashboard?${queryParams}`;
    sse.current = new EventSource(endpoint, { withCredentials: true });
    believeToBeHealthy.current = true;
    setError(false);
    sse.current.addEventListener('overview', (event) => {
      lastDataReceivedAt.current = Date.now();
      const data = parseSseEventData(event);
      if (loadingOverview) {
        setLoadingOverview(false);
      }
      setOverview(data ?? []); //If overview data is null, fallback to an empty array.
    });

    sse.current.addEventListener('eventcounter', (event) => {
      lastDataReceivedAt.current = Date.now();
      const data = parseSseEventData(event);
      if (loadingEventCounter) {
        setLoadingEventCounter(false);
      }
      setEventCounter(data);
    });

    sse.current.addEventListener('userstatus', (event) => {
      lastDataReceivedAt.current = Date.now();
      const data = parseSseEventData(event);
      setUserStatus(data);
    });

    sse.current.onerror = (event) => {
      console.error('Dashboard SSE error', event);
      believeToBeHealthy.current = false;
      setError(true);
      const counter = Math.min(10 * (Math.pow(2, retries) - 1), 120);
      startCountdown(counter); // Start timer that will try to restart stream when it ends
      setOverview([]);
      setEventCounter(undefined);
      setLoadingOverview(false);
      setLoadingEventCounter(false);
      if (sse.current) {
        sse.current.close();
      }
    };

    return () => {
      setOverview([]);
      setEventCounter(undefined);
      setLoadingEventCounter(false);
      setLoadingOverview(false);
      if (sse.current) {
        sse.current.close();
      }
    };

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [retries]);

  /**
   * Check if we have received data from the SSE connection recently
   * so we can assume that the connection is down even if we haven't
   * received an error event.
   */
  useEffect(() => {
    const iv = setInterval(() => {
      /**
       * If we currently don't believe the connection to healthy,
       * do nothing.
       */
      if (!believeToBeHealthy.current) {
        return;
      }

      const now = Date.now();
      const diff = now - lastDataReceivedAt.current;

      /**
       * If we haven't received data for 60 seconds, assume the connection is down
       */
      const noDataThresholdInMs = 60 * 1000;

      if (diff > noDataThresholdInMs) {
        if (sse.current?.onerror) {
          sse.current.onerror(new Event('No data received from SSE'));
        }
      }
    }, 1000);

    return () => {
      clearInterval(iv);
    };
  }, []);

  //Update overview data with the latest user status event from source
  const updatedOverview = overview.map((ov) => {
    const updatedSensors = ov.sensors.map((sensor) => {
      if (userStatus[sensor.sensor_composite_id]) {
        return {
          ...sensor,
          user_status: userStatus[sensor.sensor_composite_id],
        };
      }
      return sensor;
    });
    return { ...ov, sensors: updatedSensors };
  });
  return {
    overview: updatedOverview.map(evaluateDashboardOverviewZone),
    eventCounter,
    loadingOverview,
    loadingEventCounter,
    error,
    timeUntilNextRetry: secondsRemaining,
  };
};
