import React, { useState, useEffect } from 'react';
import Select from 'react-select';
import ModalWrapper from '../../ModalWrapper';
import * as api from '../../../../serverApi';
import { t } from '../../../../lib/i18n';
import { useErrorHandler } from '../../../../lib/useErrorHandler';
import { ErrorMessages } from '../../../ErrorMessages';

export interface SelectAccountProps {
  isOpen: boolean;
  sensorId: string;

  accountId: number | null;
  accounts: Array<api.Account>;
  accountsLoading?: boolean;

  handleModal: () => void;
  onAccountSet: () => void;
  onSubmit?: (account: number) => Promise<any>;
}

export default function SensorSetAccountModal(props: SelectAccountProps) {
  const errorHandler = useErrorHandler();
  const [loading, setLoading] = useState(false);

  const [selectedAccount, setSelectedAccount] = useState<number | undefined>(
    undefined
  );

  //Set initial selected account
  useEffect(() => {
    const found = props?.accounts?.find(
      (a: api.Account) => a.account_id === props.accountId
    );
    if (!found) {
      return;
    }
    setSelectedAccount(found.account_id);
  }, [props.accounts, props.accountId, props.isOpen]);

  const handleSubmit = async () => {
    if (!selectedAccount) {
      return;
    }

    try {
      setLoading(true);
      await props.onSubmit?.(selectedAccount);
      setLoading(false);
      props.onAccountSet();
      props.handleModal();
    } catch (e) {
      errorHandler.handleError(e);
      setLoading(false);
    }
  };

  const handleResetModal = () => {
    setSelectedAccount(undefined);
    errorHandler.reset();
  };

  return (
    <ModalWrapper
      title={t('components.SensorSetAccountModal.selectAccount')}
      okButtonText={t('common.commonButtons.select')}
      cancelButtonText={t('common.commonButtons.cancel')}
      isOpen={props.isOpen}
      loading={loading}
      onHide={() => props.handleModal()}
      onSubmit={handleSubmit}
      onResetModal={handleResetModal}
    >
      <ErrorMessages errorData={errorHandler} className="mb-2" />
      <div>
        <Select
          isClearable
          placeholder={t('common.commonInputs.select')}
          isDisabled={props.accountsLoading}
          isLoading={props.accountsLoading}
          options={props.accounts}
          value={props.accounts?.find((f) => f.account_id === selectedAccount)}
          getOptionLabel={(data: api.Account) => data['name']}
          getOptionValue={(data: api.Account) => data['account_id'].toString()}
          onChange={(selectedOption: any) =>
            setSelectedAccount(selectedOption?.account_id)
          }
        />
      </div>
    </ModalWrapper>
  );
}
