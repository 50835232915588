import useInterval from '../areas/care/lib/useInterval';
import { useApiCall } from './useApiCall';
import { getUnassignedSensorsAvailableForUser } from '../serverApi';

interface Opts {
  timeout?: number;
}

export function useFetchUnassignedClimaxSensors(opts?: Opts) {
  const status = useApiCall(getUnassignedSensorsAvailableForUser);

  useInterval(() => {
    status.reload();
  }, opts?.timeout ?? 1000);

  return status;
}
