import React, { useEffect, useState } from 'react';
import { useAppContext } from '../../lib/global';
import { RouteComponentProps, Switch, Route, Redirect } from 'react-router-dom';
import { Header } from '../../components/Header/Header';
import { ManageSidebar } from './common/ManageSidebar';
import { ZoneArea } from './ZoneArea';
import { ZoneContextProvider } from '../../lib/ZoneContext';
import { ManageContextProvider } from './ManageContextProvider';
import {
  useZoneTreeQuery,
  ZoneTreeContextProvider,
} from '../care/lib/ZoneTreeContext';

import AccountSettings from './account/AccountSettings';

import './Manage.scss';
import { CenterPanel } from './common/CenterPanel';
import { InlineSpinner } from '../../components/InlineSpinner';
import { DesktopSplashPage } from './common/DesktopSplashPage';
import { ManageHelpPage } from './common/ManageHelpPage';
import { t } from '../../lib/i18n';
import { useScrollToTop } from '../../lib/useScrollToTop';
import { GetSensorsResult, getZoneSettings } from '../../serverApi';
import { useAlertUserForIdleTimeout } from '../../lib/useAlertUserForIdleTimeout';

export function Manage(props: RouteComponentProps<{ zoneId?: string }>) {
  const [
    activeZoneSettings,
    setActiveZoneSettings,
  ] = useState<GetSensorsResult | null>(null);

  useEffect(() => {
    //First we reset the active zone settings
    setActiveZoneSettings(null);
    //Immediately-invoked function to get the active zone settings
    (async function () {
      if (!props.match.params.zoneId) {
        return;
      }
      try {
        const res = await getZoneSettings(
          parseInt(props.match.params.zoneId, 10)
        );
        setActiveZoneSettings(res);
      } catch (e) {}
    })();
  }, [props.match.params.zoneId]);

  useScrollToTop();
  const app = useAppContext();
  const notifyPeriod =
    app.getFeatureValueNumber('idle-timeout-notify-time') ?? 60;
  useAlertUserForIdleTimeout(notifyPeriod);

  const tree = useZoneTreeQuery();

  if (!app.user) {
    return null;
  }

  if (!app.hasCapability('manageZones')) {
    return <Redirect to="/" />;
  }

  if (tree.loading) {
    return (
      <CenterPanel>
        <InlineSpinner
          size="md"
          text={t('common.commonTexts.loading')}
          textStyle={{ fontWeight: 500, fontSize: 22 }}
        />
      </CenterPanel>
    );
  }

  if (!tree.root) {
    return null;
  }

  const zoneContext = {
    rootZone: tree.root,
    activeZone: props.match.params.zoneId
      ? tree.getZoneById(parseInt(props.match.params.zoneId, 10))
      : undefined,
    activeZoneSettings,
    setActiveZoneSettings,
    reload: tree.reload,
  };

  return (
    <div className="App" id="app">
      <div className="App-body" id="appBody">
        <ZoneTreeContextProvider value={tree}>
          <ZoneContextProvider value={zoneContext}>
            <ManageContextProvider>
              <Header mode="manage" className="App-header" />
              <main className="App-main Manage">
                <ManageSidebar className="Manage-round-container" />

                {zoneContext.activeZone ? (
                  <ZoneArea className="Manage-main" />
                ) : (
                  <Switch>
                    <Route path="/manage/account" component={AccountSettings} />
                    <Route path="/manage/help" component={ManageHelpPage} />
                    <Route exact path="/manage" component={DesktopSplashPage} />
                  </Switch>
                )}
              </main>
            </ManageContextProvider>
          </ZoneContextProvider>
        </ZoneTreeContextProvider>
      </div>
    </div>
  );
}
