import React, { CSSProperties } from 'react';
import { IconDefinition, Icon } from '../../components/Icon';

type Status = 'success' | 'warning' | 'danger';

interface Props {
  status?: Status;
  className?: string;
  label?: string;
  style?: CSSProperties;
}

export function StatusMarker(props: Props) {
  let color: string | undefined = undefined;
  let icon: IconDefinition = 'info';

  switch (props.status) {
    case 'success':
      icon = 'check';
      color = '#84b93c';
      break;
    case 'warning':
      icon = 'cross';
      color = '#e6a41a';
      break;
    case 'danger':
      icon = 'cross';
      color = '#c9533d';
      break;
  }

  return (
    <>
      <Icon
        icon={icon}
        className="StatusMarker"
        style={{ fill: color, width: '28px', height: '28px', ...props.style }}
      />{' '}
      {props.label}
    </>
  );
}
