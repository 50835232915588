import { useState } from 'react';
import cn from 'classnames';
import * as Sentry from '@sentry/browser';

import { UserFormik } from '../lib/useUsersFormik';
import { useConfirmation } from '../../../../lib/confirmation/ConfirmationContext';
import * as api from '../../../../serverApi';
import { UserFormRolesTable } from './UserFormRolesTable';
import { useApiCall } from '../../../../lib/useApiCall';

import './UserFormRoles.scss';
interface Props {
  disabled?: boolean;
  userId?: number;
  formik: UserFormik;
  readOnly?: boolean;
}

export const UserFormRoles = (props: Props) => {
  const confirmation = useConfirmation();
  const [disabled, setDisabled] = useState<boolean>(false);
  const [selectedRole, setSelectedRole] = useState<string>('');

  const userResponse = useApiCall(
    api.superGetUserDetails,
    [props.userId ?? -1],
    { skip: props.userId === undefined }
  );

  const user = userResponse?.data;

  const handleDelete = async (index: number) => {
    if (!user || !user.user_roles) {
      return;
    }

    // Get role from index
    const user_role_id = user.user_roles[index].user_role_id;

    try {
      await confirmation.confirm();
      setDisabled(true);
      await api.superDeleteUserRole(user_role_id);
      userResponse.reload();
    } catch (e) {
      Sentry.captureException(e);
    } finally {
      setDisabled(false);
    }
  };
  const handleSubmit = async () => {
    if (!selectedRole || !user) {
      return;
    }

    try {
      setDisabled(true);
      await api.superCreateUserRole({
        user_id: user.user_id,
        role: selectedRole,
      });
      setSelectedRole('');
      userResponse.reload();
    } catch (e) {
      Sentry.captureException(e);
    } finally {
      setDisabled(false);
    }
  };

  return (
    <div
      className={cn(
        'UserFormRoles',
        props.disabled && 'UserFormRoles--disabled'
      )}
    >
      {userResponse.loading ? (
        <p>Loading</p>
      ) : (
        <UserFormRolesTable
          disabled={disabled || props.readOnly}
          handleDelete={handleDelete}
          handleSubmit={handleSubmit}
          roles={user?.user_roles ?? undefined}
          selectedRole={selectedRole}
          setSelectedRole={setSelectedRole}
          buttonLabel="Submit"
        />
      )}
    </div>
  );
};
