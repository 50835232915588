import { FormikHelpers, useFormik } from 'formik';
import * as yup from 'yup';

export interface SsoProviderFields {
  client_id: string;
  client_secret: string;
  azure_tenant_id: string;
  gitlab: string;
  user_matching_strategy: 'username' | 'email' | 'none';
  idp_provider: string;
}

export type SsoProviderFromik = ReturnType<typeof useSsoProviderFormik>;

interface UseAccountFormOptions {
  initialValues?: Partial<SsoProviderFields>;
  validationSchema?: any | (() => any);
  onSubmit: (
    values: SsoProviderFields,
    formikHelpers: FormikHelpers<SsoProviderFields>
  ) => Promise<any>;
}

const SsoProviderFormikSchema = yup.object().shape({
  client_id: yup.string().required('Client ID is required!'),
  client_secret: yup.string().required('Client secret is required!'),
  idp_provider: yup.string(),
  user_matching_strategy: yup.string(),
  azure_tenant_id: yup.string().when('idp_provider', {
    is: (v: string) => v === 'azure',
    then: yup.string().required('Azure tenant ID is required!'),
    otherwise: yup.string(),
  }),
  gitlab: yup.string().when('idp_provider', {
    is: (v: string) => v === 'gitlab',
    then: yup.string().required('Gitlab id is required!'),
    otherwise: yup.string(),
  }),
});

/**
 * Formik wrapper for super account form.
 */

export function useSsoProviderFormik(opts: UseAccountFormOptions) {
  return useFormik<SsoProviderFields>({
    initialValues: {
      client_id: '',
      client_secret: '',
      azure_tenant_id: '',
      user_matching_strategy: 'none',
      gitlab: '',
      idp_provider: '',
      ...opts.initialValues,
    },
    validationSchema: SsoProviderFormikSchema,
    validateOnChange: true,
    validateOnBlur: true,
    enableReinitialize: true,

    onSubmit: async (values, formikHelpers) => {
      await opts.onSubmit(values, formikHelpers);
    },
  });
}
