import React, { useEffect } from 'react';
import { useRouter } from '../../../lib/useRouter';
import ModalWrapper from '../ModalWrapper';
import { t } from '../../../lib/i18n';

import './ConfirmationModal.scss';

export interface Props {
  showCloseButton?: boolean;
  confirmationData: {
    text?: string;
    isOpen: boolean;
    isLoading?: boolean;
    onConfirm: () => void;
    onCancel: () => void;
  };
}

export default function ConfirmationModal(props: Props) {
  const router = useRouter();

  useEffect(() => {
    cancel();
  }, [router.location.pathname]); // eslint-disable-line react-hooks/exhaustive-deps

  function confirm() {
    if (props.confirmationData.onConfirm) {
      props.confirmationData.onConfirm();
    }
  }

  function cancel() {
    if (props.confirmationData.onCancel) {
      props.confirmationData.onCancel();
    }
  }

  return (
    <ModalWrapper
      okButtonText={t('common.commonButtons.ok')}
      cancelButtonText={t('common.commonButtons.cancel')}
      className="Confirmation"
      showCloseButton={false}
      isOpen={props.confirmationData.isOpen}
      loading={props.confirmationData.isLoading}
      onHide={() => cancel()}
      onSubmit={confirm}
    >
      <h4 className="Confirmation-text">{props.confirmationData.text}</h4>
    </ModalWrapper>
  );
}
