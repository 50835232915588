import React from 'react';
import ModalWrapper from '../ModalWrapper';
import { StructureZone, updateZone } from '../../../serverApi';
import { TextField, FormGroup } from '@material-ui/core';
import {
  errorIsObjectError,
  useErrorHandler,
} from '../../../lib/useErrorHandler';
import { ErrorMessages } from '../../ErrorMessages';
import { RouteLeavingGuard } from '../../RouteLeavingGuard';
import { useZoneFormik } from './useZoneFormik';
import { useConfirmation } from '../../../lib/confirmation/ConfirmationContext';
import { t } from '../../../lib/i18n';
import { generateAutoCompleteValue } from '../../../lib/utils/generateAutoCompleteValue';
import { useZoneContext } from '../../../lib/ZoneContext';
import { getZoneAncestors } from '../../../areas/manage/zone/settings/tabs/lib/getZoneAncestors';

export interface UpdateZoneProps {
  isOpen: boolean;
  activeZone: StructureZone;
  handleModal: (property: string) => void;
  updateTree: () => void;
}

export default function UpdateZoneModal(props: UpdateZoneProps) {
  const errorHandler = useErrorHandler();
  const confirmation = useConfirmation();
  const ctx = useZoneContext();

  const formik = useZoneFormik({
    initialValues: {
      name: '',
    },
    onSubmit: async (values, helpers) => {
      const zoneId = props.activeZone.id;

      const ancestors = getZoneAncestors(ctx.rootZone, ctx.activeZone);
      const atleastOneAncestorIsApiLocked = ancestors.some(
        (zone) => zone.api_lock
      );

      if (!zoneId || atleastOneAncestorIsApiLocked) {
        return;
      }

      errorHandler.error && errorHandler.reset();

      try {
        await updateZone(zoneId, {
          name: values.name,
        });
        helpers.resetForm();
        props.updateTree();
        props.handleModal('updateModal');
      } catch (error) {
        errorHandler.handleError(error);
      }
    },
  });

  const handleOnReset = () => {
    errorHandler.reset();
    formik.resetForm({ values: { name: props.activeZone.name } });
  };

  const handleOnHide = (name: string) => {
    if (name === props.activeZone.name) {
      props.handleModal('updateModal');
    } else {
      confirmation
        .confirm(
          t('common.commonTexts.unSavedChangesHaveBeenMadeDoYouWantToContinue')
        )
        .then(() => {
          props.handleModal('updateModal');
        })
        .catch(() => {});
    }
  };

  const getNameError = () => {
    const errors = formik.errors;
    const touched = formik.touched;

    if (!errorHandler.error && !errors) {
      return undefined;
    }

    if (touched['name'] && errors['name']) {
      return errors['name'];
    }

    return (
      (errorIsObjectError(errorHandler.error) && errorHandler.error.name) ||
      undefined
    );
  };

  const error = getNameError();

  return (
    <ModalWrapper
      title={
        props.activeZone.is_housing_unit
          ? `${t('components.UpdateZoneModal.updateHousingUnit')}: ${
              props.activeZone.name
            }`
          : `${t('components.UpdateZoneModal.updateZone')}: ${
              props.activeZone.name
            }`
      }
      okButtonText={t('common.commonButtons.save')}
      cancelButtonText={t('common.commonButtons.cancel')}
      isOpen={props.isOpen}
      loading={formik.isSubmitting}
      onHide={() => handleOnHide(formik.values.name)}
      onResetModal={handleOnReset}
      onSubmit={formik.submitForm}
    >
      <RouteLeavingGuard when={formik.dirty} />

      <div className="name-wrapper">
        <FormGroup>
          <TextField
            autoComplete={generateAutoCompleteValue()}
            type="text"
            name="name"
            label={t('common.commonInputs.name')}
            value={formik.values.name}
            error={!!error}
            helperText={error}
            onKeyPress={(e) => {
              if (e.key === 'Enter') {
                e.preventDefault();
                e.stopPropagation();
                formik.submitForm();
              }
            }}
            onBlur={formik.handleBlur}
            onChange={formik.handleChange}
          />
        </FormGroup>
        <ErrorMessages className="my-2" errorData={errorHandler} />
      </div>
    </ModalWrapper>
  );
}
