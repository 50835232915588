import React from 'react';
import { useSSE } from 'react-hooks-sse';
import { useManageStyles } from '../../useManageStyles';
import * as api from '../../../../serverApi';

import { Button } from '../../../../components/Button';
import { useRouter } from '../../../../lib/useRouter';
import { StatusMarker } from '../../../../lib/ui/StatusMarker';
import getCareReceiverDisplayName from '../getCareReceiverDisplayName';
import AlertIconsForSensor from '../AlertIconsForSensor';
import { SortableTable } from '../../../../components/SortableTable';
import { SensorContextProvider } from '../../../../lib/SensorContext';
import { t, TranslationKey } from '../../../../lib/i18n';
import { Icon } from '../../../../components/Icon';
import { WifiStrength } from './WifiStrength';
import { SensorWifiStrengthSSEProvider } from '../SensorWifiStrengthSSEProvider';
import { Tooltip } from '../../../../components/Tooltip/Tooltip';
import { useAppContext } from '../../../../lib/global';

import './SensorsTable.scss';

interface Props {
  sensors?: api.GetSensorsForZoneResult['sensors'];
  className?: string;
  style?: React.CSSProperties;
  loading?: boolean;
}

interface ConsumerProps {
  sensorId: string;
}

export const SensorsTable = (props: Props) => {
  const classes = useManageStyles();
  const router = useRouter();
  const app = useAppContext();
  const sensors = props.sensors || [];

  // prettier-ignore
  const columns = [
    { header: t('common.commonTexts.active'), key: 'enabled', sortable: true },
    { header: t('manage.sensors.ui.SensorsTable.zoneHousingUnitSensor'), key: 'sone_boenhet_sensor', sortable: true},
    { header: t('common.commonTexts.theRecipient'), key: 'care_receiver', sortable: true },
    { header: t('manage.sensors.ui.SensorsTable.serialNo'),  key: 'serial', sortable: true },
    { header: t('common.commonTexts.sensor'), key: 'sensor', sortable: false, },
    { header: t('manage.sensors.ui.SensorsTable.marking'), key: 'markering', sortable: false, },
    { header: t('manage.sensors.ui.SensorsTable.activeAlerts'), key: 'events', sortable: false, },
    { header: t('manage.sensors.ui.SensorsTable.notifications'), key: 'action' },
  ];

  if (app.hasFeature('enable-wifi-column')) {
    columns.push({
      header: t('manage.sensors.ui.SensorsTable.wifi'),
      key: 'wifi-strength',
    });
  }

  const rows = sensors.map((sensor) => {
    const offline = sensor.sensor_type === 'roommate' && !sensor.is_online;

    const name = sensor.zone
      ? `${sensor.zone.parent?.name ? `${sensor.zone.parent.name},` : ''} ${
          sensor.zone.name
        }${sensor?.name ? ',' : ''} ${sensor?.name || ''}`
      : `${sensor?.name || ''}`;

    return {
      _className: offline ? 'offline' : null,
      _colSpan: name
        ? null
        : {
            column: 'sone_boenhet_sensor',
            value: 2,
            exclude: ['care_receiver'],
          },

      // data
      enabled: (
        <StatusMarker
          data-sort={sensor.is_enabled}
          status={sensor.is_enabled ? 'success' : 'danger'}
        />
      ),
      sone_boenhet_sensor: (
        <span data-sort={name || ''}>
          {name || (
            <em style={{ color: '#666' }}>
              {t('manage.sensors.ListSensors.noZoneAssignment')}
            </em>
          )}
        </span>
      ),
      care_receiver: getCareReceiverDisplayName(sensor.care_receiver),
      serial: (
        <span data-sort={sensor.sensor_id}>
          {t(`common.commonSensors.${sensor.sensor_type}` as TranslationKey)}, #
          {sensor.sensor_id} {offline ? <Icon icon="disconnected" /> : null}
        </span>
      ),
      sensor: (
        <Button
          small
          light
          color="primary"
          icon="gear"
          className="mr-2"
          to={`${router.location.pathname}/${sensor.id}`}
          disabled={!sensor.zone}
        >
          {t('common.commonButtons.show')}
        </Button>
      ),
      markering:
        sensor.id.indexOf('roommate') === 0 ? (
          <div className="d-flex align-items-center">
            <Button
              small
              light
              color="primary"
              icon="gear"
              className="mr-1"
              disabled={offline || !sensor.zone || !sensor.is_enabled}
              hideLabel
              to={`${router.location.pathname}/${sensor.id}/marking`}
            >
              {t('manage.sensors.ui.SensorsTable.showSelection')}
            </Button>

            {app.hasFeature('show-argus-management') && sensor.argus_enabled ? (
              <Tooltip
                title="Argus: Automatic markings enabled"
                aria-label="Argus: Automatic markings enabled"
                key="argus"
              >
                <div>
                  <Icon style={{ width: '24px', height: '24px' }} icon="eye" />
                </div>
              </Tooltip>
            ) : null}
          </div>
        ) : null,
      events: (
        <div className="d-flex align-items-center">
          <SensorContextProvider
            key={sensor.id}
            value={{
              id: sensor.id,
              name: sensor.name,
              sensor_type: sensor.sensor_type,
              zone: sensor.zone,
              eventTypes: sensor.event_types,
            }}
          >
            <Button
              small
              light
              color="primary"
              icon="gear"
              className="mr-2"
              disabled={!sensor.zone}
              to={`${router.location.pathname}/${sensor.id}/alerts`}
            >
              {t('common.commonButtons.show')}
            </Button>

            <AlertIconsForSensor
              linkToEventSettings
              hideDisabledEventTypes={true}
            />
          </SensorContextProvider>
        </div>
      ),
      action: (
        <Button
          small
          light
          color="primary"
          className="mr-2"
          disabled={!sensor.zone}
          to={`${router.location.pathname}/${sensor.id}/sensor-alert-settings`}
        >
          {t('common.commonButtons.show')}
        </Button>
      ),
      'wifi-strength':
        sensor.is_online && sensor.sensor_type === 'roommate' ? (
          <div>
            <Consumer sensorId={sensor.sensor_id} />
          </div>
        ) : null,
    };
  });

  return (
    <SensorWifiStrengthSSEProvider sensors={sensors}>
      <SortableTable
        stickyHeader={false}
        wrapperClassName={props.className}
        className={`SensorsTable ${classes.table}`}
        style={props.style}
        loading={props.loading}
        columns={columns}
        rows={rows}
        noResultsPlaceholder={
          <>{t('manage.sensors.ui.SensorsTable.noResultsPlaceholder')}</>
        }
      />
    </SensorWifiStrengthSSEProvider>
  );
};

interface SSEResponse {
  sensor_id?: string;
  rssi?: number;
  ssid?: string;
}

function Consumer(props: ConsumerProps) {
  const sseData = useSSE<SSEResponse[]>('rssi', []);
  const sensor = sseData?.find((sensor) => sensor.sensor_id === props.sensorId);

  return (
    <Tooltip
      title={sensor?.rssi ? `${sensor.rssi} %` : 'Signal strength unavailable'}
    >
      <div className="d-flex justify-content-center flex-column">
        <WifiStrength hideLabel wifiStrength={sensor?.rssi} />
        {/*
        {sensor?.ssid && (
          <span className="h6 mt-1 mb-0" style={{ fontSize: '14px' }}>
            {sensor?.ssid}
          </span>
        )}
        */}
      </div>
    </Tooltip>
  );
}
