import React, { RefObject } from 'react';

export function useOutsideClickDetection<T extends HTMLElement = HTMLElement>(
  ref: RefObject<T>,
  callback: (target?: MouseEvent['target']) => void
) {
  React.useEffect(() => {
    function handleClickOutside(event: MouseEvent) {
      if (ref.current && !ref.current.contains(event.target as Node)) {
        callback(event.target);
      }
    }
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [ref, callback]);
}
