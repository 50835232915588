import React, { useState } from 'react';
import { useApiCall } from '../../../lib/useApiCall';
import * as api from '../../../serverApi';
import ModalWrapper from '../../../components/modals/ModalWrapper';
import { TreeView } from '../../../components/TreeView';
import { useErrorHandler } from '../../../lib/useErrorHandler';
import { ErrorMessages } from '../../../components/ErrorMessages';
import LoadingPlaceholder from '../../../lib/ui/LoadingPlaceholder';
import getHousingUnitsIds from '../../../lib/getHousingUnitsIds';
import getApiLockedZoneIds from '../../../lib/getApiLockedZoneIds';

export interface SensorSetZoneProps {
  modalTitle?: string;
  modalSubmitText?: string;
  modalCancelText?: string;
  isOpen: boolean;
  accountId: number;
  toggle: () => void;
  onSelect: (zone: api.StructureZone) => void;
  disableHousingUnits?: boolean;
  disableApiLockedZones?: boolean;
}

const SelectZoneModal = (props: SensorSetZoneProps) => {
  const errorHandler = useErrorHandler();

  const accountStructure = useApiCall(api.superVendorGetStructureForAccount, [
    props.accountId,
  ]);

  const zone = accountStructure?.data?.zone;

  const [activeZone, setActiveZone] = useState<api.StructureZone | undefined>(
    zone
  );

  // useEffect(() => {
  //   setActiveZone(zone);
  //   if (zone) {
  //     props.onSelect(zone);
  //   }
  // }, [zone]); // eslint-disable-line react-hooks/exhaustive-deps

  const handleSubmit = async () => {
    if (activeZone) {
      props.onSelect(activeZone);
      props.toggle();
    }
  };

  const disabledZoneIds = props.disableHousingUnits
    ? getHousingUnitsIds(zone)
    : [];

  const disabledApiLockedZones = props.disableApiLockedZones
    ? getApiLockedZoneIds(zone)
    : [];

  return (
    <ModalWrapper
      title={props.modalTitle ?? 'Velg boenhet'}
      okButtonText={props.modalSubmitText ?? 'Lagre'}
      cancelButtonText={props.modalCancelText ?? 'Avbryt'}
      className="ModalWrapper-with-tree-view"
      isOpen={props.isOpen}
      loading={accountStructure.loading}
      onHide={props.toggle}
      onSubmit={handleSubmit}
    >
      <div>
        {accountStructure.loading ? (
          <LoadingPlaceholder />
        ) : (
          <>
            {zone && (
              <TreeView
                root={zone}
                className="SensorSetZone-tree"
                selectZone={setActiveZone}
                activeZone={activeZone}
                disabledZoneIds={[
                  ...disabledZoneIds,
                  ...disabledApiLockedZones,
                ]}
              />
            )}
          </>
        )}
        <ErrorMessages className="my-2" errorData={errorHandler} />
      </div>
    </ModalWrapper>
  );
};

export default SelectZoneModal;
