import { useAppContext } from './global';

export function useAccount() {
  const appContext = useAppContext();

  /**
   * Perhaps the active role is an impersonated role;
   * we fetch the active account from the active role.
   */
  if (!appContext.user?.account) {
    throw Error('No active account in authentication context.');
  }

  return appContext.user.account;
}
