import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faExclamationCircle,
  faSignOut,
} from '@fortawesome/pro-solid-svg-icons';
import { useAppContext } from '../lib/global';
import { Link } from 'react-router-dom';
import { Button } from '../components/Button';
import { t } from '../lib/i18n';

import './AccessDenied.scss';

export const AccessDenied = () => {
  const app = useAppContext();
  const showSelectRoles = app.user?.roles && app.user.roles.length > 1;

  return (
    <div className="AccessDenied">
      <div className="AccessDenied-content">
        <FontAwesomeIcon icon={faExclamationCircle} style={{ fontSize: 50 }} />
        <h1>{t('components.AccessDenied.title')}</h1>
        <p>
          {t('components.AccessDenied.info')}{' '}
          {showSelectRoles && (
            <Link to="/roles">
              {t('components.AccessDenied.selectAnotherRole')}
            </Link>
          )}
        </p>

        <Button onClick={() => app.requestLogout()}>
          <FontAwesomeIcon icon={faSignOut} style={{ marginRight: 10 }} />
          {t('common.commonTexts.logOut')}
        </Button>
      </div>
    </div>
  );
};
