function formatAuditableType(auditable_type: string) {
  switch (auditable_type) {
    case 'App\\Models\\CareReceiver':
      return 'Tjenestemottaker';
    case 'App\\Models\\Sensor':
      return 'Sensor';
    case 'App\\Models\\AlertSetting':
      return 'Varslingsregel';
    case 'App\\Models\\Zone':
      return 'Sone/boenhet';
    case 'App\\Models\\User':
      return 'Tjenesteutøver';
  }
  return auditable_type;
}

export default formatAuditableType;
