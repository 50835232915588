import React from 'react';

import { t } from '../../lib/i18n';

import './PublicFooter.scss';

export const PublicFooter = () => {
  return (
    <div className="PublicFooter">
      <p>{t('publicComponents.PublicFooter._cookieUsageAlert')}</p>
      <a
        href={t('common.url.privacyPolicy')}
        rel="noopener noreferrer"
        target="_blank"
      >
        {t('publicComponents.PublicFooter.readOurEntirePrivacyPolicyHere')}
      </a>
      {/* eslint-disable i18next/no-literal-string */}
      <p className="mt-2">&copy; RoomMate AS</p>
    </div>
  );
};
