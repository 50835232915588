import { useEffect } from 'react';

interface Options {
  streamId: number;
}

/**
 * Send a signal that stream has stopped when browser or tab is closed
 */
export const useSendStreamStopSignalOnBrowserClose = (opts: Options) => {
  /**
   * Mount a listener for the 'beforeunload' event and send the stream stopped signal when this event is fired.
   */
  useEffect(() => {
    const sendStreamingStoppedSignal = async () => {
      /**
       * We're using 'navigator.sendBeacon' here because we don't want this request to be cancelled
       * when the browser/tab is closed
       */
      navigator.sendBeacon(
        '/api/stream/sendStoppedStreamSignal',
        new Blob([JSON.stringify({ streamId: opts.streamId })], {
          type: 'application/json',
        })
      );
    };
    window.addEventListener('beforeunload', sendStreamingStoppedSignal);
    return () => {
      window.removeEventListener('beforeunload', sendStreamingStoppedSignal);
    };
  }, [opts.streamId]);
};
