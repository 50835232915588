import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import * as api from '../../../serverApi';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faSyncAlt,
  faSpinnerThird,
  faDownload,
  faTimes,
} from '@fortawesome/pro-solid-svg-icons';
import { Table } from '../../super/shared/Table';

import TableCell from '@material-ui/core/TableCell';
import { useSuperStyles } from '../../super/useSuperStyles';
import { SensorContextProvider } from '../../../lib/SensorContext';
import { Button, InputAdornment } from '@material-ui/core';
import AlertIconsForSensor from '../../manage/sensors/AlertIconsForSensor';
import { BulkAssignAccountButton } from './bulk-assign-account/BulkAssignAccountButton';
import { StatusMarker } from '../../../lib/ui/StatusMarker';
import { Icon } from '../../../components/Icon';
import { BulkAssignVendorButton } from './bulk-assign-vendor/BulkAssignVendorButton';
import HeadingBar from '../../super/shared/HeadingBar';
import { Card } from '../../super/shared/Card';
import { useSensorArea } from './Sensors';
import { useAppContext } from '../../../lib/global';

import { openFileDownloadLink } from '../../../lib/openFileDownloadLink';
import { FiltersWrapper } from '../../super/shared/filters/FiltersWrapper';
import { AccountFilter } from '../../super/shared/filters/AccountFilter';
import { ZoneFilter } from '../../super/shared/filters/ZoneFilter';
import { SelectFilter } from '../../super/shared/filters/SelectFilter';
import { VendorFilter } from '../../super/shared/filters/VendorFilter';
import renderSensorActivity from '../../../lib/renderSensorActivity';

import { Pagination } from '../../../components/Pagination/Pagination';
import { UsePagination } from '../../../hooks/usePagination';
import { transformSortStringToObject } from '../../../lib/transformSortStringToObject';
import { DebouncedInput } from '../accounts/DebouncedInput';

import './ListSensors.scss';

interface Props {
  rootUrl: string;
}

export function ListSensors(props: Props) {
  const c = useSuperStyles();

  const app = useAppContext();
  const res = useSensorArea();

  const [loadingRcts, setLoadingRcts] = useState(false);

  const filters = res.filters;

  let sensors = res?.data?.data || [];

  const paginationData: UsePagination = {
    data: sensors,
    currentPage: filters.values.page || 1,
    itemsPerPage: filters.values.page_size || 100,
    totalPages: Array.from({ length: res.data?.last_page || 1 }),
    setCurrentPage(page: number | ((page: number) => number)) {
      filters.setFilter('page', page);
    },
    setItemsPerPage(itemsPerPage: number | ((itemsPerPage: number) => number)) {
      filters.setFilter('page_size', itemsPerPage);
    },
    reset() {
      filters.setFilters({
        page: 1,
        page_size: filters.values.page_size || 100,
      });
    },
  };

  const reloadRcts = async () => {
    setLoadingRcts(true);
    await api.superReloadRcts();
    setLoadingRcts(false);
  };

  const handleExport = () => {
    openFileDownloadLink(
      '/api/super/sensors/sensor-list-export.xlsx',
      `sensor-list-export.xlsx`
    );
  };

  const handleSensorFiltersChange = (data: api.SuperVendorGetSensorsInput) => {
    filters.setFilters({ ...filters.values, ...data });
    paginationData.reset();
  };

  const handleSortClick = (columnKey: string) => {
    const currentSort = transformSortStringToObject(filters.values.sort);

    /**
     * We sort by ascending order if there's no previous sorting column or we're switching the
     * column that's being sorted
     */
    if (!currentSort || currentSort.key !== columnKey) {
      filters.setFilter('sort', `${columnKey} asc`);
      return;
    }

    /**
     * Otherwise, just switch the sorting order
     */
    if (currentSort?.key === columnKey) {
      const newSortOrder = currentSort.type === 'asc' ? 'desc' : 'asc';

      filters.setFilter('sort', `${columnKey} ${newSortOrder}`);
    }
  };

  const handleQuerySearchChange = (value: string) => {
    handleSensorFiltersChange({ query: value ? value : undefined });
  };

  const hasSuperPermission = app.hasCapability('globalSuper');

  const columnsWithSuperPermissions = [
    { key: 'period_from', header: 'Period from', style: { minWidth: 113 } },
    { key: 'period_to', header: 'Period to', style: { minWidth: 113 } },
    { key: 'po', header: 'PO', style: { minWidth: 113 } },
    { key: 'comment', header: 'Comment' },
  ];

  const columns = [
    { key: 'sensor_id', header: 'Sensor ID', sortable: true },
    { key: 'sensor_type', header: 'Sensor type', sortable: true },
    { key: 'vendor', header: 'Vendor' },
    { key: 'account', header: 'Account' },
    { key: 'zone', header: 'Zone' },
    {
      key: 'enabled_events',
      header: 'Enabled events',
      style: { minWidth: 130 },
    },
    { key: 'tunnel_ip', header: 'Tunnel IP' },
    { key: 'last_active', header: 'Is/last active', style: { minWidth: 130 } },
    { key: 'activated', header: 'Activated' },
    ...(hasSuperPermission ? columnsWithSuperPermissions : []),
  ];

  const rows = sensors.map((sensor, i) => {
    return {
      id: sensor.sensor_id,
      sensor_id: (
        <TableCell component="th" scope="row">
          <Link to={`${props.rootUrl}/${sensor.id}`}>{sensor.id}</Link>
          {sensor.sensor_type === 'roommate' && !sensor.is_online && (
            <span
              style={{
                color: '#822',
                fontSize: '10px',
                textTransform: 'uppercase',
              }}
            >
              <Icon
                alt="Sensor is offline."
                style={{
                  width: '24px',
                  height: '24px',
                  marginLeft: '12px',
                }}
                icon="disconnected"
              />
              Offline
            </span>
          )}
        </TableCell>
      ),
      sensor_type: <TableCell>{sensor.sensor_type}</TableCell>,
      vendor: (
        <TableCell>
          {sensor.vendor_account_id ? (
            app.hasCapability('globalSuper') ? (
              <Link to={`/super/accounts/${sensor.vendor_account_id}`}>
                {sensor.vendor_account_name}
              </Link>
            ) : (
              sensor.vendor_account_name
            )
          ) : (
            <div style={{ color: '#aaa' }}>None</div>
          )}
        </TableCell>
      ),
      account: sensor.account_id ? (
        <TableCell>
          {app.hasCapability('globalSuper') ? (
            <Link to={`/super/accounts/${sensor.account_id}`}>
              {sensor.account_name}
            </Link>
          ) : app.hasCapability('vendorUpdateAccounts') ? (
            <Link
              to={`/${
                app.hasCapability('globalSuper') ? 'super' : 'vendor'
              }/accounts/${sensor.account_id}`}
            >
              {sensor.account_name}
            </Link>
          ) : (
            sensor.account_name
          )}
        </TableCell>
      ) : (
        <TableCell>
          <div style={{ color: '#aaa' }}>None</div>
        </TableCell>
      ),
      zone:
        !sensor.account_id || !sensor.zone_id ? (
          <TableCell>
            <div style={{ color: '#aaa' }}>None</div>
          </TableCell>
        ) : (
          <TableCell>
            <span>{sensor.zone_name}</span>
          </TableCell>
        ),
      enabled_events: (
        <TableCell className="sensor-events-icons">
          <SensorContextProvider
            key={`${sensor.id}-${i}`}
            value={{
              id: sensor.id,
              name: sensor.name,
              sensor_type: sensor.sensor_type,
              zone:
                sensor.zone_id && sensor.zone_name
                  ? {
                      id: sensor.zone_id,
                      name: sensor.zone_name,
                      parent: null,
                    }
                  : undefined,
              careReceiver: sensor.care_receiver_id
                ? {
                    id: sensor.care_receiver_id,
                    first_name: sensor.care_receiver_first_name,
                    last_name: sensor.care_receiver_last_name,
                  }
                : undefined,
              //eventTypes: sensor.event_types,
            }}
          >
            <AlertIconsForSensor />
          </SensorContextProvider>
        </TableCell>
      ),
      tunnel_ip: (
        <TableCell className="sensor-events-icons">{sensor.tunIP}</TableCell>
      ),
      last_active: <TableCell>{renderSensorActivity(sensor)}</TableCell>,
      activated: (
        <TableCell>
          <StatusMarker
            data-sort={sensor.is_enabled}
            status={sensor.is_enabled ? 'success' : 'danger'}
          />
        </TableCell>
      ),
      period_from: (
        <TableCell>
          {sensor?.current_sensor_billing_period?.period_from}
        </TableCell>
      ),
      period_to: (
        <TableCell>
          {sensor?.current_sensor_billing_period?.period_to}
        </TableCell>
      ),
      po: (
        <TableCell>
          {sensor?.current_sensor_billing_period?.purchase_order}
        </TableCell>
      ),
      comment: (
        <TableCell>{sensor?.current_sensor_billing_period?.comment}</TableCell>
      ),
    };
  });

  return (
    <>
      <HeadingBar>
        <h2>Sensors</h2>

        <div
          style={{
            height: '25px',
            width: '25px',
            fontSize: '25px',
            lineHeight: '25px',
          }}
        >
          {res.loading && <FontAwesomeIcon icon={faSpinnerThird} spin={true} />}
        </div>

        {hasSuperPermission && (
          <Button onClick={reloadRcts} disabled={loadingRcts}>
            <FontAwesomeIcon
              icon={faSyncAlt}
              spin={loadingRcts}
              className="mr-2"
            />
            Reload RCTS
          </Button>
        )}

        {hasSuperPermission && (
          <Button onClick={handleExport} disabled={loadingRcts}>
            <FontAwesomeIcon icon={faDownload} className="mr-2" />
            Export list
          </Button>
        )}

        <Button onClick={res.reload} disabled={res.loading}>
          <FontAwesomeIcon
            icon={faSyncAlt}
            spin={res.loading}
            className="mr-2"
          />
          Refresh
        </Button>

        {(hasSuperPermission ||
          app.hasCapability('vendorBulkAssignSensorsToAccount')) && (
          <BulkAssignAccountButton onChanged={res.reload} />
        )}

        {hasSuperPermission && (
          <BulkAssignVendorButton onChanged={res.reload} />
        )}
      </HeadingBar>

      <Card>
        <FiltersWrapper onReset={filters.reset}>
          {app.hasCapability('globalSuper') && (
            <VendorFilter
              className="FiltersWrapper-item"
              disabled={res.loading}
              value={filters.values.vendor_account_id}
              onChange={(vendor) =>
                handleSensorFiltersChange({
                  vendor_account_id: vendor?.account_id,
                })
              }
            />
          )}

          <AccountFilter
            className="FiltersWrapper-item"
            disabled={res.loading}
            value={filters.values.account_id}
            onChange={(account) =>
              handleSensorFiltersChange({
                zone_id: undefined,
                account_id: account?.account_id,
              })
            }
            vendorId={filters.values.vendor_account_id}
          />

          <ZoneFilter
            className="FiltersWrapper-item"
            disabled={res.loading}
            value={filters.values.zone_id}
            accountId={filters.values.account_id}
            onChange={(zone) =>
              handleSensorFiltersChange({ zone_id: zone?.id })
            }
          />

          <SelectFilter
            className="FiltersWrapper-item"
            disabled={res.loading}
            name="sensor_type"
            value={filters.values.sensor_type}
            placeholder="Sensor type"
            options={[
              { label: 'RoomMate', value: 'roommate' },
              { label: 'Climax', value: 'climax' },
            ]}
            onChange={(sensorType) =>
              handleSensorFiltersChange({
                sensor_type: (!sensorType || sensorType?.value === 'all'
                  ? undefined
                  : sensorType.value) as any,
              })
            }
          />

          <SelectFilter
            className="FiltersWrapper-item"
            disabled={res.loading}
            name="status"
            placeholder="Sensor status"
            value={filters.values.status}
            options={[
              { label: 'Online', value: 'online' },
              { label: 'Offline', value: 'offline' },
            ]}
            onChange={(status) =>
              handleSensorFiltersChange({ status: status?.value as any })
            }
          />

          <SelectFilter
            className="FiltersWrapper-item"
            disabled={res.loading}
            name="sensor_type"
            placeholder="Zone assignment"
            value={
              filters.values.assigned_to_zone === undefined
                ? undefined
                : filters.values.assigned_to_zone
                ? 'with'
                : 'without'
            }
            options={[
              { label: 'With zone', value: 'with' },
              { label: 'Without zone', value: 'without' },
            ]}
            onChange={(sensorType) =>
              handleSensorFiltersChange({
                assigned_to_zone: !sensorType
                  ? undefined
                  : sensorType?.value === 'with',
              })
            }
          />

          <DebouncedInput
            className="FiltersWrapper-input FiltersWrapper-item"
            value={filters.values.query}
            onChange={handleQuerySearchChange}
            placeholder="Sensor: 100-200,203 etc."
            variant="outlined"
            InputProps={{
              endAdornment: filters.values.query &&
                filters.values.query.length > 0 && (
                  <InputAdornment position="end">
                    <span
                      role="button"
                      className="FilterInput-icon FilterInput-icon-clear"
                      onClick={() => {
                        handleSensorFiltersChange({ query: undefined });
                      }}
                    >
                      <FontAwesomeIcon icon={faTimes} />
                    </span>
                  </InputAdornment>
                ),
            }}
            error={res.error?.response?.data.errors?.query?.[0]}
          />
        </FiltersWrapper>
        <Pagination loading={res.loading} pagination={paginationData} />
        <Table
          className={c.table}
          columns={columns}
          rows={rows}
          loading={res.loading}
          sortColumn={transformSortStringToObject(filters.values.sort)}
          onSortClick={handleSortClick}
        />
      </Card>
    </>
  );
}
