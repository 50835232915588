import { useEffect, useMemo, useState } from 'react';
import qs from 'query-string';
import { Event, Sensor } from './CareContext';
import * as api from '../../../serverApi';
import { parseSseEventData } from './parseSseEventData';

import { SensorStatusResult } from './useSensorStatus';
import { useAppContext } from '../../../lib/global';
import { useConfirmation } from '../../../lib/confirmation/ConfirmationContext';
import { t } from '../../../lib/i18n';

interface StreamedEvent {
  event_type: api.EventType;
  event_id: number;
  event_at: string; // YYYY-MM-DD HH:MM:SS
  level: 'alarm' | 'alert';
  sensor?: Sensor;
  sensor_composite_id: string;
  timestamp?: number;
}

interface SensorStatusSseOptions {
  skip?: boolean;
}

export const useSensorStatusSse = (
  options?: SensorStatusSseOptions
): SensorStatusResult => {
  const app = useAppContext();
  const confirmCtx = useConfirmation();
  const [streamedSensors, setStreamedSensors] = useState<Sensor[]>();
  const [streamedEvents, setStreamedEvents] = useState<StreamedEvent[]>([]);
  const [userStatus, setUserStatus] = useState<{ [key: string]: string }>();
  const [retries, setRetries] = useState(0);
  const [dataTimestamp, setDataTimestamp] = useState<number>();

  /**
   * Set url of event stream
   */
  const rootURL = api.getApiRoot();
  let url = rootURL;

  /**
   * Get streaming intervals from user feature flags and parse into query string
   */
  const sensorsInterval = app.getFeatureValueNumber('interval-status-sensors');
  const eventsInterval = app.getFeatureValueNumber('interval-status-events');

  const intervalParams = qs.stringify({
    sensorsinterval: sensorsInterval,
    eventsinterval: eventsInterval,
  });

  url += `/api/stream/status?${intervalParams}`;

  /**
   * Setup SSE event source
   */
  useEffect(() => {
    //Don't create event source stream if skip option value is provided as true
    if (options?.skip) {
      return;
    }

    const sse = new EventSource(url, { withCredentials: true });

    /**
     * Listen for the sensors event, format the sensor and update the state
     */
    sse.addEventListener('sensors', (e) => {
      const data: Sensor[] = parseSseEventData(e);

      setStreamedSensors(data);
      setDataTimestamp(Date.now());
    });

    /**
     * Listen for the events event and update the state
     */
    sse.addEventListener('events', (e) => {
      const data: StreamedEvent[] = parseSseEventData(e);
      setStreamedEvents(data);
      setDataTimestamp(Date.now());
    });

    /**
     * Listen for the user status event and update the state
     */
    sse.addEventListener('userstatus', (e) => {
      const userStatus = parseSseEventData(e);
      setUserStatus(userStatus);
      setDataTimestamp(Date.now());
    });

    /**
     * If error occurs during streaming, log the error, reset the event states and close the streaming
     */
    sse.onerror = async (event) => {
      console.error('SSE error:', event);
      setStreamedSensors([]);
      setStreamedEvents([]);
      setUserStatus(undefined);
      sse.close();

      /**
       * After streaming fails, we try to reconnect for an amount of times with a 2^n interval in between (0,1,2,4,8)
       */
      const nextTimeout = Math.pow(2, retries) * 1000;

      if (retries < 4) {
        return setTimeout(() => setRetries((prev) => prev + 1), nextTimeout);
      }
      //If all of the retries fail, show an error message and ask user if he wants to retry, if yes reset the number of retries.
      try {
        await confirmCtx.confirm(t('care.Main.sseStreamingFailed'));
        setRetries(0);
      } catch (e) {}
    };

    return () => {
      setStreamedSensors([]);
      setStreamedEvents([]);
      sse.close();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [retries]);

  /**
   * Map through sensors and update user_status sensor field from user status object we get from streaming
   *
   * Value is memoized to avoid re-rendering if user status and sensors state don't change
   */
  const sensors = useMemo(
    () =>
      streamedSensors?.map((sensor) => {
        if (userStatus?.[sensor.sensor_composite_id]) {
          return {
            ...sensor,
            user_status: userStatus[sensor.sensor_composite_id],
            // If the user status is 'ps', the sensor is silenced
            // and we need to update the status field too.
            status:
              userStatus[sensor.sensor_composite_id] === 'ps'
                ? 'silenced'
                : sensor.status,
          };
        }
        return sensor;
      }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [JSON.stringify(userStatus), JSON.stringify(streamedSensors)]
  );

  /**
   *  Map through events and append sensor according to the sensor_composite_id field we get from event
   */
  const events = streamedEvents.map((event) => {
    event.sensor = streamedSensors?.find(
      (ss) => ss.sensor_composite_id === event.sensor_composite_id
    );

    if (!event.sensor) {
      return {
        event_type: event.event_type,
        event_id: event.event_id,
        event_at: event.event_at,
        level: event.level,
      } as Event;
    }

    return {
      event_type: event.event_type,
      event_id: event.event_id,
      event_at: event.event_at,
      level: event.level,
      sensor: event.sensor,
    } as Event;
  });

  /**
   * Loop through the sensors and get sensor by index
   */
  const sensor = sensors
    ? sensors.reduce((coll, item) => {
        coll[item.sensor_composite_id] = item;
        return coll;
      }, {} as { [sensor_id: string]: Sensor })
    : {};

  /**
   * Loop through the events and get event by index
   */
  const event = events.reduce((coll, item) => {
    coll[item.event_id] = item;
    return coll;
  }, {} as { [event_id: number]: Event });

  return {
    sensors,
    sensor,
    event,
    alarms: events.filter((x) => x.level === 'alarm'),
    alerts: events.filter((x) => x.level === 'alert'),
    dataTimestamp,
  };
};
