import React from 'react';
import cs from 'classnames';
import moment from 'moment';

import { InlineSpinner } from '../../components/InlineSpinner';
import { StreamType, EventType, User, SensorType, Role } from '../../serverApi';
import { Icon } from '../../components/Icon';
import { t } from '../../lib/i18n';
import { getLabelForEventType } from '../../lib/getLabelForEventType';
import { EventReceivedBySummary } from '../care/components/EventReceivedBySummary';
import { ReactComponent as RoommateLogo } from '../../images/logo-black.svg';
import { useRouter } from '../../lib/useRouter';
import { useAppContext } from '../../lib/global';

import './ReceiptPage.scss';

function formatDate(date: string) {
  return moment(date).format('HH:mm / D. MMMM YYYY');
}

interface Props {
  images?: Array<{ type: StreamType } & ({ data: string } | { url: string })>;

  time?: string;

  sensorType?: SensorType;
  eventType?: EventType;
  sensorId?: string;
  eventIsTechnical?: boolean;
  zone?: {
    id: number;
    name: null | string;
    parent: null | {
      id: number;
      name: null | string;
    };
  };

  me?: null | User;

  displayArea?: null | string;
  displayName?: null | string;

  careReceiverName?: string;

  handled?: null | {
    at_utc: string;
    at_sensor_local: string;
    by: null | {
      id: number;
      username: string;
      impersonal_user: boolean;
      first_name: null | string;
      last_name: null | string;
      display_name: null | string;
    };
  };

  loading?: boolean;

  vpkEnabled?: boolean;

  received_by_sensor?: null | {
    name: null | string;
    sensor_id: number;
    sensor_type: SensorType;
    zone: null | {
      zone_id: number;
      name: string;
    };
  };
  /**
   * Triggered when the user clicks the "handle" link
   */
  onHandleClick?: () => void;
  onLogout?: () => void;
  hasAccessToSupervision: boolean;
  roleWithAccessToSupervision: Role | null;
}

interface HandledSummaryProps {
  at_utc: string;
  at_sensor_local: string;
  by: null | {
    id: number;
    username: string;
    impersonal_user: boolean;
    first_name: string | null;
    last_name: string | null;
    display_name: string | null;
  };
}

function HandledSummary(props: HandledSummaryProps) {
  const { at_sensor_local, by } = props;
  let name: React.ReactNode = null;
  if (!by) {
    name = '';
  } else if (by.impersonal_user) {
    name = by.display_name || by.username;
  } else {
    name =
      by.first_name || by.last_name
        ? `${by.first_name || ''} ${by.last_name || ''}`
        : by.username;
  }
  return (
    <div className="ReceiptPage-handled-summary">
      <p style={{ fontWeight: 900, fontSize: 21 }}>
        {t('event.ReceiptPage.checkedBy')}
        <br />
        {name}
      </p>
      <p>{formatDate(at_sensor_local)}</p>
    </div>
  );
}

export function ReceiptPage(props: Props) {
  const router = useRouter();
  const app = useAppContext();

  if (props.loading) {
    return (
      <div
        className={cs(
          'ReceiptPage',
          props.handled ? 'ReceiptPage--loading' : undefined
        )}
      >
        <InlineSpinner size="sm" text={t('common.commonTexts.loading')} />
      </div>
    );
  }

  const handleSupervisionClick = async () => {
    if (
      !props.hasAccessToSupervision &&
      props.roleWithAccessToSupervision !== null
    ) {
      await app.setActiveRole(props.roleWithAccessToSupervision.id);
    }

    router.history.push(
      props.sensorId ? `/care?start_streaming=${props.sensorId}` : '/care'
    );
  };

  return (
    <div
      className={cs(
        'ReceiptPage',
        props.handled ? 'ReceiptPage--handled' : undefined
      )}
    >
      {
        !props.handled ? (
          !props.images || props.images.length === 0 ? (
            <p className="mt-4">{t('event.ReceiptPage.noPhotoAvailable')}.</p>
          ) : (
            props.images.map((img, ix) => {
              const src =
                'data' in img ? `data:image/png;base64,${img.data}` : img.url;

              return (
                <div className="ReceiptPage-image" key={ix}>
                  <img
                    alt={t(
                      'event.ReceiptPage.superVisionImageFromRoomMateSensor'
                    )}
                    src={src}
                  />
                </div>
              );
            })
          )
        ) : null /*Hide image if event is handled*/
      }
      <div
        className="mt-3"
        style={{ fontWeight: 500, fontSize: 21, textAlign: 'center' }}
      >
        {props.time && props.eventType && props.sensorType && (
          <div>
            <div className="EventSummary-bold">
              {getLabelForEventType(props.eventType, props.sensorType)}
            </div>
            <div className="EventSummary-bold">
              {props.displayArea}
              <br />
              {props.displayName}
            </div>

            <EventReceivedBySummary
              className="EventSummary-bold"
              receivedBySensor={props.received_by_sensor}
            />

            <div>{formatDate(props.time)}</div>
          </div>
        )}
      </div>

      <button
        className="ReceiptPage-handle-button"
        disabled={!!props.handled}
        onClick={props.onHandleClick}
      >
        <Icon className="ReceiptPage-handle-icon" icon="check" />
        <div className="ReceiptPage-handle-button-label">
          {t('event.ReceiptPage.check')}
        </div>
      </button>
      {!props.handled && (
        <p>
          {props.me ? (
            <>
              {t('event.ReceiptPage.youAreLoggedInAs')}{' '}
              {props.me.first_name || props.me.last_name
                ? `${props.me.first_name || ''} ${props.me.last_name || ''}`
                : props.me.username}
              . &nbsp;&nbsp;
              <button onClick={props.onLogout}>
                {t('common.commonTexts.logOut')}
              </button>
            </>
          ) : (
            t('event.ReceiptPage.youAreNotLoggedIn')
          )}
        </p>
      )}

      {props.handled && (
        <HandledSummary
          at_sensor_local={props.handled.at_sensor_local}
          at_utc={props.handled.at_utc}
          by={props.handled.by}
        />
      )}

      {props.sensorType === 'roommate' &&
        !props.handled &&
        !props.eventIsTechnical && (
          <button
            className="ReceiptPage-stream-button"
            onClick={handleSupervisionClick}
          >
            <Icon icon="roommate-sensor" />
            {t('event.ReceiptPage.streamSensor')}
          </button>
        )}

      <RoommateLogo className="ReceiptPage-logo" />
    </div>
  );
}
