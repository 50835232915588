import React, { CSSProperties } from 'react';
import { Spinner } from 'reactstrap';
import cs from 'classnames';

import './InlineSpinner.scss';

export interface InlineLoaderProps {
  text?: string;
  size?: string;
  type?: 'grow' | 'border';
  textStyle?: CSSProperties;
  className?: string;
  spinnerClassName?: string;
  textClassName?: string;
}

export function InlineSpinner(props: InlineLoaderProps) {
  return (
    <span className={cs('InlineSpinner', props.className)}>
      <Spinner
        size={props.size || 'sm'}
        type={props.type || 'border'}
        className={cs('InlineSpinner-spinner', props.spinnerClassName)}
      />
      {props.text && (
        <span
          className={cs('InlineSpinner-text', props.textClassName)}
          style={{ ...props.textStyle }}
        >
          {props.text}
        </span>
      )}
    </span>
  );
}
