import React from 'react';

import { useManageStyles } from '../useManageStyles';
import * as serverApi from '../../../serverApi';
import { useZoneContext } from '../../../lib/ZoneContext';
import matchSorter from 'match-sorter';
import { Button } from '../../../components/Button';
import { formatRoleValidityDates } from './formatRoleValidityDates';
import { StatusMarker } from '../../../lib/ui/StatusMarker';
import { SortableTable } from '../../../components/SortableTable';
import { t, TranslationKey } from '../../../lib/i18n';
import LockIcon from '@material-ui/icons/Lock';

interface Props {
  search?: string;
  data?: serverApi.GetUserRolesForZoneResult | undefined;
  loading: boolean;
}

export function UserRoleOverview(props: Props) {
  const classes = useManageStyles();
  const ctx = useZoneContext();

  const filterPermissions = (
    permissions?: serverApi.GetUserRolesForZoneResult,
    searchKeyword?: string
  ): serverApi.UserRolesOutputData[] | undefined => {
    if (!permissions) {
      return undefined;
    }

    if (!searchKeyword) {
      return permissions.data;
    }
    return matchSorter(permissions.data, searchKeyword, {
      keys: [
        'user.username',
        'user.display_name',
        'user.first_name',
        'user.last_name',
        'user.phone',
        'zone.name',
        'role.role',
      ],
    });
  };

  const permissions = filterPermissions(props.data, props.search);

  // prettier-ignore
  const columns = [
    { header: t('common.commonTexts.enabled'), key: 'enabled', sortable: true },
    { header: t('common.commonTexts.name'), key: 'name', sortable: true },
    { header: t('common.commonTexts.userName'), key: 'username', sortable: true },
    { header: t('common.commonTexts.role'),  key: 'role', sortable: true },
    { header: t('common.commonTexts.zone'), key: 'zone', sortable: true, },
    { header: t('common.commonTexts.validity'), key: 'validity', sortable: true, },
    { header: '', key: 'action' },
  ];

  const rows = permissions
    ? permissions.map((u, index) => {
        const isApiLocked = u.user.api_lock;

        return {
          enabled: (
            <span data-sort={u.user.enabled} style={{ paddingLeft: 15 }}>
              <StatusMarker status={u.user.enabled ? 'success' : 'danger'} />
            </span>
          ),
          name: [
            ...(u.user.display_name ? [u.user.display_name] : []),
            ...(u.user.last_name ? [u.user.last_name] : []),
            ...(u.user.first_name ? [u.user.first_name] : []),
          ].join(', '),
          username: u.user.username || u.user.phone,
          role: t(`common.roles.${u.role.role}` as TranslationKey),
          zone: u.zone.name,
          validity: formatRoleValidityDates(u),
          action: (
            <Button
              disabled={u.readonly || isApiLocked}
              icon={isApiLocked ? undefined : 'gear'}
              small={true}
              light={true}
              color="primary"
              to={`/manage/${ctx.activeZone.id}/permissions/${u.user.user_id}`}
              data-cy={`view-user-${index}`}
            >
              {isApiLocked ? (
                <LockIcon style={{ color: '#818181', width: 30, height: 25 }} />
              ) : (
                t('common.commonButtons.show')
              )}
            </Button>
          ),
        };
      })
    : [];

  return (
    <SortableTable
      stickyHeader={false}
      wrapperClassName="DescPermissionList"
      className={classes.table}
      columns={columns}
      rows={rows}
      loading={props.loading}
      noResultsPlaceholder={t('manage.permissions.common.noAccessesDefined')}
    />
  );
}
