import React from 'react';
import cs from 'classnames';
import {
  ErrorHandlerType,
  errorIsObjectError,
  errorIsString,
} from '../lib/useErrorHandler';
import { t } from '../lib/i18n';
import { getTranslatedError } from '../lib/getTranslatedError';
import './ErrorHandler.scss';

interface Props {
  tag?: 'div' | 'span';
  errorData?: ErrorHandlerType;
  errorApiCall?: any;
  className?: string;

  renderFieldErrors?: boolean;
}

export const ErrorMessages = (props: Props) => {
  const Tag = props.tag || 'div';

  //String
  if (
    props.errorData &&
    props.errorData.error &&
    errorIsString(props.errorData.error)
  ) {
    return (
      <Tag className={cs('ErrorHandler', props.className)}>
        {getTranslatedError(props.errorData.error)}
      </Tag>
    );
  }

  //Array
  if (
    props.errorData &&
    props.errorData.error &&
    Array.isArray(props.errorData.error)
  ) {
    return (
      <div
        className={cs('ErrorHandler', props.className)}
        style={{ maxHeight: 400, overflowY: 'auto' }}
      >
        {props.errorData.error.map((error: string, key: number) => {
          return (
            <Tag className={cs('ErrorHandler', props.className)} key={key}>
              {typeof error === 'string' ? getTranslatedError(error) : null}
            </Tag>
          );
        })}
      </div>
    );
  }

  //Object
  if (
    props.renderFieldErrors &&
    props.errorData &&
    props.errorData.error &&
    Number(Object.keys(props.errorData.error).length) > 0
  ) {
    return (
      <div>
        {Object.keys(props.errorData.error).map(
          (key: string, index: number) => {
            return (
              <Tag className={cs('ErrorHandler', props.className)} key={index}>
                {props.errorData &&
                errorIsObjectError(props.errorData.error) &&
                typeof props.errorData.error[key] === 'string'
                  ? getTranslatedError(props.errorData?.error[key])
                  : null}
              </Tag>
            );
          }
        )}
      </div>
    );
  }

  if (props.errorApiCall) {
    const errors = props.errorApiCall?.response?.data?.errors;

    const error =
      props.errorApiCall?.response?.data?.message ||
      props.errorApiCall.message ||
      t('common.commonTexts.somethingWentWrong');

    if (errors && props.renderFieldErrors) {
      return (
        <div>
          {Object.keys(errors).map((key: string, index: number) => {
            return (
              <Tag className={cs('ErrorHandler', props.className)} key={index}>
                {typeof errors[key] === 'string'
                  ? getTranslatedError(errors[key])
                  : errors[key][0] ?? getTranslatedError(error)}
              </Tag>
            );
          })}
        </div>
      );
    }

    return (
      <Tag className={cs('ErrorHandler', props.className)}>
        {getTranslatedError(error)}
      </Tag>
    );
  }

  return null;
};
