import { useAtom } from 'jotai';
import { atomWithStorage, createJSONStorage } from 'jotai/utils';

/**
 * Use session storage for the ignored detections -- we just want to hide them for the current session.
 */
const storage = createJSONStorage<IgnoredDetectionsState>(() => sessionStorage);

/**
 * An array of ignored detections. We should probably match the sensor ID too here ...
 */
type IgnoredDetectionsState = Array<{
  /**
   * When was this ignored detection added?
   */
  timestamp: number;

  /**
   * The composite ID of the sensor that this detection belongs to.
   */
  sensorCompositeId?: string;

  /**
   * The uid of the detection
   */
  uid: string;
}>;

function getInitialValue() {
  const item = localStorage.getItem('ignoredDetections');
  return item ? JSON.parse(item) : [];
}

const ignoredDetectionsAtom = atomWithStorage<IgnoredDetectionsState>(
  'ignoredDetections',
  getInitialValue(),
  storage
);

export const useIgnoredDetections = () => useAtom(ignoredDetectionsAtom);
