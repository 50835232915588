import React, { CSSProperties } from 'react';
import { Table as MUITable, TableContainer } from '@material-ui/core';

import './Table.scss';

type MUITableProps = React.ComponentProps<typeof MUITable>;

interface Props extends MUITableProps {
  // Table props
  stickyHeader?: boolean;
  className?: string;

  //Table container props
  tableContainerStyle?: CSSProperties;
}

export const Table = (props: Props) => {
  const { className, tableContainerStyle, ...rest } = props;
  return (
    <TableContainer
      style={{
        ...tableContainerStyle,
      }}
    >
      {/*TODO: remove hardcoded stickyHeader prop when we merge table sticky header branch*/}
      <MUITable
        {...rest}
        className={props.className}
        stickyHeader={props.stickyHeader}
      >
        {props.children}
      </MUITable>
    </TableContainer>
  );
};
