import React from 'react';
import { FormControlLabel, RadioGroup, Radio } from '@material-ui/core';

import useZoneSettingsForm from './lib/useZoneSettingsForm';
import { Panel } from '../../../../../components/Panel';
import { useZoneContext } from '../../../../../lib/ZoneContext';
import { FormBody } from './lib/FormBody';
import * as api from '../../../../../serverApi';
import { t } from '../../../../../lib/i18n';

const fields: Array<keyof api.ZoneSettings> = [
  'stream_indicator_led',
  'stream_indicator_audio',
];

export function StreamIndicator() {
  const ctx = useZoneContext();
  const form = useZoneSettingsForm({ fields });

  // prettier-ignore
  function onChange(event: React.ChangeEvent<HTMLInputElement>, value: string) {
    form.formik.setFieldValue('stream_indicator_led', value === 'both' || value === 'light');
    form.formik.setFieldValue('stream_indicator_audio', value === 'both');
    form.formik.setFieldTouched('stream_indicator_led');
    form.formik.setFieldTouched('stream_indicator_audio');
  }

  let value = '';

  if (form.displayValues?.stream_indicator_led === true) {
    value = form.displayValues?.stream_indicator_audio ? 'both' : 'light';
  } else if (form.displayValues?.stream_indicator_led === false) {
    value = 'none';
  }

  return (
    <div>
      <h2>
        {t('manage.zone.StreamIndicator.headingFor')} {ctx.activeZone.name}
      </h2>

      <Panel>
        <strong>{t('common.commonTexts.tips')}:</strong>{' '}
        {t(
          'manage.zone.settings.common.theSettingsFromTheParentZoneAreShownInGrayWhenThisIsSelected'
        )}
      </Panel>

      <FormBody zoneSettingsForm={form}>
        <RadioGroup name="light" value={value} onChange={onChange}>
          <FormControlLabel
            disabled={form.disabled}
            value="none"
            control={<Radio color="primary" />}
            label={t('manage.zone.StreamIndicator.noAlert')}
          />

          <FormControlLabel
            disabled={form.disabled}
            value="light"
            control={<Radio color="primary" />}
            label={t('manage.zone.StreamIndicator.alertWithLight')}
          />

          <FormControlLabel
            disabled={form.disabled}
            value="both"
            control={<Radio color="primary" />}
            label={t('manage.zone.StreamIndicator.alertWithLightAndSound')}
          />
        </RadioGroup>
      </FormBody>
    </div>
  );
}
