type NodeMap = {
  [key: string]: boolean;
};

export function getLocalStorageExpandedNodes(
  account_code: string
): NodeMap | undefined {
  const storage = window.localStorage.getItem(
    `expanded-tree-nodes-${account_code}`
  );
  if (!storage) {
    return undefined;
  }

  const nodeMap = JSON.parse(storage);
  return nodeMap;
}

export function setLocalStorageExpandedNodes(
  account_code: string,
  val: NodeMap
) {
  window.localStorage.setItem(
    `expanded-tree-nodes-${account_code}`,
    JSON.stringify(val)
  );
}
