import React, { useState } from 'react';
import { Link, RouteComponentProps } from 'react-router-dom';
import * as serverApi from '../../../serverApi';
import { ErrorMessages } from '../../../components/ErrorMessages';
import { InlineSpinner } from '../../../components/InlineSpinner';
import { Card } from '../shared/Card';
import { HeadingBar } from '../shared/HeadingBar';
import { Button } from '@material-ui/core';
import { useApiCall } from '../../../lib/useApiCall';
import { ImpersonateButton } from './ui/ImpersonateButton';
import { EditUserForm } from './ui/EditUserForm';

interface Props {
  userId: string;
}

export const UserDetails = (props: RouteComponentProps<Props>) => {
  const [editMode, setEditMode] = useState(false);

  const userId = parseInt(props.match.params.userId);
  const userResponse = useApiCall(serverApi.superGetUserDetails, [userId]);
  const user = userResponse?.data;

  const toggleEditMode = () => {
    setEditMode((prev) => !prev);
  };
  return (
    <div className="UserDetails">
      <ErrorMessages errorApiCall={userResponse.error} />

      {userResponse.loading ? (
        <div className="text-center">
          <InlineSpinner text="Laster ..." />
        </div>
      ) : (
        user && (
          <>
            <HeadingBar>
              <h1 className="m-0">
                {user.first_name} {user.last_name}
              </h1>
              <Button
                color={editMode ? 'secondary' : 'primary'}
                onClick={toggleEditMode}
              >
                {editMode ? 'Exit edit mode' : 'Edit Details'}
              </Button>
              <Button component={Link} to="/super/users">
                Back to users
              </Button>
              <ImpersonateButton userId={userId} />
            </HeadingBar>
            <Card>
              <EditUserForm user={user} readOnly={!editMode} />
            </Card>
          </>
        )
      )}
    </div>
  );
};
