/**
 * Get the lowest available/free item index number, 1-indexed (the presence of an item:0 is ignored).
 */
export function getAvailableItemNumber(items?: Array<{ item: number }>) {
  if (!items || items.length === 0) {
    return 1;
  }

  /**
   * This algorithm finds the highest number and adds 1.
   */
  const highestNum = items.reduce((prev, cur) => Math.max(prev, cur.item), 0);
  return highestNum + 1;

  /**
   * This algorithm finds the first available number.
   */
  // const itemNums = items.map(i => i.item);
  // let i = 1;
  // while (itemNums.includes(i)) {
  //   i++;
  // }
  // return i;
}
