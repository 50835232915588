/**
 * To disable autocomplete on inputs, we need to set "autoComplete={generateAutoCompleteValue()},
 * but this attribute is being ignored by Microsoft Edge, so for this to work on Edge we need to generate a random string.
 *
 * So: if browser is Edge generate a simple random string, otherwise for other browsers return default keyword 'off'
 * More details: https://developer.mozilla.org/en-US/docs/Web/Security/Securing_your_site/Turning_off_form_autocompletion
 *
 * @return string
 */

export function generateAutoCompleteValue() {
  // Newer versions of Edge (Chromium-based) have the user agent `Edg/...`, the condition below will not pass.
  if (navigator.userAgent.indexOf('Edge') > -1) {
    return Math.random()
      .toString(36)
      .replace(/[^a-z]+/g, '')
      .substr(0, 5);
  }

  return 'off';
}
