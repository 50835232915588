import { useAppContext } from '../../../lib/global';
import { useIdleKeepAlive } from '../../../lib/useIdleKeepAlive';
import { Event, Sensor, SensorWithActiveEvent } from './CareContext';
import { useSensorStatusLegacy } from './useSensorStatusLegacy';
import { useSensorStatusSse } from './useSensorStatusSse';
import { useMemo } from 'react';
import { hasBrowserSseSupport } from '../../../lib/hasBrowserSseSupport';

export interface SensorStatusResult {
  alarms: undefined | Event[];
  alerts: undefined | Event[];
  event: { [event_id: number]: Event };
  sensor: { [sensor_id: string]: Sensor };
  sensors: undefined | Sensor[];
  dataTimestamp?: number;
}

/**
 * Remove previous sensors field from SensorStatusResult interface and add the new one with
 * active event field inside sensor object
 */

export type SensorStatusResultWithActiveEvent = Omit<
  SensorStatusResult,
  'sensors'
> & {
  sensors?: SensorWithActiveEvent[];
};

export const useSensorStatus = (): SensorStatusResultWithActiveEvent => {
  const app = useAppContext();
  const sseEnabled =
    app.hasFeature('enable-status-streaming') && hasBrowserSseSupport();

  useIdleKeepAlive({ when: sseEnabled });
  const legacySensorStatus = useSensorStatusLegacy({ skip: sseEnabled });
  const sseSensorStatus = useSensorStatusSse({ skip: !sseEnabled });

  const data = sseEnabled ? sseSensorStatus : legacySensorStatus;

  /**
   * Now, we have sensors and events separately.
   * We want to merge the latest active event into each sensor object:
   */
  const dataWithLatestActiveEvents = useMemo(() => {
    /**
     * First, loop over the events to get the latest event by sensor
     */
    const latestEventBySensor = Object.values(data.event).reduce(
      (prev, curr) => {
        if (
          !prev[curr.sensor?.sensor_composite_id] ||
          prev[curr.sensor?.sensor_composite_id].event_at < curr.event_at
        ) {
          // Give priority to alarm events if the sensor has events of both types
          if (
            prev[curr.sensor?.sensor_composite_id] &&
            prev[curr.sensor?.sensor_composite_id].level === 'alarm' &&
            curr.level === 'alert'
          ) {
            return prev;
          }

          prev[curr.sensor?.sensor_composite_id] = curr;
        }
        return prev;
      },
      {} as { [sensorCompositeId: string]: Event }
    );

    /**
     * Then map over the sensors collection, adding `activeEvent` to each one
     */
    const sensorsWithLatestActiveEvents = data.sensors?.map((sensor) => ({
      ...sensor,
      display_name: sensor.display_name ?? sensor.sensor_composite_id, // If display name is not available, we use sensor_composite_id instead
      display_area: sensor.display_area ?? '', // and for display_area, we just leave it empty.
      activeEvent: latestEventBySensor[sensor.sensor_composite_id],
    }));

    /**
     * And return the data, replacing the old sensor collection with the new
     * sensor collection with `activeEvent` added
     */
    return {
      ...data,
      sensors: sensorsWithLatestActiveEvents,
    };
  }, [data]);

  return dataWithLatestActiveEvents;
};
