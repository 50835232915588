import React from 'react';

import useEventSettingsForm, { EventFormProps } from '../useEventSettingsForm';

import EventActiveControl from '../controls/EventActiveControl';
import EventLevelControl from '../controls/EventLevelControl';
import EventTimeIntervalControl from '../controls/EventTimeIntervalControl';
import EventFormControls from '../controls/EventFormControls';
import EventSelectControl from '../controls/EventSelectControl';
import PlayEventSoundControl from '../controls/PlayEventSoundControl';
import { RouteLeavingGuard } from '../../../../../components/RouteLeavingGuard';
import { Panel } from '../../../../../components/Panel';
import { t } from '../../../../../lib/i18n';
import { getLabelForEventType } from '../../../../../lib/getLabelForEventType';

export default function NoiseEventForm(props: EventFormProps) {
  const form = useEventSettingsForm({
    sensorType: 'roommate',
    eventType: 'hsnd',

    initialValues: props.initialValues,
  });

  return (
    <form onSubmit={form.handleSubmit}>
      <RouteLeavingGuard when={form.dirty} />
      <h2>{getLabelForEventType('hsnd', 'roommate')}</h2>

      <Panel>
        <span
          dangerouslySetInnerHTML={{
            __html: t('manage.sensors.events.NoiseEventForm.firstInfo'),
          }}
        />
      </Panel>

      <EventActiveControl form={form} />

      <EventTimeIntervalControl form={form} />

      <EventSelectControl
        form={form}
        label={t('manage.sensors.events.NoiseEventForm.volume')}
        field="threshold1"
        options={[
          {
            label: `1 (${t('manage.sensors.events.NoiseEventForm.low')})`,
            value: 800,
          },
          { label: `2`, value: 1300 },
          { label: `3`, value: 2000 },
          { label: `4`, value: 3000 },
          { label: `5`, value: 4000 },
          {
            label: `6 (${t('manage.sensors.events.NoiseEventForm.loud')})`,
            value: 5000,
          },
        ]}
      />

      <EventSelectControl
        form={form}
        label={t('manage.sensors.events.NoiseEventForm.durationInSeconds')}
        field="threshold2"
        options={[
          { label: '0.8s', value: 800 },
          { label: '1s', value: 1000 },
          { label: '1.2s', value: 1200 },
          { label: '1.5s', value: 1500 },
          { label: '2s', value: 2000 },
        ]}
      />

      <EventSelectControl
        form={form}
        label={t('manage.sensors.common.quarantineTimeInMinutes')}
        field="quarantine1"
        options={[
          { label: `1 ${t('common.minutes', 1)}`, value: 1 * 60 },
          { label: `2 ${t('common.minutes', 2)}`, value: 2 * 60 },
          { label: `5 ${t('common.minutes', 5)}`, value: 5 * 60 },
          { label: `10 ${t('common.minutes', 10)}`, value: 10 * 60 },
          { label: `15 ${t('common.minutes', 15)}`, value: 15 * 60 },
          { label: `30 ${t('common.minutes', 30)}`, value: 30 * 60 },
        ]}
      />

      <EventLevelControl form={form} />

      <PlayEventSoundControl form={form} />

      <EventFormControls form={form} />
    </form>
  );
}
