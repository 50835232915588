import { orderBy } from 'lodash';

import { IconDefinition } from '../../../components/Icon';
import { eventTypesBySensorType } from '../../../eventTypes';
import * as api from '../../../serverApi';
import { UserStatusType, userStatusTypes } from '../../../userStatusTypes';

/**
 * The various types of dashboard housing unit card designs. This should be reflected in the
 * DashboardHousingUnit component.
 */
export type DashboardZoneModifier =
  | 'alarm'
  | 'alert'
  | 'disconnected'
  | 'default';

export interface DashboardZoneSummary {
  /**
   * Unique key for iterating over the housing units
   */
  key: number | string;

  /**
   * The label to display on the housing unit
   */
  label: string;

  /**
   * The various types of dashboard housing unit card designs.
   */
  modifier: DashboardZoneModifier;

  /**
   * The icon to display on the card. Default is null: no icon if we don't find anything more suitable below.
   */
  icon: IconDefinition | null | undefined;
}

/**
 * This function takes the raw data for a single housing unit (like the
 * getDashboardOverview API call returns) and evaluates the data to display
 * on the dashboard.
 */
export function evaluateDashboardOverviewZone(
  zone: api.DashboardOverviewZoneData
): DashboardZoneSummary {
  /**
   * This is the object we are returning from this function.
   */
  const result: DashboardZoneSummary = {
    key: zone.zone.id,
    label: zone.zone.name ?? '',
    modifier: 'default',
    icon: null,
  };

  /**
   *
   * First, if there are any active events, this takes precedence:
   *
   */

  //Sort the events from the most important to the least one (alarm first then alert and event time)
  const sortedEvents = orderBy(
    zone.events,
    [
      (event) =>
        event.level === 'alarm' ? 2 : event.level === 'alert' ? 1 : 0,
      (event) => event.event_at,
    ],
    ['desc', 'desc']
  );

  const evt = sortedEvents.length ? sortedEvents[0] : undefined;

  if (evt) {
    result.icon =
      eventTypesBySensorType[evt.sensor_type]?.[evt.event_type]?.icon;
    if (evt.level === 'alarm' || evt.level === 'alert') {
      result.modifier = evt.level;
    }
    return result;
  }

  /**
   *
   * Second, if _any_ of the sensors are offline. This only applies to RoomMate
   * sensors, since we don't have online/offline status for Climax sensors.
   *
   */
  if (zone.sensors.find((x) => x.status === 'offline')) {
    result.icon = 'disconnected';
    result.modifier = 'disconnected';
    return result;
  }

  /**
   *
   * Third, if _any_ of the sensors are silenced.
   *
   */
  if (zone.sensors.find((x) => x.status === 'silenced')) {
    result.icon = 'roommate-off';
    result.modifier = 'disconnected';
    return result;
  }

  /**
   *
   * User status
   *
   * | User_status | Description   | Icon               |
   * | ----------- | ------------- | ------------------ |
   * |     uk      | UnKnown       | person.svg         |
   * |     uu      | UserUpright   | person.svg         |
   * |     us      | UserSitting   | person.svg         |
   * |     ul      | UserLaying    | person.svg         |
   * |     of      | OnFloor       | person.svg         |
   * |     lo      | LOst          | img_trans.svg      |xX
   * |     ib      | InBed         | bed-sleeping.svg   |XX
   * |     nu      | NoUsers       | img_trans.svg      |XX
   * |     mu      | MultipleUsers | person-double.svg  |XX
   * |     ub      | UpinBed       | bed-sleeping.svg   |XX
   * |     ic      | InChair       | chair-sitting.svg  |XX
   * |     ps      | PreSence      | presence.svg       |
   *
   */

  // User statuses will be shown in this prioritised order.
  // Earlier entries will be preferred.
  const userStatusPrecedence: UserStatusType[] = [
    'ps',
    'ib',
    'ub',
    'ic',
    'mu',
    'uk',
    'uu',
    'us',
    'ul',
    'of',
  ];

  for (let i = 0; i < userStatusPrecedence.length; i++) {
    // Loop over the sensors to see if any of the sensors match the current user status
    for (let j = 0; j < zone.sensors.length; j++) {
      if (zone.sensors[j].user_status === userStatusPrecedence[i]) {
        result.icon = userStatusTypes[userStatusPrecedence[i]]?.icon;
        return result;
      }
    }
  }

  return result;
}
