import React from 'react';
import { AlertSettingsForm } from './AlertSettingsForm';
import { RouteComponentProps } from 'react-router';
import getParentPath from '../../../lib/getParentPath';
import { IconButton } from '../../../components/IconButton';
import { parseTimeOfDay } from '../../../lib/utils/parseTimeOfDay';
import { useErrorHandler } from '../../../lib/useErrorHandler';
import { RouteLeavingGuard } from '../../../components/RouteLeavingGuard';
import { useAlertSettingsFormik } from './lib/useAlertSettingsFormik';

import { t } from '../../../lib/i18n';

import * as serverApi from '../../../serverApi';

export function NewAlertSetting(props: RouteComponentProps<{}>) {
  const errorHandler = useErrorHandler();

  const formik = useAlertSettingsFormik({
    onSubmit: async (values, helpers) => {
      errorHandler.reset();

      if (
        !(
          values.include_regular_alert_events ||
          values.include_regular_alarm_events
        ) &&
        !values.include_technical_event_types
      ) {
        helpers.setFieldError(
          'event_types',
          t('manage.alerts.common.atLeastOneEventType')
        );
        return false;
      }

      if (!values.zone_id) {
        helpers.setFieldError(
          'zone_id',
          t('manage.alerts.common.aZoneOrHousingUnitIsRequired')
        );
        return false;
      }

      if (
        (!values.escalation_recipients ||
          values.escalation_recipients.length === 0) &&
        values.recipients.length === 0
      ) {
        helpers.setFieldError(
          'escalation_recipients',
          t('manage.alerts.common.atLeastOneNotificationRecipientIsRequired')
        );

        helpers.setFieldError(
          'recipients',
          t('manage.alerts.common.atLeastOneNotificationRecipientIsRequired')
        );

        return false;
      }

      try {
        const submitValues: serverApi.CreateAlertSettingInput = {
          ...values,
          recipients: values.recipients.map((r) => r.id),
          escalation_recipients: values.escalation_recipients
            ? values.escalation_recipients.map((er) => er.id)
            : [],
          schedule: values.schedule.map((s) => ({
            ...s,
            from_time: parseTimeOfDay(s.from_time) || '',
            to_time: parseTimeOfDay(s.to_time) || '',
          })),
          event_types: values.event_types.map((event_type) => ({
            sensor_type: 'roommate',
            event_type,
          })),
        };
        const data = await serverApi.createAlertSetting(
          values.zone_id,
          submitValues
        );
        formik.resetForm();
        props.history.push('/manage/' + data.zone.id + '/alerts');
      } catch (e) {
        errorHandler.handleError(e);
      }
    },
  });

  return (
    <>
      <RouteLeavingGuard when={formik.dirty} />

      <h1>{t('manage.alerts.NewAlertSetting.newNotificationRule')}</h1>

      <div className="my-4">
        <IconButton
          icon="arrow-left"
          to={getParentPath(props.location.pathname)}
        >
          {t('common.commonButtons.back')}
        </IconButton>
      </div>

      <AlertSettingsForm mode="create" error={errorHandler} formik={formik} />
    </>
  );
}
