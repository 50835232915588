import { useState, useEffect, useRef } from 'react';
import { t } from '../../../../../lib/i18n';

interface Options {
  sensorImageSrc?: string;
  refreshImage?: () => void;
}

export function useSensorImageLoader(opts: Options) {
  const [loading, setLoading] = useState(false);

  const [attempts, setAttempts] = useState(0);
  const [attemptingToLoad, setAttemptingToLoad] = useState(false);

  const [error, setError] = useState<Error | undefined>(undefined);

  const img = useRef(new Image());

  useEffect(() => {
    if (!attemptingToLoad && attempts > 10) {
      setAttempts(0);
      setError(undefined);
      setAttemptingToLoad(false);
      if (opts.refreshImage) {
        opts.refreshImage();
      }
    }
  }, [opts.sensorImageSrc]); // eslint-disable-line react-hooks/exhaustive-deps

  /**
   * Reload image when the sensor image source changes.
   */
  useEffect(() => {
    setLoading(true);

    const timeout = setTimeout(() => {
      img.current.src = opts.sensorImageSrc ?? '';

      img.current.onload = ev => {
        console.log('Image loaded', ev);
        setLoading(false);
        setAttempts(0);
        setError(undefined);
        setAttemptingToLoad(false);
      };

      img.current.onerror = (
        event: Event | string,
        source?: string,
        lineno?: number,
        colno?: number,
        error?: Error
      ) => {
        console.log(
          'Error while loading image',
          event,
          source,
          lineno,
          colno,
          error
        );

        setError(error);
        setAttemptingToLoad(true);

        if (attempts > 10) {
          setAttemptingToLoad(false);
          return;
        }

        if (opts.refreshImage) {
          setAttempts(attempts + 1);
          opts.refreshImage();
        }
      };
    }, 1000);

    return () => {
      clearTimeout(timeout);
    };
  }, [opts.sensorImageSrc]); // eslint-disable-line react-hooks/exhaustive-deps

  const loadingMessage =
    attempts > 8
      ? t('manage.sensors.marking.lib.useSensorImageLoader.couldNotLoadImageClickRefreshImageToTryAgain')
      : attempts >= 3
      ? `${t('manage.sensors.marking.lib.useSensorImageLoader.stillLoadingPleaseWait')} ...`
      : `${t('common.commonTexts.loading')} ...`;

  return {
    loading,
    loadingMessage,
    attempts,
    error,
    img,
  };
}
