import React from 'react';
import { RouteComponentProps } from 'react-router';
import * as api from '../../../serverApi';
import { useApiCall } from '../../../lib/useApiCall';
import { InlineSpinner } from '../../../components/InlineSpinner';
import { EditAlertSettingForm } from './EditAlertSettingForm';
import { t } from '../../../lib/i18n';

export function EditAlertSetting(props: RouteComponentProps<{ id: string }>) {
  const alertSettingId = parseInt(props.match.params.id, 10);
  const res = useApiCall(api.getAlertSetting, [alertSettingId]);

  if (res.loading) {
    return <InlineSpinner text={t('common.commonTexts.loading')} />;
  }

  if (res.data === undefined) {
    return <p className="text-danger">{t('common.commonTexts.error')}</p>;
  }

  const dataWithOnlyRoomMateEventTypes = {
    ...res.data,
    event_types: res.data.event_types
      .filter((s) => s.sensor_type === 'roommate')
      .map((x) => x.event_type),
    zone_id: res.data.zone.id,
  };

  return (
    <EditAlertSettingForm
      alertSettingId={alertSettingId}
      data={dataWithOnlyRoomMateEventTypes}
    />
  );
}
