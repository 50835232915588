import React from 'react';
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from '@material-ui/pickers';
import moment from 'moment';
import MomentUtils from '@date-io/moment';
import { RolePicker } from '../RolePicker';
import { FormGroup } from '@material-ui/core';
import { CreateUserRoleInput, StructureZone } from '../../../../serverApi';
import { Button } from '../../../../components/Button';
import { useZoneTreeContext } from '../../../care/lib/ZoneTreeContext';
import { BorderWrapper } from '../../../../components/BorderWrapper';
import { ZonePickerField } from '../../../../components/ZonePickerField/ZonePickerField';
import * as serverApi from '../../../../serverApi';
import { t } from '../../../../lib/i18n';
import { getGlobalLocale } from '../../../../lib/i18n/i18n';

interface Props {
  loading?: boolean;
  mode: 'edit' | 'create';
  role: CreateUserRoleInput;
  deleteDisabled?: boolean;

  /**
   * An array of all available roles.
   */
  availableRoles?: serverApi.GetAvailableRolesResult['roles'];

  /**
   * An array of the roles that should _not_ be selectable nor visible in the role
   * selector. Does not make sense to use this at the same time as roleWhitelist.
   */
  roleBlacklist?: string[];

  /**
   * An array of the roles that should be selectable nor visible in the role selector.
   * If this is provided, other roles will be hidden. Does not make sense to use this
   * at the same time as roleBlacklist.
   */
  roleWhitelist?: string[];

  error?: string;

  /**
   * Locked role -- whether the role selection and delete button should be disabled.
   */
  roleLocked?: boolean;

  /**
   * Locked zone -- whether the zone should be locked to a zone could be the name of the zone or boolean
   */
  zoneLocked?: boolean | string;

  setRole?: (role: string) => void;
  setZone?: (zoneId: number) => void;
  setValidFrom: (time: moment.Moment | null) => void;
  setValidTo: (time: moment.Moment | null) => void;
  onRemove?: (role: CreateUserRoleInput) => void;
  onCancel?: () => void;
  onSave?: () => void;
}

export function RoleForm(props: Props) {
  const treeContext = useZoneTreeContext();

  const zone = treeContext.getZoneById(props.role.zone_id);

  const validFrom = props.role.valid_from
    ? moment(props.role.valid_from, 'YYYY-MM-DD')
    : null;

  const validTo = props.role.valid_to
    ? moment(props.role.valid_to, 'YYYY-MM-DD')
    : null;

  const handleSelectZone = (zone?: StructureZone) => {
    if (!zone) {
      return;
    }
    if (props.setZone) {
      props.setZone(zone.id);
    }
  };

  const handleSelectRole = (role: string | null) => {
    if (!role) {
      return;
    }

    if (props.setRole) {
      props.setRole(role);
    }
  };

  const handleRemove = () => {
    if (props.onRemove) {
      props.onRemove(props.role);
    }
  };

  const handleCancel = () => {
    if (props.onCancel) {
      props.onCancel();
    }
  };

  const handleSave = () => {
    if (props.onSave) {
      props.onSave();
    }
  };

  return (
    <div className="RoleForm" style={{ marginBottom: 30 }}>
      <BorderWrapper>
        <h3>{t('manage.permissions.forms.RoleForm.roleAccess')}</h3>

        <FormGroup className="mt-4">
          <RolePicker
            availableRoles={props.availableRoles}
            roleBlacklist={props.roleBlacklist}
            roleWhitelist={props.roleWhitelist}
            className="w-100"
            role={props.role.role}
            disabled={props.roleLocked}
            onChange={(role) => handleSelectRole(role)}
          />
        </FormGroup>

        <FormGroup className="mt-4">
          <ZonePickerField
            hideSelectButton
            showTextFieldZoneIcon
            textFieldStyle={{ marginRight: 0 }}
            className="w-100 m-0"
            label={zone?.name && t('common.commonTexts.selectedZone')}
            disabled={!!props.zoneLocked}
            selectedZone={
              !!props.zoneLocked
                ? {
                    id: props.role.zone_id,
                    name:
                      typeof props.zoneLocked === 'string'
                        ? props.zoneLocked
                        : '',
                  }
                : undefined
            }
            initialZoneId={props.role.zone_id}
            onSelectZone={(zone?: StructureZone) => handleSelectZone(zone)}
          />
        </FormGroup>

        <div className="mt-4">
          <MuiPickersUtilsProvider
            locale={getGlobalLocale()}
            utils={MomentUtils}
          >
            <KeyboardDatePicker
              margin="normal"
              label={t('manage.permissions.forms.RoleForm.validFrom')}
              format="DD/MM/YY"
              okLabel={t('common.commonButtons.ok')}
              cancelLabel={t('common.commonButtons.cancel')}
              value={validFrom}
              maxDate={validTo || undefined}
              onChange={(date) => {
                props.setValidFrom(date);
              }}
              invalidDateMessage={t('common.commonTexts.wrongFormat')}
              KeyboardButtonProps={{
                'aria-label': 'change date',
              }}
            />
            <br />
            <KeyboardDatePicker
              margin="normal"
              label={t('manage.permissions.forms.RoleForm.validTo')}
              format="DD/MM/YY"
              okLabel={t('common.commonButtons.ok')}
              cancelLabel={t('common.commonButtons.cancel')}
              value={validTo}
              minDate={validFrom || undefined}
              onChange={(date) => {
                props.setValidTo(date);
              }}
              invalidDateMessage={t('common.commonTexts.wrongFormat')}
              KeyboardButtonProps={{
                'aria-label': 'change date',
              }}
            />
          </MuiPickersUtilsProvider>
        </div>
        {props.mode === 'edit' && (
          <div className="mt-4">
            <Button
              icon={props.loading ? 'gear' : undefined}
              spinIcon={props.loading}
              disabled={props.loading}
              color="primary"
              className="mr-2"
              onClick={handleSave}
            >
              {t('common.commonButtons.save')}
            </Button>
            <Button light={true} className="mr-2" onClick={handleCancel}>
              {t('common.commonButtons.cancel')}
            </Button>
          </div>
        )}
        {props.mode === 'create' && (
          <div className="mt-4">
            <Button
              color="secondary"
              onClick={handleRemove}
              disabled={props.deleteDisabled}
            >
              {t('common.commonButtons.delete')}
            </Button>
          </div>
        )}
        {/*{props.loading && <div className="mt-3">Vennligst vent...</div>}*/}
        {props.error && (
          <div
            className="mt-3 text-danger"
            data-cy="manage-new-user-role-error"
          >
            {props.error}
          </div>
        )}
      </BorderWrapper>
    </div>
  );
}
