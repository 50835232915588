import React from 'react';

import {
  FormGroup,
  FormControl,
  FormControlLabel,
  Checkbox,
} from '@material-ui/core';

import { t, TranslationKey } from '../../../lib/i18n';

import './EventTypeSelector.scss';

interface Props {
  disabled?: boolean;

  /**
   * Whether to always show the custom option. The custom option will always be
   * shown if there are any custom event types (i.e. the `customEventTypes`
   * array has one or more elements).
   */
  alwaysShowCustomOption: boolean;

  /**
   * The currently selected custom event types.
   */
  customEventTypes: string[];
  onChangeCustomEventTypes: (selectedCustomEventTypes: string[]) => void;

  includeRegularAlarmTypes?: boolean;
  includeRegularAlertTypes?: boolean;
  includeTechnicalEventTypes?: boolean;
  includeCustomEventTypes?: boolean;
  setIncludeRegularAlarmTypes?: (includeRegularAlarmTypes: boolean) => void;
  setIncludeRegularAlertTypes?: (includeRegularAlertTypes: boolean) => void;
  setIncludeTechnicalEventTypes?: (includeTechnicalEventTypes: boolean) => void;
  setIncludeCustomEventTypes?: (includeCustomEventTypes: boolean) => void;
}

/**
 * Added from the obsolete and removed src/lib/utils/eventTypes.ts. This should be replaced
 * with the proper list from src/eventTypes.ts.
 */
const eventTypes: Array<{
  event_type: string;
  label_key: TranslationKey;
}> = [
  { event_type: 'abs', label_key: 'common.commonEvents.roommate.abs' },
  { event_type: 'act', label_key: 'common.commonEvents.roommate.act' },
  { event_type: 'bedmark', label_key: 'common.commonEvents.roommate.bedmark' },
  { event_type: 'ent', label_key: 'common.commonEvents.roommate.ent' },
  { event_type: 'exi', label_key: 'common.commonEvents.roommate.exi' },
  { event_type: 'fall', label_key: 'common.commonEvents.roommate.fall' },
  { event_type: 'fire', label_key: 'common.commonEvents.roommate.fire' },
  { event_type: 'floor', label_key: 'common.commonEvents.roommate.floor' },
  { event_type: 'hib', label_key: 'common.commonEvents.roommate.hib' },
  { event_type: 'hsnd', label_key: 'common.commonEvents.roommate.hsnd' },
  { event_type: 'inact', label_key: 'common.commonEvents.roommate.inact' },
  { event_type: 'lmib', label_key: 'common.commonEvents.roommate.lmib' },
  {
    event_type: 'markings',
    label_key: 'common.commonEvents.roommate.markings',
  },
  { event_type: 'offline', label_key: 'common.commonEvents.roommate.offline' },
  { event_type: 'online', label_key: 'common.commonEvents.roommate.online' },
  { event_type: 'oob', label_key: 'common.commonEvents.roommate.oob' },
  { event_type: 'ooc', label_key: 'common.commonEvents.roommate.ooc' },
  { event_type: 'startup', label_key: 'common.commonEvents.roommate.startup' },
  { event_type: 'tlib', label_key: 'common.commonEvents.roommate.tlib' },
];

export function EventTypeSelector(props: Props) {
  const showCustom =
    props.alwaysShowCustomOption ||
    props.customEventTypes.length > 0 ||
    props.includeCustomEventTypes;

  function handleChange(e: React.ChangeEvent<HTMLInputElement>) {
    const newEventTypes = props.customEventTypes.filter(
      (x) => x !== e.target.value
    );
    if (e.target.checked) {
      newEventTypes.push(e.target.value);
    }
    props.onChangeCustomEventTypes(newEventTypes);
  }

  function handleIncidentsChange(value: boolean) {
    props.setIncludeRegularAlarmTypes?.(value);
    props.setIncludeRegularAlertTypes?.(value);
  }

  return (
    <div className="EventTypeSelector">
      <FormGroup>
        <FormControl component="fieldset">
          <FormGroup>
            <FormControlLabel
              disabled={props.disabled}
              control={
                <Checkbox
                  color="primary"
                  checked={Boolean(
                    props.includeRegularAlarmTypes ||
                      props.includeRegularAlertTypes
                  )}
                  onChange={(e) =>
                    handleIncidentsChange(e.currentTarget.checked)
                  }
                />
              }
              label={t(
                'manage.alerts.EventTypeSelector.includeRegularEventTypes'
              )}
            />

            <div className="EventTypeSelector__incident-types">
              <FormControlLabel
                disabled={props.disabled}
                control={
                  <Checkbox
                    color="primary"
                    checked={Boolean(props.includeRegularAlarmTypes)}
                    onChange={(e) =>
                      props.setIncludeRegularAlarmTypes?.(
                        e.currentTarget.checked
                      )
                    }
                  />
                }
                label={t(
                  'manage.alerts.EventTypeSelector.includeRegularAlarms'
                )}
              />
              <FormControlLabel
                disabled={props.disabled}
                control={
                  <Checkbox
                    color="primary"
                    checked={Boolean(props.includeRegularAlertTypes)}
                    onChange={(e) =>
                      props.setIncludeRegularAlertTypes?.(
                        e.currentTarget.checked
                      )
                    }
                  />
                }
                label={t(
                  'manage.alerts.EventTypeSelector.includeRegularAlerts'
                )}
              />
            </div>

            <FormControlLabel
              disabled={props.disabled}
              control={
                <Checkbox
                  color="primary"
                  checked={Boolean(props.includeTechnicalEventTypes)}
                  onChange={(e) =>
                    props.setIncludeTechnicalEventTypes?.(
                      e.currentTarget.checked
                    )
                  }
                />
              }
              label={t(
                'manage.alerts.EventTypeSelector.includeTechnicalEventTypes'
              )}
            />
            {showCustom && (
              <FormControlLabel
                disabled={props.disabled}
                control={
                  <Checkbox
                    color="primary"
                    checked={Boolean(props.includeCustomEventTypes)}
                    onChange={(e) =>
                      props.setIncludeCustomEventTypes?.(
                        e.currentTarget.checked
                      )
                    }
                  />
                }
                label={t(
                  'manage.alerts.EventTypeSelector.includeCustomEventTypes'
                )}
              />
            )}

            {props.includeCustomEventTypes && (
              <>
                <p>-------------</p>
                {eventTypes.map((type) => (
                  <FormControlLabel
                    disabled={props.disabled}
                    key={type.event_type}
                    control={
                      <Checkbox
                        checked={Boolean(
                          props.customEventTypes.includes(type.event_type)
                        )}
                        onChange={handleChange}
                        value={type.event_type}
                        color="primary"
                      />
                    }
                    label={t(type.label_key)}
                  />
                ))}
              </>
            )}
          </FormGroup>
        </FormControl>
      </FormGroup>
    </div>
  );
}
