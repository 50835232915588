import React from 'react';
import './DesktopSplashPage.scss';
import { Redirect } from 'react-router';

export const DesktopSplashPage = () => {
  // If first time send to help
  //if (useFirstTimeOnly('manage-view')) {
  return <Redirect to="/manage/help" />;
  //}
  /*
  return (
    <div className="DesktopSplashPage Manage-round-container Manage-main">
      <CenteredLogo title={t('manage.common.ManageDefaultTitle')} />
    </div>
  );*/
};
