// import React, { useState } from 'react';
import React from 'react';
import { Link } from 'react-router-dom';
// import { Spinner } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  // faEdit,
  // faTrash,
  // faPlus,
  faSyncAlt,
  faSpinnerThird,
} from '@fortawesome/pro-solid-svg-icons';
// import { deleteZone } from '../../../serverApi';
// import { useConfirmation } from '../../../lib/confirmation/ConfirmationContext';

import * as serverApi from '../../../serverApi';
import { useApiCall } from '../../../lib/useApiCall';
import {
  Button,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from '@material-ui/core';
import { useSuperStyles } from '../useSuperStyles';
import { HeadingBar } from '../shared/HeadingBar';
import { Card } from '../shared/Card';
import { TableWrapper } from '../../../components/TableWrapper';

interface Props {}

export const ListZones: React.FC<Props> = () => {
  // const confirmation = useConfirmation();
  // const [error, setError] = useState('');
  const error = '';
  const c = useSuperStyles();

  const res = useApiCall(serverApi.superGetZones);
  const zones = res.data && res.data.data;

  return (
    <>
      <HeadingBar>
        <h2>Zones</h2>

        <div
          style={{
            height: '25px',
            width: '25px',
            fontSize: '25px',
            lineHeight: '25px',
          }}
        >
          {res.loading && <FontAwesomeIcon icon={faSpinnerThird} spin={true} />}
        </div>

        <Button onClick={res.reload} disabled={res.loading}>
          <FontAwesomeIcon
            icon={faSyncAlt}
            spin={res.loading}
            className="mr-2"
          />
          Refresh
        </Button>
      </HeadingBar>

      <Card>
        {res.error && <div className="text-danger my-4 p-5">{error}</div>}
        <TableWrapper>
          <Table className={c.table}>
            <TableHead>
              <TableRow>
                <TableCell>ID</TableCell>
                <TableCell>Name</TableCell>
                <TableCell>Account</TableCell>
                <TableCell>Parent zone</TableCell>
                <TableCell>Actions</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {zones &&
                zones.map((zone) => (
                  <TableRow key={zone.zone_id}>
                    <TableCell component="th" scope="row">
                      {zone.zone_id}
                    </TableCell>
                    <TableCell>{zone.name}</TableCell>
                    <TableCell>
                      <Link to={`/super/accounts/${zone.account_id}`}>
                        {zone.account_name}
                      </Link>
                    </TableCell>
                    <TableCell>{zone.parent_zone_name}</TableCell>
                    <TableCell>
                      {/*
                        <button
                          className="btn"
                          title="Delete Zone"
                          onClick={() => handleDeleteZone(user.id)}
                        >
                          {deleting === user.id ? (
                            <Spinner size="sm" color="danger" />
                          ) : (
                            <span className="text-danger">
                              <FontAwesomeIcon icon={faTrash} />
                            </span>
                          )}
                        </button>
                          */}
                    </TableCell>
                  </TableRow>
                ))}
            </TableBody>
          </Table>
        </TableWrapper>
      </Card>
    </>
  );
};
