import React, { useState } from 'react';
import ModalWrapper from '../modals/ModalWrapper';
import * as api from '../../serverApi';
import { TreeView } from '../TreeView';
import { useZoneTreeContext } from '../../areas/care/lib/ZoneTreeContext';
import { t } from '../../lib/i18n';

interface Props {
  isOpen: boolean;
  selectedZoneId?: number | null;
  disableBranchId?: number;
  disabledZoneIds?: number[];
  handleModal: () => void;
  onSelect: (zone?: api.StructureZone) => void;
}
const ZonePickerModal = (props: Props) => {
  const tree = useZoneTreeContext();

  const initalActiveZone = props.selectedZoneId
    ? tree.getZoneById(props.selectedZoneId)
    : undefined;

  const [activeZone, setActiveZone] = useState<api.StructureZone | undefined>(
    initalActiveZone
  );

  const handleSubmit = () => {
    props.onSelect(activeZone);
    props.handleModal();
  };

  return (
    <ModalWrapper
      title={t('components.ZonePickerModal.selectZoneOrHousingUnit')}
      okButtonText={t('common.commonButtons.select')}
      cancelButtonText={t('common.commonButtons.cancel')}
      className="ModalWrapper-with-tree-view"
      isOpen={props.isOpen}
      onHide={props.handleModal}
      onSubmit={handleSubmit}
    >
      <div>
        {tree.root && (
          <TreeView
            root={tree.root}
            selectZone={setActiveZone}
            activeZone={activeZone}
            disableBranchId={props.disableBranchId}
            disabledZoneIds={props.disabledZoneIds}
          />
        )}
      </div>
    </ModalWrapper>
  );
};

export default ZonePickerModal;
