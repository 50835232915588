import React, { useState, useEffect } from 'react';
import { Redirect, Link } from 'react-router-dom';
import { TextField, FormGroup } from '@material-ui/core';

import qs from 'query-string';

import { useAppContext } from '../../lib/global';
import { useRouter } from '../../lib/useRouter';

import { forgotPassword } from '../../serverApi';
import { Row, Col } from 'reactstrap';
import { LayoutWithBackground } from './LayoutWithBackground';
import { CenteredCard } from './CenteredCard';
import { PublicFooter } from './PublicFooter';
import { PublicHeader } from './PublicHeader';
import { errorIsObjectError, useErrorHandler } from '../../lib/useErrorHandler';
import { ErrorMessages } from '../../components/ErrorMessages';
import { Panel } from '../../components/Panel';
import { t } from '../../lib/i18n';

export function ForgotPassword() {
  const app = useAppContext();
  const router = useRouter();
  const errorHandler = useErrorHandler();

  const [loading, setLoading] = useState(false);
  const [sent, setSent] = useState<string | boolean>(false);

  const [username, setUsername] = useState('');
  const [accountCode, setAccountCode] = useState('');

  useEffect(() => {
    let timeout = setTimeout(() => {
      setSent(false);
    }, 5000);
    return () => clearTimeout(timeout);
  }, [sent]);

  if (app.isAuthenticated) {
    return <Redirect to="/" />;
  }

  if (app.isLoading) {
    return (
      <div className="App App-public" id="app">
        <div className="App-body" id="appBody">
          <LayoutWithBackground>
            <CenteredCard>
              <div style={{ fontSize: '80px', textAlign: 'center' }}>
                <i className="fas fa-spinner fa-spin mr-1" />
              </div>
            </CenteredCard>
          </LayoutWithBackground>
        </div>
      </div>
    );
  }

  if (app.isAuthenticated) {
    const values = qs.parse(router.location.search);
    return (
      <Redirect to={`${values.redirect || ''}${values.hash || ''}` || '/'} />
    );
  }

  const onSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    await handleForgotPassword(username, accountCode);
  };

  const handleForgotPassword = async (username: string, account: string) => {
    errorHandler.reset();
    try {
      setLoading(true);
      await forgotPassword({ username, account });
      setUsername('');
      setAccountCode('');
      setLoading(false);
      setSent(true);
    } catch (error) {
      setLoading(false);
      setSent(false);
      errorHandler.handleError(error);
    }
  };

  return (
    <div className="App App-public" id="app">
      <div className="App-body" id="appBody">
        <LayoutWithBackground>
          <div className="ForgotPassword">
            <PublicHeader />

            <CenteredCard>
              <h1>
                {t('publicComponents.ForgotPassword.forgotYourPassword')}?
              </h1>
              <div className="mt-4">
                <Row>
                  <Col lg="10">
                    <p className="mb-1">
                      {t(
                        'publicComponents.ForgotPassword.enterTheUsernameOrEmailAddressYouUseToLogIn'
                      )}
                      :
                    </p>

                    <ErrorMessages errorData={errorHandler} />

                    <form onSubmit={onSubmit}>
                      <FormGroup>
                        <TextField
                          disabled={loading}
                          type="text"
                          name="account"
                          placeholder={t('publicComponents.common.account')}
                          className="w-100"
                          InputProps={{
                            className: 'text-field-input',
                          }}
                          value={accountCode}
                          error={
                            errorIsObjectError(errorHandler.error) &&
                            !!errorHandler.error['account']
                          }
                          helperText={
                            errorIsObjectError(errorHandler.error) &&
                            errorHandler.error['account']
                          }
                          onChange={(e) =>
                            setAccountCode(e.currentTarget.value)
                          }
                        />
                      </FormGroup>
                      <FormGroup className="mb-3">
                        <TextField
                          disabled={loading}
                          type="text"
                          name="username"
                          placeholder={t('common.commonTexts.userName')}
                          className="w-100"
                          InputProps={{
                            className: 'text-field-input',
                          }}
                          value={username}
                          error={
                            errorIsObjectError(errorHandler.error) &&
                            !!errorHandler.error['username']
                          }
                          helperText={
                            errorIsObjectError(errorHandler.error) &&
                            errorHandler.error['username']
                          }
                          onChange={(e) => setUsername(e.currentTarget.value)}
                        />
                      </FormGroup>

                      <button
                        type="submit"
                        className="btn btn-default btn-login btn-green"
                        disabled={loading}
                      >
                        {loading
                          ? `${t('common.commonTexts.pleaseWait')} ...`
                          : t('common.commonButtons.send')}
                      </button>

                      {sent && (
                        <Panel className="mt-4">
                          <h3>{t('publicComponents.ForgotPassword.alert')}</h3>
                          {t(
                            'publicComponents.ForgotPassword.youWillImmediatelyReceiveAnEmailWithInstructionsOnHowToResetYourPassword'
                          )}
                          .
                        </Panel>
                      )}
                    </form>

                    <p className="my-3">
                      <Link to="/login">
                        &laquo; {t('publicComponents.common.backToLogin')}
                      </Link>
                    </p>
                  </Col>
                </Row>
              </div>
            </CenteredCard>
            <PublicFooter />
          </div>
        </LayoutWithBackground>
      </div>
    </div>
  );
}
