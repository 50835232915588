import React, { CSSProperties } from 'react';
import cs from 'classnames';

import './Button.scss';

import { Button as MaterialButton } from '@material-ui/core';
import { IconDefinition, Icon } from '../Icon';
import { Link } from 'react-router-dom';

export type ButtonProps = {
  variant?: 'text' | 'outlined' | 'contained';
  color?: 'default' | 'primary' | 'secondary';
  light?: boolean;
  disabled?: boolean;
  bigIcon?: boolean;
  type?: 'button' | 'submit' | 'reset';
  children?: React.ReactNode;
  className?: string;
  style?: CSSProperties;
  small?: boolean;
  icon?: IconDefinition;
  spinIcon?: boolean;
  hideLabel?: boolean;

  /**
   * If this is provided, the button will render as a link.
   */
  to?: string;

  onClick?: (e: React.MouseEvent<HTMLButtonElement>) => void;
};

export function Button(props: ButtonProps) {
  const {
    color,
    children,
    light,
    bigIcon,
    className,
    small,
    hideLabel,
    icon,
    spinIcon,
    ...rest
  } = props;

  const materialColor =
    color === 'primary' || color === 'secondary' ? color : undefined;

  const classes = cs(
    'MuiButton-default',
    (light && materialColor) || !materialColor
      ? 'MuiButton-inverse'
      : undefined,

    'Button',
    bigIcon ? 'Button--big-icon' : undefined,
    small ? 'Button--small' : undefined,
    hideLabel ? 'Button--no-label' : undefined,
    className
  );

  return (
    <MaterialButton
      variant={props.variant}
      color={materialColor}
      className={classes}
      size={small ? 'small' : 'medium'}
      to={props.to}
      {...(props.to ? { component: Link } : {})}
      {...rest}
    >
      {icon && (
        <Icon
          className="Button-icon"
          spin={spinIcon}
          icon={icon}
          style={!children || props.hideLabel ? { marginRight: 0 } : {}}
        />
      )}
      {hideLabel ? <span className="sr-only">{children}</span> : children}
    </MaterialButton>
  );
}
