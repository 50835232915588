import React, { useMemo, useState, useEffect } from 'react';
import { CSSProperties } from 'react';

import { Button, MenuItem, Select } from '@material-ui/core';
import {
  KeyboardTimePicker,
  MuiPickersUtilsProvider,
} from '@material-ui/pickers';
import moment from 'moment';
import MomentUtils from '@date-io/moment';
import * as api from '../../../../../serverApi';
import { useFormik } from 'formik';
import './SensorUpgradeSettings.scss';
import * as Yup from 'yup';
import { toast } from 'react-toastify';
import { Card } from '../../../../super/shared/Card';
import { useAppContext } from '../../../../../lib/global';
import { getGlobalLocale } from '../../../../../lib/i18n/i18n';

interface SensorUpgradeSettingsValues {
  upgrade_channel: string;
  upgrade_time: string;
}

interface Props {
  sensor_type: api.SensorType;
  sensor_id: number;
  initialValues?: SensorUpgradeSettingsValues;
  onChange?: () => void;
  className?: string;
  style?: CSSProperties;
}

export function SensorUpgradeSettings(props: Props) {
  const app = useAppContext();
  const sensorCompositeId = `${props.sensor_type}${props.sensor_id}`;
  const [loading, setLoading] = useState(!props.initialValues);

  const [initialValues, setInitialValues] = useState({
    upgrade_channel: props.initialValues?.upgrade_channel ?? '',
    upgrade_time: props.initialValues?.upgrade_time ?? '',
  });

  useEffect(() => {
    async function fetchSettings() {
      setLoading(true);
      try {
        const res = await api.getSensorSettings(sensorCompositeId);
        setInitialValues({
          upgrade_channel: res.upgrade_channel ?? '',
          upgrade_time: res.upgrade_time ?? '',
        });
      } catch (err) {
        console.error(err);
        toast.error('Something went wrong.');
      }
      setLoading(false);
    }
    if (!props.initialValues) {
      fetchSettings();
    } else {
      setInitialValues({
        upgrade_channel: props.initialValues?.upgrade_channel ?? '',
        upgrade_time: props.initialValues?.upgrade_time ?? '',
      });
    }
  }, [sensorCompositeId, props.initialValues]);

  const formik = useFormik<SensorUpgradeSettingsValues>({
    enableReinitialize: true,
    initialValues,
    validateOnChange: true,
    validateOnBlur: false,
    validationSchema: () =>
      Yup.object().shape({
        upgrade_channel: Yup.string()
          .required()
          .oneOf(['debug', 'release', 'dev']),
        upgrade_time: Yup.string()
          .required()
          .test('Upgrade time', 'Invalid time', (x) =>
            moment(x, 'HH:mm:ss', true).isValid()
          ),
      }),
    onSubmit: async (values, formik) => {
      if (!sensorCompositeId) {
        return;
      }
      try {
        const res = await api.updateSensorSetting(sensorCompositeId, values);
        await api.sendConfSignal(sensorCompositeId);
        setInitialValues({
          upgrade_time: res.sensor_setting.upgrade_time ?? '',
          upgrade_channel: res.sensor_setting.upgrade_channel ?? '',
        });
        toast.success('Settings updated');
        props.onChange?.();
      } catch (error) {
        console.error(error);
        toast.error('Something went wrong.');
      }
    },
  });

  /**
   * Reset users input to inital input
   */
  const handleReset = () => {
    formik.resetForm();
  };

  const upgradeTime = useMemo(
    () =>
      formik.values.upgrade_time === ''
        ? null
        : moment(formik.values.upgrade_time, 'HH:mm:ss'),
    [formik.values.upgrade_time]
  );

  const hasSuperCapability = app.hasCapability('globalSuper');

  return (
    <Card style={props.style} className={props.className}>
      <h3>Upgrade settings</h3>

      <p>
        An <code>uploadConf</code> signal will automatically be sent when
        saving.
      </p>

      <div className="SensorUpgradeSettings">
        <div className="SensorUpgradeSettings-titles">
          <span>Channel: </span>
          <span>Time: </span>
        </div>

        <div>
          <div>
            <Select
              readOnly={!hasSuperCapability}
              style={{ width: '100%' }}
              name="upgrade_channel"
              disabled={formik.isSubmitting || loading || !hasSuperCapability}
              value={formik.values.upgrade_channel}
              onChange={formik.handleChange}
            >
              <MenuItem value="debug">Debug</MenuItem>
              <MenuItem value="release">Release</MenuItem>
              <MenuItem value="dev">Dev</MenuItem>
            </Select>
          </div>

          <MuiPickersUtilsProvider
            locale={getGlobalLocale()}
            utils={MomentUtils}
          >
            <KeyboardTimePicker
              readOnly={!hasSuperCapability}
              name="upgrade_time"
              disabled={formik.isSubmitting || loading || !hasSuperCapability}
              clearable
              ampm={false}
              minutesStep={5}
              format={'HH:mm'}
              KeyboardButtonProps={{
                style: {
                  display: 'none',
                },
              }}
              style={{ maxWidth: '120px' }}
              value={upgradeTime}
              onChange={(val) => {
                if (val?.format('HH:mm') !== 'Invalid date') {
                  formik.setFieldTouched('upgrade_time');
                  formik.setFieldValue(
                    'upgrade_time',
                    val ? val.format('HH:mm:ss') : ''
                  );
                }
              }}
            />
          </MuiPickersUtilsProvider>
        </div>

        <div>
          <div>
            <Button
              onClick={handleReset}
              disabled={!formik.dirty || loading || !hasSuperCapability}
              variant="text"
            >
              Reset
            </Button>
          </div>
          <div>
            <Button
              disabled={
                !formik.dirty ||
                !formik.isValid ||
                loading ||
                !hasSuperCapability
              }
              onClick={() => formik.submitForm()}
              variant="contained"
            >
              Save
            </Button>
          </div>
        </div>
      </div>
    </Card>
  );
}
