import { AxiosError, AxiosResponse } from 'axios';

/**
 * Type guard function to check if the error given is an AxiosError type;
 * which is any error thrown by the axios library. It can be any non-successful
 * result from the server, or it can be a network error or something similar.
 */
export const isAxiosError = <T = any>(err: unknown): err is AxiosError<T> => {
  return (
    typeof err === 'object' &&
    err !== null &&
    Boolean((err as AxiosError).isAxiosError)
  );
};

/**
 * A more specfic type-guard helper function for checking if error thrown is
 * because of network issues.
 */
export const isAxiosNetworkError = (err: AxiosError) => {
  let errorJSON;

  if (err.toJSON().hasOwnProperty('message')) {
    errorJSON = err.toJSON() as { message: string };
  }
  return errorJSON?.message === 'Network Error';
};

/**
 * An error object representing the result from a validation failure
 * in Laravel, with an errors object containing error messages for
 * individual fields.
 */
type ValidationError = Omit<AxiosError, 'response'> & {
  response: AxiosResponse<{
    errors: Record<string, string[]>;
  }>;
};

/**
 * Check if the given error is a validation error
 */
export const isValidationError = (error: unknown): error is ValidationError =>
  Boolean(
    // If the error thrown isn't an axios error, we don't proceed further
    isAxiosError(error) &&
      // If error status is 422 and we have an errors object provided in the response
      // we know that the given error is a validation error
      error.response &&
      error.response.data &&
      error.response.status === 422 &&
      'errors' in error.response.data
  );

/**
 * Checks if the given error is an invalid credentials one.
 */
export const isInvalidCredentialsError = (error: unknown) =>
  Boolean(
    // If the error thrown isn't an axios error, we don't proceed further
    isAxiosError(error) &&
      error.response?.status === 401 &&
      error.response.data.code === 'auth-login-failed-invalid-credentials'
  );

/**
 * Checks if the given error is an invalid IP address/no matching whitelist error.
 */
export const isInvalidIpAddressError = (error: unknown) =>
  Boolean(
    // If the error thrown isn't an axios error, we don't proceed further
    isAxiosError(error) &&
      error.response?.status === 401 &&
      error.response.data.code === 'auth-login-failed-no-passing-ip-whitelists'
  );

/**
 * Checks if the given error is a session expired type (status 419)
 * Typically CSRF token verification failure or some session expiration caching issue.
 */
export const isSessionExpiredError = (error: unknown) =>
  Boolean(
    // If the error thrown isn't an axios error, we don't proceed further
    isAxiosError(error) && error.response?.status === 419
  );

/**
 * Checks if the given error is a rate limiter type (status 429)
 * Rate limiter; too many failed login attempts.
 */
export const isRateLimiterError = (error: unknown) =>
  Boolean(
    // If the error thrown isn't an axios error, we don't proceed further
    isAxiosError(error) && error.response?.status === 429
  );

/**
 * Checks whether the given error is a 401 unauthorized
 */
export const isUnauthorizedError = (error: unknown) =>
  Boolean(isAxiosError(error) && error.response?.status === 401);
