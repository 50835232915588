import { ZoneSettings } from '../../serverApi';

export const hasZoneSettingsChanged = (zoneSettings: ZoneSettings | null) => {
  if (!zoneSettings) {
    return false;
  }

  const exludedFields = ['zone_id'];

  //Map through zone settings and return its values, except the ones that are excluded in the array above.
  const settings = Object.keys(zoneSettings).map((itemKey) => {
    const key = itemKey as keyof ZoneSettings;
    return exludedFields.includes(key) ? null : zoneSettings[key];
  });

  //Check if one or more of the setting fields is not null. If yes, that means the zone has custom settings.
  return settings.some((setting) => setting !== null);
};
