import React from 'react';
import { FormGroup, TextField, FormLabel } from '@material-ui/core';
import { EventSettingsForm } from '../useEventSettingsForm';
import { ErrorComponent } from '../../../../../components/forms/shared/ErrorComponent';
import { generateAutoCompleteValue } from '../../../../../lib/utils/generateAutoCompleteValue';
import { GreenCircleIndicator } from '../../../common/ManageSidebar';

interface Props {
  form: EventSettingsForm;

  /**
   * The label for the form control.
   */
  label: string;

  /**
   * The field in EventSettings that this control should modify the value of.
   */
  field: 'threshold1' | 'threshold2' | 'quarantine1' | 'quarantine2';

  /**
   * Optional add an description to the select field
   */
  description?: string;
}

export default function EventNumberControl(props: Props) {
  const { form } = props;

  const fieldHasChanged =
    (form.values[props.field] || '') !==
    (form.initialValues[props.field] || '');

  return (
    <FormGroup className="mt-4 position-relative" style={{ maxWidth: '200px' }}>
      {props.description && <FormLabel>{props.description}</FormLabel>}
      <TextField
        value={form.values[props.field] || ''}
        onChange={form.handleChange}
        name={props.field}
        label={props.label}
        autoComplete={generateAutoCompleteValue()}
      />
      {form.errors[props.field] && (
        <ErrorComponent msg={form.errors[props.field]} />
      )}
      {fieldHasChanged && (
        <GreenCircleIndicator style={{ right: '-15px', top: '70%' }} />
      )}
    </FormGroup>
  );
}
