import React, { useEffect, useState } from 'react';
import {
  FormControl,
  FormControlLabel,
  Radio,
  RadioGroup,
  TextField,
} from '@material-ui/core';
import { toast } from 'react-toastify';
import * as Sentry from '@sentry/browser';

import { ButtonGroup } from '../../../../components/ButtonGroup';
import { Button } from '../../../../components/Button';
import * as serverApi from '../../../../serverApi';
import {
  errorIsObjectError,
  useErrorHandler,
} from '../../../../lib/useErrorHandler';
import { ErrorMessages } from '../../../../components/ErrorMessages';
import { useDataChangeDetect } from '../../../../lib/useDataChangeDetect';
import { RouteLeavingGuard } from '../../../../components/RouteLeavingGuard';
import { useHandleCancel } from '../../../../lib/useHandleCancel';
import { t } from '../../../../lib/i18n';
import { generateAutoCompleteValue } from '../../../../lib/utils/generateAutoCompleteValue';

export default function Logins() {
  const [days, setDays] = useState<number | null | undefined>(undefined);

  const [loading, setLoading] = useState(true);

  const hc = useHandleCancel();
  const errorHandler = useErrorHandler();
  const dataChangeDetect = useDataChangeDetect({ days });

  useEffect(() => {
    serverApi
      .getAccountSettings()
      .then((data) => {
        setDays(data.password_expiration_days);
        dataChangeDetect.setData({ days: data.password_expiration_days });
        setLoading(false);
      })
      .catch((e) => {
        Sentry.captureException(e);
        setLoading(false);
      });
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const onSubmit = () => {
    errorHandler.reset();
    if (typeof days === 'number' && (days < 10 || days > 3650)) {
      errorHandler.setObjectError({
        days: t('manage.account.Logins.mustChooseBetween10And3650Days'),
      });
      return;
    }
    setLoading(true);
    serverApi
      .updateAccountSettings({
        password_expiration_days: days,
      })
      .then((data) => {
        setDays(data.password_expiration_days);
        dataChangeDetect.setData({ days: data.password_expiration_days });
        setLoading(false);
        toast.success(t('manage.account.AccountSettings.savedSuccessfully'));
      })
      .catch((e) => {
        Sentry.captureException(e);
        setLoading(false);
        toast.error(t('manage.account.AccountSettings.errorWhileSaving'));
      });
  };

  const onRadioChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    errorHandler.reset();
    if (event.target.value === 'none') {
      setDays(null);
    } else if (event.target.value === 'days') {
      setDays(60);
    }
  };

  const onDaysChange = (
    e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>
  ) => {
    errorHandler.reset();
    setDays(parseInt(e.target.value, 10));
  };

  const selectedRadioGroup =
    days === null ? 'none' : days === undefined ? '' : 'days';

  // const cancel = () => {
  //   if (!dataChangeDetect.hasChanged({ days })) {
  //     router.history.push(getParentPath(router.location.pathname));
  //     return;
  //   }
  //
  //   confirmation
  //     .confirm(
  //       'Det er gjort endringer som ikke er lagret. Vil du fortsette?'
  //     )
  //     .then(() => {
  //       dataChangeDetect.resetData();
  //       router.history.push(getParentPath(router.location.pathname));
  //     })
  //     .catch(() => {});
  // };

  return (
    <div className="Logins">
      <RouteLeavingGuard when={dataChangeDetect.hasChanged({ days })} />

      <h1>{t('manage.account.Logins.passwordSettings')}</h1>

      <RadioGroup
        name="light"
        value={selectedRadioGroup}
        onChange={onRadioChange}
      >
        <FormControl>
          <FormControlLabel
            value="none"
            disabled={loading}
            control={<Radio color="primary" />}
            label={t('manage.account.Logins.noTimeLimit')}
          />
        </FormControl>

        <FormControl>
          <div className="d-flex flex-row align-items-center">
            <FormControlLabel
              value="days"
              disabled={loading}
              className="mr-0"
              control={<Radio color="primary" />}
              label=""
            />
            <TextField
              style={{ width: 100, marginTop: 0, marginRight: 10 }}
              disabled={loading || days === null}
              value={days || ''}
              onChange={onDaysChange}
              autoComplete={generateAutoCompleteValue()}
              error={
                errorIsObjectError(errorHandler.error) &&
                !!errorHandler.error?.['days']
              }
              // helperText={errorHandler.error && errorHandler.error['days']}
            />
            <span style={{ fontWeight: 500 }}>
              {t('manage.account.Logins.days')}
            </span>
          </div>
        </FormControl>
      </RadioGroup>

      <ButtonGroup className="mt-4">
        <Button
          disabled={loading || !!errorHandler.error}
          icon={loading ? 'gear' : undefined}
          spinIcon={loading}
          type="submit"
          color="primary"
          onClick={onSubmit}
        >
          {t('common.commonButtons.save')}
        </Button>
        <Button
          onClick={() =>
            hc.handleCancel(dataChangeDetect.hasChanged({ days }), null, () =>
              dataChangeDetect.resetData()
            )
          }
        >
          {t('common.commonButtons.cancel')}
        </Button>
      </ButtonGroup>

      {errorIsObjectError(errorHandler.error) && errorHandler.error['days'] && (
        <div className="my-3 text-danger">{errorHandler.error['days']}</div>
      )}

      <ErrorMessages errorData={errorHandler} />
    </div>
  );
}
