import React, { CSSProperties, useState } from 'react';
import cs from 'classnames';
import { InputAdornment, TextField } from '@material-ui/core';
import { Button } from '../Button';
import ZonePickerModal from './ZonePickerModal';
import * as api from '../../serverApi';
import { t } from '../../lib/i18n';

import './ZonePickerField.scss';
import { useZoneTreeContext } from '../../areas/care/lib/ZoneTreeContext';
import { Icon } from '../Icon';
import { generateAutoCompleteValue } from '../../lib/utils/generateAutoCompleteValue';

interface Props {
  showTextFieldZoneIcon?: boolean;
  showClearButton?: boolean;
  hideSelectButton?: boolean;
  disabled?: boolean;

  label?: string;
  buttonLabel?: string;
  error?: string | null;
  className?: string;

  initialZoneId?: number;
  disableBranchId?: number;
  disabledZoneIds?: number[];

  selectedZone?: {
    id: number;
    name: string;
  };
  onSelectZone: (z?: api.StructureZone) => void;
  onClear?: () => void;
  textFieldStyle?: CSSProperties;
}

export const ZonePickerField = (props: Props) => {
  const [isOpen, setIsOpen] = useState(false);
  const treeCtx = useZoneTreeContext();

  const selectedZone = props.selectedZone
    ? props.selectedZone
    : treeCtx.getZoneById(props.initialZoneId);

  return (
    <div className={cs('ZonePickerField', props.className)}>
      <TextField
        autoComplete={generateAutoCompleteValue()}
        style={{ ...props.textFieldStyle }}
        disabled={props.disabled}
        type="text"
        name="zone-picker-field"
        className="ZonePickerField-textfield"
        label={props.label || t('common.commonInputs.zone')}
        value={
          selectedZone
            ? selectedZone.name
            : t('components.ZonePickerField.noHousingUnitSelected')
        }
        onClick={() => {
          if (props.disabled) {
            return;
          }

          setIsOpen(!isOpen);
        }}
        error={!!props.error}
        helperText={props.error}
        InputProps={{
          readOnly: true,
          style: {
            color: selectedZone ? undefined : '#888',
          },
          endAdornment: props.showTextFieldZoneIcon && (
            <InputAdornment position="end">
              <Icon
                icon="zone-tree"
                className="RoleForm--select-tree-icon"
                style={{ color: props.disabled ? '#888' : '#111' }}
              />
            </InputAdornment>
          ),
        }}
      />

      {props.hideSelectButton ? null : (
        <Button
          icon="gear"
          color="primary"
          light={true}
          small={true}
          disabled={props.disabled}
          onClick={() => setIsOpen(!isOpen)}
        >
          {props.buttonLabel || t('common.commonButtons.select')}
        </Button>
      )}

      {props.showClearButton && selectedZone && (
        <Button
          small={true}
          icon="trash"
          className="ml-2"
          onClick={() => {
            if (props.onClear) {
              props.onClear();
            }
          }}
        >
          {t('common.commonButtons.remove')}
        </Button>
      )}

      <ZonePickerModal
        isOpen={isOpen}
        selectedZoneId={props.initialZoneId || selectedZone?.id}
        disabledZoneIds={props.disabledZoneIds}
        handleModal={() => setIsOpen(!isOpen)}
        onSelect={(selectedZone) => props.onSelectZone(selectedZone)}
      />
    </div>
  );
};
