import { MutableRefObject, RefObject, useEffect, useRef } from 'react';

type MousePoint = { x: number; y: number };

export function useRateLimitedHoverPosition(
  ref: MutableRefObject<HTMLCanvasElement> | RefObject<HTMLCanvasElement>,
  throttle: number | false | undefined,
  debounce: number | false | undefined,
  callback: (p: MousePoint | null) => void
) {
  const lastPosition = useRef<MousePoint | null>(null);
  const hoverTimeout = useRef<number>(0);
  const lastRequestAt = useRef<number>(0);

  useEffect(() => {
    /**
     * Event handler for mouseMove in the associated canvas element.
     */
    const handleMouseMove = (event: MouseEvent) => {
      if (!ref.current) {
        return;
      }
      const { left, top } = ref.current.getBoundingClientRect();

      const x = event.clientX - left;
      const y = event.clientY - top;

      if (hoverTimeout.current) {
        clearTimeout(hoverTimeout.current);
      }

      if (x !== lastPosition.current?.x || y !== lastPosition.current?.y) {
        // Store the current position
        lastPosition.current = { x, y };

        // Throttle: Continually call the callback while the mouse is moving,
        // but at a throttled rate.
        if (typeof throttle === 'number') {
          const now = Date.now();
          if (now - lastRequestAt.current > throttle) {
            lastRequestAt.current = now;
            callback(lastPosition.current);
          }
        }

        // Debouncer: always call the callback when the mouse position
        // has remained the same for `debounce` milliseconds.
        if (typeof debounce === 'number') {
          hoverTimeout.current = window.setTimeout(() => {
            callback(lastPosition.current);
          }, debounce);
        }
      }
    };

    /**
     * Event handler for mouseLeave/mouseOut in the associated canvas element.
     */
    const handleMouseLeaveAndOut = () => {
      if (hoverTimeout.current) {
        clearTimeout(hoverTimeout.current);
      }
      callback(null);
    };

    if (ref.current) {
    }

    ref.current?.addEventListener('mousemove', handleMouseMove);
    ref.current?.addEventListener('mouseleave', handleMouseLeaveAndOut);
    ref.current?.addEventListener('mouseout', handleMouseLeaveAndOut);

    const cur = ref.current;

    return () => {
      if (cur) {
        cur.removeEventListener('mousemove', handleMouseMove);
        cur.removeEventListener('mouseleave', handleMouseLeaveAndOut);
        cur.removeEventListener('mouseout', handleMouseLeaveAndOut);
      }
    };
  }, [ref, callback, throttle, debounce]);
}
