import React from 'react';
import { createTheme } from '@material-ui/core';
import { Icon } from '../../components/Icon';

export const roommateTheme = createTheme({
  palette: {
    primary: {
      main: '#84b93c',
      light: '#d1eda9',
    },
    secondary: {
      main: '#c9533d',
      light: '#fbdfdf',
    },
  },
  props: {
    MuiTextField: {
      variant: 'outlined',
    },
    MuiButton: {
      variant: 'contained',
    },
    MuiSelect: {
      variant: 'outlined',
    },
    MuiInputLabel: {
      shrink: true,
    },
    MuiTable: {
      // stickyHeader: true,
    },
    /**
     * These contain the proper icons, but don't work well.
     * Chrome on Windows don't display them at all.
     * Firefox on Windows displays them, but they're enormous.
     * Maybe they only need a height/width?
     */
    MuiCheckbox: {
      color: 'primary',
      icon: (
        <div
          style={{
            width: '20px',
            height: '20px',
            borderRadius: '4px',
            border: '1px solid #707070',
          }}
        />
      ),
      checkedIcon: (
        <div
          style={{
            width: '20px',
            height: '20px',
            backgroundColor: '#84b93c',
            borderRadius: '4px',
          }}
        >
          <Icon
            icon="check"
            style={{
              width: '18px',
              height: '18px',
              fill: 'white',
              display: 'block',
              transform: 'translate(1px,1px)',
            }}
          />
        </div>
      ),
    },
  },
  overrides: {
    MuiFormLabel: {
      root: {
        fontFamily: 'Roboto',
        fontSize: 15,
        fontWeight: 500,
        color: '#3f3f3f',
      },
    },
    MuiSelect: {
      root: {
        '& > em ': {
          fontFamily: 'Roboto',
          fontWeight: 500,
          fontSize: 15,
          fontStyle: 'normal',
          color: '#3f3f3f',
        },
      },
      select: {
        // minWidth: 180,
        borderRadius: 6,
        border: '1px solid #707070',
        padding: '9px 14px',
        '&:focus': {
          borderRadius: 6,
        },
        '&.Mui-disabled': {
          border: '1px solid #fff',
        },
      },
    },
    MuiInputBase: {
      root: {
        marginTop: 4,
        '&:before, &:after': {
          display: 'none !important',
        },
      },
      input: {
        '&::placeholder': {
          color: '#3f3f3f !important',
          opacity: 1,
        },
        '&::-ms-input-placeholder': {
          color: '#3f3f3f !important',
          opacity: 1,
        },
      },
    },
    MuiOutlinedInput: {
      notchedOutline: {
        borderColor: '#707070',
      },
      input: {
        padding: '10px 14px',
      },
    },
    MuiInputLabel: {
      root: {
        fontFamily: 'Roboto',
        fontSize: 15,
        fontWeight: 500,
        color: '#3f3f3f',
      },
      outlined: {
        '&$shrink': {
          position: 'relative',
          transform: 'translate(0, 7px) scale(1)',
          // transform: 'translate(0, -16px) scale(1)',
          padding: '0',
        },
      },
    },
    MuiTab: {
      root: {
        textTransform: 'none',
      },
    },
    MuiButton: {
      root: {
        letterSpacing: 0,
        fontSize: 15,
        fontWeight: 500,
        textTransform: 'none',
        boxShadow: 'none !important',
        '&.MuiButton-default': {
          borderRadius: 5,
          padding: '6px 20px',
          fontFamily: 'Roboto',
          color: '#ffff',
          // minWidth: 102,
          '&.Mui-disabled': {
            backgroundColor: '#c4c4c4!important',
            color: '#fff !important',
            '& .Button-icon--default': {
              backgroundColor: '#c4c4c4 ',
            },
            '& .IconButton-icon': {
              backgroundColor: '#919191 !important',
            },
          },
        },
        '&.MuiButton-icon-square': {
          backgroundColor: 'transparent',
          color: '#3f3f3f',
          padding: '3px 6px',
        },
        '&.MuiButton-inverse': {
          backgroundColor: '#888888',
          color: '#fff',
        },
        '&.MuiButton-inverse.MuiButton-containedPrimary': {
          backgroundColor: '#d1eda9',
          color: '#516d1c',
          '&:hover': {
            backgroundColor: '#a7d06b',
          },
        },
        '&.MuiButton-inverse.MuiButton-containedSecondary': {
          backgroundColor: '#fbdfdf',
          color: '#581a1a',
          '&:hover': {
            backgroundColor: '#ffb4b4',
          },
        },
        '&.MuiButton-containedSizeSmall': {
          fontSize: 15,
        },
      },
      contained: {
        '&:hover': {
          backgroundColor: '#555555',
        },
      },
    },
    MuiIconButton: {
      root: {
        padding: 0,
        borderRadius: 4,
      },
      label: {
        display: 'flex',
        alignItems: 'center',
      },
    },
    MuiTableContainer: {
      root: {
        // overflowX: 'inherit',
        // border: '1px solid #707070',
        borderRadius: 10,
      },
    },
    MuiTable: {
      root: {
        background: '#fff',
        borderCollapse: 'separate',
        border: '1px solid #707070',
        borderRadius: 10,
      },
      stickyHeader: {
        '& th:first-child': {
          borderTopLeftRadius: 10,
        },
        '& th:last-child': {
          borderTopRightRadius: 10,
        },
      },
    },
    MuiTableHead: {
      root: {
        '& th': {
          borderBottom: '1px solid #707070',
        },
      },
    },
    MuiTableRow: {
      root: {
        '& td': {
          borderBottom: '1px solid #707070',
        },
        '&:last-child .MuiTableCell-body': {
          borderBottom: 'none',
        },
      },
    },

    MuiTableCell: {
      root: {
        fontWeight: 400,
        lineHeight: '1.5rem',
      },
      head: {
        fontFamily: 'Roboto',
        fontWeight: 700,
        fontSize: 15,
        padding: '6px 20px',
        color: '#3F3F3F',
        whiteSpace: 'nowrap',
      },
      body: {
        fontSize: 16,
        fontWeight: 400,
        fontFamily: 'Roboto',
        padding: '10px 20px',
        color: '#3F3F3F',
      },
      stickyHeader: {
        backgroundColor: '#fff',
        top: 66,
      },
    },
    MuiPaper: {
      root: {
        '&.MuiPaper-table-wrapper': {
          boxShadow: 'none',
          overflowX: 'auto',
          border: 'none',
          backgroundColor: 'transparent',
        },
      },
    },
    MuiTextField: {
      root: {
        // marginTop: 20,
        fontSize: 15,
        fontWeight: 500,
      },
    },
    MuiListItemIcon: {
      root: {
        minWidth: 45,
      },
    },
    MuiCheckbox: {
      root: {
        paddingRight: 7,
      },
    },
    MuiTypography: {
      body1: {
        fontWeight: 500,
      },
    },
    MuiFormControlLabel: {
      root: {
        marginBottom: 0,
      },
    },
  },
});
