import React, { useState } from 'react';
import { useSensorContext } from '../../../../lib/SensorContext';
import { UpdateSensorModal } from '../../../../components/modals/sensors/UpdateSensorModal';
import { IconButton } from '../../../../components/IconButton';
import { t } from '../../../../lib/i18n';

interface Props {
  disabled?: boolean;
  className?: string;
  onChanged?: () => void;
}

export default function ChangeSensorNameButton(props: Props) {
  const [updateSensorModal, setUpdateSensorModal] = useState(false);
  const sensor = useSensorContext();
  return (
    <>
      <IconButton
        icon="edit"
        className={props.className}
        disabled={props.disabled}
        onClick={() => setUpdateSensorModal(!updateSensorModal)}
      >
        {t('manage.sensors.details.ChangeSensorNameButton.renameSensor')}
      </IconButton>

      <UpdateSensorModal
        isOpen={updateSensorModal}
        sensorId={sensor.id}
        currentSensorName={sensor.name}
        handleModal={() => setUpdateSensorModal(!updateSensorModal)}
        onUpdate={props.onChanged}
      />
    </>
  );
}
