import React from 'react';
import * as serverApi from '../../../serverApi';
import { HeadingBar } from '../shared/HeadingBar';
import { Card } from '../shared/Card';
import { Button } from '@material-ui/core';
import { Link } from 'react-router-dom';
import { InlineSpinner } from '../../../components/InlineSpinner';
import { RouteComponentProps } from 'react-router';
import { EditUserForm } from './ui/EditUserForm';
import { ErrorMessages } from '../../../components/ErrorMessages';
import { useApiCall } from '../../../lib/useApiCall';

export const EditUser = (props: RouteComponentProps<{ userId: string }>) => {
  const userId = parseInt(props.match.params.userId);
  const userResponse = useApiCall(serverApi.superGetUserDetails, [userId]);
  const user = userResponse?.data;

  return (
    <>
      <HeadingBar>
        <h1>Edit user</h1>
        <Button component={Link} to="/super/users">
          Back to users
        </Button>
      </HeadingBar>

      <Card>
        <ErrorMessages errorApiCall={userResponse?.error} />
        {userResponse?.loading ? (
          <InlineSpinner text="Laster ..." />
        ) : !user ? (
          <div>User not found</div>
        ) : (
          <EditUserForm user={user} />
        )}
      </Card>
    </>
  );
};
