import React, { useEffect, useRef, useState } from 'react';
import { toast } from 'react-toastify';
import ModalWrapper from './ModalWrapper';
import {
  FormControlLabel,
  RadioGroup,
  Radio,
  FormGroup,
  TextField,
  Typography,
} from '@material-ui/core';
import * as serverApi from '../../serverApi';
// import { useCareContext } from '../../areas/care/lib/CareContext';
import { SensorSelector } from '../SensorSelector';
import { ReducedZone } from '../../lib/groupSensorsByZone';
import { errorIsObjectError, useErrorHandler } from '../../lib/useErrorHandler';
import { InlineError } from '../InlineError';
import { t } from '../../lib/i18n';
import { generateAutoCompleteValue } from '../../lib/utils/generateAutoCompleteValue';

interface Props {
  isOpen: boolean;
  // selected?: string;
  onClose(): void;
}

type PresenceChoice = '15min' | '30min' | '1hour' | 'specify' | null;

export function PresenceModal(props: Props) {
  // const careCtx = useCareContext();

  const inputRef = useRef<HTMLDivElement | null>(null);

  const [loading, setLoading] = useState(false);
  const [presence, setPresence] = useState<PresenceChoice>('15min');
  const [sensor, setSensor] = useState<ReducedZone | undefined>(undefined);
  const [selectedMinutes, setSelectedMinutes] = useState('');

  const errorHandler = useErrorHandler();

  useEffect(() => {
    if (presence === 'specify' && inputRef && inputRef.current) {
      inputRef.current.focus();
    }
  }, [presence]);

  // let preSelectedSensor = undefined as Sensor | undefined;
  // if (props.selected) {
  //   preSelectedSensor = careCtx.sensors
  //     ? careCtx.sensors.find(e => e.sensor_id === props.selected)
  //     : undefined;
  // }

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    errorHandler.reset();
    if (['15min', '30min', '1hour', 'specify'].includes(event.target.value)) {
      setSelectedMinutes('');
      setPresence(event.target.value as PresenceChoice);
    }
  };

  const handleMinutesChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value;
    if (value === '' || /^[0-9\b]+$/.test(value)) {
      setSelectedMinutes(value);
      const parsed = parseInt(value, 10);
      if (parsed < 1 || parsed > 1440) {
        errorHandler.setObjectError({
          selectedMinutes: t(
            'components.PresenceModal.valueShouldBeBetween1and1440'
          ),
        });
        return false;
      } else {
        errorHandler.error && errorHandler.reset();
      }
    }
  };

  const validate = () => {
    if (presence === 'specify' && selectedMinutes === '') {
      errorHandler.setObjectError({
        selectedMinutes: t('components.PresenceModal.thisFieldIsRequired'),
      });
      return false;
    }

    if (!sensor) {
      toast.success(t('components.PresenceModal.youMustSelectTheSensor'));
      return false;
    }

    return !errorHandler.error;
  };

  async function handleSubmit() {
    const isValid = validate();

    if (!isValid || !sensor) {
      return;
    }

    setLoading(true);

    let time = 0;

    switch (presence) {
      case '15min':
        time = 900;
        break;
      case '30min':
        time = 1800;
        break;
      case '1hour':
        time = 3600;
        break;
      case 'specify':
        time = parseInt(selectedMinutes, 10) * 60;
        break;
    }

    sensor.sensors.forEach(
      async (z) =>
        await serverApi.setSensorPresence(z.sensor_id, { seconds: time })
    );

    setLoading(false);
    props.onClose();
  }

  return (
    <ModalWrapper
      title={t('components.PresenceModal.settlementOfHousingUnit')}
      isOpen={props.isOpen}
      onHide={() => props.onClose()}
      onSubmit={handleSubmit}
      loading={loading}
    >
      <div className="d-flex align-items-end">
        <div className="pr-4">
          <RadioGroup
            aria-label="gender"
            name="gender1"
            value={presence}
            onChange={handleChange}
          >
            <FormControlLabel
              value="15min"
              control={<Radio color="primary" />}
              label={`15 ${t('components.PresenceModal.minutes')}`}
            />
            <FormControlLabel
              value="30min"
              control={<Radio color="primary" />}
              label={`30 ${t('components.PresenceModal.minutes')}`}
            />
            <FormControlLabel
              value="1hour"
              control={<Radio color="primary" />}
              label={`1 ${t('components.PresenceModal.hour')}`}
            />

            <FormControlLabel
              value="specify"
              control={<Radio color="primary" />}
              label={t('components.PresenceModal.chooseTime')}
            />
          </RadioGroup>
        </div>
        <div>
          <div
            style={{
              transform: 'translateY(15px)',
              visibility: presence !== 'specify' ? 'hidden' : undefined,
            }}
          >
            {errorIsObjectError(errorHandler.error) &&
              errorHandler.error['selectedMinutes'] && (
                <InlineError>
                  {errorHandler.error['selectedMinutes']}
                </InlineError>
              )}

            <FormGroup className="mb-3 flex-row align-items-center">
              <TextField
                name="specify"
                autoComplete={generateAutoCompleteValue()}
                inputRef={inputRef}
                disabled={presence !== 'specify'}
                label={t('components.PresenceModal.setTheDesiredTime')}
                value={selectedMinutes}
                onChange={handleMinutesChange}
                style={{ maxWidth: '110px' }}
                error={
                  errorIsObjectError(errorHandler.error) &&
                  !!errorHandler.error['selectedMinutes']
                }
              />
              <span
                style={{
                  color:
                    presence !== 'specify' ? 'rgba(0, 0, 0, 0.38)' : 'inherit',
                }}
              >
                <Typography className="ml-2 mt-4" variant="body1">
                  {t('components.PresenceModal.minutes')}
                </Typography>
              </span>
            </FormGroup>
          </div>
        </div>
      </div>
      <SensorSelector onSelected={(v) => setSensor(v)} selected={sensor} />
    </ModalWrapper>
  );
}
