export function parseTimeOfDay(s: string | null) {
  if (!s) {
    return null;
  }
  if (/^[0-9]{1}$/.test(s)) {
    return `0${s}:00:00`;
  }
  if (/^[0-9]{2}$/.test(s)) {
    return `${s}:00:00`;
  }
  if (/^[0-9]{1}:[0-9]{2}$/.test(s)) {
    return `0${s}:00`;
  }
  if (/^[0-9]{2}:[0-9]{2}$/.test(s)) {
    return `${s}:00`;
  }
  if (/^[0-9]{1}:[0-9]{2}:[0-9]{2}$/.test(s)) {
    return `0${s}`;
  }
  if (/^[0-9]{2}:[0-9]{2}:[0-9]{2}$/.test(s)) {
    return s;
  }
  return null;
}
