import React from 'react';
import { Button } from '../../../../components/Button';
import { useZoneContext } from '../../../../lib/ZoneContext';
import { useRouter } from '../../../../lib/useRouter';
import { useHandleCancel } from '../../../../lib/useHandleCancel';
import {
  ImpersonalUsersFields,
  useImpersonalUsersFormik,
} from '../lib/useImpersonalUsersFormik';
import { ImpersonalUsersForm } from './ImpersonalUsersForm';

import * as yup from 'yup';
import * as api from '../../../../serverApi';
import { useErrorHandler } from '../../../../lib/useErrorHandler';
import { ErrorMessages } from '../../../../components/ErrorMessages';
import { t } from '../../../../lib/i18n';
import { isAxiosError } from '../../../../lib/utils/errorUtils';

interface Props {
  data: api.GetUserResult;
  onAfterChange?: () => void;
}

export const EditImpersonalUserForm = (props: Props) => {
  const zoneCtx = useZoneContext();
  const router = useRouter();
  const hc = useHandleCancel();
  const errorHandler = useErrorHandler();

  const userData = props.data;

  const formik = useImpersonalUsersFormik({
    initialValues: {
      display_name: userData.display_name,
      phone: userData.phone,
      preferred_locale: userData.preferred_locale || 'null',
    } as ImpersonalUsersFields,
    validationSchema: () =>
      yup.object().shape({
        display_name: yup.string().nullable(),
        email: yup.string().email().nullable(),
        phone: yup
          .string()
          .nullable()
          .test(
            'Serial',
            t('manage.permissions.common.thePhoneNumberMustContainOnlyDigits'),
            (v) => (typeof v === 'string' ? /^\+?[0-9 ]+$/.test(v) : true)
          ),
      }),
    onSubmit: async (values, formikHelpers) => {
      try {
        formikHelpers.setSubmitting(true);
        await api.updateUser(props.data.user_id, {
          ...values,
          preferred_locale:
            values.preferred_locale === 'null' ? null : values.preferred_locale,
        } as api.UpdateUserData);

        formikHelpers.resetForm(values);

        if (props.onAfterChange) {
          props.onAfterChange();
        }
        router.history.push(
          '/manage/' + zoneCtx.activeZone.id + '/permissions'
        );
      } catch (e) {
        if (isAxiosError<Record<string, string>>(e)) {
          formikHelpers.setSubmitting(false);
          e.response?.data && formikHelpers.setErrors(e.response?.data);
        }
        errorHandler.handleError(e);
      }
    },
  });

  return (
    <div className="EditImpersonalUserForm">
      <ImpersonalUsersForm
        title={t(
          'manage.permissions.forms.EditImpersonalUserForm.changeEmergencyTelephone'
        )}
        form={formik}
      />

      <div className="mt-3">
        <Button
          color="primary"
          className="ml-auto mr-2"
          disabled={formik.isSubmitting || !formik.isValid}
          spinIcon={formik.isSubmitting}
          icon={formik.isSubmitting ? 'gear' : undefined}
          onClick={formik.submitForm}
        >
          {t('common.commonButtons.save')}
        </Button>
        <Button
          className="ml-auto mr-2"
          onClick={() =>
            hc.handleCancel(
              formik.dirty,
              `/manage/${zoneCtx.activeZone.id}/permissions/${userData.user_id}`,
              () => formik.resetForm()
            )
          }
        >
          {t('common.commonButtons.cancel')}
        </Button>

        <ErrorMessages className="my-2" errorData={errorHandler} />
      </div>
    </div>
  );
};
