import { useEffect, useState } from 'react';
import { useRouter } from './useRouter';
import { useZoneContext } from './ZoneContext';

interface Opts {
  /**
   * Redirect to this path relative to the zone root.
   * E.g. when relative path is `/alertsettings`, the user will be redirected to E.g. `/manage/123/alertsettings`
   */
  relativePath: string;
}

export function useRedirectOnZoneChange(otps: Opts) {
  const [previousZoneId, setPreviousZoneId] = useState<number | undefined>(
    undefined
  );
  const ctx = useZoneContext();
  const router = useRouter();

  useEffect(() => {
    if (previousZoneId !== undefined && previousZoneId !== ctx.activeZone.id) {
      router.history.replace(
        `/manage/${ctx.activeZone.id}${otps.relativePath}`
      );
    }
    setPreviousZoneId(ctx.activeZone.id);
  }, [ctx.activeZone.id, otps.relativePath, previousZoneId, router.history]);
}
