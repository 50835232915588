import { FormikHelpers, useFormik } from 'formik';

export interface AssignSensorFields {
  is_enabled: boolean;
  name: string;
  serial: string;
  zone_id: number | null;
  move: boolean;
}

export type AssignSensorFormik = ReturnType<typeof useAssignSensorFormik>;

interface UseCareReceiverFormOptions {
  initialValues?: AssignSensorFields;
  validationSchema?: any | (() => any);
  onSubmit: (
    values: AssignSensorFields,
    formikHelpers: FormikHelpers<AssignSensorFields>
  ) => Promise<any>;
}

export function useAssignSensorFormik(opts: UseCareReceiverFormOptions) {
  return useFormik<AssignSensorFields>({
    initialValues: {
      is_enabled: false,
      name: '',
      serial: '',
      zone_id: null,
      move: false,
      ...opts.initialValues,
    },
    validateOnBlur: false,
    validateOnChange: true,
    validationSchema: opts.validationSchema,
    onSubmit: async (values, formikHelpers) => {
      await opts.onSubmit(values, formikHelpers);
    },
  });
}
