import {
  Checkbox,
  FormControlLabel,
  FormGroup,
  TextField,
} from '@material-ui/core';
import React from 'react';
import { LanguageSelector } from '../../../components/LanguageSelector';
import { useAppContext } from '../../../lib/global';
import { t } from '../../../lib/i18n';
import { AccountFormik } from './lib/useAccountFormik';
import { generateAutoCompleteValue } from '../../../lib/utils/generateAutoCompleteValue';

interface Props {
  form: AccountFormik;
  loading?: boolean;
  mode?: 'edit' | 'create';
}

export function AccountForm(props: Props) {
  const app = useAppContext();
  const { errors, touched } = props.form;

  const hasSuperCapability = app.hasCapability('globalSuper');

  return (
    <div style={{ padding: 15 }}>
      <FormGroup className="mb-3">
        <TextField
          autoComplete={generateAutoCompleteValue()}
          type="text"
          name="name"
          label={t('supervendor.AccountForm.name')}
          value={props.form.values.name}
          disabled={props.loading}
          onChange={props.form.handleChange}
          error={touched.name && errors?.name !== undefined}
          helperText={touched.name && errors?.name}
        />
      </FormGroup>
      <FormGroup className="mb-3">
        <TextField
          autoComplete={generateAutoCompleteValue()}
          type="text"
          name="account_code"
          label={t('supervendor.AccountForm.accountCode')}
          value={props.form.values.account_code}
          disabled={props.loading}
          onChange={props.form.handleChange}
          onBlur={props.form.handleBlur}
          error={touched.account_code && errors?.account_code !== undefined}
          helperText={touched.account_code && errors?.account_code}
        />
        <LanguageSelector
          name="default_user_locale"
          className="mt-2"
          value={props.form.values.default_user_locale}
          onChange={props.form.handleChange}
          disableDefaultValue={true}
        />
        <FormGroup className="mt-3">
          <FormControlLabel
            label={t('supervendor.AccountForm.disableDetailed')}
            control={
              <Checkbox
                name="disable_all_detailed_images"
                checked={props.form.values.disable_all_detailed_images}
                onChange={props.form.handleChange}
              />
            }
          />
        </FormGroup>
        <FormGroup className="mt-3">
          <FormControlLabel
            label={t('supervendor.AccountForm.requireNationalId')}
            control={
              <Checkbox
                name="require_national_id_for_care_receivers"
                checked={
                  props.form.values.require_national_id_for_care_receivers
                }
                onChange={props.form.handleChange}
              />
            }
          />
        </FormGroup>
        <FormGroup className="mt-3">
          <FormControlLabel
            label={t('supervendor.AccountForm.disableNationalId')}
            control={
              <Checkbox
                name="disable_national_id_numbers"
                checked={props.form.values.disable_national_id_numbers}
                onChange={props.form.handleChange}
              />
            }
          />
        </FormGroup>
        {hasSuperCapability ? (
          <FormGroup className="mt-3">
            <FormControlLabel
              label="This account is a vendor"
              control={
                <Checkbox
                  name="is_vendor"
                  checked={props.form.values.is_vendor}
                  onChange={props.form.handleChange}
                />
              }
            />
          </FormGroup>
        ) : null}

        <FormGroup className="mt-3">
          <FormControlLabel
            label="Enable Sso"
            control={
              <Checkbox
                name="enable_sso"
                checked={props.form.values.enable_sso}
                onChange={props.form.handleChange}
              />
            }
          />
        </FormGroup>
      </FormGroup>
    </div>
  );
}
