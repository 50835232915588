import React, { useState } from 'react';
import { useQueryClient } from 'react-query';

import { useArgusBasicManagementForm } from './useArgusBasicManagementForm';
import { ArgusBasicManagementForm } from './ArgusBasicManagementForm';
import {
  SensorContext,
  useSensorContext,
} from '../../../../../lib/SensorContext';
import { IconButton } from '../../../../../components/IconButton';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCaretDown, faCaretUp } from '@fortawesome/pro-solid-svg-icons';
import { useErrorHandler } from '../../../../../lib/useErrorHandler';
import { ErrorMessages } from '../../../../../components/ErrorMessages';
import { InlineSpinner } from '../../../../../components/InlineSpinner';

import * as serverApi from '../../../../../serverApi';
import { t } from '../../../../../lib/i18n';

import './ArgusBasicManagementButton.scss';

interface ArgusBasicManagementButtonProps {
  onChanged?: () => void;
}

export const ArgusBasicManagementButton = (
  props: ArgusBasicManagementButtonProps
) => {
  const sensor = useSensorContext();
  const basicArgusConfiguration = serverApi.useGetSensorArgusConfigurationQuery(
    sensor.id
  );

  if (basicArgusConfiguration.isLoading) {
    return (
      <div className="mt-4">
        <InlineSpinner text={'...'} />
      </div>
    );
  }

  return (
    <div className="ArgusBasicManagementButton my-4">
      {!basicArgusConfiguration.data ? null : (
        <ArgusBasicManagementButtonComponent
          loading={basicArgusConfiguration.isLoading}
          basicArgusConfiguration={basicArgusConfiguration.data}
          sensor={sensor}
          onChanged={() => {
            if (props.onChanged) {
              props.onChanged();
            }
          }}
        />
      )}
    </div>
  );
};

interface Props {
  basicArgusConfiguration: serverApi.SensorArgusConfiguration;
  sensor: SensorContext;
  loading?: boolean;
  onChanged?: () => void;
}

const ArgusBasicManagementButtonComponent = (props: Props) => {
  const { sensor } = props;
  const errorHandler = useErrorHandler();
  const queryClient = useQueryClient();
  const { mutateAsync: setSensorArgusConfigurationMutation } =
    serverApi.useSetSensorArgusConfigurationMutation({
      onSuccess: (data) => {
        const sensorSettingKey =
          serverApi.QueryKeys.getSensorArgusConfiguration(sensor.id);
        queryClient.setQueryData(sensorSettingKey, data);
      },
    });

  const [expanded, setExpanded] = useState(false);

  const values = props.basicArgusConfiguration;

  const form = useArgusBasicManagementForm({
    initialValues: {
      'argus-enable': values['argus-enable'] ?? false,
      'alert-activation-delay-secs':
        values['alert-activation-delay-secs'] ?? null,
    },
    onSubmit: async (val, formikHelpers) => {
      try {
        await setSensorArgusConfigurationMutation({
          sensorCompositeId: sensor.id,
          data: val,
        });

        formikHelpers.resetForm({
          values,
        });

        if (props.onChanged) {
          props.onChanged();
        }
      } catch (e) {
        errorHandler.handleError(e);
      }
    },
  });

  return (
    <>
      <IconButton
        icon="eye"
        spinIcon={props.loading}
        onClick={() => setExpanded(!expanded)}
      >
        {t(
          'manage.sensors.details.ArgusBasicManagementButton.openArgusConfiguration'
        )}
        <FontAwesomeIcon
          style={{ marginLeft: 5 }}
          icon={expanded ? faCaretUp : faCaretDown}
        />
      </IconButton>

      {expanded && <ArgusBasicManagementForm form={form} />}

      <ErrorMessages errorData={errorHandler} />
    </>
  );
};
