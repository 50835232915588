import React from 'react';

interface Props {
  className?: string;
  style?: React.CSSProperties;
}

export default function Logo(props: Props) {
  return (
    <svg
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      x="0px"
      y="0px"
      viewBox="0 0 400.5 111.8"
      className={props.className}
      style={{ fill: 'currentColor', ...props.style }}
    >
      {/* eslint-disable-next-line i18next/no-literal-string */}
      <title>RoomMate logo</title>
      <path
        d="M391.5,38.8h-1.2v2.3h1.2c0.7,0,1.2-0.5,1.2-1.1C392.7,39.3,392.2,38.8,391.5,38.8 M392.8,44.5l-1.5-2.7h-1
            v2.7h-0.9V38h2.2c1.1,0,2.1,0.9,2.1,2c0,1-0.6,1.6-1.4,1.8l1.6,2.8L392.8,44.5L392.8,44.5z M391.3,36.2c-2.8,0-5,2.3-5,5.1
            s2.2,5.1,5,5.1s5-2.3,5-5.1S394.1,36.2,391.3,36.2 M391.3,47.2c-3.3,0-6-2.7-6-6s2.7-6,6-6s6,2.7,6,6S394.6,47.2,391.3,47.2"
      />
      <path
        d="M103.7,54.4h7.5c2.3,0,4.1-0.6,5.4-1.8s1.9-2.8,1.9-4.9c0-2.1-0.6-3.8-1.8-5c-1.2-1.2-3.1-1.8-5.5-1.8h-7.4
            L103.7,54.4L103.7,54.4z M111.1,61.9h-7.4v16.5h-9.3v-45h16.7c5.3,0,9.4,1.2,12.3,3.6c2.9,2.4,4.3,5.7,4.3,10c0,3.1-0.7,5.6-2,7.7
            c-1.3,2-3.3,3.7-6,4.9l9.7,18.4v0.4h-9.9L111.1,61.9z"
      />
      <path
        d="M137.9,62c0,3.2,0.6,5.6,1.8,7.3c1.2,1.7,2.9,2.5,5.1,2.5c2.2,0,3.8-0.8,5.1-2.5c1.2-1.7,1.8-4.3,1.8-8
            c0-3.1-0.6-5.5-1.8-7.2s-2.9-2.6-5.1-2.6s-3.9,0.9-5.1,2.6C138.5,55.8,137.9,58.4,137.9,62 M129,61.3c0-3.3,0.6-6.3,1.9-8.9
            c1.3-2.6,3.1-4.6,5.5-6s5.2-2.1,8.4-2.1c4.5,0,8.2,1.4,11,4.1c2.9,2.8,4.4,6.5,4.8,11.2l0.1,2.3c0,5.1-1.4,9.2-4.3,12.3
            c-2.9,3.1-6.7,4.6-11.5,4.6s-8.7-1.5-11.5-4.6c-2.9-3.1-4.3-7.3-4.3-12.6L129,61.3L129,61.3z"
      />
      <path
        d="M172.4,62c0,3.2,0.6,5.6,1.8,7.3c1.2,1.7,2.9,2.5,5.1,2.5c2.2,0,3.8-0.8,5.1-2.5c1.2-1.7,1.8-4.3,1.8-8
            c0-3.1-0.6-5.5-1.8-7.2s-2.9-2.6-5.1-2.6c-2.2,0-3.9,0.9-5.1,2.6C173,55.8,172.4,58.4,172.4,62 M163.5,61.3c0-3.3,0.6-6.3,1.9-8.9
            c1.3-2.6,3.1-4.6,5.5-6s5.2-2.1,8.4-2.1c4.5,0,8.2,1.4,11,4.1c2.9,2.8,4.4,6.5,4.8,11.2l0.1,2.3c0,5.1-1.4,9.2-4.3,12.3
            c-2.9,3.1-6.7,4.6-11.5,4.6s-8.7-1.5-11.5-4.6c-2.9-3.1-4.3-7.3-4.3-12.6L163.5,61.3L163.5,61.3z"
      />
      <path
        d="M207.1,44.9l0.3,3.7c2.4-2.9,5.6-4.4,9.6-4.4c4.3,0,7.3,1.7,8.9,5.1c2.3-3.4,5.7-5.1,10-5.1
            c3.6,0,6.3,1.1,8.1,3.2c1.8,2.1,2.7,5.3,2.7,9.5v21.3h-9V57c0-1.9-0.4-3.3-1.1-4.2c-0.7-0.9-2-1.3-3.9-1.3c-2.7,0-4.5,1.3-5.6,3.8
            v23h-8.9V57c0-1.9-0.4-3.3-1.1-4.2c-0.8-0.9-2.1-1.3-3.9-1.3c-2.5,0-4.4,1.1-5.5,3.2v23.7h-8.9V44.9H207.1z"
      />
      <polygon
        points="263.7,33.4 275.2,66 286.7,33.4 298.9,33.4 298.9,78.3 289.6,78.3 289.6,66 290.5,44.8 278.4,78.3
        272,78.3 259.9,44.9 260.9,66 260.9,78.3 251.6,78.3 251.6,33.4 "
      />
      <path
        d="M330.3,56.1c0-3.7-1.2-6.7-3.6-8.7c-2.4-2.1-5.7-3.1-9.8-3.1c-2.7,0-5.2,0.5-7.4,1.4c-2.2,0.9-3.9,2.2-5.1,3.9
        c-1.2,1.6-1.8,3.4-1.8,5.4h8.9c0-1.3,0.4-2.2,1.3-3c0.9-0.7,2-1.1,3.5-1.1c1.7,0,3,0.5,3.8,1.4s1.2,2.2,1.2,3.7v1.9h-4.1
        c-5,0-8.8,1-11.4,2.9s-3.9,4.6-3.9,8.2c0,2.9,1.1,5.3,3.2,7.2c2.2,1.9,4.9,2.8,8.1,2.8c3.5,0,6.3-1.2,8.4-3.6c0.2,1.2,0.5,2.2,0.9,3
        h9v-0.5c-0.9-1.6-1.3-4-1.3-7.2L330.3,56.1L330.3,56.1z M321.4,68.9c-0.5,1-1.4,1.8-2.5,2.4s-2.3,0.9-3.6,0.9
        c-1.4,0-2.4-0.4-3.2-1.1c-0.8-0.7-1.2-1.6-1.2-2.7v-0.5c0.3-3.1,2.7-4.6,7.1-4.6h3.3v5.6C321.3,68.9,321.4,68.9,321.4,68.9z"
      />
      <path
        d="M346,70.8c-0.5-0.5-0.7-1.4-0.7-2.7V51.5h5.7V45h-5.7v-8.2h-8.9V45h-4.9v6.5h4.9v18c0.1,6.3,3.3,9.5,9.6,9.5
        c1.9,0,3.7-0.3,5.4-0.8v-6.8c-0.8,0.1-1.6,0.2-2.6,0.2C347.4,71.6,346.5,71.3,346,70.8z"
      />
      <path
        d="M382.6,60.9c0-5.3-1.3-9.4-3.9-12.3c-2.6-2.9-6.2-4.3-10.8-4.3c-3,0-5.8,0.7-8.1,2.1c-2.4,1.4-4.2,3.5-5.5,6.1
        s-1.9,5.6-1.9,9v0.9c0,5,1.5,9,4.6,12s7.1,4.5,12,4.5c2.8,0,5.3-0.5,7.6-1.6c2.3-1.1,4.1-2.5,5.4-4.4l-4.4-4.9
        c-1.9,2.5-4.6,3.7-8.1,3.7c-2.2,0-4.1-0.7-5.5-2c-1.5-1.3-2.3-3.1-2.6-5.3h21.3v-3.5C382.7,60.9,382.6,60.9,382.6,60.9z M373.8,58.5
        h-12.4c0.3-2.2,1-4,2.1-5.1c1.1-1.2,2.5-1.8,4.3-1.8c1.9,0,3.4,0.5,4.4,1.6s1.6,2.6,1.6,4.6V58.5z"
      />
      <ellipse
        transform="matrix(0.9992 -3.937516e-02 3.937516e-02 0.9992 -0.4986 1.6445)"
        cx="41.5"
        cy="13.5"
        rx="8.7"
        ry="10.4"
      />
      <path
        d="M44.9,69.9c-0.2,0.2-0.4,0.5-0.6,0.7c23.8,32,22.9,40.3,27.6,39.4c7.9-1.5,3.6-27.4-13.6-53.2
        C53.9,60.7,49.1,65,44.9,69.9z"
      />
      <path
        d="M42.8,37.4c-15.2-15.5-31.2-23.1-37.7-18C0.7,22.8,13.7,34.5,29,51.8C13,71,5.2,90.2,11.4,95.7
        c6.5,5.7,14.5-9.6,33.1-31.5c17.2-20.2,36.6-35.3,30.1-41C69.5,18.8,56.5,24.9,42.8,37.4z"
      />
    </svg>
  );
}
