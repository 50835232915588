import React, { useState } from 'react';
import * as Sentry from '@sentry/browser';

import * as api from '../../../../serverApi';
import { TableWrapper } from '../../../../components/TableWrapper';

import {
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
} from '@material-ui/core';
import { TableRowPlaceholder } from '../../common/TableRowPlaceholder';
import { InlineSpinner } from '../../../../components/InlineSpinner';
import { t } from '../../../../lib/i18n';
import { Button } from '../../../../components/Button';
import { toast } from 'react-toastify';

interface Props {
  entries?: api.VkpLogEntry[];
  loading?: boolean;
}

function getLabelForStatusCode(status_code: number) {
  if (status_code === 0) {
    return 'OK';
  }
  return (
    <>
      {t('common.commonTexts.error')} <small>({status_code})</small>
    </>
  );
}

function Row(props: { entry: api.VkpLogEntry }) {
  const e = props.entry;

  const [haveRetried, setHaveRetried] = useState(false);

  const elems = [
    ...(e.zone?.parent ? [`${e.zone.parent.name}`] : []),
    ...(e.zone ? [`${e.zone.name}`] : []),
    ...(e.sensor?.name ? [`${e.sensor.name}`] : []),
    ...(e.sensor?.name || e.care_receiver
      ? []
      : e.sensor
      ? [`${e.sensor?.sensor_composite_id}`]
      : []),
  ];

  const location = elems.join(' / ');

  const [loading, setLoading] = useState(false);

  async function retry() {
    setLoading(true);
    try {
      await api.retryVkpLog({ vkp_log_id: e.id });
      setHaveRetried(true);
    } catch (e) {
      Sentry.captureException(e);
      toast.error('Det oppsto en feil');
      throw e;
    } finally {
      setLoading(false);
    }
  }

  return (
    <TableRow
      key={e.id}
      style={{
        color: loading ? '#666' : undefined,
      }}
    >
      <TableCell>{e.added_at_user_local}</TableCell>
      <TableCell>{getLabelForStatusCode(e.status_code)}</TableCell>
      <TableCell>
        {e.user
          ? `${e.user.display_name || ''} ${e.user.first_name || ''} ${
              e.user.last_name || ''
            }${e.user.username ? ` (${e.user.username})` : ''}`
          : ''}
      </TableCell>

      <TableCell>{location}</TableCell>

      <TableCell>
        {e.care_receiver && (
          <>
            {e.care_receiver.first_name} {e.care_receiver.last_name}
          </>
        )}
      </TableCell>
      <TableCell>
        {e.status_code !== 0 && (
          <Button
            color="primary"
            light={true}
            small={true}
            icon="refresh"
            onClick={retry}
            disabled={loading || haveRetried}
            spinIcon={loading}
          >
            {t('manage.logs.vkpLog.retry')}
          </Button>
        )}
      </TableCell>
    </TableRow>
  );
}

export function VkpLogEntriesTable(props: Props) {
  return (
    <TableWrapper>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell>{t('manage.logs.common.pointInTime')}</TableCell>
            <TableCell>{t('manage.logs.vkpLog.status')}</TableCell>
            <TableCell>{t('manage.logs.vkpLog.username')}</TableCell>
            <TableCell>{t('manage.logs.vkpLog.account')}</TableCell>
            <TableCell>{t('common.commonTexts.theRecipient')}</TableCell>
            <TableCell>&nbsp;</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {props.entries && props.entries.length > 0 ? (
            props.entries.map((e) => <Row entry={e} key={e.id} />)
          ) : (
            <TableRowPlaceholder colSpan={7}>
              {props.loading ? (
                <InlineSpinner text={t('common.commonTexts.loading')} />
              ) : (
                <>
                  {t(
                    'manage.logs.common.thereAreNoEntriesInTheLogThatMatchTheFilters'
                  )}
                  .
                </>
              )}
            </TableRowPlaceholder>
          )}
        </TableBody>
      </Table>
    </TableWrapper>
  );
}
