import React from 'react';
import { Route, Switch } from 'react-router';
import { List, ListItem, ListItemText } from '@material-ui/core';
import getFirstSegmentRelativeToRoot from '../../../lib/getFirstSegmentRelativeToRoot';
import { useRouter } from '../../../lib/useRouter';

import { IconButton } from '../../../components/IconButton';

import Logins from './logins/Logins';
import LogStorage from './log-storage/LogStorage';

import { t } from '../../../lib/i18n';

import './AccountSettings.scss';
import { IdleTimeout } from './idle-timeout/IdleTimeout';

// const areas = [
//   {
//     key: 'logins',
//     url: '',
//     label: 'Pålogging',
//     component: Logins,
//   },
//   {
//     key: 'log-storage',
//     url: 'log-storage',
//     label: 'Lagring av logger',
//     component: LogStorage,
//   },
// ];

export default function AccountSettings() {
  const router = useRouter();

  const currentSegment = getFirstSegmentRelativeToRoot(
    router.match.url,
    router.location.pathname
  );

  return (
    <div className="AccountSettings Manage-round-container Manage-main">
      <h1>{t('manage.account.AccountSettings.globalSettings')}</h1>

      <div className="my-4">
        <IconButton icon="arrow-left" to="/manage">
          {t('common.commonButtons.back')}
        </IconButton>
      </div>

      <div className="AccountSettings-content">
        <div className="AccountSettings-sidebar">
          <List className="Settings-list">
            <ListItem
              button
              onClick={() => {
                router.history.push(router.match.url + '');
              }}
            >
              <ListItemText
                primary={t('manage.account.AccountSettings.logins')}
                className="Settings-list-item"
                style={currentSegment === '' ? { color: '#63951D' } : {}}
              />
            </ListItem>

            <ListItem
              button
              onClick={() => {
                router.history.push(router.match.url + '/log-storage');
              }}
            >
              <ListItemText
                primary={t('manage.account.AccountSettings.logStorage')}
                className="Settings-list-item"
                style={
                  currentSegment === 'log-storage' ? { color: '#63951D' } : {}
                }
              />
            </ListItem>
            <ListItem
              button
              onClick={() => {
                router.history.push(router.match.url + '/idle-timeout');
              }}
            >
              <ListItemText
                primary={t('manage.account.AccountSettings.idleTimeout')}
                className="Settings-list-item"
                style={
                  currentSegment === 'idle-timeout' ? { color: '#63951D' } : {}
                }
              />
            </ListItem>

            {/*{areas.map(area => {*/}
            {/*  return (*/}
            {/*    <ListItem*/}
            {/*      button*/}
            {/*      key={area.key}*/}
            {/*      onClick={() => {*/}
            {/*        router.history.push(*/}
            {/*          router.match.url + (area.url ? `/${area.url}` : '')*/}
            {/*        );*/}
            {/*      }}*/}
            {/*    >*/}
            {/*      <ListItemText*/}
            {/*        primary={*/}
            {/*          area.key */}
            {/*          area.label*/}
            {/*        }*/}
            {/*        className="Settings-list-item"*/}
            {/*        style={*/}
            {/*          area.url === currentSegment ? { color: '#63951D' } : {}*/}
            {/*        }*/}
            {/*      />*/}
            {/*    </ListItem>*/}
            {/*  );*/}
            {/*})}*/}
          </List>
        </div>
        <div className="AccountSettings-page">
          <Switch>
            <Route exact path={router.match.url} component={Logins} />
            <Route
              exact={false}
              path={`${router.match.url}/log-storage`}
              component={LogStorage}
            />
            <Route
              exact={false}
              path={`${router.match.url}/idle-timeout`}
              component={IdleTimeout}
            />

            {/*{areas.map((area) => (*/}
            {/*  <Route*/}
            {/*    key={area.key}*/}
            {/*    exact={!area.url}*/}
            {/*    path={*/}
            {/*      area.url*/}
            {/*        ? `${router.match.url}/${area.url}`*/}
            {/*        : router.match.url*/}
            {/*    }*/}
            {/*    component={area.component}*/}
            {/*  />*/}
            {/*))}*/}
          </Switch>
        </div>
      </div>
    </div>
  );
}
