import React, { useState } from 'react';
import moment from 'moment';
import { IconButton } from '../../../components/IconButton';
import { RoleForm } from './forms/RoleForm';
import { useZoneContext } from '../../../lib/ZoneContext';
import { hardcodedImpersonalUserRoleName } from './NewImpersonal';
import { RouteLeavingGuard } from '../../../components/RouteLeavingGuard';

import * as api from '../../../serverApi';
import { useConfirmation } from '../../../lib/confirmation/ConfirmationContext';
import { errorIsString, useErrorHandler } from '../../../lib/useErrorHandler';
import { useApiCall } from '../../../lib/useApiCall';
import * as serverApi from '../../../serverApi';
import { t } from '../../../lib/i18n';
import { useAccount } from '../../../lib/useAccount';

interface Props {
  isImpersonalUser?: boolean;
  userId: number;
  userNationalIdNumber?: string | null;
  onAfterChange?: () => void;
}

export const CreateUserRolesForExistingUser = (props: Props) => {
  const account = useAccount();
  const zoneCtx = useZoneContext();
  const confirmation = useConfirmation();
  const errorHandler = useErrorHandler();
  const res = useApiCall(serverApi.getAvailableRoles);

  const availableRoles =
    res?.data?.roles?.filter((x) => x.role !== 'papi') || [];

  const [loading, setLoading] = useState<number | undefined>(undefined);

  const [roles, setRoles] = useState<api.CreateUserRoleInput[]>(
    [] as api.CreateUserRoleInput[]
  );

  const setRole = (roleIndex: number, role: string) => {
    if (!roles) {
      return;
    }
    setRoles(roles.map((r, i) => (i === roleIndex ? { ...r, role: role } : r)));
  };

  const setZone = (roleIndex: number, zoneId: number) => {
    if (!roles) {
      return;
    }
    setRoles(
      roles.map((r, i) => (i === roleIndex ? { ...r, zone_id: zoneId } : r))
    );
  };

  const onDateChange = (
    roleIndex: number,
    name: string,
    time: moment.Moment | null
  ) => {
    setRoles(
      roles.map((role, i) =>
        i === roleIndex
          ? {
              ...role,
              [name]: time ? time.format('YYYY-MM-DD') : null,
            }
          : role
      )
    );
  };

  const removeZone = (zoneIndex: number) => {
    setRoles(roles?.filter((r, i) => i !== zoneIndex));
  };

  const handleRemoveZone = (zoneIndex: number) => {
    confirmation
      .confirm(
        t('common.commonTexts.unSavedChangesHaveBeenMadeDoYouWantToContinue')
      )
      .then(() => {
        setRoles(roles?.filter((r, i) => i !== zoneIndex));
      })
      .catch(() => {});
  };

  const handleAddNewRole = () => {
    setRoles([
      {
        role: props.isImpersonalUser ? hardcodedImpersonalUserRoleName : '',
        zone_id: zoneCtx.activeZone.id,
        valid_from: null,
        valid_to: null,
      },
      ...roles,
    ] as api.CreateUserRoleInput[]);
  };

  const hasRoleErrors = () => {
    const rolesWithCapabilities = availableRoles.filter((r) =>
      roles.find((x: any) => x.role === r.role)
    );
    const hasSendActivityToVkp = rolesWithCapabilities?.find((r: any) =>
      r.capabilities.includes('sendActivityToVkp')
    );
    return (
      !!hasSendActivityToVkp &&
      !props.userNationalIdNumber &&
      !account.disable_national_id_numbers &&
      account.vkp_available
    );
  };

  const handleSave = async (zoneIndex: number) => {
    const role = roles?.find((r, i) => i === zoneIndex);

    if (!role) {
      return;
    }

    if (role.role === '') {
      errorHandler.setStringError(
        t('manage.permissions.CreateUserRolesForExistingUser.selectARole')
      );
      return;
    }

    const hasErrors = hasRoleErrors();

    if (hasErrors) {
      errorHandler.setStringError(
        t(
          'manage.permissions.CreateUserRolesForExistingUser.userNeedsNationalIdNumber'
        )
      );
      return;
    }

    try {
      setLoading(zoneIndex);

      await api.createUserRoles({
        user_id: props.userId,
        ...role,
      });

      setLoading(undefined);

      removeZone(zoneIndex);

      if (props.onAfterChange) {
        props.onAfterChange();
      }
    } catch (e) {
      setLoading(undefined);
      errorHandler.handleError(e);
    }
  };

  return (
    <div className="CreateUserRolesForExistingUser">
      <RouteLeavingGuard when={roles.length > 0} />
      <div className="d-flex mb-3">
        <IconButton icon="plus" color="primary" onClick={handleAddNewRole}>
          {t('manage.permissions.common.addRole')}
        </IconButton>
      </div>

      {roles?.map((r, index) => (
        <RoleForm
          key={`${r.role}-${index}`}
          mode="edit"
          error={
            errorIsString(errorHandler.error) ? errorHandler.error : undefined
          }
          loading={loading === index}
          role={r}
          availableRoles={availableRoles}
          roleLocked={props.isImpersonalUser}
          roleBlacklist={[!props.isImpersonalUser ? 'impersonal' : '']}
          setRole={(role) => setRole(index, role)}
          setZone={(zoneId) => setZone(index, zoneId)}
          setValidFrom={(validFrom) =>
            onDateChange(index, 'valid_from', validFrom)
          }
          setValidTo={(validTo) => onDateChange(index, 'valid_to', validTo)}
          onSave={() => handleSave(index)}
          onCancel={() => handleRemoveZone(index)}
        />
      ))}
    </div>
  );
};
