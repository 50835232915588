import * as d3 from 'd3';
import { RefObject, useEffect } from 'react';

interface ZoomOptions {
  scaleExtent: [number, number];
  translateExtent: [[number, number], [number, number]];
  extent: [[number, number], [number, number]];
}

export function useD3Zoom<T extends HTMLElement | SVGElement>(
  ref: RefObject<T>,
  onZoomCallback: (event: any) => void,
  options: ZoomOptions
) {
  const { extent, scaleExtent, translateExtent } = options;
  useEffect(() => {
    const zoom: any = d3
      .zoom()
      .scaleExtent(scaleExtent)
      .translateExtent(translateExtent)
      .extent(extent)
      .on('zoom', onZoomCallback);

    const selection = d3.select(ref.current);

    selection.call(zoom);
  }, [extent, onZoomCallback, ref, scaleExtent, translateExtent]);
}
