import React, { useContext } from 'react';
import { SessionState, Core } from 'sip.js';

export type AudioProperty = 'oneWayAnon' | 'oneWay' | 'twoWay';
export type PresenceState =
  | 'Ready'
  | 'Ringing'
  | 'On the phone'
  | 'Unavailable';

export interface WebRtcContext {
  /**
   * Any active call for the current user (not for existing calls to other users on the same sensor)
   */
  call?: {
    /**
     * The sensor ID to which the currently active call is connected.
     */
    sensorId: number;

    audioProperty: AudioProperty;
    status: SessionState | undefined;

    /**
     * The cause of session termination
     */
    //cause?: C.causes;
    cause?: string;
    /**
     * a bit detailed info for a rejected call.
     */
    rejected_reason?: string;
  };

  /**
   * Status of the whole SIP provider -- 'loading' while loading config and setting up,
   * 'disabled' if SIP has been disabled (on the server or user level).
   */
  status: 'loading' | 'disabled' | 'enabled';

  /**
   * If the sensor is currently busy (from other users connecting to it)
   */
  busy?: boolean;

  /**
   * Why the sensor is currently busy (from other users connecting to it)
   */
  busyStatus?: Core.SessionDialog;

  /**
   * Request a call to the sensor currently defined by props.sensorId
   */
  requestCall: (audioProperty: AudioProperty) => void;

  /**
   * Request a hangup of a currently connected session
   */
  requestHangup: () => void;
}

const webRtcContext = React.createContext<WebRtcContext | undefined>(undefined);

const { Provider } = webRtcContext;

export const useWebRtcContext = () => useContext(webRtcContext);

export { Provider as WebRtcContextProvider };
