import React from 'react';
import * as api from '../../../../serverApi';
import { TableWrapper } from '../../../../components/TableWrapper';

import {
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
} from '@material-ui/core';
import { TableRowPlaceholder } from '../../common/TableRowPlaceholder';
import { InlineSpinner } from '../../../../components/InlineSpinner';
import { t, TranslationKey } from '../../../../lib/i18n';
import { EventTypeIcon } from '../../../../lib/utils/EventTypeIcon';
import { getLabelForEventType } from '../../../../lib/getLabelForEventType';

interface Props {
  entries?: api.EventLogEntry[];
  loading?: boolean;
}

function Row(props: { entry: api.EventLogEntry }) {
  const e = props.entry;

  const elems = [
    ...(e.zone?.parent ? [`${e.zone.parent.name}`] : []),
    ...(e.zone ? [`${e.zone.name}`] : []),
    ...(e.sensor?.name ? [`${e.sensor.name}`] : []),
    ...(e.sensor?.name || e.care_receiver ? [] : [`${e.sensor.id}`]),
  ];

  const location = elems.join(' / ');

  return (
    <TableRow key={e.event_log_id}>
      <TableCell>{e.received_at_user_local}</TableCell>
      <TableCell>
        {t(`common.eventStatus.${e.event_status}` as TranslationKey)}
      </TableCell>
      <TableCell>
        <div style={{ display: 'flex', alignItems: 'flex-end' }}>
          <EventTypeIcon
            sensorType={e.sensor.sensor_type}
            eventType={e.event_type}
          />
          &nbsp;
          {getLabelForEventType(e.event_type, e.sensor.sensor_type)}
        </div>
      </TableCell>
      <TableCell>
        {e.user
          ? `${e.user.display_name || ''} ${e.user.first_name || ''} ${
              e.user.last_name || ''
            }${e.user.username ? ` (${e.user.username})` : ''}`
          : ''}
      </TableCell>

      <TableCell>
        {e.sensor.sensor_type}
        {e.sensor.sensor_id}
      </TableCell>

      <TableCell>{location}</TableCell>

      <TableCell>
        {e.care_receiver && (
          <>
            {e.care_receiver.first_name} {e.care_receiver.last_name}
          </>
        )}
      </TableCell>
    </TableRow>
  );
}

export function EventLogEntriesTable(props: Props) {
  return (
    <TableWrapper>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell>
              {t('manage.logs.EventLogEntriesTable.columns.time')}
            </TableCell>
            <TableCell>
              {t('manage.logs.EventLogEntriesTable.columns.action')}
            </TableCell>
            <TableCell>
              {t('manage.logs.EventLogEntriesTable.columns.type')}
            </TableCell>
            <TableCell>
              {t('manage.logs.EventLogEntriesTable.columns.username')}
            </TableCell>
            <TableCell>
              {t('manage.logs.EventLogEntriesTable.columns.sensor')}
            </TableCell>
            <TableCell>
              {t('manage.logs.EventLogEntriesTable.columns.account')}
            </TableCell>
            <TableCell>
              {t('manage.logs.EventLogEntriesTable.columns.careReceiver')}
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {props.entries && props.entries.length > 0 ? (
            props.entries.map((e) => <Row entry={e} key={e.event_log_id} />)
          ) : (
            <TableRowPlaceholder colSpan={7}>
              {props.loading ? (
                <InlineSpinner text={t('common.commonTexts.loading')} />
              ) : (
                <>
                  {t(
                    'manage.logs.common.thereAreNoEntriesInTheLogThatMatchTheFilters'
                  )}
                  .
                </>
              )}
            </TableRowPlaceholder>
          )}
        </TableBody>
      </Table>
    </TableWrapper>
  );
}
