import { useState } from 'react';
import * as Sentry from '@sentry/browser';
import { hasTranslationKey, t } from './i18n';
import { isAxiosError, isValidationError } from './utils/errorUtils';

interface ObjectError {
  [key: string]: string;
}
type ErrorType = ObjectError | string[] | string | null;

export type ErrorHandlerType = ReturnType<typeof useErrorHandler>;

/**
 * The useErrorHandler hook is used to make handling errors in the frontend
 * more ergonomic for the developers.
 *
 * It can process the errors received from the server (in a standardized
 * http 422 Laravel way) and return them as an object with the field names
 * that's easier to consume in the frontend, and easier to integrate with
 * the formik forms.
 *
 * See also the wiki for more information and examples:
 * https://gitlab.rmate.no:4443/roommate/web/-/wikis/Client-side-error-handling
 */
export const useErrorHandler = () => {
  const [error, setError] = useState<ErrorType>(null);
  const [status, setStatus] = useState<number | null>(null);

  const handleError = (error: unknown) => {
    Sentry.captureException(error);
    if (!isAxiosError(error)) {
      return setError(String(error));
    }

    if (!error || !error.response) {
      setError(t('lib.common.noResponse'));
      return;
    }

    setStatus(error.response.status);

    if (error.response.status >= 500) {
      setError(t('lib.common.serverError'));
      return;
    }

    if (error.response?.data?.code) {
      const errorCode = error.response.data.code;
      const key = `common.serverError.${errorCode}`;
      const errorMessage = hasTranslationKey(key)
        ? t(key)
        : error.response.data.message;
      setError(errorMessage);
      return;
    }

    if (error.response.data.status === 'error' && error.response.data.message) {
      setError(error.response.data.message);
      return;
    }

    /**
     * The condition below checks if errors property is an array type.
     * Normally, the errors property should be an object with array values
     * (that's the response format for validation coming from Laravel see: https://laravel.com/docs/9.x/validation#validation-error-response-format),
     * but we're keeping this condition and logging the attempt to sentry, so that we're informed about this occurence
     * until we're sure that this function is never called.
     */
    if (error.response.status === 422) {
      if (
        error.response?.data?.errors &&
        Array.isArray(error.response?.data?.errors) &&
        error.response.data.errors.length > 0
      ) {
        /**
         * In case this function is called, we log the error thrown to sentry,
         * with additional fields so that we know it's coming from here
         */
        Sentry.captureException(error, {
          extra: {
            reason: 'The errors property is an array type',
            errors: error.response.data.errors,
          },
        });
        return;
      }
    }

    if (
      isValidationError(error) &&
      Object.keys(error.response.data.errors).length > 0
    ) {
      const errorsData = error.response.data.errors;
      const errorsObject: ObjectError = {};

      Object.keys(errorsData).forEach((key: string) => {
        if (errorsData.hasOwnProperty(key)) {
          errorsObject[key] = errorsData[key][0];
        }
      });

      setError(errorsObject);
      return;
    }

    setError(error.message);
  };

  const reset = () => {
    setError(null);
    setStatus(null);
  };

  const setStringError = (e?: string) => {
    setError(e || null);
  };

  const setObjectError = (error?: ObjectError) => {
    setError(error || null);
  };

  const setArrayError = (error?: string[]) => {
    setError(error || null);
  };

  const getErrorByKey = (key: string) => {
    if (errorIsObjectError(error) && error[key]) {
      return error[key];
    }
    return null;
  };

  return {
    error,
    status,
    //We now use the errorIsString type guard instead, keeping this too just in case we ever need it
    isString: error === null || typeof error === 'string',
    handleError,
    reset,
    setStringError,
    setObjectError,
    setArrayError,
    getErrorByKey,
  };
};

/**
 *
 * Typeguard function for checking if the given error is an object
 *
 * @param error
 * @returns boolean - if error is an object type
 */
export function errorIsObjectError(error?: ErrorType): error is ObjectError {
  if (
    !Array.isArray(error) &&
    typeof error !== 'string' &&
    error !== null &&
    error !== undefined
  ) {
    return true;
  }
  return false;
}

/**
 *
 * Typeguard function for checking if the given error is an array
 *
 * @param error
 * @returns boolean - if error is an array type
 */
export function errorIsArray(error: ErrorType) {
  if (Array.isArray(error)) {
    return true;
  }
  return false;
}

/**
 * Typeguard function for ensuring the given error is type string
 *
 * @param error
 * @returns boolean - if error is string
 */
export function errorIsString(error: ErrorType): error is string {
  return error === null || typeof error === 'string';
}
