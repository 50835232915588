import React from 'react';
import { TableContainer } from '@material-ui/core';

interface Props {
  children: React.ReactNode;
  className?: string;
  style?: React.CSSProperties;
  tableContainerStyle?: React.CSSProperties;
}

export const TableWrapper = (props: Props) => {
  return (
    <TableContainer style={props.tableContainerStyle}>
      {props.children}
    </TableContainer>
  );
};
