import cs from 'classnames';
import { TableCell, TableRow } from '@material-ui/core';
import { InlineSpinner } from '../../../components/InlineSpinner';
import { t } from '../../../lib/i18n';

import './TableLoadingPlaceholder.scss';

interface Props {
  className?: string;
  style?: React.CSSProperties;
  colSpan?: number;
}
export const TableLoadingPlaceholder = (props: Props) => {
  return (
    <TableRow className={cs('TableLoadingPlaceholder', props.className)}>
      <TableCell
        className="TableLoadingPlaceholder__cell"
        component="td"
        colSpan={props.colSpan}
      >
        <div className="TableLoadingPlaceholder__wrapper">
          <InlineSpinner text={t('common.commonTexts.loading')} />
        </div>
      </TableCell>
    </TableRow>
  );
};
