import React, { CSSProperties } from 'react';
import cs from 'classnames';

import * as api from '../../../serverApi';

import './CareCard.scss';

type ClickEvent =
  | React.TouchEvent<HTMLButtonElement | HTMLDivElement>
  | React.MouseEvent<HTMLButtonElement | HTMLDivElement>;

interface Props {
  sensor: api.CareSensor;
  selected?: boolean;
  onClick?: (e: ClickEvent) => void;
  icon?: React.ReactNode | null;
  className?: string;
  style?: CSSProperties;
}

/**
 * CareCard is the shared UI component used for representing sensors and events in the Care area sidebar.
 */
export function CareCard(props: Props) {
  return (
    <div
      role="button"
      className={cs(
        'CareCard',
        props.selected ? 'CareCard--selected' : undefined,
        props.className
      )}
      style={props.style}
      onClick={props.onClick}
    >
      <div className="CareCard-area">{props.sensor.display_area}</div>

      <div className="CareCard-name">{props.sensor.display_name}</div>

      {props.icon ? <div className="CareCard-icon">{props.icon}</div> : null}
    </div>
  );
}
