import React, { useState, CSSProperties } from 'react';
import cs from 'classnames';
import MomentUtils from '@date-io/moment';
import moment from 'moment';
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from '@material-ui/pickers';
import { FormControl, InputAdornment, TextField } from '@material-ui/core';

import * as api from '../../../../serverApi';

import { Icon } from '../../../../components/Icon';
import { useZoneTreeContext } from '../../../care/lib/ZoneTreeContext';
import ZonePickerModal from '../../../../components/ZonePickerField/ZonePickerModal';
import { t } from '../../../../lib/i18n';

import './Filters.scss';
import { getGlobalLocale } from '../../../../lib/i18n/i18n';

interface Props {
  zoneId?: number | null;
  onZoneChange?: (zone_id: number | undefined) => void;

  periodFrom?: string;
  periodTo?: string;
  onPeriodChange?: (from: string | undefined, to: string | undefined) => void;

  className?: string;
  style?: CSSProperties;
}

export function Filters(props: Props) {
  const [openModal, setOpenModal] = useState(false);
  const ctx = useZoneTreeContext();
  const dateFormat = 'YYYY-MM-DD';
  const zone = ctx.getZoneById(props.zoneId);

  const fromDate = props.periodFrom
    ? moment(props.periodFrom, dateFormat)
    : null;

  const toDate = props.periodTo ? moment(props.periodTo, dateFormat) : null;

  return (
    <div className={cs('Filters', props.className)} style={props.style}>
      <MuiPickersUtilsProvider locale={getGlobalLocale()} utils={MomentUtils}>
        <KeyboardDatePicker
          className="Filters-date"
          margin="normal"
          id="date-picker-dialog-from"
          label={t('manage.logs.ui.Filters.periodFrom')}
          format="DD.MM.YYYY"
          okLabel={t('common.commonButtons.ok')}
          cancelLabel={t('common.commonButtons.cancel')}
          maxDate={toDate}
          value={fromDate}
          onChange={(e) => {
            if (toDate && e?.isAfter(toDate)) {
              if (props.onPeriodChange) {
                props.onPeriodChange(
                  fromDate?.format(dateFormat),
                  props.periodTo
                );
              }
              return;
            }

            if (props.onPeriodChange) {
              props.onPeriodChange(e?.format(dateFormat), props.periodTo);
            }
          }}
          invalidDateMessage={t('common.commonTexts.wrongFormat')}
          KeyboardButtonProps={{
            'aria-label': 'change date',
          }}
        />

        <KeyboardDatePicker
          className="Filters-date"
          margin="normal"
          id="date-picker-dialog-to"
          label={t('manage.logs.ui.Filters.periodTo')}
          okLabel={t('common.commonButtons.ok')}
          cancelLabel={t('common.commonButtons.cancel')}
          minDate={fromDate || undefined}
          format="DD.MM.YYYY"
          value={toDate}
          onChange={(e) => {
            if (fromDate && e?.isBefore(fromDate)) {
              if (props.onPeriodChange) {
                props.onPeriodChange(
                  props.periodFrom,
                  toDate?.format(dateFormat)
                );
              }
              return;
            }

            if (props.onPeriodChange) {
              props.onPeriodChange(props.periodFrom, e?.format(dateFormat));
            }
          }}
          invalidDateMessage={t('common.commonTexts.wrongFormat')}
          KeyboardButtonProps={{
            'aria-label': 'change date',
          }}
        />
      </MuiPickersUtilsProvider>

      <FormControl className="flex-grow-1">
        <TextField
          name="zone"
          type="text"
          className="Filters-zone mt-2 mt-lg-0"
          placeholder={zone?.name && t('common.commonTexts.selectedZone')}
          inputProps={{
            readOnly: true,
            style: {
              cursor: 'pointer',
            },
          }}
          value={zone?.name}
          style={{ color: '#000', cursor: 'pointer' }}
          onClick={() => setOpenModal(true)}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <Icon icon="zone-tree" className="RoleForm--select-tree-icon" />
              </InputAdornment>
            ),
          }}
        />
        <ZonePickerModal
          isOpen={openModal}
          selectedZoneId={props.zoneId || null}
          handleModal={() => setOpenModal(!openModal)}
          onSelect={(zone?: api.StructureZone) => {
            if (props.onZoneChange) {
              props.onZoneChange(zone?.id);
            }
          }}
        />
      </FormControl>
    </div>
  );
}
