import React, { useState } from 'react';
import { Button } from '@material-ui/core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus } from '@fortawesome/pro-light-svg-icons';
import BulkAssignVendorModal from './BulkAssignVendorModal';

interface Props {
  onChanged?: () => void;
}

export const BulkAssignVendorButton = (props: Props) => {
  const [isOpen, setIsOpen] = useState(false);

  const handleOnModalChange = () => {
    if (!props.onChanged) {
      return;
    }
    props.onChanged();
  };

  return (
    <div className="BulkAssignVendorButton">
      <Button onClick={() => setIsOpen(true)}>
        <FontAwesomeIcon icon={faPlus} className="mr-2" />
        Bulk assign vendor
      </Button>

      <BulkAssignVendorModal
        isOpen={isOpen}
        toggle={() => setIsOpen(!isOpen)}
        onChanged={handleOnModalChange}
      />
    </div>
  );
};
