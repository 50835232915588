import React, { CSSProperties } from 'react';
import cs from 'classnames';

import './IconButton.scss';

import { Button as MaterialButton } from '@material-ui/core';
import { IconDefinition, Icon } from '../Icon';
import { Link } from 'react-router-dom';

export type IconButtonProps = {
  color?: 'default' | 'primary' | 'secondary' | 'transparent';
  children?: React.ReactNode;

  icon?: IconDefinition;
  bigIcon?: boolean;
  spinIcon?: boolean;

  type?: 'button' | 'submit' | 'reset';

  hideLabel?: boolean;
  className?: string;

  /**
   * If this is provided, the button will render as a <a /> link.
   */
  href?: string;
  target?: string;

  /**
   * If this is provided, the button will render as a <Link /> link.
   */
  to?: string;

  /**
   * This is passed through to the MaterialButton.
   */
  onClick?: (e: React.MouseEvent<HTMLButtonElement>) => void;

  /**
   * This is passed through to the MaterialButton.
   */
  disabled?: boolean;

  /**
   * This is passed through to the MaterialButton.
   */
  style?: CSSProperties;
};

export function IconButton(props: IconButtonProps) {
  const {
    color,
    children,
    className,
    hideLabel,
    icon,
    bigIcon,
    spinIcon,
    ...rest
  } = props;

  const materialColor =
    color === 'primary' || color === 'secondary' || color === 'transparent'
      ? color
      : undefined;

  const classes = cs(
    'MuiButton-default',
    'MuiButton-icon-square',
    'IconButton',
    bigIcon ? 'IconButton--big-icon' : undefined,
    materialColor ? `IconButton--${materialColor}` : undefined,
    hideLabel ? 'IconButton--no-label' : undefined,
    className
  );

  const iconClasses = cs(
    'IconButton-icon',
    materialColor && `IconButton-icon--${materialColor}`,
    !materialColor && `IconButton-icon--default`
  );

  return (
    <MaterialButton
      className={classes}
      href={props.href}
      target={props.target}
      type={props.type}
      to={props.to}
      {...(props.to ? { component: Link } : {})}
      {...rest}
    >
      {icon && <Icon className={iconClasses} spin={spinIcon} icon={icon} />}
      {hideLabel ? <span className="sr-only">{children}</span> : children}
    </MaterialButton>
  );
}
