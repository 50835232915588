import React from 'react';
import cs from 'classnames';
import { Sparklines, SparklinesLine } from 'react-sparklines';

import './SensorHistoryGraph.scss';

interface Props {
  label?: string;
  data: number[];
  className?: string;
}

export function SensorHistoryGraph(props: Props) {
  return (
    <div className={cs('SensorHistoryGraph', props.className)}>
      {props.label && (
        <div className="SensorHistoryGraph-label">{props.label}</div>
      )}

      <div className="SensorHistoryGraph-graph">
        <Sparklines data={props.data} height={40}>
          <SparklinesLine color="blue" />
        </Sparklines>
      </div>
    </div>
  );
}
