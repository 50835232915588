import React from 'react';

import LoadingPlaceholder from '../../../../lib/ui/LoadingPlaceholder';
import { useSensorContext } from '../../../../lib/SensorContext';
import { useApiCall } from '../../../../lib/useApiCall';

import {
  SensorEnabledEventsContext,
  SensorEnabledEventsContextType,
} from './SensorEnabledEventsContext';

import * as api from '../../../../serverApi';
import { getLabelForEventType } from '../../../../lib/getLabelForEventType';
import {
  eventTypesBySensorType,
  eventTypesInOrderBySensorType,
} from '../../../../eventTypes';

interface Props {
  children: React.ReactNode;
}

export const SensorEnabledEventsContextProvider = (props: Props) => {
  const sensorContext = useSensorContext();

  const sensorRes = useApiCall(api.getSensorDetails, [sensorContext.id]);
  const res = api.useGetEventSettingsBySensorIdQuery(sensorContext.id);

  if (res.isLoading || sensorRes.loading) {
    return <LoadingPlaceholder style={{ width: '100%' }} />;
  }

  if (!res.data || !sensorRes.data) {
    return null;
  }
  const sensorEventTypes = res.data;

  function isDefined<T>(argument: T | undefined): argument is T {
    return argument !== undefined;
  }

  const sensorType = sensorRes.data.sensor.sensor_type;

  const eventTypesInOrder: string[] =
    eventTypesInOrderBySensorType[sensorType] ?? [];

  const eventsEnabledForThisSensor = eventTypesInOrder
    .map((ev) => ({
      ...eventTypesBySensorType[sensorType][ev],
      key: ev,
      label: getLabelForEventType(ev, sensorType),
    }))
    .map((e) => {
      // Event not defined; this means that the sensor does not have that event
      // enabled at all -- i.e. no row in the event_settings table
      if (!sensorEventTypes[e.key]) {
        return undefined;
      }

      return {
        ...e,
        sensor: sensorEventTypes[e.key],
      };
    })
    .filter(isDefined);

  const ctx: SensorEnabledEventsContextType = {
    events: eventsEnabledForThisSensor,
  };

  return (
    <SensorEnabledEventsContext.Provider value={ctx}>
      {props.children}
    </SensorEnabledEventsContext.Provider>
  );
};
