import { useRouter } from './useRouter';
import { useConfirmation } from './confirmation/ConfirmationContext';
import getParentPath from './getParentPath';
import { t } from './i18n';

export const useHandleCancel = () => {
  const router = useRouter();
  const confirmation = useConfirmation();

  const handleCancel = (
    hasChanges: boolean | undefined,
    redirectPath = null as string | null,
    callback?: () => void
  ) => {
    const path = redirectPath || getParentPath(router.location.pathname);

    if (!hasChanges) {
      router.history.push(path);
      return;
    }

    return confirmation
      .confirm(
        t('common.commonTexts.unSavedChangesHaveBeenMadeDoYouWantToContinue')
      )
      .then(() => {
        if (callback) {
          callback();
        }
        router.history.push(path);
      })
      .catch(() => {});
  };
  return {
    handleCancel,
  };
};
