import React from 'react';

import useEventSettingsForm, { EventFormProps } from '../useEventSettingsForm';

import EventActiveControl from '../controls/EventActiveControl';
import EventLevelControl from '../controls/EventLevelControl';
import EventTimeIntervalControl from '../controls/EventTimeIntervalControl';
import EventFormControls from '../controls/EventFormControls';
import EventNumberControl from '../controls/EventNumberControl';
import PlayEventSoundControl from '../controls/PlayEventSoundControl';
import { RouteLeavingGuard } from '../../../../../components/RouteLeavingGuard';
import { Panel } from '../../../../../components/Panel';
import { t } from '../../../../../lib/i18n';
import { getLabelForEventType } from '../../../../../lib/getLabelForEventType';
import { MissingMarkingWarningPanel } from '../controls/MissingMarkingWarningPanel';

export default function LeftBedEventForm(props: EventFormProps) {
  const form = useEventSettingsForm({
    sensorType: 'roommate',
    eventType: 'oob',
    initialValues: props.initialValues,
    scales: {
      threshold2: 60,
    },
  });

  return (
    <form onSubmit={form.handleSubmit}>
      <RouteLeavingGuard when={form.dirty} />
      <h2>{getLabelForEventType('oob', 'roommate')}</h2>

      <MissingMarkingWarningPanel
        onlyWarnWhenMissing={true}
        markingType="bed"
      />

      <Panel>
        <span
          dangerouslySetInnerHTML={{
            __html: t('manage.sensors.events.LeftBedEventForm.firstInfo'),
          }}
        />
      </Panel>

      <EventActiveControl form={form} />

      <EventTimeIntervalControl form={form} />

      <EventNumberControl
        form={form}
        label={t('manage.sensors.common.delayInMinutes')}
        field="threshold2"
      />

      <EventLevelControl form={form} />

      <PlayEventSoundControl form={form} />

      <EventFormControls form={form} />
    </form>
  );
}
