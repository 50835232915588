/**
 *
 * for SIP.js version 0.17.1
 *
 * https://github.com/onsip/SIP.js/blob/0.16.0/docs/migration-0.15-0.16.md
 * https://github.com/onsip/SIP.js/blob/master/docs/migration-0.16-0.17.md
 *
 *
 * This class is a singleton that maintains state around SIP/sip.js.
 * The data returned in the contextValue object in the render() method is the only
 * fields/methods that are exposed to the rest of the application, and the values
 *
 *
 */

import React from 'react';
import * as api from '../../../serverApi';
import {
    WebRtcContext,
    WebRtcContextProvider,
    AudioProperty,
    PresenceState,
} from './WebRtcContext';
import { getSipUserAgentOptions } from './getSipUserAgentOptions';
import {
    SubscriptionDelegate,
    Subscriber,
    InviterOptions,
    SubscriptionState,
    UserAgentState,
    SessionState,
    UserAgent,
    Session,
    Registerer,
    Subscription,
    Web,
    RegistererState,
    Inviter,
    Core,
    InviterInviteOptions,
    Message,
} from 'sip.js';
import * as xmlParser from 'fast-xml-parser';
import { isAxiosError } from '../../../lib/utils/errorUtils';

interface Props {
    sensorId?: number;
    sensorStatus?:
        | 'offline'
        | 'blocked'
        | 'deactivated'
        | 'silenced'
        | 'online'
        | undefined;
}

interface State {
    status: 'loading' | 'enabled' | 'disabled';
    sipUserAgentOptions?: ReturnType<typeof getSipUserAgentOptions>;
    settings?: api.CareWebRtcConfig;
    ua?: {
        status?: UserAgentState;
    };
    active_call?: {
        sensorId: number;
        status: SessionState | undefined;
        audio_property?: AudioProperty;
        //cause?: SIP.C.causes;//SIP.Core.SignalingState;//.C.causes;
        cause?: string;
        rejected_reason?: string;
    };
    subscribe?: {
        status?: SubscriptionState;
        blf?: {
            sensorId: number;
            status?: PresenceState;
        };
    };
    audioProperty?: AudioProperty;
}

export class WebRtcProvider extends React.Component<Props, State> {
    private ua: UserAgent | undefined;
    private sipSession: Session | undefined;
    private registerer: Registerer | undefined;
    private subscription: Subscription | undefined;
    private audioRef: React.RefObject<HTMLAudioElement>;
    private audio_property: AudioProperty | undefined = undefined;

    constructor(props: Props) {
        super(props);
        this.audioRef = React.createRef<HTMLAudioElement>();
        this.state = {
            sipUserAgentOptions: undefined,
            settings: undefined,
            status: 'loading',
        };
    }

    async componentDidMount() {
        // Only load the configuration once -- we might consider loading when selecting
        // a sensor instead _if_ the configuration is sensor-specific.
        await this.loadConfig();
        //voip server is triggered with a warning "Shutting down transport 'WS to 127.0.0.1:50800' since no request was received in 32 seconds".
        //if clicking online sensor on the left sidebar 32 seconds later than browsing /care page.
        //even although sip.js sends keep alive message every 15 seconds, and receives WebSocket message with CRLF Keep Alive response from voip server.
        //voip server drops the established connection when no request was received in 32 seconds,
        //and sip.js client re-initiates a new connection when the connection is down.
        //Only after the first subscribe request is sent, keep alive message starts playing a role, the established websocket connection is persisted.
        //this is a bug inside voip server.
        //do not connect voip server at this moment to suppress annoying warning messages in voip server.
        //asterisk v16.2.1 does no longer complain that annoying warning when WebSocket message with CRLF Keep Alive is sent from the browser
    }

    async componentDidUpdate(prevProps: Props) {
        // If we are only unselecting a sensor, we need to unsubscribe from the previously selected sensor.
        if (prevProps.sensorId !== this.props.sensorId) {
            //for established conncection
            if (prevProps.sensorId) {
                // terminate an active call
                this.requestHangup();

                // Unsubscribe from the currently connected sensor
                this.unsubscribe();
            }
            if (this.props.sensorId && this.props.sensorStatus === 'online') {
                //subscribe only for online sensors
                this.subscribe();
            }
        }
    }

    componentWillUnmount() {
        this.disconnectVoipServer();
    }

    selectSensor = (sensorId: number) => {};

    loadConfig = async () => {
        try {
            const settings = await api.careGetWebRtcConfig();

            /**
             * If the disabled property is true, it means the web-rtc is disabled either because
             * of the server or user doesn't have it activated
             */
            if (settings.disabled) {
                this.setState({
                    status: 'disabled',
                });
                return;
            }

            let sipUserAgentOptions = getSipUserAgentOptions(settings);
            sipUserAgentOptions.delegate = {
                onConnect: () => {
                    console.log('VoIP server connectivity established');
                    this.setState({
                        status: 'enabled',
                        ua: {
                            status: this.ua?.state,
                        },
                    });
                    this.register();
                },
                onDisconnect: (error?: Error) => {
                    console.error('VoIP server connectivity lost');
                    this.setState({
                        //to disable call buttons
                        status: 'disabled',
                        //to clean up subscribe.
                        //when reconnected with voip server, the call buttons are not enabled until presence state to a new subscribe request is ready
                        subscribe: undefined,
                    });
                },
                onMessage: (message: Message) => {
                    this.setActiveCallStatus(
                        'unauthorized',
                        message.request.body
                    );
                    message.accept();
                },
            };
            this.setState({
                settings,
                sipUserAgentOptions,
            });
            this.ua = new UserAgent(sipUserAgentOptions);
            this.ua.start();
        } catch (err) {
            this.setState({ status: 'disabled' });
            if (
                isAxiosError(err) &&
                err.response &&
                err.response.status === 403 &&
                (err.response.data.status === 'webrtc-disabled' ||
                    err.response.data.status === 'webrtc-server-disabled')
            ) {
                // WebRTC is disabled for this user; we set status='disabled' and this component has nothing more to do.
                return;
            }
            // If it's another error, we rethrow it and let someone else deal with it.
            throw err;
        }
    };

    setActiveCallStatus(cause?: string, rejected_reason?: string) {
        this.setState({
            active_call: {
                sensorId: Number(this.sipSession?.remoteIdentity.uri.user),
                audio_property: this.audio_property,
                status: this.sipSession?.state,
                cause: cause ? cause : undefined,
                rejected_reason: rejected_reason ? rejected_reason : undefined,
            },
        });
    }

    /*setActiveCallStatus(cause?: SIP.C.cause, rejected_reason?: string) {
        this.setState({
            active_call: {
                sensorId: Number(this.sipSession?.remoteIdentity.uri.user),
                audio_property: this.audio_property,
                status: this.sipSession?.state,
                cause: cause ? cause : undefined,
                rejected_reason: rejected_reason ? rejected_reason : undefined,
            },
        });
    }*/

    bridgeRemoteMedia = () => {
        if (this.audioRef.current) {
            //sip.js v0.17.0, retrieve remote media in a simple way
            this.audioRef.current.srcObject = (this.sipSession
                ?.sessionDescriptionHandler as Web.SessionDescriptionHandler).remoteMediaStream;
        }
    };

    cleanupMedia = () => {
        if (this.audioRef.current) {
            this.audioRef.current.srcObject = null;
        }
    };

    register = () => {
        if (this.ua) {
            const registerOptions = {
                expires: 3600,
            };
            this.registerer = new Registerer(this.ua, registerOptions);

            // Setup registerer state change handler
            this.registerer.stateChange.addListener((newState) => {
                switch (newState) {
                    case RegistererState.Registered:
                        this.subscribe();
                        break;
                    case RegistererState.Unregistered:
                        break;
                    case RegistererState.Terminated:
                        break;
                }
            });

            // Send REGISTER
            this.registerer
                .register()
                .then((request) => {
                    console.log('Successfully sent REGISTER');
                })
                .catch((error) => {
                    console.error('Failed to send REGISTER');
                });
        }
    };

    unregister = () => {
        if (this.registerer) {
            this.registerer
                .unregister()
                .then((request) => {
                    console.log('Successfully sent un-REGISTER');
                })
                .catch((error) => {
                    console.error('Failed to send un-REGISTER');
                });
        }
    };

    disconnectVoipServer = () => {
        this.ua
            ?.stop()
            .then(() => {})
            .catch((error) => {
                console.error('Failed to disconnect voip server');
            });
    };

    subscribe = () => {
        if (!this.props.sensorId) {
            return;
        }

        let subscribeOptions = {
            expires: 3600,
            extraHeaders: [
                `Security-Id: ${this.state.settings?.voipSecurityIdHash}`,
            ],
        };

        // Delegate for handling notifications
        const delegate: SubscriptionDelegate = {
            onNotify: (notification) => {
                const body = notification.request.body;
                let jsonNotification = xmlParser.parse(body, {
                    attributeNamePrefix: '',
                    ignoreAttributes: false,
                    parseAttributeValue: true,
                });
                this.setState({
                    subscribe: {
                        status: this.subscription?.state,
                        blf: {
                            sensorId: jsonNotification.presence.tuple.id,
                            status: jsonNotification.presence.note,
                        },
                    },
                });
                // Send reply
                notification.accept();
            },
        };
        const uri = UserAgent.makeURI(
            `sip:${String(this.props.sensorId).padStart(5, '0')}@${
                this.state.settings?.voipServerHost
            }`
        );
        if (!uri) {
            throw new Error('Failed to create target URI.');
        }

        if (this.ua) {
            // Create presence subscription
            this.subscription = new Subscriber(this.ua, uri, 'presence', {
                delegate,
            });

            // Send initial SUBSCRIBE
            this.subscription
                .subscribe(subscribeOptions)
                .then(() => {
                    console.log('Successfully sent SUBSCRIBE');
                })
                .catch((error: Error) => {
                    console.error('Failed to send SUBSCRIBE');
                });
        }
    };

    unsubscribe = () => {
        this.subscription?.unsubscribe();
        this.subscription = undefined;
    };

    requestCall = (audioProperty: AudioProperty | undefined) => {
        if (audioProperty === this.audio_property) {
            this.requestHangup();
            return;
        }
        this.audio_property = audioProperty;

        //not InviterInviteOptions
        let inviteOptions: InviterOptions = {
            extraHeaders: [
                `Security-Id: ${this.state.settings?.voipSecurityIdHash}`,
                `Original-Signaling-IP: ${this.state.settings?.sipClientIp}`,
                //audio property is converted to values which can be recognized by pjsua sip client
                `Audio-Property: ${
                    audioProperty === 'twoWay'
                        ? '2-way'
                        : audioProperty === 'oneWay'
                        ? '1-way-normal'
                        : '1-way-anonymous'
                }`,
            ],
            sessionDescriptionHandlerModifiers:
                audioProperty === 'twoWay'
                    ? []
                    : //to force one way audio sent from sensors
                      [
                          (description: RTCSessionDescriptionInit) => {
                              description.sdp = description.sdp?.replace(
                                  /a=sendrecv/g,
                                  'a=recvonly'
                              );
                              return Promise.resolve(description);
                          },
                      ],
            //[Web.holdModifier],//does not work, a=sendrecv is replaced with a=sendonly, to hold the session by playing audio continuously
            //inviteWithoutSdp: true, //asterisk is triggered with starting music on hold
            //With the release of 0.16 or later verison, early media could be played on an invite with SDP and 100 rel enabled. This requires that InviterOptions.earlyMedia is set to true.
            //https://sipjs.com/faq/
            earlyMedia: true,
        };

        const uri = UserAgent.makeURI(
            `sip:${String(this.props.sensorId).padStart(5, '0')}@${
                this.state.settings?.voipServerHost
            }`
        );
        if (!uri) {
            throw new Error('Failed to create target URI.');
        }
        if (this.ua) {
            if (this.sipSession === undefined) {
                // Send initial INVITE
                this.sipSession = new Inviter(this.ua, uri, inviteOptions);
                this.sipSession
                    ?.invite()
                    .then((request: Core.OutgoingInviteRequest) => {
                        console.log('Successfully sent INVITE');
                    })
                    .catch((error: Error) => {
                        console.error('Failed to send INVITE');
                    });
            } else {
                //reInvite
                //sensor side does NOT support reInivte, 200 OK response is NOT received from sensor side after voip server relays the reInvite request to sensor side
                let inviteOptions: InviterInviteOptions = {
                    requestOptions: {
                        extraHeaders: [
                            `Security-Id: ${this.state.settings?.voipSecurityIdHash}`,
                            `Original-Signaling-IP: ${this.state.settings?.sipClientIp}`,
                            //audio property is converted to values which can be recognized by pjsua sip client
                            `Audio-Property: ${
                                audioProperty === 'twoWay'
                                    ? '2-way'
                                    : audioProperty === 'oneWay'
                                    ? '1-way-normal'
                                    : '1-way-anonymous'
                            }`,
                        ],
                    },
                    sessionDescriptionHandlerModifiers:
                        audioProperty === 'twoWay'
                            ? //back to bi-directional call
                              [
                                  (description: RTCSessionDescriptionInit) => {
                                      description.sdp = description.sdp?.replace(
                                          /a=recvonly/g,
                                          'a=sendrecv'
                                      );
                                      return Promise.resolve(description);
                                  },
                              ]
                            : //to force one way audio sent from sensors
                              [
                                  (description: RTCSessionDescriptionInit) => {
                                      description.sdp = description.sdp?.replace(
                                          /a=sendrecv/g,
                                          'a=recvonly'
                                      );
                                      return Promise.resolve(description);
                                  },
                              ],
                };
                //initiate a reInvite request with InviterInviteOptions based inviteOptions
                //extra headers and session description handler modifiers are injected correctly in reInvite requests
                this.sipSession.invite(inviteOptions);
            }
            // Setup session state change handler
            this.sipSession?.stateChange.addListener(
                (newState: SessionState) => {
                    switch (newState) {
                        case SessionState.Establishing:
                            //bridge remote media path even for early media.
                            this.bridgeRemoteMedia();
                            this.setActiveCallStatus();
                            break;
                        case SessionState.Established:
                            this.setActiveCallStatus();
                            break;
                        case SessionState.Terminating:
                        // fall through
                        case SessionState.Terminated:
                            //how to retrieve terminated cause???
                            this.audio_property = undefined;
                            this.setActiveCallStatus();
                            this.cleanupMedia();
                            this.sipSession = undefined;
                            break;
                    }
                }
            );
        }
    };

    requestHangup = () => {
        this.sipSession?.dispose();
    };

    render() {
        const contextValue: WebRtcContext = {
            status: this.state.status,
            requestCall: this.requestCall,
            requestHangup: this.requestHangup,
            busy:
                this.state.subscribe === undefined ||
                (this.state.subscribe.blf !== undefined &&
                    this.state.subscribe.blf?.status !== 'Ready' &&
                    this.state.subscribe.blf.sensorId === this.props.sensorId),

            call: this.state.active_call
                ? {
                      sensorId: this.state.active_call.sensorId,
                      status: this.state.active_call.status,
                      cause: this.state.active_call.cause,
                      rejected_reason: this.state.active_call.rejected_reason,
                      audioProperty: this.state.active_call
                          .audio_property as AudioProperty,
                  }
                : undefined,
        };
        return (
            <WebRtcContextProvider value={contextValue}>
                {this.props.children}
                {this.state.status === 'enabled' ? (
                    <audio
                        ref={this.audioRef}
                        controls
                        autoPlay={true}
                        hidden={true}
                    >
                        Your browser does not support the audio element.
                    </audio>
                ) : null}
            </WebRtcContextProvider>
        );
    }
}
