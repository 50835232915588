import React, { useContext } from 'react';
import * as api from '../../../../serverApi';

import { EventTypeDefinition } from '../../../../eventTypes';

export interface SensorEnabledEvents extends EventTypeDefinition {
  key: string;
  sensor: api.EventSettings;
}

export interface SensorEnabledEventsContextType {
  events: SensorEnabledEvents[];
}

const defaultContext: SensorEnabledEventsContextType = {
  events: [] as SensorEnabledEvents[],
};

export const SensorEnabledEventsContext = React.createContext<SensorEnabledEventsContextType>(
  defaultContext
);

export const useSensorEnabledEvents = () => {
  return useContext(SensorEnabledEventsContext);
};
