import React from 'react';
import {
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  TextField,
} from '@material-ui/core';
import { IpWhitelistRecord } from '../../../../../serverApi';
import { TableWrapper } from '../../../../../components/TableWrapper';
import { Button } from '../../../../../components/Button';
import { useDataChangeDetect } from '../../../../../lib/useDataChangeDetect';
import { RouteLeavingGuard } from '../../../../../components/RouteLeavingGuard';
import {
  ErrorHandlerType,
  errorIsObjectError,
} from '../../../../../lib/useErrorHandler';
import { TableRowPlaceholder } from '../../../common/TableRowPlaceholder';
import { ButtonGroup } from '../../../../../components/ButtonGroup';
import { t } from '../../../../../lib/i18n';
import { InlineSpinner } from '../../../../../components/InlineSpinner';
import { generateAutoCompleteValue } from '../../../../../lib/utils/generateAutoCompleteValue';

interface Props {
  ipWhitelistRules: Array<IpWhitelistRecord>;
  error?: ErrorHandlerType;
  activeRule?: IpWhitelistRecord;
  loading?: boolean;
  isDeleting?: number;
  isUpdating?: number;
  reload?: () => void;
  onDelete?: (id: number) => void;
  onUpdate?: (data: any) => void;
  setActiveRule?: (activeRule?: IpWhitelistRecord) => void;
}

export default function IpWhitelistTable(props: Props) {
  const activeRule = props.activeRule;

  const dataChangeDetect = useDataChangeDetect<IpWhitelistRecord | undefined>(
    activeRule
  );

  const handleChange = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    if (activeRule) {
      handleSetActiveRule({
        ...activeRule,
        [event.target.name]: event.target.value,
      });
    }
  };

  const handleSetActiveRule = (activeRule?: IpWhitelistRecord) => {
    if (props.setActiveRule) {
      props.setActiveRule(activeRule);
    }
  };

  const onDelete = (id: number) => () => {
    if (props.onDelete) {
      props.onDelete(id);
    }
  };

  const update = () => async () => {
    if (props.onUpdate && activeRule) {
      await props.onUpdate(activeRule);
      if (props.reload) {
        props.reload();
      }
    }
  };

  return (
    <div className="IpWhitelistTable">
      <RouteLeavingGuard when={dataChangeDetect.hasChanged(activeRule)} />
      <TableWrapper>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>
                {t('manage.zone.settings.advanced.IpWhitelistTable.asOf')}
              </TableCell>
              <TableCell>
                {t('manage.zone.settings.advanced.IpWhitelistTable.even')}
              </TableCell>
              <TableCell>{t('common.commonInputs.comment')}</TableCell>
              <TableCell>&nbsp;</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {props.loading ? (
              <TableRowPlaceholder colSpan={4}>
                <InlineSpinner
                  size="sm"
                  text={t('common.commonTexts.loading')}
                />
              </TableRowPlaceholder>
            ) : (
              <>
                {props.ipWhitelistRules.length === 0 ? (
                  <TableRowPlaceholder colSpan={4}>
                    {t(
                      'manage.zone.settings.advanced.IpWhitelistTable.thereAreNoDefinedIpRanges'
                    )}
                    .
                  </TableRowPlaceholder>
                ) : (
                  props.ipWhitelistRules.map((rule) => {
                    const isBeingEdited =
                      activeRule &&
                      activeRule.zone_ip_whitelist_id ===
                        rule.zone_ip_whitelist_id;
                    return (
                      <TableRow key={rule.zone_ip_whitelist_id}>
                        <TableCell>
                          {isBeingEdited ? (
                            <TextField
                              type="text"
                              name="ip_range_from"
                              label={t(
                                'manage.zone.settings.advanced.IpWhitelistTable.ipFromAndIncluding'
                              )}
                              value={activeRule && activeRule.ip_range_from}
                              onChange={handleChange}
                              error={
                                errorIsObjectError(props.error?.error) &&
                                !!props?.error?.error?.['ip_range_from']
                              }
                              helperText={
                                errorIsObjectError(props.error?.error) &&
                                props.error?.error?.['ip_range_from']
                              }
                            />
                          ) : (
                            <span>{rule.ip_range_from}</span>
                          )}
                        </TableCell>
                        <TableCell>
                          {isBeingEdited ? (
                            <TextField
                              type="text"
                              name="ip_range_to"
                              label={t(
                                'manage.zone.settings.advanced.IpWhitelistTable.ipEven'
                              )}
                              value={activeRule && activeRule.ip_range_to}
                              onChange={handleChange}
                              error={
                                errorIsObjectError(props.error?.error) &&
                                !!props?.error?.error?.['ip_range_to']
                              }
                              helperText={
                                errorIsObjectError(props.error?.error) &&
                                props.error?.error?.['ip_range_to']
                              }
                            />
                          ) : (
                            <span>{rule.ip_range_to}</span>
                          )}
                        </TableCell>
                        <TableCell>
                          {isBeingEdited ? (
                            <TextField
                              type="text"
                              name="comment"
                              label={t('common.commonInputs.comment')}
                              value={activeRule && activeRule.comment}
                              onChange={handleChange}
                              error={
                                errorIsObjectError(props.error?.error) &&
                                !!props?.error?.error?.['comment']
                              }
                              helperText={
                                errorIsObjectError(props.error?.error) &&
                                props.error?.error?.['comment']
                              }
                              autoComplete={generateAutoCompleteValue()}
                            />
                          ) : (
                            <span>{rule.comment}</span>
                          )}
                        </TableCell>
                        <TableCell>
                          <ButtonGroup>
                            <Button
                              color="secondary"
                              disabled={
                                props.isDeleting === rule.zone_ip_whitelist_id
                              }
                              onClick={onDelete(rule.zone_ip_whitelist_id)}
                            >
                              {props.isDeleting === rule.zone_ip_whitelist_id
                                ? t('common.commonButtons.deleting')
                                : t('common.commonButtons.delete')}
                            </Button>
                            {isBeingEdited ? (
                              <Button color="default" onClick={update()}>
                                {props.isUpdating === rule.zone_ip_whitelist_id
                                  ? t('common.commonButtons.saving')
                                  : t('common.commonButtons.save')}
                              </Button>
                            ) : (
                              <Button
                                color="primary"
                                onClick={() => handleSetActiveRule(rule)}
                              >
                                {t('common.commonButtons.edit')}
                              </Button>
                            )}
                          </ButtonGroup>
                        </TableCell>
                      </TableRow>
                    );
                  })
                )}
              </>
            )}
            {/*{props.loading === false && props.ipWhitelistRules.length === 0 ? (*/}
            {/*  <TableRow>*/}
            {/*    <TableCell colSpan={4} className="text-center text-warning">*/}
            {/*      Det er ingen IP-restriksjoner på denne sonen.*/}
            {/*    </TableCell>*/}
            {/*  </TableRow>*/}
            {/*) : (*/}
            {/*  props.ipWhitelistRules.map(rule => {*/}
            {/*    const isBeingEdited =*/}
            {/*      activeRule &&*/}
            {/*      activeRule.zone_ip_whitelist_id === rule.zone_ip_whitelist_id;*/}
            {/*    return (*/}
            {/*      <TableRow key={rule.zone_ip_whitelist_id}>*/}
            {/*        <TableCell>*/}
            {/*          {isBeingEdited ? (*/}
            {/*            <TextField*/}
            {/*              type="text"*/}
            {/*              name="ip_range_from"*/}
            {/*              label="IP fra og med"*/}
            {/*              value={activeRule && activeRule.ip_range_from}*/}
            {/*              onChange={handleChange('ip_range_from')}*/}
            {/*            />*/}
            {/*          ) : (*/}
            {/*            <span>{rule.ip_range_from}</span>*/}
            {/*          )}*/}
            {/*        </TableCell>*/}
            {/*        <TableCell>*/}
            {/*          {isBeingEdited ? (*/}
            {/*            <TextField*/}
            {/*              type="text"*/}
            {/*              name="ip_range_to"*/}
            {/*              label="IP til og med"*/}
            {/*              value={activeRule && activeRule.ip_range_to}*/}
            {/*              onChange={handleChange('ip_range_to')}*/}
            {/*            />*/}
            {/*          ) : (*/}
            {/*            <span>{rule.ip_range_to}</span>*/}
            {/*          )}*/}
            {/*        </TableCell>*/}
            {/*        <TableCell>*/}
            {/*          {isBeingEdited ? (*/}
            {/*            <TextField*/}
            {/*              type="text"*/}
            {/*              name="comment"*/}
            {/*              label="Kommentar"*/}
            {/*              value={activeRule && activeRule.comment}*/}
            {/*              onChange={handleChange('comment')}*/}
            {/*            />*/}
            {/*          ) : (*/}
            {/*            <span>{rule.comment}</span>*/}
            {/*          )}*/}
            {/*        </TableCell>*/}
            {/*        <TableCell>*/}
            {/*          <Button*/}
            {/*            color="secondary"*/}
            {/*            disabled={*/}
            {/*              props.isDeleting === rule.zone_ip_whitelist_id*/}
            {/*            }*/}
            {/*            onClick={onDelete(rule.zone_ip_whitelist_id)}*/}
            {/*          >*/}
            {/*            {props.isDeleting === rule.zone_ip_whitelist_id*/}
            {/*              ? 'Sletter ...'*/}
            {/*              : 'Slett'}*/}
            {/*          </Button>*/}
            {/*          {isBeingEdited ? (*/}
            {/*            <Button*/}
            {/*              color="default"*/}
            {/*              className="ml-2"*/}
            {/*              onClick={update()}*/}
            {/*            >*/}
            {/*              {props.isUpdating === rule.zone_ip_whitelist_id*/}
            {/*                ? 'Lagrer ...'*/}
            {/*                : 'Lagre'}*/}
            {/*            </Button>*/}
            {/*          ) : (*/}
            {/*            <Button*/}
            {/*              color="primary"*/}
            {/*              className="ml-2"*/}
            {/*              onClick={() => setActiveRule(rule)}*/}
            {/*            >*/}
            {/*              Edit*/}
            {/*            </Button>*/}
            {/*          )}*/}
            {/*        </TableCell>*/}
            {/*      </TableRow>*/}
            {/*    );*/}
            {/*  })*/}
            {/*)}*/}
          </TableBody>
        </Table>
      </TableWrapper>
    </div>
  );
}
