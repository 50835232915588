import { useState, useCallback, useEffect, useRef, useMemo } from 'react';
import * as Sentry from '@sentry/browser';

import * as api from '../../serverApi';
import { AudioProperty } from './lib/WebRtcContext';
import { SessionState } from 'sip.js';
import { useSendStreamStopSignalOnBrowserClose } from './lib/useSendStreamStopSignalOnBrowserClose';
import { isUnauthorizedError } from '../../lib/utils/errorUtils';

// Create a unique cookie key reference so we can have multiple
// streams, each with its own token. The token itself is sent is
// via cookie to keep it out of the URL.
// This can be unique per browser window, so we just generate it once.
const cookieKeyId = Math.random().toString(36).substr(2, 9);

interface Options {
  /**
   * The ID of the sensor from which to fetch images.
   */
  sensorId: string;

  /**
   * The type of stream images to retrieve.
   */
  streamType: api.StreamType;

  /**
   * Status of audio call
   */
  audioCallStatus?: SessionState;

  /**
   * Stop the image streaming automatically after this many seconds. Use 0 for no limit. Default is 30 seconds.
   */
  timeout?: number | null;

  /**
   * The active audio type
   */
  audioProperty?: null | AudioProperty;

  /**
   * The minimum number of ms between requests.
   */
  throttleInterval: number;

  /**
   * Stream images using the MJPEG video compression format (this is prioritized over the SSE streaming)
   */
  useMjpeg?: boolean;

  /**
   * Use SSE (server-sent events) streaming (if available)
   */
  useSse?: boolean;

  /**
   * Whether to fetch a single marking image instead of streaming images.
   * When this is true, the `useSse` and `useMjpeg` parameters will be ignored.
   */
  useSingleMarkingImage?: boolean;

  /**
   * Triggered when the image streaming times out.
   */
  onTimeout?: () => void;
}

/**
 * This function will return a new URL whenever the previous was loaded or when the interval has passed, whichever comes last.
 * @param opts
 */
export function useSensorImageStreaming(opts: Options) {
  const token = useRef<undefined | string>(undefined);
  const [hasToken, setHasToken] = useState(false);
  // const [previousImageFailed, setPreviousImageFailed] = useState(false);
  const imageTimestamp = useRef(0);
  const [imageSrc, setImageSrc] = useState<string | undefined>(undefined);
  const [loaded, setLoaded] = useState(true);
  const [hasTimedOut, setHasTimedOut] = useState(false);
  const [imageAvailable, setImageAvailable] = useState(false);
  const [error, setError] = useState(false);

  const timeout = opts.timeout ?? 30;

  const audioSuffix =
    opts.audioProperty && opts.audioCallStatus === 'Established'
      ? `/${opts.audioProperty}`
      : '';

  /**
   * Generate a 5 digit ID and use this unique identifier to start the stream and send the stopped signal
   * This is re-generated every time we switch the sensor, stream type or audio property
   * (basically, in every new initiated stream)
   */
  const streamId = useMemo(
    () => Math.floor(Math.random() * (999999 - 100000) + 100000),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [opts.sensorId, opts.streamType, audioSuffix]
  );

  //Send a stream stopped signal in case browser/tab is closed
  useSendStreamStopSignalOnBrowserClose({ streamId });

  /**
   * Whenever we change a fundamental parameter (sensor, stream type), we restart.
   */
  useEffect(() => {
    setHasTimedOut(false);
    setImageAvailable(false);
    token.current = undefined;
    setHasToken(false);
    setLoaded(true);
    setImageSrc(undefined);

    /**
     * Whenever a stream is stopped or restarted, we inform the server for it
     */
    return () => {
      api.sendStoppedStreamSignal({ streamId }).catch((e) => {
        /**
         * Avoid logging it to sentry if it's a 401 Unauthorized error
         */
        if (!isUnauthorizedError(e)) {
          Sentry.captureException(e, {
            extra: {
              reason: 'Failed to send the stream stop signal',
            },
          });
        }
      });
    };
  }, [
    opts.sensorId,
    opts.streamType,
    opts.useSse,
    opts.useSingleMarkingImage,
    audioSuffix,
    streamId,
  ]);

  /**
   * If useMjpeg option if provided, we use the new video compression feature for streaming
   */
  useEffect(() => {
    if (opts.useSingleMarkingImage) {
      return;
    }

    if (opts.useMjpeg) {
      setImageSrc(
        `/api/stream/mjpeg/${opts.sensorId}/${
          opts.streamType
        }${audioSuffix}?ts=${Date.now().toString()}&streamId=${streamId}`
      );
    }
  }, [
    opts.useMjpeg,
    opts.sensorId,
    opts.streamType,
    opts.useSingleMarkingImage,
    opts.useSse,
    audioSuffix,
    streamId,
  ]);

  /**
   *
   *
   *
   * Callback to fetch a new getter token. Note that this is only used for fetching
   * short-lived tokens when polling for individual images, not when using SSE.
   *
   */
  const fetchToken = useCallback(() => {
    api
      .getStreamToken({
        sensorId: opts.sensorId,
        type: opts.streamType,
        audioProperty: opts.audioProperty,
        streamId,
      })
      .then((res) => {
        setHasToken(true);
        token.current = res.token;
        // Store cookie
        document.cookie = `image-token-${cookieKeyId}=${encodeURIComponent(
          res.token
        )}; path=/`;
      })
      .catch((error) => {
        console.log('Error while getting stream token:', error);
        Sentry.captureException(error);
        if (error.status) {
          setError(true);
        }
      });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [opts.sensorId, opts.streamType, opts.audioProperty, token]);

  /**
   *
   *
   *
   * Set up the single marking image
   * (only if useSingleMarkingImage is true)
   *
   */
  useEffect(() => {
    if (!opts.useSingleMarkingImage) {
      return;
    }
    const url = [
      api.getApiRoot(),
      '/api/sensors/',
      encodeURIComponent(opts.sensorId ?? ''),
      '/marking/image?ts=',
      Date.now().toString(),
    ].join('');

    setImageSrc(url);
  }, [
    opts.sensorId,
    opts.streamType,
    opts.audioProperty,
    opts.useSse,
    opts.useSingleMarkingImage,
    opts.throttleInterval,
  ]);

  /**
   *
   *
   *
   * Set up the SSE event source
   * (only if useSse is true)
   *
   */
  useEffect(() => {
    if (opts.useSingleMarkingImage || opts.useMjpeg || !opts.useSse) {
      return;
    }
    console.log('Setting up SSE');
    const baseUrl = `/api/stream/images/${opts.sensorId}/${opts.streamType}?streamId=${streamId}`;
    const throttle = opts.throttleInterval
      ? `&throttle=${opts.throttleInterval}`
      : '';
    const url = baseUrl + audioSuffix + throttle;
    const sse = new EventSource(url, { withCredentials: true });

    sse.addEventListener('message', (e) => {
      //const msg = JSON.parse(e.data);
      console.log('Message: ', e.data);
    });

    sse.addEventListener('image', (e: unknown) => {
      const msg = e as MessageEvent<string>;
      setImageSrc(`data:image/jpeg;base64,${msg.data}`);
    });

    sse.addEventListener('error', (e) => {
      if (sse.readyState === EventSource.CLOSED) {
        console.log('SSE Connection Closed.');
      }
      console.error('SSE error:', e);
      setImageSrc(undefined);
      setImageAvailable(false);
      sse.close();
    });

    return () => {
      setImageSrc(undefined);
      sse.close();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    opts.sensorId,
    opts.streamType,
    opts.useSse,
    opts.useSingleMarkingImage,
    opts.throttleInterval,
    opts.useMjpeg,
    audioSuffix,
  ]);

  /**
   *
   *
   *
   * Here is how we'll keep track of the hooks mounted state.
   * Whenever this is false, we must disregard the result from any async functions.
   *
   */
  const componentIsMounted = useRef(true);
  useEffect(() => {
    return () => {
      componentIsMounted.current = false;
    };
  }, []);

  /**
   * And whenever we change a fundamental parameter and/or the ready state,
   * we reinitialize and restart the ticker/stream.
   */
  useEffect(() => {
    // This is only for non-SSE or non-MJPEG streams:
    if (opts.useSingleMarkingImage || opts.useSse || opts.useMjpeg) {
      return;
    }

    if (!loaded || !token.current) {
      return;
    }
    // Okay, it's loaded! But maybe we haven't waited enough ...
    const timePassed = Date.now() - imageTimestamp.current;

    const delayFor = opts.throttleInterval - timePassed;

    const int = setTimeout(async () => {
      const url = token.current
        ? [
            api.getApiRoot(),
            '/api/stream?ck=',
            encodeURIComponent(cookieKeyId),
            '&ts=',
            Date.now().toString(),
          ].join('')
        : undefined;
      setImageSrc(url);
      setLoaded(false);
      imageTimestamp.current = Date.now();
    }, Math.max(delayFor, 0));

    return () => {
      clearTimeout(int);
    };
  }, [
    opts.sensorId,
    opts.streamType,
    opts.throttleInterval,
    loaded,
    hasToken,
    opts.useSse,
    opts.useSingleMarkingImage,
    opts.useMjpeg,
  ]);

  /**
   * The timeout function for observation timeouts.
   */
  useEffect(() => {
    if (timeout === 0) {
      return;
    }
    const int = setTimeout(() => {
      setHasTimedOut(true);
      if (opts.onTimeout) {
        return opts.onTimeout();
      }
      setHasTimedOut(false);
    }, timeout * 1000);
    return () => {
      clearTimeout(int);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [opts.sensorId, opts.streamType, timeout, audioSuffix]);

  /**
   *
   *
   *
   * Fetch new tokens at regular intervals (if we are not using SSE)
   *
   */
  useEffect(() => {
    if (opts.useSingleMarkingImage || opts.useSse || opts.useMjpeg) {
      return;
    }
    fetchToken();
    const interval = setInterval(() => fetchToken(), 8000); // Increased from 5000ms to 9000ms to slightly improve latency/load issues

    return () => {
      clearInterval(interval);
    };
  }, [fetchToken, opts.useSse, opts.useSingleMarkingImage, opts.useMjpeg]);

  /**
   *
   *
   *
   * This event is run when the <img> element successfully loads an image.
   *
   */
  const imageOnLoad = useCallback(() => {
    if (!componentIsMounted.current) {
      return false;
    }
    // if (previousImageFailed) {
    //   setPreviousImageFailed(false);
    // }
    setLoaded(true);
    setImageAvailable(true);

    // console.log('onLoad');
  }, []);

  /**
   * This event is run when the <img> element tries to load an image but fails.
   */
  const imageOnError = useCallback(() => {
    if (!componentIsMounted.current) {
      return false;
    }
    // if (!previousImageFailed) {
    //   setPreviousImageFailed(true);
    // }
    setLoaded(true);
    setImageAvailable(false);
    // Check what type of error it is? If it's a 404, we can just retry silently ...
    // console.log('onError');
  }, []);

  return {
    // ready,
    error,
    imageOnLoad,
    imageOnError,
    imageSrc,
    hasTimedOut,
    imageAvailable,
  };
}
