import React, { useState, useEffect } from 'react';
import { QueryClient, useQueryClient } from 'react-query';

import IpWhitelistTable from './IpWhitelistTable';
import { useApiCall } from '../../../../../lib/useApiCall';
import { useZoneContext } from '../../../../../lib/ZoneContext';
import { useConfirmation } from '../../../../../lib/confirmation/ConfirmationContext';
import { useDataChangeDetect } from '../../../../../lib/useDataChangeDetect';
import { RouteLeavingGuard } from '../../../../../components/RouteLeavingGuard';
import { useErrorHandler } from '../../../../../lib/useErrorHandler';
import { IpWhiteListForm } from './IpWhiteListForm';
import { ErrorMessages } from '../../../../../components/ErrorMessages';
import * as api from '../../../../../serverApi';
import { t } from '../../../../../lib/i18n';

import './IpWhitelistEditor.scss';

const defaultValues = {
  ip_range_from: '',
  ip_range_to: '',
  comment: '',
};

export function IpWhitelistEditor() {
  const zoneContext = useZoneContext();
  const confirmation = useConfirmation();
  const errorHandler = useErrorHandler();
  const queryClient = useQueryClient();

  const [creating, setCreating] = useState<boolean>(false);
  const [isUpdating, setIsUpdating] = useState<number | undefined>(undefined);
  const [isDeleting, setIsDeleting] = useState<number | undefined>(undefined);
  const [errorType, setErrorType] = useState<'create' | 'update' | undefined>(
    undefined
  );

  const [activeRule, setActiveRule] = useState<
    api.IpWhitelistRecord | undefined
  >(undefined);

  const [values, setValues] = useState<api.IpWhitelistInput>(defaultValues);

  const ipWhitelistRes = useApiCall(api.manageGetIpWhitelistForZone, [
    zoneContext.activeZone.id,
  ]);

  const [ipWhitelistRules, setIpWhitelistRules] = useState<
    Array<api.IpWhitelistRecord>
  >([]);

  const dataChangeDetect = useDataChangeDetect<api.IpWhitelistInput>(values);

  useEffect(() => {
    const structureKey = api.QueryKeys.getStructure();
    const hasIpWhitelist = zoneContext.activeZone.has_defined_ip_whitelists;

    // These conditions below avoid updating cache everytime we add or remove an ip from the state if it doesn't affect the 'hasIpWhitelist' variable.

    // Update structure cache if we have ip whitelist rules and the zone 'hasIpWhitelist' variable is false
    if (ipWhitelistRules.length > 0 && !hasIpWhitelist) {
      updateIPWhitelistStructureCache(
        queryClient,
        structureKey,
        zoneContext.activeZone,
        true
      );
      return;
    }

    //Update structure cache if ipWhitelistRules state is empty and 'hasIpWhitelist' variable is true
    if (ipWhitelistRules.length === 0 && hasIpWhitelist) {
      updateIPWhitelistStructureCache(
        queryClient,
        structureKey,
        zoneContext.activeZone,
        false
      );
      return;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ipWhitelistRules]);

  useEffect(() => {
    if (!ipWhitelistRes.data) {
      return;
    }

    setIpWhitelistRules(ipWhitelistRes.data.data);
  }, [ipWhitelistRes.data]);

  const handleChange = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => setValues({ ...values, [event.target.name]: event.target.value });

  const handleDelete = async (id: number) => {
    confirmation
      .confirm(t('common.commonTexts.toConfirmDeletionPressOk'))
      .then(async () => {
        try {
          setIsDeleting(id);
          await api.manageDeleteIpWhitelistFromZone(
            id,
            zoneContext.activeZone.id
          );
          setIpWhitelistRules(
            ipWhitelistRules.filter(
              (item: api.IpWhitelistRecord) => item.zone_ip_whitelist_id !== id
            )
          );
          setIsDeleting(undefined);
        } catch (error) {
          setIsDeleting(undefined);
          errorHandler.handleError(error);
        }
      })
      .catch(() => {});
  };

  const handleUpdate = async (rule: api.IpWhitelistRecord) => {
    try {
      setErrorType(undefined);
      setIsUpdating(rule.zone_ip_whitelist_id);
      const { data } = await api.manageUpdateIpWhitelist(
        rule.zone_ip_whitelist_id,
        zoneContext.activeZone.id,
        {
          ip_range_from: rule.ip_range_from,
          ip_range_to: rule.ip_range_to,
          comment: rule.comment,
        }
      );
      setIpWhitelistRules(data);
      setActiveRule(undefined);
      setIsUpdating(undefined);
    } catch (error) {
      errorHandler.handleError(error);
      setIsUpdating(undefined);
      setErrorType('update');
    }
  };

  const handleCreate = async () => {
    errorHandler.reset();

    try {
      setCreating(true);
      setErrorType(undefined);
      await api.manageAddIpWhitelist(zoneContext.activeZone.id, values);
      setCreating(false);
      setValues(defaultValues);
      ipWhitelistRes.reload();
    } catch (error) {
      errorHandler.handleError(error);
      setCreating(false);
      setErrorType('create');
    }
  };

  return (
    <div className="IpWhitelistEditor">
      <RouteLeavingGuard when={dataChangeDetect.hasChanged(values)} />

      <IpWhitelistTable
        ipWhitelistRules={ipWhitelistRules}
        loading={ipWhitelistRes.loading}
        isDeleting={isDeleting}
        isUpdating={isUpdating}
        onDelete={(id) => handleDelete(id)}
        onUpdate={(data) => handleUpdate(data)}
        error={errorType === 'update' ? errorHandler : undefined}
        activeRule={activeRule}
        setActiveRule={(activeRule) => setActiveRule(activeRule)}
      />

      <IpWhiteListForm
        loading={creating}
        error={errorType === 'create' ? errorHandler : undefined}
        values={values}
        setValues={(e) => handleChange(e)}
        onSubmit={handleCreate}
      />

      <ErrorMessages errorData={errorHandler} />
    </div>
  );
}

function updateIPWhitelistStructureCache(
  queryClient: QueryClient,
  queryKey: string[],
  activeZone: api.StructureZone,
  fieldValue: boolean
) {
  const structureData = queryClient.getQueryData<api.GetStructureResult>(
    queryKey
  );
  if (!structureData) {
    return;
  }

  const isTopZone = activeZone.parent === null;

  queryClient.setQueryData<api.GetStructureResult>(queryKey, {
    zone: isTopZone
      ? { ...structureData.zone, has_defined_ip_whitelists: fieldValue }
      : {
          ...structureData.zone,
          zones:
            !structureData.zone.zones || structureData.zone.zones.length === 0
              ? null
              : structureData.zone.zones.map((zone) =>
                  zone.id === activeZone.id
                    ? { ...zone, has_defined_ip_whitelists: fieldValue }
                    : zone.zones
                    ? {
                        ...zone,
                        zones: zone.zones.map((nestedZone) =>
                          nestedZone.id === activeZone.id
                            ? {
                                ...nestedZone,
                                has_defined_ip_whitelists: fieldValue,
                              }
                            : nestedZone
                        ),
                      }
                    : zone
                ),
        },
  });
}
