import { useState } from 'react';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
} from '@material-ui/core';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';

import { useApiCall } from '../../../lib/useApiCall';
import * as api from '../../../serverApi';
import { Sensor, useCareContext } from '../lib/CareContext';
import { Icon } from '../../../components/Icon';
import { EventTypeIcon } from '../../../lib/utils/EventTypeIcon';
import { InlineSpinner } from '../../../components/InlineSpinner';
import { getLabelForEventType } from '../../../lib/getLabelForEventType';
import { getMinutesAndSecondsFromTime } from '../../../lib/utils/getMinutesAndSecondsFromTime';
import { useWindowSize } from '../../../lib/useWindowSize';
import { CenteredLogo } from '../CenteredLogo';
import { t } from '../../../lib/i18n';
import { useAppContext } from '../../../lib/global';
import { MarkingOverview } from './MarkingOverview';
import { BehaviorPanel } from './BehaviorPanel';
import { Tooltip } from '../../../components/Tooltip/Tooltip';
import { EventSettingsInfoTable } from './EventSettingsInfoTable';
import { Feature } from '../../../components/Feature';
import { WhenTimezoneDiffers } from '../../../components/WhenTimezoneDiffers';

import './InfoPage.scss';

interface Props {
  data?: {
    last_movement_in_bed_utc?: null | string;
    last_movement_in_bed_sensor_local?: null | string;
    timezone?: null | string;
    events: Array<{
      id: number;
      sensor_type: api.SensorType;
      event_type: string;
      received_at: string;
      received_at_utc: string;
      received_at_sensor_local: string;
      timezone: string;
    }>;
    event_settings: Array<{
      sensor_type: api.SensorType;
      event_type: api.EventType;
      level: 'alert' | 'alarm';
      enabled: boolean;
      enabled_from: string | null; // 'HH:mm'
      enabled_to: string | null; // 'HH:mm'
    }>;
  };
  loading?: boolean;
  sensor?: Sensor;
}

const initialAccordions = {
  activityOverview: false,
  markings: false,
  activeEventSettings: true,
  last24h: true,
};

export function InfoPageMarkup(props: Props) {
  const app = useAppContext();
  const { isMobile } = useWindowSize();

  const [accordions, setAccordions] = useState(initialAccordions);

  const handlePanelChange = (panel: keyof typeof initialAccordions) => (
    event: React.ChangeEvent<{}>,
    isExpanded: boolean
  ) => {
    setAccordions({
      ...accordions,
      [panel]: !accordions[panel],
    });
  };

  const hasAccessToMarkings =
    props.sensor?.media.observation_image_detailed &&
    !app.user?.account?.disable_all_detailed_images &&
    app.hasCapability('observationDetailed');

  /**
   * Load marking objects, used by both marking overview and
   */
  const markingObjectResult = useApiCall(
    api.getMarkingObjects,
    [props.sensor?.sensor_composite_id ?? ''],
    {
      skip: !props.sensor?.sensor_composite_id,
    }
  );

  const activityOverviewEnabled =
    !!props.sensor?.is_online && !!props.sensor.media.enable_behavioral_data;

  return (
    <div className="InfoPage" id="careInfoPage">
      {props.sensor && !isMobile && (
        <div className="InfoPage__heading">
          <Icon className="InfoPage__heading-icon" icon="info" />
          <span>
            <h1>
              {props.sensor.display_area}
              <br />
              {props.sensor.display_name}
            </h1>
          </span>
        </div>
      )}
      {props.data && (
        <div className="InfoPage__accordions">
          <Feature feature="show-activity-overview">
            <Tooltip
              title={
                activityOverviewEnabled
                  ? ''
                  : t(
                      'care.components.InfoPage.featureMustEnableInZoneSettings'
                    )
              }
              placement={isMobile ? 'bottom' : 'bottom-start'}
              arrow={isMobile}
              enterTouchDelay={0}
              leaveTouchDelay={3000}
            >
              <Accordion
                expanded={
                  accordions.activityOverview && activityOverviewEnabled
                }
                onChange={handlePanelChange('activityOverview')}
                TransitionProps={{
                  unmountOnExit: true,
                }}
                disabled={!activityOverviewEnabled}
              >
                <AccordionSummary
                  expandIcon={
                    isMobile ? (
                      <ChevronRightIcon fontSize="large" />
                    ) : (
                      <ExpandMoreIcon fontSize="large" />
                    )
                  }
                >
                  <span className="InfoPage__accordion-title">
                    {t('care.components.InfoPage.activityOverview')}
                  </span>
                  <span className="InfoPage__accordion-subtitle">
                    {t('care.components.InfoPage.activityOverviewLogged')}
                  </span>
                </AccordionSummary>

                <AccordionDetails>
                  <BehaviorPanel
                    className="mb-4"
                    sensorCompositeId={props.sensor?.sensor_composite_id ?? '0'}
                    enableRequest={activityOverviewEnabled}
                    showSoundDimension={app.hasFeature('show-sound-dimension')}
                    onMobileModalCloseClick={() =>
                      setAccordions((prev) => ({
                        ...prev,
                        activityOverview: false,
                      }))
                    }
                  />
                </AccordionDetails>
              </Accordion>
            </Tooltip>
          </Feature>

          <Tooltip
            title={
              hasAccessToMarkings
                ? ''
                : t(
                    'care.components.InfoPage.noAccessToDetailedImageForMarkings'
                  )
            }
            placement={isMobile ? 'bottom' : 'bottom-start'}
            arrow={isMobile}
            enterTouchDelay={0}
            leaveTouchDelay={3000}
          >
            <Accordion
              expanded={hasAccessToMarkings && accordions.markings}
              onChange={handlePanelChange('markings')}
              disabled={!hasAccessToMarkings}
              TransitionProps={{
                unmountOnExit: true,
              }}
            >
              <AccordionSummary
                expandIcon={<ExpandMoreIcon fontSize="large" />}
              >
                <span className="InfoPage__accordion-title">
                  {t('care.components.InfoPage.sensorMarkings')}
                </span>
                <span className="InfoPage__accordion-subtitle">
                  {t('care.components.InfoPage.sensorMarkingsLogged')}
                </span>
              </AccordionSummary>

              <AccordionDetails>
                <MarkingOverview
                  sensorCompositeId={props.sensor?.sensor_composite_id}
                  markingObjectsLoading={markingObjectResult.loading}
                  markingObjects={markingObjectResult.data?.items}
                />
              </AccordionDetails>
            </Accordion>
          </Tooltip>

          <Accordion
            expanded={accordions.activeEventSettings}
            onChange={handlePanelChange('activeEventSettings')}
          >
            <AccordionSummary expandIcon={<ExpandMoreIcon fontSize="large" />}>
              <span className="InfoPage__accordion-title">
                {t('care.components.InfoPage.eventSettings')}
              </span>
            </AccordionSummary>

            <AccordionDetails>
              {props.loading ? (
                <div>
                  <InlineSpinner />
                </div>
              ) : (
                <EventSettingsInfoTable
                  event_settings={props.data.event_settings}
                  markingObjectsLoading={markingObjectResult.loading}
                  markingObjects={markingObjectResult.data?.items}
                />
              )}
            </AccordionDetails>
          </Accordion>

          <Accordion
            expanded={accordions.last24h}
            onChange={handlePanelChange('last24h')}
          >
            <AccordionSummary expandIcon={<ExpandMoreIcon fontSize="large" />}>
              <span className="InfoPage__accordion-title">
                {t('care.components.InfoPage.eventsLast24Hours')}
              </span>
            </AccordionSummary>

            <AccordionDetails>
              <div>
                <WhenTimezoneDiffers tz={props.data.timezone}>
                  <div className="mb-4">
                    <Icon icon="warning" />
                    {t('care.components.InfoPage.timestampsInSensorLocalTime')}
                  </div>
                </WhenTimezoneDiffers>

                {props.loading ? (
                  <div>
                    <InlineSpinner />
                  </div>
                ) : (
                  <>
                    {props.data.last_movement_in_bed_sensor_local && (
                      <div className="InfoPage-info">
                        <Icon icon="info" />
                        <div>
                          {t('care.components.InfoPage.lastMoveInBed')}:
                          <br />
                          {getMinutesAndSecondsFromTime(
                            props.data.last_movement_in_bed_sensor_local
                          )}
                        </div>
                      </div>
                    )}

                    <div>
                      {props.data?.events?.length ? (
                        <table className="InfoPage-events">
                          <tbody>
                            {props.data.events.map((e) => (
                              <tr key={e.id}>
                                <td>
                                  <EventTypeIcon
                                    sensorType={e.sensor_type}
                                    eventType={e.event_type}
                                  />
                                </td>
                                <td>
                                  {getLabelForEventType(
                                    e.event_type,
                                    e.sensor_type
                                  )}
                                </td>
                                <td>
                                  {getMinutesAndSecondsFromTime(
                                    e.received_at_sensor_local
                                  )}
                                </td>
                              </tr>
                            ))}
                          </tbody>
                        </table>
                      ) : (
                        <>
                          {t('care.components.InfoPage.noEventsLast24Hours')}.
                        </>
                      )}
                    </div>
                  </>
                )}
              </div>
            </AccordionDetails>
          </Accordion>
        </div>
      )}
    </div>
  );
}

export function InfoPage() {
  const care = useCareContext();

  const res = useApiCall(
    api.careGetInfoSummaryForSensor,
    [care.selectedSensor?.sensor_composite_id || '0'],
    { skip: !care.selectedSensor?.sensor_composite_id }
  );

  if (!care.selectedSensor) {
    return (
      <div className="InfoPage">
        <CenteredLogo
          title={t(
            'care.common.selectHousingUnitSensorInTheLeftFieldForSupervision'
          )}
        />
      </div>
    );
  }

  if (care.selectedSensor && !res.loading && !res.data) {
    return (
      <div className="InfoPage-info">
        <Icon icon="info" />
        <div>{t('care.common.noDataAvailable')}.</div>
      </div>
    );
  }

  if (!res.data && res.loading) {
    return (
      <div className="InfoPage">
        <div>
          <InlineSpinner />
        </div>
      </div>
    );
  }

  return (
    <InfoPageMarkup
      loading={res.loading}
      data={res.data}
      sensor={care.selectedSensor}
    />
  );
}
