import React from 'react';
import { RouteComponentProps, Switch, Route } from 'react-router';
import { ListZones } from './ListZones';

export function Zones(props: RouteComponentProps<{}>) {
  return (
    <Switch>
      <Route exact={true} path={props.match.path} component={ListZones} />
    </Switch>
  );
}
