import * as api from '../../../../../serverApi';
import groupAuditables, { GroupedChanges } from './groupAuditables';

// import { GroupedChanges } from './types';
import formatAlertSetting from './formats/formatAlertSetting';
import formatDefault from './formats/formatDefault';

export type RequestWithFormattedChanges = ReturnType<typeof formatChanges>[0];

export type GroupedChangesFormatter<T, R> = (
  auditChangeLogEntry: api.AuditChangeLogEntry,
  groupedChanges: GroupedChanges
) => {
  auditChangeLogEntry: api.AuditChangeLogEntry;
  type: T;
  groupedChanges: GroupedChanges;
  formattedChanges: {
    old_values?: R;
    new_values?: R;
  };
};

/**
 * This function groups related audit change log entries into suitable entities.
 * For example, it will group any AlertSettingEventType changes together with any
 * corresponding AlertSetting changes. This is used on change entries within a
 * single request ID.
 *
 * Typically, but not always, all the changes for a single request ID will return
 * changes for a single object after the grouping. I.e. while the underlying log
 * can contain AlertSetting, AlertSettingEventType, AlertSettingScheduleRule etc.
 * for a single request, in this case they will all be grouped with the same
 * AlertSetting.
 *
 * @param entries The audit change log entries, as received from the audit change log backend API call.
 */
export function formatChanges(entries: api.AuditChangeLogEntry[]) {
  /**
   * From the server, we receive the individual entries grouped by request_id.
   * This is also how we page the audit changes -- a fixed number of request_ids
   * on every page, while the "real" number of audit change log entries can vary
   * depending on the type of change (but at least one change per request_id).
   *
   * So first, for each request_id, we make an additional group level to group
   * all requests with a matching auditable_group into the same collection.
   */

  const requestsWithGroupedAuditables = entries.map(requestEntries => ({
    ...requestEntries,
    auditableGroups: groupAuditables(requestEntries.changes),
  }));

  /**
   * At this point, we have grouped one more level -- this whole function accepts an
   * array of the changes within a single request_id, and creates an additional group
   * level based on the auditable_group field in the rows.
   *
   * Next, we will run an additional pass over the data based on the group type/overall
   * auditable type.
   */
  const requestsWithFormattedAuditables = requestsWithGroupedAuditables.map(
    re => ({
      ...re,
      formattedAuditableGroups: re.auditableGroups.map(ag => {
        /**
         * Map the overall auditable type to a formatter function so we can
         * customise the output presentation based on the model type.
         */
        if (ag.auditable_type === 'App\\Models\\AlertSetting') {
          return formatAlertSetting(re, ag);
        }

        return formatDefault(re, ag);
      }),
    })
  );
  return requestsWithFormattedAuditables;
}

/***
 *
 *
 *
 *
 *
 *
 *
 *
 *
 *
 *
 *
 *
 */
