import React, { CSSProperties, useEffect, useMemo, useState } from 'react';
import cs from 'classnames';
import matchSorter from 'match-sorter';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes } from '@fortawesome/pro-solid-svg-icons';
import { faExclamationTriangle } from '@fortawesome/pro-solid-svg-icons';

import { t } from '../../../lib/i18n';
import * as api from '../../../serverApi';
import { Sensor, useCareContext, Event, SidebarMode } from '../lib/CareContext';

import FilterInput from '../../../components/FilterInput/FilterInput';
import { SensorCard } from './SensorCard';
import CareIndicators from './CareIndicators';
import EventCard from './EventCard';

import './CareSidebar.scss';

interface Props {
  className?: string;
  style?: CSSProperties;
}

function filterSensorsByZone(sensors: Sensor[], zone?: api.StructureZone) {
  if (!zone) {
    return sensors;
  }
  let response: Sensor[] = [];
  response = response.concat(sensors.filter((s) => s.zone_id === zone.id));
  if (zone.zones && zone.zones.length > 0) {
    zone.zones.forEach((z) => {
      response = response.concat(filterSensorsByZone(sensors, z));
    });
  }
  return response;
}

export const CareSidebar = (props: Props) => {
  const care = useCareContext();

  const { sidebarMode, setSidebarMode } = care;

  const [keyword, setKeyword] = useState('');

  const [currentTime, setCurrentTime] = useState(() => Date.now());

  /**
   * We set an interval to update current time every second and clear it when component is unmounted.
   */
  useEffect(() => {
    const timeInterval = setInterval(() => setCurrentTime(Date.now()), 1000);

    return () => clearInterval(timeInterval);
  }, []);

  function handleOnSelectMode(mode: SidebarMode) {
    setSidebarMode(mode);
  }

  function filterEventsByZone(events: Event[], zone?: api.StructureZone) {
    if (!zone) {
      return events;
    }

    let response: Event[] = [];
    response = response.concat(
      events.filter((e) => e.sensor?.zone_id === zone.id)
    );

    if (zone.zones && zone.zones.length > 0) {
      zone.zones.forEach((z) => {
        response = response.concat(filterEventsByZone(events, z));
      });
    }

    return response;
  }
  function filterEvents(keyword: string): Event[] {
    const events =
      sidebarMode === 'alerts' || sidebarMode === 'alarms'
        ? care[sidebarMode]
        : ([] as Event[]);

    const filteredByZone = filterEventsByZone(events ?? [], care.activeZone);

    return matchSorter(filteredByZone, keyword, {
      keys: ['sensor.display_area', 'sensor.display_name'],
    }) as Event[];
  }

  const events = filterEvents(keyword);

  const sensorFilter = useMemo(() => {
    const sensorsEnabledRm = care.sensors
      ? care.sensors.filter((s) => s.is_enabled && s.sensor_type === 'roommate')
      : [];

    const sensorsFilteredByZone = filterSensorsByZone(
      sensorsEnabledRm,
      care.activeZone
    );

    const sensorsFiltered = matchSorter(sensorsFilteredByZone, keyword, {
      keys: ['display_area', 'display_name'],
    });

    return {
      enabledRmSensorCount: sensorsEnabledRm.length,
      sensors: sensorsFiltered,
    };
  }, [care.sensors, keyword, care.activeZone]);

  const isDataStale =
    care.dataTimestamp && currentTime - care.dataTimestamp > 10000
      ? true
      : false;

  return (
    <aside
      className={cs(
        'CareSidebar',
        care.searchEnabled && 'CareSidebar-with-search',
        props.className
      )}
      style={{ ...props.style }}
    >
      <div className="CareSidebar-head">
        <CareIndicators
          mode={sidebarMode}
          onSelectMode={handleOnSelectMode}
          alarmCount={care.alarms?.length}
          alertCount={care.alerts?.length}
          sensorCount={sensorFilter.enabledRmSensorCount}
          attentionAlarms={care.attentionAlarms}
          attentionAlerts={care.attentionAlerts}
          onClickAlarms={care.clearAlarmAttention}
          onClickAlerts={care.clearAlertAttention}
        />

        {isDataStale && (
          <div className="CareSidebar-old-data-card">
            <FontAwesomeIcon
              className="CareSidebar-old-data-card-icon"
              icon={faExclamationTriangle}
            />
            <span>{t('care.Main.connectionLostTryingAgain')}</span>
          </div>
        )}

        {care.searchEnabled && (
          <FilterInput
            className="CareSidebar-head-filter-input"
            onChange={(name, value) => setKeyword(value)}
          />
        )}

        {care.activeZone && (
          <div className="CareSidebar-head-active-zone">
            <span className="CareSidebar-active-zone-name">
              {care.activeZone.is_housing_unit
                ? t('care.components.Sidebar.selectedHousingUnit')
                : t('care.components.Sidebar.selectedZone')}
              : <strong>{care.activeZone.name}</strong>
            </span>
            <span
              role="button"
              className="CareSidebar-btn CareSidebar-btn-remove-zone"
              onClick={() => care.handleActiveZoneSelect(undefined)}
            >
              <FontAwesomeIcon icon={faTimes} />
            </span>
          </div>
        )}
      </div>

      <div
        className={cs(
          'CareSidebar-body',
          care.activeZone && 'CareSidebar-body-zone-selected',
          care.activeZone &&
            care.searchEnabled &&
            'CareSidebar-body-zone-selected--with-search'
        )}
      >
        {(sidebarMode === 'sensors' || sidebarMode === 'info') && (
          <>
            {sensorFilter.sensors.map((s, index) => (
              <SensorCard
                key={`${s.sensor_composite_id}-${index}`}
                selected={
                  care.selectedSensor &&
                  s.sensor_composite_id ===
                    care.selectedSensor.sensor_composite_id
                }
                sensor={s}
                onClick={() => {
                  care.toggleMainContent();
                  care.selectSensor(s.sensor_composite_id);
                }}
              />
            ))}
          </>
        )}

        {(sidebarMode === 'alerts' || sidebarMode === 'alarms') &&
          events.map((ce) => (
            <EventCard
              key={ce.event_id}
              event={ce}
              selected={
                care.selectedEvent &&
                ce.event_id === care.selectedEvent.event_id
              }
              onClick={() => {
                care.toggleMainContent();
                care.selectEvent(ce.event_id);
              }}
            />
          ))}
      </div>
    </aside>
  );
};
