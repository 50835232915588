import React from 'react';
import cs from 'classnames';

import { Icon, IconDefinition } from './Icon';

import './HelpBlock.scss';

interface Props {
  className?: string;
  icon?: IconDefinition;
  isPdf?: boolean;
  onClick?: () => void;
  children?: React.ReactNode;
}

export function HelpBlock(props: Props) {
  return (
    <div
      role="button"
      tabIndex={0}
      className={cs(
        'HelpBlock',
        props.isPdf && 'HelpBlock--pdf',
        props.className
      )}
      onClick={props.onClick}
    >
      {props.icon && <Icon className="HelpBlock-icon" icon={props.icon} />}

      <div className="HelpBlock-body">{props.children}</div>
    </div>
  );
}
