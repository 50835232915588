import React, { useEffect } from 'react';
import { useParams } from 'react-router';
import { useQueryClient } from 'react-query';

import { AccountForm } from './AccountForm';
import { InlineSpinner } from '../../../components/InlineSpinner';
import { Button } from '../../../components/Button';
import { useRouter } from '../../../lib/useRouter';
import * as yup from 'yup';
import { useErrorHandler } from '../../../lib/useErrorHandler';
import { useAccountFormik } from './lib/useAccountFormik';
import { ErrorMessages } from '../../../components/ErrorMessages';
import { HeadingBar } from '../../super/shared/HeadingBar';
import { Card } from '../../super/shared/Card';
import { AccountAssignVendor } from './AccountAssignVendor';
import { useAppContext } from '../../../lib/global';
import { t } from '../../../lib/i18n';
import {
  QueryKeys,
  SuperVendorGetAccountsResult,
  useSuperVendorGetAccountDetailsQuery,
  useSuperVendorUpdateAccountMutation,
} from '../../../serverApi';
import setQueryDataForAccount from './lib/setQueryDataForAccount';
import { AccountAssignSsoProvider } from './AccountAssignSsoProvider';
import { isValidationError } from '../../../lib/utils/errorUtils';

export function EditAccount() {
  const router = useRouter();
  const app = useAppContext();
  const errorHandler = useErrorHandler();
  const { accountId } = useParams<{ accountId: string }>();

  const queryClient = useQueryClient();
  const accountsCacheKey = QueryKeys.superVendorGetAccounts({});

  const query = useSuperVendorGetAccountDetailsQuery(parseInt(accountId), {
    initialData: () => {
      const res = queryClient.getQueryData<SuperVendorGetAccountsResult>(
        accountsCacheKey
      );
      const account = res?.data.find(
        (item) => item.account_id === parseInt(accountId)
      );
      if (!account) {
        return;
      }
      return { account: account };
    },
  });

  const account = query?.data?.account;

  const hasSuperCapability = app.hasCapability('globalSuper');

  const { mutateAsync } = useSuperVendorUpdateAccountMutation({
    onSuccess: (data) => {
      setQueryDataForAccount(queryClient, data.account);
      router.history.push(`/super/accounts`);
    },
  });
  const formik = useAccountFormik({
    validationSchema: () =>
      yup.object().shape({
        name: yup.string().required(),
        account_code: yup.string().required(),
      }),
    onSubmit: async (values, formikHelpers) => {
      try {
        await mutateAsync({
          accountId: parseInt(accountId),
          data: values,
        });
      } catch (e) {
        if (isValidationError(e)) {
          formikHelpers.setErrors(e.response?.data.errors);
        }
        errorHandler.handleError(e);
      }
    },
  });

  useEffect(() => {
    if (account && !formik.dirty) {
      formik.setValues(account);
    }
  }, [account, formik]);

  return (
    <div className="EditAccount">
      {query.isLoading ? (
        <InlineSpinner
          size="md"
          text="Loading..."
          textStyle={{ fontWeight: 500, fontSize: 22 }}
        />
      ) : !account ? (
        <div>Account not found !</div>
      ) : (
        <>
          <HeadingBar>
            <h1>{`Edit ${account.name}`}</h1>
          </HeadingBar>

          <Card>
            <AccountForm
              form={formik}
              loading={formik.isSubmitting}
              mode="edit"
            />

            {formik.values.enable_sso && (
              <AccountAssignSsoProvider
                accountId={account.account_id}
                onSave={() => formik.submitForm()}
              />
            )}

            {hasSuperCapability && !account.is_vendor && (
              <AccountAssignVendor accountId={account.account_id} />
            )}

            <Button
              className="mt-4"
              variant="contained"
              color="primary"
              disabled={formik.isSubmitting}
              spinIcon={formik.isSubmitting}
              icon={formik.isSubmitting ? 'gear' : undefined}
              onClick={formik.submitForm}
            >
              {t('supervendor.AccountForm.save')}
            </Button>
            <ErrorMessages
              tag="span"
              className="m-3"
              errorData={errorHandler}
            />
          </Card>
        </>
      )}
    </div>
  );
}
