import React, { useEffect, useState } from 'react';
import { FormGroup, FormControlLabel, Checkbox } from '@material-ui/core';

import { EventSettingsForm } from '../useEventSettingsForm';
import TimeIntervalPicker from '../../../../../components/TimeIntervalPicker';
import { t } from '../../../../../lib/i18n';
import { GreenCircleIndicator } from '../../../common/ManageSidebar';

export default function EventTimeIntervalControl(props: {
  form: EventSettingsForm;
}) {
  const { form } = props;

  const [enableTimePeriod, setEnableTimePeriod] = useState(
    Boolean(
      form.initialValues &&
        (form.initialValues.enabled_from || form.initialValues.enabled_to)
    )
  );

  useEffect(() => {
    const newVal = Boolean(
      form.values && (form.values.enabled_from || form.values.enabled_to)
    );
    if (newVal !== enableTimePeriod) {
      setEnableTimePeriod(newVal);
    }
  }, [form.values.enabled_from, form.values.enabled_to]); // eslint-disable-line react-hooks/exhaustive-deps

  const fieldHasChanged =
    form.values.enabled_from !== form.initialValues.enabled_from ||
    form.values.enabled_to !== form.initialValues.enabled_to;

  return (
    <div className="d-flex flex-column">
      <FormGroup
        className="mt-4 position-relative"
        style={{ width: 'fit-content' }}
      >
        <FormControlLabel
          className="d-inline-flex"
          control={
            <Checkbox
              checked={enableTimePeriod}
              onChange={(e) => {
                setEnableTimePeriod(e.target.checked);
                if (!e.target.checked) {
                  form.setFieldValue('enabled_from', null);
                  form.setFieldValue('enabled_to', null);
                  form.setFieldTouched('enabled_from');
                  form.setFieldTouched('enabled_to');
                }
              }}
              color="primary"
            />
          }
          label={t(
            'manage.sensors.events.controls.EventTimeIntervalControl.selectATimePeriod'
          )}
        />
        {fieldHasChanged && <GreenCircleIndicator style={{ right: '-15px' }} />}
      </FormGroup>

      <TimeIntervalPicker
        disabled={!enableTimePeriod}
        fromValue={form.values.enabled_from}
        toValue={form.values.enabled_to}
        onChange={(v) => {
          form.setFieldValue('enabled_from', v.time_from);
          form.setFieldValue('enabled_to', v.time_to);
          form.setFieldTouched('enabled_from');
          form.setFieldTouched('enabled_to');
        }}
      />
    </div>
  );
}
