import React, { CSSProperties } from 'react';
import { t } from '../i18n';

interface Props {
  heading?: string;
  style?: CSSProperties;
}

export default function LoadingPlaceholder(props: Props) {
  return (
    <>
      <h2>{props.heading}</h2>

      <div
        style={{
          padding: '50px 20px',
          textAlign: 'center',
          color: '#666',
          fontSize: '32px',
          ...props.style,
        }}
      >
        {t('common.commonTexts.loading')} ...
      </div>
    </>
  );
}
