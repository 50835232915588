import { FrontendMarkingObjectType } from '../types';

type ValidationResult = 'ok' | 'reject' | 'warning';

interface ValidateMarkingThresholdsResult {
  status: ValidationResult;
}

/**
 * Run validation/sanity check for the X,Y,Z values for the given marking type.
 * @param type The type of the marking object, using 'chair_area' and 'chair_circle' (not only 'circle').
 * @param x The x coordinate of the point to validate.
 * @param y The y coordinate of the point to validate.
 * @param z The z coordinate of the point to validate.
 * @param e The e option of the point to validate.
 * @returns 'ok', 'warning' or 'reject'.
 */
export function validateMarkingThresholds(
  type: FrontendMarkingObjectType,
  x: number,
  y: number,
  z: number,
  e?: boolean
): ValidateMarkingThresholdsResult {
  if (x === null || y === null || z === null) {
    return { status: 'ok' };
  }

  if (type === 'bed' || type === 'chair_area') {
    return { status: validateBedChairGroupThresholds(x, y, z) };
  }

  if (type === 'chair_circle') {
    return { status: validateChairThresholds(x, y, z) };
  }

  if (type === 'door_exit' || type === 'bathroom') {
    return { status: validateDoorBathThresholds(x, y, z, e) };
  }

  return { status: 'ok' };
}

/**
 * Bed / Chair group Thresholds
 */
function validateBedChairGroupThresholds(x: number, y: number, z: number) {
  if (-5000 <= x && x <= 5000 && 0 <= y && y <= 1200 && 0 < z && z <= 6000) {
    return 'ok';
  }
  if (7500 < x || x < -7500 || 1600 < y || y < -500 || z === 0 || z > 7500) {
    return 'reject';
  }
  return 'warning';
}

/**
 * Chair Thresholds
 */
function validateChairThresholds(x: number, y: number, z: number) {
  if (-3500 <= x && x <= 3500 && 200 <= y && y <= 1000 && 0 < z && z <= 4500) {
    return 'ok';
  }
  if (5000 < x || x < -5000 || 1600 < y || y < -300 || z === 0 || z > 6000) {
    return 'reject';
  }
  return 'warning';
}

/**
 * Door / Bath Thresholds
 */
function validateDoorBathThresholds(
  x: number,
  y: number,
  z: number,
  e?: boolean
) {
  /**
   * e = 1, slightly different parameters for validation
   */
  if (e) {
    if (
      -5000 <= x &&
      x <= 5000 &&
      -200 <= y &&
      y <= 1200 &&
      0 <= z &&
      z <= 10000
    ) {
      return 'ok';
    }
    if (7500 < x || x < -7500 || 1800 < y || y < -500 || z > 10000) {
      return 'reject';
    }
    return 'warning';
  }

  /**
   * e = 0
   */
  if (-5000 <= x && x <= 5000 && -200 <= y && y <= 1200 && 0 < z && z <= 6000) {
    return 'ok';
  }
  if (7500 < x || x < -7500 || 1800 < y || y < -500 || z === 0 || z > 7500) {
    return 'reject';
  }
  return 'warning';
}
