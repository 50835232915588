import React from 'react';

import * as serverApi from '../../../serverApi';

import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import { useSuperStyles } from '../useSuperStyles';
import HeadingBar from '../shared/HeadingBar';
import { TableWrapper } from '../../../components/TableWrapper';
import { Card } from '../shared/Card';
import { NhnCertificatesTable } from './NhnCertificatesTable';
import { TableLoadingPlaceholder } from '../shared/TableLoadingPlaceholder';

export function Overview() {
  const res = serverApi.useSuperGetOverviewDataQuery();
  const classes = useSuperStyles();

  if (res.error) {
    return <span>Error!</span>;
  }

  let body: React.ReactNode = null;
  if (res.isLoading) {
    body = <TableLoadingPlaceholder colSpan={2} />;
  } else if (res.data) {
    body = (
      <>
        <TableRow>
          <TableCell component="th" scope="row">
            Users
          </TableCell>
          <TableCell>
            {res.data.count_users}
            <small>(highest ID is {res.data.max_user_id})</small>
          </TableCell>
        </TableRow>
        <TableRow>
          <TableCell component="th" scope="row">
            Sensors
          </TableCell>
          <TableCell>
            {res.data.count_sensors}{' '}
            <small>(highest ID is {res.data.max_roommate_sensor_id})</small>
          </TableCell>
        </TableRow>
        <TableRow>
          <TableCell component="th" scope="row">
            Online, real sensors
          </TableCell>
          <TableCell>{res.data.count_sensors_real_online}</TableCell>
        </TableRow>
        <TableRow>
          <TableCell component="th" scope="row">
            Online, emulated sensors
          </TableCell>
          <TableCell>{res.data.count_sensors_emulated_online}</TableCell>
        </TableRow>
        <TableRow>
          <TableCell component="th" scope="row">
            Zones
          </TableCell>
          <TableCell>{res.data.count_zones} </TableCell>
        </TableRow>
      </>
    );
  }

  return (
    <>
      <HeadingBar>
        <h2>Overview</h2>
      </HeadingBar>

      <Card>
        <TableWrapper>
          <Table className={classes.table}>
            <TableHead>
              <TableRow>
                <TableCell>Key</TableCell>
                <TableCell>Value</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>{body}</TableBody>
          </Table>
        </TableWrapper>
      </Card>

      <Card className="mt-4">
        <NhnCertificatesTable
          data={res.data?.nhn_certificates || []}
          loading={res.isLoading}
        />
      </Card>
    </>
  );
}
