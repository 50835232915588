import React, { useState, useEffect } from 'react';
import { Link, Redirect } from 'react-router-dom';
import { Col, Row } from 'reactstrap';

import { FormGroup, TextField } from '@material-ui/core';

import qs from 'query-string';
import { toast } from 'react-toastify';

import { useAppContext } from '../../lib/global';
import { useRouter } from '../../lib/useRouter';
import { errorIsObjectError, useErrorHandler } from '../../lib/useErrorHandler';

import { resetPassword } from '../../serverApi';
import { LayoutWithBackground } from './LayoutWithBackground';
import { PublicHeader } from './PublicHeader';
import { PublicFooter } from './PublicFooter';
import { CenteredCard } from './CenteredCard';
import { ErrorMessages } from '../../components/ErrorMessages';
import { Panel } from '../../components/Panel';
import { t } from '../../lib/i18n';
import { generateAutoCompleteValue } from '../../lib/utils/generateAutoCompleteValue';

export function ResetPassword() {
  const app = useAppContext();
  const router = useRouter();
  const errorHandler = useErrorHandler();

  const [loading, setLoading] = useState(false);

  const [token, setToken] = useState('');

  const [account, setAccount] = useState('');
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [passwordConfirmation, setPasswordConfirmation] = useState('');

  useEffect(() => {
    const params = qs.parse(router.location.search);

    setAccount((params.account || '') as string);
    setUsername((params.username || '') as string);
    setToken((params.token || '') as string);
  }, [router.location.search]);

  if (app.isAuthenticated) {
    const values = qs.parse(router.location.search);
    return (
      <Redirect to={`${values.redirect || ''}${values.hash || ''}` || '/'} />
    );
  }

  const handleResetPassword = async (
    token: string,
    account: string,
    username: string,
    password: string,
    passwordConfirmation: string
  ) => {
    errorHandler.reset();
    try {
      setLoading(true);
      await resetPassword(token, {
        account,
        username,
        password,
        passwordConfirmation,
      });
      setLoading(false);
      toast.success(
        `${t('publicComponents.ResetPassword.thePasswordHasBeenChanged')}.`
      );
      router.history.push('/login');
    } catch (error) {
      errorHandler.handleError(error);
      setLoading(false);
    }
  };

  const onSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    if (password !== passwordConfirmation) {
      errorHandler.setObjectError({
        password_confirm: t(
          'publicComponents.ResetPassword.thePasswordsAreNotTheSame'
        ),
      });
      return;
    }

    await handleResetPassword(
      token,
      account,
      username,
      password,
      passwordConfirmation
    );
  };

  return (
    <div className="App App-public" id="app">
      <div className="App-body" id="appBody">
        <LayoutWithBackground>
          <div className="ResetPassword">
            <PublicHeader />
            <CenteredCard>
              <h1 className="mb-4">
                {t('publicComponents.ResetPassword.resetPassword')}
              </h1>
              <Row>
                <Col lg={10}>
                  <ErrorMessages errorData={errorHandler} />
                  <form onSubmit={onSubmit}>
                    <FormGroup>
                      <TextField
                        disabled
                        type="text"
                        name="account"
                        placeholder={t('publicComponents.common.account')}
                        className="w-100"
                        InputProps={{
                          className: 'text-field-input',
                        }}
                        value={account}
                        error={
                          errorIsObjectError(errorHandler.error) &&
                          !!errorHandler.error['account']
                        }
                        helperText={
                          errorIsObjectError(errorHandler.error) &&
                          errorHandler.error['account']
                        }
                        onChange={(e) => setAccount(e.currentTarget.value)}
                      />
                    </FormGroup>

                    <FormGroup>
                      <TextField
                        disabled
                        type="text"
                        name="username"
                        placeholder={t('common.commonTexts.userName')}
                        className="w-100"
                        InputProps={{
                          className: 'text-field-input',
                        }}
                        value={username}
                        error={
                          errorIsObjectError(errorHandler.error) &&
                          !!errorHandler.error['username']
                        }
                        helperText={
                          errorIsObjectError(errorHandler.error) &&
                          errorHandler.error['username']
                        }
                        onChange={(e) => setUsername(e.currentTarget.value)}
                      />
                    </FormGroup>

                    <FormGroup>
                      <TextField
                        disabled={loading}
                        type="password"
                        name="password"
                        placeholder={t(
                          'publicComponents.ResetPassword.newPassword'
                        )}
                        className="w-100"
                        InputProps={{
                          className: 'text-field-input',
                        }}
                        value={password}
                        error={
                          errorIsObjectError(errorHandler.error) &&
                          !!errorHandler.error['password']
                        }
                        helperText={
                          errorIsObjectError(errorHandler.error) &&
                          errorHandler.error['password']
                        }
                        onChange={(e) => setPassword(e.currentTarget.value)}
                      />
                    </FormGroup>

                    <FormGroup className="mb-3">
                      <TextField
                        autoComplete={generateAutoCompleteValue()}
                        disabled={loading}
                        type="password"
                        name="password"
                        placeholder={t(
                          'publicComponents.ResetPassword.confirmPassword'
                        )}
                        className="w-100"
                        InputProps={{
                          className: 'text-field-input',
                        }}
                        value={passwordConfirmation}
                        error={
                          errorIsObjectError(errorHandler.error) &&
                          !!errorHandler.error['password_confirm']
                        }
                        helperText={
                          errorIsObjectError(errorHandler.error) &&
                          errorHandler.error['password_confirm']
                        }
                        onChange={(e) =>
                          setPasswordConfirmation(e.currentTarget.value)
                        }
                      />
                    </FormGroup>

                    <button
                      type="submit"
                      className="btn btn-default btn-login btn-green"
                      disabled={loading}
                    >
                      {loading
                        ? `${t('common.commonTexts.pleaseWait')} ...`
                        : t('publicComponents.ResetPassword.reset')}
                    </button>
                  </form>

                  <p className="my-3">
                    <Link to="/login">
                      &laquo; {t('publicComponents.common.backToLogin')}
                    </Link>
                  </p>

                  <Panel className="mt-4">
                    <h3>{t('common.commonTexts.tips')}</h3>
                    {t('common.commonTexts._passwordTips')}
                  </Panel>
                </Col>
              </Row>
            </CenteredCard>
            <PublicFooter />
          </div>
        </LayoutWithBackground>
      </div>
    </div>
  );
}
