import React from 'react';
import cs from 'classnames';

import './CareIndicator.scss';
import { Icon } from '../../../components/Icon';

interface Props {
  className?: string;
  title?: string;
  value?: number | undefined;
  type?: 'alarms' | 'alerts' | 'sensors' | 'info';
  selected?: boolean;
  extraAttention?: boolean;
  onSelect?: (area: 'alarms' | 'alerts' | 'sensors' | 'info') => void;
}

const CareIndicator = (props: Props) => {
  function onClick(e: React.MouseEvent<HTMLButtonElement>) {
    if (props.onSelect && props.type) {
      props.onSelect(props.type);
    }
  }

  return (
    <button
      className={cs(
        'CareIndicator',
        props.type ? `CareIndicator--${props.type}` : undefined,
        {
          selected: props.selected,
          attention: props.extraAttention,
        },
        props.className
      )}
      onClick={onClick}
    >
      <div className="CareIndicator-content">
        {props.type === 'info' ? (
          <Icon icon="info" />
        ) : (
          <>
            <div className="CareIndicator-label">{props.title}</div>
            <div className="CareIndicator-value">
              {props.value ?? <>&nbsp;</>}
            </div>
          </>
        )}
      </div>
    </button>
  );
};

export default CareIndicator;
