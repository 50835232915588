import { useState, useEffect, useCallback, useRef } from 'react';
import { useAppContext } from './global';

const emptyArray = [] as string[];

export function useApiCall<R, A extends any[]>(
  apiCall: (...args: A) => Promise<R>,
  args?: A,
  options?: {
    /**
     * Whether to skip/ignore this query.
     */
    skip?: boolean;

    /**
     * Repeat the request every n milliseconds.
     */
    repeatInterval?: number | null;
  }
) {
  const skip = Boolean(options?.skip);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<any>(undefined);
  const [data, setData] = useState<R | undefined>(undefined);
  const [i, setI] = useState(0);

  const mounted = useRef(false);
  const inFlight = useRef(0);

  const app = useAppContext();

  /**
   * Handle repeat intervals
   */
  useEffect(() => {
    if (options?.repeatInterval && !options?.skip) {
      /**
       * If the repeat interval option is set, and we are not
       * currently skipping (options.skip), create a timer:
       */
      const intervalTimer = setInterval(() => {
        setI(i + 1);
      }, options.repeatInterval);
      return () => clearInterval(intervalTimer);
    }
  }, [options?.skip, options?.repeatInterval, i]);

  const reload = useCallback(() => {
    setI(i + 1);
  }, [apiCall, i, ...(args || emptyArray)]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    mounted.current = true;
    if (!skip) {
      setLoading(true);

      /**
       * We skip sending a new request if there is
       * already an in-flight request.
       */
      if (inFlight.current < 1) {
        inFlight.current = inFlight.current + 1;
        apiCall
          .apply({}, args === undefined ? (([] as unknown) as A) : args)
          .then((res) => {
            inFlight.current = inFlight.current - 1;
            if (mounted.current) {
              setLoading(false);
              setData(res);
            }
          })
          .catch((err) => {
            inFlight.current = inFlight.current - 1;
            if (mounted.current) {
              setLoading(false);
              setError(err);
            }
          });
      }
    }

    return () => {
      mounted.current = false;
    };
  }, [apiCall, i, ...(args || emptyArray), reload, app, skip]); // eslint-disable-line react-hooks/exhaustive-deps

  return {
    error,
    loading,
    args,
    data,
    reload,
  };
}
