import React from 'react';
import * as api from '../../../../serverApi';
import {
  Checkbox,
  FormControlLabel,
  TextField,
  MenuItem,
} from '@material-ui/core';
import {
  KeyboardTimePicker,
  MuiPickersUtilsProvider,
} from '@material-ui/pickers';
import MomentUtils from '@date-io/moment';
import moment, { Moment } from 'moment';
import { IconButton } from '../../../../components/IconButton';
import { t, TranslationKey } from '../../../../lib/i18n';
import { allDays } from './ScheduleSelector';

import './ScheduleSelectorRule.scss';
import { getGlobalLocale } from '../../../../lib/i18n/i18n';

interface Props {
  allDays?: boolean;
  scheduleRule?: api.AlertSettingScheduleRule;
  disabled?: boolean;
  onChange?: (updatedScheduleRule: api.AlertSettingScheduleRule) => void;
  onDelete?: () => void;

  fromTimeError?: string;
  toTimeError?: string;
}

export const defaultTime = '00:00:00';

export function ScheduleSelectorRule(props: Props) {
  if (!props.scheduleRule) {
    return null;
  }

  function change(chg: Partial<api.AlertSettingScheduleRule>) {
    if (props.onChange && props.scheduleRule) {
      props.onChange({
        ...props.scheduleRule,
        ...chg,
      });
    }
  }

  const isAllDay =
    props.scheduleRule?.from_time === defaultTime &&
    props.scheduleRule?.to_time === defaultTime;

  const fromDayValue = props?.allDays
    ? 'alle'
    : props.scheduleRule?.from_weekday.length === 1
    ? props.scheduleRule?.from_weekday[0]
    : '';

  const toDayValue = props?.allDays
    ? 'alle'
    : isAllDay
    ? fromDayValue
    : props.scheduleRule?.to_weekday.length === 1
    ? props.scheduleRule?.to_weekday[0]
    : '';

  function onCheckboxChange(e: React.ChangeEvent<HTMLInputElement>) {
    change({
      from_time: e.currentTarget.checked ? defaultTime : '08:00:00',
      to_time: e.currentTarget.checked ? defaultTime : '22:00:00',
      to_weekday: props.scheduleRule?.from_weekday,
    });
  }

  const onFromTimeChange = (date: Moment | null) => {
    change({
      from_time: date ? date.format('HH:mm:ss') : '',
    });
  };

  const onToTimeChange = (date: Moment | null) => {
    change({
      to_time: date ? date.format('HH:mm:ss') : '',
    });
  };

  const onFromDayChange = (e: React.ChangeEvent<HTMLInputElement>) =>
    change({
      from_weekday: [e.target.value] as api.Weekday[],
      ...(props.scheduleRule?.to_weekday?.length === 1 &&
      props.scheduleRule?.from_weekday?.length === 1 &&
      props.scheduleRule.to_weekday[0] === props.scheduleRule.from_weekday[0]
        ? {
            to_weekday: [e.target.value] as api.Weekday[],
          }
        : undefined),
    });

  const onToDayChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    change({
      to_weekday: [e.target.value] as api.Weekday[],
    });
  };

  return (
    <div className="ScheduleSelectorRule">
      <div>{t('manage.alerts.ui.ScheduleSelectorRule.period')}:</div>

      <div>
        <FormControlLabel
          control={
            <Checkbox
              color="primary"
              checked={isAllDay}
              onChange={onCheckboxChange}
              value="allDays"
              disabled={props.disabled}
            />
          }
          label={t('manage.alerts.ui.ScheduleSelectorRule.roundTheClock')}
        />
      </div>

      <div className="ScheduleSelectorRule-inputs">
        <TextField
          className="mr-1"
          select={true}
          disabled={props?.allDays}
          value={fromDayValue}
          onChange={onFromDayChange}
          name="from_weekday"
          label={t('manage.alerts.ui.ScheduleSelectorRule.fromDay')}
        >
          {props.allDays && (
            <MenuItem value="alle">
              {t('manage.alerts.ui.ScheduleSelectorRule.all')}
            </MenuItem>
          )}
          {allDays.map((day) => (
            <MenuItem value={day} key={day}>
              {t(`lib.longWeekDay.${day}` as TranslationKey)}
            </MenuItem>
          ))}
        </TextField>

        <MuiPickersUtilsProvider locale={getGlobalLocale()} utils={MomentUtils}>
          <KeyboardTimePicker
            label={t('manage.alerts.ui.ScheduleSelectorRule.startingAt')}
            className="mr-1"
            disabled={isAllDay || props.disabled}
            clearable
            ampm={false}
            minutesStep={5}
            format={'HH:mm'}
            value={
              isAllDay || !props.scheduleRule?.from_time
                ? null
                : moment(props.scheduleRule.from_time, 'HH:mm')
            }
            placeholder={props.disabled || isAllDay ? '--' : ''}
            onChange={onFromTimeChange}
            error={!!props.fromTimeError}
            helperText={
              props.fromTimeError &&
              t('manage.alerts.ui.ScheduleSelectorRule.wrongTimeFormat')
            }
            keyboardIcon={null}
            style={{
              opacity: props.disabled || isAllDay ? 0.8 : 1,
            }}
          />
        </MuiPickersUtilsProvider>

        <TextField
          className="mr-1"
          select={true}
          disabled={props?.allDays}
          value={toDayValue}
          onChange={onToDayChange}
          name="to_weekday"
          label={t('manage.alerts.ui.ScheduleSelectorRule.toDay')}
        >
          {props.allDays && (
            <MenuItem value="alle">
              {t('manage.alerts.ui.ScheduleSelectorRule.all')}
            </MenuItem>
          )}
          {/*<MenuItem value="mon">Mandag</MenuItem>*/}
          {/*<MenuItem value="tue">Tirsdag</MenuItem>*/}
          {/*<MenuItem value="wed">Onsdag</MenuItem>*/}
          {/*<MenuItem value="thu">Torsdag</MenuItem>*/}
          {/*<MenuItem value="fri">Fredag</MenuItem>*/}
          {/*<MenuItem value="sat">Lørdag</MenuItem>*/}
          {/*<MenuItem value="sun">Søndag</MenuItem>*/}
          {allDays.map((day) => (
            <MenuItem value={day} key={day}>
              {t(`lib.longWeekDay.${day}` as TranslationKey)}
            </MenuItem>
          ))}
        </TextField>

        <MuiPickersUtilsProvider locale={getGlobalLocale()} utils={MomentUtils}>
          <KeyboardTimePicker
            label={t('manage.alerts.ui.ScheduleSelectorRule.until')}
            disabled={isAllDay || props.disabled}
            clearable
            ampm={false}
            minutesStep={5}
            format={'HH:mm'}
            value={
              isAllDay || !props.scheduleRule?.to_time
                ? null
                : moment(props.scheduleRule.to_time, 'HH:mm')
            }
            placeholder={props.disabled || isAllDay ? '--' : ''}
            onChange={onToTimeChange}
            error={!!props.toTimeError}
            helperText={
              props.toTimeError &&
              t('manage.alerts.ui.ScheduleSelectorRule.wrongTimeFormat')
            }
            keyboardIcon={null}
            style={{
              opacity: props.disabled || isAllDay ? 0.8 : 1,
            }}
          />
        </MuiPickersUtilsProvider>
      </div>
      <div className="mt-2">
        <IconButton
          icon="minus"
          color="secondary"
          onClick={props.onDelete}
          disabled={props.disabled || props.allDays}
        >
          {t('manage.alerts.ui.ScheduleSelectorRule.deleteTimeInterval')}
        </IconButton>
      </div>
    </div>
  );
}
