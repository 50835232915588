import React, { useRef, useState, useEffect, useCallback } from 'react';
import classNames from 'classnames';
import * as api from '../../serverApi';
import { ReactComponent as SensorIcon } from '../../images/roommate-icon.svg';
import { useSensorImageStreaming } from './useSensorImageStreaming';
import { t } from '../../lib/i18n';
import { AudioProperty } from './lib/WebRtcContext';

import './ImageStreamer.scss';
import ViewImageModal from '../../components/modals/ViewImageModal';
import { useCareContext } from './lib/CareContext';
import { useAppContext } from '../../lib/global';
import { toast } from 'react-toastify';
import { ReadOnlyMarkingCanvasOverlay } from '../../components/ReadOnlyMarkingCanvasOverlay';
import { hasBrowserSseSupport } from '../../lib/hasBrowserSseSupport';
import { SessionState } from 'sip.js';

interface Props {
  className?: string;

  /**
   * The ID of the sensor from which to fetch images.
   */
  sensorId: string;

  /**
   * The type of stream images to retrieve.
   */
  streamType: api.StreamType;

  /**
   * Additional audio property value; this is passes to the getStreamToken API call.
   */
  audioProperty?: AudioProperty;

  /**
   * Stop the image streaming automatically after this many seconds. Use 0 for no limit. Default is 30 seconds.
   */
  timeout?: number | null;

  /**
   * Triggered when the image streaming times out.
   */
  onTimeout?: () => void;

  /**
   * The minimum number of ms between requests.
   */
  throttleInterval: number;

  /**
   * Whether to show a demo overlay on the sensor image.
   */
  demoOverlay?: boolean;

  /**
   * Disable explicit sizing. When this is true, it should behave like a standard element.
   * When this is false, the `useImageSizer` hook will be used.
   */
  disableExplicitSizing?: boolean;

  /**
   * Stream images using the MJPEG video compression format (this is prioritized over the SSE streaming)
   */
  useMjpeg?: boolean;
  /**
   * Use SSE (server-sent events) streaming (if available)
   */
  useSse?: boolean;

  /**
   * Whether to fetch a single marking image instead of streaming images.
   * When this is true, the `useSse` parameter will be ignored.
   */
  useSingleMarkingImage?: boolean;

  /**
   * An array of the marking objects to be drawn on the canvas.
   */
  markingObjects?: api.MarkingObject[];

  /**
   * Audio call status
   */
  audioCallStatus?: SessionState;
}

/**
 * This was implemented before the imagestreamer was contained
 * inside a flexbox. As of now; the use of imageSizer is not
 * necessary and if new implementations gives the avatange of
 * moving away from imageSize it should be considered.
 */
function useImageSizer() {
  const aspectRatio = 160 / 120;
  const ref = useRef<HTMLDivElement>(null);
  const care = useCareContext();

  const [size, setSize] = useState<
    { width: number; height: number } | undefined
  >(undefined);

  const onResize = useCallback(() => {
    if (!ref?.current) {
      return;
    }
    const containerSize = ref.current.getBoundingClientRect();
    const availableHeight = window.innerHeight - containerSize.top;
    const availableWidth =
      window.innerWidth - (care.showMainContentMobile ? 0 : containerSize.left);

    const fitToHeight = availableWidth / availableHeight > aspectRatio;
    setSize({
      height: fitToHeight ? availableHeight : availableWidth / aspectRatio,
      width: fitToHeight ? availableHeight * aspectRatio : availableWidth,
    });
  }, [care.showMainContentMobile, aspectRatio]);

  useEffect(() => {
    onResize();
  }, [ref.current, onResize]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    onResize();
    window.addEventListener('resize', onResize);
    return () => {
      window.removeEventListener('resize', onResize);
    };
  }, [onResize]);

  return {
    size,
    ref,
  };
}

export function ImageStreamer(props: Props) {
  const sz = useImageSizer();
  const [isOpen, setIsOpen] = useState(false);
  const appCtx = useAppContext();
  const careCtx = useCareContext();

  const imgRef = useRef<HTMLImageElement>(null);
  const [toastWarningForSensorId, setToastWarningForSensorId] = useState('');

  const streamer = useSensorImageStreaming({
    sensorId: props.sensorId,
    streamType: props.streamType,
    timeout: props.timeout,
    onTimeout: props.onTimeout,
    throttleInterval: hasBrowserSseSupport()
      ? props.throttleInterval
      : Math.max(props.throttleInterval, 250),
    useSse: props.useSse && hasBrowserSseSupport(),
    useMjpeg: props.useMjpeg,
    audioProperty: props.audioProperty,
    useSingleMarkingImage: props.useSingleMarkingImage,
    audioCallStatus: props.audioCallStatus,
  });

  /**
   * If we're streaming using MJPEG compression, before component is unmounted,
   * we set the image src to empty to stop downloading images in the background.
   */
  useEffect(() => {
    const imgStreamer = imgRef.current;
    return () => {
      if (imgStreamer && props.useMjpeg) {
        imgStreamer.src = '';
      }
    };
  }, [props.useMjpeg]);

  const demoOverlay = props.demoOverlay ? (
    <div className="ImageStreamer-demo-overlay">
      <SensorIcon className="ImageStreamer-demo-overlay-icon" />
      {t('care.ImageStreamer.demoSensor')}
    </div>
  ) : null;

  const markingOverlay =
    props.markingObjects && props.streamType !== 'depth' ? (
      <ReadOnlyMarkingCanvasOverlay markingObjects={props.markingObjects} />
    ) : null;

  /**
   * If the user does not have VKP enabled
   * during observation, show a warnings toast
   */
  useEffect(() => {
    if (
      toastWarningForSensorId !== props.sensorId &&
      careCtx.selectedSensor?.vkp_enabled &&
      !appCtx.user?.vkp_enabled
    ) {
      toast.error(t('care.ImageStreamer.notAddedToVkp'));
      setToastWarningForSensorId(props.sensorId);
    }
  }, [
    appCtx.user,
    props.sensorId,
    toastWarningForSensorId,
    careCtx.selectedSensor,
  ]);

  if (streamer.hasTimedOut) {
    return (
      <div className="p-2">
        {t('care.ImageStreamer.theInspectionPeriodHasExpired')}.
      </div>
    );
  }

  return (
    <div className={classNames('ImageStreamer', props.className)} ref={sz.ref}>
      <div
        style={{
          visibility: streamer.imageAvailable ? undefined : 'hidden',
          position: 'relative',
        }}
      >
        <img
          ref={imgRef}
          onClick={() => setIsOpen(true)}
          className="ImageStreamer-img"
          alt={t('care.ImageStreamer.superVisionImageFromRoomMateSensor')}
          src={isOpen ? undefined : streamer.imageSrc}
          onLoad={streamer.imageOnLoad}
          onError={streamer.imageOnError}
          style={
            props.disableExplicitSizing
              ? undefined
              : {
                  width: sz.size?.width,
                  height: sz.size?.height,
                }
          }
        />
        {demoOverlay}
        {markingOverlay}
      </div>
      <ViewImageModal
        isOpen={isOpen}
        imgSrc={streamer.imageSrc}
        onLoad={streamer.imageOnLoad}
        onError={streamer.imageOnError}
        handleModal={() => setIsOpen(false)}
        markingObjects={props.markingObjects}
        streamType={props.streamType}
      />
    </div>
  );
}
