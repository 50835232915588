import React from 'react';
import { FormGroup, MenuItem, TextField } from '@material-ui/core';
import { ErrorComponent } from '../../../../../components/forms/shared/ErrorComponent';
import useZoneSettingsForm from './lib/useZoneSettingsForm';
import { Panel } from '../../../../../components/Panel';
import { useZoneContext } from '../../../../../lib/ZoneContext';

import { FormBody } from './lib/FormBody';
import * as api from '../../../../../serverApi';
import { t } from '../../../../../lib/i18n';

const fields: Array<keyof api.ZoneSettings> = [
  'alert_reminder_count',
  'alert_reminder_interval',
];

export function AlertReminders() {
  const ctx = useZoneContext();
  const form = useZoneSettingsForm({ fields });

  return (
    <div>
      <h2>
        {t('manage.zone.AlertReminders.headingFor')} {ctx.activeZone.name}
      </h2>

      <Panel>
        <strong>{t('common.commonTexts.tips')}:</strong>{' '}
        {t(
          'manage.zone.settings.common.theSettingsFromTheParentZoneAreDisplayedInGrayWhenThisIsSelected'
        )}
      </Panel>

      <FormBody zoneSettingsForm={form}>
        <p>{t('manage.zone.AlertReminders.repetitionsAndIntervals')}:</p>

        <FormGroup className="mt-4" style={{ maxWidth: '200px' }}>
          <TextField
            select={true}
            disabled={form.disabled}
            value={form.displayValues?.alert_reminder_count ?? ''}
            onChange={form.formik.handleChange}
            name="alert_reminder_count"
            label={t('manage.zone.AlertReminders.numberOfReminders')}
          >
            <MenuItem value="0">{t('manage.zone.AlertReminders.no')}</MenuItem>
            <MenuItem value="1">1</MenuItem>
            <MenuItem value="2">2</MenuItem>
            <MenuItem value="3">3</MenuItem>
          </TextField>
          {form.formik.errors.alert_reminder_count && (
            <ErrorComponent msg={form.formik.errors.alert_reminder_count} />
          )}
        </FormGroup>

        <FormGroup className="mt-4" style={{ maxWidth: '200px' }}>
          <TextField
            select={true}
            disabled={form.disabled}
            value={form.displayValues?.alert_reminder_interval ?? ''}
            onChange={form.formik.handleChange}
            name="alert_reminder_interval"
            label={t('manage.zone.AlertReminders.minutesBeforeEachReminder')}
          >
            <MenuItem value="60">1 {t('common.minutes', 1)}</MenuItem>
            <MenuItem value="120">2 {t('common.minutes', 2)}</MenuItem>
            <MenuItem value="180">3 {t('common.minutes', 3)}</MenuItem>
            <MenuItem value="300">5 {t('common.minutes', 5)}</MenuItem>
            <MenuItem value="600">10 {t('common.minutes', 10)}</MenuItem>
            <MenuItem value="900">15 {t('common.minutes', 15)}</MenuItem>
            <MenuItem value="1200">20 {t('common.minutes', 20)}</MenuItem>
            <MenuItem value="1800">30 {t('common.minutes', 30)}</MenuItem>
          </TextField>
          {form.formik.errors.alert_reminder_interval && (
            <ErrorComponent msg={form.formik.errors.alert_reminder_interval} />
          )}
        </FormGroup>
      </FormBody>
    </div>
  );
}
