import { FormikHelpers, useFormik } from 'formik';
import { useSensorContext } from '../../../../lib/SensorContext';
import * as api from '../../../../serverApi';
import * as yup from 'yup';
import { t } from '../../../../lib/i18n';
import { useQueryClient } from 'react-query';
import { toast } from 'react-toastify';
import { isValidationError } from '../../../../lib/utils/errorUtils';

export interface EventFormProps {
  initialValues?: api.EventSettings;
}

export type EventSettingsForm = ReturnType<typeof useEventSettingsForm>;

type EventSettingsWithStringQuarantineAndThresholdFields = Omit<
  api.EventSettings,
  'quarantine1' | 'quarantine2' | 'threshold1' | 'threshold2'
> & {
  quarantine1: string | null;
  quarantine2: string | null;
  threshold1: string | null;
  threshold2: string | null;
};

interface UseEventSettingsFormOptions {
  sensorType: api.SensorType;
  eventType: string;

  initialValues?: api.EventSettings;

  /**
   * An optional definition of how quarantine and threshold values should be scaled.
   */
  scales?: {
    /**
     * How quarantine1 should be scaled -- `60` here means the text field will contain `1` when the real value is `60`.
     */
    quarantine1?: number;
    /**
     * How quarantine2 should be scaled -- `60` here means the text field will contain `1` when the real value is `60`.
     */
    quarantine2?: number;
    /**
     * How threshold1 should be scaled -- `60` here means the text field will contain `1` when the real value is `60`.
     */
    threshold1?: number;
    /**
     * How threshold2 should be scaled -- `60` here means the text field will contain `1` when the real value is `60`.
     */
    threshold2?: number;
  };
}

/**
 * Formik wrapper for event settings forms.
 */
export default function useEventSettingsForm(
  opts: UseEventSettingsFormOptions
) {
  const sensor = useSensorContext();
  const queryClient = useQueryClient();

  const formik = useFormik({
    initialValues: {
      audio_set: 'a',
      enabled: true,
      level: 'hidden',
      enabled_from: null,
      enabled_to: null,
      audio_enabled: null,
      audio_volume: null,
      audio_file: null,
      ...opts.initialValues, // convert here from the initial values -- but we don't know the scale!

      quarantine1:
        opts.initialValues?.quarantine1 !== null &&
        opts.initialValues?.quarantine1 !== undefined
          ? (
              Math.round(
                (opts.initialValues.quarantine1 /
                  (opts.scales?.quarantine1 ?? 1)) *
                  1000
              ) / 1000
            )
              .toString()
              .replace('.', ',')
          : null,

      quarantine2:
        opts.initialValues?.quarantine2 !== null &&
        opts.initialValues?.quarantine2 !== undefined
          ? (
              Math.round(
                (opts.initialValues.quarantine2 /
                  (opts.scales?.quarantine2 ?? 1)) *
                  1000
              ) / 1000
            )
              .toString()
              .replace('.', ',')
          : null,

      threshold1:
        opts.initialValues?.threshold1 !== null &&
        opts.initialValues?.threshold1 !== undefined
          ? (
              Math.round(
                (opts.initialValues.threshold1 /
                  (opts.scales?.threshold1 ?? 1)) *
                  1000
              ) / 1000
            )
              .toString()
              .replace('.', ',')
          : null,

      threshold2:
        opts.initialValues?.threshold2 !== null &&
        opts.initialValues?.threshold2 !== undefined
          ? (
              Math.round(
                (opts.initialValues.threshold2 /
                  (opts.scales?.threshold2 ?? 1)) *
                  1000
              ) / 1000
            )
              .toString()
              .replace('.', ',')
          : null,
    },
    enableReinitialize: true,
    validationSchema: () =>
      yup.object().shape({
        enabled: yup.boolean().required(),
        level: yup
          .string()
          .oneOf(['alert', 'alarm'])
          .required(
            t('manage.sensors.events.useEventSettingsForm.mustUseAValidLevel')
          ),
        enabled_from: yup.string().nullable(),
        enabled_to: yup.string().nullable(),

        quarantine1: yup
          .string()
          .nullable()
          .test(
            'quarantine1',
            'quarantine1 invalid',
            (v) =>
              v === null ||
              (typeof v === 'string' ? /^[0-9]+,?[0-9]*$/.test(v) : true)
          ),

        quarantine2: yup
          .string()
          .nullable()
          .test(
            'quarantine2',
            'quarantine2 invalid',
            (v) =>
              v === null ||
              (typeof v === 'string' ? /^[0-9]+,?[0-9]*$/.test(v) : true)
          ),

        threshold1: yup
          .string()
          .nullable()
          .test(
            'threshold1',
            'threshold1 invalid',
            (v) =>
              v === null ||
              (typeof v === 'string' ? /^[0-9]+,?[0-9]*$/.test(v) : true)
          ),

        threshold2: yup
          .string()
          .nullable()
          .test(
            'threshold2',
            'threshold2 invalid',
            (v) =>
              v === null ||
              (typeof v === 'string' ? /^[0-9]+,?[0-9]*$/.test(v) : true)
          ),
        audio_enabled: yup.boolean().nullable(),
        audio_volume: yup.number().integer().nullable(),
        audio_file: yup.string().nullable(),
      }),

    onSubmit: async (
      values: EventSettingsWithStringQuarantineAndThresholdFields,
      formikHelpers: FormikHelpers<EventSettingsWithStringQuarantineAndThresholdFields>
    ) => {
      try {
        const vals: api.EventSettings = {
          ...values,
          quarantine1:
            values.quarantine1 !== null
              ? Math.round(
                  parseFloat(
                    typeof values.quarantine1 === 'string'
                      ? values.quarantine1.replace(',', '.')
                      : values.quarantine1
                  ) * (opts.scales?.quarantine1 ?? 1)
                )
              : null,

          quarantine2:
            values.quarantine2 !== null
              ? Math.round(
                  parseFloat(
                    typeof values.quarantine2 === 'string'
                      ? values.quarantine2.replace(',', '.')
                      : values.quarantine2
                  ) * (opts.scales?.quarantine2 ?? 1)
                )
              : null,

          threshold1:
            values.threshold1 !== null
              ? Math.round(
                  parseFloat(
                    typeof values.threshold1 === 'string'
                      ? values.threshold1.replace(',', '.')
                      : values.threshold1
                  ) * (opts.scales?.threshold1 ?? 1)
                )
              : null,

          threshold2:
            values.threshold2 !== null
              ? Math.round(
                  parseFloat(
                    typeof values.threshold2 === 'string'
                      ? values.threshold2.replace(',', '.')
                      : values.threshold2
                  ) * (opts.scales?.threshold2 ?? 1)
                )
              : null,
        };
        await api.updateEventSettingsBySensorId(
          opts.eventType,
          sensor.id,
          vals
        );
        /**
         * After updating sensor event settings we invalidate the previous query,
         * so it fetches the latest data from server in background
         */
        const queryKey = api.QueryKeys.getEventSettingsBySensorId(sensor.id);
        queryClient.invalidateQueries(queryKey);

        toast.success(t('manage.sensors.events.useEventSettingsForm.success'));
      } catch (e) {
        if (isValidationError(e)) {
          formikHelpers.setErrors(e.response.data.errors);
        }

        toast.error(
          t('manage.sensors.events.useEventSettingsForm.anErrorOccurred')
        );
      }
    },
  });

  return formik;
}
