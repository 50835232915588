import { IconDefinition } from '../../../../components/Icon';

import * as api from '../../../../serverApi';

/**
 * The available types of marking objects from the user perspective.
 * We treat 'chair_circle' and 'chair_area' differently in the frontend,
 * while they are both stored as 'chair' in the database.
 */
export type FrontendMarkingObjectType =
  | Exclude<api.MarkingObjectType, 'chair'>
  | 'chair_circle'
  | 'chair_area';

/**
 * An array of the marking objects types that require the marking
 * object path to be enclosed, i.e. the first and last point must
 * be the same.
 */
export const enclosedObjects: FrontendMarkingObjectType[] = [
  'bed',
  'discard',
  'reflex',
  'chair_area',
];

interface MarkingObjectTypeDefinition {
  /**
   * The color of the icon/symbol/button for this marking type.
   */
  iconColor: string;

  /**
   * The color of the lines on the marking image.
   */
  markingLineColor: string;

  icon?: IconDefinition;

  // This type requires the shape/path to be enclosed, i.e. that the first and last point must
  // be at the same position. Note that the last point is typically not presented in the user interface.
  enclosed: boolean;

  // If this is true, the marking object is a single point with a diameter.
  circle?: boolean;

  /**
   * The maximum number of points in a single marking object. This is included the closing point (same coords as the first).
   */
  maxPoints: number;
}

/**
 * These define the marking objects from the user perspective. Note that 'chair'
 * is a single marking object type on the server, but it has two representations
 * here (circle or area).
 */
export const markingObjectDefinitions: {
  [key in FrontendMarkingObjectType]: MarkingObjectTypeDefinition;
} = {
  bed: {
    iconColor: '#b391ff',
    markingLineColor: '#be96ff',
    icon: 'bed-empty',
    enclosed: true,
    maxPoints: 9,
  },
  door_exit: {
    iconColor: '#afd737',
    markingLineColor: '#b7f366',
    icon: 'door-open',
    enclosed: false,
    maxPoints: 3,
  },
  discard: {
    iconColor: '#999999',
    markingLineColor: '#FFFFFF',
    icon: 'minus',
    enclosed: true,
    maxPoints: 9,
  },
  chair_circle: {
    iconColor: '#f7c744',
    markingLineColor: '#f7c744',
    icon: 'chair-empty',
    enclosed: false,
    circle: true,
    maxPoints: 9,
  },
  chair_area: {
    iconColor: '#f7c744',
    markingLineColor: '#f7c744',
    icon: 'chair-group',
    enclosed: true,
    maxPoints: 9,
  },
  bathroom: {
    iconColor: '#ffa1cf',
    markingLineColor: '#ffb8cd',
    icon: 'door-open',
    enclosed: false,
    maxPoints: 3,
  },
  reflex: {
    iconColor: '#61e3ff',
    markingLineColor: '#7ee2f4',
    icon: 'pause-card',
    enclosed: true,
    maxPoints: 9,
  },
};

/**
 * The available sizes for chair (circle) type marking objects.
 */
export const CHAIR_SIZE = {
  S: 0.7,
  M: 0.9,
  L: 1.2,
};

/**
 * Represents a point
 */
export interface Point {
  // Canvas coordinates
  h: number; //scaled coordinate [0, 160] inside canvas
  v: number; //scaled coordinate [0, 120] inside canvas

  // Evaluated 3d-space coordinates
  x?: number;
  y?: number;
  z?: number;

  // Amplitude
  a?: number;

  /**
   * Diameter of circle (in meters)
   * For chair_circle type
   */
  d?: number;

  /**
   * Extend? Only the first and last points can be extended.
   * For door_exit, door_blind and bathroom types.
   */
  e?: boolean;

  // The validation result for this point
  status?: 'ok' | 'warning' | 'reject';
}

export interface Resolution {
  w: number;
  h: number;
  ratio: number;
}

export const sensorImageResolution: Resolution = {
  w: 160,
  h: 120,
  ratio: 0.75,
};

export type MarkingObject = {
  item: number;
  type: FrontendMarkingObjectType;
  original?: api.MarkingObject;
  points: Point[];
  typeDefinition: MarkingObjectTypeDefinition;
  hasCustomDiameter?: boolean;

  /**
   * Whether this marking object is currently being saved.
   */
  saving?: boolean;

  /**
   * Whether this marking object is currently being deleted.
   */
  deleting?: boolean;
};

/**
 * Convert from the server marking object type to the frontend type with additional metadata.
 */
export function convertFromServerMarkingObjectToLocalType(
  item: api.MarkingObject
): MarkingObject {
  const frontendMarkingObjectType =
    item.type === 'chair'
      ? item.points.length === 1 && item.points[0].d !== undefined
        ? 'chair_circle'
        : 'chair_area'
      : item.type;

  let hasCustomDiameter = false;

  if (frontendMarkingObjectType === 'chair_circle') {
    if (
      !Object.keys(CHAIR_SIZE).find(
        (k) => CHAIR_SIZE[k as keyof typeof CHAIR_SIZE] === item.points[0].d
      )
    ) {
      hasCustomDiameter = true;
    }
  }

  const typeDefinition = markingObjectDefinitions[frontendMarkingObjectType];

  // If it is an enclosed object, we close the shape (the final point is not stored in the database)
  const points = typeDefinition.enclosed
    ? [...item.points, item.points[0]]
    : item.points;

  return {
    item: item.item,
    original: {
      ...item,
      points,
    },
    points,
    // We need to differ between chair_circle and chair_area
    type: frontendMarkingObjectType,
    hasCustomDiameter,
    typeDefinition,
  };
}

export function getServerMarkingObjectTypeFromLocal(
  type: FrontendMarkingObjectType
): api.MarkingObjectType {
  return type === 'chair_circle' || type === 'chair_area' ? 'chair' : type;
}

/**
 * Convert from our local marking object type to the input type the API expects.
 */
export function convertFromLocalMarkingObjectToInputType(
  item: MarkingObject
): api.MarkingObjectInput {
  return {
    type: getServerMarkingObjectTypeFromLocal(item.type),

    has_warning: undefined !== item.points?.find((p) => p.status === 'warning'),

    points: item.points.map((p) => ({
      h: p.h,
      v: p.v,
      d: p.d,
      e: p.e,
    })),
  };
}

export function loadMarkingObjectsFromOriginals(
  items: api.MarkingObject[]
): MarkingObject[] {
  if (!items || items.length === 0) {
    return [];
  }
  return items.map(convertFromServerMarkingObjectToLocalType);
}
