import { ReactComponent as RoommateLogo } from '../../images/logo-black.svg';
import { t } from '../../lib/i18n';

import './RestartMobileAppWarning.scss';

export function RestartMobileAppWarning() {
  const resetMobileDeviceState = () => {
    localStorage.removeItem('mobile-device');
    window.location.href = '/';
  };

  return (
    <div className="RestartMobileAppWarning">
      <div>
        <p className="RestartMobileAppWarning-message">
          {t('publicComponents.RestartMobileAppWarning.pleaseCloseWindow')}
        </p>
      </div>

      <RoommateLogo
        onClick={resetMobileDeviceState}
        className="RestartMobileAppWarning-logo"
      />
    </div>
  );
}
