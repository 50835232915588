import './modernizr.js';

/**
 * Polyfill for localStorage in case browser doesn't support native localStorage
 * Please note that, if application is closed or page is refreshed, data will be lost.
 */

if (!Modernizr.localstorage) {
  const mockLocalStorage = () => {
    let store: Record<string, string> = {};
    return {
      getItem(key: string) {
        return store.hasOwnProperty(key) ? store[key] : undefined;
      },
      setItem(key: string, value: string) {
        store[key] = String(value);
      },
      removeItem(key: string) {
        delete store[key];
      },
      clear() {
        store = {};
      },
      key(index: number) {
        const keys = Object.keys(store);
        return keys[index];
      },
      length: Object.keys(store).length,
    };
  };

  /**
   * Update window object localStorage property with mocked local storage
   */
  Object.defineProperty(window, 'localStorage', {
    value: mockLocalStorage(),
  });
}
