import React, { useContext } from 'react';

import { ConfirmationContextType } from './types';

const defaultContext = {
  isOpen: false,
  confirm: (text?: string) => Promise, // tslint:disable-line: no-empty
};

export const ConfirmationContext = React.createContext<ConfirmationContextType>(
  defaultContext
);

export function useConfirmation() {
  return useContext(ConfirmationContext);
}
