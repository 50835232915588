import React, { useState } from 'react';
import { RouteComponentProps } from 'react-router-dom';
import * as api from '../../../serverApi';

import {
  FormControl,
  FormControlLabel,
  RadioGroup,
  Radio,
} from '@material-ui/core';

import { useRouter } from '../../../lib/useRouter';
import { useZoneContext } from '../../../lib/ZoneContext';
import { useApiCall } from '../../../lib/useApiCall';
import { useSessionPersistedState } from '../../../lib/useSessionPersistedState';

import { UserOverview } from './UserOverview';
import { UserRoleOverview } from './UserRoleOverview';
import { IconButton } from '../../../components/IconButton';
import FilterInput from '../../../components/FilterInput/FilterInput';

import { t } from '../../../lib/i18n';

import './ListPermissions.scss';
import {
  ManageHelpDropdown,
  ManageHelpOption,
} from '../common/ManageHelpDropdown';

type SortChoice = 'desc' | 'collected';

/**
 *  Systembrukere og deres roller
 *  Oversikt over en brukers varslingsinnstillinger
 */
export function ListPermissions(props: RouteComponentProps) {
  const ctx = useZoneContext();
  const { history } = useRouter();
  const [search, setSearch] = useState('');
  const [sortChoice, setSortChoice] = useSessionPersistedState<SortChoice>(
    'ListPermission-sortChoice',
    'desc'
  );

  const userOverviewRes = useApiCall(
    api.getSortedUserRolesForZone,
    [ctx.activeZone.id],
    {
      skip: sortChoice === 'collected',
    }
  );

  const userRolesRes = useApiCall(
    api.getUserRolesForZone,
    [ctx.activeZone.id],
    { skip: sortChoice === 'desc' }
  );

  function onExport() {
    const a = document.createElement('a');
    a.href = `/api/zones/${ctx.activeZone.id}/user-roles.xlsx`;
    a.download = `user-roles-${ctx.activeZone.id}.xlsx`;
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
  }

  const isExportDisabled =
    sortChoice === 'desc'
      ? !userOverviewRes.data || userOverviewRes.data?.data.length === 0
      : !userRolesRes.data || userRolesRes.data?.data.length === 0;

  const helpDropdownOptions: ManageHelpOption[] = [
    {
      title: t('common.video.manage.addUser.title'),
      vimeoId: t('common.video.manage.addUser.vimeo'),
      type: 'video',
    },
    {
      title: t('common.video.manage.addNewRole.title'),
      vimeoId: t('common.video.manage.addNewRole.vimeo'),
      type: 'video',
    },
    {
      title: t('common.video.manage.addSharedPhone.title'),
      vimeoId: t('common.video.manage.addSharedPhone.vimeo'),
      type: 'video',
    },
    {
      title: t('common.video.manage.activationCodes.title'),
      vimeoId: t('common.video.manage.activationCodes.vimeo'),
      type: 'video',
    },
    {
      title: t('common.pdf.manage.systemAdministrator.title'),
      link: t('common.url.adminUserGuidePdf'),
      type: 'pdf',
    },
  ];

  return (
    <>
      <div className="Manage__heading-wrapper">
        <h1 className="m-0">
          {t('manage.permissions.common.serviceProvidersAndAccesses')}:{' '}
          {ctx.activeZone.name}
        </h1>
        <ManageHelpDropdown options={helpDropdownOptions} />
      </div>

      <div className="d-flex align-items-start justify-content-between flex-column flex-lg-row  my-4">
        <div>
          <IconButton
            color="primary"
            icon="plus"
            className="mb-2 mb-lg-0"
            onClick={() => history.push(props.match.path + '/new')}
            data-cy="add-new-system-user"
          >
            {t('manage.permissions.common.registerNewServiceProviderAccess')}
          </IconButton>
          <br />

          <IconButton
            color="primary"
            icon="plus"
            className="mb-2 mb-lg-0"
            onClick={() => history.push(props.match.path + '/new-impersonal')}
          >
            {t('manage.permissions.common.registerEmergencyTelephone')}
          </IconButton>
        </div>
        <div>
          <FormControl>
            <RadioGroup value={sortChoice}>
              <FormControlLabel
                control={<Radio color="primary" />}
                value="desc"
                onClick={() => setSortChoice('desc')}
                label={t(
                  'manage.permissions.ListPermissions.serviceProvidersAlphabeticalList'
                )}
                className="mb-2 mb-lg-0"
              />
              <FormControlLabel
                control={<Radio color="primary" />}
                value="collected"
                onClick={() => setSortChoice('collected')}
                label={t(
                  'manage.permissions.ListPermissions.viewListOfAccesses'
                )}
                className="mb-2 mb-lg-0"
              />
            </RadioGroup>
          </FormControl>
        </div>
        <div className="d-flex flex-column-reverse flex-lg-column">
          <FilterInput
            inputClassName="ListPermissions-actions-filter mb-2"
            onChange={(name, value) => setSearch(value)}
          />
          <div className="mb-2 mb-lg-0">
            <IconButton
              color="primary"
              icon="export"
              onClick={onExport}
              disabled={isExportDisabled}
            >
              {t('common.commonTexts.exportToExcel')}
            </IconButton>
          </div>
        </div>
      </div>

      {sortChoice === 'desc' && (
        <UserOverview
          data={userOverviewRes.data}
          loading={userOverviewRes.loading}
          search={search}
        />
      )}

      {sortChoice === 'collected' && (
        <UserRoleOverview
          data={userRolesRes.data}
          loading={userRolesRes.loading}
          search={search}
        />
      )}
    </>
  );
}
