import React, { ChangeEvent, useState, useEffect, CSSProperties } from 'react';
import cs from 'classnames';
import { InputAdornment, TextField } from '@material-ui/core';
import { faTimes, faSearch } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Icon } from '../Icon';
import { t } from '../../lib/i18n';

import './FilterInput.scss';

interface FilterInputProps {
  loading?: boolean;
  name?: string;
  label?: string;
  placeholder?: string;
  value?: string;
  className?: string;
  inputClassName?: string;
  error?: string;
  style?: CSSProperties;
  onChange: (name: string, value: string) => void;
  onBlur?: () => void;
  onFocus?: () => void;
}

const FilterInput = (props: FilterInputProps) => {
  const [keyword, setKeyword] = useState(props.value || '');
  const inputName = props.name || 'keyword';

  const onEscPress = (e: KeyboardEvent) => {
    if (e.key && e.key === 'Escape') {
      handleClear();
    }
  };

  useEffect(() => {
    if (props.value) {
      setKeyword(props.value);
    }
  }, [props.value]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    document.addEventListener('keyup', onEscPress);
    return () => {
      document.removeEventListener('keyup', onEscPress);
    };
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const handleChange = (
    e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    setKeyword(e.target.value);
    props.onChange(inputName, e.target.value);
  };

  const handleFocus = () => {
    if (props.onFocus) {
      props.onFocus();
    }
  };

  const handleBlur = () => {
    if (props.onBlur) {
      props.onBlur();
    }
  };

  const handleClear = () => {
    setKeyword('');
    props.onChange(inputName, '');
  };

  return (
    <div
      className={cs('FilterInput', props.className)}
      style={{ ...props.style }}
    >
      <TextField
        type="text"
        variant="outlined"
        label={props.label}
        placeholder={t('components.FilterInput.placeholder')}
        className={cs('FilterInput-input', props.inputClassName)}
        error={!!props.error}
        helperText={props.error || ''}
        value={keyword}
        onChange={(e) => handleChange(e)}
        onFocus={handleFocus}
        onBlur={handleBlur}
        // InputLabelProps={{
        //   shrink: false,
        // }}
        InputProps={{
          endAdornment: (
            <InputAdornment position="end">
              {props.loading ? (
                <Icon
                  icon="gear"
                  spin
                  style={{ width: 17, color: '#9b9b9b' }}
                />
              ) : keyword.length > 0 ? (
                <span
                  role="button"
                  className="FilterInput-icon FilterInput-icon-clear"
                  onClick={handleClear}
                >
                  <FontAwesomeIcon icon={faTimes} />
                </span>
              ) : (
                <span className="FilterInput-icon">
                  <FontAwesomeIcon icon={faSearch} />
                </span>
              )}
            </InputAdornment>
          ),
        }}
      />
    </div>
  );
};

export default FilterInput;
