/**
 * This hook will return true only the first time the
 * component is rendered for a given context key.
 *
 * @param context This is typically fixed, and will be used in the key in sessionStorage/localStorage
 * @param useSessionStorage Use sessionStorage instead of localStorage when this is true.
 * @param resetOnChange Whenever this parameter changes, the state will reset (the state will be overriden).
 */
export function useFirstTimeOnly(
  context: string,
  useSessionStorage?: boolean,
  resetOnChange?: string | number | boolean
) {
  const key = `useFirstTimeOnly-${context}`;

  const storage = useSessionStorage ? sessionStorage : localStorage;

  const value = `${resetOnChange || 'true'}`;

  let isFirstTime = false;

  try {
    // If failed to get storage return false
    if (storage.getItem(key) !== value) {
      isFirstTime = true;
      storage.setItem(key, value);
    }
  } catch {}

  return {
    isFirstTime,
  };
}
