import cs from 'classnames';

import './SortIcon.scss';

interface Props {
  type?: 'asc' | 'desc';
  onClick?: () => void;
}
export const SortIcon = (props: Props) => {
  return (
    <div
      className={cs('SortIcon', props.type && `SortIcon--${props.type}`)}
      onClick={props.onClick}
    >
      <ArrowSVG up />
      <ArrowSVG />
    </div>
  );
};

interface ArrowSVGI {
  className?: string;
  up?: boolean;
  disabled?: boolean;
}

/**
 * SVG arrow
 * @param up - if true, arrow points up
 * @param disabled - disables the arrow
 */
const ArrowSVG = (props: ArrowSVGI) => (
  <svg
    className={cs('ArrowSVG', props.className, props.up && 'ArrowSVG--up')}
    xmlns="http://www.w3.org/2000/svg"
    width="12"
    height="6"
    viewBox="0 0 10 5"
  >
    <path id="arrow" d="M7,10l5,5,5-5Z" transform="translate(-7 -10)" />
  </svg>
);
