import React from 'react';
import { RouteComponentProps } from 'react-router';

import { IconButton } from '../../../../components/IconButton';
import { useApiCall } from '../../../../lib/useApiCall';
import LoadingPlaceholder from '../../../../lib/ui/LoadingPlaceholder';
import { Button } from '../../../../components/Button';
import getParentPath from '../../../../lib/getParentPath';
import { renderNationalIdNumber } from '../../../../lib/renderNationalIdNumber';
import { UnassignCareReceiverButton } from './UnassignCareReceiverButton';
import { RemoveCareReceiverButton } from './RemoveCareReceiverButton';
import { ButtonGroup } from '../../../../components/ButtonGroup';
import { useRouter } from '../../../../lib/useRouter';
import { useZoneContext } from '../../../../lib/ZoneContext';
import { CareReceiverProvider } from '../lib/CareReceiverContext';
import formatUserLabel from '../../permissions/lib/formatUserLabel';
import { LabeledValue } from '../../../../components/LabeledValue';

import * as api from '../../../../serverApi';
import { t } from '../../../../lib/i18n';

import './CareReceiverDetails.scss';
import { useAccount } from '../../../../lib/useAccount';

type Props = RouteComponentProps<{ careReceiverId: string }>;

export function CareReceiverDetails(props: Props) {
  const router = useRouter();
  const zoneCtx = useZoneContext();
  const account = useAccount();

  const res = useApiCall(api.getCareReceiverDetails, [
    parseInt(props.match.params.careReceiverId, 10),
  ]);

  const isSingleAssociation = true;

  const handleOnRemove = () => {
    zoneCtx.reload();
    props.history.push(getParentPath(props.location.pathname));
  };

  if (res.loading) {
    return <LoadingPlaceholder heading={t('common.commonTexts.loading')} />;
  }

  if (!res.data) {
    return null;
  }

  const careReceiver = res.data;

  return (
    <CareReceiverProvider
      value={
        careReceiver
          ? {
              id: careReceiver.care_receiver_id,
              firstName: careReceiver.first_name ?? '',
              lastName: careReceiver.last_name ?? '',
            }
          : undefined
      }
    >
      <div className="CareReceiverDetails">
        <h1>
          {t('manage.careReceivers.common.recipient')}{' '}
          {careReceiver?.first_name || ''} {careReceiver.last_name || ''}
        </h1>

        <div className="d-flex align-items-start justify-content-between my-4">
          <div>
            <IconButton
              icon="arrow-left"
              to={getParentPath(props.location.pathname)}
            >
              {t('common.commonButtons.back')}
            </IconButton>
          </div>
        </div>

        <div className="CareReceiverDetails-wrapper">
          {!account.disable_national_id_numbers && (
            <LabeledValue label={t('common.commonTexts.dateOfBirthday')}>
              {renderNationalIdNumber(careReceiver?.national_id_number) || (
                <span style={{ fontWeight: 400 }}>
                  {t('manage.careReceivers.notRegistered')}
                </span>
              )}
            </LabeledValue>
          )}

          <LabeledValue
            label={t('common.commonTexts.firstNameAndMiddleName')}
            dataCy="manage-care-receiver-details-firstname"
          >
            {careReceiver?.first_name || (
              <span style={{ fontWeight: 400 }}>
                {t('common.commonTexts.notRegistered')}
              </span>
            )}
          </LabeledValue>

          <LabeledValue
            label={t('common.commonTexts.surname')}
            dataCy="manage-care-receiver-details-lastname"
          >
            {careReceiver?.last_name || (
              <span style={{ fontWeight: 400 }}>
                {' '}
                {t('common.commonTexts.notRegistered')}
              </span>
            )}
          </LabeledValue>

          <LabeledValue label={t('common.commonTexts.housingUnit')}>
            {careReceiver?.zone ? (
              <span>{careReceiver.zone.name}</span>
            ) : (
              <em>
                {t('manage.careReceivers.ui.CareReceiversTable.noZoneAttached')}
              </em>
            )}
          </LabeledValue>

          {careReceiver.blocked_users && (
            <LabeledValue
              label={t('manage.careReceivers.common.superVisionReservations')}
              dataCy="manage-care-receiver-details-blocked-users"
            >
              {careReceiver.blocked_users.length === 0 ? (
                <>{t('common.commonTexts.noItemsInTheList')}</>
              ) : (
                careReceiver.blocked_users.map((user) => (
                  <div key={user.blocked_user_id}>{formatUserLabel(user)}</div>
                ))
              )}
            </LabeledValue>
          )}
        </div>

        <ButtonGroup>
          <Button
            color="primary"
            to={`${props.location.pathname}/edit`}
            data-cy="manage-edit-care-receiver"
          >
            {t('common.commonButtons.edit')}
          </Button>

          <Button color="default" to={getParentPath(props.location.pathname)}>
            {t('common.commonButtons.back')}
          </Button>

          <UnassignCareReceiverButton
            zoneId={careReceiver?.zone?.id}
            buttonLabel={t('common.commonTexts.disable')}
            onChanged={() => {
              zoneCtx.reload();
              router.history.push(getParentPath(router.location.pathname));
            }}
          />

          <RemoveCareReceiverButton
            isSingleAssociation={isSingleAssociation}
            onRemove={handleOnRemove}
          />
        </ButtonGroup>
      </div>
    </CareReceiverProvider>
  );
}
