import React from 'react';
import * as api from '../serverApi';
import { StatusMarker } from './ui/StatusMarker';
import * as df from 'date-fns';

const renderSensorActivity = (sensor: api.SuperVendorSensor) => {
  if (sensor.is_online) {
    // Sensor is active
    return <StatusMarker data-sort="success" status="success" />;
  } else if (sensor.on_time) {
    // Sensor is inactive
    return df.formatDistanceToNow(df.parseISO(sensor.on_time));
  } else {
    // Sensor has no data yet.
    return 'No data';
  }
};

export default renderSensorActivity;
