import React from 'react';
import { Link } from 'react-router-dom';

import * as serverApi from '../../../serverApi';
import { useApiCall } from '../../../lib/useApiCall';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faSyncAlt,
  faSpinnerThird,
  faUser,
} from '@fortawesome/pro-solid-svg-icons';

import { useSuperStyles } from '../useSuperStyles';
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Button,
} from '@material-ui/core';
import { TableWrapper } from '../../../components/TableWrapper';
import { HeadingBar } from '../shared/HeadingBar';
import { Card } from '../shared/Card';

export function ListCareReceivers() {
  const res = useApiCall(serverApi.superGetCareReceivers);
  const c = useSuperStyles();

  if (res.error) {
    return <span>Error!</span>;
  }

  const careReceivers = res && res.data && res.data.data ? res.data.data : [];

  return (
    <>
      <HeadingBar>
        <h1>Care receivers</h1>

        <div
          style={{
            height: '25px',
            width: '25px',
            fontSize: '25px',
            lineHeight: '25px',
          }}
        >
          {res.loading && <FontAwesomeIcon icon={faSpinnerThird} spin={true} />}
        </div>

        <Button onClick={res.reload} disabled={res.loading}>
          <FontAwesomeIcon
            icon={faSyncAlt}
            spin={res.loading}
            className="mr-2"
          />
          Refresh
        </Button>
      </HeadingBar>
      <Card>
        <TableWrapper>
          <Table className={c.table}>
            <TableHead>
              <TableRow>
                <TableCell>ID</TableCell>
                <TableCell>First name</TableCell>
                <TableCell>Last name</TableCell>
                <TableCell>Account</TableCell>
                <TableCell>&nbsp;</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {!res.loading && !careReceivers && (
                <tr>
                  <td
                    colSpan={5}
                    style={{ fontSize: '32px', textAlign: 'center' }}
                  >
                    No data
                  </td>
                </tr>
              )}

              {careReceivers.map((cr) => (
                <TableRow key={cr.care_receiver_id}>
                  <TableCell component="th" scope="row">
                    {cr.care_receiver_id}
                  </TableCell>
                  <TableCell>{cr.first_name || ''}</TableCell>
                  <TableCell>{cr.last_name || ''}</TableCell>
                  <TableCell>{cr.account_name}</TableCell>
                  <TableCell>
                    <Button
                      component={Link}
                      to={`/super/care-receivers/${cr.care_receiver_id}`}
                    >
                      <FontAwesomeIcon className="mr-2" icon={faUser} />
                      Vis
                    </Button>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableWrapper>
      </Card>
    </>
  );
}
