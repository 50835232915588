import React from 'react';
import { Form } from 'reactstrap';
import cs from 'classnames';

import * as api from '../../serverApi';

import {
  FormGroup,
  TextField,
  InputAdornment,
  Button,
} from '@material-ui/core';
import { t } from '../../lib/i18n';
import { IconButton } from '../../components/IconButton';
import { renderDeviceTitle } from './OneTimeCode';

import './OneTimeCodeSubmitForm.scss';
import { generateAutoCompleteValue } from '../../lib/utils/generateAutoCompleteValue';

interface Props {
  code?: string;
  onChangeCode?: (value: string) => void;
  className?: string;
  errorMessage?: string;
  onSubmit?: (event: React.FormEvent<HTMLFormElement>) => void;
  selectedDevice?: api.OtpDevice;
  loading?: boolean;
  onRequestNewCode?: () => void;
}

export function OneTimeCodeSubmitForm(props: Props) {
  return (
    <div className={cs('OneTimeCodeSubmitForm', props.className)}>
      <p className="OneTimeCodeSubmitForm-device ml-1">
        {props.selectedDevice &&
          `${t('publicComponents.OneTimeCode.codeSendtTo')} ${renderDeviceTitle(
            props.selectedDevice
          )}`}
      </p>
      <Form onSubmit={props.onSubmit}>
        <FormGroup>
          <TextField
            autoComplete={generateAutoCompleteValue()}
            autoFocus={true}
            className="OneTimeCodeSubmitForm-input mt-2"
            type="text"
            name="code"
            data-cy="otp-code"
            disabled={props.loading}
            placeholder={t('publicComponents.OneTimeCode.enterYourCode')}
            error={!!props.errorMessage}
            helperText={props.errorMessage}
            value={props.code || ''}
            onChange={(e) => props.onChangeCode?.(e.target.value)}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  {
                    <IconButton
                      spinIcon={props.loading}
                      type="submit"
                      icon={props.loading ? 'gear' : 'arrow-right'}
                      className="OneTimeCodeSubmitForm-icon"
                    />
                  }
                </InputAdornment>
              ),
            }}
          />

          <Button
            variant="text"
            href="#"
            type="button"
            className="mt-2 OneTimeCodeSubmitForm-new-code"
            onClick={() => props.onRequestNewCode?.()}
          >
            {t('publicComponents.OneTimeCode.requestNewCode')}
          </Button>
        </FormGroup>
      </Form>
    </div>
  );
}
