import { createContext } from 'react';
import * as api from '../../serverApi';

export interface AppContext {
  isAuthenticated: boolean;
  user?: api.User;
  isLoading: boolean;
  isLoggingIn?: boolean;
  setActiveRole: (roleId: number) => Promise<boolean>;
  setOneTimeCodeProvided: () => void;
  error?: string | { [key: string]: string };
  login: (user: api.User) => void;
  requestLogout: (redirect?: string | false) => Promise<void>;
  hasCapability: (capability: api.CapabilityType) => boolean;
  hasCapabilityInAnyRole: (capability: api.CapabilityType) => boolean;
  hasFeature: (feature: api.FeatureFlagKey) => boolean;
  getFeatureValue: (
    feature: api.FeatureFlagKey
  ) => undefined | null | number | string | boolean;
  getFeatureValueNumber: (feature: api.FeatureFlagKey) => number | undefined;
  updateUser?: (user?: api.User) => void;
  hasAppOtpCode?: boolean;
}

export const appContext = createContext<AppContext | undefined>(undefined);
