import { useState, useEffect } from 'react';
import { QueryClient, useQueryClient } from 'react-query';
import { FormikHelpers, useFormik } from 'formik';

import * as api from '../../../../../../serverApi';
import * as yup from 'yup';
import { useZoneContext } from '../../../../../../lib/ZoneContext';
import { useApiCall } from '../../../../../../lib/useApiCall';
import { useErrorHandler } from '../../../../../../lib/useErrorHandler';
import { isAxiosError } from '../../../../../../lib/utils/errorUtils';

export interface ZoneSettingsFormProps {
  initialValues?: api.ZoneSettings;
}

export type ZoneSettingsForm = ReturnType<typeof useZoneSettingsForm>;

interface UseZoneSettingsFormOptions {
  // initialValues?: Partial<api.ZoneSettings>;
  fields: Array<keyof api.ZoneSettings>;
}

/**
 * Formik and business logic wrapper for zone settings.
 */
export default function useZoneSettingsForm(opts: UseZoneSettingsFormOptions) {
  const zoneContext = useZoneContext();
  const queryClient = useQueryClient();
  const structureQueryKey = api.QueryKeys.getStructure();

  /**
   * These are the initial values for the formik form. The form is
   * reinitialized when this changes. We typically only have two different values:
   * The initial values when we first create the component, and the actual
   * settings received from the server when the server call returns.
   */
  const [initialValues, setInitialValues] = useState<Partial<api.ZoneSettings>>(
    {}
  );
  const [ready, setReady] = useState(false);
  const [saving, setSaving] = useState(false);
  const data = useApiCall(api.getZoneSettings, [zoneContext.activeZone.id]);
  const errorHandler = useErrorHandler();

  /**
   * The inheritance field is the field we use to evaluate whether
   * the form should be set in "inherited" state or "local" state.
   *
   * Some forms contain multiple fields, we always just use the first.
   */
  const inheritanceField = opts.fields[0];

  useEffect(() => {
    if (!data.loading && data.data) {
      setReady(true);
      setInitialValues(
        opts.fields.reduce((coll, key) => {
          if (data.data) {
            // TS doesn't understand that all the [key] elements use the same base value.
            coll[key] = data.data[key].value as any;
          }
          return coll;
        }, {} as Partial<api.ZoneSettings>)
      );
    }
  }, [data.data, data.loading, opts.fields]);

  const inherited = data.data
    ? Object.keys(data.data).reduce((coll, key: unknown) => {
        const k = key as keyof api.ZoneSettings;
        if (data.data) {
          coll[k] = data.data[k].value === null;
        }
        return coll;
      }, {} as { [P in keyof api.ZoneSettings]: boolean })
    : undefined;

  const formik = useFormik<Partial<api.ZoneSettings>>({
    enableReinitialize: true,

    initialValues,

    validationSchema: () =>
      // prettier-ignore
      yup.object().shape({
        care_observation_image_detailed: yup.boolean().nullable(),
        care_observation_image_anonymised: yup.boolean().nullable(),
        care_event_image_detailed: yup.boolean().nullable(),
        care_event_image_anonymised: yup.boolean().nullable(),
        stream_indicator_led: yup.boolean().nullable(),
        stream_indicator_audio: yup.boolean().nullable(),
        enable_twoway_audio: yup.boolean().nullable(),
        enable_oneway_audio: yup.boolean().nullable(),
        enable_oneway_audio_anonymised: yup.boolean().nullable(),
        max_call_duration_twoway_audio: yup.number().integer().nullable(),
        max_call_duration_oneway_audio: yup.number().integer().nullable(),
        max_call_duration_oneway_audio_anonymised: yup.number().integer().nullable(),
        alert_reminder_count: yup.number().integer().nullable(),
        alert_reminder_interval: yup.number().integer().nullable(),
        reflex_presence_timeout: yup.number().integer().nullable(),
        observation_stream_timeout: yup.number().integer().nullable(),
        vkp_enabled: yup.boolean().nullable(),
        sensor_display_area_format: yup.string().nullable(),
        sensor_display_name_format: yup.string().nullable(),
        enable_behavioral_data: yup.boolean().nullable(),
        timezone: yup.string().nullable(),
      }),

    onSubmit: async (
      values: Partial<api.ZoneSettings>,

      formikHelpers: FormikHelpers<Partial<api.ZoneSettings>>
    ) => {
      // try {
      errorHandler.reset();
      setSaving(true);
      try {
        await api.updateZoneSettings(zoneContext.activeZone.id, values);

        const zoneSettingsValuesObject = {} as api.GetSensorsResult;
        Object.keys(values).forEach((itemKey) => {
          const key = itemKey as keyof Partial<api.GetSensorsResult>;
          const value = values[key] as any;

          zoneSettingsValuesObject[key] = {
            inherited_value: null,
            inherited_from: null,
            value: value,
          };
        });

        //Update fields for activeZoneSettings state
        zoneContext.activeZoneSettings &&
          zoneContext.setActiveZoneSettings({
            ...zoneContext.activeZoneSettings,
            ...zoneSettingsValuesObject,
          });

        //Update cache for structure query

        queryClient.setQueryData(structureQueryKey, () =>
          updateStructureCache(
            queryClient,
            structureQueryKey,
            zoneSettingsValuesObject,
            zoneContext.activeZone.id
          )
        );
      } catch (e) {
        errorHandler.handleError(e);
        if (isAxiosError(e)) {
          formikHelpers.setFieldError(
            inheritanceField,
            e.response?.data?.message
          );
        }
      }
      data.reload();
      setSaving(false);
    },
  });

  /**
   * Display values are either the actual value, when it's not inherited,
   * or the inherited value. This is used to display the correct value in the form
   * whether inherit or not is enabled.
   */
  const displayValues = data.data
    ? opts.fields.reduce((coll, key) => {
        if (data.data) {
          // TS doesn't understand that all the [key] elements use the same base value.
          coll[key] = (formik.values[key] ??
            data.data[key].inherited_value) as any;
        }
        return coll;
      }, {} as Partial<api.ZoneSettings>)
    : undefined;

  const isInherited =
    formik.values[inheritanceField] === undefined
      ? undefined
      : formik.values[inheritanceField] === null;

  const isRootZone = data.data
    ? data.data[inheritanceField].inherited_from === null
    : undefined;

  function setInherited(inherit?: boolean) {
    if (inherit === true) {
      opts.fields.forEach((f) => {
        formik.setFieldValue(f, null);
        formik.setFieldTouched(f);
      });
    } else if (inherit === false) {
      opts.fields.forEach((f) => {
        if (data.data) {
          const field = data.data[f];
          const value =
            field.value === null ? field.inherited_value : field.value;

          formik.setFieldValue(f, value);
          formik.setFieldTouched(f);
        }
      });
    }
  }

  /**
   * When inheriting values, this is the zone we are inheriting from.
   */
  const inheritedFromZone = data.data
    ? data.data[inheritanceField].inherited_from
    : undefined;

  function cancel(e: React.SyntheticEvent) {
    formik.handleReset(e);
  }

  return {
    // Whether the current value is inherited
    isInherited,
    cancel,
    inheritedFromZone,
    loading: data.loading || saving,
    error: errorHandler.error,
    hasChanged: formik.dirty,
    disabled: isInherited !== false,
    isRootZone,
    formik,
    inherited,
    ready,
    setInherit: setInherited,
    displayValues,
  };
}

function updateStructureCache(
  queryClient: QueryClient,
  queryKey: string[],
  updatedFields: api.GetSensorsResult,
  activeZoneId: number
) {
  const previousData = queryClient.getQueryData<api.GetStructureResult>(
    queryKey
  );
  let updatedFieldsForStructure = {} as api.ZoneSettings;

  Object.keys(updatedFields).forEach((field) => {
    const key = field as keyof api.ZoneSettings;
    updatedFieldsForStructure[key] = updatedFields[key].value as any;
  });
  //Check if the updated zone is the root zone
  if (previousData?.zone.id === activeZoneId) {
    return {
      zone: {
        ...previousData.zone,
        zone_settings: {
          ...previousData.zone.zone_settings,
          ...updatedFieldsForStructure,
        },
      },
    };
  }
  //If not, find the zone by id and update it
  return {
    zone: {
      ...previousData?.zone,
      zones: previousData?.zone.zones?.map((zone) =>
        zone.id === activeZoneId
          ? {
              ...zone,
              zone_settings: {
                ...zone.zone_settings,
                ...updatedFieldsForStructure,
              },
            }
          : zone.zones
          ? {
              ...zone,
              zones: zone.zones.map((nestedZone) =>
                nestedZone.id === activeZoneId
                  ? {
                      ...nestedZone,
                      zone_settings: {
                        ...nestedZone.zone_settings,
                        ...updatedFieldsForStructure,
                      },
                    }
                  : nestedZone
              ),
            }
          : zone
      ),
    },
  };
}
