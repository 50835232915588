import React from 'react';
import {
  FormControl,
  FormGroup,
  FormControlLabel,
  Checkbox,
} from '@material-ui/core';

import { EventSettingsForm } from '../useEventSettingsForm';
import { ErrorComponent } from '../../../../../components/forms/shared/ErrorComponent';
import { t } from '../../../../../lib/i18n';
import { GreenCircleIndicator } from '../../../common/ManageSidebar';

export default function EventActiveControl(props: { form: EventSettingsForm }) {
  const { form } = props;

  const fieldHasChanged = form.values.enabled !== form.initialValues.enabled;
  return (
    <FormGroup className="mt-4 d-inline-flex">
      <FormControl>
        <FormControlLabel
          control={
            <Checkbox
              checked={form.values.enabled}
              value={form.values.enabled}
              onChange={(e) => {
                form.setFieldValue('enabled', e.currentTarget.checked);
                form.setFieldTouched('enabled');
              }}
              color="primary"
            />
          }
          label={t(
            'manage.sensors.events.controls.EventActiveControl.enableAlert'
          )}
        />
        {form.errors.enabled && <ErrorComponent msg={form.errors.enabled} />}
        {fieldHasChanged && <GreenCircleIndicator style={{ right: '-15px' }} />}
      </FormControl>
    </FormGroup>
  );
}
