import React, { useState } from 'react';

import { useConfirmation } from '../../../../lib/confirmation/ConfirmationContext';
import { Button } from '../../../../components/Button';

import * as api from '../../../../serverApi';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUserTimes } from '@fortawesome/pro-solid-svg-icons';
import { useOptionalCareReceiverContext } from '../lib/CareReceiverContext';
import { useErrorHandler } from '../../../../lib/useErrorHandler';
import { ErrorMessages } from '../../../../components/ErrorMessages';
import { t } from '../../../../lib/i18n';

interface Props {
  zoneId?: number;
  className?: string;
  onChanged?: () => void;
  buttonLabel?: string;
}

// export const UnassignCareReceiverButton = (props: Props) => {
//   const confirmation = useConfirmation();
//
//   const [unassigning, setUnassigning] = useState(false);
//
//   const onClick = async () => {
//     confirmation
//       .confirm()
//       .then(async () => {
//         if (props.zoneId) {
//           setUnassigning(true);
//           try {
//             await api.unassignCareReceiver(props.zoneId);
//
//             setUnassigning(false);
//             if (props.onChanged) {
//               props.onChanged();
//             }
//           } catch (error) {
//             setUnassigning(false);
//           }
//         }
//       })
//       .catch(() => {});
//   };
//
//   return (
//     <Button
//       className={props.className}
//       disabled={unassigning}
//       onClick={onClick}
//       icon={unassigning ? 'gear' : undefined}
//       spinIcon={unassigning}
//     >
//       {props.buttonLabel || 'Inaktivere'}
//     </Button>
//   );
// };

export function UnassignCareReceiverButton(props: Props) {
  const confirmation = useConfirmation();
  const errorHandler = useErrorHandler();
  const careReceiver = useOptionalCareReceiverContext();
  const [unassigning, setUnassigning] = useState(false);

  if (!careReceiver) {
    return null;
  }

  const onClick = async () => {
    confirmation
      .confirm(t('common.commonTexts.toConfirmInactivationPressOk'))
      .then(async () => {
        if (props.zoneId) {
          setUnassigning(true);
          try {
            errorHandler.reset();
            await api.updateCareReceiver(careReceiver.id, {
              zone_id: null,
            });
            setUnassigning(false);
            if (props.onChanged) {
              props.onChanged();
            }
          } catch (error) {
            errorHandler.handleError(error);
            setUnassigning(false);
          }
        }
      })
      .catch(() => {});
  };

  return (
    <>
      <Button
        className={props.className}
        icon={unassigning ? 'gear' : undefined}
        disabled={unassigning}
        spinIcon={unassigning}
        onClick={onClick}
      >
        {props.buttonLabel || (
          <>
            <FontAwesomeIcon icon={faUserTimes} className="mr-2" />
            {t(
              'manage.careReceivers.details.UnassignCareReceiverButton.unlink'
            )}
          </>
        )}
      </Button>
      <ErrorMessages tag="span" errorData={errorHandler} />
    </>
  );
}
