type Points = Array<{
  h: number;
  v: number;
}>;

interface Detection {
  points: Points;
  type: string;
}

/**
 * Get the centroid (h/v average) of the given points, used to place the label in the polygon.
 * @param points
 * @returns The centroid of the given points
 */
function getCentroid(points: Points): {
  h: number;
  v: number;
} {
  const totalPoints = points.length;
  const sum = points.reduce(
    (acc, point) => {
      acc.h += point.h;
      acc.v += point.v;
      return acc;
    },
    { h: 0, v: 0 }
  );

  return {
    h: sum.h / totalPoints,
    v: sum.v / totalPoints,
  };
}

/**
 * Return the positions of the label and controls for the given detection
 */
export function getPositions(detection: Detection) {
  if (detection.points.length === 1) {
    /**
     * Move the reference point away from the edge of the canvas
     * to avoid the label/controls being cropped
     */
    const referencePoint = {
      h: Math.min(Math.max(detection.points[0].h, 13), 147),
      v: Math.max(detection.points[0].v, 14),
    };
    return {
      label: {
        h: referencePoint.h,
        v: referencePoint.v - 5,
      },
      controls: {
        h: referencePoint.h,
        v: referencePoint.v - 10,
      },
    };
  }

  const centroid = getCentroid(detection.points);

  return {
    label: {
      h: centroid.h,
      v: centroid.v,
    },
    controls: {
      h: centroid.h,
      v: centroid.v - 5,
    },
  };
}
