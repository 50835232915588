import React, { useEffect, useState } from 'react';
import { Redirect, Link } from 'react-router-dom';
import { Row, Col } from 'reactstrap';

import qs from 'query-string';

import { useAppContext } from '../../lib/global';
import { useRouter } from '../../lib/useRouter';

import { PublicHeader } from './PublicHeader';
import { SystemMessages } from './SystemMessages';
import { LayoutWithBackground } from './LayoutWithBackground';
import { CenteredCard } from './CenteredCard';
import { PublicFooter } from './PublicFooter';
import { FormGroup, TextField } from '@material-ui/core';

import { t } from '../../lib/i18n';
import { getDefaultAccountCode } from '../../lib/persistDefaultAccountCode';
import { DevelopmentQuickDemoLogins } from './DevelopmentQuickDemoLogins';
import { RestartMobileAppWarning } from './RestartMobileAppWarning';
import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined';

import * as api from '../../serverApi';
import { useLocalStorageState } from '../care/lib/useLocalStorageState';

import './Login.scss';
import {
  isInvalidCredentialsError,
  isInvalidIpAddressError,
  isRateLimiterError,
  isSessionExpiredError,
  isValidationError,
} from '../../lib/utils/errorUtils';
import { LoginFields, useLoginFormik } from '../../lib/useLoginFormik';

export function Login() {
  const router = useRouter();
  const values = qs.parse(router.location.search);

  const [isApp, setIsApp] = useLocalStorageState<boolean | undefined>(
    'mobile-device',
    undefined
  );

  const app = useAppContext();
  //Specific error in this case means invalid credentials, session expired or rate limiter error on login call
  const [specificError, setSpecificError] = useState('');

  const form = useLoginFormik({
    initialValues: {
      account: values.account
        ? values.account.toString()
        : getDefaultAccountCode() ?? '',
    },
    onSubmit: async (values, formHelpers) => {
      await login(values);
    },
  });

  useEffect(() => {
    /**
     * If have the app parameter here, we assume we got this from the
     * backend, and that we are currently running in a mobile app webview.
     */
    if (values.app) {
      setIsApp(true);
    }
  }, [values, setIsApp]);

  if (app.isAuthenticated) {
    const redirect =
      !values.redirect || values.redirect.toString() === '//'
        ? '/'
        : values.redirect.toString();

    if (values.skipRoleSelect === '1' || app.user?.active_role) {
      return <Redirect to={redirect} />;
    } else {
      const url =
        redirect === '/' || redirect === '/roles'
          ? '/roles'
          : `/roles?redirect=${encodeURIComponent(redirect)}`;

      return <Redirect to={url} />;
    }
  }

  /**
   * If we are NOT authenticated, but we ARE running in a mobile app, but we do NOT have
   * an OTP code (i.e. this is not the initial, push-notification-triggered login after
   * clicking the supervision button in the app)
   */
  if (isApp && !app.hasAppOtpCode && !app.isAuthenticated) {
    return <RestartMobileAppWarning />;
  }
  const login = async (loginValues: LoginFields) => {
    try {
      /**
       * Remove timeout parameter from URL if present, assuming that since the user trying to log in,
       * it has already interacted with the app, therefore is no longer idle.
       */
      if (values.timeout) {
        const searchParams = new URLSearchParams(router.location.search);
        searchParams.delete('timeout');
        router.history.replace({ search: searchParams.toString() });
      }

      setSpecificError('');

      const { user } = await api.login({
        ...loginValues,
        redirect:
          typeof values.redirect === 'string' ? values.redirect : undefined,
      });

      app.login(user);
    } catch (e) {
      /***
       * 422 Unprocessable Entity
       * If it's a validation error, we update the formik errors state with error fields coming from server.
       */
      if (isValidationError(e)) {
        form.setErrors(e.response.data.errors);
        return;
      }

      /**
       * 401 Unauthorized
       * Invalid credentials, regular login failed issue. Avoiding
       * using handleError for this to avoid passing this issue over
       * to Sentry.
       */
      if (isInvalidCredentialsError(e)) {
        setSpecificError(
          t('common.serverError.auth-login-failed-invalid-credentials')
        );
        return;
      }
      // 401 due to invalid IP address/whitelist mismatch
      if (isInvalidIpAddressError(e)) {
        setSpecificError(
          t('common.serverError.auth-login-failed-no-passing-ip-whitelists')
        );
        return;
      }

      /**
       * 419 session expired
       * Typically CSRF token verification failure or some session expiration caching issue.
       * We simply force a full reload and let the user log in again.
       */
      if (isSessionExpiredError(e)) {
        window.location.reload();
        return;
      }

      /**
       * 429 Too Many Requests
       * Rate limiter; too many failed login attempts.
       */
      if (isRateLimiterError(e)) {
        setSpecificError(
          t(
            'publicComponents.Login.youHaveTriedToLogInTooManyTimesPleaseWaitTenSecondsBeforeTryingAgain'
          )
        );
        return;
      }

      /**
       * If none of the above conditions match, we just let the error bubble up.
       */
      throw e;
    }
  };

  return (
    <div className="App App-public" id="app">
      <div className="App-body" id="appBody">
        <LayoutWithBackground>
          <div className="Login">
            <PublicHeader />
            <CenteredCard>
              {values.timeout && (
                <div className="Login-idle-timeout">
                  <InfoOutlinedIcon />
                  <p>{t('user.IdleTimeout.loggedOutDueToInactivity')}</p>
                </div>
              )}

              <h1>{t('publicComponents.Login.roomMateLogin')}</h1>
              <div className="mt-4">
                <Row>
                  <Col lg="10">
                    {specificError && (
                      <div className="Login-specific-error mb-2">
                        {specificError}
                      </div>
                    )}

                    <form onSubmit={form.handleSubmit}>
                      <FormGroup>
                        <TextField
                          type="text"
                          name="account"
                          placeholder={t('publicComponents.common.account')}
                          className="w-100"
                          InputProps={{
                            className: 'text-field-input',
                          }}
                          value={form.values.account}
                          error={!!form.errors.account && form.touched.account}
                          helperText={
                            form.touched.account &&
                            form.errors.account &&
                            form.errors.account
                          }
                          onChange={form.handleChange}
                          disabled={!!values.account}
                        />
                      </FormGroup>
                      <FormGroup>
                        <TextField
                          type="text"
                          name="username"
                          placeholder={t('common.commonTexts.userName')}
                          value={form.values.username}
                          className="w-100"
                          InputProps={{
                            className: 'text-field-input',
                          }}
                          error={
                            !!form.errors.username && form.touched.username
                          }
                          helperText={
                            form.touched.username &&
                            form.errors.username &&
                            form.errors.username
                          }
                          onChange={form.handleChange}
                        />
                      </FormGroup>
                      <FormGroup>
                        <TextField
                          type="password"
                          name="password"
                          placeholder={t('common.commonInputs.password')}
                          value={form.values.password}
                          className="w-100"
                          InputProps={{
                            className: 'text-field-input',
                          }}
                          error={
                            !!form.errors.password && form.touched.password
                          }
                          helperText={
                            form.touched.password &&
                            form.errors.password &&
                            form.errors.password
                          }
                          onChange={form.handleChange}
                        />
                      </FormGroup>
                      <div className="mt-3">
                        <button
                          type="submit"
                          className="btn btn-default btn-login btn-green"
                          disabled={app.isLoading || form.isSubmitting}
                        >
                          {form.isSubmitting
                            ? `${t('common.commonTexts.pleaseWait')} ...`
                            : t('common.commonTexts.logIn')}
                        </button>
                      </div>
                    </form>
                  </Col>
                </Row>
              </div>

              <p className="my-3">
                <Link to="/forgot-password">
                  {t('publicComponents.Login.needANewPassword')}?
                </Link>
              </p>
              <p></p>
              <hr
                className="mt-5"
                style={{ borderTop: '2px solid rgba(0, 0, 0, 0.1)' }}
              />

              <SystemMessages />

              {process.env.NODE_ENV === 'development' && (
                <DevelopmentQuickDemoLogins requestLogin={login} />
              )}
            </CenteredCard>
            <PublicFooter />
          </div>
        </LayoutWithBackground>
      </div>
    </div>
  );
}
