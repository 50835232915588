import { GroupedChangesFormatter } from '../formatChanges';

const formatDefault: GroupedChangesFormatter<'default', unknown> = (
  acl,
  cg
) => {
  return {
    auditChangeLogEntry: acl,
    groupedChanges: cg,
    type: 'default',
    formattedChanges: {},
  };
};

export default formatDefault;
