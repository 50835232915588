import React, { ReactNode, useEffect } from 'react';
import { Modal, ModalBody, ModalFooter } from 'reactstrap';
import cs from 'classnames';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimesCircle } from '@fortawesome/pro-light-svg-icons';
import { faExclamationCircle } from '@fortawesome/pro-solid-svg-icons';
import { Button } from '../Button';
import { t } from '../../lib/i18n';

import './ModalWrapper.scss';

export interface ModalWrapperProps {
  title?: string;
  underTitle?: string;
  size?: 'lg' | 'md' | 'sm';
  showExclamation?: boolean;
  okButtonText?: string;
  okButtonColor?: 'default' | 'primary' | 'secondary';
  okButtonClassname?: string;
  cancelButtonText?: string;
  cancelButtonClassname?: string;
  className?: string;
  showOkButton?: boolean;
  showCancelButton?: boolean;
  showCloseButton?: boolean;
  showFooter?: boolean;
  fade?: boolean;
  isOpen: boolean;
  centered?: boolean;
  loading?: boolean;
  disableSubmit?: boolean;
  children?: ReactNode;
  onSubmit?: () => any;
  onHide: () => void;
  onResetModal?: () => void;
  onOpened?: () => void;
  onToggle?: () => void;
  staticBackdrop?: boolean;
}

export default function ModalWrapper(props: ModalWrapperProps) {
  useEffect(() => {
    if (props.onResetModal) {
      props.onResetModal();
    }
  }, [props.isOpen]); // eslint-disable-line react-hooks/exhaustive-deps

  function onSubmit() {
    props.onSubmit && props.onSubmit();
  }

  function onHide() {
    props.onHide();
  }

  function onToggle() {
    props.onToggle ? props.onToggle() : props.onHide();
  }

  function handleOpened() {
    if (props.onOpened) {
      props.onOpened();
    }
  }

  return (
    <Modal
      fade={props.fade !== false}
      size={props.size || 'md'}
      isOpen={props.isOpen}
      centered={props.centered || true}
      toggle={onToggle}
      className={cs('ModalWrapper', props.className)}
      contentClassName="ModalWrapper-content"
      onOpened={handleOpened}
      backdrop={props.staticBackdrop ? 'static' : undefined}
    >
      <div className="ModalWrapper-header">
        <div className="ModalWrapper-title-wrapper">
          {props.showExclamation && (
            <div className="exclamation">
              <FontAwesomeIcon icon={faExclamationCircle} />
            </div>
          )}
          <div className="title">
            <h4 className="ModalWrapper-title">{props.title || ''}</h4>
            {props.underTitle && (
              <h4 className="ModalWrapper-title">{props.underTitle}</h4>
            )}
          </div>
        </div>
        {props.showCloseButton !== false && (
          <div className="ModalWrapper-close">
            <button className="ModalWrapper-close-btn" onClick={onToggle}>
              <span>{t('components.ModalWrapper.close')}</span>
              <FontAwesomeIcon icon={faTimesCircle} />
            </button>
          </div>
        )}
      </div>

      <ModalBody className="ModalWrapper-body">{props.children}</ModalBody>

      {props.showFooter !== false && (
        <ModalFooter className="ModalWrapper-footer">
          {props.showOkButton !== false && (
            <Button
              color={props.okButtonColor || 'primary'}
              className={cs('mr-3', props.okButtonClassname)}
              onClick={onSubmit}
              disabled={props.loading || props.disableSubmit}
              icon={props.loading ? 'gear' : undefined}
              spinIcon={props.loading}
              data-cy="modal-confirm-button"
            >
              {props.okButtonText || t('common.commonButtons.ok')}
            </Button>
          )}
          {props.showCancelButton !== false && (
            <Button
              color="default"
              className={cs(props.cancelButtonClassname)}
              onClick={onHide}
              data-cy="modal-cancel-button"
            >
              {props.cancelButtonText || t('common.commonButtons.cancel')}
            </Button>
          )}
        </ModalFooter>
      )}
    </Modal>
  );
}
