import React from 'react';
import cs from 'classnames';
import { useApiCall } from '../../../../../lib/useApiCall';
import * as api from '../../../../../serverApi';

import { Button } from '../../../../../components/Button';
import { SensorHistoryGraph } from './SensorHistoryGraph';

import './SensorHistory.scss';
import { SensorHistoryTable } from './SensorHistoryTable';
import LoadingPlaceholder from '../../../../../lib/ui/LoadingPlaceholder';

interface Props {
  sensor: api.SuperVendorSensor;
  className?: string;
}

export function SensorHistory(props: Props) {
  const sensorCompositeId = `${props.sensor.sensor_type}${props.sensor.sensor_id}`;
  const res = useApiCall(api.getSensorStatusLog, [sensorCompositeId]);

  const log = res.data?.data ?? [];

  async function refresh() {
    await api.sendShortStatusSignal(sensorCompositeId);
    setTimeout(() => {
      res.reload();
    }, 1000);
  }

  return (
    <div className={cs('SensorHistory', props.className)}>
      {!res.data && res.loading ? (
        <LoadingPlaceholder />
      ) : (
        <>
          <div className="SensorHistory-header">
            <div>
              Last status update: <strong>{log[0]?.reg}</strong>
            </div>
            <Button onClick={res.reload}>Reload</Button>
            <Button onClick={refresh}>Refresh status</Button>
          </div>

          <div className="SensorHistory-graphs">
            <div className="SensorHistory-graph-column">
              <SensorHistoryGraph
                label="CPU temp"
                data={log.map((e) => e.cpuTemp ?? 0)}
              />

              <SensorHistoryGraph
                label="Lim temp"
                data={log.map((e) => e.limTemp ?? 0)}
              />

              <SensorHistoryGraph
                label="Tim temp"
                data={log.map((e) => e.timTemp ?? 0)}
              />
            </div>
            <div className="SensorHistory-graph-column">
              <SensorHistoryGraph
                label="5V mV"
                data={log.map((e) => e.volt5v ?? 0)}
              />

              <SensorHistoryGraph
                label="3.9V mV"
                data={log.map((e) => e.volt3v9 ?? 0)}
              />

              <SensorHistoryGraph
                label="VIN mV"
                data={log.map((e) => e.voltVin ?? 0)}
              />

              <SensorHistoryGraph
                label="Vlim mV"
                data={log.map((e) => e.voltLim ?? 0)}
              />
            </div>
            <div className="SensorHistory-graph-column">
              <SensorHistoryGraph
                label="5V mA"
                data={log.map((e) => e.curr5v ?? 0)}
              />

              <SensorHistoryGraph
                label="3.9V mA"
                data={log.map((e) => e.curr3v9 ?? 0)}
              />

              <SensorHistoryGraph
                label="VIN mA"
                data={log.map((e) => e.currVin ?? 0)}
              />

              <SensorHistoryGraph
                label="Vlim mA"
                data={log.map((e) => e.currLim ?? 0)}
              />
            </div>
            <div className="SensorHistory-graph-column">
              <SensorHistoryGraph
                label="Wi-Fi strength"
                data={log.map((e) => e.wifiStrength ?? 0)}
              />
            </div>
          </div>

          <SensorHistoryTable data={res.data} />
        </>
      )}
    </div>
  );
}
