import { eventTypesBySensorType } from '../eventTypes';
import { SensorType } from '../serverApi';

export function parseSensorCompositeId(
  sensorCompositeId?: null | string
): { sensor_type: SensorType; sensor_id: number } | undefined {
  if (!sensorCompositeId) {
    return undefined;
  }

  const match = sensorCompositeId.match(/^([a-z_]+)([0-9]+)$/);

  if (!match) {
    return undefined;
  }

  if (match[1] in eventTypesBySensorType) {
    return {
      sensor_type: match[1] as SensorType,
      sensor_id: parseInt(match[2], 10),
    };
  }

  return undefined;
}

/**
 * This function extracts the sensor type prefix from a sensor composite ID.
 *
 * Given `roommate12345`, it will return `roommate`.
 *
 * @param sensorCompositeId The composite sensor ID.
 */
export function getSensorTypeFromCompositeId(
  sensorCompositeId?: null | string
): SensorType | undefined {
  const res = parseSensorCompositeId(sensorCompositeId);
  return res?.sensor_type;
}

/**
 * This function extracts the sensor id from a sensor composite ID.
 *
 * Given `roommate12345`, it will return `12345`.
 *
 * @param sensorCompositeId The composite sensor ID.
 */
export function getSensorIdFromCompositeId(
  sensorCompositeId?: null | string
): number | undefined {
  const res = parseSensorCompositeId(sensorCompositeId);
  return res?.sensor_id;
}
