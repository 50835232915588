import React, { useEffect, useState } from 'react';
import Axios from 'axios';
import cs from 'classnames';
import { Route, Switch } from 'react-router';
import { ThemeProvider } from '@material-ui/core/styles';
import MenuOpenIcon from '@material-ui/icons/MenuOpen';
import { IconButton } from '@material-ui/core';
import { SuperAreaTheme } from '../../lib/themes/SuperAreaTheme';

import { Sensors } from './sensors/Sensors';
import { Accounts } from './accounts/Accounts';
import { CareReceivers } from './care-receivers/CareReceivers';
import { Overview } from './overview/Overview';
import { Header } from '../../components/Header/Header';
import { Zones } from './zones/Zones';
import { Vendors } from './vendors/Vendors';
import { Users } from './users/Users';
import { Integrations } from '../supervendor/integrations/Integrations';
import { SuperVendorNavigation } from '../supervendor/navigation/SuperVendorNavigation';
import { useWindowSize } from '../../lib/useWindowSize';
import { useScrollToTop } from '../../lib/useScrollToTop';

import './Super.scss';

export function Super() {
  const [menuOpen, setMenuOpen] = useState(false);
  const windowSize = useWindowSize();

  useScrollToTop();

  useEffect(() => {
    const interceptor = Axios.interceptors.response.use(
      (response) => response,
      (error) => {
        if (
          // When status response is 403 (Access denied), reload the app.
          // This is only for super area.
          // TODO: when session expires return response 401.
          error.response?.status === 403
        ) {
          window.location.reload();
        }

        return Promise.reject(error);
      }
    );
    return () => Axios.interceptors.response.eject(interceptor);
  }, []);

  const toggleMenu = () => {
    setMenuOpen((prev) => !prev);
  };

  return (
    <ThemeProvider theme={SuperAreaTheme}>
      <div
        className={cs(
          'Super',
          windowSize.isMobile && menuOpen && 'Super-nav--open'
        )}
      >
        <Header mode="super" className="Super-header" />

        {windowSize.isMobile && (
          <IconButton className="Super-nav-button" onClick={toggleMenu}>
            <MenuOpenIcon fontSize="large" />
          </IconButton>
        )}

        <SuperVendorNavigation
          className="Super-nav"
          onItemClick={() => setMenuOpen(false)}
        />

        <main className="Super-main">
          <Switch>
            <Route path="/super" exact={true} component={Overview} />
            <Route path="/super/zones" component={Zones} />
            <Route path="/super/sensors" component={Sensors} />
            <Route path="/super/users" component={Users} />
            <Route path="/super/accounts" component={Accounts} />
            <Route path="/super/vendors" component={Vendors} />
            <Route path="/super/integrations" component={Integrations} />
            <Route path="/super/care-receivers" component={CareReceivers} />
          </Switch>
        </main>
      </div>
    </ThemeProvider>
  );
}
