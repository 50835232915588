import moment from 'moment';
import { t } from '../../../lib/i18n';

interface Dates {
  valid_from?: null | string;
  valid_to?: null | string;
}

export function formatRoleValidityDates(obj: Dates) {
  if (obj.valid_from && obj.valid_to) {
    return `${moment(obj.valid_from).format('DD/MM/YY')} - ${moment(
      obj.valid_to
    ).format('DD/MM/YY')}`;
  }
  if (obj.valid_from) {
    return moment(obj.valid_from).format('DD/MM/YY');
  }
  if (obj.valid_to) {
    return moment(obj.valid_to).format('DD/MM/YY');
  }

  return '';
}

export function formatRolesValidityDates(objs: Array<Dates>) {
  // If just a single role, return date for that role.
  if (objs.length === 1) {
    return formatRoleValidityDates(objs[0]);
  }
  // If multiple roles, see if there is any non-empty dates among them.
  if (objs.find((o) => formatRoleValidityDates(o) !== '')) {
    return t('lib.utils.formatRolesValidityDates.multiplePeriods');
  }
  return '';
}
