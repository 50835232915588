import React, { useContext } from 'react';
import { MarkingObjectType, SensorType } from '../serverApi';

interface SharedSensorContext {
  zone?: {
    id: number;
    name: string | null;
    parent: null | {
      id: number;
      name: string | null;
    };
  };
  careReceiver?: null | {
    id: number;
    first_name: string | null;
    last_name: string | null;
  };
  eventTypes?: {
    [key: string]: {
      enabled: boolean;
      level: string; // hidden, info, alert, alarm
      audio_set: string;
    };
  };
  markingsDefined?: MarkingObjectType[];
  reload?: () => void;
}

export interface OptionalSensorContext extends SharedSensorContext {
  id?: string;
  name?: string | null;
  sensor_type?: SensorType;
  is_enabled?: boolean;
  show_receiving_sensor?: boolean;
}

export interface SensorContext extends SharedSensorContext {
  id: string;
  name: string | null;
  sensor_type: SensorType;
  is_enabled: boolean;
  show_receiving_sensor: boolean;
}

const sensorContext = React.createContext<OptionalSensorContext>({
  id: undefined,
  name: undefined,
  is_enabled: false,
  show_receiving_sensor: false,
});

const { Consumer, Provider } = sensorContext;

export const useOptionalSensorContext = () => useContext(sensorContext);

export function useSensorContext(): SensorContext {
  const context = useContext(sensorContext);
  if (context.id === undefined) {
    throw new Error('Invalid sensor context -- sensor not found.');
  }
  return context as SensorContext;
}

export { Consumer, Provider as SensorContextProvider };
