import React, { useEffect, useState } from 'react';
import { debounce } from 'lodash';

// import BlockedUsersTable from './BlockedUsersTable';
import ModalWrapper from '../../../../components/modals/ModalWrapper';
import { useErrorHandler } from '../../../../lib/useErrorHandler';
import { ErrorMessages } from '../../../../components/ErrorMessages';
import FilterInput from '../../../../components/FilterInput/FilterInput';
import { CareReceiverBlockedUser } from '../lib/useCareReceiverFormik';

import * as api from '../../../../serverApi';

// import { Panel } from '../../../../components/Panel';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimesCircle } from '@fortawesome/pro-light-svg-icons';
import formatUserLabel from '../../permissions/lib/formatUserLabel';
import { t } from '../../../../lib/i18n';

import './BlockedUserModal.scss';

interface Props {
  open: boolean;
  searchZoneId?: number;
  initialBlockedUsers: CareReceiverBlockedUser[];
  onCancel(): void;
  onSave(blockedUsers: CareReceiverBlockedUser[]): void;
}

export function BlockedUserModal(props: Props) {
  const errorHandler = useErrorHandler();

  const [searching, setSearching] = useState(true);
  const [searchResults, setSearchResults] = useState<
    CareReceiverBlockedUser[] | null
  >(null);

  const [blockedUsers, setBlockedUsers] = useState<CareReceiverBlockedUser[]>(
    props.initialBlockedUsers
  );

  useEffect(() => {
    if (props.open) {
      setSearching(true);
      searchForUsers('');
    }
    setBlockedUsers(props.initialBlockedUsers);
  }, [props.open]); // eslint-disable-line react-hooks/exhaustive-deps

  const handleResetModal = () => {
    if (errorHandler.error) {
      errorHandler.reset();
    }
  };

  const handleSubmit = () => {
    props.onSave(blockedUsers);
    props.onCancel();
    setSearchResults([]);
  };

  const handleOnChange = (name: string, value: string) => {
    setSearching(true);
    searchForUsers(value);
  };

  const searchForUsers = debounce(async (query: string) => {
    try {
      const res = await api.searchUsers({ query, zone_id: props.searchZoneId });
      const users: CareReceiverBlockedUser[] = res.results.map((user) => ({
        blocked_user_id: user.id,
        username: user.username,
        first_name: user.first_name,
        last_name: user.last_name,
        impersonal_user: user.impersonal_user,
      }));

      setSearching(false);
      setSearchResults(users);
    } catch (e) {
      setSearching(false);
      errorHandler.handleError(e);
    }
  }, 150);

  const selectUser = (user: CareReceiverBlockedUser) => {
    setBlockedUsers([...blockedUsers, user]);
  };

  const unSelectUser = (userId: number) => {
    setBlockedUsers(
      blockedUsers.filter((user) => user.blocked_user_id !== userId)
    );
  };

  const availableUsers = searchResults
    ? searchResults.filter(
        (item) =>
          blockedUsers.findIndex(
            (i) => i.blocked_user_id === item.blocked_user_id
          ) === -1
      )
    : [];

  return (
    <ModalWrapper
      className="BlockedUserModal"
      okButtonText={t('common.commonButtons.ok')}
      size="lg"
      showCancelButton={true}
      isOpen={props.open}
      onSubmit={handleSubmit}
      onHide={() => props.onCancel()}
      onResetModal={handleResetModal}
    >
      {/*<Panel className="mb-3">*/}
      {/*  Disse tjenesteutøverne vil ikke få anledning til å utføre tilsyn på*/}
      {/*  denne tjenestemottakeren.*/}
      {/*</Panel>*/}

      <div className="BlockedUserModal-container">
        <div className="BlockedUserModal-selected-users">
          <h2 className="BlockedUserModal-title">
            {t('manage.careReceivers.common.superVisionReservations')}
          </h2>

          <div className="BlockedUserModal-selected-users-list">
            {blockedUsers &&
              blockedUsers.length > 0 &&
              blockedUsers.map((bu) => (
                <div
                  className="BlockedUserModal-selected-users-list-item"
                  key={bu.blocked_user_id}
                  data-cy="blocked-care-providers-list"
                >
                  <span
                    role="button"
                    className="BlockedUserModal-selected-users-list-item-close"
                    onClick={() => unSelectUser(bu.blocked_user_id)}
                  >
                    <FontAwesomeIcon icon={faTimesCircle} />
                  </span>
                  <span className="BlockedUserModal-selected-users-list-item-label">
                    {formatUserLabel(bu)}
                  </span>
                </div>
              ))}
          </div>

          {/*<BlockedUsersTable*/}
          {/*  className="BlockedUserModal-table"*/}
          {/*  blockedUsers={blockedUsers}*/}
          {/*  onDelete={userId => unSelectUser(userId)}*/}
          {/*/>*/}
        </div>

        <div className="BlockedUserModal-search-user">
          <h2 className="BlockedUserModal-title">
            {t('common.commonButtons.select')}
          </h2>
          <FilterInput
            loading={searching}
            placeholder={t('common.commonInputs.name')}
            className="BlockedUserModal-search-user-input w-100 mt-0"
            onChange={(name, value) => {
              handleOnChange(name, value);
            }}
          />
          <div
            className="BlockedUserModal-search-user-results w-100"
            data-cy="available-care-providers-list"
          >
            {!availableUsers || availableUsers.length === 0 ? (
              <div
                className="BlockedUserModal-no-results"
                style={{ color: '#3F3F3F' }}
              >
                {t('common.commonTexts.noItemsInTheList')}.
              </div>
            ) : (
              availableUsers?.map((user) => (
                <div
                  className="BlockedUserModal-search-user-results-item"
                  key={user.blocked_user_id}
                  onClick={() => selectUser(user)}
                >
                  {formatUserLabel(user)}
                </div>
              ))
            )}
          </div>
        </div>
      </div>

      <ErrorMessages className="my-2" errorData={errorHandler} />
    </ModalWrapper>
  );
}
