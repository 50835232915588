import React, { useState } from 'react';
import HeadingBar from '../../super/shared/HeadingBar';
import { Card } from '../../super/shared/Card';
import {
  FormGroup,
  TextField,
  InputAdornment,
  IconButton,
} from '@material-ui/core';
import { Button } from '../../../components/Button';
import { Visibility, VisibilityOff } from '@material-ui/icons';
import { useSetupIntegrationFormik } from './lib/useSetupIntegrationFormik';
import { SelectAccount } from '../sensors/bulk-assign-account/SelectAccount';
import { ZoneFilter } from '../../super/shared/filters/ZoneFilter';
import { useErrorHandler } from '../../../lib/useErrorHandler';
import { ErrorMessages } from '../../../components/ErrorMessages';
import * as API from '../../../serverApi';
import { useSnackbar } from '../../../lib/SnackContext';
import { generatePassword } from '../../../lib/utils/generatePassword';
import { t } from '../../../lib/i18n';
import { RouteComponentProps } from 'react-router';

interface Props {}

export const SetupIntegration: React.FC<RouteComponentProps<Props>> = (
  props
) => {
  const [showPassword, setShowPassword] = useState(false);

  const snack = useSnackbar();
  const errorHandler = useErrorHandler();
  const formik = useSetupIntegrationFormik({
    onSubmit: async (values, helpers) => {
      try {
        errorHandler.reset();
        const { account_id, ...rest } = values;

        await API.superVendorSetupIntegration({
          ...(rest as API.SuperVendorSetupIntegrationInput),
        });

        //TODO: what should we do after submission
        snack.info('Success');
        showPassword && setShowPassword(false);
        helpers.resetForm();
        helpers.setFieldValue('password', generatePassword('integration'));
      } catch (e) {
        errorHandler.handleError(e);
      }
    },
  });

  const setupIntegrationURL = props?.match?.url?.substr(
    0,
    props.match.url.lastIndexOf('/')
  );

  return (
    <div className="SetupIntegration">
      <HeadingBar>
        <h2>{t('supervendor.Integration.setupIntegrations')}</h2>
        <Button to={setupIntegrationURL}>
          {t('supervendor.Integration.backToIntegrations')}
        </Button>
      </HeadingBar>

      <Card>
        <div style={{ maxWidth: 500 }}>
          <form onSubmit={formik.handleSubmit}>
            <FormGroup className="mb-3">
              <SelectAccount
                disabled={formik.isSubmitting}
                error={
                  formik.touched.account_id && formik.errors.account_id
                    ? formik.errors.account_id
                    : undefined
                }
                accountId={formik.values.account_id || undefined}
                onSelect={(a) => {
                  formik.setFieldValue('account_id', a);
                  formik.setFieldTouched('account_id');
                  formik.validateField('account_id');
                }}
              />
            </FormGroup>

            <FormGroup className="mb-1">
              <ZoneFilter
                disableApiLockedZones
                disableHousingUnits
                placeholder={t('common.commonTexts.selectZone')}
                modalTitle={t('common.commonTexts.selectZone')}
                variant="standard"
                style={{ width: '100%' }}
                error={
                  formik.touched.parent_zone_id && formik.errors.parent_zone_id
                    ? formik.errors.parent_zone_id
                    : undefined
                }
                disabled={formik.isSubmitting || !formik.values.account_id}
                accountId={formik.values.account_id || undefined}
                value={formik.values.parent_zone_id || undefined}
                onChange={(zone) => {
                  formik.setFieldValue('parent_zone_id', zone ? zone.id : null);
                  formik.setFieldTouched('parent_zone_id');
                }}
              />
            </FormGroup>

            <FormGroup className="mb-3">
              <TextField
                label="Zone name"
                name="zone_name"
                disabled={formik.isSubmitting}
                error={formik.touched.zone_name && !!formik.errors.zone_name}
                helperText={formik.touched.zone_name && formik.errors.zone_name}
                value={formik.values.zone_name}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
              />
            </FormGroup>

            <FormGroup className="mb-3">
              <TextField
                label="Username"
                name="username"
                disabled={formik.isSubmitting}
                error={formik.touched.username && !!formik.errors.username}
                helperText={formik.touched.username && formik.errors.username}
                value={formik.values.username}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
              />
            </FormGroup>

            <FormGroup className="mb-3">
              <TextField
                type={showPassword ? 'text' : 'password'}
                label="Password"
                name="password"
                disabled={formik.isSubmitting}
                error={formik.touched.password && !!formik.errors.password}
                helperText={formik.touched.password && formik.errors.password}
                value={formik.values.password}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="Toggle password visibility"
                        onClick={() => setShowPassword(!showPassword)}
                      >
                        {showPassword ? <VisibilityOff /> : <Visibility />}
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
              />
            </FormGroup>

            <div className="mt-5">
              <Button
                type="submit"
                disabled={!formik.isValid || formik.isSubmitting}
                spinIcon={formik.isSubmitting}
                icon={formik.isSubmitting ? 'gear' : undefined}
              >
                Save
              </Button>

              <ErrorMessages
                errorData={errorHandler}
                className="mt-4"
                renderFieldErrors
              />
            </div>
          </form>
        </div>
      </Card>
    </div>
  );
};
