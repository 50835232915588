import React from 'react';
import { Switch, Route, RouteComponentProps } from 'react-router';
import { ListPermissions } from './ListPermissions';
import { NewUser } from './NewUser';
import { UserDetails } from './UserDetails';
import { NewImpersonal } from './NewImpersonal';
import { EditUser } from './EditUser';
import { useRedirectOnZoneChange } from '../../../lib/useRedirectOnZoneChange';

export default function Permissions(props: RouteComponentProps<{}>) {
  useRedirectOnZoneChange({ relativePath: '/permissions' });
  return (
    <Switch>
      <Route exact={true} path={props.match.path} component={ListPermissions} />
      <Route
        exact={true}
        path={`${props.match.path}/new`}
        component={NewUser}
      />
      <Route
        exact={true}
        path={`${props.match.path}/new-impersonal`}
        component={NewImpersonal}
      />

      <Route
        exact={true}
        path={`${props.match.path}/:userId([0-9]+)`}
        component={UserDetails}
      />

      <Route
        exact={true}
        path={`${props.match.path}/user/:userId([0-9]+)`}
        component={UserDetails}
      />
      <Route
        exact={true}
        path={`${props.match.path}/user/:userId([0-9]+)/edit`}
        component={EditUser}
      />
    </Switch>
  );
}
