import { useEffect, useState } from 'react';
import { useZoneContext } from '../../../../lib/ZoneContext';

type OnZoneChangeFunction = (zoneId: number, prevZoneId: number) => void;

export function useOnZoneChange(func: OnZoneChangeFunction) {
  const ctx = useZoneContext();
  const [prevZoneId, setPrevZoneId] = useState<number | undefined>(undefined);

  useEffect(() => {
    if (prevZoneId !== ctx.activeZone.id) {
      if (prevZoneId !== undefined) {
        func(ctx.activeZone.id, prevZoneId);
      }
      setPrevZoneId(ctx.activeZone.id);
    }
  }, [ctx.activeZone.id, prevZoneId, func]);
}
