import React, { useState } from 'react';
import cn from 'classnames';
import {
  Button,
  MenuItem,
  Select,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from '@material-ui/core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSpinner } from '@fortawesome/pro-light-svg-icons';
import { useApiCall } from '../../../lib/useApiCall';
import { useConfirmation } from '../../../lib/confirmation/ConfirmationContext';
import * as Api from '../../../serverApi';
import { useErrorHandler } from '../../../lib/useErrorHandler';
import { ErrorMessages } from '../../../components/ErrorMessages';
import './AccountAssignVendor.scss';

interface Props {
  disabled?: boolean;
  accountId: number;
}

export const AccountAssignVendor = (props: Props) => {
  const confirmation = useConfirmation();
  const errorHandler = useErrorHandler();

  const [submitting, setSubmitting] = useState<boolean>(false);
  const [deleting, setDeleting] = useState<number | null>(null);
  const [selectedVendor, setSelectedVendor] = useState<number | null>(null);

  const accountVendorsResponse = useApiCall(Api.superGetAccountVendors, [
    props.accountId,
  ]);
  const accountVendors = accountVendorsResponse?.data?.data || [];

  const vendorsResponse = Api.useSuperVendorGetAccountsNewQuery({
    is_vendor: true,
  });

  const filteredVendors = vendorsResponse?.data?.data?.filter(
    (f) => !accountVendors.find((x) => x.account_id === f.account_id)
  );

  const handleSubmit = async () => {
    if (!selectedVendor || !props.accountId) {
      return;
    }
    try {
      errorHandler.reset();
      setSubmitting(true);
      await Api.superAssignAccountVendor(selectedVendor, props.accountId);
      setSelectedVendor(null);
      accountVendorsResponse.reload();
    } catch (e) {
      errorHandler.handleError(e);
    } finally {
      setSubmitting(false);
    }
  };

  const handleDelete = async (id: number) => {
    confirmation
      .confirm()
      .then(async () => {
        try {
          setDeleting(id);
          await Api.superUnassignAccountVendor(id, props.accountId);
          accountVendorsResponse.reload();
        } catch (e) {
          errorHandler.handleError(e);
        } finally {
          setDeleting(null);
        }
      })
      .catch(() => {});
  };

  return (
    <div>
      <ErrorMessages errorData={errorHandler} />
      <div
        className={cn(
          'AccountAssignVendor',
          props.disabled && 'AccountAssignVendor--disabled'
        )}
      >
        <div className="AccountAssignVendor-add-role">
          <p className="mb-0 mr-3">Select vendor: </p>

          <Select
            disabled={props.disabled || vendorsResponse.isLoading}
            name="vendor"
            value={selectedVendor || ''}
            className="AccountAssignVendor-select-role"
            onChange={(e) => setSelectedVendor(e.target.value as number)}
          >
            <MenuItem value="">Select vendor</MenuItem>
            {filteredVendors?.map((r) => (
              <MenuItem value={r.account_id} key={r.account_id}>
                {r.account_code} - {r.name}
              </MenuItem>
            ))}
          </Select>

          <Button
            className="ml-4"
            variant="contained"
            disabled={props.disabled}
            onClick={handleSubmit}
          >
            Submit
            {submitting && (
              <FontAwesomeIcon spin icon={faSpinner} className="ml-2" />
            )}
          </Button>
        </div>

        <div className="AccountAssignVendor-list">
          <TableContainer>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>Account ID</TableCell>
                  <TableCell>Name</TableCell>
                  <TableCell>Account code</TableCell>
                  <TableCell>&nbsp;</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {accountVendorsResponse.loading ? (
                  <TableRow>
                    <TableCell colSpan={4} className="text-center">
                      Loading
                    </TableCell>
                  </TableRow>
                ) : (
                  accountVendorsResponse?.data?.data?.map((account) => (
                    <TableRow key={account.account_id}>
                      <TableCell component="th" scope="row">
                        {account.account_id}
                      </TableCell>
                      <TableCell>{account.name}</TableCell>
                      <TableCell>{account.account_code}</TableCell>

                      <TableCell>
                        <Button
                          color="secondary"
                          variant="contained"
                          size="small"
                          disabled={deleting === account.account_id}
                          onClick={() => handleDelete(account.account_id)}
                        >
                          Remove
                          {deleting === account.account_id && (
                            <FontAwesomeIcon
                              spin
                              icon={faSpinner}
                              className="ml-2"
                            />
                          )}
                        </Button>
                      </TableCell>
                    </TableRow>
                  ))
                )}
              </TableBody>
            </Table>
          </TableContainer>
        </div>
      </div>
    </div>
  );
};
