import { useEffect, useState } from 'react';
import produce from 'immer';
import { t } from '../../../../lib/i18n';

// import { MarkingObj } from './MarkingObj';
import {
  convertFromServerMarkingObjectToLocalType,
  convertFromLocalMarkingObjectToInputType,
  markingObjectDefinitions,
  FrontendMarkingObjectType,
} from './types';
import MarkingCanvas from './components/MarkingCanvas';

import * as api from '../../../../serverApi';
import getParentPath from '../../../../lib/getParentPath';
import {
  MarkingObject,
  loadMarkingObjectsFromOriginals,
  Point,
  CHAIR_SIZE,
} from './types';

import * as mou from './lib/markingObjectUtils';
import { MarkingSidebar } from './components/MarkingSidebar';
import './MarkingApp.scss';
import { getAvailableItemNumber } from './lib/getAvailableItemNumber';
import { toast } from 'react-toastify';
import { validateMarkingThresholds } from './lib/validatePoints';

// api.MarkingObject represents the saved object as received from the database -- id is non-optional.

interface Props {
  sensorId: string;
  pathname: string;
}

interface State {
  // dirty: boolean;
  // markingObjs: MarkingObj[];
  // point3D: Point | undefined;
  // selectedMarkingObjIndex: number;
  // isLoading: boolean;
  error?: any;

  showResetImageModal?: boolean;

  /**
   * The currently selected marking object -- value of state.markingObjects[].item
   */
  selectedMarkingObjectItemNum?: number;

  /**
   * The type of the currently selected marking object -- to pass to the getPixelDetails for validation
   */
  selectedMarkingObjectType?: FrontendMarkingObjectType;

  /**
   * This indicated that the marking engine is in simulation mode (i.e. RCTS is _not_ being used)
   */
  simulated?: boolean;

  /**
   * The marking objects as they were received from the server.
   */
  originalMarkingObjects?: api.MarkingObject[];

  /**
   * The current marking objects, as they currently are with any unsaved changes.
   */
  markingObjects: MarkingObject[];

  /**
   *
   */
  loadingMarkingObjects: { [key: number]: boolean };

  sensorImageSrc?: string;
}

function getSensorImageUrl(sensorId: string) {
  const url = [
    api.getApiRoot(),
    '/api/sensors/',
    encodeURIComponent(sensorId),
    '/marking/image?ts=',
    Date.now().toString(),
  ].join('');
  return url;
}

export function MarkingApp(props: Props) {
  const [state, setState] = useState<State>({
    markingObjects: [],
    loadingMarkingObjects: [],
    sensorImageSrc: getSensorImageUrl(props.sensorId),
  });

  useEffect(() => {
    loadMarkingObjects();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const loadMarkingObjects = async () => {
    if (props.sensorId) {
      try {
        const res = await api.getMarkingObjects(props.sensorId);
        resetFromMarkingObjectsResult(res);
      } catch (e) {
        toast.error(t('common.error.anErrorOccurred'));
      }
    }
  };

  const resetFromMarkingObjectsResult = (res: api.MarkingObjectsResult) => {
    setState((prev) => ({
      ...prev,
      simulated: res.simulated,
      originalMarkingObjects: res.items,
      markingObjects: loadMarkingObjectsFromOriginals(res.items),
    }));
  };

  /**
   * componentDidUpdate when sensorId changes
   */
  useEffect(() => {
    setState((prev) => ({
      ...prev,
      markingObjects: [],
      loadingMarkingObjects: {},
      sensorImageSrc: getSensorImageUrl(props.sensorId),
    }));
    loadMarkingObjects();
  }, [props.sensorId]); // eslint-disable-line react-hooks/exhaustive-deps

  const addMarkingObject = (objectType: FrontendMarkingObjectType) => {
    setState((prevState) => {
      const typeDefinition = markingObjectDefinitions[objectType];

      const itemNum = getAvailableItemNumber(prevState.markingObjects);

      return {
        ...prevState,
        markingObjects: [
          ...(prevState.markingObjects || []),
          {
            item: itemNum,
            points: [],
            type: objectType,
            typeDefinition,
          },
        ],
        //the last added marking object is automatically selected as an editable object
        selectedMarkingObjectItemNum: itemNum,
        selectedMarkingObjectType: objectType,
      };
    });
  };

  const addSuggestedMarkingObject = async (obj: {
    type: FrontendMarkingObjectType;
    points: Point[];
  }) => {
    const typeDefinition = markingObjectDefinitions[obj.type];

    const points = typeDefinition.enclosed
      ? [...obj.points, obj.points[0]]
      : obj.points;
    /**
     * Figure out if we are creating a new object or replacing an existing one
     */
    let replaceIndex: number = -1;

    if (obj.type === 'bed') {
      replaceIndex = state.markingObjects.findIndex((mo) => mo.type === 'bed');
    } else if (obj.type === 'chair_area' || obj.type === 'chair_circle') {
      replaceIndex = state.markingObjects.findIndex(
        (mo) => mo.type === 'chair_area' || mo.type === 'chair_circle'
      );
    }

    const newObject = {
      item:
        replaceIndex >= 0
          ? state.markingObjects[replaceIndex].item
          : getAvailableItemNumber(state.markingObjects),
      points,
      type: obj.type,
      typeDefinition,
    };

    try {
      setMarkingObjectState(
        newObject.item,
        { ...newObject, saving: true },
        true
      );
      setState((prev) => ({ ...prev, error: undefined }));

      const res = await api.setMarkingObject(
        newObject.item,
        props.sensorId,
        convertFromLocalMarkingObjectToInputType(newObject)
      );
      resetFromMarkingObjectsResult(res);
      setMarkingObjectState(newObject.item, { saving: false });
    } catch (error) {
      setState((prev) => ({ ...prev, error }));
      setMarkingObjectState(newObject.item, { saving: false });
    }
  };

  const requestRefreshImage = () => {
    setState((prev) => ({
      ...prev,
      sensorImageSrc: getSensorImageUrl(props.sensorId),
    }));
  };

  const requestResetImage = async () => {
    try {
      setState((prev) => ({ ...prev, showResetImageModal: true }));
      await api.resetSensorImage(props.sensorId);
      setTimeout(() => {
        setState((prev) => ({
          ...prev,
          showResetImageModal: false,
          sensorImageSrc: getSensorImageUrl(props.sensorId),
        }));
        loadMarkingObjects();
      }, 15000);
    } catch (e) {
      toast.error(t('common.error.anErrorOccurred'));
      setState((prev) => ({ ...prev, showResetImageModal: false }));
    }
  };

  /**
   * Delete a single marking object for the current sensor, given by its index.
   */
  const deleteMarkingObject = async (itemNum: number) => {
    // If this is just a local marking object, we simply remove the object.
    // If not, we set it to loading here and delete it on the server, THEN delete it locally.
    const mo = state.markingObjects.find((mo) => mo.item === itemNum);
    if (!mo) {
      return;
    }
    if (mo.original) {
      try {
        setMarkingObjectState(itemNum, { deleting: true });
        setState((prev) => ({ ...prev, error: undefined }));
        const res = await api.deleteMarkingObject(mo.item, props.sensorId);
        resetFromMarkingObjectsResult(res);
        setMarkingObjectState(itemNum, { deleting: false });
      } catch (error) {
        setState((prev) => ({ ...prev, error }));
        setMarkingObjectState(itemNum, { deleting: false });
      }
    } else {
      // This marking object only exists in the browser, we just clean it out of the state
      setState((prev) => ({
        ...prev,
        error: undefined,
        markingObjects: state.markingObjects.filter(
          (mo) => mo.item !== itemNum
        ),
      }));
    }
    // Since we are using index as ID; we can't trust the selected index number
    if (state.selectedMarkingObjectItemNum === itemNum) {
      setState((prev) => ({
        ...prev,
        selectedMarkingObjectItemNum: undefined,
        selectedMarkingObjectType: undefined,
      }));
    }
  };

  const setSelectedMarkingObjectItemNum = (
    selectedMarkingObjectItemNum: number | undefined
  ) => {
    const mo = state.markingObjects.find(
      (mo) => mo.item === selectedMarkingObjectItemNum
    );
    setState((prev) => ({
      ...prev,
      selectedMarkingObjectItemNum,
      selectedMarkingObjectType: mo?.type,
    }));
  };

  /**
   * Change the diameter of the point for a point/circle marking object
   */
  const onChangePointDiameter = (
    markingObjItemNum: number,
    pointIndex: number,
    customDiameter: boolean,
    diameter: number | undefined
  ) => {
    setState((prevState) =>
      produce(prevState, (draft) => {
        const obj = draft.markingObjects.find(
          (i) => i.item === markingObjItemNum
        );
        if (obj) {
          obj.hasCustomDiameter = customDiameter;
          if (diameter !== undefined) {
            obj.points[pointIndex].d = diameter;
          }
        }
      })
    );
  };

  /**
   * Revalidate the status field of a specific point, typically based on a toggle in the `e` field.
   */
  const revalidateMarkingPixelValues = async (p: {
    x: number;
    y: number;
    z: number;
    a: number;
    e?: boolean;
    markingObjectType: FrontendMarkingObjectType;
    pointIndex: number;
    itemNum: number;
  }) => {
    /**
     * Send a new request to the server to get the new values for the point.
     */
    try {
      const res = validateMarkingThresholds(
        p.markingObjectType,
        p.x,
        p.y,
        p.z,
        p.e
      );

      // Update the marking object with the new values
      setState((prevState) =>
        produce(prevState, (draft) => {
          const origIndex = prevState.markingObjects.findIndex(
            (prev) => prev.item === p.itemNum
          );
          if (origIndex < 0) {
            return prevState;
          }

          const mo = draft.markingObjects[origIndex];

          /**
           * Additional sanity check to ensure we are not adding the returned
           * values to the wrong point. This check would not be necessary if
           * we had full trust in the logic, but the release date is
           * approaching ...
           */
          if (
            !mo.points[p.pointIndex] ||
            mo.points[p.pointIndex].x !== p.x ||
            mo.points[p.pointIndex].y !== p.y ||
            mo.points[p.pointIndex].z !== p.z ||
            mo.points[p.pointIndex].a !== p.a
          ) {
            return prevState;
          }
          mo.points[p.pointIndex].status =
            res.status !== 'ok' ? res.status : undefined;
        })
      );
    } catch (err) {
      console.error(err);
    }
  };

  /**
   * Toggle the extension setting on the first/last point of an open marking object
   */
  const onTogglePointExtended = async (
    markingObjItemNum: number,
    pointIndex: number
  ) => {
    /**
     * Find the marking object/point
     */
    const mox = state.markingObjects.findIndex(
      (i) => i.item === markingObjItemNum
    );
    const mo = state.markingObjects[mox];
    const point = mo?.points[pointIndex];
    const newExtendedState = !point?.e;

    /**
     * Basic sanity checks
     */
    if (
      !point || // Ignore the request if the point is not found
      !mo || // Ignore the request if the marking object is not found
      mo.typeDefinition.enclosed || // Extensions are invalid for this marking object type
      mo.typeDefinition.circle || // Extensions are invalid for this marking object type
      mo.points.length < 2 || // Must be at least two points in the marking object in order to create an extension line
      (pointIndex !== 0 && pointIndex !== mo.points.length - 1) // Only the first and last point can be extended
    ) {
      return;
    }

    /**
     * First, we update the toggle state to avoid sluggish UI
     */
    setState((prevState) =>
      produce(prevState, (draft) => {
        draft.markingObjects[mox].points[pointIndex].e = newExtendedState;
      })
    );

    /**
     * Then, we request updated point values for the changed point.
     */
    if (
      point.x !== undefined &&
      point.y !== undefined &&
      point.z !== undefined &&
      point.a !== undefined
    ) {
      revalidateMarkingPixelValues({
        x: point.x,
        y: point.y,
        z: point.z,
        a: point.a,
        e: newExtendedState,
        markingObjectType: mo.type,
        pointIndex: pointIndex,
        itemNum: mo.item,
      });
    }
  };

  const resetMarkingObject = (itemNum: number) => {
    const mo = state.markingObjects.find((mo) => mo.item === itemNum);
    if (!mo) {
      return;
    }
    if (mo.original) {
      setState((prev) => {
        const origIndex = prev.markingObjects.findIndex(
          (o) => o.item === itemNum
        );
        if (origIndex >= 0) {
          const orig = prev.markingObjects[origIndex].original;
          if (orig) {
            return {
              ...prev,
              markingObjects: [
                ...prev.markingObjects.slice(0, origIndex),
                convertFromServerMarkingObjectToLocalType(orig),
                ...prev.markingObjects.slice(origIndex + 1),
              ],
            };
          }
        }
        return prev;
      });
      // TODO: Copy points from original to current
    } else {
      setState((prev) => ({
        ...prev,
        markingObjects: prev.markingObjects.filter((mo) => mo.item !== itemNum),
      }));
    }

    if (state.error) {
      setState((prev) => ({ ...prev, error: undefined }));
    }
  };

  const setMarkingObjectState = (
    itemNum: number,
    updateState: Partial<MarkingObject>,
    overwrite: boolean = false // When this is true, we don't keep any existing state for the current marking object with the given item number
  ) => {
    setState((prev) => {
      const ix = prev.markingObjects.findIndex((m) => m.item === itemNum);
      if (ix >= 0) {
        return {
          ...prev,
          markingObjects: [
            ...prev.markingObjects.slice(0, ix),
            {
              ...(overwrite ? ({} as MarkingObject) : prev.markingObjects[ix]),
              ...updateState,
            },
            ...prev.markingObjects.slice(ix + 1),
          ],
        };
      }
      return prev;
    });
  };

  /**
   * Save the marking object with the given index
   */
  const saveMarkingObject = async (itemNum: number) => {
    const markingObj = state.markingObjects.find((mo) => mo.item === itemNum);
    if (!markingObj) {
      return;
    }
    if (!markingObj.points.length) {
      toast.success(
        `${t(
          'manage.sensors.marking.MarkingApp.cannotSaveASelectionWithoutDots'
        )}.`
      );
      return;
    }

    if (markingObj.points.find((p) => p.status === 'reject')) {
      // If there are any rejected points in this marking object, we
      // don't save the point. We should never get here though,
      // because the save button should have been disabled.
      return;
    }

    // If this is an enclosed type, we need to ensure that it's closed
    if (markingObj.typeDefinition.enclosed) {
      if (markingObj.points.length < 3) {
        toast.success(
          `${t(
            'manage.sensors.marking.MarkingApp.mustBeAtLeastThreePointsInThisSelectionType'
          )}.`
        );
        return;
      }
      const firstPoint = markingObj.points[0];
      const lastPoint = markingObj.points[markingObj.points.length - 1];
      if (firstPoint.h !== lastPoint.h || firstPoint.v !== lastPoint.v) {
        toast.success(
          `${t(
            'manage.sensors.marking.MarkingApp.theSelectionMustBeClosedBeforeItCanBeStoredAddTheLastPointToTheSamePlaceAsTheFirstPoint'
          )}.`
        );
        return;
      }
    }

    try {
      setMarkingObjectState(itemNum, { saving: true });
      setState((prev) => ({ ...prev, error: undefined }));

      const res = await api.setMarkingObject(
        markingObj.item,
        props.sensorId,
        convertFromLocalMarkingObjectToInputType(markingObj)
      );

      resetFromMarkingObjectsResult(res);
      setMarkingObjectState(itemNum, { saving: false });
    } catch (error) {
      setState((prev) => ({ ...prev, error }));
      setMarkingObjectState(itemNum, { saving: false });
    }
  };

  const addPoint = (p: { h: number; v: number }) => {
    setState((prevState) =>
      produce(prevState, (draft) => {
        const obj = draft.markingObjects.find(
          (mo) => mo.item === state.selectedMarkingObjectItemNum
        );
        if (obj) {
          /**
           * Prevent adding more points than allowed for the given marking type
           */
          if (obj.points.length >= obj.typeDefinition.maxPoints) {
            toast.success(
              t(
                'manage.sensors.marking.MarkingApp.maxPointsMessage',
                obj.typeDefinition.maxPoints
              )
            );
            return prevState;
          }

          const np: Point = {
            ...p,
          };

          /**
           * Circle chairs; only a single point with a default diameter
           */
          if (obj.type === 'chair_circle') {
            if (obj.points.length > 0) {
              toast.success(
                t(
                  'manage.sensors.marking.MarkingApp.thereCanOnlyBeOnePointInAChairMarking'
                )
              );
              return prevState;
            }
            if (np.d === undefined) {
              np.d = CHAIR_SIZE.S;
            }
          }

          /**
           * If trying to add more points after the final closing point
           */
          if (
            obj.typeDefinition.enclosed &&
            obj.points.length > 1 &&
            mou.isTwoPointsEqual(
              obj.points[0],
              obj.points[obj.points.length - 1]
            )
          ) {
            toast.success(
              `${t(
                'manage.sensors.marking.MarkingApp.cannotAddMorePointsAfterTheSelectionIsClosed'
              )}.`
            );
            return prevState;
          }

          /**
           * If we got here, we're adding a new point!
           */

          /**
           * Check if we should snap the new point to the first point
           */
          if (mou.isSnappedPoint(obj, np)) {
            obj.points.push(obj.points[0]);
          } else {
            // Clear the extension on the previous point, if set
            if (obj.points.length > 0 && obj.points[obj.points.length - 1].e) {
              obj.points[obj.points.length - 1].e = false;
            }
            // Push the new point to the point array
            obj.points.push(np);

            // Request fresh 3D coordinates for the given h,v coords
            if (typeof state.selectedMarkingObjectItemNum === 'number') {
              updatePointCoords(
                state.selectedMarkingObjectItemNum,
                np.h,
                np.v,
                obj.type
              );
            }
          }
        }
      })
    );
  };

  const updatePointCoords = async (
    item: number,
    h: number,
    v: number,
    type?: FrontendMarkingObjectType,
    e?: boolean
  ) => {
    /**
     * Send a new request to the server to get the new values for the point.
     */
    const pointDetails = await api.getMarkingPixelDetails(props.sensorId, {
      h,
      v,
      t: type,
      e,
    });

    if (!pointDetails) {
      return;
    }

    setState((prevState) =>
      produce(prevState, (draft: State) => {
        /**
         * Find the object for the given item we're updating for
         */
        const obj = draft.markingObjects.find(
          (mo) => mo.item === state.selectedMarkingObjectItemNum
        );

        // No object, no change (e.g. we deleted the object before we got the response)
        if (!obj) {
          return;
        }

        /**
         * Search for the point with the given h,v coords
         */
        for (let i = 0; i < obj.points.length; i++) {
          if (h === obj.points[i].h && v === obj.points[i].v) {
            // We found a matching point -- let's update its coords.
            obj.points[i].x = pointDetails.x;
            obj.points[i].y = pointDetails.y;
            obj.points[i].z = pointDetails.z;
            obj.points[i].a = pointDetails.a;

            if (type) {
              const validationResult = validateMarkingThresholds(
                type,
                pointDetails.x,
                pointDetails.y,
                pointDetails.z,
                e
              );
              obj.points[i].status = validationResult?.status ?? 'ok';
            }
          }
        }
      })
    );
  };

  const removeLastPoint = () => {
    setState((prevState) =>
      produce(prevState, (draft) => {
        const mo = draft.markingObjects.find(
          (mo) => mo.item === state.selectedMarkingObjectItemNum
        );
        if (mo) {
          mo.points.pop();
        }
      })
    );
  };

  return (
    <div className="MarkingApp">
      <MarkingSidebar
        error={state.error}
        className="MarkingApp-sidebar"
        clickBackTarget={getParentPath(getParentPath(props.pathname))}
        markingObjects={state.markingObjects}
        onRequestRefreshImage={requestRefreshImage}
        onRequestResetImage={requestResetImage}
        showResetImageModal={state.showResetImageModal}
        selectedMarkingObjectItemNum={state.selectedMarkingObjectItemNum}
        setSelectedMarkingObjectItemNum={setSelectedMarkingObjectItemNum}
        onAddMarkingObject={addMarkingObject}
        onDeleteMarkingObject={deleteMarkingObject}
        saveMarkingObject={saveMarkingObject}
        resetMarkingObject={resetMarkingObject}
        showSimulationWarning={state.simulated}
        onChangePointDiameter={onChangePointDiameter}
        onTogglePointExtended={onTogglePointExtended}
        sensorCompositeId={props.sensorId}
      />

      <div className="MarkingApp-main">
        <MarkingCanvas
          sensorId={props.sensorId}
          className="MarkingApp-canvas"
          markingObjects={state.markingObjects}
          selectedMarkingObjectItemNum={state.selectedMarkingObjectItemNum}
          addPoint={addPoint}
          removeLastPoint={removeLastPoint}
          refreshImage={requestRefreshImage}
          sensorImageSrc={state.sensorImageSrc}
          addSuggestedMarkingObject={addSuggestedMarkingObject}
        />
      </div>
    </div>
  );
}
