import React from 'react';
import { AlertSettingsForm } from './AlertSettingsForm';
import { AlertSettingState } from './AlertSettingsForm';
import { useZoneContext } from '../../../lib/ZoneContext';
import { IconButton } from '../../../components/IconButton';
import getParentPath from '../../../lib/getParentPath';
import { useRouter } from '../../../lib/useRouter';
import { parseTimeOfDay } from '../../../lib/utils/parseTimeOfDay';
import { useErrorHandler } from '../../../lib/useErrorHandler';
import { RouteLeavingGuard } from '../../../components/RouteLeavingGuard';
import { sortBy } from 'lodash';
import { useAlertSettingsFormik } from './lib/useAlertSettingsFormik';
import { t } from '../../../lib/i18n';

import * as api from '../../../serverApi';

interface Props {
  alertSettingId: number;
  data: AlertSettingState;
}

export function EditAlertSettingForm(props: Props) {
  const router = useRouter();
  const ctx = useZoneContext();
  const errorHandler = useErrorHandler();
  const backUrl = getParentPath(router.location.pathname);
  const isAlertSettingLocked = props.data.api_lock;

  const formik = useAlertSettingsFormik({
    initialValues: {
      ...props.data,
      event_types: sortBy(props.data.event_types),
      recipients: props.data.recipients.map((u) => ({
        id: u.id,
        username: u.username,
        impersonal_user: u.impersonal_user,
        phone: u.phone,
        first_name: u.first_name,
        last_name: u.last_name,
        display_name: u.display_name,
      })),
      escalation_delay: props.data.escalation_enabled
        ? props.data.escalation_delay
        : 180,
    },
    onSubmit: async (values, helpers) => {
      let createInput: api.UpdateAlertSettingInput = {
        name: values.name ?? '',
        enabled: values.enabled,
        escalation_enabled: values.escalation_enabled,
        escalation_delay: values.escalation_delay,
        include_regular_alert_events: values.include_regular_alert_events,
        include_regular_alarm_events: values.include_regular_alarm_events,
        include_technical_event_types: values.include_technical_event_types,
        include_custom_event_types: values.include_custom_event_types,
        event_types: values.event_types.map((event_type) => ({
          sensor_type: 'roommate',
          event_type,
        })),
        recipients: values.recipients.map((r) => r.id),
        escalation_recipients: values.escalation_recipients
          ? values.escalation_recipients.map((er) => er.id)
          : [],
        schedule: values.schedule.map((s) => ({
          ...s,
          from_time: parseTimeOfDay(s.from_time) || '',
          to_time: parseTimeOfDay(s.to_time) || '',
        })),
      };

      if (props.data.zone_id !== values.zone_id) {
        createInput = {
          ...createInput,
          zone_id: values.zone_id,
        } as api.UpdateAlertSettingInput;
      }

      if (
        (!values.escalation_recipients ||
          values.escalation_recipients.length === 0) &&
        values.recipients.length === 0
      ) {
        errorHandler.setObjectError({
          recipients: t(
            'manage.alerts.common.atLeastOneNotificationRecipientIsRequired'
          ),
          escalation_recipients: t(
            'manage.alerts.common.atLeastOneNotificationRecipientIsRequired'
          ),
        });
        return false;
      }

      if (isAlertSettingLocked) {
        errorHandler.setStringError(
          //TODO: add translation after finishing the functionality
          'This alert settings is locked and cannot be changed'
        );
        return;
      }

      try {
        await api.updateAlertSetting(props.alertSettingId, createInput);
        helpers.resetForm();
        router.history.push(backUrl);
      } catch (e) {
        errorHandler.handleError(e);
      }
    },
  });

  return (
    <>
      <RouteLeavingGuard when={formik.dirty} />

      <h1>
        {t('manage.alerts.EditAlertSettingForm.notificationRuleAndRecipients')}:{' '}
        {ctx.activeZone.name}
      </h1>

      <div className="my-4">
        <IconButton icon="arrow-left" to={backUrl}>
          {t('common.commonButtons.back')}
        </IconButton>
      </div>

      <AlertSettingsForm
        disabled={isAlertSettingLocked}
        error={errorHandler}
        formik={formik}
      />
    </>
  );
}
