import { useMemo } from 'react';
import cs from 'classnames';
import { MarkingObject } from '../types';
import { ButtonGroup } from '../../../../../components/ButtonGroup';
import { Button } from '../../../../../components/Button';

import './MarkingObjectControls.scss';
import { t } from '../../../../../lib/i18n';
import { Panel } from '../../../../../components/Panel';
import { MarkingObjectPointsTable } from '../ui/MarkingObjectPointsTable';

interface Props {
  /**
   * The currently selected marking object.
   */
  markingObject?: MarkingObject;

  /**
   * Whether we are loading for some reason (show indicator and disable buttons)
   */
  loading?: boolean;

  /**
   * Whether there are unsaved changes.
   */
  hasUnsavedChanges?: boolean;

  /**
   * Event triggered when saved button is clicked
   */
  onClickSave?: () => void;

  /**
   * Event triggered when cancel button is clicked
   */
  onClickCancel?: () => void;

  /**
   * Event triggered when delete button is clicked
   */
  onClickDelete?: () => void;

  selectedMarkingObjectItemNum?: number;

  onUnselectMarkingObject?: () => void;

  onChangePointDiameter?: (
    markingObjItemNum: number,
    pointIndex: number,
    customDiameter: boolean,
    diameter: number | undefined
  ) => void;

  onTogglePointExtended?: (
    markingObjItemNum: number,
    pointIndex: number
  ) => void;
}

export function MarkingObjectControls(props: Props) {
  const worstValidationResult = useMemo(() => {
    let worst: 'ok' | 'warning' | 'reject' = 'ok';
    if (props.markingObject) {
      for (let i = 0; i < props.markingObject.points.length; i++) {
        if (
          props.markingObject.points[i].status === 'warning' &&
          worst === 'ok'
        ) {
          worst = 'warning';
        } else if (props.markingObject.points[i].status === 'reject') {
          worst = 'reject';
        }
      }
    }
    return worst;
  }, [props.markingObject]);

  if (!props.markingObject) {
    return null;
  }

  const obj = props.markingObject;

  const lastPoint = obj.points.length - 1;

  // Don't show the last point if this is an enclosed shape
  // and the first and last points are identical
  const displayPoints =
    obj.typeDefinition.enclosed &&
    obj.points.length > 2 &&
    obj.points[0].h === obj.points[lastPoint].h &&
    obj.points[0].v === obj.points[lastPoint].v
      ? obj.points.slice(0, obj.points.length - 1)
      : obj.points;

  return (
    <div
      className={cs(
        'MarkingObjectControls',
        `MarkingObjectControls--${obj.type}`,
        props.hasUnsavedChanges ? 'MarkingObjectControls--changed' : undefined
      )}
    >
      <MarkingObjectPointsTable
        points={displayPoints}
        markingObject={obj}
        onChangePointDiameter={props.onChangePointDiameter}
        onTogglePointExtended={props.onTogglePointExtended}
        selectedMarkingObjectItemNum={props.selectedMarkingObjectItemNum}
      />

      <ButtonGroup className="mt-3">
        <Button
          color="primary"
          icon={obj.saving || obj.deleting ? 'gear' : undefined}
          spinIcon={obj.saving || obj.deleting}
          disabled={
            props.loading ||
            !props.onClickSave ||
            obj.saving ||
            obj.deleting ||
            worstValidationResult === 'reject'
          }
          onClick={props.onClickSave}
        >
          {t('common.commonButtons.save')}
        </Button>

        <Button
          color="secondary"
          icon={obj.saving || obj.deleting ? 'gear' : undefined}
          spinIcon={obj.saving || obj.deleting}
          disabled={
            props.loading || !props.onClickDelete || obj.saving || obj.deleting
          }
          onClick={props.onClickDelete}
        >
          {t('common.commonButtons.delete')}
        </Button>

        <Button
          color="default"
          disabled={props.loading || !props.onClickCancel}
          onClick={props.onClickCancel}
        >
          {t('common.commonButtons.cancel')}
        </Button>
      </ButtonGroup>

      {worstValidationResult === 'reject' && (
        <Panel color="danger">
          {t('manage.sensors.marking.MarkingApp.markingRejected')}
        </Panel>
      )}

      {worstValidationResult === 'warning' && (
        <Panel color="warning">
          {t('manage.sensors.marking.MarkingApp.markingWarning')}
        </Panel>
      )}
    </div>
  );
}
