import React, { CSSProperties } from 'react';
import { MenuItem, TextField } from '@material-ui/core';
import cs from 'classnames';
import { t } from '../../../../lib/i18n';
import { useAccount } from '../../../../lib/useAccount';

type LogType =
  | 'audit'
  | 'audit-change'
  | 'change'
  | 'observation'
  | 'event'
  | 'vkp';

interface Props {
  logType?: LogType;
  onLogTypeChange?: (logType: LogType | undefined) => void;
  className?: string;
  style?: CSSProperties;
}

export function LogTypeSelector(props: Props) {
  const account = useAccount();
  return (
    <div className={cs('LogTypeSelector', props.className)} style={props.style}>
      <TextField
        className="Filters-logtype"
        select={true}
        value={props.logType}
        onChange={(e) => {
          if (props.onLogTypeChange) {
            props.onLogTypeChange(e.target.value as LogType | undefined);
          }
        }}
        name="logType"
        label={t('manage.logs.ui.LogTypeSelector.logType')}
      >
        <MenuItem value="observation">
          {t('manage.logs.ui.LogTypeSelector.auditLogs')}
        </MenuItem>

        <MenuItem value="audit">
          {t('manage.logs.ui.LogTypeSelector.accessLog')}
        </MenuItem>

        <MenuItem value="audit-change">
          {t('manage.logs.ui.LogTypeSelector.auditChangeLogs')}
        </MenuItem>

        {account.vkp_available && (
          <MenuItem value="vkp">
            {t('manage.logs.ui.LogTypeSelector.vkpLogs')}
          </MenuItem>
        )}

        {/* Old-type logs
        <MenuItem value="change">
          {t('manage.logs.ui.LogTypeSelector.changeLogs')}
        </MenuItem>
        */}

        <MenuItem value="event">
          {t('manage.logs.ui.LogTypeSelector.eventLogs')}
        </MenuItem>
      </TextField>
    </div>
  );
}
