import React, { useCallback, useState } from 'react';
import { Button } from '@material-ui/core';
import BulkAssignAccountModal from './BulkAssignAccountModal';
import { faPlus } from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import * as api from '../../../../serverApi';
import { sortAccountsAlphabetically } from '../../../../lib/utils/sortAccountsAlphabetically';

interface Props {
  onChanged?: () => void;
}

export const BulkAssignAccountButton = React.memo((props: Props) => {
  const [isOpen, setIsOpen] = useState(false);

  const loadAvailableAccounts = useCallback(async () => {
    const res = await api.superVendorGetAccounts({});
    const filteredData = res?.data?.filter((a) => !a.is_vendor); // Show accounts that are not vendors.

    return sortAccountsAlphabetically(filteredData).map((a) => ({
      id: a.account_id,
      label: a.name,
    }));
  }, []);

  const handleOnSubmit = async (values: {
    accountId: number | null;
    sensors: string;
    sensorType: api.SensorType;
  }) => {
    return await api.superVendorBulkAssignSensorAccount({
      account_id: values.accountId,
      sensors: values.sensors,
      sensor_type: values.sensorType,
    });
  };

  const handleOnChanged = () => {
    if (!props.onChanged) {
      return;
    }

    props.onChanged();
  };

  return (
    <>
      <Button onClick={() => setIsOpen(true)}>
        <FontAwesomeIcon icon={faPlus} className="mr-2" />
        Bulk assign account
      </Button>

      <BulkAssignAccountModal
        isOpen={isOpen}
        toggle={() => setIsOpen(!isOpen)}
        mode="account"
        loadAvailableAccounts={loadAvailableAccounts}
        onSubmit={handleOnSubmit}
        onChanged={handleOnChanged}
      />
    </>
  );
});
