import React, { CSSProperties } from 'react';

import * as api from '../../../../../serverApi';

import { TableWrapper } from '../../../../../components/TableWrapper';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import { Card } from '../../../../super/shared/Card';

interface Props {
  sensor: api.SuperVendorSensor;
  className?: string;
  style?: CSSProperties;
}

export function IpAddressesCard(props: Props) {
  return (
    <Card className={props.className} style={props.style} noPadding>
      <h3>IP addresses</h3>

      <TableWrapper>
        <Table size="small">
          <TableBody>
            <TableRow>
              <TableCell
                component="th"
                scope="row"
                size="medium"
                style={{ paddingLeft: '24px' }}
              >
                LAN IP
              </TableCell>
              <TableCell>{props.sensor.lanIP}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell
                component="th"
                scope="row"
                size="medium"
                style={{ paddingLeft: '24px' }}
              >
                Tunnel IP
              </TableCell>
              <TableCell>{props.sensor.tunIP}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell
                component="th"
                scope="row"
                size="medium"
                style={{ paddingLeft: '24px' }}
              >
                WAN IP
              </TableCell>
              <TableCell>{props.sensor.wanIP}</TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </TableWrapper>
    </Card>
  );
}
