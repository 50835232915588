import React, { useState } from 'react';
import { useRouter } from '../../../../lib/useRouter';
import { SensorForm } from '../SensorForm';
import { useAssignSensorFormik } from '../lib/useAssignSensorFormik';
import { useZoneTreeContext } from '../../../care/lib/ZoneTreeContext';
import {
  errorIsString,
  useErrorHandler,
} from '../../../../lib/useErrorHandler';
import { useConfirmation } from '../../../../lib/confirmation/ConfirmationContext';

import * as yup from 'yup';
import * as api from '../../../../serverApi';
import { t } from '../../../../lib/i18n';
import { isAxiosError } from '../../../../lib/utils/errorUtils';

interface Props {
  onCreate: () => void;
  initialZoneId?: number;
}
export const AssignNewSensor = (props: Props) => {
  const router = useRouter();
  const zoneTreeCtx = useZoneTreeContext();
  const errorHandler = useErrorHandler();
  const confirmation = useConfirmation();

  const initialZone = zoneTreeCtx.getZoneById(props.initialZoneId);

  const [selectedZone, setSelectedZone] = useState<
    api.StructureZone | undefined
  >(initialZone?.is_housing_unit ? initialZone : undefined);

  const handleSetSelectedZone = (zoneId?: number) => {
    const zone = zoneTreeCtx.getZoneById(zoneId);
    setSelectedZone(zone);
  };

  const formik = useAssignSensorFormik({
    initialValues: {
      name: '',
      is_enabled: true,
      serial: '',
      zone_id: selectedZone?.id ?? null,
      move: false,
    },
    validationSchema: () =>
      yup.object().shape({
        name: yup.string(),
        is_enabled: yup.boolean().required(),
        zone_id: yup
          .number()
          .transform((value) => (isNaN(value) ? undefined : value))
          .required(t('manage.sensors.common.zoneHousingUnitIsRequired')),
        serial: yup
          .string()
          .min(2, t('manage.sensors.common.serialNumberIsToShort'))
          .required(t('manage.sensors.common.serialNumberIsRequired'))
          .test(
            'Serial',
            t('manage.sensors.common.serialNumbersCanOnlyContainDigits'),
            (v) => (typeof v === 'string' ? /^\d+$/.test(v) : true)
          ),
      }),
    onSubmit: async (values, formikHelpers) => {
      const sensorType = 'roommate';
      // We hard code the sensor type to `roommate` for the time being
      const sensorId = `${sensorType}${values.serial}`;
      if (!values.zone_id || !sensorId) {
        return;
      }

      try {
        errorHandler.error && errorHandler.reset();

        await api.updateSensors(sensorId, {
          name: values.name,
          zone_id: values.zone_id,
          is_enabled: values.is_enabled,
          move: values.move,
        });

        formikHelpers.resetForm({ values });

        if (props.onCreate) {
          props.onCreate();
        }

        router.history.push(`/manage/${values.zone_id}/sensors`);
      } catch (error) {
        if (
          isAxiosError(error) &&
          error?.response?.data?.code === 'sensor-move-to-zone-confirmation'
        ) {
          const old_zone_name = error?.response?.data?.data?.old_zone_name;
          confirmation
            .confirm(
              t(
                'manage.sensors.assign.AssignNewSensor.sensorAlreadyAssociated',
                old_zone_name
              )
            )
            .then(() => {
              formik.setFieldValue('move', true);
              formik.submitForm();
            })
            .catch(() => {});
        } else {
          errorHandler.handleError(error);
        }
      }
    },
  });

  const error = errorIsString(errorHandler.error)
    ? errorHandler.error
    : undefined;

  return (
    <div className="AssignNewSensor">
      <SensorForm
        form={formik}
        error={error}
        selectedZone={selectedZone}
        onSelectZone={handleSetSelectedZone}
      />
    </div>
  );
};
