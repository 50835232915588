function formatEvent(event: string) {
  switch (event) {
    case 'created':
      return 'Ny';
    case 'updated':
      return 'Endret';
    case 'deleted':
      return 'Slettet';
  }
  return event;
}

export default formatEvent;
