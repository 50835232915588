import React from 'react';
import { Link, RouteComponentProps } from 'react-router-dom';

import { useApiCall } from '../../../../lib/useApiCall';
import { InlineSpinner } from '../../../../components/InlineSpinner';

import * as api from '../../../../serverApi';

import { t, TranslationKey } from '../../../../lib/i18n';
import { SensorClimaxDetails } from './climax/SensorClimaxDetails';
import { SensorRoomMateDetails } from './roommate/SensorRoomMateDetails';
import HeadingBar from '../../../super/shared/HeadingBar';
import { Card } from '../../../super/shared/Card';
import LoadingPlaceholder from '../../../../lib/ui/LoadingPlaceholder';
import { ErrorMessages } from '../../../../components/ErrorMessages';

export function SensorDetails(
  props: RouteComponentProps<{ sensorCompositeId: string }>
) {
  const sensorCompositeId = props.match.params.sensorCompositeId;
  const res = useApiCall(api.superVendorGetSensor, [sensorCompositeId]);
  const sensor = (res.data && res.data.sensor) || undefined;

  if (res.loading) {
    return <LoadingPlaceholder />;
  }

  return (
    <>
      <ErrorMessages errorApiCall={res.error} />
      <HeadingBar>
        <h2>
          {sensor ? (
            <>
              Sensor {sensor.sensor_id}{' '}
              <small>
                (
                {t(
                  `common.commonSensors.${sensor.sensor_type}` as TranslationKey
                )}
                )
              </small>
            </>
          ) : (
            'Sensor details'
          )}
        </h2>
        {res.loading && <InlineSpinner size="lg" />}
      </HeadingBar>

      {!res.loading && !sensor && (
        <Card>
          Sensor not found.
          <p>
            <Link to="/super/sensors">Back to sensor overview</Link>
          </p>
        </Card>
      )}

      {sensor?.sensor_type === 'roommate' && (
        <SensorRoomMateDetails
          markingObjects={res.data?.markingObjects ?? undefined}
          sensor={sensor}
          onRequestReload={res.reload}
        />
      )}

      {sensor && sensor.sensor_type !== 'roommate' && (
        <SensorClimaxDetails sensor={sensor} onRequestReload={res.reload} />
      )}
    </>
  );
}
