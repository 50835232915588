/**
 * Find all instances of a substring
 */
function getIndicesOf(needle: string, haystack: string) {
  let startIndex = 0;
  let index: number | undefined;
  const indices: number[] = [];
  if (needle.length === 0) {
    return [];
  }
  while ((index = haystack.indexOf(needle, startIndex)) > -1) {
    indices.push(index);
    startIndex = index + needle.length;
  }
  return indices;
}

export function formatString(str: string, ...args: any[]) {
  const positions = getIndicesOf('#', str);

  if (args.length < positions.length) {
    throw new Error(
      `Not enough arguments (${args.length}), needed ${positions.length}`
    );
  }

  let buffer = str;

  // We do the replacements in reverse so we don't affect the positions of the remaining placeholders
  for (let i = positions.length - 1; i >= 0; i--) {
    buffer = buffer.slice(0, positions[i]) + args[i] + buffer.slice(positions[i] + 1);
  }

  return buffer;
}
