import { FormikHelpers, useFormik } from 'formik';
import * as Yup from 'yup';

export interface ZoneFields {
  name: string;
}

export type ZoneFormik = ReturnType<typeof useZoneFormik>;

interface UseZoneFormikOptions {
  initialValues?: ZoneFields;
  validationSchema?: any | (() => any);
  onSubmit: (
    values: ZoneFields,
    formikHelpers: FormikHelpers<ZoneFields>
  ) => Promise<any>;
}

/**
 * Formik wrapper for create/update zone forms.
 */
export function useZoneFormik(opts: UseZoneFormikOptions) {
  return useFormik<ZoneFields>({
    initialValues: {
      name: '',
      ...opts.initialValues,
    },
    validateOnChange: true,
    validateOnBlur: false,
    validationSchema: () =>
      Yup.object().shape({
        name: Yup.string().required(),
      }),
    onSubmit: async (values, formikHelpers) => {
      await opts.onSubmit(values, formikHelpers);
    },
  });
}
