import cs from 'classnames';
import { IconButton } from '@material-ui/core';
import { Icon } from '../Icon';

import './AudioPlayer.scss';

interface Props {
  className?: string;
  onClick: React.MouseEventHandler<HTMLButtonElement>;
  playing?: boolean;
  disabled?: boolean;
}

export const PlaySoundButton = (props: Props) => {
  return (
    <IconButton
      className={cs(
        'AudioPlayer',
        props.className,
        props.disabled && 'AudioPlayer--disabled'
      )}
      disabled={props.disabled}
      onClick={props.onClick}
    >
      {
        <Icon
          icon="loudspeaker"
          style={{
            fill: props.playing ? undefined : '#858585',
          }}
        />
      }
    </IconButton>
  );
};
