import React, { useContext } from 'react';
import { Switch, Route } from 'react-router';

import { ListSensors } from './ListSensors';
import { SensorDetails } from './details/SensorDetails';
import useFilters from '../../super/shared/filters/useFilters';
import * as api from '../../../serverApi';
import { AxiosError } from 'axios';
import { getCurrentArea } from '../../../lib/getCurrentArea';

const sensorAreaContext = React.createContext<SensorAreaContext | undefined>(
  undefined
);

export type SensorFilters = ReturnType<typeof useSensorFilters>;

const useSensorFilters = () => {
  const currentArea = getCurrentArea();

  return useFilters<api.SuperVendorGetSensorsInput>({
    initialValues: {
      sensor_type: 'roommate',
      page_size: 100,
      sort: 'sensor_id asc',
    },
    key: currentArea ? `${currentArea}-sensors` : 'super-vendor-sensors',
  });
};

interface SensorAreaContext {
  filters: SensorFilters;
  data?: api.SuperVendorGetSensorsResult;
  loading?: boolean;
  error: AxiosError | null;
  reload: () => void;
}

export function useSensorArea(): SensorAreaContext {
  const ctx = useContext(sensorAreaContext);
  if (!ctx) {
    throw new Error('Hook must be called inside sensor area context.');
  }
  return ctx;
}

interface Props {
  rootUrl: string;
}

export function Sensors(props: Props) {
  const filters = useSensorFilters();

  const res = api.useSuperVendorGetSensorsQuery(filters.values, {
    keepPreviousData: true,
    retry: false,
  });

  const ctx = {
    filters: filters,
    data: res.data,
    loading: res.isLoading,
    reload: res.refetch,
    error: res.error,
  };

  return (
    <sensorAreaContext.Provider value={ctx}>
      <Switch>
        <Route exact={true} path={props.rootUrl}>
          <ListSensors rootUrl={props.rootUrl} />
        </Route>
        <Route
          exact={true}
          path={`${props.rootUrl}/:sensorCompositeId`}
          component={SensorDetails}
        />
      </Switch>
    </sensorAreaContext.Provider>
  );
}
