import {
  Table,
  TableRow,
  TableCell,
  TableHead,
  TableContainer,
  TableBody,
  Button,
  MenuItem,
  Select,
} from '@material-ui/core';
import * as api from '../../../../serverApi';
import { useApiCall } from '../../../../lib/useApiCall';

interface Props {
  disabled?: boolean;
  selectedRole?: string;
  roles?: Array<{
    user_role_id?: number;
    enabled?: boolean;
    role: string;
    valid_from?: null | string;
    valid_to?: null | string;
    zone_id?: null | number;
    zone?: null | {
      is_housing_unit: boolean;
      name: null | string;
      parent_zone_id: null | number;
      zone_id: number;
    };
  }>;
  buttonLabel?: string;
  setSelectedRole?: (role: string) => void;
  handleSubmit?: () => void;
  handleDelete?: (index: number) => void;
}

export function UserFormRolesTable(props: Props) {
  const roles = useApiCall(api.superGetAllRoles);

  return (
    <div className="UserFormRolesTable">
      <div className="UserFormRoles-add-role">
        <p className="mb-0 mr-3">Add global role: </p>

        <Select
          disabled={props.disabled}
          name="role"
          value={props.selectedRole ?? ''}
          className="UserFormRoles-select-role"
          onChange={(e) => {
            props.setSelectedRole?.(e.target.value as string);
          }}
        >
          <MenuItem value="">Select global role</MenuItem>
          {roles.data?.map((r) => {
            return (
              r.is_global &&
              !props.roles?.map((role) => role.role).includes(r.role) && (
                <MenuItem value={r.role} key={r.role}>
                  {r.name}
                </MenuItem>
              )
            );
          })}
        </Select>

        {props.buttonLabel && (
          <Button
            className="ml-4"
            variant="contained"
            disabled={props.disabled}
            onClick={props.handleSubmit}
          >
            {props.buttonLabel}
          </Button>
        )}
      </div>

      <div className="UserFormRoles-list">
        <TableContainer>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>ID</TableCell>
                <TableCell>Role</TableCell>
                <TableCell>Zone</TableCell>
                <TableCell>Period</TableCell>
                <TableCell>&nbsp;</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {props.roles?.map((userRole, i) => (
                <TableRow key={i}>
                  <TableCell component="th" scope="row">
                    {userRole.user_role_id}
                  </TableCell>
                  <TableCell>{userRole.role}</TableCell>
                  <TableCell>
                    {userRole.zone?.name || (
                      <em style={{ color: '#999' }}>Global</em>
                    )}
                  </TableCell>
                  <TableCell>
                    {!userRole.valid_from && !userRole.valid_to ? (
                      <span>No expiration</span>
                    ) : (
                      <>
                        From:{userRole.valid_from} - To:
                        {userRole.valid_to}
                      </>
                    )}
                  </TableCell>
                  <TableCell>
                    <Button
                      color="secondary"
                      variant="contained"
                      size="small"
                      disabled={props.disabled}
                      onClick={() => props.handleDelete?.(i)}
                    >
                      Delete
                    </Button>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </div>
    </div>
  );
}
