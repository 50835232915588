/**
 *
 *
 *
 * This file should be the primary definition of how event types are defined in the frontend.
 *
 * All sensor types and event types need to be present here, otherwise TypeScript will
 * give a compile-time error. It must match the sensor/event type structure generated in serverApi.ts.
 *
 *
 *
 */

import { IconDefinition } from './components/Icon';

import FallEventForm from './areas/manage/sensors/events/eventforms/FallEventForm';
import LeftRoomEventForm from './areas/manage/sensors/events/eventforms/LeftRoomEventForm';
import LeftBedEventForm from './areas/manage/sensors/events/eventforms/LeftBedEventForm';
import AroseInBedEventForm from './areas/manage/sensors/events/eventforms/AroseInBedEventForm';
import ActivityInRoomEventForm from './areas/manage/sensors/events/eventforms/ActivityInRoomEventForm';
import NoiseEventForm from './areas/manage/sensors/events/eventforms/NoiseEventForm';
import EnterRoomEventForm from './areas/manage/sensors/events/eventforms/EnterRoomEventForm';
import TooLongInBathroomEventForm from './areas/manage/sensors/events/eventforms/TooLongInBathroomEventForm';
import LeftChairEventForm from './areas/manage/sensors/events/eventforms/LeftChairEventForm';
import LastMovementEventForm from './areas/manage/sensors/events/eventforms/LastMovementEventForm';
import DoorContactOpenEventForm from './areas/manage/sensors/events/eventforms/DoorContactOpenEventForm';
import DoorContactCloseEventForm from './areas/manage/sensors/events/eventforms/DoorContactCloseEventForm';
import GeoPendantClickEventForm from './areas/manage/sensors/events/eventforms/GeoPendantClickEventForm';
import HeatDetectorFireEventForm from './areas/manage/sensors/events/eventforms/HeatDetectorFireEventForm';
import MotionDetectorMotionEventForm from './areas/manage/sensors/events/eventforms/MotionDetectorMotionEventForm';
import SmokeDetectorFireEventForm from './areas/manage/sensors/events/eventforms/SmokeDetectorFireEventForm';
import WaterDetectorWaterEventForm from './areas/manage/sensors/events/eventforms/WaterDetectorWaterEventForm';

import { EventFormProps } from './areas/manage/sensors/events/useEventSettingsForm';

import {
  SensorType,
  BySensorTypeAndEventType,
  MarkingObjectType,
} from './serverApi';
import PullCordPullEventForm from './areas/manage/sensors/events/eventforms/PullCordPullEventForm';
import PushButtonClickEventForm from './areas/manage/sensors/events/eventforms/PushButtonClickEventForm';
import WristButtonClickEventForm from './areas/manage/sensors/events/eventforms/WristButtonClickEventForm';

export type EventTypeDefinition = {
  /**
   * The event settings form component used to manage settings for events of this type.
   */
  settingsFormComponent?: (props: EventFormProps) => JSX.Element;

  /**
   * The icon used to represent events of this type.
   */
  icon: IconDefinition;

  /**
   * Whether this event type requires a marking of a specific type to function.
   */
  requiredMarkingType?: MarkingObjectType;

  /**
   * Whether we should prevent users from handling events of this type
   * from the frontend. This is used for the 'markings' type, which should
   * only be handled automatically by the system.
   */
  disableHandlingFromFrontend?: boolean;
};

const eventTypesBySensorTypeStrictlyTyped: BySensorTypeAndEventType<EventTypeDefinition> =
  {
    roommate: {
      fall: {
        settingsFormComponent: FallEventForm,
        icon: 'fall',
      },
      oob: {
        settingsFormComponent: LeftBedEventForm,
        icon: 'bed-exit',
        requiredMarkingType: 'bed',
      },
      hib: {
        settingsFormComponent: AroseInBedEventForm,
        icon: 'bed-upright',
        requiredMarkingType: 'bed',
      },
      act: {
        settingsFormComponent: ActivityInRoomEventForm,
        icon: 'activity',
      },
      ent: {
        settingsFormComponent: EnterRoomEventForm,
        icon: 'room-enter',
        requiredMarkingType: 'door_exit',
      },
      exi: {
        settingsFormComponent: LeftRoomEventForm,
        icon: 'room-exit',
        requiredMarkingType: 'door_exit',
      },
      ooc: {
        settingsFormComponent: LeftChairEventForm,
        icon: 'chair-exit',
        requiredMarkingType: 'chair',
      },
      tlib: {
        settingsFormComponent: TooLongInBathroomEventForm,
        icon: 'timeout',
        requiredMarkingType: 'bathroom',
      },
      hsnd: {
        settingsFormComponent: NoiseEventForm,
        icon: 'sound',
      },
      lmib: {
        settingsFormComponent: LastMovementEventForm,
        icon: 'bed-microactivity',
        requiredMarkingType: 'bed',
      },
      markings: {
        icon: 'tools',
        // Events of this type should only be handled
        // by the system itself.
        // disableHandlingFromFrontend: true,

        // Update 2023-09-20: The specification for the markings
        // event type is in flux -- we'll allow users to check
        // these events for now.
      },
      abs: {
        icon: 'help',
      },
      bedmark: {
        icon: 'help',
      },
      fire: {
        icon: 'fire',
      },
      floor: {
        icon: 'help',
      },
      inact: {
        icon: 'help',
      },
      offline: {
        icon: 'disconnected',
      },
      online: {
        icon: 'connected',
      },
      speech: {
        icon: 'help',
      },
      startup: {
        icon: 'help',
      },
    },
    climax_door_contact: {
      open: {
        settingsFormComponent: DoorContactOpenEventForm,
        icon: 'door-open',
      },
      close: {
        settingsFormComponent: DoorContactCloseEventForm,
        icon: 'door-closed',
      },
      battery: {
        icon: 'battery-low',
      },
      tamper: {
        icon: 'tools',
      },
    },
    climax_geo_pendant: {
      click: {
        settingsFormComponent: GeoPendantClickEventForm,
        icon: 'wrist-button',
      },
      battery: {
        icon: 'battery-low',
      },
      doubleclick: {
        icon: 'wrist-button-2x',
      },
    },
    climax_heat_detector: {
      fire: {
        settingsFormComponent: HeatDetectorFireEventForm,
        icon: 'fire',
      },
      battery: {
        icon: 'battery-low',
      },
    },
    climax_motion_detector: {
      motion: {
        settingsFormComponent: MotionDetectorMotionEventForm,
        icon: 'activity',
      },
      battery: {
        icon: 'battery-low',
      },
    },
    climax_pull_cord: {
      pull: {
        settingsFormComponent: PullCordPullEventForm,
        icon: 'pull-cord',
      },
      battery: {
        icon: 'battery-low',
      },
      doublepull: {
        icon: 'pull-cord-2x',
      },
    },
    climax_push_button: {
      click: {
        settingsFormComponent: PushButtonClickEventForm,
        icon: 'panic-button',
      },
      battery: {
        icon: 'battery-low',
      },
      doubleclick: {
        icon: 'panic-button-2x',
      },
    },
    climax_smoke_detector: {
      fire: {
        settingsFormComponent: SmokeDetectorFireEventForm,
        icon: 'fire',
      },
      battery: {
        icon: 'battery-low',
      },
    },
    climax_water_detector: {
      water: {
        settingsFormComponent: WaterDetectorWaterEventForm,
        icon: 'water',
      },
      battery: {
        icon: 'battery-low',
      },
    },
    climax_wrist_button: {
      click: {
        icon: 'wrist-button',
        settingsFormComponent: WristButtonClickEventForm,
      },
      battery: {
        icon: 'battery-low',
      },
      doubleclick: {
        icon: 'wrist-button-2x',
      },
    },
  };

/**
 * The preferred order of event types in the user interface, grouped by sensor type.
 */
export const eventTypesInOrderBySensorType: {
  [sensorType in SensorType]: Array<
    keyof (typeof eventTypesBySensorTypeStrictlyTyped)[sensorType]
  >;
} = {
  roommate: [
    'fall',
    'oob',
    'hib',
    'act',
    'ent',
    'exi',
    'ooc',
    'tlib',
    'hsnd',
    'lmib',
    'markings',
  ],
  climax_door_contact: ['open', 'close'],
  climax_geo_pendant: ['click', 'doubleclick'],
  climax_heat_detector: ['fire'],
  climax_motion_detector: ['motion'],
  climax_pull_cord: ['pull', 'doublepull'],
  climax_push_button: ['click', 'doubleclick'],
  climax_smoke_detector: ['fire'],
  climax_water_detector: ['water'],
  climax_wrist_button: ['click', 'doubleclick'],
};

/**
 * We export an object with looser types so we can use string indexes for event types:
 */
export const eventTypesBySensorType: {
  [sensorType in SensorType]: {
    [eventType: string]: EventTypeDefinition;
  };
} = eventTypesBySensorTypeStrictlyTyped;
