function repeatString(str: string, count: number) {
  if (str.length * count >= 1 << 28) {
    throw new RangeError('repeat count must not overflow maximum string size');
  }

  var maxCount = str.length * count;
  let remaining = Math.floor(Math.log(count) / Math.log(2));
  while (remaining) {
    str += str;
    remaining--;
  }
  str += str.substring(0, maxCount - str.length);
  return str;
}

export function renderNationalIdNumber(nr: string | undefined | null): string {
  if (!nr) {
    return '';
  }

  if (typeof nr === 'number') {
    return renderNationalIdNumber(`${nr}`);
  }

  if (typeof nr === 'string' && /^[0-9]{7,}$/.test(nr)) {
    let result = nr.substring(0, 6);
    let fill = repeatString('*', nr.length - 6);
    return `${result}${fill}`;
  }
  return (nr as unknown) as string;
}
