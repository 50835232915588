import { getHighlightRangeAndMidnightDate } from './helpers/getHighlightRangeAndMidnightDate';

interface Props {
  startTime: string;
  margin: { top: number; right: number; bottom: number; left: number };
  xScale: d3.ScaleTime<number, number, never>;
  width: number;
  height: number;
}

export const HighlightedAreaAndVerticalRuler = (props: Props) => {
  const { startTime, margin, xScale, width, height } = props;

  const highlight = getHighlightRangeAndMidnightDate(startTime);
  const midnight = xScale(highlight.midnight);

  /**
   * Avoid rendering highlighted area if width is not calculated properly yet
   */
  if (width <= 1) {
    return null;
  }

  return (
    <svg className="BehavioralDataChartOverlay">
      <clipPath id="highlight-clip-path">
        <rect
          x={margin.left}
          y={margin.top}
          width={width - margin.left}
          height={height - margin.top - 25} // Height calculated by chart wrapping div height and removing top margin and x-axis height
        />
      </clipPath>
      {highlight.range.map((highlight) => {
        const rectStart = xScale(highlight.start);
        const rectEnd = xScale(highlight.end);
        return (
          <rect
            key={highlight.id}
            clipPath="url(#highlight-clip-path)"
            x={rectStart}
            y={margin.top}
            width={rectEnd - rectStart}
            height={height}
            fill="#000000"
            opacity={0.03}
          />
        );
      })}

      <line
        clipPath="url(#highlight-clip-path)"
        x1={midnight}
        x2={midnight}
        y1={margin.top}
        y2={height}
        strokeWidth={3}
        stroke="#b6b6b3"
      />
    </svg>
  );
};
