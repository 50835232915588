import React, { useState } from 'react';
import { Button } from '../../Button';
import { ModalFooter } from 'reactstrap';
import {
  FormControl,
  FormControlLabel,
  FormGroup,
  Radio,
  RadioGroup,
  TextField,
} from '@material-ui/core';
import ModalWrapper from '../ModalWrapper';
import { InlineError } from '../../InlineError';
import { ButtonGroup } from '../../ButtonGroup';

import { t } from '../../../lib/i18n';

import './VkpReceiptModal.scss';
import { useAppContext } from '../../../lib/global';
import { generateAutoCompleteValue } from '../../../lib/utils/generateAutoCompleteValue';

interface Props {
  isOpen: boolean;
  loading?: boolean;
  error?: boolean;
  selected?: number;
  initialNote?: string;
  onClose?: () => void;
  onSubmitRequest: (note: string, vkp?: boolean) => void;
}

interface SelectedOption {
  option: string;
  text: string;
}

export function VkpReceiptModal(props: Props) {
  const appCtx = useAppContext();
  const [textAreaValue, setTextAreaValue] = useState(props.initialNote || '');

  const initialSelectedOption =
    props.initialNote &&
    (props.initialNote === 'Tiltak iverksatt' ||
      props.initialNote === 'Tiltak ikke nødvendig')
      ? {
          option: props.initialNote,
          text: props.initialNote,
        }
      : undefined;

  const [selectedOption, setSelectedOption] = useState<
    SelectedOption | undefined
  >(initialSelectedOption);

  function handleChange(e: React.ChangeEvent<HTMLInputElement>) {
    setSelectedOption({
      option: e.target.value,
      text: e.target.value,
    });
  }

  function handleTextAreaChange(
    e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>
  ) {
    setTextAreaValue(e.target.value);
  }

  function handleOnHide() {
    if (props.onClose) {
      props.onClose();
    }
  }

  function handleOnReset() {
    if (!props.initialNote) {
      setTextAreaValue('');
    }
    setSelectedOption(undefined);
  }

  function handleSubmit(vkp?: boolean) {
    const noteText = selectedOption
      ? `${selectedOption.text}. ${textAreaValue}`
      : textAreaValue;

    props.onSubmitRequest(noteText, vkp);
  }

  const vkpEnabled = !appCtx.user || appCtx.user?.vkp_enabled || false;
  return (
    <ModalWrapper
      title={`${t(
        'components.VkpReceiptModal.doYouWantToRegisterTheEventInEPJ'
      )} ?`}
      className="VkpReceiptModal"
      isOpen={props.isOpen}
      showFooter={false}
      onHide={handleOnHide}
      onResetModal={handleOnReset}
      loading={props.loading}
      staticBackdrop={true}
    >
      <h6>{t('components.VkpReceiptModal.journalLyrics')}:</h6>
      <FormGroup>
        <FormControl component="fieldset" className="w-100">
          <RadioGroup
            aria-label="user"
            name="user"
            value={selectedOption ? selectedOption.option : ''}
            onChange={handleChange}
          >
            <FormControlLabel
              value="Tiltak iverksatt"
              label={t('components.VkpReceiptModal.actionTaken')}
              control={<Radio color="primary" disabled={!vkpEnabled} />}
            />

            <FormControlLabel
              value="Tiltak ikke nødvendig"
              label={t('components.VkpReceiptModal.noActionRequired')}
              control={<Radio color="primary" disabled={!vkpEnabled} />}
            />
          </RadioGroup>

          <FormGroup className="mt-0">
            <TextField
              autoComplete={generateAutoCompleteValue()}
              type="text"
              name="free-text"
              placeholder={t('components.VkpReceiptModal.eventualComments')}
              className="VkpReceiptModal-comment-input"
              disabled={props.loading || !vkpEnabled}
              multiline={true}
              value={textAreaValue}
              onChange={handleTextAreaChange}
            />
          </FormGroup>
        </FormControl>
      </FormGroup>

      <ModalFooter className="ModalWrapper-footer">
        {!vkpEnabled && (
          <div className="ErrorHandler">
            {t('components.VkpReceiptModal.userHasNoVkp')}
          </div>
        )}
        <ButtonGroup>
          <Button
            color="primary"
            onClick={() => handleSubmit(true)}
            disabled={props.loading || !vkpEnabled}
            icon={props.loading ? 'gear' : undefined}
            spinIcon={props.loading}
          >
            {t('common.commonButtons.yes')}
          </Button>
          <Button
            color="primary"
            onClick={() => handleSubmit(false)}
            disabled={props.loading}
            icon={props.loading ? 'gear' : undefined}
            spinIcon={props.loading}
          >
            {t('common.commonButtons.no')}
          </Button>
          <Button onClick={handleOnHide}>
            {t('common.commonButtons.cancel')}
          </Button>
        </ButtonGroup>
      </ModalFooter>
      {props.error && <InlineError>{props.error}</InlineError>}
    </ModalWrapper>
  );
}
