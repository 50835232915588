import React, { useState, useEffect } from 'react';
import Select from 'react-select';
import ModalWrapper from '../../ModalWrapper';
import * as api from '../../../../serverApi';
import { t } from '../../../../lib/i18n';
import { isAxiosError } from '../../../../lib/utils/errorUtils';

export interface SelectAccountProps {
  isOpen: boolean;
  handleModal: () => void;
  sensor: api.SuperVendorSensor;
  accountId: number | null;
  onAccountSet: (account: api.Account | null) => void;
}

export default function SensorSetVendorModal(props: SelectAccountProps) {
  const [error, setError] = useState('');
  const [loading, setLoading] = useState(false);
  const [selectedVendor, setSelectedVendor] = useState<api.Account | null>(
    null
  );

  const vendorsResponse = api.useSuperVendorGetAccountsNewQuery({
    is_vendor: true,
  });

  const vendors = vendorsResponse?.data?.data ?? [];

  useEffect(() => {
    const found = vendorsResponse?.data?.data?.find(
      (a: api.Account) => a.account_id === props.accountId
    );
    if (!found) {
      return;
    }

    setSelectedVendor(found);
  }, [vendorsResponse.data, props.accountId, props.isOpen]);

  const resetModal = () => {
    setSelectedVendor(null);
  };

  const handleSubmit = async () => {
    if (!selectedVendor) {
      return;
    }

    try {
      setError('');
      setLoading(true);
      await api.superVendorUpdateSensor(props.sensor.id, {
        vendor_account_id: selectedVendor.account_id,
      });
      setLoading(false);
      props.handleModal();
    } catch (e) {
      if (isAxiosError(e)) {
        setError(e.message);
      } else {
        setError(String(e));
      }
      setLoading(false);
    }
    props.onAccountSet(selectedVendor);
    props.handleModal();
  };

  const handleSelect = (account: api.Account | null) =>
    setSelectedVendor(account);

  return (
    <ModalWrapper
      title={t('components.SensorSetVendorModal.selectVendor')}
      okButtonText={t('common.commonButtons.select')}
      cancelButtonText={t('common.commonButtons.cancel')}
      isOpen={props.isOpen}
      loading={loading}
      onHide={() => props.handleModal()}
      onSubmit={handleSubmit}
      onResetModal={resetModal}
    >
      {error && <div className="alert alert-danger">{error}</div>}
      <div>
        <Select
          isClearable
          placeholder={t('common.commonInputs.select')}
          isLoading={vendorsResponse.isLoading}
          options={vendors}
          value={selectedVendor}
          getOptionLabel={(data: api.Account) => data['name']}
          getOptionValue={(data: api.Account) => data['account_id'].toString()}
          onChange={(selectedOption: any) => handleSelect(selectedOption)}
        />
      </div>
    </ModalWrapper>
  );
}
