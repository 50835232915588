import React, { useContext } from 'react';
import * as api from '../../../serverApi';

export type SidebarMode = 'alarms' | 'alerts' | 'sensors' | 'info';

/**
 * Depth = Anonymised
 * Amp = Detailed
 * Raw = Anonymised without coloration
 */
export type StreamType = 'depth' | 'amp' | 'raw';

export type Sensor = api.CareSensor;

export type SensorWithActiveEvent = Sensor & { activeEvent?: Event };

export type Event = api.CareEvent & {
  sensor: Sensor;
};

export interface CareContext {
  /**
   * Get event by event ID.
   */
  event: {
    [event_id: number]: Event | undefined;
  };

  /**
   * Get sensor by sensor ID.
   */
  sensor: {
    [sensor_id: string]: Sensor | undefined;
  };

  /**
   * Get a list of all alarms, in the order received from the server.
   */
  alarms: Event[] | undefined;

  /**
   * Get a list of all alerts, in the order received from the server.
   */
  alerts: Event[] | undefined;

  /**
   * Get a list of all sensors, in the order received from the server.
   */
  sensors: SensorWithActiveEvent[] | undefined;

  /**
   * Timestamp of the latest update on events/sensors data (Used when streaming only)
   */
  dataTimestamp?: number;

  /**
   * Get the currently selected sensor, if any.
   */
  selectedSensor: undefined | Sensor;

  /**
   * Select a given sensor by ID, or undefined to unselect any currently selected sensor.
   */
  selectSensor: (sensor_id: string | undefined) => void;

  sidebarMode: SidebarMode;
  setSidebarMode: (mode: SidebarMode) => void;

  streamType: StreamType | undefined;
  setStreamType: (streamType: StreamType | undefined) => void;

  selectedEvent: undefined | Event;
  selectEvent: (event_id: number | undefined) => void;

  /**
   * Whether the alarm section should indicate activity (blinking etc.)
   */
  attentionAlarms: boolean;

  /**
   * Whether the alert section should indicate activity (blinking etc.)
   */
  attentionAlerts: boolean;

  /**
   * Call this to clear any attention on the alarm section.
   */
  clearAlarmAttention: () => void;

  /**
   * Call this to clear any attention on the alert section.
   */
  clearAlertAttention: () => void;

  markEventAsHandled: (event: Event, note?: string, vkp?: boolean) => void;

  presenceSensor: (id: string) => void;

  setShowDisableModal: (state: boolean) => void;

  setShowHelpModal: (state: boolean) => void;

  showInfo: boolean;

  setShowInfo: (show: boolean) => void;

  showMainContentMobile: boolean;

  toggleMainContent: () => void;

  activeZone?: api.StructureZone;

  handleActiveZoneSelect: (zone?: api.StructureZone) => void;

  searchEnabled: boolean;

  setSearchEnabled: (enabled: boolean | ((prev: boolean) => boolean)) => void;

  continueAudioStreaming: boolean;

  setContinueAudioStreaming: (
    value: boolean | ((prev: boolean) => boolean)
  ) => void;
}

const careContext = React.createContext<CareContext>({
  event: {},
  sensor: {},
  alarms: [],
  alerts: [],
  sensors: [],
  dataTimestamp: undefined,
  selectedSensor: undefined,
  selectSensor: () => {},
  sidebarMode: 'sensors',
  setSidebarMode: () => {},
  streamType: undefined,
  setStreamType: () => {},
  selectedEvent: undefined,
  selectEvent: () => {},
  attentionAlarms: false,
  attentionAlerts: false,
  markEventAsHandled: () => {},
  clearAlarmAttention: () => {},
  clearAlertAttention: () => {},
  presenceSensor: () => {},
  setShowDisableModal: () => {},
  setShowHelpModal: () => {},
  showInfo: false,
  setShowInfo: () => {},
  showMainContentMobile: false,
  toggleMainContent: () => {},
  activeZone: undefined,
  handleActiveZoneSelect: () => {},
  searchEnabled: false,
  setSearchEnabled: (enabled: boolean | ((prev: boolean) => boolean)) => {},
  continueAudioStreaming: false,
  setContinueAudioStreaming: () => {},
});

export const useCareContext = () => useContext(careContext);

const { Provider } = careContext;

export { Provider };
