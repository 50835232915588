import { t, TranslationKey } from '../../../lib/i18n';

import './LevelIndicator.scss';

interface Props {
  level?: string | null;
}

export function LevelIndicator(props: Props) {
  if (props.level !== 'alert' && props.level !== 'alarm') {
    return null;
  }

  return (
    <div className={`LevelIndicator LevelIndicator--${props.level}`}>
      {t(`care.components.InfoPage.levels.${props.level}` as TranslationKey)}
    </div>
  );
}
