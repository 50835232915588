import React from 'react';
import { FormControlLabel, RadioGroup, Radio } from '@material-ui/core';

import useZoneSettingsForm from './lib/useZoneSettingsForm';
import { Panel } from '../../../../../components/Panel';
import { useZoneContext } from '../../../../../lib/ZoneContext';
import { FormBody } from './lib/FormBody';

import * as api from '../../../../../serverApi';
import { t } from '../../../../../lib/i18n';
import { useAppContext } from '../../../../../lib/global';

const fields: Array<keyof api.ZoneSettings> = [
  'enable_behavioral_data',
  'enable_behavioral_data_audio',
];

export function BehavioralData() {
  const ctx = useZoneContext();
  const form = useZoneSettingsForm({ fields });
  const appContext = useAppContext();

  return (
    <div>
      <h2>
        {t('manage.zone.BehavioralData.headingFor')} {ctx.activeZone.name}
      </h2>

      <Panel>
        <strong>{t('common.commonTexts.tips')}:</strong>{' '}
        {t('manage.zone.BehavioralData.tips')}
      </Panel>

      <FormBody zoneSettingsForm={form}>
        <h2 style={{ marginTop: '32px', marginBottom: '8px' }}>
          {t('manage.zone.BehavioralData.general-heading')}
        </h2>

        <RadioGroup
          aria-label={t('manage.zone.settings.Settings.areas.behavioral-data')}
          name="enable_behavioral_data"
          value={form.displayValues?.enable_behavioral_data?.toString() || ''}
          onChange={(e) => {
            form.formik.setFieldValue(
              'enable_behavioral_data',
              e.currentTarget.value === 'true'
            );
            form.formik.setFieldTouched('enable_behavioral_data');
          }}
        >
          <FormControlLabel
            disabled={form.disabled}
            value="true"
            control={<Radio color="primary" />}
            label={t('common.commonTexts.enabled')}
          />
          <FormControlLabel
            disabled={form.disabled}
            value="false"
            control={<Radio color="primary" />}
            label={t('common.commonTexts.notActivated')}
          />
        </RadioGroup>

        {appContext.hasFeature('show-sound-dimension') && (
          <>
            <h2 style={{ marginTop: '32px', marginBottom: '8px' }}>
              {t('manage.zone.BehavioralData.audio-heading')}
            </h2>

            <RadioGroup
              aria-label={t(
                'manage.zone.settings.Settings.areas.behavioral-data'
              )}
              name="enable_behavioral_data_audio"
              value={
                form.displayValues?.enable_behavioral_data_audio?.toString() ||
                ''
              }
              onChange={(e) => {
                form.formik.setFieldValue(
                  'enable_behavioral_data_audio',
                  e.currentTarget.value === 'true'
                );
                form.formik.setFieldTouched('enable_behavioral_data_audio');
              }}
            >
              <FormControlLabel
                disabled={
                  form.disabled ||
                  form.displayValues?.enable_behavioral_data === false
                }
                value="true"
                control={<Radio color="primary" />}
                label={t('common.commonTexts.enabled')}
              />
              <FormControlLabel
                disabled={
                  form.disabled ||
                  form.displayValues?.enable_behavioral_data === false
                }
                value="false"
                control={<Radio color="primary" />}
                label={t('common.commonTexts.notActivated')}
              />
            </RadioGroup>
          </>
        )}
      </FormBody>
    </div>
  );
}
