import React from 'react';
import ModalWrapper from './ModalWrapper';
import { t } from '../../lib/i18n';

import './VideoModal.scss';

interface Props {
  isOpen: boolean;
  title?: string;
  src?: string;
  onToggle(): void;
}

export function VideoModal(props: Props) {
  return (
    <ModalWrapper
      title={props.title || ''}
      isOpen={props.isOpen}
      showOkButton={false}
      cancelButtonText={t('common.commonButtons.cancel')}
      onHide={() => props.onToggle()}
      className="VideoModal"
      showFooter={false}
    >
      <div className="VideoModal-wrapper">
        <iframe
          src={props?.src}
          frameBorder="0"
          allow="autoplay; fullscreen"
          allowFullScreen={true}
          title={props?.title || t('components.VideoModal.video')}
        />
      </div>
    </ModalWrapper>
  );
}

//  width=\"426\" height=\"240\"
