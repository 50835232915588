import React, { useState } from 'react';
import { useZoneContext } from '../../../lib/ZoneContext';
import { useApiCall } from '../../../lib/useApiCall';
import { useRouter } from '../../../lib/useRouter';
import { CareReceiversTable } from './ui/CareReceiversTable';
import { IconButton } from '../../../components/IconButton';
import FilterInput from '../../../components/FilterInput/FilterInput';

import { t } from '../../../lib/i18n';

import * as api from '../../../serverApi';
import matchSorter from 'match-sorter';
import {
  ManageHelpDropdown,
  ManageHelpOption,
} from '../common/ManageHelpDropdown';

export function ListCareReceivers() {
  const [keyword, setKeyword] = useState('');

  const router = useRouter();
  const zoneContext = useZoneContext();
  const res = useApiCall(api.getCareReceiversByZone, [
    zoneContext.activeZone.id,
  ]);

  const onExport = () => {
    const a = document.createElement('a');
    a.href = `/api/care-receivers/${zoneContext.activeZone.id}/care-receivers.xlsx`;
    a.download = `care-receivers-${zoneContext.activeZone.id}.xlsx`;
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
  };

  const filterCareReceivers = (
    careReceivers: api.GetCareReceiversByZoneResult['care_receivers'],
    keyword: string
  ) => {
    if (!keyword) {
      return careReceivers;
    }

    return matchSorter(careReceivers, keyword, {
      keys: ['first_name', 'last_name', 'zone.name', 'parent_zone.name'],
    });
  };

  const careReceivers = res?.data?.care_receivers
    ? filterCareReceivers(res.data.care_receivers, keyword)
    : [];

  const helpDropdownOptions: ManageHelpOption[] = [
    {
      title: t('common.video.manage.addCareReceiver.title'),
      vimeoId: t('common.video.manage.addCareReceiver.vimeo'),
      type: 'video',
    },
    {
      title: t('common.video.manage.moveCareReceiver.title'),
      vimeoId: t('common.video.manage.moveCareReceiver.vimeo'),
      type: 'video',
    },
    {
      title: t('common.pdf.manage.systemAdministrator.title'),
      link: t('common.url.adminUserGuidePdf'),
      type: 'pdf',
    },
  ];

  return (
    <>
      <div className="Manage__heading-wrapper">
        <h1 className="m-0">
          {t('manage.careReceivers.ListCareReceivers.serviceRecipients')}:{' '}
          {zoneContext.activeZone.name}
        </h1>
        <ManageHelpDropdown options={helpDropdownOptions} />
      </div>

      <div className="d-flex align-items-start justify-content-between flex-column flex-sm-row my-4">
        <div>
          <IconButton
            icon="plus"
            color="primary"
            to={router.match.path + '/new'}
            data-cy="manage-add-care-receiver"
          >
            {t('manage.careReceivers.common.addServiceRecipient')}
          </IconButton>
        </div>
        <div className="d-flex flex-column-reverse flex-sm-column">
          <FilterInput
            onChange={(name, value) => setKeyword(value)}
            className="mb-2"
          />
          <div>
            <IconButton
              color="primary"
              icon="export"
              onClick={onExport}
              disabled={!res.data || res.data.care_receivers.length === 0}
            >
              {t('common.commonTexts.exportToExcel')}
            </IconButton>
          </div>
        </div>
      </div>

      <CareReceiversTable
        className="mt-4"
        loading={res.loading}
        careReceivers={careReceivers}
      />
    </>
  );
}
