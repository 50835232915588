import { useLocalStorageState } from '../../../care/lib/useLocalStorageState';

interface FiltersOptions<Values> {
  initialValues: Values;
  key: string;
}

const LOCAL_STORAGE_PREFIX = 'useFilters-';

/**
 *
 * @param FilterOptions:
 *         key - the key to use for local storage
 *         initialValues - the initial values to use for the filters
 *
 * @returns Object with the following properties:
 *      values - the current values of the filters
 *      setFilters - a function to set the values of the filters
 *      setFilter - a function to set a single filter
 *      reset - a function to reset the filters to their initial values
 */

export default function useFilters<T extends { [field: string]: any }>({
  initialValues,
  key,
}: FiltersOptions<T>) {
  const FILTERS_LOCAL_STORAGE_KEY = LOCAL_STORAGE_PREFIX + key;

  const [filters, setFilters] = useLocalStorageState<T>(
    FILTERS_LOCAL_STORAGE_KEY,
    initialValues
  );

  const setFilter = (f: keyof T, value?: any) => {
    setFilters((prevFilters) => ({ ...prevFilters, [f]: value }));
  };

  const _setFilters = (newFilters: T) => {
    setFilters((prevFilters) => ({ ...prevFilters, ...newFilters }));
  };

  const reset = () => {
    setFilters(initialValues);
  };

  return {
    values: filters,
    setFilters: _setFilters,
    setFilter,
    reset,
  };
}

export type UseFilters = ReturnType<typeof useFilters>;
