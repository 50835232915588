import React, { useState } from 'react';
import { MoveSensorModal } from '../../../../components/modals/sensors/MoveSensorModal';
import { useSensorContext } from '../../../../lib/SensorContext';
import { IconButton } from '../../../../components/IconButton';
import { t } from '../../../../lib/i18n';

interface Props {
  disabled?: boolean;
  className?: string;
  onChanged: () => void;
}

export default function MoveSensorButton(props: Props) {
  const [moveSensorModal, setMoveSensorModal] = useState(false);

  const sensor = useSensorContext();

  const handleOnMove = () => {
    setMoveSensorModal(!moveSensorModal);
    if (props.onChanged) {
      props.onChanged();
    }

    if (sensor && sensor.reload) {
      sensor.reload();
    }
  };

  return (
    <>
      <IconButton
        icon="map-marker"
        color="primary"
        className={props.className}
        disabled={props.disabled}
        onClick={() => setMoveSensorModal(!moveSensorModal)}
      >
        {t('manage.sensors.details.MoveSensorButton.moveToAnotherUnit')}
      </IconButton>

      <MoveSensorModal
        isOpen={moveSensorModal}
        handleModal={() => setMoveSensorModal(!moveSensorModal)}
        onMove={handleOnMove}
      />
    </>
  );
}
