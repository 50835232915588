import React, { useState } from 'react';
import * as Sentry from '@sentry/browser';

import * as api from '../../../serverApi';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSyncAlt, faSpinnerThird } from '@fortawesome/pro-solid-svg-icons';

import TableCell from '@material-ui/core/TableCell';
import { faPlus } from '@fortawesome/pro-light-svg-icons';

import { Chip, Button, InputAdornment } from '@material-ui/core';
import { HeadingBar } from '../../super/shared/HeadingBar';
import { toast } from 'react-toastify';
import { t, TranslationKey } from '../../../lib/i18n';
import { Card } from '../../super/shared/Card';
import { Link } from 'react-router-dom';
import { FullScreenLoader } from '../../../components/FullScreenLoader/FullScreenLoader';
import { useAppContext } from '../../../lib/global';
import useFilters from '../../super/shared/filters/useFilters';
import { FiltersWrapper } from '../../super/shared/filters/FiltersWrapper';
import { VendorFilter } from '../../super/shared/filters/VendorFilter';
import { ErrorMessages } from '../../../components/ErrorMessages';
import { useSuperVendorGetAccountsNewQuery } from '../../../serverApi';
import { UsePagination } from '../../../hooks/usePagination';
import { Pagination } from '../../../components/Pagination/Pagination';
import { HasVendorsFilter } from './HasVendorsFilter';
import { faTimes, faSearch } from '@fortawesome/pro-solid-svg-icons';
import { DebouncedInput } from './DebouncedInput';
import { SortColumnI, Table } from '../../super/shared/Table';
import { isAxiosError } from '../../../lib/utils/errorUtils';
import { getCurrentArea } from '../../../lib/getCurrentArea';

interface Props {
  rootUrl: string;
}

const columns = [
  {
    key: 'account_id',
    header: t('supervendor.ListAccounts.accountId'),
    sortable: true,
  },
  {
    key: 'name',
    header: t('supervendor.ListAccounts.accountName'),
    sortable: true,
  },
  {
    key: 'account_code',
    header: t('supervendor.ListAccounts.accountCode'),
    sortable: true,
  },
  { key: 'account_vendors', header: t('supervendor.ListAccounts.vendors') },
  { key: 'actions', header: '' },
];

export function ListAccounts(props: Props) {
  const app = useAppContext();

  const [switchingToManage, setSwitchingToManage] = useState(false);

  const currentArea = getCurrentArea();

  const filters = useFilters<api.SuperVendorGetAccountsNewInput>({
    initialValues: { sort: 'name asc', page: 1, page_size: 100 },
    key: currentArea ? `${currentArea}-accounts` : 'super-vendor-accounts',
  });

  const query = useSuperVendorGetAccountsNewQuery(filters.values, {
    keepPreviousData: true,
  });
  const accounts = query?.data?.data ?? [];

  if (switchingToManage) {
    return <FullScreenLoader />;
  }

  const handleSortClick = (columnKey: string) => {
    const currentSort = transformSortStringToObject(filters.values.sort);

    /**
     * We sort by ascending order if there's no previous sorting column or we're switching the
     * column that's being sorted
     */
    if (!currentSort || currentSort.key !== columnKey) {
      filters.setFilter('sort', `${columnKey} asc`);
      return;
    }

    /**
     * Otherwise, just switch the sorting order
     */
    if (currentSort?.key === columnKey) {
      const newSortOrder = currentSort.type === 'asc' ? 'desc' : 'asc';

      filters.setFilter('sort', `${columnKey} ${newSortOrder}`);
    }
  };

  const handleAccountsFiltersChange = (
    data: api.SuperVendorGetAccountsNewInput
  ) => {
    filters.setFilters({ ...filters.values, ...data });
    paginationData.reset();
  };

  const impersonateClick = async (account_id: number) => {
    try {
      setSwitchingToManage(true);
      await api.impersonateAccount({ account_id, redirect_url: '/super' });
      window.location.reload();
    } catch (e) {
      Sentry.captureException(e);
      setSwitchingToManage(false);
      if (isAxiosError(e)) {
        if (e.response?.data?.code) {
          const errorCode = e.response.data.code;
          toast.error(t(`common.serverError.${errorCode}` as TranslationKey));
        } else {
          console.error(e.response);
        }
      }
      console.error(e);
    }
  };

  const rows = accounts.map((data) => {
    return {
      account_id: (
        <TableCell component="th" scope="row">
          {data.account_id}
        </TableCell>
      ),
      name: (
        <TableCell>
          {data.name}
          {data.is_vendor ? (
            <Chip className="ml-2" size="small" label="Forhandler" />
          ) : null}
        </TableCell>
      ),
      account_code: <TableCell>{data.account_code}</TableCell>,
      account_vendors: (
        <TableCell>
          <RenderAccountVendors account={data} />
        </TableCell>
      ),
      actions: (
        <TableCell>
          {(app.hasCapability('globalSuper') ||
            app.hasCapability('vendorUpdateAccounts')) && (
            <Button
              component={Link}
              to={`${props.rootUrl}/${data.account_id}/edit`}
            >
              {t('supervendor.ListAccounts.edit')}
            </Button>
          )}
          {(app.hasCapability('superImpersonateAccounts') ||
            app.hasCapability('vendorImpersonateAccounts')) && (
            <Button
              className="ml-2"
              onClick={() => impersonateClick(data.account_id)}
            >
              {t('supervendor.ListAccounts.goToManageArea')}
            </Button>
          )}
        </TableCell>
      ),
      id: data.account_id,
    };
  });

  const paginationData: UsePagination = {
    data: accounts,
    currentPage: filters.values.page || 1,
    itemsPerPage: filters.values.page_size || 100,
    totalPages: Array.from({ length: query.data?.last_page || 1 }),
    setCurrentPage(page: number | ((page: number) => number)) {
      filters.setFilter('page', page);
    },
    setItemsPerPage(itemsPerPage: number | ((itemsPerPage: number) => number)) {
      filters.setFilter('page_size', itemsPerPage);
    },
    reset() {
      filters.setFilters({
        page: 1,
        page_size: filters.values.page_size || 100,
      });
    },
  };

  return (
    <>
      <ErrorMessages errorApiCall={query.error} />
      <HeadingBar>
        <h2>{t('supervendor.ListAccounts.title')}</h2>
        <div
          style={{
            height: '25px',
            width: '25px',
            fontSize: '25px',
            lineHeight: '25px',
          }}
        >
          {query.status === 'loading' && (
            <FontAwesomeIcon icon={faSpinnerThird} spin={true} />
          )}
        </div>

        <Button
          onClick={() => query.refetch()}
          disabled={query.status === 'loading'}
        >
          <FontAwesomeIcon
            icon={faSyncAlt}
            spin={query.isLoading}
            className="mr-2"
          />
          {t('supervendor.ListAccounts.refresh')}
        </Button>

        {(app.hasCapability('globalSuper') ||
          app.hasCapability('vendorCreateAccounts')) && (
          <Button component={Link} to={`${props.rootUrl}/new`}>
            <FontAwesomeIcon icon={faPlus} className="mr-2" />
            {t('supervendor.ListAccounts.createNew')}
          </Button>
        )}
      </HeadingBar>
      <Card>
        <FiltersWrapper onReset={filters.reset}>
          {app.hasCapability('globalSuper') && (
            <VendorFilter
              className="FiltersWrapper-item"
              value={filters.values.vendor_account_id}
              onChange={(vendor) =>
                handleAccountsFiltersChange({
                  vendor_account_id: vendor?.account_id,
                })
              }
            />
          )}

          <HasVendorsFilter
            key={`Filters-${filters.values.has_vendors}`}
            className="FiltersWrapper-item"
            value={
              filters.values.has_vendors === undefined
                ? filters.values.has_vendors
                : filters.values.has_vendors
                ? 'has_vendors'
                : 'no_vendors'
            }
            onChange={(opt) => {
              if (opt === null || opt === undefined) {
                return handleAccountsFiltersChange({ has_vendors: undefined });
              }

              handleAccountsFiltersChange({
                has_vendors: opt.value === 'has_vendors' ? true : false,
              });
            }}
          />
          <DebouncedInput
            className="FiltersWrapper-input FiltersWrapper-item"
            value={filters.values.query}
            onChange={(value) => {
              handleAccountsFiltersChange({ query: value });
            }}
            placeholder="Search"
            variant="outlined"
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  {filters.values.query && filters.values.query.length > 0 ? (
                    <span
                      role="button"
                      className="FilterInput-icon FilterInput-icon-clear"
                      onClick={() => handleAccountsFiltersChange({ query: '' })}
                    >
                      <FontAwesomeIcon icon={faTimes} />
                    </span>
                  ) : (
                    <span className="FilterInput-icon">
                      <FontAwesomeIcon icon={faSearch} />
                    </span>
                  )}
                </InputAdornment>
              ),
            }}
          />
        </FiltersWrapper>
        <Pagination loading={query.isLoading} pagination={paginationData} />

        <Table
          columns={columns}
          rows={rows}
          loading={query.isLoading}
          sortColumn={transformSortStringToObject(filters.values.sort)}
          onSortClick={handleSortClick}
        />
      </Card>
    </>
  );
}

function RenderAccountVendors(props: { account: api.Account }) {
  const { account } = props;
  const items = account.is_vendor ? [] : account.vendors;
  return (
    <>
      {items?.map((x) => (
        <Chip key={x.id} size="small" label={x.name || ''} />
      ))}
    </>
  );
}

const transformSortStringToObject = (
  string?: string
): SortColumnI | undefined => {
  if (!string) {
    return;
  }
  const [column, sortOrder] = string.split(' ');

  if (sortOrder !== 'asc' && sortOrder !== 'desc') {
    return;
  }

  return {
    key: column,
    type: sortOrder,
  };
};
