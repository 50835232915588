import React from 'react';
import * as api from '../../../../serverApi';
import Select from 'react-select';
import { Account } from '../../../../serverApi';
import cn from 'classnames';
import { sortAccountsAlphabetically } from '../../../../lib/utils/sortAccountsAlphabetically';

const reactSelectStyles = {
  menu: (provided: any) => ({
    ...provided,
    zIndex: 100,
  }),
};

interface Props {
  className?: string;
  disabled?: boolean;
  value?: number | null;
  onChange: (account: Account) => void;
  vendorId?: number;
}

const params = {};

export const AccountFilter = (props: Props) => {
  const accounts = api.useSuperVendorGetAccountsQuery(params);

  const filterAndSortAccounts = (data: api.Account[] | undefined) => {
    const nonVendorAccounts = data?.filter((a) => !a.is_vendor);

    const filteredAccounts = props.vendorId
      ? nonVendorAccounts?.filter((account) =>
          account.vendors?.some((vendor) => vendor.id === props.vendorId)
        )
      : nonVendorAccounts;

    return sortAccountsAlphabetically(filteredAccounts);
  };

  return (
    <Select
      isSearchable
      isClearable
      styles={reactSelectStyles}
      isDisabled={accounts.isLoading || props.disabled}
      isLoading={accounts.isLoading}
      placeholder="Account"
      className={cn('AccountFilter', props.className)}
      options={filterAndSortAccounts(accounts?.data?.data)}
      value={
        accounts?.data?.data?.find((z) => z.account_id === props.value) || null
      }
      getOptionLabel={(data: api.Account) => data['name']}
      getOptionValue={(data: api.Account) => data['account_id'].toString()}
      onChange={(s: any) => {
        props.onChange(s);
      }}
    />
  );
};
