import { addSecondsToDate } from './addSecondsToDate';

interface Opts {
  samples: number[];
  startTime: string;
  samplePeriodS: number;
}

type Result = Array<{
  startDate: Date;
  streak: number;
}>;

export function groupSamplesByStreaks(opts: Opts): Result {
  const { samples } = opts;
  const results: Result = [];

  let streakStartDate = null;
  let streak = 1;

  for (var i = 0; i < samples.length; i++) {
    const currentSample = samples[i];

    /**
     * If the last sample is 1, we need to check if there's a streak of 1s before calculating
     * the startDate and append streak to results.
     */
    if (currentSample === 1 && i === samples.length - 1) {
      const startDate = streakStartDate
        ? streakStartDate
        : addSecondsToDate(opts.startTime, i * opts.samplePeriodS);

      results.push({ startDate, streak: streak + 1 });
      continue;
    }

    /**
     * If the current sample is 1, and there's no current streak, we need to start a new streak
     */
    if (currentSample === 1 && streakStartDate === null) {
      streakStartDate = addSecondsToDate(
        opts.startTime,
        i * opts.samplePeriodS
      );
      continue;
    }

    /**
     * If the current sample is 1, and we have a streak, we just need to increment the streak
     */
    if (currentSample === 1 && streakStartDate !== null) {
      streak += 1;
      continue;
    }

    /**
     * If the current sample is 0, and we have a streak, it means that we've reached the end of the streak
     * and we need to append the streak and startDate to results.
     *
     * We also need to reset the streakStartDate and streak.
     */
    if (currentSample === 0 && streakStartDate !== null) {
      results.push({
        startDate: streakStartDate,
        streak,
      });
      streakStartDate = null;
      streak = 1;
      continue;
    }
  }

  return results;
}
