import { useState } from 'react';
import { isEqual } from 'lodash';

export function useDataChangeDetect<T>(initialData?: T) {
  const [data, setData] = useState(initialData);

  const resetData = (updatedData?: T) => {
    setData(updatedData ?? initialData);
  };

  const hasChanged = (currentData: T) => !isEqual(data, currentData);

  return {
    hasChanged,
    resetData,
    setData,
  };
}
