import * as api from '../../serverApi';

const sortAlphabetically = (firstEl: api.Account, secondEl: api.Account) =>
  firstEl.name.localeCompare(secondEl.name);

export const sortAccountsAlphabetically = (data: api.Account[] | undefined) => {
  if (!data) {
    return [];
  }

  return data.sort(sortAlphabetically);
};
