import React from 'react';
import { FormControlLabel, RadioGroup, Radio } from '@material-ui/core';

import useZoneSettingsForm from './lib/useZoneSettingsForm';
import { Panel } from '../../../../../components/Panel';
import { useZoneContext } from '../../../../../lib/ZoneContext';
import { FormBody } from './lib/FormBody';

import * as api from '../../../../../serverApi';
import { t } from '../../../../../lib/i18n';

const fields: Array<keyof api.ZoneSettings> = ['vkp_enabled'];

export function Vkp() {
  const ctx = useZoneContext();
  const form = useZoneSettingsForm({ fields });

  return (
    <div>
      <h2>
        {t('manage.zone.Vkp.headingFor')} {ctx.activeZone.name}
      </h2>

      <Panel>
        <strong>{t('common.commonTexts.tips')}:</strong>{' '}
        {t('manage.zone.Vkp.tips')}
      </Panel>

      <FormBody zoneSettingsForm={form}>
        <RadioGroup
          aria-label="EPJ"
          name="vkp_enabled"
          value={form.displayValues?.vkp_enabled?.toString() || ''}
          onChange={(e) => {
            form.formik.setFieldValue(
              'vkp_enabled',
              e.currentTarget.value === 'true'
            );
            form.formik.setFieldTouched('vkp_enabled');
          }}
        >
          <FormControlLabel
            disabled={form.disabled}
            value="true"
            control={<Radio color="primary" />}
            label={t('common.commonTexts.enabled')}
          />
          <FormControlLabel
            disabled={form.disabled}
            value="false"
            control={<Radio color="primary" />}
            label={t('common.commonTexts.notActivated')}
          />
        </RadioGroup>
      </FormBody>
    </div>
  );
}
