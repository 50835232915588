/**
 * Removes seconds and date from given timestamp and returns only hours and minutes
 * @return string: hours and minutes
 * @param time: string | undefined | null
 */

export function getMinutesAndSecondsFromTime(time?: string | null): string {
  if (!time) {
    return '';
  }

  // if its full date e.g. 2017/02/14 23:00:00
  if (
    /^[0-9]{4}[-|/](0[1-9]|1[0-2])[-|/](0[1-9]|[1-2][0-9]|3[0-1]) (2[0-3]|[01][0-9]):[0-5][0-9]:[0-5][0-9]/.test(
      time
    )
  ) {
    return time.slice(11, 16);
  }

  //check if e.g. 23:00:00
  if (/^(2[0-3]|[01][0-9]):[0-5][0-9]:[0-5][0-9]/.test(time)) {
    return time.slice(0, -3);
  }

  return '';
}
