import { useEffect, useState } from 'react';
import { Modal, ModalBody } from 'reactstrap';
import { LinearProgress } from '@material-ui/core';

import { t } from '../../../../../lib/i18n';

import './ResetImageModal.scss';

interface Props {
  isOpen: boolean;
}

const initialTimeout = 15;

export const ResetImageModal = (props: Props) => {
  const [counter, setCounter] = useState<number>(initialTimeout);

  //Reset timeout
  useEffect(() => {
    if (props.isOpen) {
      setCounter(initialTimeout);
    }
  }, [props.isOpen]);

  useEffect(() => {
    const timeoutId = setTimeout(() => {
      if (props.isOpen && counter > 0) {
        setCounter(counter - 1);
      }
    }, 1000);

    return () => {
      clearTimeout(timeoutId);
    };
  }, [counter, props.isOpen]);

  return (
    <Modal
      fade={false}
      centered
      size="lg"
      isOpen={props.isOpen}
      className="ResetImageModal"
    >
      <ModalBody>
        <div className="text-center w-100">
          <p>{t('common.commonTexts.pleaseWait')}...</p>
          <LinearProgress
            color="primary"
            style={{ width: '100%', height: 8 }}
          />
          <span
            style={{
              fontSize: 30,
              fontWeight: 'bold',
              color: '#84b93c',
            }}
          >
            {counter}
          </span>
        </div>
      </ModalBody>
    </Modal>
  );
};
