import React from 'react';

// import { HeadingBar } from '../../super/shared/HeadingBar';
// import { Card } from '../../super/shared/Card';
import { Redirect } from 'react-router';
import { useAppContext } from '../../../lib/global';

export function Overview() {
  // Nothing to show on the overview page -- we just redirect to the accounts page for now.

  const app = useAppContext();

  if (app.hasCapability('vendorReadAccounts')) {
    return <Redirect to="/vendor/accounts" />;
  }

  return <Redirect to="/vendor/sensors" />;
}
