import React, { useEffect } from 'react';
import { useUsersFormik } from '../lib/useUsersFormik';
import { useRouter } from '../../../../lib/useRouter';
import { UserForm } from './UserForm';
import { useErrorHandler } from '../../../../lib/useErrorHandler';
import { ErrorMessages } from '../../../../components/ErrorMessages';

import * as serverApi from '../../../../serverApi';
import { SuperUpdateUserData } from '../../../../serverApi';
import { RouteLeavingGuard } from '../../../../components/RouteLeavingGuard';
import { isAxiosError } from '../../../../lib/utils/errorUtils';

interface Props {
  user: serverApi.SuperUser;
  readOnly?: boolean;
}

export const EditUserForm = (props: Props) => {
  const { user } = props;
  const router = useRouter();
  const errorHandler = useErrorHandler();

  const formik = useUsersFormik({
    initialValues: {
      username: user.username,
      first_name: user.first_name,
      last_name: user.last_name,
      display_name: user.display_name,
      email: user.email,
      phone: user.phone,
      impersonal_user: user.impersonal_user,
      account_id: user.account.account_id,
      roles: undefined,
    },
    onSubmit: async (values, helpers) => {
      try {
        await serverApi.superUpdateUser(
          user.user_id,
          values as SuperUpdateUserData
        );
        helpers.resetForm();
        router.history.push('/super/users');
      } catch (e) {
        errorHandler.handleError(e);
        if (isAxiosError<Record<string, string>>(e)) {
          e.response?.data && helpers.setErrors(e.response?.data);
        }
      }
    },
  });

  useEffect(() => {
    if (
      // User have have roles
      user.user_roles &&
      user.user_roles.length > 0 &&
      // Account is changed
      user.account.account_id !== formik.values.account_id &&
      // Dont set error if already set
      !formik.errors.account_id
    ) {
      formik.setFieldError('account_id', 'Cannot move users with active roles');
    }
  }, [formik, user]);

  return (
    <>
      <RouteLeavingGuard when={formik.dirty} />
      <UserForm
        formik={formik}
        mode="edit"
        userId={user.user_id}
        readOnly={props.readOnly}
      />
      <ErrorMessages errorData={errorHandler} />
    </>
  );
};
