import React, { CSSProperties } from 'react';
import cs from 'classnames';

import { Icon } from '../../components/Icon';
import { SensorType } from '../../serverApi';
import { eventTypesBySensorType } from '../../eventTypes';

interface EventTypeIconProps {
  sensorType: SensorType;
  eventType: string;
  className?: string;
  style?: CSSProperties;
}

export function EventTypeIcon(props: EventTypeIconProps) {
  const icon =
    eventTypesBySensorType[props.sensorType]?.[props.eventType]?.icon;

  if (!icon) {
    return null;
  }

  return (
    <Icon
      icon={icon}
      className={cs('EventTypeIcon', props.className)}
      style={props.style}
    />
  );
}
