import React, { useState } from 'react';
import { FormControl, MenuItem, Select } from '@material-ui/core';
import * as api from '../../../../../serverApi';

import { ActionButton } from '../../../../../components/ActionButton';

import './AssignSensorSystem.scss';

interface Props {
  sensor: api.SuperVendorSensor;
}

const sensorSystems = [
  'vpn.roommate.no',
  'vpn.roommate2.no',
  'vpn.roommate3.no',
  'vpn.roommate4.no',
  'vpn.roommate5.no',
  'vpn.roommate6.no',
  'vpn.roommate7.no',
  'vpn.roommate8.no',
  'vpn.roommate9.no',
  'vpn.testmate.no',
  'vpn.testmate2.no',
];

export const AssignSensorSystem = (props: Props) => {
  const [selectedSystem, setSelectedSystem] = useState<string>('');
  return (
    <div className="AssignSensorSystem">
      <FormControl className="AssignSensorSystem-select">
        <Select
          value={selectedSystem}
          onChange={(e) => {
            setSelectedSystem(e.target.value as string);
          }}
          displayEmpty={true}
        >
          <MenuItem value="" disabled>
            Select a server
          </MenuItem>
          {sensorSystems.map((hostname) => (
            <MenuItem key={hostname} value={hostname}>
              {hostname}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
      <ActionButton
        className="ml-5"
        label="Save"
        action={api.moveSensorToServer}
        successMessage={`Sensor is being moved to ${selectedSystem}`}
        parameters={[props.sensor.id, { server: selectedSystem }]}
        onSuccess={() => {
          setSelectedSystem('');
        }}
      />
    </div>
  );
};
