import React from 'react';
import MenuItem from '@material-ui/core/MenuItem';
// import { useApiCall } from '../../../lib/useApiCall';
import * as serverApi from '../../../serverApi';
import { TextField } from '@material-ui/core';
import { t, TranslationKey } from '../../../lib/i18n';

interface Props {
  role: string | null;
  /**
   * An array of all available roles.
   */
  availableRoles?: serverApi.GetAvailableRolesResult['roles'];

  /**
   * An array of the roles that should _not_ be selectable nor visible in the role
   * selector. Does not make sense to use this at the same time as roleWhitelist.
   */
  roleBlacklist?: string[];

  /**
   * An array of the roles that should be selectable nor visible in the role selector.
   * If this is provided, other roles will be hidden. Does not make sense to use this
   * at the same time as roleBlacklist.
   */
  roleWhitelist?: string[];

  disabled?: boolean;
  onChange(role: string | null): void;
  className: string;
}

export function RolePicker(props: Props) {
  // const res = useApiCall(serverApi.getAvailableRoles);

  const onChange = (event: any) => {
    props.onChange(event.target.value);
  };

  let roles: Array<{ role: string }> | undefined;

  // if (res.data?.roles) {
  //   if (props.roleWhitelist) {
  //     roles = res.data.roles.filter(r => props.roleWhitelist?.includes(r.role));
  //   } else if (props.roleBlacklist) {
  //     roles = res.data.roles.filter(
  //       r => !props.roleBlacklist?.includes(r.role)
  //     );
  //   } else {
  //     roles = res.data.roles;
  //   }
  // }

  if (props.availableRoles) {
    if (props.roleWhitelist) {
      roles = props.availableRoles.filter((r) =>
        props.roleWhitelist?.includes(r.role)
      );
    } else if (props.roleBlacklist) {
      roles = props.availableRoles.filter(
        (r) => !props.roleBlacklist?.includes(r.role)
      );
    } else {
      roles = props.availableRoles;
    }
  }
  const value = roles && roles?.length > 0 && props.role ? props.role : '';
  return (
    <div style={{ flex: 1 }} className={props.className}>
      <TextField
        select={true}
        variant="outlined"
        className="w-100"
        label={t('manage.permissions.RolePicker.selectRole')}
        disabled={props.disabled}
        value={value}
        onChange={onChange}
        data-cy="create-user-select-role"
      >
        {roles ? (
          roles.map((r, index) => (
            <MenuItem
              key={r.role}
              value={r.role}
              data-cy={`select-role-${index}`}
            >
              {t(`common.roles.${r.role}` as TranslationKey)}
            </MenuItem>
          ))
        ) : (
          <MenuItem value="" disabled>
            {t('manage.permissions.RolePicker.noAvailableRoles')}
          </MenuItem>
        )}
      </TextField>
    </div>
  );
}
