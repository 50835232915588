import React, { useState, useEffect, FormEvent } from 'react';
import * as api from '../../serverApi';

import { OneTimeCodeSelectDevice } from './OneTimeCodeSelectDevice';
import { OneTimeCodeSubmitForm } from './OneTimeCodeSubmitForm';

import { t } from '../../lib/i18n';

interface Props {
  devices?: Array<api.OtpDevice>;
  onSubmit: () => void;

  /**
   * Use this only in storybook.
   */
  overrideShowForm?: boolean;
}

export function renderDeviceTitle(d: api.OtpDevice) {
  return d.personal
    ? `${t('publicComponents.OneTimeCode.personalPhone')} (${d.channel})`
    : `${d.display_name ? d.display_name : ''} ${
        d.phone ? `(${d.phone})` : ''
      }`;
}

function getDeviceInput(device: api.OtpDevice) {
  if (device.channel === 'app') {
    return {
      channel: device.channel,
      mobile_device_id: device.mobile_device_id,
    };
  }

  if (device.channel === 'sms') {
    return {
      channel: device.channel,
      user_id: device.user_id,
    };
  }

  return {
    channel: device.channel,
  };
}

export function OneTimeCode(props: Props) {
  const [code, setCode] = useState('');
  const [error, setError] = useState('');
  const [showForm, setShowForm] = useState(props.devices?.length === 1);
  const [requesting, setRequesting] = useState<string | undefined>();
  const [submitting, setSubmitting] = useState(false);
  const [selectedDevice, setSelectedDevice] = useState<
    undefined | api.OtpDevice
  >(undefined);

  useEffect(() => {
    if (props.devices?.length === 1) {
      requestFromDevice(props.devices[0], `${props.devices[0].channel}-0`);
    }
  }, [props.devices]);

  /**
   * @param device - otp device object
   * @param deviceIndex - String concatenation of device channel with device array index
   * to create a unique index to handle loading state. E.g. app-0, sms-1
   */
  async function requestFromDevice(
    device: api.OtpDevice,
    deviceIndex?: string
  ) {
    try {
      setRequesting(deviceIndex);
      await api.requestOtpCode(getDeviceInput(device));
      setSelectedDevice(device);
      setShowForm(true);
      setRequesting(undefined);
    } catch (error) {
      setRequesting(undefined);
      console.log('error', error);
    }
  }

  async function submitMfaCode(e: FormEvent<EventTarget>) {
    e.preventDefault();
    setError('');
    setSubmitting(true);
    try {
      await api.otpSubmit({ code });
      props.onSubmit();
      setSubmitting(false);
    } catch (error) {
      setSubmitting(false);
      setError(t('publicComponents.OneTimeCode.invalidCode'));
    }
  }

  if (showForm || props.overrideShowForm) {
    return (
      <OneTimeCodeSubmitForm
        onRequestNewCode={() => setShowForm(false)}
        loading={submitting}
        onSubmit={submitMfaCode}
        errorMessage={error}
        code={code}
        onChangeCode={setCode}
        selectedDevice={selectedDevice}
      />
    );
  }

  return (
    <OneTimeCodeSelectDevice
      devices={props.devices}
      onSelectDevice={requestFromDevice}
      loading={requesting}
    />
  );
}

export default OneTimeCode;
