import React from 'react';
import { useCareContext } from '../lib/CareContext';
import { Icon } from '../../../components/Icon';
import { t } from '../../../lib/i18n';

interface Props {
  className?: string;
}

export const ToolbarMobileHeader = (props: Props) => {
  const careCtx = useCareContext();

  const handleClick = () => {
    if (careCtx.selectedEvent) {
      careCtx.selectEvent(undefined);
    }
    if (careCtx.selectedSensor) {
      careCtx.selectSensor(undefined);
    }
    careCtx.toggleMainContent();
  };

  return (
    <div className={props.className}>
      <span role="button" onClick={handleClick}>
        <Icon icon="arrow-left" />
        <span className="ml-2">
          {t('care.components.ToolbarMobileHeader.backToTheRoomList')}
        </span>
      </span>
    </div>
  );
};
