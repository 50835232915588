import React from 'react';
import { useManageStyles } from '../../useManageStyles';
import { Button } from '../../../../components/Button';
import { useRouter } from '../../../../lib/useRouter';
import { SortableTable } from '../../../../components/SortableTable';
import { t } from '../../../../lib/i18n';

import * as api from '../../../../serverApi';

interface Props {
  careReceivers?: api.GetCareReceiversByZoneResult['care_receivers'];
  className?: string;
  style?: React.CSSProperties;
  loading?: boolean;
}

export const CareReceiversTable = (props: Props) => {
  const classes = useManageStyles();
  const router = useRouter();

  // prettier-ignore
  const columns = [
    { header: t('manage.careReceivers.common.recipient'), key: 'first_name', sortable: true },
    { header: t('manage.careReceivers.ui.CareReceiversTable.zoneHousingUnit'), key: 'sone_boenhet', sortable: true },
    { header: t('manage.careReceivers.common.superVisionOrder'), key: 'blocked_users_count', sortable: true },
    { header: '', key: 'action' },
  ];

  const rows = props.careReceivers
    ? props.careReceivers.map((cr, index) => {
        const soneBoenhet = `${
          cr.parent_zone ? `${cr.parent_zone.name} »` : ''
        } ${
          cr.zone?.name ??
          t('manage.careReceivers.ui.CareReceiversTable.noZoneAttached')
        }`;
        return {
          first_name: `${cr.first_name || ''} ${cr?.last_name || ''}`,
          sone_boenhet: soneBoenhet,
          blocked_users_count: `${
            cr.blocked_users_count
              ? cr.blocked_users_count
              : t('common.commonTexts.neither')
          }`,
          action: (
            <Button
              light={true}
              icon="gear"
              color="primary"
              className="mr-2"
              to={`${router.match.path}/${cr.care_receiver_id}`}
              small={true}
              data-cy={`manage-view-care-receiver-${index}`}
            >
              {t('common.commonButtons.show')}
            </Button>
          ),
        };
      })
    : [];

  return (
    <SortableTable
      stickyHeader={false}
      wrapperClassName={`CareReceiversTable ${props.className}`}
      className={classes.table}
      style={props.style}
      columns={columns}
      rows={rows}
      loading={props.loading}
      noResultsPlaceholder={`${t(
        'manage.careReceivers.ui.CareReceiversTable.thereAreNoAssociatedRecipientsInThisZoneOrHousingUnit'
      )}.`}
    />
  );
};
