import { PropsWithChildren, useEffect, useState } from 'react';
import cs from 'classnames';
import { useCareGetBehaviorDataQuery } from '../../../serverApi';
import { BehavioralDataChart } from '../behavior/BehavioralDataChart';
import { t } from '../../../lib/i18n';
import { InlineSpinner } from '../../../components/InlineSpinner';
import { useWindowSize } from '../../../lib/useWindowSize';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimesCircle } from '@fortawesome/pro-light-svg-icons';

import './BehaviorPanel.scss';

interface Props {
  className?: string;
  sensorCompositeId: string;
  enableRequest: boolean;
  showSoundDimension?: boolean;
  onMobileModalCloseClick: () => void;
}

export const BehaviorPanel = (props: Props) => {
  const { data, isLoading, isError } = useCareGetBehaviorDataQuery(
    props.sensorCompositeId,
    {
      refetchOnWindowFocus: false,
      enabled: props.enableRequest,
    }
  );
  const [mouseOverPanel, setMouseOverPanel] = useState(false);

  /**
   * If cursor is over behavior panel, we temporarily disable wheel events on
   * main parent wrapper of InfoPage (div with id careInfoPage), to prevent scrolling while user
   * is using scroll wheel to zoom into the charts
   */
  useEffect(() => {
    if (!mouseOverPanel) {
      return;
    }
    const careInfoPage = document.getElementById('careInfoPage');

    if (!careInfoPage) {
      return;
    }

    const preventScroll = (e: WheelEvent) => {
      e.preventDefault();
    };

    careInfoPage.addEventListener('wheel', preventScroll);

    return () => {
      careInfoPage.removeEventListener('wheel', preventScroll);
    };
  }, [mouseOverPanel]);

  /**
   * Handler for the onMouseOver and onMouseLeave events for the wrapping behavior panel div.
   *
   */
  const handleMouseMove = (type: 'over' | 'out') => (e: any) => {
    setMouseOverPanel(type === 'over' ? true : false);
  };

  /**
   * The BehavioralDataChart component uses the following condition to determine
   * whether it should allow the  data processing for the audioLevel label
   */
  const shouldShowSoundDimension =
    props.showSoundDimension && data?.labels.includes('audioLevel');

  return (
    <div
      className={cs('BehaviorPanel', props.className)}
      onMouseOver={handleMouseMove('over')}
      onMouseLeave={handleMouseMove('out')}
    >
      {isLoading && !data && <InlineSpinner />}
      {isError && !isLoading && (
        <p className="BehaviorPanel__no-data">
          {t('care.components.BehaviorPanel.noData')}
        </p>
      )}
      {data && (
        <ModalOnMobile onCloseClick={props.onMobileModalCloseClick}>
          <BehavioralDataChart
            data={data}
            showSoundDimension={shouldShowSoundDimension}
          />
        </ModalOnMobile>
      )}
    </div>
  );
};

/**
 *
 * Wrapper component used to show the behavior data chart inside a modal for mobile devices.
 *
 */
const ModalOnMobile = (
  props: PropsWithChildren<{ onCloseClick: () => void }>
) => {
  const { isMobile } = useWindowSize();

  if (isMobile) {
    return (
      <div className="ModalOnMobile">
        <div className="ModalOnMobile__btn-wrapper">
          <button
            className="ModalOnMobile__button"
            onClick={props.onCloseClick}
          >
            <span>{t('components.ModalWrapper.close')}</span>
            <FontAwesomeIcon icon={faTimesCircle} />
          </button>
        </div>
        {props.children}
      </div>
    );
  }
  return <>{props.children}</>;
};
