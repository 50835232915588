import React from 'react';
import cs from 'classnames';
import {
  FormControl,
  FormControlLabel,
  RadioGroup,
  Radio,
} from '@material-ui/core';

import { t } from '../../../../../../lib/i18n';

import { ZoneSettingsForm } from './useZoneSettingsForm';

interface Props {
  className?: string;
  zoneSettingsForm: ZoneSettingsForm;
}

export function InheritanceRadioButtons(props: Props) {
  const val =
    props.zoneSettingsForm.isInherited === true
      ? 'inherit'
      : props.zoneSettingsForm.isInherited === false
      ? 'set'
      : '';

  return (
    <RadioGroup
      className={cs('InheritanceRadioButtons', props.className)}
      name="light"
      value={val}
      onChange={(v) => {
        if (v.currentTarget.value === 'inherit') {
          props.zoneSettingsForm.setInherit(true);
        }
        if (v.currentTarget.value === 'set') {
          props.zoneSettingsForm.setInherit(false);
        }
      }}
    >
      <FormControl>
        <FormControlLabel
          disabled={!props.zoneSettingsForm.ready}
          value="inherit"
          control={<Radio color="primary" />}
          label={`${t(
            'manage.zone.settings.tabs.lib.InheritanceRadioButtons.usesSettingsFromParentZone'
          )} ${
            props.zoneSettingsForm.inheritedFromZone?.name
              ? ` (${props.zoneSettingsForm.inheritedFromZone.name})`
              : ''
          }`}
        />
      </FormControl>
      <FormControl>
        <FormControlLabel
          disabled={!props.zoneSettingsForm.ready}
          value="set"
          control={<Radio color="primary" />}
          label={t(
            'manage.zone.settings.tabs.lib.InheritanceRadioButtons.selectOtherSettings'
          )}
        />
      </FormControl>
    </RadioGroup>
  );
}
