import { useEffect } from 'react';

export function useAppContainerOverflow() {
  useEffect(() => {
    const element = document.getElementById('app');
    if (!element) {
      return;
    }
    element.style.overflow = 'auto';
    return () => {
      element.style.removeProperty('overflow');
    };
  }, []); // eslint-disable-line react-hooks/exhaustive-deps
}
