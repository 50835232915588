import { useEffect, useState } from 'react';
import {
  FormControl,
  FormControlLabel,
  Radio,
  RadioGroup,
  TextField,
} from '@material-ui/core';
import { toast } from 'react-toastify';

import { Button } from '../../../../components/Button';
import { ButtonGroup } from '../../../../components/ButtonGroup';
import { ErrorMessages } from '../../../../components/ErrorMessages';
import { RouteLeavingGuard } from '../../../../components/RouteLeavingGuard';

import {
  getAccountSettings,
  updateAccountSettings,
} from '../../../../serverApi';
import { t } from '../../../../lib/i18n';
import { useDataChangeDetect } from '../../../../lib/useDataChangeDetect';
import { useErrorHandler } from '../../../../lib/useErrorHandler';
import { useHandleCancel } from '../../../../lib/useHandleCancel';

import './IdleTimeout.scss';

export const IdleTimeout = () => {
  const [loading, setLoading] = useState(true);
  const [idleTimeout, setIdleTimeout] = useState<number | null>(null);
  const hc = useHandleCancel();
  const errorHandler = useErrorHandler();
  const dataChangeDetect = useDataChangeDetect();

  useEffect(() => {
    getAccountSettings()
      .then((data) => {
        setIdleTimeout(data.idle_timeout);
        dataChangeDetect.setData({ idleTimeout: data.idle_timeout });
        setLoading(false);
      })
      .finally(() => {
        setLoading(false);
      });
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const onSubmit = async () => {
    try {
      setLoading(true);
      const data = await updateAccountSettings({
        idle_timeout: idleTimeout,
      });
      setIdleTimeout(data.idle_timeout);
      dataChangeDetect.setData({
        idleTimeout: data.idle_timeout,
      });
      setLoading(false);
      toast.success(t('manage.account.AccountSettings.savedSuccessfully'));
    } catch (e) {
      errorHandler.handleError(e);
      setLoading(false);
      toast.error(t('manage.account.AccountSettings.errorWhileSaving'));
    }
  };

  const handleRadioChange = (e: any, value: string) => {
    if (value === 'none') {
      return setIdleTimeout(null);
    }
    setIdleTimeout(0);
  };

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    /**
     * We treat 0 as a falsy value, so when input is empty string we set idle timeout to 0.
     */
    if (e.target.value === '') {
      setIdleTimeout(0);
    }

    /**
     * Test if input value is a positive integer.
     */
    if (/^[1-9]\d*$/.test(e.target.value)) {
      setIdleTimeout(parseInt(e.target.value));
    }
  };

  const selectedRadio = idleTimeout === null ? 'none' : 'timeout';

  return (
    <div className="IdleTimeout">
      <RouteLeavingGuard when={dataChangeDetect.hasChanged({ idleTimeout })} />

      <h1>{t('manage.account.AccountSettings.idleTimeout')}</h1>

      <RadioGroup value={selectedRadio} onChange={handleRadioChange}>
        <FormControl>
          <FormControlLabel
            value="none"
            disabled={loading}
            control={<Radio color="primary" />}
            label={t('manage.account.AccountSettings.noTimeout')}
          />
        </FormControl>

        <FormControl>
          <div className="d-flex flex-row align-items-center">
            <FormControlLabel
              className="mr-0"
              value="timeout"
              disabled={loading}
              control={<Radio color="primary" />}
              label=""
            />
            <TextField
              className="IdleTimeout-input"
              value={idleTimeout || ''}
              onChange={handleChange}
              disabled={selectedRadio === 'none'}
            />
            <span style={{ fontWeight: 500 }}>
              {t('common.minutes', 'other')}
            </span>
          </div>
        </FormControl>
      </RadioGroup>

      <ButtonGroup className="mt-4">
        <Button
          disabled={loading || idleTimeout === 0}
          icon={loading ? 'gear' : undefined}
          spinIcon={loading}
          type="submit"
          color="primary"
          onClick={onSubmit}
        >
          {t('common.commonButtons.save')}
        </Button>
        <Button
          onClick={() =>
            hc.handleCancel(
              dataChangeDetect.hasChanged({ idleTimeout }),
              null,
              () => dataChangeDetect.resetData()
            )
          }
        >
          {t('common.commonButtons.cancel')}
        </Button>
      </ButtonGroup>
      <ErrorMessages errorData={errorHandler} renderFieldErrors />
    </div>
  );
};
