import { FormikHelpers, FormikValues, useFormik } from 'formik';
import { useAccount } from '../../../../lib/useAccount';
import * as api from '../../../../serverApi';

export type CareReceiverBlockedUser = api.CareReceiverBlockedUser;

export interface CareReceiverFields {
  national_id_number: string | null;
  first_name: string | null;
  last_name: string | null;
  zone_id: number | null;
  blocked_users: CareReceiverBlockedUser[];
  nationalIdRequired: boolean;
}

export type CareReceiverFormik = ReturnType<typeof useCareReceiverFormik>;

interface UseCareReceiverFormOptions {
  initialValues?: CareReceiverFields;
  validationSchema?: any | (() => any);
  onSubmit: (
    values: FormikValues,
    formikHelpers: FormikHelpers<CareReceiverFields>
  ) => Promise<any>;
}

/**
 * Formik wrapper for event settings forms.
 */
export function useCareReceiverFormik(opts: UseCareReceiverFormOptions) {
  const account = useAccount();

  return useFormik({
    initialValues: {
      national_id_number: '',
      first_name: '',
      last_name: '',
      zone_id: null,
      blocked_users: [],
      ...opts.initialValues,

      nationalIdRequired:
        !account.disable_national_id_numbers &&
        account.require_national_id_for_care_receivers,
    },
    validateOnBlur: true,
    validateOnChange: false,
    validationSchema: opts.validationSchema,
    onSubmit: async (values, formikHelpers) => {
      await opts.onSubmit(values, formikHelpers);
    },
  });
}
