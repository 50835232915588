import React from 'react';
import {
  Checkbox,
  FormControl,
  FormControlLabel,
  FormGroup,
  TextField,
} from '@material-ui/core';
import { Button } from '../../../../../components/Button';
import { ArgusBasicManagementFormik } from './useArgusBasicManagementForm';
import { RouteLeavingGuard } from '../../../../../components/RouteLeavingGuard';
import { ButtonGroup } from '../../../../../components/ButtonGroup';
import { t } from '../../../../../lib/i18n';
import { generateAutoCompleteValue } from '../../../../../lib/utils/generateAutoCompleteValue';

interface Props {
  form: ArgusBasicManagementFormik;
}

export const ArgusBasicManagementForm = (props: Props) => {
  const loading = props.form.isSubmitting;

  return (
    <div className="ArgusBasicManagementForm mt-3">
      <RouteLeavingGuard when={props.form.dirty} />
      <FormGroup className="mt-3">
        <FormControlLabel
          style={{ marginLeft: -8 }}
          control={
            <Checkbox
              color="primary"
              name="argus-enable"
              value="argus-enable"
              disabled={loading}
              checked={props.form.values['argus-enable'] ?? false}
              onChange={(e) => {
                props.form.setFieldValue(
                  'argus-enable',
                  e.currentTarget.checked
                );
                props.form.setFieldTouched('argus-enable');
              }}
              onBlur={props.form.handleBlur}
            />
          }
          label={t(
            'manage.sensors.details.ArgusBasicManagementForm.argus-enable'
          )}
        />
      </FormGroup>

      <FormGroup className="mt-3">
        <FormControl>
          <TextField
            disabled={loading}
            name="alert-activation-delay-secs"
            label={t(
              'manage.sensors.details.ArgusBasicManagementForm.alert-activation-delay-secs'
            )}
            autoComplete={generateAutoCompleteValue()}
            value={props.form.values['alert-activation-delay-secs'] ?? ''}
            onBlur={props.form.handleBlur}
            onChange={(e) => {
              const val =
                e.target.value === '0'
                  ? 0
                  : parseInt(e.target.value, 10) || e.target.value;

              const fieldValue = val === '' ? null : val;
              console.log('setting field value', {
                name: e.target.name,
                fieldValue,
              });
              props.form.setFieldValue(e.target.name, fieldValue);
              props.form.setFieldTouched(e.target.name);
            }}
            error={
              props.form.errors['alert-activation-delay-secs'] !== undefined
            }
            helperText={props.form.errors['alert-activation-delay-secs']}
            style={{ maxWidth: 400 }}
          />
        </FormControl>
      </FormGroup>

      <ButtonGroup direction="horizontal" className="mt-5">
        <Button
          type="submit"
          onClick={props.form.submitForm}
          color="primary"
          disabled={loading}
          icon={loading ? 'gear' : undefined}
          spinIcon={loading}
        >
          {t('common.commonButtons.save')}
        </Button>
      </ButtonGroup>
    </div>
  );
};
