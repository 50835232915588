import React from 'react';
import { QueryClient, QueryClientProvider } from 'react-query';
import { ReactQueryDevtools } from 'react-query/devtools';
import { toast } from 'react-toastify';
import * as Sentry from '@sentry/browser';

import { t } from '../i18n';
import { isAxiosError } from '../utils/errorUtils';

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      retry: (failureCount, error: any) => {
        if (
          error?.response?.status === 404 ||
          error?.response?.status === 401
        ) {
          return false;
        }
        return failureCount < 3;
      },
      onError: (error) => {
        /**
         * We only handle non-axios errors here.
         * See the Axios interceptor in 'index.tsx' for more in-depth information about global error handling.
         */
        if (!isAxiosError(error)) {
          toast.error(t('common.error.defaultError'));
          Sentry.withScope((scope) => {
            scope.setTag('error_id', 2000);
            Sentry.captureException(error);
          });
        }
      },
    },
  },
});

interface Props {}

export const ReactQueryClientProvider: React.FC<Props> = (props) => {
  return (
    <QueryClientProvider client={queryClient}>
      {props.children}
      <ReactQueryDevtools initialIsOpen={false} />
    </QueryClientProvider>
  );
};
