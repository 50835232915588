import React from 'react';
import { Switch, Route, RouteComponentProps } from 'react-router';
import { ListSensors } from './ListSensors';
import AlertDetails from './AlertDetails';
import SensorDetails from './details/SensorDetails';
import Marking from './Marking';
import { AssignSensorPage } from './assign/AssignSensorPage';
import { AssignOtherSensorPage } from './assign/AssignOtherSensorPage';
import { ListSensorAlertSettings } from './ListSensorAlertSettings';
import { useRedirectOnZoneChange } from '../../../lib/useRedirectOnZoneChange';

export function Sensors(props: RouteComponentProps<{}>) {
  useRedirectOnZoneChange({ relativePath: '/sensors' });

  return (
    <Switch>
      <Route exact={true} path={props.match.path} component={ListSensors} />

      <Route path={`${props.match.path}/new`} component={AssignSensorPage} />

      <Route
        path={`${props.match.path}/other/new`}
        component={AssignOtherSensorPage}
      />

      <Route
        exact={true}
        path={`${props.match.path}/:sensorId([0-9a-zA-Z_]+)`}
        component={SensorDetails}
      />

      <Route
        path={`${props.match.path}/:sensorId([0-9a-zA-Z_]+)/marking`}
        component={Marking}
      />

      <Route
        path={`${props.match.path}/:sensorId([0-9a-zA-Z_]+)/alerts`}
        component={AlertDetails}
      />

      <Route
        path={`${props.match.path}/:sensorId([0-9a-zA-Z_]+)/sensor-alert-settings`}
        component={ListSensorAlertSettings}
      />
    </Switch>
  );
}
