import { useState } from 'react';
import * as Sentry from '@sentry/browser';

import { Button } from './Button';
import * as api from '../serverApi';
import { useApiCall } from '../lib/useApiCall';
import { InlineSpinner } from './InlineSpinner';
import { toast } from 'react-toastify';
import { t } from '../lib/i18n';
import { getLabelForEventType } from '../lib/getLabelForEventType';
import { EventTypeIcon } from '../lib/utils/EventTypeIcon';

import './SimulateEventPanel.scss';

export interface EventType {
  sensor_type: api.SensorType;
  event_type: string;
}

export interface EventData extends EventType {
  level?: 'alarm' | 'alert';
  enabled?: boolean;
}

interface Props {
  sensor_id: number;
  sensor_type: api.SensorType;
  showEventSettings?: boolean;
}

export function SimulateEventPanel(props: Props) {
  const [loading, setLoading] = useState(false);

  const sensorCompositeId = `${props.sensor_type}${props.sensor_id}`;
  const res = useApiCall(api.getEventTypesForSensor, [sensorCompositeId]);

  const handleClick = (eventType: string) => () => {
    setLoading(true);
    api
      .simulateEvent(`${props.sensor_type}${props.sensor_id}`, {
        eventType: eventType,
      })
      .then(() => {
        toast.success(t('components.SimulateEventPanel.success'));
        setLoading(false);
      })
      .catch((e) => {
        Sentry.captureException(e);
        toast.error(t('components.SimulateEventPanel.error'));
        setLoading(false);
      });
  };

  if (res.loading) {
    return <InlineSpinner />;
  }
  return (
    <div className="SimulateEventPanel">
      {res.data ? (
        <>
          <table>
            <thead>
              <tr>
                <th>{t('components.SimulateEventPanel.eventTypes')}</th>
                <th>{t('components.SimulateEventPanel.action')}</th>
                <th>{t('common.commonTexts.enabled')}</th>
                <th>{t('components.SimulateEventPanel.timePeriod')}</th>
              </tr>
            </thead>
            <tbody>
              {res?.data?.data?.map((et) => (
                <tr key={et.event_type}>
                  <th scope="row">
                    <EventTypeIcon
                      sensorType={et.sensor_type}
                      eventType={et.event_type}
                      style={{
                        width: '22px',
                        height: '22px',
                        marginLeft: '8px',
                        marginRight: '6px',
                      }}
                    />

                    {getLabelForEventType(et.event_type, et.sensor_type)}
                  </th>
                  <td>
                    <Button
                      color="primary"
                      disabled={loading}
                      onClick={handleClick(et.event_type)}
                    >
                      {t('components.SimulateEventPanel.send')}
                    </Button>
                  </td>
                  <td>{et.enabled ? t('common.commonTexts.enabled') : '-'}</td>
                  <td>
                    {et.enabled_from}-{et.enabled_to}
                  </td>
                </tr>
              ))}
            </tbody>
          </table>

          {loading && <InlineSpinner className="p-4" />}
        </>
      ) : (
        <p>{t('components.SimulateEventPanel.sensorNotFound')}</p>
      )}
    </div>
  );
}
