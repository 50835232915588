import React from 'react';

import { ZoneSettingsForm } from './useZoneSettingsForm';
import { FormButtons } from './FormButtons';
import { InheritanceRadioButtons } from './InheritanceRadioButtons';
import { InlineSpinner } from '../../../../../../components/InlineSpinner';
import { RouteLeavingGuard } from '../../../../../../components/RouteLeavingGuard';
import { t } from '../../../../../../lib/i18n';

interface Props {
  zoneSettingsForm: ZoneSettingsForm;
  children?: React.ReactNode;
  debug?: boolean;
}

export function FormBody(props: Props) {
  if (!props.zoneSettingsForm.ready) {
    return (
      <div className="py-5">
        <InlineSpinner text={t('common.commonTexts.loading')} />
      </div>
    );
  }

  return (
    <div className="FormBody pt-4">
      <RouteLeavingGuard when={props.zoneSettingsForm.hasChanged} />

      {props.zoneSettingsForm.isRootZone ? null : (
        <>
          <InheritanceRadioButtons
            className="my-3"
            zoneSettingsForm={props.zoneSettingsForm}
          />
          <hr className="my-4" />
        </>
      )}

      <div className="FormBody-contents">{props.children}</div>

      <FormButtons className="mt-5" zoneSettingsForm={props.zoneSettingsForm} />

      {props.debug && (
        <>
          <hr />
          <pre>{JSON.stringify(props.zoneSettingsForm, null, 2)}</pre>
        </>
      )}
    </div>
  );
}
