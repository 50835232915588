import React from 'react';
import { TableRow, TableCell } from '@material-ui/core';

interface Props {
  colSpan?: number;
  children?: React.ReactNode;
}

export function TableRowPlaceholder(props: Props) {
  return (
    <TableRow>
      <TableCell colSpan={props.colSpan}>
        <div
          className="text-center p-2"
          style={{
            fontSize: '115%',
            color: '#666',
          }}
        >
          {props.children}
        </div>
      </TableCell>
    </TableRow>
  );
}
