import React, { useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Button } from '../../../components/Button';
import {
  faSyncAlt,
  faUsersCog,
  faSpinnerThird,
  faSpinner,
} from '@fortawesome/pro-solid-svg-icons';
import HeadingBar from '../../super/shared/HeadingBar';
import { Card } from '../../super/shared/Card';
import Table from '@material-ui/core/Table';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import { t } from '../../../lib/i18n';
import TableBody from '@material-ui/core/TableBody';
import { TableWrapper } from '../../../components/TableWrapper';
import { faTrashAlt } from '@fortawesome/pro-light-svg-icons';
import { useConfirmation } from '../../../lib/confirmation/ConfirmationContext';
import * as api from '../../../serverApi';
import { useApiCall } from '../../../lib/useApiCall';
import { useErrorHandler } from '../../../lib/useErrorHandler';
import { ErrorMessages } from '../../../components/ErrorMessages';
import { Tooltip } from '../../../components/Tooltip/Tooltip';
import { RouteComponentProps } from 'react-router';

interface Props {}

export const ListIntegrations: React.FC<RouteComponentProps<Props>> = (
  props
) => {
  const confirmation = useConfirmation();
  const errorHandler = useErrorHandler();
  const [deleting, setDeleting] = useState<number | undefined>(undefined);
  const [error, setError] = useState<number | undefined>(undefined);

  const res = useApiCall(api.superVendorListIntegrationUsers);

  const handleDelete = (zoneId: number) => {
    confirmation
      .confirm(t('common.commonTexts.toConfirmDeletionPressOk'))
      .then(async () => {
        setDeleting(zoneId);
        setError(undefined);
        errorHandler.reset();
        try {
          await api.superVendorRemoveIntegration(zoneId.toString());
          res.reload();
        } catch (e) {
          setDeleting(undefined);
          setError(zoneId);
          errorHandler.handleError(e);
        }
      })
      .catch(() => {});
  };

  const users = res.data ?? [];

  return (
    <div className="ListIntegrations">
      <HeadingBar>
        <h2>Integrations</h2>

        <div
          style={{
            height: '25px',
            width: '25px',
            fontSize: '25px',
            lineHeight: '25px',
          }}
        >
          {res.loading && <FontAwesomeIcon icon={faSpinnerThird} spin={true} />}
        </div>

        <div>
          <Button className="mr-3" onClick={res.reload} disabled={res.loading}>
            <FontAwesomeIcon icon={faSyncAlt} className="mr-2" />
            {t('common.commonTexts.refresh')}
          </Button>

          <Button to={`${props.match.url}/new`}>
            {t('supervendor.Integration.setupIntegrations')}
            <FontAwesomeIcon style={{ marginLeft: 10 }} icon={faUsersCog} />
          </Button>
        </div>
      </HeadingBar>

      <Card>
        <TableWrapper>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>Account</TableCell>
                <TableCell>Zone</TableCell>
                <TableCell>Username</TableCell>
                <TableCell>Number of subzones</TableCell>
                <TableCell>Number of sensors</TableCell>
                <TableCell>&nbsp;</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {!res.loading && !users && (
                <tr>
                  <td
                    colSpan={2}
                    style={{ fontSize: '32px', textAlign: 'center' }}
                  >
                    No integrations
                  </td>
                </tr>
              )}
              {users.map((user) => {
                const isDeleting = deleting === user.zone_id;
                return (
                  <TableRow key={user.user_id}>
                    <TableCell>{user.account}</TableCell>
                    <TableCell>
                      {user.zone_name} {user.zone_id}
                    </TableCell>
                    <TableCell>{user.username}</TableCell>
                    <TableCell>{user.subzones_count}</TableCell>
                    <TableCell>{user.sensors_count}</TableCell>
                    <TableCell>
                      <div>
                        <Tooltip
                          title={
                            !user.can_delete ? (
                              <span style={{ fontSize: 14 }}>
                                {user.cannot_delete_reason}
                              </span>
                            ) : (
                              ''
                            )
                          }
                          aria-label={user.cannot_delete_reason}
                        >
                          <div>
                            <Button
                              className="mr-3"
                              disabled={isDeleting}
                              to={`${props.match.url}/reset-password/${user.user_id}`}
                            >
                              {t(
                                'publicComponents.ResetPassword.resetPassword'
                              )}
                            </Button>

                            <Button
                              disabled={isDeleting || !user.can_delete}
                              onClick={() => handleDelete(user.zone_id)}
                            >
                              <FontAwesomeIcon
                                icon={isDeleting ? faSpinner : faTrashAlt}
                                spin={isDeleting}
                                className="mr-1"
                              />
                              {t('common.commonButtons.delete')}
                            </Button>
                          </div>
                        </Tooltip>

                        {error === user.zone_id && (
                          <ErrorMessages
                            errorData={errorHandler}
                            className="mt-2"
                          />
                        )}
                      </div>
                    </TableCell>
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
        </TableWrapper>
      </Card>
    </div>
  );
};
