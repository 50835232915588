export default function getFirstSegmentRelativeToRoot(
  rootUrl: string,
  fullUrl: string
) {
  if (fullUrl.indexOf(rootUrl) !== 0) {
    return undefined;
  }
  if (fullUrl === rootUrl) {
    return '';
  }
  const re = fullUrl.substr(rootUrl.length).match(/^\/([^/]+)($|\/)/);
  if (!re) {
    return undefined;
  }
  return re[1];
}
