import { QueryClient } from 'react-query';
import { QueryKeys, SensorDetails } from '../../../../serverApi';
import * as api from '../../../../serverApi';

export default function setQueryDataForSensor(
  queryClient: QueryClient,
  sensor: SensorDetails
) {
  const key = QueryKeys.getSensorDetails(sensor.id);

  queryClient.setQueryData<api.GetSensorDetailsResult>(key, {
    sensor: sensor,
  });
}
