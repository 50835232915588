import React from 'react';
import moment from 'moment';
import matchSorter from 'match-sorter';

import * as serverApi from '../../../serverApi';
import { useManageStyles } from '../useManageStyles';
import { useZoneContext } from '../../../lib/ZoneContext';
import { Button } from '../../../components/Button';
import { formatRolesValidityDates } from './formatRoleValidityDates';
import { StatusMarker } from '../../../lib/ui/StatusMarker';
import { SortableTable } from '../../../components/SortableTable';
import { t, TranslationKey } from '../../../lib/i18n';
import LockIcon from '@material-ui/icons/Lock';

interface Props {
  search?: string;
  data?: serverApi.GetSortedUserRolesForZoneResult | undefined;
  loading: boolean;
}

function statusForUser(u: serverApi.SortedUserRolesOutputData) {
  if (!u.enabled) {
    return <StatusMarker status="danger" />;
  }

  const now = new Date();
  const notOnlyValidRoles = u.user_roles?.length
    ? u.user_roles.find(
        (u) =>
          (u.valid_from && moment(u.valid_from).isAfter(now)) ||
          (u.valid_to && moment(u.valid_to).isBefore(now))
      ) !== undefined
    : false;

  if (notOnlyValidRoles) {
    return <StatusMarker status="warning" />;
  }
  return <StatusMarker status="success" />;
}

export function UserOverview(props: Props) {
  const classes = useManageStyles();
  const ctx = useZoneContext();

  const getUserRoleKeys = (
    permissions: serverApi.GetSortedUserRolesForZoneResult
  ): string[] => {
    const keys = permissions.data
      .map((p, j) => {
        return p.user_roles.map((r) => `user_roles.${j}.role`);
      })
      .flat(Infinity);
    return keys as string[];
  };

  const hasApp = (u: serverApi.SortedUserRolesOutputData) =>
    u.app_reg ? (
      <span data-sort={u.app_reg}>{u.app_reg}</span>
    ) : u.has_registered_app ? (
      <StatusMarker data-sort={u.has_registered_app} status="success" />
    ) : (
      <StatusMarker data-sort={u.has_registered_app} status="danger" />
    );

  const filterPermissions = (
    permissions?: serverApi.GetSortedUserRolesForZoneResult,
    searchKeyword?: string
  ): serverApi.SortedUserRolesOutputData[] | undefined => {
    if (!permissions) {
      return undefined;
    }

    if (!searchKeyword) {
      return permissions.data;
    }

    return matchSorter(permissions.data, searchKeyword, {
      keys: [
        'username',
        'display_name',
        'first_name',
        'last_name',
        'phone',
        ...getUserRoleKeys(permissions),
      ],
    });
  };

  const permissions = filterPermissions(props.data, props.search);

  // prettier-ignore
  const columns = [
    { header: t('common.commonTexts.enabled'), key: 'enabled', sortable: true },
    { header: t('common.commonTexts.name'), key: 'name', sortable: true },
    { header: t('common.commonTexts.userName'), key: 'username', sortable: true },
    { header: t('manage.permissions.UserOverview.roleZone'),  key: 'role_sone', sortable: true },
    { header: t('manage.permissions.UserOverview.hasApp'), key: 'app_reg', sortable: true },
    { header: t('common.commonTexts.validity'), key: 'validity', sortable: true, },
    { header: '', key: 'action' },
  ];

  const rows = permissions
    ? permissions.map((u, index) => {
        const isApiLocked = u.api_lock;
        return {
          enabled: (
            <span
              data-sort={u.enabled}
              style={{ fontSize: '18px', paddingLeft: 15 }}
            >
              {statusForUser(u)}
            </span>
          ),
          name: [
            ...(u.display_name ? [u.display_name] : []),
            ...(u.last_name ? [u.last_name] : []),
            ...(u.first_name ? [u.first_name] : []),
          ].join(', '),
          username: u.username || u.phone,
          role_sone:
            u.user_roles.length > 1 ? (
              t('manage.permissions.UserOverview.multipleRoles')
            ) : u.user_roles.length === 1 ? (
              <>
                <span>
                  {t(`common.roles.${u.user_roles[0].role}` as TranslationKey)}
                </span>
                , <span>{u.user_roles[0].zone_name}</span>
              </>
            ) : (
              t('manage.permissions.UserOverview.noRoles')
            ),
          app_reg: isApiLocked ? null : hasApp(u),
          validity: formatRolesValidityDates(u.user_roles),
          action: (
            <Button
              disabled={u.readonly || isApiLocked}
              icon={isApiLocked ? undefined : 'gear'}
              light={true}
              color="primary"
              className="mr-2"
              small={true}
              to={`/manage/${ctx.activeZone.id}/permissions/${u.user_id}`}
              data-cy={`view-user-${index}`}
            >
              {isApiLocked ? (
                <LockIcon style={{ color: '#818181', width: 30, height: 25 }} />
              ) : (
                t('common.commonButtons.show')
              )}
            </Button>
          ),
        };
      })
    : [];

  return (
    <SortableTable
      stickyHeader={false}
      wrapperClassName="CollectedPermissionList"
      className={classes.table}
      columns={columns}
      rows={rows}
      loading={props.loading}
      noResultsPlaceholder={t('manage.permissions.common.noAccessesDefined')}
    />
  );
}
