import React, { useEffect, useState } from 'react';
import * as Sentry from '@sentry/browser';
import * as api from '../../../../../serverApi';

import SensorSetAccountModal from '../../../../../components/modals/super/sensors/SensorSetAccountModal';
import SensorSetZoneModal from '../../../../../components/modals/super/sensors/SensorSetZoneModal';

import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';

import { TableWrapper } from '../../../../../components/TableWrapper';
import SensorSetVendorModal from '../../../../../components/modals/super/sensors/SensorSetVendorModal';
import { useApiCall } from '../../../../../lib/useApiCall';
import { useHistory } from 'react-router';
import { toast } from 'react-toastify';
import { useConfirmation } from '../../../../../lib/confirmation/ConfirmationContext';
import { Card } from '../../../../super/shared/Card';
import { useSensorArea } from '../../Sensors';
import { useAppContext } from '../../../../../lib/global';
import { Button, TextField } from '@material-ui/core';
import { ErrorMessages } from '../../../../../components/ErrorMessages';
import { UpdateSensorStatusRow } from './UpdateSensorStatusRow';
import { sortAccountsAlphabetically } from '../../../../../lib/utils/sortAccountsAlphabetically';
import { isAxiosError } from '../../../../../lib/utils/errorUtils';
// import { makeStyles } from '@material-ui/core/styles';

interface Props {
  sensor: api.SuperVendorSensor;
  onRequestReload?: () => void;
}

type RemovingAvailableState = 'account' | 'vendor' | 'zone' | 'sensor' | '';

// const useStyles = makeStyles((theme) => ({
// deleteButton: {
//   background: theme.palette.error.main,
//   '&:hover': {
//     background: darken(theme.palette.error.main, 0.2),
//   },
// },
// }));

const params = {};
export function AssignmentDetailsCard(props: Props) {
  const sensor = props.sensor;
  // const styles = useStyles();
  const app = useAppContext();

  const [removing, setRemoving] = useState<RemovingAvailableState>('');

  const [accountModal, setAccountModal] = useState(false);
  const [vendorModal, setVendorModal] = useState(false);
  const [zoneModal, setZoneModal] = useState(false);

  const history = useHistory();
  const sensorArea = useSensorArea();
  const confirmation = useConfirmation();
  const accountsRes = useApiCall(api.superVendorGetAccounts, [params]);

  const handleAccountSetSubmit = async (accountId: number) => {
    if (!accountId || !sensor) {
      return;
    }
    await api.superVendorUpdateSensor(sensor.id, {
      account_id: accountId,
    });
    props.onRequestReload?.();
  };

  const handleAccountClear = async () => {
    if (!sensor) {
      return;
    }

    try {
      setRemoving('account');

      await api.superVendorBulkAssignSensorAccount({
        account_id: null,
        sensors: sensor.sensor_id.toString(),
        sensor_type: sensor.sensor_type,
      });

      props.onRequestReload?.();
    } catch (e) {
      Sentry.captureException(e);
      toast.error('Error while removing sensor account');
    } finally {
      setRemoving('');
    }
  };

  const handleVendorClear = async () => {
    if (!sensor) {
      return;
    }

    try {
      setRemoving('vendor');
      await api.superVendorUpdateSensor(sensor.id, {
        vendor_account_id: null,
      });
      props.onRequestReload?.();
    } catch (e) {
      Sentry.captureException(e);
      toast.error('Could not clear Vendor');
    } finally {
      setRemoving('');
    }
  };

  const handleZoneClear = async () => {
    if (!sensor) {
      return;
    }
    try {
      setRemoving('zone');
      const res = await api.superVendorUpdateSensor(sensor.id, {
        zone_id: null,
      });

      if (!res.sensor) {
        history.push('/super/sensors');
        return;
      }

      props.onRequestReload?.();
    } catch (e) {
      Sentry.captureException(e);
      toast.error('Error while removing sensor Zone');
    } finally {
      setRemoving('');
    }
  };

  const handleDeleteSensor = () => {
    if (!sensor) {
      return;
    }

    setRemoving('sensor');
    confirmation
      .confirm('Are you sure you want to delete this sensor?')
      .then(async () => {
        try {
          await api.superVendorDeleteSensor(
            `${sensor.sensor_type}${sensor.sensor_id}`
          );

          sensorArea.reload();
          history.push('/super/sensors');
        } catch (e) {
          Sentry.captureException(e);
          toast.error('Could not delete Sensor');
          setRemoving('');
        }
      })
      .catch(() => {
        setRemoving('');
      });
  };

  const availableAccounts =
    accountsRes?.data?.data?.filter((a) => !a.is_vendor) || [];

  const hasSuperCapability = app.hasCapability('globalSuper');

  return (
    <Card>
      <TableWrapper>
        <Table size="small" aria-label="a dense table">
          <TableBody>
            <TableRow>
              <TableCell component="th" scope="row">
                Account
              </TableCell>
              <TableCell>
                {sensor.account_name || (
                  <span style={{ color: '#aaa' }}>None</span>
                )}
              </TableCell>

              <TableCell>
                <Button
                  variant="contained"
                  color="primary"
                  onClick={() => setAccountModal(!accountModal)}
                >
                  Set account
                </Button>
                <SensorSetAccountModal
                  isOpen={accountModal}
                  sensorId={sensor.id}
                  accountId={sensor.account_id}
                  accounts={sortAccountsAlphabetically(availableAccounts)}
                  handleModal={() => setAccountModal(!accountModal)}
                  onAccountSet={() => props.onRequestReload?.()}
                  onSubmit={handleAccountSetSubmit}
                />

                <Button
                  color="secondary"
                  variant="contained"
                  className="ml-2"
                  onClick={handleAccountClear}
                  disabled={
                    removing === 'account' || sensor.account_id === null
                  }
                >
                  Clear account
                </Button>
              </TableCell>
            </TableRow>

            {hasSuperCapability && (
              <>
                <TableRow>
                  <TableCell component="th" scope="row">
                    Vendor
                  </TableCell>
                  <TableCell>
                    {sensor.vendor_account_id ? (
                      <span>{sensor.vendor_account_name}</span>
                    ) : (
                      <div style={{ color: '#aaa' }}>None</div>
                    )}
                  </TableCell>
                  <TableCell>
                    <Button
                      variant="contained"
                      color="primary"
                      onClick={() => setVendorModal(!vendorModal)}
                    >
                      Set vendor
                    </Button>

                    <SensorSetVendorModal
                      isOpen={vendorModal}
                      sensor={sensor}
                      accountId={sensor.account_id}
                      handleModal={() => setVendorModal(!vendorModal)}
                      onAccountSet={() => props.onRequestReload?.()}
                    />

                    <Button
                      color="secondary"
                      variant="contained"
                      className="ml-2"
                      onClick={handleVendorClear}
                      disabled={
                        removing === 'vendor' ||
                        sensor.vendor_account_id === null
                      }
                    >
                      Clear vendor
                    </Button>
                  </TableCell>
                </TableRow>

                <TableRow>
                  <TableCell component="th" scope="row">
                    Zone
                  </TableCell>
                  <TableCell>
                    {sensor.zone_name || (
                      <span style={{ color: '#aaa' }}>None</span>
                    )}
                  </TableCell>
                  <TableCell>
                    <Button
                      color="primary"
                      variant="contained"
                      disabled={sensor.account_id === null}
                      onClick={() => setZoneModal(!zoneModal)}
                    >
                      Set zone
                    </Button>
                    {sensor.account_id && (
                      <SensorSetZoneModal
                        isOpen={zoneModal}
                        sensor={sensor}
                        accountId={sensor.account_id}
                        onToggle={() => setZoneModal(!zoneModal)}
                        onSubmit={() => {
                          setZoneModal(!zoneModal);
                          props.onRequestReload?.();
                        }}
                      />
                    )}
                    <Button
                      color="secondary"
                      variant="contained"
                      className="ml-2"
                      onClick={() => handleZoneClear()}
                      disabled={removing === 'zone' || sensor.zone_id === null}
                    >
                      Clear zone
                    </Button>
                  </TableCell>
                </TableRow>

                <TableRow>
                  <TableCell component="th" scope="row">
                    Delete sensor
                  </TableCell>
                  <TableCell>&nbsp;</TableCell>
                  <TableCell>
                    <Button
                      disabled={removing === 'sensor'}
                      color="secondary"
                      onClick={handleDeleteSensor}
                      // classes={{ root: styles.deleteButton }}
                      variant="contained"
                    >
                      Delete Sensor
                    </Button>
                  </TableCell>
                </TableRow>
              </>
            )}

            <TableRow>
              <TableCell component="th" scope="row">
                Comment
              </TableCell>
              <TableCell>&nbsp;</TableCell>
              <TableCell>
                <EditComment
                  comment={sensor?.comment}
                  sensorId={sensor.id}
                  onUpdate={() => {
                    if (props.onRequestReload) {
                      props.onRequestReload();
                    }
                  }}
                />
              </TableCell>
            </TableRow>

            <UpdateSensorStatusRow
              is_enabled={sensor.is_enabled}
              is_online={sensor.is_online}
              is_updating={sensor.is_updating}
              sensorCompositeId={`${sensor.sensor_type}${sensor.sensor_id}`}
              onChange={() => {
                props.onRequestReload?.();
              }}
            />
          </TableBody>
        </Table>
      </TableWrapper>
    </Card>
  );
}

interface EditCommentProps {
  comment: string | null;
  sensorId: string;
  onUpdate?: () => void;
}
function EditComment(props: EditCommentProps) {
  const [editing, setEditing] = useState(false);
  const [saving, setSaving] = useState(false);
  const [error, setError] = useState('');
  const [value, setValue] = useState<string | null>(props.comment);

  useEffect(() => {
    setValue(props.comment);
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const handleSave = async () => {
    try {
      setSaving(true);
      setError('');
      const res = await api.superVendorUpdateSensor(props.sensorId, {
        comment: value || '',
      });
      props.onUpdate?.();
      setValue(res?.sensor?.comment || '');
      setEditing(false);
    } catch (e) {
      Sentry.captureException(e);
      if (isAxiosError(e)) {
        setError(e.message);
        return;
      }
      setError(String(e));
    } finally {
      setSaving(false);
    }
  };

  return (
    <div className="EditComment d-flex align-items-start">
      <ErrorMessages errorApiCall={error} />
      {!editing ? (
        <>
          <span
            style={{
              width: 300,
              display: 'inline-block',
              wordBreak: 'break-all',
              marginTop: 4,
            }}
          >
            {value || <span style={{ color: '#aaa' }}>None</span>}
          </span>
          <Button
            size="small"
            variant="contained"
            className="ml-2"
            onClick={() => setEditing(true)}
          >
            Edit
          </Button>
        </>
      ) : (
        <div>
          <TextField
            multiline
            disabled={saving}
            value={value || ''}
            style={{ width: 300 }}
            onChange={(e) => setValue(e.target.value)}
          />

          <Button
            disabled={saving}
            size="small"
            variant="contained"
            className="ml-2"
            onClick={handleSave}
          >
            {saving ? 'Saving' : 'Save'}
          </Button>
          <Button
            size="small"
            variant="contained"
            className="ml-2"
            onClick={() => {
              setValue(props.comment);
              setEditing(false);
            }}
          >
            Cancel
          </Button>
        </div>
      )}
    </div>
  );
}
