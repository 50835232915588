import { QueryClient } from 'react-query';
import {
  QueryKeys,
  SuperVendorGetAccountsResult,
  Account,
  SuperVendorGetAccountDetailsResult,
} from '../../../../serverApi';

/**
 * Update account details cache data and also update specific account in list accounts cache.
 * @param queryClient
 * @param account
 */
export default function setQueryDataForAccount(
  queryClient: QueryClient,
  account: Account
) {
  const accountId = account.account_id;
  const accountCacheKey = QueryKeys.superVendorGetAccountDetails(accountId);
  const listAccountsCacheKey = QueryKeys.superVendorGetAccounts({});

  // Update account details first (if exists)
  const prevAccountDetails = queryClient.getQueryData<SuperVendorGetAccountDetailsResult>(
    accountCacheKey
  );

  if (prevAccountDetails?.account) {
    queryClient.setQueryData<SuperVendorGetAccountDetailsResult>(
      accountCacheKey,
      () => {
        return {
          account: account,
        };
      }
    );
  }

  // Update list accounts. (handle create and update account cases).
  const prevAccountsList = queryClient.getQueryData<SuperVendorGetAccountsResult>(
    listAccountsCacheKey
  );

  if (!prevAccountsList) {
    return;
  }

  queryClient.setQueryData<SuperVendorGetAccountsResult>(
    listAccountsCacheKey,
    () => ({
      ...prevAccountsList,
      data: prevAccountsList.data.find((x) => x.account_id === accountId)
        ? prevAccountsList.data.map((x) =>
            x.account_id === accountId ? account : x
          )
        : [...prevAccountsList.data, account],
    })
  );
}
