import React, { CSSProperties, useEffect, useState } from 'react';
import { orderBy } from 'lodash';
import { TableBody, TableCell, TableHead, TableRow } from '@material-ui/core';
import { Icon } from './Icon';
import { TableRowPlaceholder } from '../areas/manage/common/TableRowPlaceholder';
import { InlineSpinner } from './InlineSpinner';
import { t } from '../lib/i18n';
import { Table } from './Table';

type SortableTableColumns = {
  header: string;
  key: string;
  sortable?: boolean;
};

type SortableTableRows = {
  [key: string]: any;
};

interface SortableTableProps {
  columns: SortableTableColumns[];
  rows: SortableTableRows[];

  loading?: boolean;
  className?: string;
  wrapperClassName?: string;
  noResultsPlaceholder?: string | React.ReactNode;
  style?: React.CSSProperties;

  stickyHeader?: boolean;
  tableContainerStyle?: CSSProperties;
}

export const SortableTable = (props: SortableTableProps) => {
  const [rows, setRows] = useState<SortableTableRows[]>(props.rows);
  const [columns, setColumns] = useState<SortableTableColumns[]>(props.columns);

  const [sortType, setSortType] = useState<'asc' | 'desc'>('asc');
  const [sortKey, setSortKey] = useState<string | undefined>(undefined);

  useEffect(() => {
    setRows(props.rows);
    setColumns(props.columns);
  }, [props.rows, props.columns]);

  function handleSort(key: string) {
    const type = sortType === 'asc' ? 'desc' : 'asc';
    setSortKey(key);
    setSortType(type);

    setRows(
      orderBy(
        rows,
        function (e) {
          const row = e[key];
          if (row && typeof row === 'object') {
            return row.props?.['data-sort'] ?? null;
          }
          return e[key];
        },
        [type]
      )
    );
  }

  return (
    <Table
      stickyHeader={props.stickyHeader}
      style={props.style}
      tableContainerStyle={props.tableContainerStyle}
      className={props.className}
    >
      <TableHead>
        <TableRow>
          {columns.map((col: any) => (
            <TableCell key={`${col.key}`}>
              <strong> {col.header} </strong>
              {col.sortable && (
                <span
                  role="button"
                  onClick={() => {
                    if (col?.key) {
                      handleSort(col.key);
                    }
                  }}
                >
                  <Icon
                    icon="arrow-right"
                    style={{
                      width: 20,
                      height: 20,
                      cursor: 'pointer',
                      transform:
                        sortKey === col.key
                          ? sortType === 'asc'
                            ? 'rotate(-90deg)'
                            : 'rotate(90deg)'
                          : 'none',
                    }}
                  />
                </span>
              )}
            </TableCell>
          ))}
        </TableRow>
      </TableHead>
      <TableBody>
        {rows && rows.length > 0 ? (
          rows.map((row, i) => {
            return (
              <TableRow key={`row-${i}`} className={row['_className']}>
                {columns.map((rowColumn) => {
                  const colSpanValue =
                    row?._colSpan?.column === rowColumn?.key
                      ? row?._colSpan?.value
                      : null;

                  if (row?._colSpan?.exclude?.includes(rowColumn?.key)) {
                    return null;
                  }

                  return (
                    <TableCell key={rowColumn.key} colSpan={colSpanValue || 1}>
                      {row[rowColumn['key']]}
                    </TableCell>
                  );
                })}
              </TableRow>
            );
          })
        ) : (
          <TableRowPlaceholder colSpan={columns.length}>
            {props.loading ? (
              <InlineSpinner size="sm" text={t('common.commonTexts.loading')} />
            ) : (
              <>
                {props.noResultsPlaceholder ||
                  t('components.SortableTable.thereAreNoResults')}
              </>
            )}
          </TableRowPlaceholder>
        )}
      </TableBody>
    </Table>
  );
};
