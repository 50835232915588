import React from 'react';

import { Icon } from '../../components/Icon';
import { useCareMainWrapperOverflow } from './lib/useCareMainWrapperOverflow';
import './CenteredLogo.scss';
import { useWindowSize } from '../../lib/useWindowSize';

interface Props {
  title: string;
}

export const CenteredLogo = (props: Props) => {
  const windowSize = useWindowSize();
  useCareMainWrapperOverflow();

  return (
    <div className="CenteredLogo">
      <h1 className="Care-splash-title">
        {!windowSize.isMobile && <Icon icon="arrow-left" />} {props.title}
      </h1>

      <div className="CenteredLogo-wrapper">
        <Icon icon="roommate-symbol" className="CenteredLogo-icon" />
      </div>
    </div>
  );
};
