import React from 'react';
import { Redirect, Route, Switch } from 'react-router';
import { List, ListItem, ListItemText } from '@material-ui/core';
import getFirstSegmentRelativeToRoot from '../../../../lib/getFirstSegmentRelativeToRoot';
import { useRouter } from '../../../../lib/useRouter';
import classNames from 'classnames';
import { IconButton } from '../../../../components/IconButton';
import { useZoneContext } from '../../../../lib/ZoneContext';
import { useWindowSize } from '../../../../lib/useWindowSize';
import './Settings.scss';
import { t, TranslationKey } from '../../../../lib/i18n';

import * as api from '../../../../serverApi';

import { StreamAudio } from './tabs/StreamAudio';
import { ObservationImage } from './tabs/ObservationImage';
import { EventImage } from './tabs/EventImage';
import { AlertReminders } from './tabs/AlertReminders';
import { StreamIndicator } from './tabs/StreamIndicator';
import { ObservationTimeout } from './tabs/ObservationTimeout';
import { AudioTimeout } from './tabs/AudioTimeout';
import { ReflexTimeout } from './tabs/ReflexTimeout';
import { SensorDisplayName } from './tabs/SensorDisplayName';
import { Vkp } from './tabs/Vkp';
import { BehavioralData } from './tabs/BehavioralData';
import { SensorResolution } from './tabs/SensorResolution';
import { IpWhitelist } from './advanced/IpWhitelist';
import { useAppContext } from '../../../../lib/global';

import './Settings.scss';
import { GreenCircleIndicator } from '../../common/ManageSidebar';
import { MobileAppSounds } from './tabs/MobileAppSounds';
import { TimeZone } from './tabs/TimeZone';

const areas: Array<{
  key:
    | 'audio'
    | 'image-observation'
    | 'image-event'
    | 'reminders'
    | 'alert-observation'
    | 'observation-timeout'
    | 'audio-timeout'
    | 'reflex-timeout'
    | 'display-name'
    | 'vkp'
    | 'advanced'
    | 'mobile-sounds'
    | 'behavioral-data'
    | 'time-zone'
    | 'sensor-resolution';
  url: string;
  component: () => JSX.Element;
  /**
   * If `condition` is present, the zone setting section will only be shown when
   * the condition function, given the active user, returns `true`.
   */
  condition?: (user?: api.User | null) => boolean;
  fields?: Array<keyof api.ZoneSettings>;
}> = [
  {
    key: 'audio',
    url: 'stream-audio',
    component: StreamAudio,
    fields: [
      'enable_oneway_audio_anonymised',
      'enable_oneway_audio',
      'enable_twoway_audio',
    ],
  },
  {
    key: 'image-observation',
    url: 'image-observation',
    component: ObservationImage,
    fields: [
      'care_observation_image_detailed',
      'care_observation_image_anonymised',
    ],
  },
  {
    key: 'image-event',
    url: 'image-event',
    component: EventImage,
    fields: ['care_event_image_detailed', 'care_event_image_anonymised'],
  },
  {
    key: 'sensor-resolution',
    url: 'sensor-resolution',
    component: SensorResolution,
    fields: ['sensor_resolution'],
    /**
     * Show settings if the user has 'show-sensor-resolution-option' feature enabled
     */
    condition: (user) =>
      Boolean(
        user?.features &&
          Object.keys(user?.features).find(
            (key) => key === 'show-sensor-resolution-option'
          )
      ),
  },
  {
    key: 'mobile-sounds',
    url: 'mobile-sounds',
    component: MobileAppSounds,
    fields: [
      'alarm_audio_key_a',
      'alarm_audio_key_b',
      'alert_audio_key_a',
      'alert_audio_key_b',
    ],
  },
  {
    key: 'reminders',
    url: 'reminders',
    component: AlertReminders,
    fields: ['alert_reminder_count', 'alert_reminder_interval'],
  },
  {
    key: 'alert-observation',
    url: 'alert-observation',
    component: StreamIndicator,
    fields: ['stream_indicator_led', 'stream_indicator_audio'],
  },
  {
    key: 'observation-timeout',
    url: 'observation-timeout',
    component: ObservationTimeout,
    fields: ['observation_stream_timeout'],
  },
  {
    key: 'audio-timeout',
    url: 'audio-timeout',
    component: AudioTimeout,
    fields: [
      'max_call_duration_oneway_audio',
      'max_call_duration_oneway_audio_anonymised',
      'max_call_duration_twoway_audio',
    ],
  },
  {
    key: 'reflex-timeout',
    url: 'reflex-timeout',
    component: ReflexTimeout,
    fields: ['reflex_presence_timeout'],
  },
  {
    key: 'display-name',
    url: 'display-name',
    component: SensorDisplayName,
    fields: ['sensor_display_area_format', 'sensor_display_name_format'],
  },
  {
    key: 'vkp',
    url: 'vkp',
    component: Vkp,
    fields: ['vkp_enabled'],
    condition: (user) => Boolean(user?.account?.vkp_available),
  },
  {
    key: 'behavioral-data',
    url: 'behavioral-data',
    component: BehavioralData,
    fields: ['enable_behavioral_data', 'enable_behavioral_data_audio'],
    /**
     * Show settings if the user has 'show-activity-overview' feature enabled
     */
    condition: (user) =>
      Boolean(
        user?.features &&
          Object.keys(user?.features).find(
            (key) => key === 'show-activity-overview'
          )
      ),
  },
  {
    key: 'time-zone',
    url: 'time-zone',
    fields: ['timezone'],
    component: TimeZone,
    /**
     * Show settings if the user has 'enable-time-zones' feature enabled
     */
    condition: (user) =>
      Boolean(
        user?.features &&
          Object.keys(user?.features).find((key) => key === 'enable-time-zones')
      ),
  },
  {
    key: 'advanced',
    url: 'advanced',
    component: IpWhitelist,
  },
];

function getAreasForAccount(user?: api.User | null) {
  return areas.filter((a) => !a.condition || a.condition(user));
}

interface SettingsSidebarProps {
  className?: string;
}

function SettingsSidebar(props: SettingsSidebarProps) {
  const router = useRouter();
  const appContext = useAppContext();
  const user = appContext.user;
  const zoneContext = useZoneContext();

  const currentSegment = getFirstSegmentRelativeToRoot(
    router.match.url,
    router.location.pathname
  );

  return (
    <div className={classNames('Settings-sidebar', props.className)}>
      <List className="Settings-sidebar-list">
        {getAreasForAccount(user).map((area) => {
          const hasCustomSettings = area.fields?.some((areaField) => {
            return (
              zoneContext.activeZoneSettings &&
              zoneContext.activeZoneSettings[areaField].value !== null
            );
          });

          return (
            <ListItem
              button
              key={area.key}
              onClick={() => {
                router.history.push(
                  router.match.url + (area.url ? `/${area.url}` : '')
                );
              }}
            >
              <ListItemText
                primary={t(
                  `manage.zone.settings.Settings.areas.${area.key}` as TranslationKey
                )}
                className="Settings-list-item"
                style={area.url === currentSegment ? { color: '#63951D' } : {}}
              />
              {(hasCustomSettings ||
                (area.key === 'advanced' &&
                  zoneContext.activeZone.has_defined_ip_whitelists)) && (
                <GreenCircleIndicator className="Settings-indicator" />
              )}
            </ListItem>
          );
        })}
      </List>
    </div>
  );
}

function SettingsIndex() {
  const router = useRouter();
  const windowSize = useWindowSize();
  const appContext = useAppContext();
  const user = appContext.user;

  if (windowSize.isTabletOrMobile) {
    return <SettingsSidebar className="Settings-sidebar--mobile" />;
  }

  return (
    <Redirect to={`${router.match.url}/${getAreasForAccount(user)[0].url}`} />
  );
}

export default function Settings() {
  const router = useRouter();
  const zoneContext = useZoneContext();
  const windowSize = useWindowSize();
  const appContext = useAppContext();
  const user = appContext.user;

  const currentSegment = getFirstSegmentRelativeToRoot(
    router.match.url,
    router.location.pathname
  );

  const backUrl =
    currentSegment === '' || !windowSize.isTabletOrMobile
      ? `/manage/${zoneContext.activeZone.id}/zone`
      : router.match.url;

  return (
    <div className="Settings">
      <h1>
        {t('manage.zone.settings.Settings.settings')}:{' '}
        {zoneContext.activeZone.name}
      </h1>

      <div className="my-4">
        <IconButton icon="arrow-left" to={backUrl}>
          {t('common.commonButtons.back')}
        </IconButton>
      </div>

      <div className="Settings-content">
        <SettingsSidebar className="Settings-sidebar--desktop" />

        <div className="Settings-page">
          <Switch>
            <Route exact path={router.match.url} component={SettingsIndex} />
            {getAreasForAccount(user).map((area) => (
              <Route
                key={area.key}
                exact={!area.url}
                path={
                  area.url
                    ? `${router.match.url}/${area.url}`
                    : router.match.url
                }
                component={area.component}
              />
            ))}
          </Switch>
        </div>
      </div>
    </div>
  );
}
