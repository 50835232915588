import React, { ChangeEvent, useEffect, useState } from 'react';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import { Checkbox, FormControlLabel, FormGroup } from '@material-ui/core';
import * as api from '../../../../../serverApi';
import { useErrorHandler } from '../../../../../lib/useErrorHandler';
import { InlineSpinner } from '../../../../../components/InlineSpinner';
import { ErrorMessages } from '../../../../../components/ErrorMessages';
import { Badge } from '../../../../../components/Badge';

import { t } from '../../../../../lib/i18n';

interface Props {
  is_enabled: boolean;
  is_online?: boolean;
  is_updating?: boolean;
  sensorCompositeId?: string;
  onChange?: () => void;
}

export const UpdateSensorStatusRow: React.FC<Props> = (props) => {
  const errorHandler = useErrorHandler();

  const [loading, setLoading] = useState(false);
  const [enabled, setEnabled] = useState(() => Boolean(props.is_enabled));

  useEffect(() => {
    setEnabled(Boolean(props.is_enabled));
  }, [props.is_enabled]);

  const handleOnChange = async (e: ChangeEvent<HTMLInputElement>) => {
    e.persist();

    const checkedValue = e.target.checked;

    if (!props.sensorCompositeId) {
      return;
    }

    try {
      setLoading(true);

      await api.superVendorUpdateSensor(props.sensorCompositeId, {
        is_enabled: checkedValue,
      });

      setLoading(false);

      setEnabled(checkedValue);

      if (props.onChange) {
        props.onChange();
      }
    } catch (e) {
      setLoading(false);

      errorHandler.handleError(e);
    }
  };

  return (
    <TableRow>
      <TableCell component="th" scope="row">
        Status
      </TableCell>
      <TableCell>&nbsp;</TableCell>
      <TableCell>
        <div style={{ display: 'flex', flex: 'row', alignItems: 'center' }}>
          <div>
            {props.is_online === true && (
              <Badge className="p-2 mr-4" type="success">
                Online
              </Badge>
            )}
            {props.is_online === false && (
              <Badge className="p-2 mr-4" type="error">
                Offline
              </Badge>
            )}
          </div>

          <FormGroup className="mt-2">
            <FormControlLabel
              disabled={loading}
              label={
                <span>
                  {enabled
                    ? t('common.commonTexts.enabled')
                    : t('common.commonTexts.disabled')}
                  {loading && <InlineSpinner className="ml-1" />}
                  <ErrorMessages
                    tag="span"
                    errorData={errorHandler}
                    className="ml-2"
                  />
                </span>
              }
              control={
                <Checkbox
                  color="primary"
                  name="disable_all_detailed_images"
                  checked={Boolean(enabled)}
                  onChange={handleOnChange}
                />
              }
            />
          </FormGroup>

          <div>
            {props.is_updating === true && (
              <Badge className="p-2 mr-4" type="success">
                Updating ...
              </Badge>
            )}
            {props.is_updating === false && (
              <Badge className="p-2 mr-4">Not updating</Badge>
            )}
          </div>
        </div>
      </TableCell>
    </TableRow>
  );
};
