import { useState } from 'react';
import { Col, Row } from 'reactstrap';
import {
  TextField,
  Button,
  Tabs,
  Tab,
  MenuItem,
  Select,
} from '@material-ui/core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSpinner } from '@fortawesome/pro-light-svg-icons';
import { toast } from 'react-toastify';
import { useQueryClient } from 'react-query';

import {
  QueryKeys,
  useGetIdentityProviderQuery,
  useUpdateIdentityProviderMutation,
  deleteIdentityProvider,
} from '../../../serverApi';
import {
  SsoProviderFields,
  useSsoProviderFormik,
} from './lib/useSsoProviderFormik';
import { t } from '../../../lib/i18n';

import { useConfirmation } from '../../../lib/confirmation/ConfirmationContext';
import { useErrorHandler } from '../../../lib/useErrorHandler';
import { ErrorMessages } from '../../../components/ErrorMessages';

import './AccountAssignSsoProvider.scss';

interface Props {
  accountId: number;
  onSave?: (() => Promise<any>) | (() => void);
}

const providers = [
  {
    value: 'gitlab',
    label: 'GitLab',
    fields: [{ value: 'gitlab', label: 'GitLab' }],
  },
  {
    value: 'azure',
    label: 'Azure',
    fields: [{ value: 'azure_tenant_id', label: 'Azure tenant ID' }],
  },
];
export const AccountAssignSsoProvider = (props: Props) => {
  const queryClient = useQueryClient();
  const errorHandler = useErrorHandler();
  const confirmCtx = useConfirmation();
  const [selectedProvider, setSelectedProvider] = useState<string>(
    providers[0].value
  );

  const { data } = useGetIdentityProviderQuery(
    selectedProvider || '',
    props.accountId.toString(),
    { enabled: !!selectedProvider }
  );

  const { mutateAsync } = useUpdateIdentityProviderMutation({
    onSuccess: (data) => {
      const identityProviderKey = QueryKeys.getIdentityProvider(
        selectedProvider || '',
        props.accountId.toString()
      );
      queryClient.setQueryData(identityProviderKey, data);
    },
  });

  const { account_id, id, idp_provider, ...providerFields } =
    data?.identity_provider || {};

  const formik = useSsoProviderFormik({
    initialValues: { ...providerFields, idp_provider: selectedProvider || '' },
    onSubmit: async (values) => {
      const data = prepareValuesForUpdate(values, props.accountId.toString());
      try {
        errorHandler.reset();
        await mutateAsync(data);
        await props.onSave?.();
        toast.success(t('super.AccountSSO.providerAddedSuccessfully'));
      } catch (e) {
        errorHandler.handleError(e);
      }
    },
  });

  const handleRemoveSso = async () => {
    await confirmCtx.confirm(t('super.AccountSSO.confirmProviderRemove'));

    const identityProviderKey = QueryKeys.getIdentityProvider(
      selectedProvider || '',
      props.accountId.toString()
    );
    errorHandler.reset();
    try {
      await deleteIdentityProvider(
        selectedProvider || '',
        props.accountId.toString()
      );
      queryClient.setQueryData(identityProviderKey, undefined);
    } catch (e) {
      errorHandler.handleError(e);
    }
  };

  const providerData = providers.find(
    (idp_provider) => idp_provider.value === selectedProvider
  );

  return (
    <div className="AccountAssignSsoProvider">
      <ErrorMessages errorData={errorHandler} renderFieldErrors />
      <div className="AccountAssignSsoProvider__provider">
        <Tabs
          value={selectedProvider}
          onChange={(e, value) => setSelectedProvider(value)}
        >
          {providers.map((idp_provider) => (
            <Tab
              label={idp_provider.label}
              value={idp_provider.value}
              key={idp_provider.value}
            />
          ))}
        </Tabs>
      </div>

      <div>
        <Row>
          <Col>
            <div className="mb-2">
              <span className="mr-3">
                {t('super.AccountSSO.matchStrategy')}:
              </span>
              <Select
                className="AccountAssignSsoProvider-select"
                name="user_matching_strategy"
                value={formik.values.user_matching_strategy}
                onChange={formik.handleChange}
              >
                <MenuItem value="none">
                  {t('super.AccountSSO.matchUsers.none')}
                </MenuItem>
                <MenuItem value="username">
                  {t('super.AccountSSO.matchUsers.name')}
                </MenuItem>
                <MenuItem value="email">
                  {t('super.AccountSSO.matchUsers.email')}
                </MenuItem>
              </Select>
            </div>
          </Col>
        </Row>
        <Row>
          <Col>
            <TextField
              className="AccountAssignSsoProvider__input"
              label="Client ID"
              name="client_id"
              value={formik.values.client_id ?? ''}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
            />
          </Col>
          <Col>
            <TextField
              className="AccountAssignSsoProvider__input"
              label="Client secret"
              name="client_secret"
              value={formik.values.client_secret ?? ''}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
            />
          </Col>
          {providerData?.fields.map((providerField) => (
            <Col key={providerField.value}>
              <TextField
                className="AccountAssignSsoProvider__input"
                label={providerField.label}
                name={providerField.value}
                value={
                  formik.values[
                    providerField.value as keyof SsoProviderFields
                  ] ?? ''
                }
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
              />
            </Col>
          ))}
          <Button
            className="AccountAssignSsoProvider__remove"
            size="small"
            color="secondary"
            onClick={handleRemoveSso}
            disabled={!data}
          >
            {t('common.commonButtons.remove')}
          </Button>
        </Row>
      </div>
      <Button
        className="mt-4"
        variant="contained"
        onClick={() => formik.handleSubmit()}
        disabled={!formik.dirty}
      >
        {t('common.commonButtons.submit')}
        {formik.isSubmitting && (
          <FontAwesomeIcon spin icon={faSpinner} className="ml-2" />
        )}
      </Button>
    </div>
  );
};

const prepareValuesForUpdate = (
  formValues: SsoProviderFields,
  accountId: string
) => {
  //Update data type after gitlab is added to sso providers (IdentityProviderData interface)
  let values = {
    idp_provider: formValues.idp_provider,
    accountId,
    data: {} as any,
  };

  for (const value in formValues) {
    const key = value as keyof SsoProviderFields;
    if (formValues[key] !== '' && key !== 'idp_provider') {
      values.data[key] = formValues[key];
    }
  }
  return values;
};
