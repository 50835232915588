import React, { useState } from 'react';
import * as Sentry from '@sentry/browser';

import ModalWrapper from '../ModalWrapper';
import { useCareContext } from '../../../areas/care/lib/CareContext';
import {
  FormControl,
  FormControlLabel,
  FormGroup,
  Radio,
  RadioGroup,
  TextField,
} from '@material-ui/core';
import { InlineError } from '../../InlineError';

import * as api from '../../../serverApi';
import { t } from '../../../lib/i18n';

import { useWebRtcContext } from '../../../areas/care/lib/WebRtcContext';
import { generateAutoCompleteValue } from '../../../lib/utils/generateAutoCompleteValue';
import { isAxiosError } from '../../../lib/utils/errorUtils';

interface Props {
  isOpen: boolean;
  selected?: number;
  onClose?: () => void;
}

interface SelectedOption {
  option: string;
  text: string;
}

export function ObservationEpjModal(props: Props) {
  const careCtx = useCareContext();
  const rtc = useWebRtcContext();

  const [error, setError] = useState('');
  const [loading, setLoading] = useState(false);

  const [selectedOption, setSelectedOption] = useState<SelectedOption>({
    option: 'planlagt-tilsyn',
    text: 'planlagt-tilsyn',
  });

  const [textAreaValue, setTextAreaValue] = useState('');

  function handleHide() {
    if (props.onClose) {
      props.onClose();
    }
  }

  function handleReset() {
    setTextAreaValue('');
    setSelectedOption({
      option: 'planlagt-tilsyn',
      text: 'planlagt-tilsyn',
    });
    setLoading(false);
    setError('');
  }

  function handleChange(e: React.ChangeEvent<HTMLInputElement>) {
    setTextAreaValue('');
    setSelectedOption({
      option: e.target.value,
      text: e.target.value,
    });
  }

  function handleTextAreaChange(
    e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>
  ) {
    setTextAreaValue(e.target.value);
    setSelectedOption({
      option: '',
      text: e.target.value,
    });
  }

  async function handleSubmit() {
    const sensorId = careCtx.selectedSensor?.sensor_composite_id;
    if (!sensorId) {
      return;
    }
    try {
      setLoading(true);
      await api.addObservationNote({
        audioProperty: rtc?.call?.audioProperty ?? null,
        type: careCtx.streamType ?? null,
        sensorId: sensorId,
        note: selectedOption.text,
      });
      setLoading(false);
      handleHide();
    } catch (e) {
      Sentry.captureException(e);
      setLoading(false);
      if (
        isAxiosError(e) &&
        e.response?.data.status === 'error' &&
        e.response?.data.message
      ) {
        setError(e.response?.data.message);
      }
    }
  }
  return (
    <ModalWrapper
      title={t('components.ObservationEpjModal.addCauseForOversight')}
      isOpen={props.isOpen}
      onHide={handleHide}
      onSubmit={handleSubmit}
      onResetModal={handleReset}
      loading={loading}
      staticBackdrop={true}
    >
      <p>
        {t('components.ObservationEpjModal.auditsAreAlwaysRegisteredInTheEPJ')}.
      </p>

      <FormGroup className="mt-5">
        <FormControl component="fieldset" className="w-100">
          <RadioGroup
            aria-label="user"
            name="user"
            value={selectedOption.option}
            onChange={handleChange}
          >
            <FormControlLabel
              value="planlagt-tilsyn"
              label={t('components.ObservationEpjModal.scheduledOversight')}
              control={<Radio color="primary" />}
            />

            <FormControlLabel
              value="event-based-observation"
              label={t('components.ObservationEpjModal.eventBasedSupervision')}
              control={<Radio color="primary" />}
            />

            <FormControlLabel
              value=""
              label={t('components.ObservationEpjModal.freeText')}
              control={<Radio color="primary" />}
            />
          </RadioGroup>

          <FormGroup className="mt-0">
            <TextField
              autoComplete={generateAutoCompleteValue()}
              type="text"
              name="free-text"
              placeholder={t('components.ObservationEpjModal.freeText')}
              disabled={loading}
              // disabled={selectedOption.option !== 'free-text'}
              multiline={true}
              value={textAreaValue}
              onChange={handleTextAreaChange}
              onFocus={() => {
                setSelectedOption({
                  option: '',
                  text: '',
                });
              }}
            />
          </FormGroup>
        </FormControl>
      </FormGroup>
      {error && <InlineError>{error}</InlineError>}
    </ModalWrapper>
  );
}
