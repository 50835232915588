import React from 'react';
import { Redirect } from 'react-router';
import cs from 'classnames';

import { InlineSpinner } from '../../components/InlineSpinner';
import { CenterPanel } from '../manage/common/CenterPanel';
import { CareSidebar } from './components/CareSidebar';
import Main from './Main';

import DefaultZoneTreeContextProvider from './lib/DefaultZoneTreeContextProvider';
import CareContextProvider from './lib/CareContextProvider';
import { useAppContext } from '../../lib/global';
import { useApiCall } from '../../lib/useApiCall';
import { useCareContext } from './lib/CareContext';
import { WebRtcProvider } from './lib/WebRtcProvider';
import { useScrollToTop } from '../../lib/useScrollToTop';

import { t } from '../../lib/i18n';
import * as api from '../../serverApi';
import { CareHeader } from '../../components/Header/CareHeader';

import './Care.scss';
import { useAlertUserForIdleTimeout } from '../../lib/useAlertUserForIdleTimeout';

export function InnerCare() {
  const care = useCareContext();

  const rmSensorId =
    care.selectedSensor?.sensor_type === 'roommate'
      ? parseInt(care.selectedSensor.sensor_composite_id.substring(8), 10)
      : undefined;

  return (
    <WebRtcProvider
      sensorId={rmSensorId}
      sensorStatus={care.selectedSensor?.status}
    >
      <div className="Care">
        <div className="Care-new-left">
          <CareHeader />

          <CareSidebar
            className={cs(
              'Care-sidebar',
              care.showMainContentMobile && 'Care-sidebar--hide'
            )}
          />
        </div>

        <Main className="Care-main" />
      </div>
    </WebRtcProvider>
  );
}

export function Care() {
  useScrollToTop();
  const app = useAppContext();
  const structure = useApiCall(api.getStructure);

  const notifyPeriod =
    app.getFeatureValueNumber('idle-timeout-notify-time') ?? 60;
  useAlertUserForIdleTimeout(notifyPeriod);

  if (!app.user) {
    return <div>{t('care.Care.noUser')}</div>;
  }

  if (!app.hasCapability('careAndObservation')) {
    return <Redirect to="/" />;
  }

  if (structure.loading) {
    return (
      <CenterPanel>
        <InlineSpinner
          size="md"
          text={t('common.commonTexts.loading')}
          textStyle={{ fontWeight: 500, fontSize: 22 }}
        />
      </CenterPanel>
    );
  }

  if (!structure.data) {
    return <div>{t('care.common.noDataAvailable')}</div>;
  }

  return (
    <div className="App" id="app">
      <div className="App-body" id="appBody">
        <DefaultZoneTreeContextProvider>
          <CareContextProvider>
            <InnerCare />
          </CareContextProvider>
        </DefaultZoneTreeContextProvider>
      </div>
    </div>
  );
}
