import React from 'react';
import { Switch, Route, RouteComponentProps } from 'react-router';

import { AddCareReceiver } from './add/AddCareReceiver';
import { ListCareReceivers } from './ListCareReceivers';
import { CareReceiverDetails } from './details/CareReceiverDetails';
import { EditCareReceiver } from './edit/EditCareReceiver';
import { useRedirectOnZoneChange } from '../../../lib/useRedirectOnZoneChange';

export function CareReceiver(props: RouteComponentProps<{}>) {
  useRedirectOnZoneChange({ relativePath: '/care-receivers' });
  return (
    <Switch>
      <Route
        exact={true}
        path={props.match.path}
        component={ListCareReceivers}
      />

      <Route
        exact={true}
        path={`${props.match.path}/new`}
        component={AddCareReceiver}
      />

      <Route
        exact={true}
        path={`${props.match.path}/:careReceiverId([0-9]+)/edit`}
        component={EditCareReceiver}
      />

      <Route
        exact={true}
        path={`${props.match.path}/:careReceiverId([0-9]+)`}
        component={CareReceiverDetails}
      />
    </Switch>
  );
}
