import React, { useEffect, useState } from 'react';
import moment from 'moment';
import * as yup from 'yup';
import { UserRoleDetailCard } from './UserRoleDetailCard';
import { RoleForm } from './forms/RoleForm';
import { RouteLeavingGuard } from '../../../components/RouteLeavingGuard';
import { useConfirmation } from '../../../lib/confirmation/ConfirmationContext';
import { errorIsString, useErrorHandler } from '../../../lib/useErrorHandler';
import { useApiCall } from '../../../lib/useApiCall';
import { useFormik } from 'formik';
import { t } from '../../../lib/i18n';

import * as api from '../../../serverApi';
import { useZoneTreeContext } from '../../care/lib/ZoneTreeContext';
import { useAccount } from '../../../lib/useAccount';

export interface UserRole {
  user_role_id: number;
  role: string;
  valid_from: string | null;
  valid_to: string | null;
  zone_id: number;
  zone_name: string | null;
}

interface Props {
  userId: number;
  userNationalIdNumber?: string | null;
  userRole: UserRole;
  onAfterChange?: () => void;
}

function formatDate(date: string | null) {
  if (!date) {
    return null;
  }
  return moment(date).format('YYYY-MM-DD');
}

export const UserRoleCard = (props: Props) => {
  const confirmation = useConfirmation();
  const errorHandler = useErrorHandler();
  const zoneTreeCtx = useZoneTreeContext();

  const res = useApiCall(api.getAvailableRoles);
  const account = useAccount();
  const availableRoles =
    res?.data?.roles?.filter((x) => x.role !== 'papi') || [];

  const [editing, setEditing] = useState(false);
  const [deleting, setDeleting] = useState(false);

  const formik = useFormik<UserRole>({
    enableReinitialize: true,
    initialValues: {
      ...props.userRole,
      valid_from: formatDate(props.userRole.valid_from),
      valid_to: formatDate(props.userRole.valid_to),
    },
    validationSchema: yup.object().shape({
      role: yup
        .string()
        .test(
          'Role',
          t('manage.permissions.UserRoleCard.roleRequiresNationalIdNumber'),
          (v) => {
            const rolesWithCapabilities = availableRoles.filter(
              (r) => r.role === v
            );

            const hasSendActivityToVkp = rolesWithCapabilities?.find((r: any) =>
              r.capabilities.includes('sendActivityToVkp')
            );

            return Boolean(
              account.disable_national_id_numbers ||
                !account.vkp_available ||
                !hasSendActivityToVkp ||
                props.userNationalIdNumber
            );
          }
        ),
    }),

    onSubmit: async (values, helpers) => {
      try {
        await api.updateUserRoles(values.user_role_id, {
          zone_id: values.zone_id,
          role: values.role,
          valid_from: values.valid_from,
          valid_to: values.valid_to,
        });
        setEditing(false);
        handleAfterChange();
      } catch (e) {
        errorHandler.handleError(e);
      }
    },
  });

  useEffect(() => {
    if (errorHandler) {
      errorHandler.reset();
    }
  }, [editing]); // eslint-disable-line react-hooks/exhaustive-deps

  const handleAfterChange = () => {
    if (props.onAfterChange) {
      props.onAfterChange();
    }
  };

  const handleCancelEdit = () => {
    if (!formik.dirty) {
      formik.resetForm();
      setEditing(false);
      return;
    }
    confirmation
      .confirm(
        t('common.commonTexts.unSavedChangesHaveBeenMadeDoYouWantToContinue')
      )
      .then(() => {
        formik.resetForm();
        setEditing(false);
      })
      .catch(() => {});
  };

  // const handleDeactivate = async () => {
  //   // TODO: We do not support inactive roles?
  //   console.log(`Set ${props.userRole.role} to inactive!`);
  // };

  const handleDelete = async () => {
    confirmation
      .confirm(t('common.commonTexts.toConfirmDeletionPressOk'))
      .then(async () => {
        setDeleting(true);
        try {
          await api.deleteUserRole(props.userRole.user_role_id);
          setDeleting(false);
          handleAfterChange();
        } catch (e) {
          setDeleting(false);
          errorHandler.handleError(e);
        }
      })
      .catch(() => {});
  };

  const handleDateChange = (
    name: 'valid_from' | 'valid_to',
    time: moment.Moment | null
  ) => {
    formik.setFieldTouched(name);
    formik.setFieldValue(name, time ? time.format('YYYY-MM-DD') : null);
  };

  if (editing) {
    const roleError = errorIsString(errorHandler.error)
      ? errorHandler.error
      : formik.errors.role || undefined;

    return (
      <div className="UserRoleCard-editing">
        <RouteLeavingGuard when={formik.dirty} />

        <RoleForm
          mode="edit"
          zoneLocked={
            /**
             * If the zone is not in the tree structure we want to
             * lock the zone so i cannot be changed.
             */
            !zoneTreeCtx.getZoneById(props.userRole.zone_id)
              ? props.userRole.zone_name ?? false
              : false
          }
          error={roleError}
          availableRoles={availableRoles}
          roleWhitelist={
            props.userRole.role === 'impersonal' ? ['impersonal'] : undefined
          }
          roleBlacklist={[
            props.userRole.role !== 'impersonal' ? 'impersonal' : '',
          ]}
          roleLocked={props.userRole.role === 'impersonal'}
          role={formik.values}
          setRole={(r) => {
            formik.setFieldTouched('role');
            formik.setFieldValue('role', r);
          }}
          setZone={(z) => {
            formik.setFieldTouched('zone_id');
            formik.setFieldValue('zone_id', z);
          }}
          setValidFrom={(validFrom) =>
            handleDateChange('valid_from', validFrom)
          }
          setValidTo={(validTo) => handleDateChange('valid_to', validTo)}
          onSave={formik.submitForm}
          onCancel={handleCancelEdit}
          loading={formik.isSubmitting}
        />
      </div>
    );
  }

  return (
    <UserRoleDetailCard
      error={
        errorIsString(errorHandler.error) && !editing
          ? errorHandler.error
          : undefined
      }
      deleting={deleting}
      userRole={props.userRole}
      onDelete={handleDelete}
      // onDeactivate={handleDeactivate}
      onEdit={() => setEditing(true)}
    />
  );
};
