import React from 'react';
import { NavbarBrand } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUserCircle } from '@fortawesome/pro-solid-svg-icons';
import { Link } from 'react-router-dom';
import { Icon } from '../Icon';

interface Props {
  username: string;
}

export const MobileHeader = (props: Props) => {
  return (
    <>
      <span className="nav-link nav-link-mobile-username">
        <FontAwesomeIcon icon={faUserCircle} className="mr-2" />
        <strong className="mr-1">{props.username}</strong>
      </span>

      <NavbarBrand tag={Link} to="/">
        <Icon icon="roommate-symbol" className="Header-logo" />
      </NavbarBrand>
    </>
  );
};
