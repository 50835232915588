import React from 'react';
import { Switch, Route } from 'react-router';
import { ListAccounts } from './ListAccounts';
import { AccountDetails } from './AccountDetails';
import { NewAccount } from './NewAccount';
import { EditAccount } from './EditAccount';

interface Props {
  rootUrl: string;
}

export function Accounts(props: Props) {
  return (
    <Switch>
      <Route exact={true} path={props.rootUrl}>
        <ListAccounts rootUrl={props.rootUrl} />
      </Route>

      <Route
        exact={true}
        path={`${props.rootUrl}/new`}
        component={NewAccount}
      />

      <Route
        exact={true}
        path={`${props.rootUrl}/:accountId/edit`}
        component={EditAccount}
      />

      <Route
        exact={true}
        path={`${props.rootUrl}/:accountId`}
        component={AccountDetails}
      />
    </Switch>
  );
}
