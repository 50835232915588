/**
 * Load Sentry as quickly as possible to catch any errors during initialization.
 */
import './lib/global/sentry';

/**
 * Import custom created polyfills.
 */
import './polyfills';

/**
 * Any necessary polyfills.
 */
import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';
import 'core-js/features/array';

/**
 * Add more polyfills for IE11
 * Ref.: https://www.jonhuu.com/sample-post/1786.html
 */
import 'core-js/stable';
import 'regenerator-runtime/runtime';

/**
 * Common libraries
 */
import React from 'react';
import ReactDOM from 'react-dom';
import { Router } from 'react-router-dom';
import Axios, { AxiosError } from 'axios';
import { toast } from 'react-toastify';
import * as Sentry from '@sentry/browser';

import { AppContextProvider } from './lib/global/AppContextProvider';

import history from './lib/history';
import App from './areas/App';

import { setApiRoot } from './serverApi';
import { ReactQueryClientProvider } from './lib/global/ReactQueryClientProvider';
import { ConfirmationProvider } from './lib/confirmation/ConfirmationProvider';
import ErrorBoundary from './components/ErrorBoundary';
import { ThemeProvider } from '@material-ui/core/styles';
import { roommateTheme } from './lib/themes/roommateTheme';
import { SnackProvider } from './lib/SnackContext';
import { ToastContainerComponent } from './components/ToastContainerComponent';
import { isAxiosError, isAxiosNetworkError } from './lib/utils/errorUtils';

import { I18nProvider, t } from './lib/i18n';
import config from './config';

import './styles/main.scss';

/**
 * By setting the REACT_APP_API_ROOT, we can use a local development frontend
 * with a remote production/staging backend. If it is not set, the frontend
 * will connect to the backend at the same hostname as the frontend.
 */
if (config.apiRootPath) {
  console.log('Using API root:', config.apiRootPath);
  setApiRoot(config.apiRootPath);
  // We must specify withCredentials for the axios calls to include session cookies in XHR requests.
  Axios.defaults.withCredentials = true;
}

/**
 * Add a global error handler to the Axios calls to catch session timeouts
 * or invalid sessions. We want to avoid a situation in which we appear to
 * still be logged into the application, but all API calls fail.
 */
let toastId: number | string | undefined;

Axios.interceptors.response.use(
  (response) => response,
  (error: Error | AxiosError) => {
    if (!isAxiosError(error)) {
      /**
       * If the error is not an AxiosError type, we show the default error message and log it to sentry
       * with a specific error id so we know that it came from here.
       */
      toast.error(t('common.error.defaultError'));
      Sentry.withScope((scope) => {
        scope.setTag('error_id', 1996);
        Sentry.captureException(error);
      });
      return Promise.reject(error);
    }

    /**
     * If error happened due to not having an internet connection we show a common network error message.
     * Also, we have a 5 second threshold so the user is not spammed with notifications in pages where there are multiple requests made.
     */
    if (isAxiosNetworkError(error)) {
      if (!toastId) {
        toastId = toast.error(t('common.error.networkError'));
        setTimeout(() => {
          toastId = undefined;
        }, 5000);
      }

      return Promise.reject(error);
    }

    /**
     * If error status is greater than 500, we know it was an internal server error, so we show a general message
     * including the error response status and message
     */
    if (error.response?.status && error.response.status >= 500) {
      if (!toastId) {
        toastId = toast.error(
          <div>
            <p>{`${error.response.status} - ${t('lib.common.serverError')}`}</p>
            <p>{error.response.data?.message || ''}</p>
          </div>
        );
        setTimeout(() => {
          toastId = undefined;
        }, 1000);
      }
      return Promise.reject(error);
    }

    if (
      // When status response is 401 Not authenticated or 419 token mismatch(session expired) ...
      (error.response?.status === 401 || error.response?.status === 419) &&
      //... unless the error code is due to invalid IP address
      // when selecting the role (the role selection page will
      // take care of this error)
      error.response?.data.code !== 'user-role-disabled-invalid-ip' &&
      // ... and unless we are already at the /login page
      history.location.pathname !== '/login'
    ) {
      const idleTimeleftHeader =
        error.response?.headers['x-roommate-idle-time-left'];
      /**
       * Check if idle timeleft header is equal to 0,
       * this means that the user's idle timeout has passed and it will be logged out.
       */
      if (
        typeof idleTimeleftHeader === 'string' &&
        idleTimeleftHeader === '0'
      ) {
        /**
         * Encode current URL to pass it to the login page for redirecting the user
         */
        const redirectUrl = encodeURIComponent(window.location.pathname);

        /**
         * Append a timeout parameter when redirecting to login page, so we can use it inform the user
         * that it is logged out due to the idle timeout.
         */
        window.location.replace('/login?timeout=1&redirect=' + redirectUrl);
        return Promise.reject(error);
      }
      window.location.reload();
    }

    return Promise.reject(error);
  }
);

/**
 *
 * Order of the providers is significant:
 *
 * AppContextProvider must be outside I18nProvider, to avoid
 * having to reauthenticate when switching languages (a language
 * switch will force a full component tree remount below the
 * I18nProvider level).
 *
 * ThemeProvider and SnackProvider don't interact much with the
 * rest of the application and can be on the outside.
 *
 * ConfirmationProvider depends on being within a Router.
 *
 */
ReactDOM.render(
  <ThemeProvider theme={roommateTheme}>
    <SnackProvider>
      <ReactQueryClientProvider>
        <Router history={history}>
          <ConfirmationProvider>
            <AppContextProvider>
              <I18nProvider>
                <ErrorBoundary>
                  <>
                    <App />

                    <ToastContainerComponent />
                  </>
                </ErrorBoundary>
              </I18nProvider>
            </AppContextProvider>
          </ConfirmationProvider>
        </Router>
      </ReactQueryClientProvider>
    </SnackProvider>
  </ThemeProvider>,
  document.getElementById('root')
);
