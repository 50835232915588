import React, { useState } from 'react';
import { useSensorContext } from '../../../../lib/SensorContext';
import { useConfirmation } from '../../../../lib/confirmation/ConfirmationContext';
import * as api from '../../../../serverApi';
import { IconButton } from '../../../../components/IconButton';
import { useErrorHandler } from '../../../../lib/useErrorHandler';
import { ErrorMessages } from '../../../../components/ErrorMessages';
import { t } from '../../../../lib/i18n';

interface Props {
  disabled?: boolean;
  className?: string;
  onChanged: () => void;
}

export default function UnassignSensorButton(props: Props) {
  const [unAssigning, setUnAssigning] = useState(false);
  const confirmation = useConfirmation();
  const sensor = useSensorContext();
  const errorHandler = useErrorHandler();

  const zoneId = sensor.zone?.id;

  async function onClick() {
    if (!zoneId || !sensor.id) {
      return;
    }
    confirmation
      .confirm(
        t(
          'manage.sensors.details.UnassignSensorButton.unAssignSensorConfirmation'
        )
      )
      .then(async () => {
        try {
          errorHandler.reset();
          setUnAssigning(true);
          await api.updateSensors(sensor.id, {
            zone_id: null,
          });
          setUnAssigning(false);
          if (props.onChanged) {
            props.onChanged();
          }
        } catch (error) {
          setUnAssigning(false);
          errorHandler.handleError(error);
        }
      })
      .catch(() => {});
  }

  return (
    <>
      <IconButton
        icon="map-marker"
        color="secondary"
        className={props.className}
        disabled={!zoneId || props.disabled || unAssigning}
        onClick={onClick}
      >
        {t(
          'manage.sensors.details.UnassignSensorButton.removeTheSensorConnectionToTheHousingUnit'
        )}
      </IconButton>
      <ErrorMessages tag="span" className="m-2" errorData={errorHandler} />
    </>
  );
}
