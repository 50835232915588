import * as api from '../../../serverApi';
import { t } from '../../../lib/i18n';

import { getLabelForEventType } from '../../../lib/getLabelForEventType';
import { eventTypesBySensorType } from '../../../eventTypes';
import { useMemo } from 'react';
import { EventTypeIcon } from '../../../lib/utils/EventTypeIcon';
import { LevelIndicator } from './LevelIndicator';
import { Icon } from '../../../components/Icon';

import './EventSettingsInfoTable.scss';

interface Props {
  event_settings?: Array<{
    enabled?: boolean;
    enabled_from?: string | null;
    enabled_to?: string | null;
    event_type: api.EventType;
    sensor_type: api.SensorType;
    level?: string | null;
  }>;
  markingObjectsLoading?: boolean;

  /**
   * An array of the marking objects to be drawn on the canvas.
   */
  markingObjects?: api.MarkingObject[];
}

export function EventSettingsInfoTable(props: Props) {
  const eventSettings = useMemo(
    () =>
      props.event_settings?.map((es) => {
        // Find the marking type required for this event type (if any)
        const requiredMarkingType =
          eventTypesBySensorType[es.sensor_type][es.event_type]
            .requiredMarkingType;
        // Find any marking for this sensor that matches the required type
        const requiredMarkingObject = props.markingObjects?.find(
          (mo) => mo.type === requiredMarkingType
        );
        // If a type is required but not present, it's lacking a marking ...
        const lacksMarking = Boolean(
          requiredMarkingType && !requiredMarkingObject
        );
        // Or if the marking is present, but has a warning ...
        const has_warning = Boolean(requiredMarkingObject?.has_warning);
        return {
          ...es,
          lacksMarking,
          has_warning,
        };
      }),

    [props.markingObjects, props.event_settings]
  );

  if (!eventSettings) {
    return null;
  }

  return (
    <table className="EventSettingsInfoTable mb-4">
      <thead>
        <tr>
          <th className="pr-4">
            {t('care.components.EventSettingsInfoTable.event')}
          </th>
          <th className="pr-4">
            {t('care.components.EventSettingsInfoTable.level')}
          </th>
          <th>{t('care.components.EventSettingsInfoTable.schedule')}</th>
          <th></th>
        </tr>
      </thead>
      <tbody>
        {eventSettings.map((es) => {
          if (!es.enabled) {
            return null;
          }

          const showTime = es.enabled_from !== null && es.enabled_to !== null;

          return (
            <tr key={es.event_type} className="EventSettingsInfoTable-row">
              <td className="EventSettingsInfoTable-row-eventIcon">
                <EventTypeIcon
                  sensorType={es.sensor_type}
                  eventType={es.event_type}
                  className="mr-2"
                />
                <span className="EventSettingsInfoTable-row-eventLabel">
                  {getLabelForEventType(es.event_type, es.sensor_type)}
                </span>
              </td>
              <td className="EventSettingsInfoTable-row-eventLevelIndicator">
                <LevelIndicator level={es.level} />
                <span className="EventSettingsInfoTable-row-eventLabel-mobile">
                  {getLabelForEventType(es.event_type, es.sensor_type)}
                </span>
              </td>
              <td className="EventSettingsInfoTable-row-eventSchedule">
                {showTime
                  ? `${es.enabled_from} - ${es.enabled_to}`
                  : t('care.components.InfoPage.noTimeRestriction')}
              </td>
              <td className="EventSettingsInfoTable-row-eventWarnings">
                {es.lacksMarking && (
                  <div className="EventSettingsInfoTable-error">
                    <Icon
                      className="EventSettingsInfoTable-icon"
                      icon="warning"
                    />
                    <span>{t('care.components.InfoPage.lacksMarking')}</span>
                  </div>
                )}

                {es.has_warning && (
                  <div className="EventSettingsInfoTable-warning">
                    <Icon
                      className="EventSettingsInfoTable-icon"
                      icon="warning"
                    />
                    <span>
                      {t('care.components.InfoPage.markingsOutsideThreshold')}
                    </span>
                  </div>
                )}
              </td>
            </tr>
          );
        })}
      </tbody>
    </table>
  );
}
