import React from 'react';

import useEventSettingsForm, { EventFormProps } from '../useEventSettingsForm';

import EventActiveControl from '../controls/EventActiveControl';
import EventLevelControl from '../controls/EventLevelControl';
import EventTimeIntervalControl from '../controls/EventTimeIntervalControl';
import EventFormControls from '../controls/EventFormControls';
import PlayEventSoundControl from '../controls/PlayEventSoundControl';
import EventSelectControl from '../controls/EventSelectControl';
import { RouteLeavingGuard } from '../../../../../components/RouteLeavingGuard';
import { Panel } from '../../../../../components/Panel';
import { t, TranslationKey } from '../../../../../lib/i18n';
import { getLabelForEventType } from '../../../../../lib/getLabelForEventType';

const sensitivityOptions: Array<{
  description?: TranslationKey;
  value: number;
}> = [
  {
    value: 1,
    description: 'manage.sensors.events.FallEventForm.sensitivity.low',
  },
  {
    value: 2,
    description: 'manage.sensors.events.FallEventForm.sensitivity.normal',
  },
  {
    value: 3,
  },
  {
    value: 4,
    description: 'manage.sensors.events.FallEventForm.sensitivity.high',
  },
];

export default function FallEventForm(props: EventFormProps) {
  const form = useEventSettingsForm({
    sensorType: 'roommate',
    eventType: 'fall',

    initialValues: props.initialValues
      ? {
          ...props.initialValues,
          threshold1: props.initialValues?.threshold1 ?? 2,
        }
      : undefined,
  });

  return (
    <form onSubmit={form.handleSubmit}>
      <RouteLeavingGuard when={form.dirty} />

      <h2>{getLabelForEventType('fall', 'roommate')}</h2>

      <Panel>
        <span
          dangerouslySetInnerHTML={{
            __html: t('manage.sensors.events.FallEventForm.firstInfo'),
          }}
        />
      </Panel>

      <EventActiveControl form={form} />

      <EventTimeIntervalControl form={form} />

      <EventSelectControl
        form={form}
        label={t('manage.sensors.events.FallEventForm.sensitivity.label')}
        field="threshold1"
        options={sensitivityOptions.map((so) => ({
          label: so.description
            ? `${so.value} (${t(so.description)})`
            : `${so.value}`,
          value: so.value,
        }))}
      />

      <EventLevelControl form={form} />

      <PlayEventSoundControl form={form} enableFallOption={true} />

      <EventFormControls form={form} />
    </form>
  );
}
