import React, { useState } from 'react';
import { useZoneContext } from '../../../lib/ZoneContext';
import CreateZoneModal from '../../../components/modals/zones/CreateZoneModal';
import { IconButton } from '../../../components/IconButton';
import { t } from '../../../lib/i18n';

export const CreateZoneButton = () => {
  const [isOpen, setIsOpen] = useState(false);
  const [housingUnit, setHousingUnit] = useState<boolean | undefined>(
    undefined
  );

  const ctx = useZoneContext();

  const handleModal = () => setIsOpen(!isOpen);

  const handleClick = (housingUnit: boolean) => {
    setIsOpen(true);
    setHousingUnit(housingUnit);
  };

  return (
    <>
      <IconButton
        disabled={ctx.activeZone.api_lock}
        icon="home"
        color="primary"
        onClick={() => handleClick(true)}
      >
        {t('manage.zone.CreateZoneButton.createNewHousingUnit')}
      </IconButton>
      <IconButton
        disabled={ctx.activeZone.api_lock}
        icon="zone"
        color="primary"
        onClick={() => handleClick(false)}
      >
        {t('manage.zone.CreateZoneButton.createNewSubzone')}
      </IconButton>

      <CreateZoneModal
        isOpen={isOpen}
        housingUnit={housingUnit}
        activeZone={ctx.activeZone}
        handleModal={handleModal}
        updateTree={ctx.reload}
      />
    </>
  );
};
