import React from 'react';
import moment from 'moment';
import { MaterialUiPickersDate } from '@material-ui/pickers/typings/date';
import {
  KeyboardDatePicker,
  MuiPickersUtilsProvider,
} from '@material-ui/pickers';
import { getGlobalLocale, t } from '../../../../../../../lib/i18n/i18n';
import MomentUtils from '@date-io/moment';

const dateFormat = 'YYYY-MM-DD';

export const EditableDateCell = (props: any) => {
  const {
    value: initialValue,
    row: { index },
    column: { id },
    onUpdate: updateData,
  } = props;

  const [editing, setEditing] = React.useState(false);
  const [value, setValue] = React.useState(
    initialValue ? moment(initialValue).format(dateFormat) : undefined
  );

  const onClick = () => setEditing(true);

  const onChange = (date: MaterialUiPickersDate) => {
    setValue(date?.format(dateFormat));
  };

  const onAccept = (date: MaterialUiPickersDate) => {
    const val = date?.format(dateFormat);
    setEditing(false);

    if (val === initialValue) {
      return;
    }

    updateData(index, id, val);
  };

  const onClose = () => {
    setEditing(false);
  };

  React.useEffect(() => {
    setValue(initialValue);
  }, [initialValue]);

  return (
    <span className="EditableDateCell">
      {!editing ? (
        <span onClick={onClick} className="EditableDateCell-value">
          {value ? moment(value).format(dateFormat) : ''}
        </span>
      ) : (
        <MuiPickersUtilsProvider locale={getGlobalLocale()} utils={MomentUtils}>
          <KeyboardDatePicker
            open={editing}
            autoFocus
            margin="dense"
            style={{ maxWidth: 180 }}
            label={t('manage.logs.ui.Filters.periodFrom')}
            okLabel={t('common.commonButtons.ok')}
            cancelLabel={t('common.commonButtons.cancel')}
            inputVariant="outlined"
            format={dateFormat}
            value={value}
            onChange={onChange}
            onClose={onClose}
            onAccept={onAccept}
          />
        </MuiPickersUtilsProvider>
      )}
    </span>
  );
};
