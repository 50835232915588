import React from 'react';
import { Button } from '@material-ui/core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSyncAlt } from '@fortawesome/pro-solid-svg-icons';

import './FiltersWrapper.scss';

interface Props {
  children: React.ReactNode;
  onReset: () => void;
}
export const FiltersWrapper = (props: Props) => {
  return (
    <div className="FiltersWrapper">
      {props.children}

      <Button
        variant="contained"
        onClick={props.onReset}
        className="FiltersWrapper__btn-reset"
      >
        <FontAwesomeIcon icon={faSyncAlt} className="mr-2" />
        Reset
      </Button>
    </div>
  );
};
