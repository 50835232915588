import React, { useContext, useEffect, useState } from 'react';
import * as api from '../../../serverApi';

type ZoneMap = {
  [id: number]: api.StructureZone;
};

export interface ZoneTreeContext {
  root: api.StructureZone | undefined;
  getZoneById: (
    zone_id: number | undefined | null
  ) => api.StructureZone | undefined;
  getParentZone: (
    z: api.StructureZone | undefined
  ) => api.StructureZone | undefined;
  reload: () => void;
  loading: boolean;
}

const zoneTreeContext = React.createContext<ZoneTreeContext>({
  root: undefined,
  getZoneById: () => undefined,
  getParentZone: () => undefined,
  reload: () => {},
  loading: false,
});

export const useZoneTreeContext = () => useContext(zoneTreeContext);

const { Provider } = zoneTreeContext;

export { Provider as ZoneTreeContextProvider };

export function useZoneTreeQuery(): ZoneTreeContext {
  const structure = api.useGetStructureQuery();

  const [zoneById, setZoneById] = useState<ZoneMap>({});

  useEffect(() => {
    const zoneMap: ZoneMap = {};
    function traverse(z: api.StructureZone) {
      zoneMap[z.id] = z;

      z.zones?.forEach(traverse);
    }
    if (structure.data) {
      traverse(structure.data.zone);
    }
    setZoneById(zoneMap);
  }, [structure.data]);

  function getZoneById(zone_id: number | null | undefined) {
    if (!zone_id) {
      return undefined;
    }
    return zoneById[zone_id];
  }

  //TODO: fix getParentId, this should be fixed and return parentZone as object.
  function getParentId(
    activeZone: api.StructureZone,
    rootZone: api.StructureZone
  ): number[] {
    let id: number[] = [];

    if (rootZone.zones?.includes(activeZone)) {
      id = id.concat(rootZone.id);
    }

    rootZone.zones?.forEach((z) => {
      id = id.concat(getParentId(activeZone, z));
    });

    return id;
  }

  function getParentZone(
    zone: api.StructureZone | undefined
  ): api.StructureZone | undefined {
    const rootZone = structure?.data?.zone;

    if (!zone || !rootZone) {
      return undefined;
    }

    const parentId = getParentId(zone, rootZone);

    return parentId[0] ? zoneById[parentId[0]] : undefined;
  }

  const context: ZoneTreeContext = {
    root: structure && structure.data ? structure.data.zone : undefined,
    getZoneById,
    getParentZone,
    reload: structure.refetch,
    loading: structure.isLoading,
  };

  return context;
}
