import React from 'react';
import moment from 'moment';
import * as Sentry from '@sentry/browser';

import { IconButton } from '../../../components/IconButton';
import { useZoneContext } from '../../../lib/ZoneContext';
import { useRouter } from '../../../lib/useRouter';
import getParentPath from '../../../lib/getParentPath';
import {
  PermissionsFields,
  usePermissionsFormik,
} from './lib/usePermissionsFormik';
import isValidNorwegianId from '../../../lib/isValidNorwegianId';
import { PersonaliaForm } from './forms/PersonaliaForm';
import { RoleForm } from './forms/RoleForm';
import { Button } from '../../../components/Button';

import * as yup from 'yup';
import * as serverApi from '../../../serverApi';
import { t } from '../../../lib/i18n';
import { ButtonGroup } from '../../../components/ButtonGroup';
import { useHandleCancel } from '../../../lib/useHandleCancel';
import { useErrorHandler } from '../../../lib/useErrorHandler';
import { ErrorMessages } from '../../../components/ErrorMessages';
import { useApiCall } from '../../../lib/useApiCall';

import { useZoneTreeContext } from '../../care/lib/ZoneTreeContext';
import { useAccount } from '../../../lib/useAccount';
import { CreateUserPassword } from '../../../serverApi';

import './NewUser.scss';
import { isValidationError } from '../../../lib/utils/errorUtils';

const defaultRole = '';

export function NewUser() {
  const router = useRouter();
  const context = useZoneContext();

  return (
    <div className="NewUser">
      <NewUserForm
        onSubmit={() => {
          router.history.push(
            '/manage/' + context.activeZone.id + '/permissions'
          );
        }}
      />
    </div>
  );
}

interface NewUserFormProps {
  initialValues?: PermissionsFields;
  onSubmit: () => void;
}

export function NewUserForm(props: NewUserFormProps) {
  const router = useRouter();
  const context = useZoneContext();
  const hc = useHandleCancel();
  const account = useAccount();
  const treeCtx = useZoneTreeContext();
  const errorHandler = useErrorHandler();

  const res = useApiCall(serverApi.getAvailableRoles);

  const availableRoles =
    res?.data?.roles?.filter((x) => x.role !== 'papi') || [];

  const formik = usePermissionsFormik({
    initialValues: { ...props.initialValues },
    validationSchema: () =>
      yup.object().shape(
        {
          username: yup.string().required(),
          national_id_number: yup
            .string()
            .when(['roles'], (roles: any, passSchema: any) => {
              const rolesWithCapabilities = availableRoles.filter((r) =>
                roles.find((x: any) => x.role === r.role)
              );

              const hasSendActivityToVkp = Boolean(
                rolesWithCapabilities?.find((r: any) =>
                  r.capabilities.includes('sendActivityToVkp')
                )
              );

              return hasSendActivityToVkp &&
                account.vkp_available &&
                !account.disable_national_id_numbers
                ? passSchema.required(
                    t('common.commonTexts.birthNumberorDnumberIsRequired')
                  )
                : passSchema;
            })
            .test(
              'national id number',
              t('common.commonTexts.invalidBirthNumber'),
              (x) => !x || isValidNorwegianId(x)
            ),
          first_name: yup.string().when('email', {
            is: (values: unknown) => !values,
            then: yup
              .string()
              .required(
                t('manage.permissions.common.firstNameOrEmailIsRequired')
              ),
            otherwise: yup.string().nullable(),
          }),
          last_name: yup.string().nullable(),
          email: yup
            .string()
            .when(
              ['first_name', 'password'],
              (
                firstNameValue: string,
                passwordValue: CreateUserPassword,
                passSchema: any
              ) => {
                if (!firstNameValue) {
                  return passSchema.required(
                    t('manage.permissions.common.firstNameOrEmailIsRequired')
                  );
                }

                if (passwordValue.notify === 'email') {
                  return passSchema.required(t('yup.string.email'));
                }
              }
            ),
          phone: yup
            .string()
            .test(
              'Serial',
              t(
                'manage.permissions.common.thePhoneNumberMustContainOnlyDigits'
              ),
              (v) => {
                return typeof v === 'string' ? /^\+?[0-9 ]+$/.test(v) : true;
              }
            )
            .nullable(),
          roles: yup.array().of(
            yup
              .object()
              .shape({
                role: yup
                  .string()
                  .required(t('manage.permissions.common.roleIsRequired')),
                zone_id: yup.string(),
              })
              .test(
                'role',
                t(
                  'manage.permissions.common.onlyHousingForResidentAndNextOfKin'
                ),
                (v) => {
                  const selectedZone = v
                    ? treeCtx.getZoneById(v.zone_id)
                    : undefined;

                  const isRoleToTest =
                    v?.role && ['nextOfKin', 'resident'].includes(v.role);
                  return !(!selectedZone?.is_housing_unit && isRoleToTest);
                }
              )
          ),
        },
        [['email', 'first_name']]
      ),

    onSubmit: async (values, formikHelpers) => {
      try {
        //If external_idp field is true we remove password from request payload;
        if (values.external_idp) {
          const { password, ...data } = values;
          await serverApi.createUser({
            ...data,
            preferred_locale:
              values.preferred_locale === 'null'
                ? null
                : values.preferred_locale,
          } as serverApi.CreateUserData);
        } else {
          await serverApi.createUser({
            ...values,
            preferred_locale:
              values.preferred_locale === 'null'
                ? null
                : values.preferred_locale,
          } as serverApi.CreateUserData);
        }

        formikHelpers.resetForm(values);

        props.onSubmit();
      } catch (e) {
        Sentry.captureException(e);

        if (isValidationError(e)) {
          formikHelpers.setSubmitting(false);
          formikHelpers.setErrors(e.response?.data.errors);

          if (e.response?.data?.errors?.['password.password']) {
            formikHelpers.setFieldError(
              'password',
              e.response.data.errors['password.password'][0]
            );
          }
          return;
        }
        errorHandler.handleError(e);
      }
    },
  });

  const setRole = (roleIndex: number, role: string) => {
    formik.setFieldTouched('roles');
    formik.setFieldValue(
      'roles',
      formik.values.roles.map((r, i) =>
        i === roleIndex ? { ...r, role: role } : r
      )
    );
  };

  const setZone = (roleIndex: number, zoneId: number) => {
    formik.setFieldTouched('roles');
    formik.setFieldValue(
      'roles',
      formik.values.roles.map((r, i) =>
        i === roleIndex ? { ...r, zone_id: zoneId } : r
      )
    );
  };

  const onDateChange = (
    roleIndex: number,
    name: string,
    time: moment.Moment | null
  ) => {
    formik.setFieldTouched('roles');
    formik.setFieldValue(
      'roles',
      formik.values.roles.map((role, i) =>
        i === roleIndex
          ? {
              ...role,
              [name]: time ? time.format('YYYY-MM-DD') : null,
            }
          : role
      )
    );
  };

  const handleAddRole = () => {
    formik.setFieldValue('roles', [
      {
        role: defaultRole,
        zone_id: context.activeZone.id,
        valid_from: null,
        valid_to: null,
      },
      ...formik.values.roles,
    ]);
  };

  const handleDeleteRole = (roleIndex: number) => {
    if (formik.values.roles.length > 1) {
      formik.setFieldValue(
        'roles',
        formik.values.roles.filter((r, i) => i !== roleIndex)
      );
    }
  };

  const roleError = (roleIndex: number) => {
    if (
      formik.touched.roles &&
      formik.errors.roles &&
      Array.isArray(formik.errors.roles)
    ) {
      if (formik.errors.roles[roleIndex]) {
        const val = formik.errors.roles[roleIndex];
        if (typeof val === 'string') {
          return val;
        } else {
          return val.role;
        }
      }
    }
    return undefined;
  };

  return (
    <form onSubmit={formik.handleSubmit}>
      <h1>{t('manage.permissions.common.registerNewServiceProviderAccess')}</h1>

      <div className="my-4">
        <IconButton
          icon="arrow-left"
          to={getParentPath(router.location.pathname)}
        >
          {t('common.commonButtons.back')}
        </IconButton>
      </div>

      <div className="row">
        <div className="col-md-6 mt-3">
          <PersonaliaForm
            mode="create"
            form={formik}
            title={t('components.NewUser.personalia')}
          />
          <div className="mt-4">
            <ButtonGroup>
              <Button
                type="submit"
                color="primary"
                disabled={formik.isSubmitting}
                spinIcon={formik.isSubmitting}
                icon={formik.isSubmitting ? 'gear' : undefined}
              >
                {t('common.commonButtons.save')}
              </Button>
              <Button
                onClick={() =>
                  hc.handleCancel(formik.dirty, null, () => formik.resetForm())
                }
              >
                {t('common.commonButtons.cancel')}
              </Button>
            </ButtonGroup>

            <ErrorMessages
              tag="span"
              className="m-3"
              errorData={errorHandler}
            />
          </div>
        </div>

        <div className="col-md-6 NewUser-roles">
          <IconButton
            icon="plus"
            color="primary"
            className="mb-2"
            onClick={handleAddRole}
          >
            {t('manage.permissions.common.addRole')}
          </IconButton>

          {formik.values.roles.map((r, index) => {
            return (
              <RoleForm
                key={`${r.role}-${index}`} //Because by default we set an empty role, we should add also __index__ in key prop to be unique.
                mode="create"
                availableRoles={availableRoles}
                roleBlacklist={['impersonal']}
                role={r}
                setRole={(role) => setRole(index, role)}
                setZone={(zoneId) => setZone(index, zoneId)}
                setValidFrom={(validFrom) =>
                  onDateChange(index, 'valid_from', validFrom)
                }
                setValidTo={(validTo) =>
                  onDateChange(index, 'valid_to', validTo)
                }
                onRemove={() => handleDeleteRole(index)}
                deleteDisabled={formik.values.roles.length === 1}
                error={roleError(index)}
              />
            );
          })}
        </div>
      </div>
    </form>
  );
}
