import React, { CSSProperties } from 'react';
import cs from 'classnames';

import { Radio } from '@material-ui/core';

import { MarkingObject, markingObjectDefinitions } from '../types';
import { Icon } from '../../../../../components/Icon';
import { isEqual } from '../lib/markingObjectUtils';

import './SelectMarkingObject.scss';
import { InlineSpinner } from '../../../../../components/InlineSpinner';
import { t, TranslationKey } from '../../../../../lib/i18n';

interface Props {
  className?: string;
  style?: CSSProperties;

  /**
   * An array of the currently stored marking objects
   */
  markingObjects?: MarkingObject[];

  /**
   * The item number of the currently selected/active marking object.
   */
  selectedMarkingObjectItemNum?: number;

  /**
   * Event triggered when the user selects a marking object (or unselects)
   */
  setSelectedMarkingObjectItemNum?: (itemNum: number | undefined) => void;
}

/**
 * Display the currently set marking objects for this sensor
 * and let the user select/unselect them.
 */
export function SelectMarkingObject(props: Props) {
  const sortedObjs = [...(props.markingObjects || [])];

  // Sort by marking item number (not sure why, but at least it's consistent)
  sortedObjs.sort((a, b) => a.item - b.item);

  return (
    <ul
      className={cs('SelectMarkingObject', props.className)}
      style={props.style}
    >
      {sortedObjs.length ? (
        <>
          {sortedObjs.map((mo) => {
            const def = markingObjectDefinitions[mo.type];

            const select = () => {
              props.setSelectedMarkingObjectItemNum?.(
                props.selectedMarkingObjectItemNum === mo.item
                  ? undefined
                  : mo.item
              );
            };

            const selected = mo.item === props.selectedMarkingObjectItemNum;

            const unsavedChanges =
              mo && (!mo.original || !isEqual(mo.points, mo.original.points));

            return (
              <li key={mo.item}>
                <label
                  onClick={(e) => {
                    if (selected) {
                      e.preventDefault();
                      props.setSelectedMarkingObjectItemNum?.(undefined);
                    }
                  }}
                >
                  <Radio
                    color="primary"
                    checked={selected}
                    onChange={(e) => {
                      if (e.currentTarget.checked) {
                        props.setSelectedMarkingObjectItemNum?.(mo.item);
                      } else {
                        props.setSelectedMarkingObjectItemNum?.(undefined);
                      }
                    }}
                  />

                  <div
                    style={{
                      backgroundColor: def.iconColor,
                      fill: 'white',
                      color: 'white',
                      display: 'inline-block',
                      borderRadius: '6px',
                      marginRight: '16px',
                      marginLeft: '8px',
                      cursor: 'pointer',
                      padding: '3px',
                    }}
                  >
                    <Icon
                      icon={def.icon}
                      style={{
                        width: '24px',
                        height: '24px',
                      }}
                    />
                  </div>
                  <div
                    onClick={select}
                    style={{
                      display: 'inline-block',
                      fontWeight: selected ? 'bold' : undefined,
                      pointerEvents: 'none',
                    }}
                  >
                    {t(
                      `manage.sensors.marking.markingObjects.${mo.type}` as TranslationKey
                    )}

                    {mo.original?.has_warning && (
                      <Icon
                        icon="warning"
                        style={{
                          fill: 'rgb(226,177,18)',
                          marginLeft: '15px',
                        }}
                      />
                    )}

                    {unsavedChanges && (
                      <span
                        style={{
                          fontWeight: 'normal',
                          marginLeft: '24px',
                          padding: '0 4px',
                          color: '#898989',
                          fontSize: '12px',
                          display: 'inline-block',
                          borderRadius: '4px',
                          border: '1px solid #898989',
                          // backgroundColor: 'rgba(0,0,0,0.1)',
                        }}
                      >
                        {t('common.commonTexts.notSaved')}
                      </span>
                    )}
                  </div>

                  {mo.saving || mo.deleting ? (
                    <InlineSpinner size="sm" className="ml-3" />
                  ) : null}
                </label>
              </li>
            );
          })}
        </>
      ) : (
        <li style={{ padding: '16px 0' }}>
          (
          {t(
            'manage.sensors.marking.components.SelectMarkingObject.noSelections'
          )}
          )
        </li>
      )}
    </ul>
  );
}
