import React, { useEffect, useState } from 'react';
import { AxiosError } from 'axios';
import moment from 'moment';
import * as Sentry from '@sentry/browser';

import { ReactTable } from '../../../../../../components/ReactTable/ReactTable';
import LoadingPlaceholder from '../../../../../../lib/ui/LoadingPlaceholder';
import { t } from '../../../../../../lib/i18n';
import { Button } from '../../../../../../components/Button';
import { faSpinner, faTrash } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useConfirmation } from '../../../../../../lib/confirmation/ConfirmationContext';
import { ErrorMessages } from '../../../../../../components/ErrorMessages';
import { EditableTextCell } from './ui/EditableTextCell';
import { EditableDateCell } from './ui/EditableDateCell';
import { BillingAndPoForm } from './BillingAndPoForm';

import * as api from '../../../../../../serverApi';

import './BillingAndPO.scss';
import { isAxiosError } from '../../../../../../lib/utils/errorUtils';

interface Props {
  sensor: api.SuperVendorSensor;
}

const _formatSensorBillingPeriod = (x: api.SensorBillingPeriod) => ({
  sensor_billing_period_id: x.sensor_billing_period_id,
  period_from: x.period_from,
  period_to: x.period_to,
  purchase_order: x.purchase_order,
  comment: x.comment,
  created_at: moment(x.created_at).format('YYYY-MM-DD h:m'),
  updated_at: moment(x.created_at).format('YYYY-MM-DD h:m'),
});

const _formatData = (data: api.SensorBillingPeriod[]) =>
  data.map((i) => _formatSensorBillingPeriod(i));

type LoadingType = 'read' | 'update' | 'create' | number | null;

export type BillingAndPOData = Omit<
  api.SensorBillingPeriod,
  'sensor_id' | 'sensor_type'
>;

export const BillingAndPO = (props: Props) => {
  const { sensor } = props;

  const confirmation = useConfirmation();

  const [loadingType, setLoadingType] = useState<LoadingType>();
  const [error, setError] = useState<AxiosError | undefined>(undefined);
  const [data, setData] = useState<BillingAndPOData[]>([]);

  // Fetch sensor billing periods
  useEffect(() => {
    setLoadingType('read');
    api
      .superGetSensorBillingPeriods(sensor.id)
      .then((response) => {
        setData(_formatData(response.sensor_billing_periods));
      })
      .catch((error) => {
        setError(error);
        Sentry.captureException(error);
      })
      .finally(() => {
        setLoadingType(null);
      });
  }, [sensor.id]);

  const columns = React.useMemo(
    () => [
      {
        Header: 'From',
        accessor: 'period_from',
        Cell: EditableDateCell,
      },
      {
        Header: 'To',
        accessor: 'period_to',
        Cell: EditableDateCell,
      },
      {
        Header: 'Purchase Order',
        accessor: 'purchase_order',
        Cell: EditableTextCell,
      },
      {
        Header: 'Comment',
        accessor: 'comment',
        Cell: EditableTextCell,
      },
      {
        Header: 'Created at',
        accessor: 'created_at',
      },
      {
        Header: 'Updated at',
        accessor: 'updated_at',
      },
      {
        Header: 'Delete',
        accessor: 'sensor_billing_period_id',
        Cell: (data) => {
          const {
            row: { index },
            column: { id },
            onDelete,
          } = data;
          const isDeleting = loadingType === data.cell.value;
          return (
            <Button
              disabled={isDeleting}
              onClick={() => onDelete(index, id, data.cell.value)}
            >
              <FontAwesomeIcon
                spin={isDeleting}
                icon={isDeleting ? faSpinner : faTrash}
              />
            </Button>
          );
        },
      },
    ],
    [loadingType]
  );

  const handleUpdate = async (
    rowIndex: number,
    columnId: number | string,
    value: any
  ) => {
    const item = data.find((x, index) => index === rowIndex);
    if (!item) {
      return;
    }

    try {
      setError(undefined);
      setLoadingType('update');

      const res = await api.superUpdateSensorBillingPeriod(
        item.sensor_billing_period_id.toString(),
        {
          period_from: item.period_from || undefined,
          period_to: item.period_to || undefined,
          [columnId]: value,
        }
      );

      const updatedItem = _formatSensorBillingPeriod(res);

      setData(
        data.map((x) =>
          x.sensor_billing_period_id === updatedItem.sensor_billing_period_id
            ? updatedItem
            : x
        )
      );
    } catch (err) {
      Sentry.captureException(err);

      if (isAxiosError(err)) {
        const error = err as AxiosError;
        setError(error);
      }
    } finally {
      setLoadingType(null);
    }
  };

  const handleDelete = async (rowIndex: number) => {
    const item = data.find((x, index) => index === rowIndex);
    if (!item) {
      return;
    }

    confirmation
      .confirm(t('common.commonTexts.toConfirmDeletionPressOk'))
      .then(async () => {
        try {
          setError(undefined);
          setLoadingType(item.sensor_billing_period_id);
          await api.superDeleteSensorBillingPeriod(
            item.sensor_billing_period_id.toString()
          );

          setData(
            data.filter(
              (x) =>
                x.sensor_billing_period_id !== item.sensor_billing_period_id
            )
          );
        } catch (err) {
          Sentry.captureException(err);
          if (isAxiosError(err)) {
            setError(err);
          }
          console.error(err);
        } finally {
          setLoadingType(null);
        }
      })
      .catch(() => {});
  };

  const readLoading = loadingType === 'read';
  const updateLoading = loadingType === 'update';

  return (
    <div className="BillingAndPO">
      <ErrorMessages errorApiCall={error} className="mb-4" renderFieldErrors />

      {readLoading ? (
        <LoadingPlaceholder />
      ) : (
        <>
          <div className="BillingAndPO__head mb-3">
            {updateLoading && (
              <FontAwesomeIcon
                className="position-absolute ml-2"
                icon={faSpinner}
                spin
              />
            )}
          </div>
          <div className="BillingAndPO__body">
            <div className="BillingAndPO__form">
              <BillingAndPoForm
                sensorId={sensor.id}
                onCreate={(item) => {
                  setData([...data, _formatSensorBillingPeriod(item)]);
                }}
              />
            </div>

            <ReactTable
              columns={columns}
              data={data}
              onUpdate={handleUpdate}
              onDelete={handleDelete}
              showPagination={false}
            />
          </div>
        </>
      )}
    </div>
  );
};
