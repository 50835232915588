import React from 'react';
import { RouteComponentProps } from 'react-router-dom';
import getParentPath from '../../../../lib/getParentPath';
import { useRouter } from '../../../../lib/useRouter';
import { useZoneContext } from '../../../../lib/ZoneContext';
import { IconButton } from '../../../../components/IconButton';
import { AssignNewSensor } from './AssignNewSensor';
import { t } from '../../../../lib/i18n';

export const AssignSensorPage = (props: RouteComponentProps<{}>) => {
  const ctx = useZoneContext();
  const router = useRouter();

  return (
    <div className="AssignSensorPage">
      <h1>{t('manage.sensors.common.addRoommateSensor')} </h1>

      <div className="my-4">
        <IconButton
          icon="arrow-left"
          onClick={() => props.history.push(getParentPath(props.match.path))}
        >
          {t('common.commonButtons.back')}
        </IconButton>
      </div>

      <AssignNewSensor
        onCreate={router.history.goBack}
        initialZoneId={ctx.activeZone.id}
      />
    </div>
  );
};
