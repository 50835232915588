import React, { useState } from 'react';
import ModalWrapper from '../ModalWrapper';
import { useApiCall } from '../../../lib/useApiCall';
import { TreeView } from '../../TreeView';
import * as api from '../../../serverApi';
import { RouteLeavingGuard } from '../../RouteLeavingGuard';
import { useErrorHandler } from '../../../lib/useErrorHandler';
import { ErrorMessages } from '../../ErrorMessages';
import { t } from '../../../lib/i18n';

export interface MoveZoneProps {
  isOpen: boolean;
  activeZone: api.StructureZone;
  handleModal: (property: string) => void;
  updateTree: () => void;
}

export default function MoveZoneModal(props: MoveZoneProps) {
  const [loading, setLoading] = useState(false);
  const [activeZone, setActiveZone] = useState<api.StructureZone | undefined>();

  const errorHandler = useErrorHandler();
  const structure = useApiCall(api.getStructure);

  const zone = structure.data && structure.data.zone;

  const handleSubmit = async () => {
    const zoneId = props.activeZone.id;
    const parentId = activeZone && activeZone.id;

    if (
      !zoneId ||
      !parentId ||
      zoneId === parentId ||
      props.activeZone.api_lock
    ) {
      return;
    }

    try {
      errorHandler.reset();
      setLoading(true);
      await api.moveZone(zoneId, { parent_zone_id: parentId });
      setLoading(false);
      structure.reload();
      props.updateTree();
      props.handleModal('moveModal');
    } catch (error) {
      errorHandler.handleError(error);
      setLoading(false);
    }
  };

  const resetModal = () => {
    errorHandler.reset();
    setActiveZone(undefined);
  };

  const getDisabledZoneIds = (z?: api.StructureZone): number[] => {
    let response: number[] = [];

    if (!z) {
      return [] as number[];
    }

    if (z.care_receiver || z.sensors || z.is_housing_unit || z.api_lock) {
      response = response.concat(z.id);
    }

    z.zones?.forEach((zone: api.StructureZone) => {
      response = response.concat(getDisabledZoneIds(zone));
    });

    return response;
  };

  const getChildZones = (z: api.StructureZone): api.StructureZone[] => {
    let response: api.StructureZone[] = [];

    if (z.zones && z.zones.length > 0) {
      response = response.concat(z.zones);
      z.zones.forEach((zone: api.StructureZone) => {
        response = response.concat(getChildZones(zone));
      });
    }

    return response;
  };

  const disabledZoneIds = getDisabledZoneIds(zone);

  const childZones = getChildZones(props.activeZone).map(
    (zone: api.StructureZone) => zone.id
  );

  const allDisabledZones = [...disabledZoneIds, ...childZones];

  return (
    <ModalWrapper
      title={
        props.activeZone.is_housing_unit
          ? `${t('components.MoveZoneModal.moveHousingUnit')}: ${
              props.activeZone.name
            }`
          : `${t('components.MoveZoneModal.moveZone')}: ${
              props.activeZone.name
            }`
      }
      okButtonText={t('common.commonButtons.move')}
      cancelButtonText={t('common.commonButtons.cancel')}
      className="ModalWrapper-with-tree-view"
      isOpen={props.isOpen}
      loading={loading}
      onHide={() => props.handleModal('moveModal')}
      onSubmit={handleSubmit}
      onResetModal={resetModal}
    >
      <div>
        <RouteLeavingGuard when={activeZone !== undefined} />
        {zone && (
          <TreeView
            root={zone}
            className="MoveZone-tree"
            selectZone={setActiveZone}
            activeZone={activeZone}
            disableBranchId={props.activeZone.id}
            disabledZoneIds={allDisabledZones}
          />
        )}
        <ErrorMessages className="my-2" errorData={errorHandler} />
      </div>
    </ModalWrapper>
  );
}
