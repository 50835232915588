import React from 'react';
import { FormControlLabel, Checkbox } from '@material-ui/core';
import { useZoneContext } from '../../../../../lib/ZoneContext';
import { Panel } from '../../../../../components/Panel';
import useZoneSettingsForm from './lib/useZoneSettingsForm';
import { FormBody } from './lib/FormBody';
import * as api from '../../../../../serverApi';
import { t } from '../../../../../lib/i18n';
import { ToolbarButton } from '../../../../care/components/ToolbarButton';

const fields: Array<keyof api.ZoneSettings> = [
  'enable_oneway_audio_anonymised',
  'enable_oneway_audio',
  'enable_twoway_audio',
];

export function StreamAudio() {
  const ctx = useZoneContext();
  const form = useZoneSettingsForm({ fields });

  const onChangeHandler = (field: keyof api.ZoneSettings) => (
    event: React.ChangeEvent<HTMLInputElement>,
    checked: boolean
  ) => {
    form.formik.setFieldValue(field, checked);
    form.formik.setFieldTouched(field);
  };

  function toggleField(field: keyof api.ZoneSettings) {
    form.formik.setFieldValue(field, !form.displayValues?.[field]);
    form.formik.setFieldTouched(field);
  }

  return (
    <div>
      <h2>
        {t('manage.zone.StreamAudio.headingFor')} {ctx.activeZone.name}
      </h2>

      <Panel>
        <strong>{t('common.commonTexts.tips')}:</strong>{' '}
        {t('manage.zone.StreamAudio.tips')}
      </Panel>

      <FormBody zoneSettingsForm={form}>
        <div className="d-flex align-items-center my-3">
          <ToolbarButton
            className="mr-3"
            disabled={form.disabled}
            label={t('manage.zone.StreamAudio.twoWayAudio')}
            icon="telephone"
            onClick={() => toggleField('enable_twoway_audio')}
          />

          <FormControlLabel
            disabled={form.disabled}
            value="enable_twoway_audio"
            control={
              <Checkbox
                checked={form.displayValues?.enable_twoway_audio ?? false}
                onChange={onChangeHandler('enable_twoway_audio')}
                value="enable_twoway_audio"
                color="primary"
              />
            }
            label={t('manage.zone.StreamAudio.twoWayAudio')}
          />
        </div>

        <div className="d-flex align-items-center my-3">
          <ToolbarButton
            className="mr-3"
            disabled={form.disabled}
            label={t('manage.zone.StreamAudio.oneWayAudio')}
            icon="ear"
            onClick={() => toggleField('enable_oneway_audio')}
          />

          <FormControlLabel
            disabled={form.disabled}
            value="enable_oneway_audio"
            control={
              <Checkbox
                checked={form.displayValues?.enable_oneway_audio ?? false}
                onChange={onChangeHandler('enable_oneway_audio')}
                value="enable_oneway_audio"
                color="primary"
              />
            }
            label={t('manage.zone.StreamAudio.oneWayAudio')}
          />
        </div>

        <div className="d-flex align-items-center my-3">
          <ToolbarButton
            className="mr-3"
            disabled={form.disabled}
            label={t('manage.zone.StreamAudio.oneWayAnonymisedAudio')}
            icon="ear"
            accentColor={true}
            onClick={() => toggleField('enable_oneway_audio_anonymised')}
          />

          <FormControlLabel
            disabled={form.disabled}
            value="enable_oneway_audio_anonymised"
            control={
              <Checkbox
                checked={
                  form.displayValues?.enable_oneway_audio_anonymised ?? false
                }
                onChange={onChangeHandler('enable_oneway_audio_anonymised')}
                value="enable_oneway_audio_anonymised"
                color="primary"
              />
            }
            label={t('manage.zone.StreamAudio.oneWayAnonymisedAudio')}
          />
        </div>
      </FormBody>
    </div>
  );
}
