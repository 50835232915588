import React, { CSSProperties, useState } from 'react';
import { FormGroup, InputAdornment, TextField } from '@material-ui/core';
import * as Sentry from '@sentry/browser';

import isValidNorwegianId from '../../../../lib/isValidNorwegianId';
import { Icon } from '../../../../components/Icon';
import { t } from '../../../../lib/i18n';

import * as api from '../../../../serverApi';

import './SearchCareReceiver.scss';
import { generateAutoCompleteValue } from '../../../../lib/utils/generateAutoCompleteValue';

export type SearchCareReceiverType = api.CareReceiverDetails | null;

interface SearchCareReceiverProps {
  disabled?: boolean;
  autoFocus?: boolean;
  error?: string;
  label?: string;
  name: string;
  value: string | null;
  style?: CSSProperties;
  onChange?: (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => void;
  onBlur?: (
    event: React.FocusEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => void;
  onAutoFill?: (careReceiver: SearchCareReceiverType) => void;
  onNotFound?: (text?: string) => void;
}

export const SearchCareReceiver = (props: SearchCareReceiverProps) => {
  const [searching, setSearching] = useState(false);

  const onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value.trim();

    if (value.trim().length > 11) {
      return;
    }

    if (props.onChange) {
      props.onChange(e);
    }

    if (isValidNorwegianId(value)) {
      setSearching(true);
      api
        .findCareReceiverByNationalId({ national_id_number: value })
        .then((res) => {
          setSearching(false);
          if (res.care_receiver) {
            autoFill(res.care_receiver);
          } else {
            notFound();
          }
        })
        .catch((e) => {
          Sentry.captureException(e);
          setSearching(false);
        });
    }
  };

  const onBlur = (
    event: React.FocusEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    if (props.onBlur) {
      props.onBlur(event);
    }
  };

  const autoFill = (cr: SearchCareReceiverType) => {
    if (props.onAutoFill) {
      props.onAutoFill(cr);
    }
  };

  const notFound = (text?: string) => {
    if (props.onNotFound) {
      props.onNotFound(text);
    }
  };

  return (
    <div className="SearchCareReceiver" style={{ ...props?.style }}>
      <FormGroup style={{ flex: 1, maxWidth: 400 }}>
        <TextField
          autoFocus={props.autoFocus}
          disabled={props.disabled || searching}
          label={
            props.label ||
            t(
              'manage.careReceivers.search.SearchCareReceiver.nameOrBirthNumber'
            )
          }
          value={props.value ?? ''}
          name={props.name}
          onChange={onChange}
          onBlur={onBlur}
          error={!!props.error}
          helperText={props.error || ''}
          autoComplete={generateAutoCompleteValue()}
          InputProps={{
            endAdornment: (
              <>
                {searching ? (
                  <InputAdornment position="end">
                    <Icon
                      icon="gear"
                      spin
                      style={{ width: 17, color: '#9b9b9b' }}
                    />
                  </InputAdornment>
                ) : null}
              </>
            ),
          }}
        />
      </FormGroup>
    </div>
  );
};
