import React from 'react';

import './LayoutWithBackground.scss';

interface Props {
  children?: React.ReactNode;
}

export const LayoutWithBackground = (props: Props) => {
  return <div className="LayoutWithBackground">{props.children}</div>;
};
