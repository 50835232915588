import React, { useEffect, useState } from 'react';
import { RouteComponentProps } from 'react-router';
import * as api from '../../serverApi';
import { useApiCall } from '../../lib/useApiCall';
import { ReceiptPage } from './ReceiptPage';
import { useRouter } from '../../lib/useRouter';
import { VkpReceiptModal } from '../../components/modals/care/VkpReceiptModal';
import { useAppContext } from '../../lib/global';
import { useAppContainerOverflow } from '../../lib/useAppContainerOverflow';
import { t, useI18nContext } from '../../lib/i18n';
import { isEventTypeExcludedFromVkp } from '../../lib/isEventTypeExcludedFromVkp';

/**
 * TODO: figure out what image to show, now its shows the first available
 */
export function Event(props: RouteComponentProps<{ hash: string }>) {
  useAppContainerOverflow();
  const hash = props.match.params.hash;
  const i18n = useI18nContext();

  // Store possible VKP-note in scope and remove it form session storage
  const [sessionNote, setSessionNote] = useState(
    sessionStorage.getItem(`RMReceiptNote-${hash}`)
  );
  sessionStorage.removeItem(`RMReceiptNote-${hash}`);

  const res = useApiCall(api.getEventByHash, [hash]);
  const [postLoading, setPostLoading] = useState(false);
  const [vkpReceiptModal, setVkpReceiptModal] = useState(
    sessionNote ? true : false
  );
  const router = useRouter();
  const app = useAppContext();
  const loading = postLoading || res.loading;

  /**
   * Set the UI language to the recipient language, but only if
   * we're not currently logged in.
   */
  useEffect(() => {
    if (
      !res.data?.me &&
      res.data?.recipient?.locale &&
      res.data.recipient.locale !== i18n.locale
    ) {
      i18n.setLocale(res.data.recipient.locale);
    }
  }, [res]); // eslint-disable-line react-hooks/exhaustive-deps

  const handleLogout = async () => {
    setPostLoading(true);
    try {
      localStorage.removeItem('mobile-device');
      await app.requestLogout('/');
      res.reload();
    } finally {
      setPostLoading(false);
    }
  };

  async function handleMarkClick() {
    if (res.data) {
      const useVkpReceiptModal =
        res.data.vkp_enabled &&
        !isEventTypeExcludedFromVkp(
          res.data.sensor.sensor_type,
          res.data.event_type
        ) &&
        (!app.isAuthenticated ||
          app.hasCapabilityInAnyRole('sendActivityToVkp'));

      if (useVkpReceiptModal) {
        setVkpReceiptModal(true);
      } else {
        submitData();
      }
    }
  }

  async function submitData(note?: string, vkp?: boolean) {
    if (res.data) {
      setPostLoading(true);
      await api.markEventAsHandled({
        sensor_id: res.data.sensor.id,
        event_type: res.data.event_type,
        event_id: res.data.event_id,
        event_hash: hash,
        note: note,
        vkp: vkp,
      });
      res.reload();
      setPostLoading(false);
      setVkpReceiptModal(false);
    }
  }

  if (res.error) {
    return (
      <div
        style={{
          fontSize: 18,
          textAlign: 'center',
          height: '100%',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        {t('event.notFound')}
      </div>
    );
  }

  if (res.loading || res.data === undefined) {
    return <p>{t('common.commonTexts.loading')}...</p>;
  }

  return (
    <div className="App" id="app">
      <div className="App-body" id="appBody">
        <div className="Event">
          <ReceiptPage
            loading={loading}
            onHandleClick={handleMarkClick}
            images={res.data.images}
            time={res.data.at_sensor_local}
            zone={res.data.zone}
            me={res.data.me}
            sensorType={res.data.sensor.sensor_type}
            sensorId={res.data.sensor.id}
            displayArea={res.data.sensor.display_area}
            displayName={res.data.sensor.display_name}
            eventType={res.data.event_type}
            eventIsTechnical={res.data.is_technical}
            handled={res.data.handled}
            vpkEnabled={res.data.vkp_enabled ? true : false}
            received_by_sensor={res.data.received_by_sensor}
            careReceiverName={
              res.data.care_receiver
                ? `${res.data.care_receiver.first_name || ''} ${
                    res.data.care_receiver.last_name || ''
                  }`
                : undefined
            }
            onLogout={handleLogout}
            hasAccessToSupervision={res.data.access_to_supervision}
            roleWithAccessToSupervision={res.data.role_with_supervision_access}
          />

          <VkpReceiptModal
            isOpen={vkpReceiptModal}
            loading={postLoading}
            onClose={() => {
              setSessionNote(null);
              setVkpReceiptModal(false);
            }}
            initialNote={sessionNote === null ? '' : sessionNote}
            onSubmitRequest={(note: string, vkp) => {
              if (vkp && res.data && !res.data.me) {
                sessionStorage.setItem(`RMReceiptNote-${hash}`, note);
                const url = [
                  '/login',
                  '?redirect=',
                  encodeURIComponent(router.location.pathname),
                  '&skipRoleSelect=1',
                  ...(router.location.hash
                    ? ['&hash=', encodeURIComponent(router.location.hash)]
                    : []),
                  '&account=',
                  encodeURIComponent(res.data.account_code),
                ].join('');
                router.history.push(url);
              } else {
                submitData(note, vkp);
              }
            }}
          />
        </div>
      </div>
    </div>
  );
}
