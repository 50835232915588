import React from 'react';
import { Route, Switch } from 'react-router';
import { ThemeProvider } from '@material-ui/core/styles';
import { VendorAreaTheme } from '../../lib/themes/VendorAreaTheme';

import { Overview } from './overview/Overview';

import { Header } from '../../components/Header/Header';
import { Accounts } from './accounts/Accounts';
import { Sensors } from './sensors/Sensors';

import { useScrollToTop } from '../../lib/useScrollToTop';

import { useAppContext } from '../../lib/global';
import { SuperVendorNavigation } from '../supervendor/navigation/SuperVendorNavigation';
import { Integrations } from '../supervendor/integrations/Integrations';

import './Vendor.scss';

export function Vendor() {
  useScrollToTop();

  const app = useAppContext();

  return (
    <ThemeProvider theme={VendorAreaTheme}>
      <div className="Vendor">
        <Header mode="vendor" className="Vendor-header" />

        <SuperVendorNavigation className="Vendor-nav" />

        <main className="Vendor-main">
          <Switch>
            <Route path="/vendor" exact={true} component={Overview} />

            {app.hasCapability('vendorReadAccounts') && (
              <Route path="/vendor/accounts" component={Accounts} />
            )}

            <Route path="/vendor/sensors" component={Sensors} />

            {app.hasCapability('vendorSetupIntegration') && (
              <Route path="/vendor/integrations" component={Integrations} />
            )}
          </Switch>
        </main>
      </div>
    </ThemeProvider>
  );
}
