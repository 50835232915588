import React from 'react';

import { defaultTime } from './ScheduleSelectorRule';
import { getMinutesAndSecondsFromTime } from '../../../../lib/utils/getMinutesAndSecondsFromTime';

import * as api from '../../../../serverApi';

import { t, TranslationKey } from '../../../../lib/i18n';

import './ScheduleSummaryCard.scss';

interface Props {
  scheduleRules?: api.AlertSettingScheduleRule[];
}

function ScheduleRule(props: { scheduleRule: api.AlertSettingScheduleRule }) {
  const sr = props.scheduleRule;
  const allDays = sr.from_weekday.length === 7 && sr.to_weekday.length === 7;
  const singleDay = sr.from_weekday.length === 1 && sr.to_weekday.length === 1;
  const sameDay = singleDay && sr.from_weekday[0] === sr.to_weekday[0];
  const isAllDay = sr.from_time === defaultTime && sr.to_time === defaultTime;

  //TODO: Add Hele døgnet when schedule rule is all day (when null values are fixed on backend)
  if (allDays && isAllDay) {
    return (
      <tr>
        <td colSpan={2}>
          {t('common.commonTexts.allDays')}, {t('common.commonTexts.allDay')}
        </td>
      </tr>
    );
  } else if (!allDays && isAllDay) {
    return (
      <tr>
        <td colSpan={2}>
          {t(`lib.longWeekDay.${sr.from_weekday[0]}` as TranslationKey)},{' '}
          {t('common.commonTexts.allDay')}
        </td>
      </tr>
    );
  } else if (allDays) {
    return (
      <tr>
        <td colSpan={2}>
          {t('manage.alerts.ui.ScheduleSummaryCard.allDays')},{' '}
          {getMinutesAndSecondsFromTime(sr.from_time)}&ndash;
          {getMinutesAndSecondsFromTime(sr.to_time)}
        </td>
      </tr>
    );
  } else if (sameDay) {
    return (
      <tr>
        <td colSpan={2}>
          {t(`lib.longWeekDay.${sr.from_weekday[0]}` as TranslationKey)},{' '}
          {getMinutesAndSecondsFromTime(sr.from_time)}
          &ndash;
          {getMinutesAndSecondsFromTime(sr.to_time)}
        </td>
      </tr>
    );
  } else if (singleDay) {
    return (
      <tr>
        <td colSpan={2}>
          {t(`lib.longWeekDay.${sr.from_weekday[0]}` as TranslationKey)}{' '}
          {getMinutesAndSecondsFromTime(sr.from_time)}
          &ndash;
          {t(`lib.shortWeekDay.${sr.to_weekday[0]}` as TranslationKey)}{' '}
          {getMinutesAndSecondsFromTime(sr.to_time)}
        </td>
      </tr>
    );
  } else {
    return (
      <tr>
        <td colSpan={2}>
          {t('manage.alerts.ui.ScheduleSummaryCard.invalidSchedule')}.
          <br />
          {t(
            'manage.alerts.ui.ScheduleSummaryCard.pleaseContactCustomerSupport'
          )}
          .
        </td>
      </tr>
    );
  }
}

export function ScheduleSummaryCard(props: Props) {
  return (
    <table className="ScheduleSummaryCard">
      <thead>
        <tr>
          <th colSpan={2}>{t('manage.alerts.common.schedule')}:</th>
        </tr>
      </thead>
      <tbody>
        {props.scheduleRules?.length ? (
          <>
            {props.scheduleRules?.map((sr, idx) => (
              <ScheduleRule key={idx} scheduleRule={sr} />
            ))}
          </>
        ) : (
          <tr>
            <td colSpan={2}>
              {t('manage.alerts.ui.ScheduleSummaryCard.noScheduleHasBeenSetUp')}
              <br />
              {t('manage.alerts.ui.ScheduleSummaryCard.thisAlertWillNotBeSent')}
            </td>
          </tr>
        )}
      </tbody>
    </table>
  );
}
